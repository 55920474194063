import { connect } from 'react-redux';
import _ from 'lodash';
import PageHeader from './page-header-admin.presentation';
import {
  logoutAsync, clearUIError, setUIError, toggleMenu,
} from '../../redux/action';
import * as Constant from '../../constant';

const mapStateToProps = state => ({
  loggingOut: state.uiWebUserMain.loggingOut,
  menuOpen: state.uiMenu.menuOpen,
  shoppingCart: _.values(state.shoppingCart),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onToggleMenuPressed: (menustate) => {
    dispatch(toggleMenu(menustate));
  },
  onLogoutButtonPressed: async () => {
    try {
      dispatch(clearUIError(Constant.RXSTATE_WEB_USER_MAIN));
      await dispatch(logoutAsync(ownProps.history));
    } catch (error) {
      dispatch(setUIError(Constant.RXSTATE_WEB_USER_MAIN, error.message));
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageHeader);
