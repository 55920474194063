import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import HistoryCard from './history-card.presentation';
import {
  downloadMyPaymentOrderAsync,
  setMyPaymentHistorySearchText,
  setTappedPaymentHistoryProformaNo,
  showErrorSnackbar,
} from '../../redux/action';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler, toMoment } from '../../helper';

const getFilteredBl = state => _.values(state.myOrder)
  .filter((item) => {
    const searchText = state.uiMyPaymentHistory.searchBarText.toLowerCase();
    const itemsFound = item.items.find((detailItem) => {
      const invoiceNo = detailItem.invoiceNo || '-';
      const blNumber = detailItem.blNumber || '-';
      const service = detailItem.service || '-';

      return invoiceNo.toLowerCase().indexOf(searchText) >= 0
        || blNumber.toLowerCase().indexOf(searchText) >= 0
        || service.toLowerCase().indexOf(searchText) >= 0;
    });
    return item.proformaNo.toLowerCase()
      .indexOf(searchText) >= 0
      || itemsFound;
  })
  .sort((left, right) => toMoment(right.createdDate) - toMoment(left.createdDate));

const mapStateToProps = state => ({
  downloading: state.uiMyPaymentHistory.downloading,
  bl: getFilteredBl(state),
  searchBarText: state.uiMyPaymentHistory.searchBarText,
  selectedProformaNo: state.uiMyPaymentHistory.tappedProformaNo,
  userCompany: state.currentUser.company,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    try {
      await dispatch(downloadMyPaymentOrderAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onChangeSearchBarText: (text) => {
    dispatch(setMyPaymentHistorySearchText(text));
  },
  onMorePressed: (tappedProformaNo) => {
    dispatch(setTappedPaymentHistoryProformaNo(tappedProformaNo));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HistoryCard));
