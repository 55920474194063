import _ from 'lodash';
import * as Action from '../action';

const initialState = {
  booking: false,
  bookNo: '',
  modifiedPartyId: 0,
  uploadingBookPartyDocument: false,
  modalAddPartyVisibility: false,
  modalModifyPartyVisibility: false,

  party: [],
  inEditParty: [],
  newParty: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case Action.BOOKING_EXPORT_PARTY_SERVICE:
    //   return { ...state, booking: action.status };
    case Action.ADD_EXPORT_BOOK_NO:
      return { ...state, bookNo: action.bookNo };
    case Action.UPLOADING_BOOK_EXPORT_PARTY_DOCUMENT:
      return { ...state, uploadingBookPartyDocument: action.status };

    case Action.SET_EXPORT_NEW_PARTY:
      return { ...state, newParty: [...state.newParty, action.partyId] };
    case Action.REMOVE_EXPORT_NEW_PARTY:
      return { ...state, newParty: _.without(state.newParty, action.partyId) };

    case Action.SET_EXPORT_EDIT_PARTY:
      return { ...state, inEditParty: [...state.inEditParty, action.value] };
    case Action.REMOVE_EXPORT_EDIT_PARTY:
      return { ...state, inEditParty: _.without(state.inEditParty, action.value) };
    // case Action.SET_BOOK_EXPORT_ADD_PARTY_MODAL_VISIBILTIY:
    //   return { ...state, modalAddPartyVisibility: action.status };
    // case Action.SET_BOOK_EXPORT_MODIFY_PARTY_MODAL_VISIBILTIY:
    //   return { ...state, modalModifyPartyVisibility: action.status };
    // case Action.SET_BOOK_EXPORT_MODIFIED_PARTY_ID:
    //   return { ...state, modifiedPartyId: action.id };
    default: return state;
  }
};
