import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  withStyles,
  CssBaseline,
  Card,
  Icon,
  CardContent,
  Typography,
  Grid,
  Fab,
  Button,
  ButtonBase,
  TextField,
} from '@material-ui/core';
import { Link, Route } from 'react-router-dom';
import { toMoment } from '../../helper';
import {
  LoadingIndicator,
  PageHeaderAdmin,
} from '../../component';
import LocalizedString from '../../localization';
import * as Constant from '../../constant';
import DocumentSubmissionDetail from './document-submission-detail.container';

const styles = theme => ({
  backIcon: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${Constant.DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    color: '#0266B4',
  },
  cardListContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: theme.spacing.unit * 2,
    maxBlockSize: '500px',
  },
  cardItem: {
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '6px',
    boxSizing: 'border-box',
    padding: '16px',
    marginBottom: theme.spacing.unit,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  buttonPrimary: {
    backgroundColor: '#50abf1',
  },
  textGray: {
    color: '#8C8C8C',
  },
  notFound: {
    color: '#8C8C8C',
    textAlign: 'center',
  },
  selected: {
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  orangeText: {
    color: Constant.COLOR_STATUS_ACCENT,
  },
  greenText: {
    color: Constant.COLOR_STATUS_FINISHED,
  },
});

const renderStatusActionButton = (status, refId, onStatusActionPressed) => {
  if (status === Constant.FORM_RESPONSE_STATUS_WAITING_PAYMENT) {
    return (
      <Route
        render={({ history }) => (
          <Button
            color="primary"
            variant="text"
            size="large"
            onClick={e => onStatusActionPressed(e, history, status, refId)}
          >
            {LocalizedString.submitRequest.buttonCaptionViewPayment}
          </Button>
        )}
      />
    );
  }
  if (status === Constant.FORM_RESPONSE_STATUS_FINISHED) {
    return (
      <Route
        render={({ history }) => (
          <Button
            color="primary"
            variant="text"
            size="large"
            onClick={e => onStatusActionPressed(e, history, status, refId)}
          >
            {LocalizedString.submitRequest.buttonCaptionViewTransaction}
          </Button>
        )}
      />
    );
  }

  return null;
};

const renderCardList = (
  classes, myResponses, tappedId, onCardItemPressed, onStatusActionPressed,
) => {
  if (!myResponses || myResponses.length === 0) {
    return (
      <Typography className={classes.notFound}>
        {LocalizedString.submitRequest.labelNoDocuments}
      </Typography>
    );
  }

  return myResponses.map(item => (
    <ButtonBase
      component="div"
      key={item.id}
      className={classNames(classes.cardItem, {
        [classes.selected]: item.id === tappedId,
      })}
      onClick={() => onCardItemPressed(item.id, tappedId)}
    >
      <Grid container spacing={24}>
        <Grid item xs={12} md={12}>
          <Typography variant="h6" className={classes.title}>
            {item.form.title}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="caption" className={classes.textGray}>
            {LocalizedString.submitRequest.labelSubmitId}
          </Typography>
          <Typography variant="subtitle2" className={classes.textGray}>
            {item.submitId}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="caption" className={classes.textGray}>
            {LocalizedString.submitRequest.labelBlNoRefNo}
          </Typography>
          <Typography variant="subtitle2" className={classes.textGray}>
            {item.refId}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="caption" className={classes.textGray}>
            {LocalizedString.submitRequest.labelSubmitDate}
          </Typography>
          <Typography variant="subtitle2" className={classes.textGray}>
            {toMoment(item.submitDate).format(Constant.MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="caption" className={classes.textGray}>
            {LocalizedString.submitRequest.labelStatus}
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography
              variant="subtitle2"
              className={item.status === Constant.FORM_RESPONSE_STATUS_FINISHED
                ? classes.greenText : classes.orangeText}
            >
              {item.status}
            </Typography>
            {renderStatusActionButton(item.status, item.refId, onStatusActionPressed)}
          </div>
        </Grid>
      </Grid>
    </ButtonBase>
  ));
};


class DocumentSubmissionPage extends Component {
  componentDidMount() {
    this.props.onAppear();
  }

  render() {
    const {
      classes,
      myResponses,
      tappedId,
      downloadingMyResponses,
      notificationOpen,
      onSubmitNewDocumentPressed,
      onCardItemPressed,
      searchBarText,
      onChangeSearchBarText,
      onStatusActionPressed,
    } = this.props;

    return (
      <>
        <CssBaseline />
        <PageHeaderAdmin />

        <div
          className={
            classNames(classes.content, {
              [classes.contentDrawerOpen]: notificationOpen,
              [classes.contentDrawerClosed]: !notificationOpen,
            })
          }
          style={{ padding: '16px' }}
        >
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <div className={classes.pageNavigatorContainer}>
                    <Fab
                      variant="round"
                      classes={{ root: classes.backIcon }}
                      component={Link}
                      to={Constant.ROUTE_NAME_DASHBOARD_USER}
                    >
                      <Icon>chevron_left</Icon>
                    </Fab>
                    <div style={{ marginLeft: '32px', flex: 1 }}>
                      <Typography variant="h5" className={classes.title}>{LocalizedString.submitRequest.labelDocumentSubmission}</Typography>
                      <Typography variant="caption">{LocalizedString.submitRequest.documentSubmissionCaption}</Typography>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonPrimary}
                        onClick={onSubmitNewDocumentPressed}
                      >
                        {LocalizedString.submitRequest.buttonCaptionSubmitNewDocument}
                      </Button>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <div style={{ display: 'flex', marginTop: '24px' }}>
                    <div style={{ flexGrow: 1, alignSelf: 'flex-end' }}>
                      <TextField
                        fullWidth
                        placeholder="Search BL no"
                        value={searchBarText}
                        onChange={event => onChangeSearchBarText(event.target.value)}
                      />
                    </div>
                    <div style={{ display: 'inline-flex', marginLeft: '8px' }}>
                      <Fab
                        size="small"
                        variant="round"
                        color="default"
                        className={classes.searchButton}
                      >
                        <Icon>search</Icon>
                      </Fab>
                    </div>
                  </div>
                  <div className={classes.cardListContainer}>
                    {renderCardList(
                      classes, myResponses, tappedId,
                      onCardItemPressed, onStatusActionPressed,
                    )}
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              <DocumentSubmissionDetail />
            </Grid>
          </Grid>
        </div>
        {downloadingMyResponses && <LoadingIndicator />}
      </>
    );
  }
}
DocumentSubmissionPage.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  myResponses: PropTypes.arrayOf(PropTypes.any).isRequired,
  tappedId: PropTypes.string.isRequired,
  searchBarText: PropTypes.string.isRequired,
  downloadingMyResponses: PropTypes.bool.isRequired,
  notificationOpen: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onSubmitNewDocumentPressed: PropTypes.func.isRequired,
  onCardItemPressed: PropTypes.func.isRequired,
  onChangeSearchBarText: PropTypes.func.isRequired,
  onStatusActionPressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(DocumentSubmissionPage);
