import * as Helper from '../../../helper';
import * as Constant from '../../../constant';
import { addUser, loadingFilter } from '../simple-action';

export default (filters, sort, paging) => async (dispatch, getState) => {
  // try {
  //   dispatch(loadingFilter(Constant.RXSTATE_USER_MANAGEMENT, true));
  //   const { token } = getState().authentication;
  //   // const profiles = await Helper.downloadAllProfiles(paging.activePage, paging.pageSize, token);

  //   profiles.forEach((userInfo) => {
  //     dispatch(addUser(userInfo));
  //   });
  // } finally {
  //   dispatch(loadingFilter(Constant.RXSTATE_USER_MANAGEMENT, false));
  // }
};
