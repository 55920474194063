import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  TextField, Button, withStyles, Typography, Card, CardHeader, Avatar, CardContent, Input, Icon,
} from '@material-ui/core';
import LocalizedString from '../../localization';
import { LoadingIndicator, RoundedButton, ErrorMessage } from '../../component';
import * as Constant from '../../constant';

const styles = theme => ({
  titleContainer: {
    width: '90%',
    padding: '16px',
  },
  footerContainer: {
    width: '90%',
    padding: '16px',
    textAlign: 'right',
  },
  button: {
    margin: theme.spacing.unit,
  },
  cardRoot: {
    overflow: 'visible',
    padding: theme.spacing.unit,
    minWidth: '360px',
    maxWidth: '95%',
  },
  cardHeaderAvatar: {
    margin: 0,
    width: '100%',
  },
  cardAvatar: {
    height: 96,
    // width: 'calc(100% - 24px)',
    width: '100%',
    padding: '12px',
    borderRadius: 4,
    marginTop: theme.spacing.unit * -6,

    background: 'linear-gradient(60deg, #0164b2, #014982)',
    boxShadow: '0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)',
    color: '#ffffff',
  },

  labelTitle: {
    fontWeight: 500,
    color: '#ffffff',
  },
  labelSubtitle: {
    fontWeight: 400,
    color: '#ffffff',
  },
  labelFooter: {
    fontWeight: 400,
    color: '#ffffff',
  },
});

const LoginCardAvatar = withStyles(styles)(props => (
  <Avatar classes={{ root: props.classes.cardAvatar }}>
    <img title="Logo" alt="Logo" src="/assets/images/logo.png" />
  </Avatar>
));

class EmailSubmission extends Component {
    state = {
      email: '',
    }

    onSubmitButtonClicked = () => {
      this.props.onSubmit(this.state.email);
    }

    onBackToLoginPressed = () => {
      this.props.history.goBack();
    }

    render() {
      const { submitting, classes, errorMsg } = this.props;
      return (
        <>
          <div className={classes.titleContainer}>
            <Typography variant="title">
              <span className={classes.labelTitle}>{LocalizedString.loginScreen.title}</span>
              &nbsp;
              <span variant="title" className={classes.labelSubtitle}>{LocalizedString.loginScreen.subTitle}</span>
            </Typography>
          </div>
          <Card classes={{ root: classes.cardRoot }}>
            <CardHeader
              avatar={<LoginCardAvatar />}
              classes={{ avatar: classes.cardHeaderAvatar }}
            />
            <CardContent>
              <Typography variant="title">
                {LocalizedString.forgetPasswordScreen.title}
              </Typography>

              <Typography variant="body1">
                {LocalizedString.forgetPasswordScreen.description}
              </Typography>

              <TextField
                fullWidth
                label={LocalizedString.forgetPasswordScreen.lableEmail}
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
                margin="normal"
                InputProps={{
                  endAdornment: (<Icon>email</Icon>),
                }}
              />
              <div style={{ textAlign: 'center', marginTop: '32px' }}>
                <RoundedButton type="button" className={classes.button} onClick={this.onSubmitButtonClicked}>
                  {LocalizedString.common.buttonCaptionSubmit}
                </RoundedButton>
                <br />
                <br />
                <Button color="primary" onClick={this.onBackToLoginPressed}>
                  {LocalizedString.common.buttonCaptionBackToLogin}
                </Button>
              </div>

              {errorMsg && <ErrorMessage message={errorMsg} />}
            </CardContent>
          </Card>
          <div className={classes.footerContainer}>
            <Typography variant="body2" className={classes.labelFooter}>{LocalizedString.loginScreen.footer}</Typography>
          </div>
          {submitting && <LoadingIndicator />}

          {/* <div>
            <h2>{LocalizedString.forgetPasswordScreen.title}</h2>

            <div>
              {LocalizedString.forgetPasswordScreen.description}
            </div>

            <div>
              <TextField
                fullWidth
                label={LocalizedString.forgetPasswordScreen.lableEmail}
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
                margin="normal"
              />
            </div>

            <Button variant="contained" color="primary" disabled={submitting} onClick={this.onSubmitButtonClicked}>
              {LocalizedString.common.buttonCaptionSubmit}
            </Button>
            {submitting && <LoadingIndicator />}
          </div> */}
        </>
      );
    }
}
EmailSubmission.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  submitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  history: PropTypes.func.isRequired,
  errorMsg: PropTypes.string.isRequired,
};
export default withStyles(styles)(EmailSubmission);
