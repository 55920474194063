import * as Action from '../action';

const initialState = {
  selectedTab: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.SET_TRACKING_STATUS_BEHANDLE_SELECTED_TAB:
      return { ...state, selectedTab: action.value };
    default:
      return state;
  }
};
