import { downloadingMyPaymentTermOrder, addDownloadedMyPaymentTermOrder } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;

  try {
    dispatch(downloadingMyPaymentTermOrder(true));

    const myTermOrder = await Helper.downloadMyPaymentTermOrder(token);

    dispatch(addDownloadedMyPaymentTermOrder(myTermOrder));
  } finally {
    dispatch(downloadingMyPaymentTermOrder(false));
  }
};
