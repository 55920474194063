import { updatingDepositBalance, updateDepositBalance } from '../simple-action';
import { PAYMENT_TYPE_DEPOSIT } from '../../../constant';
import { downloadDepositBalance } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { company } = getState().currentUser;

  if (company.paymentMethod !== PAYMENT_TYPE_DEPOSIT) { return; }

  try {
    dispatch(updatingDepositBalance(true));
    const result = await downloadDepositBalance(token);
    dispatch(updateDepositBalance(result.depositAccount.balance));
  } finally {
    dispatch(updatingDepositBalance(false));
  }
};
