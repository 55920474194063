import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { change } from 'redux-form';
import _, { isEmpty } from 'lodash';
import FieldFilterDialog from './field-filter-dialog.presentation';
import {
  showErrorSnackbar,
  setFieldFilter,
  downloadAllFieldAsync,
  setFieldFilterDialogStatus,
  downloadAllFormAsync,
  setFormFilter,
  setTempFilterForm,
  setFieldSort,
} from '../../redux/action';
import {
  RXFIELD_FIELD_FORM,
  RXFIELD_FORM_TITLE,
  RXFORM_FIELD_FILTER, TABLE_SORT_ASCENDING,
} from '../../constant';
import LocalizedString from '../../localization';

const getFilteredForms = (state) => {
  const filteredForms = _.values(state.forms.data)
    .map((item, index) => ({ ...item, index: index + 1 }));

  const sorted = filteredForms
    .map(x => ({
      label: x.title,
      value: x.id,
    }))
    .sort((left, right) => (left.label.toLowerCase().localeCompare(right.label.toLowerCase())));

  return sorted;
};

const getPageFilteredForms = (state) => {
  const { filters } = state.uiForm;
  const { title } = filters;
  const filteredForm = getFilteredForms(state);

  if (!isEmpty(filteredForm)) {
    if (title !== '') {
      const form = filteredForm.filter(
        x => (x.label ? x.label.toLowerCase().includes(title.toLowerCase()) : ''),
      );
      return form;
    }
    return filteredForm;
  }

  return [];
};

const getInitialValues = (state) => {
  const {
    filters, tempFormFilter,
  } = state.uiField;
  const { formName } = filters;

  return ({
    [RXFIELD_FIELD_FORM]: tempFormFilter || formName,
  });
};

const mapStateToProps = state => ({
  downloadingForm: state.uiForm.downloading,

  forms: getPageFilteredForms(state),
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = dispatch => ({
  onAppear: async () => {
    try {
      await dispatch(downloadAllFormAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    }
  },
  onFormTextChanged: async (text) => {
    dispatch(setFormFilter(RXFIELD_FORM_TITLE, text));
  },
  onSubmitPressed: async (value) => {
    dispatch(setFieldFilter(RXFIELD_FIELD_FORM, (value.formName || '')));
    dispatch(setTempFilterForm(''));
    dispatch(setFieldFilterDialogStatus(false));
    try {
      dispatch(setFieldSort('order', TABLE_SORT_ASCENDING));
      await dispatch(downloadAllFieldAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    }
  },
  onFormSelected: (value) => {
    dispatch(setTempFilterForm(value));
    dispatch(change(RXFORM_FIELD_FILTER, RXFIELD_FIELD_FORM, value));
  },
  onResetPressed: async () => {
    dispatch(change(RXFORM_FIELD_FILTER, RXFIELD_FIELD_FORM, ''));
    dispatch(setTempFilterForm(''));
    dispatch(setFieldFilter(RXFIELD_FIELD_FORM, ('')));
    dispatch(setFormFilter(RXFIELD_FORM_TITLE, ''));
    dispatch(setFieldFilterDialogStatus(false));
    try {
      dispatch(setFieldSort('order', TABLE_SORT_ASCENDING));
      await dispatch(downloadAllFieldAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    }
  },
  onClose: () => {
    dispatch(setFieldFilterDialogStatus(false));
    dispatch(setFormFilter(RXFIELD_FORM_TITLE, ''));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FieldFilterDialog));
