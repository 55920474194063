import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, withStyles } from '@material-ui/core';
import LocalizedString from '../../localization';
import {
  PAYMENT_TYPE_DEPOSIT, PAYMENT_TYPE_TERM, PAYMENT_TYPE_CASH, ROLE_PPJK,
} from '../../constant';

const styles = theme => ({
  dialogContent: {
    marginTop: '24px',
  },
  tabIndicator: {
    display: 'none',
  },
  tabRoot: {
    marginTop: theme.spacing.unit,
    overflow: 'visible',
  },
  tabElement: {
    backgroundColor: 'rgba(200, 200, 200, 0.2)',
    textTransform: 'capitalize',
  },
  tabElementActive: {
    backgroundColor: '#3f51b5',
    color: '#ffffff !important',
    transform: 'scaleY(1.1)',
    borderRadius: '4px',
    boxShadow: '0px 4px 20px rgba(0, 102, 203, 0.45)',
  },
  tabScroller: {
    overflowX: 'visible',
  },
});

const MyPaymentTabs = ({
  classes,
  selectedTab,
  userCompany,
  onTabPressed,
}) => {
  const showOutstandingTab = userCompany
    && (userCompany.paymentMethod === PAYMENT_TYPE_CASH
      || userCompany.paymentMethod === PAYMENT_TYPE_TERM);
  const showTermTab = userCompany
    && (userCompany.paymentMethod === PAYMENT_TYPE_TERM
      || userCompany.role.toUpperCase() === ROLE_PPJK);

  return (
    <Tabs
      value={selectedTab}
      indicatorColor="primary"
      textColor="primary"
      classes={{
        root: classes.tabRoot,
        indicator: classes.tabIndicator,
        scroller: classes.tabScroller,
      }}
      fullWidth
      centered
    >
      {showOutstandingTab && (
        <Tab
          value={0}
          label={LocalizedString.myPayment.labelOutstanding}
          classes={{
            root: classes.tabElement,
            selected: classes.tabElementActive,
          }}
          onClick={() => onTabPressed(0)}
        />
      )}

      {showTermTab && (
        <Tab
          value={1}
          label={LocalizedString.myPayment.labelTerm}
          classes={{
            root: classes.tabElement,
            selected: classes.tabElementActive,
          }}
          onClick={() => onTabPressed(1)}
        />
      )}
      <Tab
        value={3}
        label={LocalizedString.myPayment.labelOrder}
        classes={{
          root: classes.tabElement,
          selected: classes.tabElementActive,
        }}
        onClick={() => onTabPressed(3)}
      />
      <Tab
        value={2}
        label={LocalizedString.myPayment.labelHistory}
        classes={{
          root: classes.tabElement,
          selected: classes.tabElementActive,
        }}
        onClick={() => onTabPressed(2)}
      />
    </Tabs>
  );
};
MyPaymentTabs.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  selectedTab: PropTypes.number.isRequired,
  userCompany: PropTypes.shape(PropTypes.any).isRequired,
  onTabPressed: PropTypes.func.isRequired,
};
export default withStyles(styles)(MyPaymentTabs);
