import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import ReeferDataDetailScreen from './reefer-data-detail.presentation';
import * as Constant from '../../constant';
import * as Action from '../../redux/action';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const getReeferDetails = (state) => {
  const { tappedContainerNo } = state.uiReeferDataContainer;
  const { tappedBlNo } = state.uiReeferData;

  return _.values(state.reeferDataDetails)
    .filter(item => item.blNo === tappedBlNo && item.containerNo === tappedContainerNo);
};

const getFilteredReeferDetails = (state) => {
  const {
    filters, sortField, sortDirection,
  } = state.uiReeferDataContainer;
  const reeferDataDetails = getReeferDetails(state);

  return reeferDataDetails.sort((left, right) => {
    if (typeof left[sortField] === 'string') {
      if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
        return left[sortField].localeCompare(right[sortField]);
      }
      return right[sortField].localeCompare(left[sortField]);
    }

    if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
      return left[sortField] - right[sortField];
    }
    return right[sortField] - left[sortField];
  })
    .filter(item => item.shiftId.indexOf(
      filters[Constant.TABLE_FIELD_SHIFT_ID],
    ) >= 0)
    .filter((item) => {
      if (filters[Constant.TABLE_FIELD_SHIFT_DATE]) {
        return moment(item.shiftDate).format(Constant.MOMENT_DATE_FORMAT_STRING)
              === moment(filters[Constant.TABLE_FIELD_SHIFT_DATE])
                .format(Constant.MOMENT_DATE_FORMAT_STRING);
      }
      return true;
    })
    .filter((item) => {
      const temp = `${item.settingPointTemp}${LocalizedString.common.tempSymbol}`;
      return temp.indexOf(
        filters[Constant.TABLE_FIELD_SETTING_TEMPERATURE],
      ) >= 0;
    })
    .filter((item) => {
      const temp = `${item.supplyAirTemp}${LocalizedString.common.tempSymbol}`;
      return temp.indexOf(
        filters[Constant.TABLE_FIELD_AIR_SUPPLY],
      ) >= 0;
    });
};

const getPageFilteredReeferDetails = (state) => {
  const {
    activePage, pageSize,
  } = state.uiReeferDataContainer;
  const reeferDataDetails = getFilteredReeferDetails(state);

  const refinedActivePage = activePage || 1;
  const start = Math.min((refinedActivePage - 1) * pageSize, reeferDataDetails.length);
  const end = Math.min(start + pageSize, reeferDataDetails.length);
  return reeferDataDetails.slice(start, end);
};

const getTotalPage = (state) => {
  const totalData = getFilteredReeferDetails(state).length;
  const { pageSize } = state.uiReeferDataContainer;

  return Math.max(Math.ceil(totalData / pageSize), 1);
};

const mapStateToProps = state => ({
  downloadingReeferDataDetails: state.uiReeferDataContainer.downloadingReeferDataDetails,
  containerNo: state.uiReeferDataContainer.tappedContainerNo,
  reeferData: getPageFilteredReeferDetails(state),
  filters: state.uiReeferDataContainer.filters,
  sortField: state.uiReeferDataContainer.sortField,
  sortDirection: state.uiReeferDataContainer.sortDirection,
  pageSize: state.uiReeferDataContainer.pageSize,
  activePage: state.uiReeferDataContainer.activePage,
  totalPage: getTotalPage(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    try {
      dispatch(Action.setReeferDataDetailPaging(Constant.RXTABLEFIELD_ACTIVE_PAGE, 1));
      dispatch(Action.setReeferDataDetailFilter(Constant.TABLE_FIELD_SHIFT_ID, ''));
      dispatch(Action.setReeferDataDetailFilter(Constant.TABLE_FIELD_SHIFT_DATE, ''));
      dispatch(Action.setReeferDataDetailFilter(Constant.TABLE_FIELD_SETTING_TEMPERATURE, ''));
      dispatch(Action.setReeferDataDetailFilter(Constant.TABLE_FIELD_AIR_SUPPLY, ''));
    } catch (error) {
      dispatch(Action.showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onChangeFilter: (filterName, value) => {
    dispatch(Action.setReeferDataDetailFilter(filterName, value));
  },
  onChangePaging: (pageField, value) => {
    dispatch(Action.setReeferDataDetailPaging(pageField, value));
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(Action.setReeferDataDetailSort(sortField, sortDirection));
  },
  onClose: () => {
    dispatch(Action.setReeferDataDetailDialogStatus(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReeferDataDetailScreen);
