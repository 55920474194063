import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import uuid from 'uuid/v4';
import OrderCard from './order-card.presentation';
import {
  addToShoppingCartAsync,
  downloadingTariffSimulationProformaInvoice,
  downloadProformaInvoiceAsync,
  requestPrintAsync,
  setExpandedCartItem,
  setPdfViewerStatus,
  showErrorSnackbar,
} from '../../redux/action';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';
import {
  SERVICE_TYPE_PREDEFINED_ORDER, PRINT_TYPE_INVOICE, PRINT_TYPE_KMT, PRINT_TYPE_SP2,
  ROUTE_NAME_CART_PAGE,
} from '../../constant';


const mapStateToProps = state => ({
  shoppingCartItems: _.values(state.shoppingCart),
  selectedBlNo: state.uiPredefinedOrder.tappedBlNo,
  userCompany: state.currentUser.company,
  downloadingProforma: state.uiTariffSimulation.downloadingProformaInvoice,
  addingItemToCart: state.uiShoppingCart.addingItem,
  addingItemId: state.uiShoppingCart.addingItemId,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddToCartPressed: (item, foundOnCart, check) => {
    if (foundOnCart) {
      dispatch(setExpandedCartItem(item.blHeader));
      ownProps.history.push(ROUTE_NAME_CART_PAGE);
    } else {
      const itemId = uuid();
      const { blHeader, totalAmount } = item;

      dispatch(addToShoppingCartAsync(
        itemId,
        blHeader,
        SERVICE_TYPE_PREDEFINED_ORDER,
        totalAmount,
        {
          ...item,
          pph: check,
          originalService: item.serviceType,
        },

      ))
        .catch((error) => {
          dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
        });
    }
  },
  onSecondaryButtonPressed: async (label, item) => {
    switch (label) {
      case LocalizedString.myPaymentOrder.buttonCaptionInvoicePDF:
        dispatch(requestPrintAsync({
          printType: PRINT_TYPE_INVOICE,
          invoiceNo: item.invoiceNo,
          blNo: item.blHeader,
          service: item.serviceType,
        }))
          .catch((error) => {
            dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
          });
        break;
      case LocalizedString.myPaymentOrder.buttonCaptionSP2:
        dispatch(requestPrintAsync({
          printType: PRINT_TYPE_SP2,
          invoiceNo: item.invoiceNo,
          blNo: item.blHeader,
          service: item.serviceType,
        }))
          .catch((error) => {
            dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
          });
        break;
      case LocalizedString.myPaymentOrder.buttonCaptionKMT:
        dispatch(requestPrintAsync({
          printType: PRINT_TYPE_KMT,
          invoiceNo: item.invoiceNo,
          blNo: item.blHeader,
          service: item.serviceType,
        }))
          .catch((error) => {
            dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
          });
        break;
      case LocalizedString.myPaymentOrder.buttonCaptionViewProforma:
        try {
          dispatch(downloadingTariffSimulationProformaInvoice(true));
          await dispatch(downloadProformaInvoiceAsync(item.invoiceNo, true));
          dispatch(setPdfViewerStatus(true));
        } catch (error) {
          dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
          unauthorizedErrorHandler(error, ownProps.history.push);
        } finally {
          dispatch(downloadingTariffSimulationProformaInvoice(false));
        }
        break;
      default:
        break;
    }
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderCard));
