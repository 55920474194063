import React from 'react';
import classNames from 'classnames';
import {
  withStyles, ButtonBase, Grid, Typography,
} from '@material-ui/core';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import LocalizedString from '../../localization';
import { toMoment } from '../../helper';
import { MOMENT_DATE_FORMAT_STRING } from '../../constant';

const getItemSize = items => index => (items.length - 1 === index ? 188 : 196);

const styles = theme => ({
  cardItem: {
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '6px',
    boxSizing: 'border-box',
    padding: '16px',
    marginBottom: theme.spacing.unit,
    '&:last-child': {
      marginBottom: 0,
    },
    '&:after': {
      content: '',
      position: 'absolute',
      bottom: '0px',
      left: 0,
      width: '100%',
      height: '10px',
      backgroundColor: '#f4f4f4',
    },
  },
  cardItemActive: {
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  spacer: {
    height: '8px',
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

const TruckingBlCard = ({
  classes,
  bl,
  selectedBlNo,
  onMorePress,
}) => (
  <>
    <AutoSizer>
      {({ width, height }) => (
        <List
          autoHeight
          className="List"
          height={height}
          itemCount={bl.length}
          itemSize={getItemSize(bl)}
          width={width}
        >
          {({ index, style }) => {
            const item = bl[index];
            return (
              <div style={style}>
                <ButtonBase
                  component="div"
                  key={item.blNo}
                  className={classNames(classes.cardItem, {
                    [classes.cardItemActive]: selectedBlNo
                      && item.refNo.toUpperCase() === selectedBlNo.toUpperCase(),
                  })}
                  onClick={() => onMorePress(item.refNo)}
                >
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <Typography color="primary" variant="h6">
                        {LocalizedString.trucking.lblBlNo}
                        &nbsp;
                        {item.refNo}
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={4}>
                      <Typography className={classes.ellipsis} variant="caption">{LocalizedString.trucking.lblInvoiceNo}</Typography>
                      <Typography className={classes.ellipsis}>
                        {item.invoiceNo || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={8}>
                      <Typography className={classes.ellipsis} variant="caption">{LocalizedString.trucking.lblParty}</Typography>
                      <Typography className={classes.ellipsis}>
                        {item.bookParty || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Typography className={classes.ellipsis} variant="caption">{LocalizedString.trucking.lblESil}</Typography>
                      <Typography className={classes.ellipsis}>
                        {item.soNo || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Typography className={classes.ellipsis} variant="caption">{LocalizedString.trucking.lblPlanDate}</Typography>
                      <Typography className={classes.ellipsis}>
                        {toMoment(item.planDate).format(MOMENT_DATE_FORMAT_STRING)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Typography className={classes.ellipsis} variant="caption">{LocalizedString.trucking.lblBookingStatus}</Typography>
                      <Typography className={classes.ellipsis}>
                        {item.bookingStatus}
                      </Typography>
                    </Grid>
                  </Grid>
                </ButtonBase>
                <div className={classNames({
                  [classes.spacer]: index !== bl.length - 1,
                })}
                />
              </div>
            );
          }}
        </List>
      )}
    </AutoSizer>
    {/* {bl.map(item => (
      <ButtonBase
        component="div"
        key={item.blNo}
        className={classNames(classes.cardItem, {
          [classes.cardItemActive]: selectedBlNo
            && item.refNo.toUpperCase() === selectedBlNo.toUpperCase(),
        })}
        onClick={() => onMorePress(item.refNo)}
      >
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography color="primary" variant="h6">
              {LocalizedString.trucking.lblBlNo}
              &nbsp;
              {item.refNo}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="caption">{LocalizedString.trucking.lblESil}</Typography>
            <Typography>
              {item.soNumber || '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="caption">{LocalizedString.trucking.lblService}</Typography>
            <Typography className={classes.capitalize}>Import</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="caption">{LocalizedString.trucking.lblBookingStatus}</Typography>
            <Typography>
              {item.bookingStatus}
            </Typography>
          </Grid>
        </Grid>
      </ButtonBase>
    ))} */}
  </>
);

export default withStyles(styles)(TruckingBlCard);
