import * as Action from '../action';

const initialState = {
  downloading: false,
  searchBarText: '',
  tappedId: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.DOWNLOADING_TRACKING_STATUS_BEHANDLE_ON_PROGRESSES:
      return { ...state, downloading: action.status };
    case Action.SET_TRACKING_STATUS_BEHANDLE_ON_PROGRESS_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case Action.SET_TRACKING_STATUS_BEHANDLE_ON_PROGRESS_TAPPED_ID:
      return { ...state, tappedId: action.id };
    default:
      return state;
  }
};
