import { connect } from 'react-redux';
import {
  BL_TYPES, ROUTE_NAME_TARIFF_SIMULATION_RELEASE, RXFIELD_IMPORT_RELEASE_BL_TYPE,
} from '../../constant';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';
import TariffSimulationReleaseSearchBaseScreen from './tariff-simulation-release-search.presentation';
import {
  setTariffSimulationReleaseSearchSearchText,
  downloadingTariffSimulationReleaseSearch,
  downloadTariffSimulationReleaseSearchAsync,
  showErrorSnackbar,
  setTariffSimulationReleaseSearchText,
  addTariffSimulation,
  downloadTariffSimulationHostBLAsync,
  showHostBLCard,
  submitHostBlAsync,
} from '../../redux/action';

const mapStateToProps = state => ({
  notificationOpen: state.uiWebUserMain.notificationDrawerOpen,
  downloading: state.uiTariffSimulationReleaseSearch.downloading,
  searchBarText: state.uiTariffSimulationReleaseSearch.searchBarText,
  downloadingHostBL: state.uiTariffSimulationReleaseSearch.downloadingHostBL,
  hostBL: state.tariffSimulationHostBL,
  showHostBLCard: state.uiTariffSimulationReleaseSearch.showHostBLCard,
  submittingHostBL: state.uiTariffSimulationReleaseSearch.submittingHostBL,
  initialValues: {
    [RXFIELD_IMPORT_RELEASE_BL_TYPE]: BL_TYPES[0].value,
  },
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: () => {
    dispatch(setTariffSimulationReleaseSearchText(''));
    dispatch(showHostBLCard(false));
  },
  onChangeSearchBarText: (text) => {
    dispatch(setTariffSimulationReleaseSearchSearchText(text));
  },
  onSearchBlPressed: async (values) => {
    const { blNo, blType } = values;
    if (blType === BL_TYPES[0].value) {
      try {
        dispatch(downloadingTariffSimulationReleaseSearch(true));
        dispatch(setTariffSimulationReleaseSearchText(blNo));
        // dispatch(setTappedContainerTrackingBlNo(blNo));
        // dispatch(addTariffSimulation())
        dispatch(addTariffSimulation({}));
        await dispatch(downloadTariffSimulationReleaseSearchAsync(blNo));
        ownProps.history.push(ROUTE_NAME_TARIFF_SIMULATION_RELEASE);
      } catch (error) {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
        unauthorizedErrorHandler(error, ownProps.history.push);
      }
    } else {
      try {
        await dispatch(downloadTariffSimulationHostBLAsync(blNo));
      } catch (error) {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
        unauthorizedErrorHandler(error, ownProps.history.push);
      }
    }
  },
  onNextPressed: async (values) => {
    const { blNo, masterBl } = values;
    try {
      await dispatch(submitHostBlAsync(blNo, masterBl, ownProps));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TariffSimulationReleaseSearchBaseScreen);
