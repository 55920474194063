import { connect } from 'react-redux';
import MyPaymentBaseScreen from './my-payment.presentation';
import { setMyPaymentHistorySearchText, setMyPaymentSelectedTab, setPredefinedOrderSearchText } from '../../redux/action';
import { PAYMENT_TYPE_DEPOSIT, PAYMENT_TYPE_TERM, PAYMENT_TYPE_CASH } from '../../constant';

const getSelectedTab = (state) => {
  const { company } = state.currentUser;
  if (company && state.uiMyPayment.selectedTab === -1) {
    // userCompany && (userCompany.paymentMethod === PAYMENT_TYPE_CASH
    //   || userCompany.paymentMethod === PAYMENT_TYPE_TERM)

    if (company.paymentMethod === PAYMENT_TYPE_DEPOSIT) {
      return 2; // history tab
    }
    if (company.paymentMethod === PAYMENT_TYPE_TERM) {
      return 1;
    }
    if (company.paymentMethod === PAYMENT_TYPE_CASH) {
      return 0;
    }
  }

  return state.uiMyPayment.selectedTab;
};

const mapStateToProps = state => ({
  notificationOpen: state.uiWebUserMain.notificationDrawerOpen,
  selectedTab: state.uiMyPayment.selectedTab, // getSelectedTab(state),
  userCompany: state.currentUser.company,
  isDownloading: state.uiMyPayment.cancellingOutstandingOrderStatus,
});

const mapDispatchToProps = dispatch => ({
  onAppear: async (selectedTab) => {

  },
  onTabPressed: (selectedTab) => {
    if (selectedTab === 2) {
      dispatch(setMyPaymentHistorySearchText(''));
    }
    if (selectedTab === 3) {
      dispatch(setPredefinedOrderSearchText(''));
    }
    dispatch(setMyPaymentSelectedTab(selectedTab));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MyPaymentBaseScreen);
