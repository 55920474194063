import { searchingVesselList, addVesselList, setVoyagePicker } from '../simple-action';
import * as Helper from '../../../helper';

export default name => async (dispatch, getState) => {
  const { token } = getState().authentication;
  try {
    dispatch(searchingVesselList(true));
    const vesselList = await Helper.downloadVesselList(name, token);
    dispatch(addVesselList(vesselList));
    dispatch(setVoyagePicker(false));
  } finally {
    dispatch(searchingVesselList(false));
  }
};
