import * as Constant from '../../constant';
import * as Action from '../action';

const initialState = {
  downloadingTrucks: false,
  filters: {
    [Constant.TABLE_FIELD_MASTER_TRUCK_TRUCK_NO]: '',
    [Constant.TABLE_FIELD_MASTER_TRUCK_RFID]: '',
    [Constant.TABLE_FIELD_MASTER_TRUCK_PLATE_NUMBER]: '',
    [Constant.TABLE_FIELD_MASTER_TRUCK_VENDOR]: '',
    [Constant.TABLE_FIELD_MASTER_TRUCK_CID]: '',
    [Constant.TABLE_FIELD_MASTER_TRUCK_WEIGHT]: '',
  },
  sortField: '',
  sortDirection: '',
  activePage: 1,
  pageSize: 5,
  selectedTruckId: null,
  dialogAddStatus: false,
  dialogEditStatus: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.SET_MASTER_TRUCK_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.value,
        },
      };
    }
    case Action.SET_MASTER_TRUCK_PAGING: {
      return { ...state, ...action.value };
    }
    case Action.SET_MASTER_TRUCK_SORT: {
      return { ...state, sortField: action.sortField, sortDirection: action.sortDirection };
    }
    case Action.SET_MASTER_TRUCK_ADD_DIALOG_STATUS: {
      return { ...state, dialogAddStatus: action.value };
    }
    case Action.SET_MASTER_TRUCK_DETAIL_DIALOG_STATUS: {
      return { ...state, dialogEditStatus: action.value };
    }
    case Action.SET_MASTER_TRUCK_SELECTED_ID: {
      return { ...state, selectedTruckId: action.value };
    }
    case Action.DOWNLOADING_TRUCKS: {
      return { ...state, downloadingTrucks: action.value };
    }
    default: {
      return state;
    }
  }
};
