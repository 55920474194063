import * as Action from '../action';
import * as Constant from '../../constant';

const initialState = {
  downloading: false,
  downloadingPriceDetail: false,
  searchBarText: '',
  tappedContainerNo: '',
  filters: {
    [Constant.TABLE_FIELD_CONTAINER_NUMBER]: '',
    [Constant.TABLE_FIELD_SEAL_NUMBER]: '',
    [Constant.TABLE_FIELD_STATUS_HAULAGE]: '',
  },
  sortField: '',
  sortDirection: '',
  activePage: 1,
  pageSize: 5,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.DOWNLOADING_FEEDER_SERVICE_ORDER_DETAIL:
      return { ...state, downloading: action.status };
    case Action.DOWNLOADING_FEEDER_SERVICE_PRICE_DETAIL:
      return { ...state, downloadingPriceDetail: action.value };
    case Action.SET_FEEDER_SERVICE_DETAIL_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case Action.SET_FEEDER_SERVICE_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.value,
        },
      };
    }
    case Action.SET_FEEDER_SERVICE_PAGING: {
      return { ...state, ...action.value };
    }
    case Action.SET_FEEDER_SERVICE_SORT: {
      return { ...state, sortField: action.sortField, sortDirection: action.sortDirection };
    }
    case Action.SET_FEEDER_SERVICE_TAPPED_CONTAINER_NO: {
      return { ...state, tappedContainerNo: action.tappedContainerNo };
    }
    default: return state;
  }
};
