import uuid from 'uuid/v4';
import addToShoppingCartAsync from './addToShoppingCartAsync';
import * as Constant from '../../../constant';

export default exportOrderHistory => async (dispatch) => {
  const {
    blNo, totalPayment, blNumber, totalAmount,
  } = exportOrderHistory;
  const itemId = uuid();

  await dispatch(addToShoppingCartAsync(itemId,
    blNo || blNumber,
    Constant.CART_SERVICE_NAME_EXPORT,
    totalPayment || totalAmount || 0,
    exportOrderHistory));
};
