import { addUser, addCurrentUser } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const profile = await Helper.downloadMyProfile(token);

  if (profile) {
    const user = {
      ...profile,
    };
    dispatch(addUser(user));

    const currentUser = {
      id: profile.id,
      email: profile.email,
      phone: profile.phone,
      jobTitle: profile.jobTitle,
      role: profile.role,
      company: profile.company,
      corporateAdmin: profile.corporateAdmin,
      fullName: profile.fullName,
    };
    dispatch(addCurrentUser(currentUser));
  }
};
