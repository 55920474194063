import { PAYMENT_TYPE_DEPOSIT } from '../../../constant';
import { downloadMoneyLoanInfo } from '../../../helper';
import { downloadingMoneyLoanInfo, setMoneyLoanInfo } from '../simple-action';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { paymentMethod } = getState().currentUser.company;

  if (paymentMethod !== PAYMENT_TYPE_DEPOSIT) {
    return;
  }

  try {
    dispatch(downloadingMoneyLoanInfo(true));
    const info = await downloadMoneyLoanInfo(token);
    dispatch(setMoneyLoanInfo(info));
  } finally {
    dispatch(downloadingMoneyLoanInfo(false));
  }
};
