import * as Helper from '../../../helper';
import { addImage } from '../simple-action';

export default imageId => async (dispatch, getState) => {
  try {
    if (!imageId) { return; }

    const image = getState().images[imageId];
    if (image) { return; }

    const imageFromStorage = Helper.getImageFromStorage(imageId);
    if (imageFromStorage) {
      dispatch(addImage({ id: imageId, content: imageFromStorage }));
    }
  } catch (error) {
    // ignore error
  }
};
