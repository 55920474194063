import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  CssBaseline,
  Grid,
  Card,
  CardContent,
  Fab,
  Icon,
  Typography,
  withStyles,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Input,
  CardHeader,
  Select,
  MenuItem,
  ButtonBase,
} from '@material-ui/core';
import moment from 'moment';
import { DatePicker } from 'material-ui-pickers';
import * as Constant from '../../constant';
import { getSortIcon, isSortFieldActive } from '../../helper';
import LocalizedString from '../../localization';
import {
  PageHeaderUser, LoadingIndicator, PageHeaderAdmin, MainMenu,
} from '../../component';
import ContainerDetailDialog from './container-detail-dialog.container';

const styles = theme => ({
  alignRight: {
    textAlign: 'right',
  },
  backIcon: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  detailButton: {
    backgroundColor: '#ffffff',
  },
  searchButton: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  moreButton: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  cardItem: {
    border: '1px solid #E5E5E5',
    borderRadius: '6px',
    boxSizing: 'border-box',
    padding: '16px',
    marginBottom: theme.spacing.unit,
    '&:last-child': {
      marginBottom: 0,
    },
    transition: 'background-color 0.4s',
  },
  cardItemActive: {
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  cardItemContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: theme.spacing.unit * 2,
    maxBlockSize: '500px',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentMenuDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '256px',
      width: 'auto',
    },
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${Constant.DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  detailHeader: {
    color: '#0066CB',
  },
  itemPerRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    color: '#0266B4',
  },
  root: {
    width: '100%',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

const renderBlCard = (
  classes, billingLines, selectedBlNo, onMorePress,
) => {
  if (!billingLines) {
    return (<Typography>No BL available</Typography>);
  }

  return billingLines.map(item => (
    <ButtonBase
      component="div"
      key={item.blNo}
      className={classNames(classes.cardItem, {
        [classes.cardItemActive]: selectedBlNo && item.blNo === selectedBlNo,
      })}
      onClick={() => onMorePress(item.blNo)}
    >
      <Grid container spacing={24}>
        <Grid item xs={12} md={12}>
          <Typography color="primary" variant="h6">
            BL No.
            &nbsp;
            {item.blNo}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="caption">Gate In CDP</Typography>
          <Typography>
            {moment(item.gateInCdp).format(Constant.MOMENT_DATE_FORMAT_STRING)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} className={classes.alignRight}>
          <Typography variant="caption">Service</Typography>
          <Typography className={classes.capitalize}>{item.exim}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="caption">Vessel-voyage</Typography>
          <Typography>{`${item.vessel} - ${item.voyage}`}</Typography>
        </Grid>
        <Grid item xs={12} md={4} className={classes.alignRight}>
          <Typography variant="caption">Party</Typography>
          <Typography>{`1 - ${item.total}`}</Typography>
        </Grid>
      </Grid>
    </ButtonBase>
  ));
};

const renderBlDetail = (
  classes, activeBl, containerTracking,
  filters, sortField, sortDirection,
  pageSize, activePage, totalPage,
  onChangeFilter, onChangePaging, handleChangeSort,
  onDetailPress,
) => {
  if (!activeBl) {
    return (<></>);
  }

  return (
    <Card>
      <CardHeader
        title={(
          <>
            <Typography variant="h6" className={classes.detailHeader}>
              BL No.&nbsp;
              {activeBl.blNo}
            </Typography>
            <div className={classes.itemPerRowContainer}>
              <Select
                value={pageSize}
                onChange={event => onChangePaging(
                  Constant.RXTABLEFIELD_PAGE_SIZE,
                  event.target.value,
                )}
              >
                {Constant.DEFAULT_PAGE_SIZE_OPTIONS.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                    &nbsp;
                    {LocalizedString.common.labelRows}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </>
        )}
      />
      <CardContent>
        <div style={{ width: '100%', overflow: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={isSortFieldActive(sortField, Constant.TABLE_FIELD_CONTAINER_NUMBER)}
                    IconComponent={getSortIcon(
                      isSortFieldActive(sortField, Constant.TABLE_FIELD_CONTAINER_NUMBER),
                      sortDirection,
                    )}
                    onClick={() => handleChangeSort(Constant.TABLE_FIELD_CONTAINER_NUMBER)}
                  >
                    {LocalizedString.containerTracking.lblContainerNumber}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={isSortFieldActive(sortField, Constant.TABLE_FIELD_SERVICE)}
                    IconComponent={getSortIcon(
                      isSortFieldActive(sortField, Constant.TABLE_FIELD_SERVICE),
                      sortDirection,
                    )}
                    onClick={() => handleChangeSort(Constant.TABLE_FIELD_SERVICE)}
                  >
                    {LocalizedString.containerTracking.lblService}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={isSortFieldActive(sortField, Constant.TABLE_FIELD_SIZE)}
                    IconComponent={getSortIcon(
                      isSortFieldActive(sortField, Constant.TABLE_FIELD_SIZE),
                      sortDirection,
                    )}
                    onClick={() => handleChangeSort(Constant.TABLE_FIELD_SIZE)}
                  >
                    {LocalizedString.containerTracking.lblContainerSize}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={isSortFieldActive(sortField, Constant.TABLE_FIELD_GATE_IN_CDP)}
                    IconComponent={getSortIcon(
                      isSortFieldActive(sortField, Constant.TABLE_FIELD_GATE_IN_CDP),
                      sortDirection,
                    )}
                    onClick={() => handleChangeSort(Constant.TABLE_FIELD_GATE_IN_CDP)}
                  >
                    {LocalizedString.containerTracking.lblContainerGateIn}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  {LocalizedString.containerTracking.lblContainerParty}
                </TableCell>
                <TableCell>
                  Action(s)
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Input
                    type="text"
                    value={filters[Constant.TABLE_FIELD_CONTAINER_NUMBER]}
                    onChange={
                      ({ nativeEvent }) => onChangeFilter(
                        Constant.TABLE_FIELD_CONTAINER_NUMBER,
                        nativeEvent.target.value,
                      )
                    }
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <Input
                    type="text"
                    value={filters[Constant.TABLE_FIELD_SERVICE]}
                    onChange={
                      ({ nativeEvent }) => onChangeFilter(
                        Constant.TABLE_FIELD_SERVICE,
                        nativeEvent.target.value,
                      )
                    }
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <Input
                    type="number"
                    value={filters[Constant.TABLE_FIELD_SIZE]}
                    onChange={
                      ({ nativeEvent }) => onChangeFilter(
                        Constant.TABLE_FIELD_SIZE,
                        nativeEvent.target.value,
                      )
                    }
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  {/* <Input
                    type="date"
                    value={filters[Constant.TABLE_FIELD_GATE_IN_CDP]}
                    onChange={
                      ({ nativeEvent }) => onChangeFilter(
                        Constant.TABLE_FIELD_GATE_IN_CDP,
                        nativeEvent.target.value,
                      )
                    }
                    fullWidth
                  /> */}
                  <DatePicker
                    value={filters[Constant.TABLE_FIELD_GATE_IN_CDP] || null}
                    clearable
                    emptyLabel="DD MMM YYYY"
                    format="DD MMM YYYY"
                    onChange={value => onChangeFilter(
                      Constant.TABLE_FIELD_GATE_IN_CDP,
                      value,
                    )}
                  />
                </TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {containerTracking && containerTracking.map(container => (
                <TableRow key={container.containerNo} className={classes.tableRow}>
                  <TableCell>
                    <Typography>{container.containerNo}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.capitalize}>{activeBl.exim}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{container.ctrSize}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {container.gateInCdp
                        ? moment(container.gateInCdp).format(Constant.MOMENT_DATE_FORMAT_STRING)
                        : '-'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {container.party}
                      &nbsp;of&nbsp;
                      {container.total}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="primary"
                      classes={{ outlinedPrimary: classes.detailButton }}
                      onClick={() => onDetailPress(container.containerNo)}
                    >
                      Detail
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <div className={classes.tablePagination}>
          <Button
            variant="contained"
            style={{ flexGrow: 1 }}
            disabled={!activePage || activePage === 1}
            onClick={() => onChangePaging(Constant.RXTABLEFIELD_ACTIVE_PAGE, activePage - 1)}
          >
            {LocalizedString.common.labelPagingPrevButton}
          </Button>

          <div className={classes.tablePaginationPageInput}>
            <span style={{ fontWeight: 500 }}>{LocalizedString.common.labelPagingPage}</span>
            <Input
              type="number"
              value={activePage}
              style={{ width: '50%' }}
              inputProps={{
                min: 1,
                max: totalPage,
                step: 1,
                style: { textAlign: 'center' },
              }}
              onChange={({ nativeEvent }) => onChangePaging(
                Constant.RXTABLEFIELD_ACTIVE_PAGE,
                parseInt(nativeEvent.target.value, 10),
              )}
            />
            <span style={{ fontWeight: 500 }}>
              {`${LocalizedString.common.labelPagingPageOf} ${totalPage}`}
            </span>
          </div>

          <Button
            variant="contained"
            style={{ flexGrow: 1 }}
            disabled={activePage && activePage === totalPage}
            onClick={() => onChangePaging(Constant.RXTABLEFIELD_ACTIVE_PAGE, activePage + 1)}
          >
            {LocalizedString.common.labelPagingNextButton}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

class ContainerTrackingBase extends Component {
  componentDidMount() {
    this.props.onAppear();
  }

  handleChangeSort = (newSortField) => {
    const { sortField, sortDirection, onChangeSort } = this.props;
    if (newSortField === sortField) {
      onChangeSort(
        sortField,
        sortDirection === Constant.TABLE_SORT_ASCENDING
          ? Constant.TABLE_SORT_DESCENDING
          : Constant.TABLE_SORT_ASCENDING,
      );
    } else {
      onChangeSort(newSortField, Constant.TABLE_SORT_ASCENDING);
    }
  };

  render() {
    const {
      classes,
      menuOpen,
      downloadingContainerTrackingsData,
      billingLines,
      selectedBlNo,
      selectedContainerNo,
      activeBl,
      containerTracking,
      activePage,
      totalPage,
      filters,
      sortField,
      sortDirection,
      pageSize,
      onChangeSearchBarText,
      onDetailPress,
      onMorePress,
      onChangeFilter,
      onChangePaging,
      onCloseDetailDialogPressed,
    } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />

        <PageHeaderAdmin />
        <MainMenu />

        <div
          className={
            classNames(classes.content, {
              [classes.contentMenuDrawerOpen]: menuOpen,
            })
          }
          style={{ padding: '16px' }}
        >
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <div className={classes.pageNavigatorContainer}>
                    {/* <Fab
                      variant="round"
                      classes={{ root: classes.backIcon }}
                      component={Link}
                      to={Constant.ROUTE_NAME_DASHBOARD_USER}
                    >
                      <Icon>chevron_left</Icon>
                    </Fab> */}
                    {/* <div style={{ marginLeft: '32px' }}> */}
                    <div>
                      <Typography variant="h5" className={classes.pageTitle}>{LocalizedString.containerTracking.title}</Typography>
                      <Typography variant="caption">{LocalizedString.containerTracking.caption}</Typography>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <div style={{ display: 'flex' }}>
                    <div style={{ flexGrow: 1, alignSelf: 'flex-end' }}>
                      <TextField
                        fullWidth
                        placeholder="Search BL no"
                        onChange={event => onChangeSearchBarText(event.target.value)}
                      />
                    </div>
                    <div style={{ display: 'inline-flex', marginLeft: '8px' }}>
                      <Fab
                        size="small"
                        variant="round"
                        color="default"
                        className={classes.searchButton}
                      >
                        <Icon>search</Icon>
                      </Fab>
                    </div>
                  </div>

                  <div className={classes.cardItemContainer}>
                    {renderBlCard(classes, billingLines, selectedBlNo, onMorePress)}
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              {renderBlDetail(
                classes, activeBl, containerTracking,
                filters, sortField, sortDirection,
                pageSize, activePage, totalPage,
                onChangeFilter, onChangePaging, this.handleChangeSort,
                onDetailPress,
              )}
            </Grid>
          </Grid>
        </div>

        {downloadingContainerTrackingsData && <LoadingIndicator />}

        <ContainerDetailDialog
          open={Boolean(selectedContainerNo)}
          onClose={onCloseDetailDialogPressed}
        />
      </div>
    );
  }
}
ContainerTrackingBase.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  menuOpen: PropTypes.bool.isRequired,
  downloadingContainerTrackingsData: PropTypes.bool,
  billingLines: PropTypes.arrayOf(PropTypes.any),
  selectedBlNo: PropTypes.string.isRequired,
  selectedContainerNo: PropTypes.string.isRequired,
  containerTracking: PropTypes.arrayOf(PropTypes.any),
  activeBl: PropTypes.shape({
    id: PropTypes.string,
    containers: PropTypes.arrayOf(PropTypes.any),
  }),
  filters: PropTypes.shape(PropTypes.any).isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  activePage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onChangeSearchBarText: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onChangePaging: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onDetailPress: PropTypes.func.isRequired,
  onMorePress: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCloseDetailDialogPressed: PropTypes.func.isRequired,
};
ContainerTrackingBase.defaultProps = {
  downloadingContainerTrackingsData: true,
  billingLines: [],
  activeBl: undefined,
  containerTracking: [],
};

export default withStyles(styles)(ContainerTrackingBase);
