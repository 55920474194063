import { updatingPin } from '../simple-action';
import * as Constant from '../../../constant';
import * as Helper from '../../../helper';
import { validatePin } from '../../../validation';

export default (oldPin, newPin, navigateTo) => async (dispatch, getState) => {
  try {
    dispatch(updatingPin(true));
    validatePin(newPin);

    const { token } = getState().authentication;

    await Helper.updatePin(oldPin, newPin, token);

    navigateTo(Constant.ROUTE_NAME_PROFILE);
  } finally {
    dispatch(updatingPin(false));
  }
};
