import { connect } from 'react-redux';
import * as Action from '../../redux/action';
import SplashScreen from './splash.presentation';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    try {
      await dispatch(Action.checkAuthenticationStatusAsync(ownProps.history.push));
    } catch (error) {
      dispatch(Action.showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
});

export default connect(null, mapDispatchToProps)(SplashScreen);
