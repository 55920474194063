import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import LocalizedString from '../../localization';

const renderConfirmationButtons = (
  useDefaultButtonStyle, onOkPressed, onCancelPressed,
) => {
  if (useDefaultButtonStyle) {
    return (
      <DialogActions>
        <Button
          color="primary"
          onClick={onOkPressed}
        >
          {LocalizedString.common.buttonCaptionYes}
        </Button>
      &emsp;&emsp;
        <Button
          variant="contained"
          color="primary"
          onClick={onCancelPressed}
        >
          {LocalizedString.common.buttonCaptionCancel}
        </Button>
      </DialogActions>
    );
  }
  return (
    <DialogActions>
      <Button
        color="primary"
        onClick={onCancelPressed}
      >
        {LocalizedString.common.buttonCaptionCancel}
      </Button>
    &emsp;&emsp;
      <Button
        variant="contained"
        color="primary"
        onClick={onOkPressed}
      >
        {LocalizedString.common.buttonCaptionYes}
      </Button>
    </DialogActions>
  );
};

const ConfirmationDialog = ({
  open,
  title,
  message,
  onOkPressed,
  onCancelPressed,
  useDefaultButtonStyle,
}) => (
  <Dialog
    open={open}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      {message}
    </DialogContent>
    {renderConfirmationButtons(
      useDefaultButtonStyle, onOkPressed, onCancelPressed,
    )}
  </Dialog>
);
ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  useDefaultButtonStyle: PropTypes.bool,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onOkPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
};

ConfirmationDialog.defaultProps = {
  useDefaultButtonStyle: true,
};

export default ConfirmationDialog;
