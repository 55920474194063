import { connect } from 'react-redux';
import Snackbar from './snackbar.presentation';
import { hideSnackbar } from '../../redux/action';

const mapStateToProps = state => ({
  openSnackbarStatus: state.uiSnackbar.openSnackbarStatus,
  title: state.uiSnackbar.title,
  message: state.uiSnackbar.message,
  type: state.uiSnackbar.type,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => {
    dispatch(hideSnackbar());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
