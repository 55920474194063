import { downloadingErpPaymentLogDetail, addErpPaymentLogDetail, showErpPaymentLogDetail } from '../simple-action';
import * as Helper from '../../../helper';

export default id => async (dispatch, getState) => {
  const { token } = getState().authentication;
  try {
    dispatch(downloadingErpPaymentLogDetail(true));
    const erpPaymentLogDetail = await Helper.downloadErpPaymentLogDetail(id, token);
    dispatch(addErpPaymentLogDetail(erpPaymentLogDetail));
    dispatch(showErpPaymentLogDetail(true));
  } finally {
    dispatch(downloadingErpPaymentLogDetail(false));
  }
};
