import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  Typography,
  Icon,
  Button,
  DialogActions,
  TextField,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import { LoadingIndicator } from '../../component';
import LocalizedString from '../../localization';
import {
  DEFAULT_STYLES,
  RXFIELD_ADMIN_PAYMENT_BL_NO,
  RXFIELD_ADMIN_PAYMENT_INVOICE_NO,
  RXFIELD_ADMIN_PAYMENT_BILL_TO,
  RXFIELD_ADMIN_PAYMENT_PAYMENT_OWNER,
  RXFORM_ADD_ADMIN_PAYMENT,
  PAYMENT_TYPE_DEPOSIT,
} from '../../constant';
import {
  renderReduxFormTextField,
  renderReduxFormFilterableSelectAsyncField,
  renderReduxFormFilterableSelectField,
} from '../../helper';

const styles = () => ({
  btnPrimary: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  dialogActions: {
    margin: '0 -24px',
    marginTop: '24px',
    padding: 24,
    borderTop: '1px solid #C4C4C4',
    justifyContent: 'space-between',
    paddingBottom: 0,
    display: 'flex',
  },
  btnAddToCartContainer: {
    margin: '0 -24px',
    padding: 24,
    justifyContent: 'center',
    display: 'flex',
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
});

const PaymentAddDialog = ({
  classes,
  open,
  loading,

  bl,
  blOptions,
  paymentOwners,
  lstPaymentOwner,

  cartItem,
  paymentData,
  dialogPinInputStatus,

  pin,

  initialValues,
  initialize,
  handleSubmit,
  onSearchTextChange,
  onSelectedBlChange,
  onSelectedPaymentOwnerChange,
  onPinTextChange,
  onPinCancelPressed,
  onPinSubmitPressed,
  onSubmitPressed,
  onAppear,
  onClose,
  onDeleteCartItemPressed,
  onProcessPaymentPressed,
}) => {
  const handleOnDialogEnter = () => {
    initialize(initialValues);
    onAppear();
  };

  const onSubmitHandler = (values) => {
    onSubmitPressed(values, paymentData);
  };

  const renderPinInputDialogContent = () => {
    if (!cartItem || cartItem.length === 0 || !cartItem[0].billToPaymentMethod) return null;

    if (cartItem[0].billToPaymentMethod.toUpperCase() === PAYMENT_TYPE_DEPOSIT
      && cartItem[0].allowPpjkToUseDeposit) {
      return (
        <>
          <Typography className={classes.paymentConfirmTitle}>
            {`${LocalizedString.cart.msgEnterPinAllowPPJKTrue} ${cartItem[0].billTo}`}
          </Typography>
          <TextField
            type="password"
            label="PIN"
            value={pin}
            fullWidth
            InputProps={{
              endAdornment: (<InputAdornment position="end"><Icon color="disabled">lock</Icon></InputAdornment>),
            }}
            onChange={({ nativeEvent }) => { onPinTextChange(nativeEvent.target.value); }}
          />
        </>
      );
    }

    return (
      <>
        <Typography className={classes.paymentConfirmTitle}>
          {`${LocalizedString.cart.msgEnterPinAllowPPJKTrue} ${cartItem[0].paymentOwner}`}
        </Typography>
        <TextField
          type="password"
          label="PIN"
          value={pin}
          fullWidth
          InputProps={{
            endAdornment: (<InputAdornment position="end"><Icon color="disabled">lock</Icon></InputAdornment>),
          }}
          onChange={({ nativeEvent }) => { onPinTextChange(nativeEvent.target.value); }}
        />
      </>
    );
  };

  const renderTableCellHeader = label => (
    <TableCell>
      {label}
    </TableCell>
  );

  const renderTableCellBody = (text, className) => (
    <TableCell className={className}>
      <Typography>
        {text || '-'}
      </Typography>
    </TableCell>
  );

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onEnter={() => handleOnDialogEnter()}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
            }}
          >
            <Typography color="primary" variant="h6">
              {LocalizedString.adminPaymentHistoryAdd.title}
            </Typography>
            <IconButton onClick={onClose}>
              <Icon>close</Icon>
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Field
              name="keyword"
              component={renderReduxFormFilterableSelectAsyncField}
              options={blOptions}
              disableDefaultOptions
              onLazyLoad={onSearchTextChange}
              onValueChange={value => onSelectedBlChange(value, bl)}
              label={LocalizedString.adminPaymentHistoryAdd.labelSearch}
            />
            <Field
              name={RXFIELD_ADMIN_PAYMENT_BL_NO}
              component={renderReduxFormTextField}
              label={LocalizedString.adminPaymentHistoryAdd.labelBlNo}
              disabled
            />
            <Field
              name={RXFIELD_ADMIN_PAYMENT_INVOICE_NO}
              component={renderReduxFormTextField}
              label={LocalizedString.adminPaymentHistoryAdd.labelInvoiceNo}
              disabled
            />
            <Field
              name={RXFIELD_ADMIN_PAYMENT_BILL_TO}
              component={renderReduxFormTextField}
              label={LocalizedString.adminPaymentHistoryAdd.labelBillto}
              disabled
            />
            <Field
              name={RXFIELD_ADMIN_PAYMENT_PAYMENT_OWNER}
              component={renderReduxFormFilterableSelectField}
              label={LocalizedString.adminPaymentHistoryAdd.labelPaymentOwner}
              onValueChange={value => onSelectedPaymentOwnerChange(value, paymentOwners)}
              required
              options={lstPaymentOwner}
            />

            <div className={classes.btnAddToCartContainer}>
              <div style={{ flexGrow: 1 }} />
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  Add to Cart
                </Button>
              </div>
            </div>

            <div style={{ width: '100%', overflow: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {renderTableCellHeader('BL No')}
                    {renderTableCellHeader('Invoice No')}
                    {renderTableCellHeader('Bill To')}
                    {renderTableCellHeader('Payment Owner')}
                    {renderTableCellHeader('Action(s)')}
                  </TableRow>
                </TableHead>
                <TableBody>
                  { cartItem && cartItem.map(item => (
                    <TableRow>
                      {renderTableCellBody(item.blNo)}
                      {renderTableCellBody(item.invoiceNo)}
                      {renderTableCellBody(item.billTo)}
                      {renderTableCellBody(item.paymentOwner)}
                      <TableCell>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => onDeleteCartItemPressed(item.invoiceNo)}
                        >
                          {LocalizedString.common.buttonCaptionDelete}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )) }
                </TableBody>
              </Table>
            </div>

            <div className={classes.dialogActions}>
              <div style={{ flexGrow: 1 }} />
              <div>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.btnPrimary}
                  disabled={loading || cartItem.length === 0}
                  onClick={() => onProcessPaymentPressed(cartItem)}
                >
                  Process
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={dialogPinInputStatus}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          {renderPinInputDialogContent()}

        </DialogContent>
        <DialogActions className={classes.centeredActions}>
          <Button
            color="primary"
            onClick={() => onPinCancelPressed()}
          >
            {LocalizedString.common.buttonCaptionCancel}
          </Button>
          &emsp;&emsp;
          <Button
            variant="contained"
            color="primary"
            className={classes.btnSubmit}
            onClick={() => onPinSubmitPressed(pin)}
          >
            {LocalizedString.common.buttonCaptionSubmit}
          </Button>
        </DialogActions>
      </Dialog>
      {loading && <LoadingIndicator />}
    </>
  );
};
PaymentAddDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  bl: PropTypes.shape(PropTypes.any).isRequired,
  blOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  paymentOwners: PropTypes.shape(PropTypes.any).isRequired,
  lstPaymentOwner: PropTypes.arrayOf(PropTypes.any).isRequired,
  paymentData: PropTypes.shape(PropTypes.any).isRequired,
  cartItem: PropTypes.shape(PropTypes.any).isRequired,

  dialogPinInputStatus: PropTypes.bool.isRequired,

  pin: PropTypes.string.isRequired,

  initialValues: PropTypes.shape(PropTypes.any).isRequired,
  initialize: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  onAppear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onSearchTextChange: PropTypes.func.isRequired,
  onSelectedBlChange: PropTypes.func.isRequired,
  onSelectedPaymentOwnerChange: PropTypes.func.isRequired,
  onPinTextChange: PropTypes.func.isRequired,
  onPinCancelPressed: PropTypes.func.isRequired,
  onPinSubmitPressed: PropTypes.func.isRequired,
  onDeleteCartItemPressed: PropTypes.func.isRequired,
  onProcessPaymentPressed: PropTypes.func.isRequired,
};

export default reduxForm({
  form: RXFORM_ADD_ADMIN_PAYMENT,
  enableReinitialize: true,
})(withStyles(styles)(PaymentAddDialog));
