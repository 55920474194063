import * as Helper from '../../../helper';
import * as Action from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(Action.downloadingTrucks(true));
    // const { token } = getState().authentication;
    // const trucks = await Helper.downloadAllForwarders(token);
    // trucks.forEach((fwd) => {
    //   dispatch(Action.addForwarder(fwd));
    // });

    const dummyTrucks = [
      {
        id: 'B9420UYW',
        truckNo: 'B9420UYW',
        rfid: '34567823',
        licensePlateNumber: 'B9420UYW',
        vendorId: 'ACE',
        cid: '11717',
        weight: '100',
      },
      {
        id: 'B2222CUJ',
        truckNo: 'B2222CUJ',
        rfid: '',
        licensePlateNumber: 'B2222CUJ',
        vendorId: 'CKD',
        cid: '11608',
        weight: '100',
      },
    ];
    dispatch(Action.removeAllTrucks());
    dummyTrucks.forEach((fwd) => {
      dispatch(Action.addTruck(fwd));
    });
  } finally {
    dispatch(Action.downloadingTrucks(false));
  }
};
