import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  withStyles,
  Icon,
  DialogContent,
  Tabs,
  Tab,
} from '@material-ui/core';
import LocalizedString from '../../localization';
import { LoadingIndicator } from '../../component';
import { renderErrorMessage } from '../../helper';
import FeederTab from './feeder.container';
import PartyTab from './party.container';
import PriceTab from './price.container';

const styles = theme => ({
  dialogActions: {
    margin: 0,
    padding: theme.spacing.unit * 3,
    borderTop: '1px solid #C4C4C4',
    justifyContent: 'space-between',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tabIndicator: {
    display: 'none',
  },
  tabRoot: {
    marginTop: theme.spacing.unit,
    overflow: 'visible',
  },
  tabElement: {
    backgroundColor: 'rgba(200, 200, 200, 0.2)',
    textTransform: 'capitalize',
  },
  tabElementActive: {
    backgroundColor: '#3f51b5',
    color: '#ffffff !important',
    transform: 'scaleY(1.1)',
    borderRadius: '4px',
    boxShadow: '0px 4px 20px rgba(0, 102, 203, 0.45)',
  },
  tabScroller: {
    overflowX: 'visible',
  },
});

const BookNewDialog = ({
  classes,
  error,
  open,
  selectedTab,
  uploadingNewBooking,
  onClose,
}) => (
  <>
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <div className={classes.dialogHeader}>
          <Typography color="primary" variant="h6">
            {LocalizedString.bookingFeeder.title}
            &nbsp;
          </Typography>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>

        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          classes={{
            root: classes.tabRoot,
            indicator: classes.tabIndicator,
            scroller: classes.tabScroller,
          }}
          fullWidth
          centered
        >
          <Tab
            label={LocalizedString.bookExport.labelTabFeeder}
            classes={{
              root: classes.tabElement,
              selected: classes.tabElementActive,
            }}
            disabled={selectedTab !== 0}
          />
          <Tab
            label={LocalizedString.bookExport.labelTabParty}
            classes={{
              root: classes.tabElement,
              selected: classes.tabElementActive,
            }}
            disabled={selectedTab !== 1}
          />
          <Tab
            label={LocalizedString.bookExport.labelTabPrice}
            classes={{
              root: classes.tabElement,
              selected: classes.tabElementActive,
            }}
            disabled={selectedTab !== 2}
          />
        </Tabs>

        {error && renderErrorMessage(error)}
      </DialogTitle>
      <DialogContent>
        {selectedTab === 0 && <FeederTab />}
        {selectedTab === 1 && <PartyTab />}
        {selectedTab === 2 && <PriceTab />}
      </DialogContent>
    </Dialog>
    {uploadingNewBooking && <LoadingIndicator />}
  </>
);
BookNewDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  error: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  selectedTab: PropTypes.number.isRequired,
  uploadingNewBooking: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default withStyles(styles)(BookNewDialog);
