import {
  submittingResetPassword, addForgetPasswordInfo, addToken,
  downloadMyProfileAsync, downloadProfilePictureAsync, removeForgetPasswordInfo,
} from '..';
import * as Helper from '../../../helper';
import * as Constant from '../../../constant';

export default (password, navigateTo) => async (dispatch, getState) => {
  try {
    dispatch(submittingResetPassword(true));
    const stateInfo = { password };
    dispatch(addForgetPasswordInfo(stateInfo));

    const { email, pin } = getState().forgetPasswordInfo;
    const response = await Helper.resetPassword(email, pin, password);

    dispatch(removeForgetPasswordInfo());
    dispatch(addToken(response.token));

    await dispatch(downloadMyProfileAsync());
    const { id } = getState().currentUser;
    await dispatch(downloadProfilePictureAsync(id));

    navigateTo(Constant.ROUTE_NAME_SPLASH);
  } finally {
    dispatch(submittingResetPassword(false));
  }
};
