import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Checkbox, CircularProgress, Dialog, DialogActions,
  DialogContent, DialogTitle, Icon, InputAdornment, TextField, Typography, withStyles,
} from '@material-ui/core';
import LocalizedString from '../../localization';

const styles = () => ({
  pinContainer: {
    margin: '16px',
  },
  circularProgress: {
    height: '24px !important',
    width: '24px !important',
  },
});

const getTncLabel = tnc => tnc.split('\n').map(string => <p>{string}</p>);

const MoneyLoanTncDialog = ({
  visibility, tnc, downloading, submitting, classes,
  onAppear, onSubmitPressed, onCancelPressed,
}) => {
  const [checked, setChecked] = useState(false);
  const [pin, setPin] = useState('');
  useEffect(() => {
    if (visibility) {
      onAppear();
    }
  }, [visibility]);
  return (
    <Dialog open={visibility}>
      <DialogTitle>{LocalizedString.checkout.dialogTncTitle}</DialogTitle>
      <DialogContent>
        {downloading ? (
          <CircularProgress className={classes.circularProgress} />
        ) : getTncLabel(tnc)}
      </DialogContent>
      <div>
        <Checkbox
          checked={checked}
          color="primary"
          onClick={() => setChecked(!checked)}
        />
        {LocalizedString.common.labelIAgreeTnc}
      </div>

      {checked && (
        <div className={classes.pinContainer}>
          <Typography className={classes.paymentConfirmTitle}>
            {LocalizedString.checkout.labelEnterPin}
          </Typography>
          <TextField
            type="password"
            label={LocalizedString.checkout.labelPin}
            value={pin}
            fullWidth
            InputProps={{
              endAdornment: (<InputAdornment position="end"><Icon color="disabled">lock</Icon></InputAdornment>),
            }}
            onChange={({ nativeEvent }) => { setPin(nativeEvent.target.value); }}
          />
        </div>
      )}

      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            onCancelPressed();
            setChecked(false);
            setPin('');
          }}
          disabled={submitting}
        >
          {LocalizedString.common.buttonCaptionCancel}
        </Button>
      &emsp;&emsp;
        <Button
          variant={submitting ? 'none' : 'contained'}
          color="primary"
          onClick={() => onSubmitPressed(pin)}
          disabled={!checked || pin.length < 6}
        >
          {submitting ? (
            <CircularProgress className={classes.circularProgress} />
          ) : LocalizedString.common.buttonCaptionSubmit}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(MoneyLoanTncDialog);

MoneyLoanTncDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  onAppear: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  visibility: PropTypes.bool,
  downloading: PropTypes.bool,
  submitting: PropTypes.bool,
  tnc: PropTypes.string,
};

MoneyLoanTncDialog.defaultProps = {
  downloading: false,
  submitting: false,
  visibility: false,
  tnc: '',
};
