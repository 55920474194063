import { TABLE_SORT_ASCENDING } from '../../constant';
import {
  SET_ADMIN_PAYMENT_HISTORY_DETAIL_SORT,
  SET_ADMIN_PAYMENT_HISTORY_DETAIL_PAGING,
  SET_ADMIN_PAYMENT_HISTORY_DETAIL_FILTER,
  CLEAR_ADMIN_PAYMENT_HISTORY_DETAIL_STATE,
} from '../action';

const initialState = {
  downloading: false,

  filters: {
  },
  sortField: 'blNumber',
  sortDirection: TABLE_SORT_ASCENDING,
  activePage: 1,
  pageSize: 5,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ADMIN_PAYMENT_HISTORY_DETAIL_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.value,
        },
      };
    }
    case SET_ADMIN_PAYMENT_HISTORY_DETAIL_PAGING: {
      return { ...state, ...action.value };
    }
    case SET_ADMIN_PAYMENT_HISTORY_DETAIL_SORT: {
      return { ...state, sortField: action.sortField, sortDirection: action.sortDirection };
    }
    case CLEAR_ADMIN_PAYMENT_HISTORY_DETAIL_STATE: {
      return initialState;
    }
    default: return state;
  }
};
