import {
  setTappedPdfViewerTitle,
  setPdfViewerSourceUrl,
} from '../simple-action';
import { downloadProformaInvoicePrintId } from '../../../helper';
import { URL_PRINT } from '../../../constant';
import LocalizedString from '../../../localization';

export default (preInv, isPredefinedOrder = false) => async (dispatch, getState) => {
  const { token } = getState().authentication;
  try {
    const id = await downloadProformaInvoicePrintId(token, preInv, isPredefinedOrder);
    const url = URL_PRINT.replace(/\{id\}/, id);

    dispatch(setTappedPdfViewerTitle(LocalizedString.pdfViewerScreen.titleProformaInvoice));
    dispatch(setPdfViewerSourceUrl(url));
  } finally {
  }
};
