import * as Action from '../action';

const initialState = {
  downloading: false,
  searchBarText: '',
  tappedBlNo: '',
  addingItem: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.SET_TAPPED_TERM_BL_NO:
      return { ...state, tappedBlNo: action.tappedBlNo };
    case Action.SET_MY_PAYMENT_TERM_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case Action.ADDING_MY_PAYMENT_TERM_ORDER_ITEM:
      return { ...state, addingItem: action.status };
    case Action.DOWNLOADING_MY_PAYMENT_TERM_ORDER:
      return { ...state, downloading: action.status };
    default:
      return state;
  }
};
