import * as Helper from '../../../helper';
import { validateAssignClearance } from '../../../validation';
import { uploadingAssignClearance, addAssignedBl } from '../simple-action';
import { CART_SERVICE_NAME_EXPORT } from '../../../constant';

export default assignClearance => async (dispatch, getState) => {
  try {
    dispatch(uploadingAssignClearance(true));

    const {
      soNo, blNo, forwarderId, truckerId,
    } = assignClearance;
    const blInfo = getState().assign[blNo];

    validateAssignClearance(assignClearance,
      blInfo.exim.toLowerCase() === CART_SERVICE_NAME_EXPORT.toLowerCase());

    const { token } = getState().authentication;
    const data = {
      blNo, soNo, forwarderId, truckerId,
    };
    await Helper.uploadAssignClearance(data, token);

    const { exim, billTo } = getState().uiAssignClearance.assignClearanceForm;
    const assignedBl = {
      soNo, blNo, forwarderId, exim, billTo,
    };
    dispatch(addAssignedBl(assignedBl));
  } finally {
    dispatch(uploadingAssignClearance(false));
  }
};
