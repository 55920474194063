import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  withStyles,
  AppBar,
  Toolbar,
  Icon,
  IconButton,
} from '@material-ui/core';
import * as Constant from '../../constant';
import { LoadingIndicator, MySnackbar } from '..';

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  cartDisabled: {
    borderRadius: 32,
    backgroundColor: 'rgba(80, 171, 241, 0.2) !important',
    color: '#0066CB !important',
    boxShadow: 'none',
  },
  drawerContentMarginTop: {
    marginTop: 0,
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing.unit * 8,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing.unit * 8,
    },
  },
  drawerRoot: {
    [theme.breakpoints.up('md')]: {
      width: Constant.DRAWER_NOTIFICATION_WIDTH,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  grow: {
    flexGrow: 1,
  },
  link: {
    textDecoration: 'none',
    marginLeft: theme.spacing.unit * 4,
  },
  spacing: {
    marginLeft: theme.spacing.unit * 4,
  },
  toolbar: {
    backgroundColor: 'white',
  },
});

const PageHeaderUser = (props) => {
  const {
    classes,
    menuOpen,
    loggingOut,
    onToggleMenuPressed,
    onLogoutButtonPressed,
  } = props;

  return (
    <>
      {loggingOut && <LoadingIndicator />}

      <AppBar position="sticky" elevation={1} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <img src={Constant.IMAGE_LOGO_CDP_2} alt="logo" />
          <IconButton
            size="small"
            onClick={() => onToggleMenuPressed(!menuOpen)}
            style={{ marginLeft: '24px' }}
          >
            <Icon>menu</Icon>
          </IconButton>
          <div className={classes.grow} />
          <div>
            <IconButton
              onClick={onLogoutButtonPressed}
            >
              <Icon style={{ fontSize: '24pt' }}>exit_to_app</Icon>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <MySnackbar />
    </>
  );
};
PageHeaderUser.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  menuOpen: PropTypes.bool.isRequired,
  loggingOut: PropTypes.bool.isRequired,
  onLogoutButtonPressed: PropTypes.func.isRequired,
  onToggleMenuPressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(PageHeaderUser);
