import { connect } from 'react-redux';
import _ from 'lodash';
import TruckEditDialog from './truck-edit-dialog.presentation';
import { clearUIError, setUIError, downloadAllVendorAsync } from '../../redux/action';
import { RXSTATE_MASTER_TRUCKING } from '../../constant';

const getTruckDetail = (state) => {
  const { trucks } = state;
  const { selectedTruckId } = state.uiMasterTruck;
  return trucks[selectedTruckId] || {};
};

const getInitialValues = state => getTruckDetail(state);

const mapStateToProps = state => ({
  truck: getTruckDetail(state),
  initialValues: getInitialValues(state),
  loading: false,
  vendors: _.values(state.vendors).map(vendor => ({ label: vendor.name, value: vendor.id })),
});

const mapDispatchToProps = dispatch => ({
  onAppear: async () => {
    try {
      dispatch(clearUIError(RXSTATE_MASTER_TRUCKING));
      await dispatch(downloadAllVendorAsync());
    } catch (error) {
      dispatch(
        setUIError(RXSTATE_MASTER_TRUCKING, error.message),
      );
    }
  },
  onSubmitPressed: (values) => { },

});

export default connect(mapStateToProps, mapDispatchToProps)(TruckEditDialog);
