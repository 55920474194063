import { connect } from 'react-redux';
import OrderListExportInPage from './order-list-export-in.presentation';
import {
  setOrderListExportInFilter,
  setOrderListExportInPaging,
  setOrderListExportInSort,
} from '../../redux/action';
import * as Constant from '../../constant';

const getFilteredOrderList = (state) => {
  const filteredOrderList = [{
    id: 1,
    soNo: '515539.2ES',
    blNo: 'JKTU82136400',
    billTo: 'HPPM',
  }, {
    id: 2,
    soNo: '515538.2ES',
    blNo: 'JKTU86735600',
    billTo: 'HPPM',
  }, {
    id: 3,
    soNo: '515477.2ES',
    blNo: '580250436',
    billTo: 'IEI',
  }];

  const {
    filters, sortField, sortDirection,
  } = state.uiOrderListExportIn;

  return filteredOrderList.sort((left, right) => {
    if (typeof left[sortField] === 'string') {
      if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
        return left[sortField].localeCompare(right[sortField]);
      }
      return right[sortField].localeCompare(left[sortField]);
    }

    if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
      return left[sortField] - right[sortField];
    }
    return right[sortField] - left[sortField];
  })
    .filter(item => item.billTo.toUpperCase().indexOf(
      filters[Constant.TABLE_FIELD_ORDER_LIST_BILL_TO].toUpperCase(),
    ) >= 0)
    .filter(item => item.blNo.toUpperCase().indexOf(
      filters[Constant.TABLE_FIELD_ORDER_LIST_BL_NO].toUpperCase(),
    ) >= 0);
};

const getPageFilteredOrderList = (state) => {
  const {
    activePage, pageSize,
  } = state.uiOrderListExportIn;
  const filteredOrderList = getFilteredOrderList(state);

  if (filteredOrderList) {
    const refinedActivePage = activePage || 1;
    const start = Math.min((refinedActivePage - 1) * pageSize,
      filteredOrderList.length);
    const end = Math.min(start + pageSize, filteredOrderList.length);
    return filteredOrderList.slice(start, end);
  }

  return [];
};

const getTotalPage = (state) => {
  const filteredOrderList = getFilteredOrderList(state);
  const { pageSize } = state.uiOrderListExportIn;
  const totalData = filteredOrderList ? filteredOrderList.length : 0;

  return Math.max(Math.ceil(totalData / pageSize), 1);
};

const mapStateToProps = state => ({
  menuOpen: state.uiMenu.menuOpen,
  orders: getPageFilteredOrderList(state),
  filters: state.uiOrderListExportIn.filters,
  activePage: state.uiOrderListExportIn.activePage,
  pageSize: state.uiOrderListExportIn.pageSize,
  totalPage: getTotalPage(state),
  sortField: state.uiOrderListExportIn.sortField,
  sortDirection: state.uiOrderListExportIn.sortDirection,
});

const mapDispatchToProps = dispatch => ({
  onChangeFilter: (filterName, value) => {
    dispatch(setOrderListExportInFilter(filterName, value));
  },
  onChangePaging: (pageField, value) => {
    dispatch(setOrderListExportInPaging(pageField, value));
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setOrderListExportInSort(sortField, sortDirection));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderListExportInPage);
