import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PriceTab from './price.presentation';
import {
  setBookFeederActiveForm,
  setUIError,
  removeBookFeederServiceinfo,
  downloadFeederServiceOrderHistoriesAsync,
  setNewBookingDialogStatus,
  showSnackbar,
  showErrorSnackbar,
} from '../../redux/action';
import { RXSTATE_FEEDER_SERVICE } from '../../constant';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const mapStateToProps = state => ({
  nilTotal: state.bookFeeder.priceInfo.totalPayment,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onPreviousPressed: () => {
    dispatch(setBookFeederActiveForm(1));
  },
  onNextPressed: async () => {
    try {
      dispatch(setNewBookingDialogStatus(false));
      dispatch(setBookFeederActiveForm(0));
      dispatch(removeBookFeederServiceinfo());
      await dispatch(downloadFeederServiceOrderHistoriesAsync());
      dispatch(showSnackbar(LocalizedString.common.labelSuccess,
        LocalizedString.trucking.ntfBookingSuccess));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PriceTab));
