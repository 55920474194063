import { downloadingAdminPayableBl, setAdminPayableBl } from '../simple-action';
import { downloadAdminPayableBl } from '../../../helper';

/** This function returns the result instead of store it to redux store */
export default searchTerm => async (dispatch, getState) => {
  try {
    dispatch(downloadingAdminPayableBl(true));

    const { token } = getState().authentication;

    const data = await downloadAdminPayableBl(searchTerm, token);
    await dispatch(setAdminPayableBl(data));
    return data;
  } finally {
    dispatch(downloadingAdminPayableBl(false));
  }
};
