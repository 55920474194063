import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  withStyles,
  Icon,
  DialogContent,
  Button,
  TextField,
} from '@material-ui/core';
import {
  setPdfViewerSourceUrl,
  setTappedPdfViewerTitle,
  setPdfViewerStatus,
} from '../redux/action';
import LocalizedString from '../localization';
import { DEFAULT_STYLES, URL_TOP_UP_GUIDE } from '../constant';

const styles = () => ({
  btnPrimary: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  italic: {
    fontStyle: 'italic',
  },
  separator: {
    height: '1px',
    marginTop: 12,
    marginBottom: 12,
    // borderBottom: '1px solid #EEEEEE',
  },
});

class TopUpDialog extends Component {
  onCopyPressed = (ref) => {
    ref.current.select();
    document.execCommand('copy');
  };

  render() {
    const {
      classes,
      open,
      vaNumber,
      onClose,
      onGuidePressed,
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          <div className={classes.dialogHeader}>
            <Typography color="primary" variant="h6">
              {LocalizedString.depositHistory.buttonCaptionTopUp}
              &nbsp;
            </Typography>
            <IconButton onClick={onClose}>
              <Icon>close</Icon>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2">{LocalizedString.depositHistory.labelVaNumber}</Typography>
          { vaNumber && vaNumber.map((va) => {
            const ref = React.createRef();
            return (
              <div style={{ marginTop: '16px' }}>
                <div style={{ display: 'flex' }}>
                  <TextField
                    fullWidth
                    inputRef={ref}
                    value={va.vaNumber || '-'}
                    label={va.bankName}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.btnPrimary}
                    onClick={() => this.onCopyPressed(ref)}
                  >
                    Copy
                  </Button>
                </div>
              </div>
            );
          })}

          <div className={classes.separator} />

          <Typography variant="caption" className={classes.italic}>
            {LocalizedString.depositHistory.labelTopUpInfo}
          </Typography>

          <div className={classes.separator} />

          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={onGuidePressed}
          >
            {LocalizedString.depositHistory.buttonCaptionGuide}
          </Button>
        </DialogContent>
      </Dialog>
    );
  }
}
TopUpDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  open: PropTypes.bool.isRequired,
  vaNumber: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onGuidePressed: PropTypes.func.isRequired,
};
const TopUpDialogWithStyles = withStyles(styles)(TopUpDialog);

const mapStateToProps = state => ({
  vaNumber: state.currentUser.company.depositAccount
    ? state.currentUser.company.depositAccount.vaNumbers
    : '',
});
const mapDispatchToProps = dispatch => ({
  onGuidePressed: () => {
    dispatch(setPdfViewerSourceUrl(URL_TOP_UP_GUIDE));
    dispatch(setTappedPdfViewerTitle(LocalizedString.depositHistory.buttonCaptionGuide));
    dispatch(setPdfViewerStatus(true));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(TopUpDialogWithStyles);
