import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  InputAdornment,
  Icon,
} from '@material-ui/core';
import LocalizedString from '../../localization';
import { DEFAULT_STYLES } from '../../constant';

const styles = () => ({
  btnSubmit: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  centeredActions: {
    justifyContent: 'center',
    paddingBottom: '24px',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  errorMessage: {
    marginTop: 8,
    color: '#ff0000',
  },
  paymentConfirmTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '16px',
  },
});

const PasswordInputDialog = ({
  classes,
  open,

  password,
  error,

  onCancelPressed,
  onSubmitPressed,
  onChangePasswordText,
}) => (
  <Dialog
    open={open}
    fullWidth
    maxWidth="xs"
  >
    <DialogContent>
      <Typography className={classes.paymentConfirmTitle}>
        Please enter your login password
      </Typography>
      <TextField
        type="password"
        label="Password"
        value={password}
        fullWidth
        InputProps={{
          endAdornment: (<InputAdornment position="end"><Icon color="disabled">lock</Icon></InputAdornment>),
        }}
        onChange={({ nativeEvent }) => { onChangePasswordText(nativeEvent.target.value); }}
      />
      {error && (
        <Typography className={classes.errorMessage}>{error}</Typography>
      )}
    </DialogContent>
    <DialogActions className={classes.centeredActions}>
      <Button
        color="primary"
        onClick={() => onCancelPressed()}
      >
        {LocalizedString.common.buttonCaptionCancel}
      </Button>
      &emsp;&emsp;
      <Button
        variant="contained"
        color="primary"
        className={classes.btnSubmit}
        onClick={() => onSubmitPressed(password)}
      >
        {LocalizedString.common.buttonCaptionSubmit}
      </Button>
    </DialogActions>
  </Dialog>
);
PasswordInputDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  open: PropTypes.bool.isRequired,
  password: PropTypes.string,
  error: PropTypes.string,

  onCancelPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onChangePasswordText: PropTypes.func.isRequired,
};
PasswordInputDialog.defaultProps = {
  password: '',
  error: '',
};
export default withStyles(styles)(PasswordInputDialog);
