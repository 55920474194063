import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Card, CardHeader, CardContent, Typography, Button, Table, TableCell,
  TableSortLabel, Input, Select, MenuItem, TableHead, TableRow, TableBody, Dialog,
  DialogContent, DialogActions,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { DatePicker } from 'material-ui-pickers';
import {
  TABLE_SORT_ASCENDING, TABLE_SORT_DESCENDING, RXTABLEFIELD_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS, RXTABLEFIELD_ACTIVE_PAGE,
  TABLE_FIELD_LOAN_HISTORY_AMOUNT, TABLE_FIELD_LOAN_HISTORY_TERM_DAYS,
  TABLE_FIELD_LOAN_HISTORY_STATUS, MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING,
  COLOR_BACKGROUND, COLOR_PRIMARY,
  TABLE_FIELD_LOAN_HISTORY_CREATED_DATE, MOMENT_DATE_FORMAT_STRING, COLOR_BACKGROUND_SECONDARY,
} from '../../constant';
import {
  isSortFieldActive, getSortIcon, toCurrency, toMoment,
} from '../../helper';
import LocalizedString from '../../localization';

const styles = () => ({
  detailButton: {
    backgroundColor: COLOR_BACKGROUND,
  },
  detailHeader: {
    color: COLOR_PRIMARY,
  },
  itemPerRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: COLOR_BACKGROUND,
    },
  },
  loanItemsContainer: {
    padding: '10px',
    borderRadius: 8,
    backgroundColor: COLOR_BACKGROUND_SECONDARY,
  },
  moneyLoanHistoryItems: {
    fontWeight: '500',
    textAlign: 'center',
    fontSize: 20,
  },
});

const getMoneyLoanHistoryItem = (classes, moneyLoanHistory, tappedId) => {
  if (!isEmpty(tappedId)) {
    const selectedList = moneyLoanHistory.find(x => x.id === tappedId);
    const { items, amount } = selectedList;
    return (
      <>
        <Typography className={classes.moneyLoanHistoryItems}>
          {`${LocalizedString.depositHistory.labelTotalLoan} ${toCurrency(amount)}`}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Payment Amount</TableCell>
              <TableCell>Payment Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(item => (
              <TableRow>
                <TableCell>{toCurrency(item.amount)}</TableCell>
                <TableCell>
                  {toMoment(item.createdDate).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    );
  }
  return null;
};

const LoanHistoryDetail = ({
  classes,
  moneyLoanHistory,

  filters,
  sortField,
  sortDirection,
  activePage,
  totalPage,
  pageSize,

  onOpenPressed,
  onChangeSort,
  onChangePage,
  onChangeFilter,
  tappedId,
  onDownloadCsvPressed,
}) => {
  const [itemsVisibility, setItemsVisibility] = useState(false);
  const handleChangeSort = (newSortField) => {
    if (newSortField === sortField) {
      onChangeSort(
        sortField,
        sortDirection === TABLE_SORT_ASCENDING
          ? TABLE_SORT_DESCENDING
          : TABLE_SORT_ASCENDING,
      );
    } else {
      onChangeSort(newSortField, TABLE_SORT_ASCENDING);
    }
  };

  const renderTableItemPerRow = () => (
    <div className={classes.itemPerRowContainer}>
      <Select
        value={pageSize}
        onChange={event => onChangePage(
          RXTABLEFIELD_PAGE_SIZE,
          event.target.value,
        )}
      >
        {DEFAULT_PAGE_SIZE_OPTIONS.map(item => (
          <MenuItem value={item} key={item}>
            {`${item} ${LocalizedString.common.labelRows}`}
          </MenuItem>
        ))}
      </Select>
    </div>
  );

  const renderTableCellHeader = (label, fieldId) => (
    <TableCell>
      <TableSortLabel
        active={isSortFieldActive(sortField, fieldId)}
        IconComponent={getSortIcon(
          isSortFieldActive(sortField, fieldId),
          sortDirection,
        )}
        onClick={() => handleChangeSort(fieldId)}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  const renderTableCellStringFilter = field => (
    <TableCell>
      <Input
        type="text"
        fullWidth
        value={filters[field]}
        onChange={
          ({ nativeEvent }) => onChangeFilter(
            field,
            nativeEvent.target.value,
          )}
      />
    </TableCell>
  );

  const renderTableCellDateFilter = field => (
    <TableCell>
      <DatePicker
        keyboard
        value={filters[field]}
        format={MOMENT_DATE_FORMAT_STRING}
        placeholder={MOMENT_DATE_FORMAT_STRING}
        mask={(dateValue) => {
          if (dateValue) {
            return [/\d/, /\d/, ' ', /\w/, /\w/, /\w/, ' ', /\d/, /\d/, /\d/, /\d/];
          }
          return [];
        }}
        onChange={value => onChangeFilter(field, value)}
        clearable
      />
    </TableCell>
  );


  const renderTableCellBody = text => (
    <TableCell>
      <Typography>{text || '-'}</Typography>
    </TableCell>
  );

  const renderTableCellAction = (item) => {
    const btnOpenDisabled = isEmpty(item.items);
    return (
      <TableCell>
        <Button
          variant="outlined"
          color="primary"
          disabled={btnOpenDisabled}
          onClick={() => {
            onOpenPressed(item.id);
            setItemsVisibility(true);
          }}
        >
          {LocalizedString.common.buttonCaptionDetail}
        </Button>
      </TableCell>
    );
  };

  const renderTablePagination = () => (
    <div className={classes.tablePagination}>
      <Button
        variant="contained"
        style={{ flexGrow: 1 }}
        disabled={activePage === 1}
        onClick={() => onChangePage(RXTABLEFIELD_ACTIVE_PAGE, activePage - 1)}
      >
        {LocalizedString.common.labelPagingPrevButton}
      </Button>

      <div className={classes.tablePaginationPageInput}>
        <span style={{ fontWeight: 500 }}>{LocalizedString.common.labelPagingPage}</span>
        <Input
          type="number"
          value={activePage}
          style={{ width: '50%' }}
          inputProps={{
            min: 1,
            max: totalPage,
            step: 1,
            style: { textAlign: 'center' },
          }}
          onChange={({ nativeEvent }) => onChangePage(
            RXTABLEFIELD_ACTIVE_PAGE,
            parseInt(nativeEvent.target.value, 10),
          )}
        />
        <span style={{ fontWeight: 500 }}>
          {`${LocalizedString.common.labelPagingPageOf} ${totalPage}`}
        </span>
      </div>

      <Button
        variant="contained"
        style={{ flexGrow: 1 }}
        disabled={activePage === totalPage}
        onClick={() => onChangePage(RXTABLEFIELD_ACTIVE_PAGE, activePage + 1)}
      >
        {LocalizedString.common.labelPagingNextButton}
      </Button>
    </div>
  );

  return (
    <>
      <Card>
        <CardHeader
          title={(
            <>
              <Typography variant="h6" className={classes.detailHeader}>
                {LocalizedString.depositHistory.labelMoneyLoanHistoryTransaction}
              </Typography>
              {renderTableItemPerRow()}
            </>
          )}
        />
        <CardContent>
          <Button
            color="primary"
            variant="outlined"
            onClick={onDownloadCsvPressed}
          >
            {LocalizedString.depositHistory.buttonCaptionDownloadCsv}
          </Button>
          <Table>
            <TableHead>
              <TableRow>
                {renderTableCellHeader(LocalizedString.depositHistory.labelAmount,
                  TABLE_FIELD_LOAN_HISTORY_AMOUNT)}
                {renderTableCellHeader(LocalizedString.depositHistory.labelTermDays,
                  TABLE_FIELD_LOAN_HISTORY_TERM_DAYS)}
                {renderTableCellHeader(LocalizedString.depositHistory.labelStatus,
                  TABLE_FIELD_LOAN_HISTORY_STATUS)}
                {renderTableCellHeader(LocalizedString.depositHistory.labelDate,
                  TABLE_FIELD_LOAN_HISTORY_CREATED_DATE)}
                <TableCell>{LocalizedString.common.labelActions}</TableCell>
              </TableRow>
              <TableRow>
                {renderTableCellStringFilter(TABLE_FIELD_LOAN_HISTORY_AMOUNT)}
                {renderTableCellStringFilter(TABLE_FIELD_LOAN_HISTORY_TERM_DAYS)}
                {renderTableCellStringFilter(TABLE_FIELD_LOAN_HISTORY_STATUS)}
                {renderTableCellDateFilter(TABLE_FIELD_LOAN_HISTORY_CREATED_DATE)}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(moneyLoanHistory) && moneyLoanHistory.map(item => (
                <TableRow>
                  {renderTableCellBody(toCurrency(item.amount || 0))}
                  {renderTableCellBody(item.termDays)}
                  {renderTableCellBody(item.status)}
                  {renderTableCellBody(toMoment(item.createdDate)
                    .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING))}
                  {renderTableCellAction(item)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {renderTablePagination()}
        </CardContent>
      </Card>
      <Dialog open={itemsVisibility}>
        <DialogContent>
          {getMoneyLoanHistoryItem(classes, moneyLoanHistory, tappedId)}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setItemsVisibility(false);
              onOpenPressed('');
            }}
          >
            {LocalizedString.common.buttonCaptionOK}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

LoanHistoryDetail.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  moneyLoanHistory: PropTypes.arrayOf(PropTypes.any).isRequired,

  filters: PropTypes.objectOf(PropTypes.string).isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
  activePage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,

  onOpenPressed: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onDownloadCsvPressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(LoanHistoryDetail);
