import * as Action from '../action';

const initialState = {
  submittingMessage: false,
  error: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.SUBMITTING_MESSAGE:
      return { ...state, submittingMessage: action.status };
    default: return state;
  }
};
