import { submittingForgetPassword, addForgetPasswordInfo } from '../simple-action';
import * as Helper from '../../../helper';
import * as Constant from '../../../constant';

export default (email, navigateTo) => async (dispatch) => {
  try {
    dispatch(submittingForgetPassword(true));
    const response = await Helper.forgetPassword(email);
    const stateInfo = { email, pinExpirationDate: response.expiredDate };
    dispatch(addForgetPasswordInfo(stateInfo));

    navigateTo(Constant.ROUTE_NAME_PIN_VERIFICATION_FORGET_PASSWORD);
  } finally {
    dispatch(submittingForgetPassword(false));
  }
};
