import {
  downloadingProfilePicture, removeImage, addImage, addProfilePicture, removeProfilePicture,
} from '../simple-action';
import * as Helper from '../../../helper';

export default userId => async (dispatch, getState) => {
  try {
    dispatch(downloadingProfilePicture(true));

    const { token } = getState().authentication;
    const img = await Helper.downloadProfilePicture(userId, token);
    if (img) {
      const user = getState().users[userId];
      if (user) {
        await Helper.removeProfilePictureFromStorage(user.id, getState());
        dispatch(removeImage(user.profilePicture));
      }

      dispatch(addImage(img));
      dispatch(addProfilePicture(userId, img.id));
    } else {
      await Helper.removeProfilePictureFromStorage(userId, getState());
      dispatch(removeProfilePicture(userId));
    }
  } finally {
    dispatch(downloadingProfilePicture(false));
  }
};
