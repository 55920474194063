import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  withStyles,
  CssBaseline,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Select,
  MenuItem,
  TableCell,
  TableSortLabel,
  Input,
  IconButton,
  Icon,
  Table,
  TableHead,
  TableRow,
  CardHeader,
  TableBody,
} from '@material-ui/core';
import {
  DEFAULT_STYLES,
  DRAWER_NOTIFICATION_WIDTH,
  RXTABLEFIELD_ACTIVE_PAGE,
  TABLE_SORT_ASCENDING,
  TABLE_SORT_DESCENDING,
  RXTABLEFIELD_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS,
  TABLE_FIELD_FORM_ORDER,
  TABLE_FIELD_FORM_STATUS,
  TABLE_FIELD_FORM_TITLE,
  RXFIELD_FORM_ORDER,
} from '../../constant';
import { MainMenu, PageHeaderAdmin, LoadingIndicator } from '../../component';
import LocalizedString from '../../localization';
import { isSortFieldActive, getSortIcon } from '../../helper';
import FormAddDialog from './form-add-dialog.container';
import FormEditDialog from './form-edit-dialog.container';
import FormDetailDialog from './form-detail-dialog.container';
import FormDeleteDialog from './form-delete-dialog.container';

const styles = theme => ({
  backIcon: {
    ...DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  btnNewBooking: {
    backgroundColor: '#5CB860',
    color: '#ffffff',
    width: '256px',
    '&:hover': {
      backgroundColor: '#558b2f',
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentMenuDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '256px',
      width: 'auto',
    },
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  detailHeader: {
    color: '#0066CB',
  },
  grow: {
    flexGrow: 1,
  },
  itemPerRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    color: '#0266B4',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

class FormBase extends Component {
  componentDidMount() {
    this.props.onAppear();
    this.handleChangeSort('order');
  }

  handleChangeSort = (newSortField) => {
    const { sortField, sortDirection, onChangeSort } = this.props;

    if (newSortField === sortField) {
      onChangeSort(
        sortField,
        sortDirection === TABLE_SORT_ASCENDING
          ? TABLE_SORT_DESCENDING
          : TABLE_SORT_ASCENDING,
      );
    } else {
      onChangeSort(sortField, TABLE_SORT_ASCENDING);
    }
  };

  renderTableItemPerRow = () => {
    const { classes, pageSize, onChangeRow } = this.props;

    return (
      <div className={classes.itemPerRowContainer}>
        <Select
          value={pageSize}
          onChange={event => onChangeRow(
            RXTABLEFIELD_PAGE_SIZE,
            event.target.value,
          )}
        >
          {DEFAULT_PAGE_SIZE_OPTIONS.map(item => (
            <MenuItem value={item} key={item}>
              {item}
              &nbsp;
              {LocalizedString.common.labelRows}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }

  renderTableCellHeader = (label, fieldId) => {
    const { sortField, sortDirection } = this.props;
    return (
      <TableCell>
        <TableSortLabel
          active={
            isSortFieldActive(sortField, fieldId)
          }
          IconComponent={getSortIcon(
            isSortFieldActive(sortField, fieldId),
            sortDirection,
          )}
          onClick={
            () => this.handleChangeSort(fieldId)
          }
        >
          {label}
        </TableSortLabel>
      </TableCell>
    );
  }

  renderTableCellStringFilter = (field, hidden = false) => {
    const { filters, onChangeFilter } = this.props;

    if (!hidden) {
      return (
        <TableCell>
          <Input
            type="text"
            fullWidth
            value={filters[field]}
            onChange={
            ({ nativeEvent }) => onChangeFilter(
              field,
              nativeEvent.target.value,
            )
          }
            placeholder={LocalizedString.common.placeholderSearch}
          />
        </TableCell>
      );
    }
    return (
      <TableCell />
    );
  }

  renderTableCellBody = (text, field = '') => (
    <TableCell>
      <Typography>
        {field === RXFIELD_FORM_ORDER ? text : (text || '-')}
      </Typography>
    </TableCell>
  );

  renderTableCellAction = (user) => {
    const {
      classes, onEditPressed,
      onDetailPressed, onDeletePressed,
    } = this.props;
    return (
      <TableCell>
        <IconButton
          color="primary"
          className={classes.iconButtonPadding4}
          onClick={() => onEditPressed(user.id)}
        >
          <Icon>edit</Icon>
        </IconButton>
        <IconButton
          color="primary"
          className={classes.iconButtonPadding4}
          onClick={() => onDetailPressed(user.id)}
        >
          <Icon>info</Icon>
        </IconButton>
        <IconButton
          color="primary"
          className={classes.iconButtonPadding4}
          onClick={() => onDeletePressed(user.id)}
        >
          <Icon>delete</Icon>
        </IconButton>
      </TableCell>
    );
  }

  renderTablePagination = () => {
    const {
      classes, activePage, totalPage, onChangePaging,
    } = this.props;

    if (totalPage - 1 !== 0) {
      return (
        <div className={classes.tablePagination}>
          <Button
            variant="contained"
            style={{ flexGrow: 1 }}
            disabled={activePage === 0}
            onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage - 1)}
          >
            {LocalizedString.common.labelPagingPrevButton}
          </Button>

          <div className={classes.tablePaginationPageInput}>
            <span style={{ fontWeight: 500 }}>{LocalizedString.common.labelPagingPage}</span>
            <Input
              type="number"
              value={activePage}
              style={{ width: '50%' }}
              inputProps={{
                min: 1,
                max: totalPage,
                step: 1,
                style: { textAlign: 'center' },
              }}
              onChange={({ nativeEvent }) => onChangePaging(
                RXTABLEFIELD_ACTIVE_PAGE,
                parseInt(nativeEvent.target.value, 10),
              )}
            />
            <span style={{ fontWeight: 500 }}>
              {`${LocalizedString.common.labelPagingPageOf} ${totalPage}`}
            </span>
          </div>

          <Button
            variant="contained"
            style={{ flexGrow: 1 }}
            disabled={activePage === totalPage - 1}
            onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage + 1)}
          >
            {LocalizedString.common.labelPagingNextButton}
          </Button>
        </div>
      );
    }
    return (<></>);
  }

  render() {
    const {
      classes,
      menuOpen,
      downloading,
      forms,
      dialogAddStatus,
      dialogEditStatus,
      dialogDetailStatus,
      dialogDeleteStatus,
      onAddNewPressed,
      onAddDialogClosePressed,
      onEditDialogClosePressed,
      onDetailDialogClosePressed,
      onDeleteDialogClosePressed,
    } = this.props;

    return (
      <>
        <div className={classes.root}>
          <CssBaseline />

          <PageHeaderAdmin />

          <MainMenu />

          <div
            className={
              classNames(classes.content, {
                [classes.contentMenuDrawerOpen]: menuOpen,
              })
            }
            style={{ padding: '16px' }}
          >
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <div className={classes.pageNavigatorContainer}>
                      <div>
                        <Typography variant="h5" className={classes.pageTitle}>{LocalizedString.form.title}</Typography>
                      </div>
                      <div className={classes.grow} />
                      <div>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          classes={{ containedSecondary: classes.btnNewBooking }}
                          onClick={onAddNewPressed}
                        >
                          {LocalizedString.form.btnAddNew}
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    title={(
                      <>
                        <Typography variant="h6" className={classes.detailHeader}>
                          {LocalizedString.form.detailTitle}
                        </Typography>
                        {this.renderTableItemPerRow()}
                      </>
                    )}
                  />
                  <CardContent>
                    <div style={{ width: '100%', overflow: 'auto' }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {this.renderTableCellHeader(LocalizedString.form.labelOrder,
                              TABLE_FIELD_FORM_ORDER)}
                            {this.renderTableCellHeader(LocalizedString.form.labelTitle,
                              TABLE_FIELD_FORM_TITLE)}
                            {this.renderTableCellHeader(LocalizedString.form.labelStatus,
                              TABLE_FIELD_FORM_STATUS)}
                            <TableCell>{LocalizedString.common.labelActions}</TableCell>
                          </TableRow>
                          <TableRow>
                            {this.renderTableCellStringFilter('', true)}
                            {this.renderTableCellStringFilter(TABLE_FIELD_FORM_TITLE)}
                            {this.renderTableCellStringFilter('', true)}
                            {this.renderTableCellStringFilter('', true)}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {forms && forms.map(user => (
                            <TableRow>
                              {this.renderTableCellBody(user.order, RXFIELD_FORM_ORDER)}
                              {this.renderTableCellBody(user.title)}
                              {this.renderTableCellBody(user.status)}
                              {this.renderTableCellAction(user)}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>

                    {this.renderTablePagination()}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>

        <FormAddDialog
          open={dialogAddStatus}
          onClose={onAddDialogClosePressed}
        />
        <FormEditDialog
          open={dialogEditStatus}
          onClose={onEditDialogClosePressed}
        />
        <FormDetailDialog
          open={dialogDetailStatus}
          onClose={onDetailDialogClosePressed}
        />
        <FormDeleteDialog
          open={dialogDeleteStatus}
          onClose={onDeleteDialogClosePressed}
        />
        {downloading && <LoadingIndicator />}
      </>
    );
  }
}
FormBase.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  menuOpen: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,

  forms: PropTypes.arrayOf(PropTypes.any).isRequired,

  dialogAddStatus: PropTypes.bool.isRequired,
  dialogEditStatus: PropTypes.bool.isRequired,
  dialogDetailStatus: PropTypes.bool.isRequired,
  dialogDeleteStatus: PropTypes.bool.isRequired,

  filters: PropTypes.shape(PropTypes.any).isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  activePage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,

  onAddNewPressed: PropTypes.func.isRequired,
  onAddDialogClosePressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onChangeRow: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onChangePaging: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onDetailPressed: PropTypes.func.isRequired,
  onDetailDialogClosePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onDeleteDialogClosePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onEditDialogClosePressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(FormBase);
