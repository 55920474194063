import { removingProfilePicture, removeProfilePicture, removeImage } from '../simple-action';
import * as Helper from '../../../helper';

export default userId => async (dispatch, getState) => {
  try {
    dispatch(removingProfilePicture(true));

    const { token } = getState().authentication;
    const user = getState().users[userId];
    const { profilePicture } = user;

    await Helper.deleteProfilePicture(userId, token);
    await Helper.removeProfilePictureFromStorage(userId, getState());

    dispatch(removeProfilePicture(userId));
    dispatch(removeImage(profilePicture));
  } finally {
    dispatch(removingProfilePicture(false));
  }
};
