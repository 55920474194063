import {
  setDocSubmissionMyResponseTappedId,
  showSnackbar,
  uploadingNewDocumentMyFormResponse,
} from '../simple-action';
import * as Helper from '../../../helper';
import LocalizedString from '../../../localization';
import * as Constant from '../../../constant';

export default (values, paymentOnBehalf, myForm, navigateTo) => async (dispatch, getState) => {
  try {
    dispatch(uploadingNewDocumentMyFormResponse(true));
    const { token } = getState().authentication;

    const valArray = Object.values(values);

    const responseArray = myForm.fields.map((field) => {
      const findIdx = valArray.findIndex(e => e.fieldId === field.id);
      return {
        fieldId: field.id,
        value: findIdx !== -1 ? valArray[findIdx].value : null,
        ...(field.type === Constant.FIELD_TYPE_FILE && {
          fileExtension: findIdx !== -1 ? valArray[findIdx].fileExtension : null,
        }),
      };
    });

    const payload = {
      formId: myForm.id,
      paymentOnBehalfId: paymentOnBehalf,
      response: responseArray,
    };

    await Helper.uploadNewDocumentMyFormResponse(token, payload);
    dispatch(showSnackbar(
      LocalizedString.common.labelSuccess,
      LocalizedString.export.uploadDataSuccess,
    ));
    dispatch(setDocSubmissionMyResponseTappedId(''));
    navigateTo(Constant.ROUTE_NAME_DOCUMENT_SUBMISSION);
  } finally {
    dispatch(uploadingNewDocumentMyFormResponse(false));
  }
};
