import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  Table,
  TableCell,
  TableSortLabel,
  Input,
  Select,
  MenuItem,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';
import { DatePicker } from 'material-ui-pickers';
import {
  TABLE_SORT_ASCENDING,
  TABLE_SORT_DESCENDING,
  RXTABLEFIELD_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS,
  MOMENT_DATE_FORMAT_STRING,
  RXTABLEFIELD_ACTIVE_PAGE,
  TABLE_FIELD_DEPOSIT_HISTORY_TYPE,
  TABLE_FIELD_DEPOSIT_HISTORY_DATE,
  TABLE_FIELD_DEPOSIT_HISTORY_AMOUNT,
  TABLE_FIELD_DEPOSIT_HISTORY_INVOICE_NO,
  MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING,
  TRANSACTION_TYPE_TOPUP,
  TRANSACTION_TYPE_INITIAL_BALANCE,
  TRANSACTION_TYPE_REFUND,
  TABLE_FIELD_START_DATE,
  TABLE_FIELD_END_DATE,
  DEFAULT_STYLES,
} from '../../constant';
import {
  isSortFieldActive, getSortIcon, toCurrency, toMoment,
} from '../../helper';
import LocalizedString from '../../localization';

const styles = theme => ({
  detailButton: {
    backgroundColor: '#ffffff',
  },
  detailHeader: {
    color: '#0066CB',
  },
  itemPerRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  filterText: {
    fontWeight: '500',
    color: '#0066CB',
  },
  dateFilterContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '20px',
    marginBottom: '12px',
  },
  dateFilter: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  buttonShow: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
    marginTop: '12px',
  },
  width100: {
    width: '100%',
  },
  validationMessage: {
    height: '20px',
    marginTop: '5px',
  },
});

const getTypeText = historyType => historyType || '-';


const DepositHistoryDetail = ({
  classes,
  depositHistory,

  filters,
  validationMessages,
  sortField,
  sortDirection,
  activePage,
  totalPage,
  pageSize,

  onBtnDownloadHistory,
  onOpenPressed,
  onChangeSort,
  onChangePaging,
  onChangeFilter,
  onShowButtonPressed,
}) => {
  const handleChangeSort = (newSortField) => {
    if (newSortField === sortField) {
      onChangeSort(
        sortField,
        sortDirection === TABLE_SORT_ASCENDING
          ? TABLE_SORT_DESCENDING
          : TABLE_SORT_ASCENDING,
      );
    } else {
      onChangeSort(newSortField, TABLE_SORT_ASCENDING);
    }
  };

  const renderDateFilter = (fieldStartDate, fieldEndDate) => (
    <div>
      <Typography variant="subtitle1" color="primary" className={classes.filterText}>
        {LocalizedString.depositHistory.labelFilter}
      </Typography>
      <div className={classes.dateFilterContainer}>
        <div className={classes.dateFilter}>
          <DatePicker
            label={LocalizedString.depositHistory.labelStartDate}
            value={filters[fieldStartDate] || null}
            clearable
            emptyLabel="DD MMM YYYY"
            format="DD MMM YYYY"
            onChange={value => onChangeFilter(
              fieldStartDate,
              value,
            )}
            className={classes.width100}
          />
          <div className={classes.validationMessage}>
            <Typography variant="caption" color="error">
              {validationMessages ? validationMessages.startDate : ''}
            </Typography>
          </div>
        </div>
        <div className={classes.dateFilter}>
          <DatePicker
            label={LocalizedString.depositHistory.labelEndDate}
            value={filters[fieldEndDate] || null}
            clearable
            emptyLabel="DD MMM YYYY"
            format="DD MMM YYYY"
            onChange={value => onChangeFilter(
              fieldEndDate,
              value,
            )}
            className={classes.width100}
          />
          <div className={classes.validationMessage}>
            <Typography variant="caption" color="error">
              {validationMessages ? validationMessages.endDate : ''}
            </Typography>
          </div>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onShowButtonPressed(filters)}
          className={classes.buttonShow}
        >
          {LocalizedString.depositHistory.buttonCaptionShow}
        </Button>
      </div>
    </div>
  );

  const renderTableItemPerRow = () => (
    <div className={classes.itemPerRowContainer}>
      <Select
        value={pageSize}
        onChange={event => onChangePaging(
          RXTABLEFIELD_PAGE_SIZE,
          event.target.value,
        )}
      >
        {DEFAULT_PAGE_SIZE_OPTIONS.map(item => (
          <MenuItem value={item} key={item}>
            {item}
            &nbsp;
            {LocalizedString.common.labelRows}
          </MenuItem>
        ))}
      </Select>
    </div>
  );

  const renderTableCellHeader = (label, fieldId) => (
    <TableCell>
      <TableSortLabel
        active={
          isSortFieldActive(sortField, fieldId)
        }
        IconComponent={getSortIcon(
          isSortFieldActive(sortField, fieldId),
          sortDirection,
        )}
        onClick={
          () => handleChangeSort(fieldId)
        }
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  const renderTableCellStringFilter = field => (
    <TableCell>
      <Input
        type="text"
        fullWidth
        value={filters[field]}
        onChange={
          ({ nativeEvent }) => onChangeFilter(
            field,
            nativeEvent.target.value,
          )
        }
      />
    </TableCell>
  );

  const renderTableCellDateFilter = field => (
    <TableCell>
      <DatePicker
        keyboard
        value={filters[field]}
        format={MOMENT_DATE_FORMAT_STRING}
        placeholder={MOMENT_DATE_FORMAT_STRING}
        mask={(dateValue) => {
          if (dateValue) {
            return [/\d/, /\d/, ' ', /\w/, /\w/, /\w/, ' ', /\d/, /\d/, /\d/, /\d/];
          }
          return [];
        }}
        onChange={value => onChangeFilter(field, value)}
        clearable
      />
    </TableCell>
  );

  const renderTableCellBody = text => (
    <TableCell>
      <Typography>
        {text || '-'}
      </Typography>
    </TableCell>
  );

  const renderTableCellAction = (item) => {
    const btnOpenDisabled = item.type === TRANSACTION_TYPE_TOPUP
      || item.type === TRANSACTION_TYPE_INITIAL_BALANCE
      || item.type === TRANSACTION_TYPE_REFUND;
    return (
      <TableCell>
        <Button
          variant="outlined"
          color="primary"
          disabled={btnOpenDisabled}
          onClick={() => onOpenPressed(item)}
        >
          Open
        </Button>
      </TableCell>
    );
  };

  const renderTablePagination = () => (
    <div className={classes.tablePagination}>
      <Button
        variant="contained"
        style={{ flexGrow: 1 }}
        disabled={activePage === 1}
        onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage - 1)}
      >
        {LocalizedString.common.labelPagingPrevButton}
      </Button>

      <div className={classes.tablePaginationPageInput}>
        <span style={{ fontWeight: 500 }}>{LocalizedString.common.labelPagingPage}</span>
        <Input
          type="number"
          value={activePage}
          style={{ width: '50%' }}
          inputProps={{
            min: 1,
            max: totalPage,
            step: 1,
            style: { textAlign: 'center' },
          }}
          onChange={({ nativeEvent }) => onChangePaging(
            RXTABLEFIELD_ACTIVE_PAGE,
            parseInt(nativeEvent.target.value, 10),
          )}
        />
        <span style={{ fontWeight: 500 }}>
          {`${LocalizedString.common.labelPagingPageOf} ${totalPage}`}
        </span>
      </div>

      <Button
        variant="contained"
        style={{ flexGrow: 1 }}
        disabled={activePage === totalPage}
        onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage + 1)}
      >
        {LocalizedString.common.labelPagingNextButton}
      </Button>
    </div>
  );

  return (
    <>
      <Card>
        <CardHeader
          title={(
            <>
              <Typography variant="h6" className={classes.detailHeader}>
                {LocalizedString.depositHistory.detailTitle}
              </Typography>
              {renderTableItemPerRow()}
            </>
          )}
        />
        <CardContent>
          {renderDateFilter(TABLE_FIELD_START_DATE, TABLE_FIELD_END_DATE)}
          <Button
            color="primary"
            variant="outlined"
            onClick={() => onBtnDownloadHistory(filters)}
          >
            Download as CSV
          </Button>
          <Table>
            <TableHead>
              <TableRow>
                {renderTableCellHeader(LocalizedString.depositHistory.labelType,
                  TABLE_FIELD_DEPOSIT_HISTORY_TYPE)}
                {renderTableCellHeader(LocalizedString.depositHistory.labelDate,
                  TABLE_FIELD_DEPOSIT_HISTORY_DATE)}
                {renderTableCellHeader(LocalizedString.depositHistory.labelAmount,
                  TABLE_FIELD_DEPOSIT_HISTORY_AMOUNT)}
                {renderTableCellHeader(LocalizedString.depositHistory.labelInvoiceNo,
                  TABLE_FIELD_DEPOSIT_HISTORY_INVOICE_NO)}
                <TableCell>{LocalizedString.common.labelActions}</TableCell>
              </TableRow>
              <TableRow>
                {renderTableCellStringFilter(TABLE_FIELD_DEPOSIT_HISTORY_TYPE)}
                {renderTableCellDateFilter(TABLE_FIELD_DEPOSIT_HISTORY_DATE)}
                {renderTableCellStringFilter(TABLE_FIELD_DEPOSIT_HISTORY_AMOUNT)}
                {renderTableCellStringFilter(TABLE_FIELD_DEPOSIT_HISTORY_INVOICE_NO)}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {depositHistory && depositHistory.map(item => (
                <TableRow>
                  {renderTableCellBody(getTypeText(item.type))}
                  {renderTableCellBody(toMoment(item.date)
                    .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING))}
                  {renderTableCellBody(toCurrency(item.amount))}
                  {renderTableCellBody(item.referenceNo)}
                  {renderTableCellAction(item)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {renderTablePagination()}
        </CardContent>
      </Card>
    </>
  );
};
DepositHistoryDetail.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  depositHistory: PropTypes.arrayOf(PropTypes.any).isRequired,

  filters: PropTypes.shape(PropTypes.any).isRequired,
  validationMessages: PropTypes.shape(PropTypes.any).isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  activePage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,

  onBtnDownloadHistory: PropTypes.func.isRequired,
  onOpenPressed: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onChangePaging: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onShowButtonPressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(DepositHistoryDetail);
