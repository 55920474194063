import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  withStyles,
  CssBaseline,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TableCell,
  TableSortLabel,
  Input,
  IconButton,
  Icon,
  Table,
  TableHead,
  TableRow,
  CardHeader,
  TableBody,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import {
  DEFAULT_STYLES,
  DRAWER_NOTIFICATION_WIDTH,
  TABLE_SORT_ASCENDING,
  TABLE_SORT_DESCENDING,
  TABLE_FIELD_FORM_RESPONSE_NO,
  TABLE_FIELD_FORM_RESPONSE_FORM_TITLE,
  TABLE_FIELD_FORM_RESPONSE_USER,
  TABLE_FIELD_FORM_RESPONSE_SUBMIT_DATE,
  TABLE_FIELD_FORM_RESPONSE_ACTIONS,
  RXFORM_FORM_RESPONSE_DETAIL,
  RXFIELD_FORM_RESPONSE_DETAIL_FORM_ID,
  RXFIELD_FORM_RESPONSE_DETAIL_FORM_NAME,
  RXFIELD_FORM_RESPONSE_DETAIL_USER_ID,
  RXFIELD_FORM_RESPONSE_DETAIL_USER_NAME,
  RXFIELD_FORM_RESPONSE_DETAIL_SUBMIT_DATE,
  RXFIELD_FORM_RESPONSE_DETAIL_STATUS,
  RXFIELD_FORM_RESPONSE_DETAIL_STATUS_NOTES,
  TABLE_FIELD_FORM_RESPONSE_STATUS,
  TABLE_FIELD_FORM_RESPONSE_SUBMIT_ID,
  RXFIELD_FORM_RESPONSE_DETAIL_SUBMIT_ID,
  DEFAULT_PAGE_SIZE_OPTIONS,
  RXTABLEFIELD_PAGE_SIZE,
  RXFIELD_FORM_RESPONSE_DETAIL_PAYMENT_ON_BEHALF_ID,
  RXFIELD_FORM_RESPONSE_DETAIL_PAYMENT_ON_BEHALF_NAME,
  FIELD_TYPE_TEXT,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_DATE,
  FIELD_TYPE_FILE,
} from '../../constant';
import { MainMenu, PageHeaderAdmin, LoadingIndicator } from '../../component';
import LocalizedString from '../../localization';
import {
  isSortFieldActive, getSortIcon, renderReduxFormTextField, renderReduxFormDateField, sortAsc,
} from '../../helper';
import ResponseFilterDialog from './response-filter-dialog.container';

const styles = theme => ({
  backIcon: {
    ...DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  btnNewBooking: {
    backgroundColor: '#5CB860',
    color: '#ffffff',
    width: '256px',
    '&:hover': {
      backgroundColor: '#558b2f',
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentMenuDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '256px',
      width: 'auto',
    },
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  detailHeader: {
    color: '#0066CB',
  },
  grow: {
    flexGrow: 1,
  },
  itemPerRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    color: '#0266B4',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  detailContent: {
    display: 'flex',
    flex: 1,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  spacer: {
    height: 20,
  },
  column: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  responseLabel: {
    color: '#00000061',
    marginTop: '14px',
    fontSize: '16px',
  },
});

class ResponsePage extends Component {
  componentDidMount() {
    this.props.onAppear();
  }

  handleChangeSort = (newSortField) => {
    const { sortField, sortDirection, onChangeSort } = this.props;

    if (newSortField === sortField) {
      onChangeSort(
        sortField,
        sortDirection === TABLE_SORT_ASCENDING
          ? TABLE_SORT_DESCENDING
          : TABLE_SORT_ASCENDING,
      );
    } else {
      onChangeSort(newSortField, TABLE_SORT_ASCENDING);
    }
  };

  renderTableCellHeader = (label, fieldId) => {
    const { sortField, sortDirection } = this.props;
    return (
      <TableCell>
        <TableSortLabel
          active={
            isSortFieldActive(sortField, fieldId)
          }
          IconComponent={getSortIcon(
            isSortFieldActive(sortField, fieldId),
            sortDirection,
          )}
          onClick={
            () => this.handleChangeSort(fieldId)
          }
        >
          {label}
        </TableSortLabel>
      </TableCell>
    );
  }

  renderTableCellBody = text => (
    <TableCell>
      <Typography>
        {text || '-'}
      </Typography>
    </TableCell>
  );

  renderTableCellAction = (formResponse) => {
    const {
      classes, onDetailPressed,
    } = this.props;
    return (
      <TableCell>
        <IconButton
          color="primary"
          className={classes.iconButtonPadding4}
          onClick={() => onDetailPressed(formResponse.id)}
        >
          <Icon>info</Icon>
        </IconButton>
      </TableCell>
    );
  }

  renderTablePagination = () => {
    const {
      classes, activePage, totalPage, onChangePaging,
    } = this.props;
    if (totalPage - 1 !== 0) {
      return (
        <div className={classes.tablePagination}>
          <Button
            variant="contained"
            style={{ flexGrow: 1 }}
            disabled={activePage === 0}
            onClick={() => onChangePaging(activePage - 1)}
          >
            {LocalizedString.common.labelPagingPrevButton}
          </Button>
          <div className={classes.tablePaginationPageInput}>
            <span style={{ fontWeight: 500 }}>{LocalizedString.common.labelPagingPage}</span>
            <Input
              type="number"
              value={activePage + 1}
              style={{ width: '50%' }}
              inputProps={{
                min: 1,
                max: totalPage,
                step: 1,
                style: { textAlign: 'center' },
              }}
              onChange={({ nativeEvent }) => onChangePaging(
                parseInt(nativeEvent.target.value, 10) - 1,
              )}
            />
            <span style={{ fontWeight: 500 }}>
              {`${LocalizedString.common.labelPagingPageOf} ${totalPage}`}
            </span>
          </div>
          <Button
            variant="contained"
            style={{ flexGrow: 1 }}
            disabled={activePage === totalPage - 1}
            onClick={() => onChangePaging(activePage + 1)}
          >
            {LocalizedString.common.labelPagingNextButton}
          </Button>
        </div>
      );
    }
    return (<></>);
  }

  render() {
    const {
      classes,
      menuOpen,
      downloading,
      downloadingDetail,
      showDetail,
      showResponseFilterDialog,
      formResponses,
      selectedStatus,
      selectedUser,
      detail,
      downloadingFile,

      onBackPressed,
      onResponseFilterDialogClosePressed,
      onFilterPressed,
      onDownloadFilePressed,
    } = this.props;

    const renderTableItemPerRow = () => {
      const { pageSize, onChangeRow } = this.props;

      return (
        <div className={classes.itemPerRowContainer}>
          <Select
            value={pageSize}
            onChange={event => onChangeRow(
              RXTABLEFIELD_PAGE_SIZE,
              event.target.value,
            )}
          >
            {DEFAULT_PAGE_SIZE_OPTIONS.map(item => (
              <MenuItem value={item} key={item}>
                {item}
                &nbsp;
                {LocalizedString.common.labelRows}
              </MenuItem>
            ))}
          </Select>
        </div>
      );
    };

    const renderTableCellStringFilter = (field, hidden = false) => {
      const { filters, onChangeFilter, pageSize } = this.props;
      if (!hidden) {
        return (
          <TableCell>
            <Input
              type="text"
              fullWidth
              value={filters[field]}
              onChange={
              ({ nativeEvent }) => onChangeFilter(
                field,
                nativeEvent.target.value,
                pageSize,
              )
            }
              placeholder={LocalizedString.common.placeholderSearch}
            />
          </TableCell>
        );
      }
      return (
        <TableCell />
      );
    };

    const renderDynamicForm = () => {
      if (detail.response && detail.response.length > 0) {
        return detail.response
          .sort((a, b) => sortAsc(a.field.order, b.field.order))
          .map((x) => {
            if (x.field.type === FIELD_TYPE_TEXT || x.field.type === FIELD_TYPE_NUMBER) {
              return (
                <Field
                  key={x.field.name}
                  name={x.field.name}
                  component={renderReduxFormTextField}
                  label={x.field.label}
                  type={x.field.type === FIELD_TYPE_NUMBER ? 'number' : 'text'}
                  input={{ value: x.value }}
                  disabled
                />
              );
            }
            if (x.field.type === FIELD_TYPE_DATE) {
              return (
                <Field
                  key={x.field.name}
                  name={x.field.name}
                  component={renderReduxFormDateField}
                  label={x.field.label}
                  input={{ value: x.value }}
                  keyboard
                  disabled
                />
              );
            }
            if (x.field.type === FIELD_TYPE_FILE) {
              return (
                <div style={{ margin: '10px 0 8px 0' }} key={x.field.name}>
                  <Typography className={classes.fileLabel}>{x.field.label}</Typography>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="large"
                    onClick={() => onDownloadFilePressed(x.value)}
                    disabled={downloadingFile}
                  >
                    {`${LocalizedString.submitRequest.buttonCaptionDownload} ${x.field.label}`}
                  </Button>
                </div>
              );
            }
            return null;
          });
      }
      return null;
    };

    const renderBody = () => {
      if (showDetail) {
        return (
          <div
            className={
          classNames(classes.detailContent, {
            [classes.contentMenuDrawerOpen]: menuOpen,
          })
        }
            style={{ padding: '32px' }}
          >
            <Grid container spacing={24}>
              <Card className={classes.column}>
                <CardHeader
                  title={(
                    <div className={classes.row}>
                      <IconButton aria-label="delete" onClick={onBackPressed}>
                        <Icon>arrow_back</Icon>
                      </IconButton>
                      <Typography variant="h6" className={classes.detailHeader}>
                        {LocalizedString.response.title}
                      </Typography>
                    </div>
                )}
                />
                <CardContent className={classes.cardContent}>
                  <Field
                    name={RXFIELD_FORM_RESPONSE_DETAIL_FORM_ID}
                    component={renderReduxFormTextField}
                    label={LocalizedString.response.labelFormId}
                    disabled
                  />
                  <Field
                    name={RXFIELD_FORM_RESPONSE_DETAIL_FORM_NAME}
                    component={renderReduxFormTextField}
                    label={LocalizedString.response.labelFormName}
                    disabled
                  />
                  <Field
                    name={RXFIELD_FORM_RESPONSE_DETAIL_USER_ID}
                    component={renderReduxFormTextField}
                    label={LocalizedString.response.labelUserId}
                    disabled
                  />
                  <Field
                    name={RXFIELD_FORM_RESPONSE_DETAIL_USER_NAME}
                    component={renderReduxFormTextField}
                    label={LocalizedString.response.labelUserName}
                    disabled
                  />
                  <Field
                    name={RXFIELD_FORM_RESPONSE_DETAIL_SUBMIT_ID}
                    component={renderReduxFormTextField}
                    label={LocalizedString.response.labelSubmitId}
                    disabled
                  />
                  <Field
                    name={RXFIELD_FORM_RESPONSE_DETAIL_SUBMIT_DATE}
                    component={renderReduxFormTextField}
                    label={LocalizedString.response.labelSubmitDate}
                    disabled
                  />
                  <Field
                    name={RXFIELD_FORM_RESPONSE_DETAIL_STATUS}
                    component={renderReduxFormTextField}
                    label={LocalizedString.response.labelStatus}
                    disabled
                  />
                  <Field
                    name={RXFIELD_FORM_RESPONSE_DETAIL_STATUS_NOTES}
                    component={renderReduxFormTextField}
                    label={LocalizedString.response.labelStatusNotes}
                    disabled
                  />
                  <Field
                    name={RXFIELD_FORM_RESPONSE_DETAIL_PAYMENT_ON_BEHALF_ID}
                    component={renderReduxFormTextField}
                    label={LocalizedString.response.labelPaymentOnBehalfId}
                    disabled
                  />
                  <Field
                    name={RXFIELD_FORM_RESPONSE_DETAIL_PAYMENT_ON_BEHALF_NAME}
                    component={renderReduxFormTextField}
                    label={LocalizedString.response.labelPaymentOnBehalfName}
                    disabled
                  />
                  <Typography className={classes.responseLabel}>
                    {LocalizedString.response.labelResponse}
                  </Typography>
                  {renderDynamicForm(detail)}
                </CardContent>
              </Card>
            </Grid>
          </div>
        );
      }

      return (
        <div
          className={
          classNames(classes.content, {
            [classes.contentMenuDrawerOpen]: menuOpen,
          })
        }
          style={{ padding: '16px' }}
        >
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title={(
                    <div className={classes.tableHeader}>
                      <Typography variant="h6" className={classes.detailHeader}>
                        {LocalizedString.response.title}
                      </Typography>
                      <div className={classes.row}>
                        {renderTableItemPerRow()}
                        <div style={{ width: 20 }} />
                        <Button variant="contained" className={classes.approve} onClick={() => onFilterPressed()}>
                          {LocalizedString.response.labelFilter}
                        </Button>
                      </div>
                    </div>
                )}
                />
                <CardContent>
                  <div style={{ width: '100%', overflow: 'auto' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {this.renderTableCellHeader(LocalizedString.response.labelNo,
                            TABLE_FIELD_FORM_RESPONSE_NO)}
                          {this.renderTableCellHeader(LocalizedString.response.labelSubmitId,
                            TABLE_FIELD_FORM_RESPONSE_SUBMIT_ID)}
                          {this.renderTableCellHeader(LocalizedString.response.labelFormTitle,
                            TABLE_FIELD_FORM_RESPONSE_FORM_TITLE)}
                          {this.renderTableCellHeader(LocalizedString.response.labelUser,
                            TABLE_FIELD_FORM_RESPONSE_USER)}
                          {this.renderTableCellHeader(LocalizedString.response.labelSubmitDate,
                            TABLE_FIELD_FORM_RESPONSE_SUBMIT_DATE)}
                          {this.renderTableCellHeader(LocalizedString.response.labelStatus,
                            TABLE_FIELD_FORM_RESPONSE_STATUS)}
                          {this.renderTableCellHeader(LocalizedString.response.labelActions,
                            TABLE_FIELD_FORM_RESPONSE_ACTIONS)}
                        </TableRow>
                        <TableRow>
                          {renderTableCellStringFilter('', true)}
                          {renderTableCellStringFilter(TABLE_FIELD_FORM_RESPONSE_SUBMIT_ID)}
                          {renderTableCellStringFilter(TABLE_FIELD_FORM_RESPONSE_FORM_TITLE)}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {formResponses && formResponses.map((formResponse, index) => (
                          <TableRow key={formResponse.id}>
                            {this.renderTableCellBody(index + 1)}
                            {this.renderTableCellBody(formResponse.submitId)}
                            {this.renderTableCellBody(formResponse.form.title)}
                            {this.renderTableCellBody(formResponse.user.fullName)}
                            {this.renderTableCellBody(formResponse.submitDate)}
                            {this.renderTableCellBody(formResponse.status)}
                            {this.renderTableCellAction(formResponse)}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>

                  {this.renderTablePagination()}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      );
    };

    return (
      <>
        <div className={classes.root}>
          <CssBaseline />

          <PageHeaderAdmin />

          <MainMenu />

          {renderBody(showDetail)}
          <ResponseFilterDialog
            open={showResponseFilterDialog}
            onClose={() => onResponseFilterDialogClosePressed(selectedStatus, selectedUser)}
          />
        </div>

        {(downloading || downloadingDetail) && <LoadingIndicator />}
      </>
    );
  }
}
ResponsePage.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  filters: PropTypes.shape(PropTypes.any).isRequired,
  menuOpen: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDetail: PropTypes.bool.isRequired,
  showDetail: PropTypes.bool.isRequired,
  showResponseFilterDialog: PropTypes.bool.isRequired,
  downloadingFile: PropTypes.bool.isRequired,

  formResponses: PropTypes.arrayOf(PropTypes.any).isRequired,
  detail: PropTypes.shape(PropTypes.any).isRequired,

  sortField: PropTypes.string.isRequired,
  selectedStatus: PropTypes.string.isRequired,
  selectedUser: PropTypes.string.isRequired,

  sortDirection: PropTypes.string.isRequired,
  activePage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,

  onAppear: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onChangePaging: PropTypes.func.isRequired,

  onDetailPressed: PropTypes.func.isRequired,
  onBackPressed: PropTypes.func.isRequired,
  onResponseFilterDialogClosePressed: PropTypes.func.isRequired,
  onFilterPressed: PropTypes.func.isRequired,
  onChangeRow: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onDownloadFilePressed: PropTypes.func.isRequired,
};

export default reduxForm({
  form: RXFORM_FORM_RESPONSE_DETAIL,
  enableReinitialize: true,
})(withStyles(styles)(ResponsePage));
