import * as Action from '../action';

const initialState = {
  notificationDrawerOpen: false,
  downloadingNotification: false,
  uploadingNotification: false,
  nextNotificationAutoUpdate: undefined,
  globalLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.DOWNLOADING_MY_NOTIFICATION:
      return { ...state, downloadingNotification: action.value };
    case Action.UPLOADING_NOTIFICATION_READ_STATUS:
      return { ...state, uploadingNotification: action.value };
    case Action.TOGGLE_NOTIFICATION_DRAWER:
      return { ...state, notificationDrawerOpen: action.status };
    case Action.LOGGING_OUT:
      return { ...state, loggingOut: action.status };
    case Action.SET_GLOBAL_LOADING:
      return { ...state, globalLoading: action.status };
    case Action.SET_NOTIFICATION_NEXT_AUTO_UPDATE:
      return { ...state, nextNotificationAutoUpdate: action.value };
    default: return state;
  }
};
