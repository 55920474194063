import { connect } from 'react-redux';
import ContactUsScreen from './contact-us.presentation';

const mapStateToProps = state => ({
  notificationOpen: state.uiWebUserMain.notificationDrawerOpen,
  socialMedia: [
    {
      imgPath: '/assets/images/i_ig.png',
      link: 'https://www.instagram.com/cikarang_dryport/',
    },
    {
      imgPath: '/assets/images/i_fb.png',
      link: 'https://www.facebook.com/cikarangdryport/',
    },
    {
      imgPath: '/assets/images/i_tw.png',
      link: 'https://twitter.com/CikarangDryPort',
    },
  ],
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsScreen);
