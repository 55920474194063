import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  CssBaseline,
  Card,
  CardHeader,
  Avatar,
  Icon,
  Select,
  MenuItem,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Input,
  TableBody,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import { MainMenu, PageHeader } from '../../component';
import {
  DRAWER_WIDTH,
  RXTABLEFIELD_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS,
  TABLE_SORT_ASCENDING,
  TABLE_SORT_DESCENDING,
  RXTABLEFIELD_ACTIVE_PAGE,
  TABLE_FIELD_ORDER_LIST_BILL_TO,
  TABLE_FIELD_ORDER_LIST_BL_NO,
} from '../../constant';
import LocalizedString from '../../localization';
import { isSortFieldActive, getSortIcon } from '../../helper';

const styles = theme => ({
  cellAction: {
    width: '128px',
  },
  contentContainer: {
    padding: '16px 32px',
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100%',
  },
  cardRoot: {
    overflow: 'visible',
  },
  cardAvatar: {
    height: 64,
    width: 64,
    padding: '12px',
    borderRadius: 4,
    marginTop: theme.spacing.unit * -5,
    background: 'linear-gradient(60deg, #0164b2, #014982)',
    boxShadow:
      '0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)',
  },
  iconButtonPadding4: {
    padding: '4px',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  tabIndicator: {
    display: 'none',
  },
  tabRoot: {
    marginTop: theme.spacing.unit,
    overflow: 'visible',
  },
  tabElement: {
    backgroundColor: 'rgba(200, 200, 200, 0.2)',
    textTransform: 'capitalize',
  },
  tabElementActive: {
    backgroundColor: '#3f51b5',
    color: '#ffffff !important',
    transform: 'scaleY(1.1)',
    borderRadius: '4px',
    boxShadow: '0px 4px 20px rgba(0, 102, 203, 0.45)',
  },
  tabScroller: {
    overflowX: 'visible',
  },
});

const OrderListExportInPage = ({
  classes,
  menuOpen,
  orders,
  activePage,
  totalPage,
  pageSize,
  filters,
  sortField,
  sortDirection,
  onChangeFilter,
  onChangePaging,
  onChangeSort,
}) => {
  const handleChangeSort = (newSortField) => {
    if (newSortField === sortField) {
      onChangeSort(
        sortField,
        sortDirection === TABLE_SORT_ASCENDING
          ? TABLE_SORT_DESCENDING
          : TABLE_SORT_ASCENDING,
      );
    } else {
      onChangeSort(newSortField, TABLE_SORT_ASCENDING);
    }
  };

  const renderDataAsTable = () => {
    if (orders) {
      return (
        <>
          {orders.map(order => (
            <TableRow className={classes.tableRow}>
              <TableCell>
                <Typography>{order.billTo}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{order.blNo}</Typography>
              </TableCell>
              <TableCell>
                <IconButton color="primary" className={classes.iconButtonPadding4}>
                  <Icon>visibility</Icon>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </>
      );
    }

    return (<></>);
  };

  const renderTablePagination = () => (
    <div className={classes.tablePagination}>
      <Button
        variant="contained"
        style={{ flexGrow: 1 }}
        disabled={activePage === 1}
        onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage - 1)}
      >
        {LocalizedString.common.labelPagingPrevButton}
      </Button>

      <div className={classes.tablePaginationPageInput}>
        <span style={{ fontWeight: 500 }}>{LocalizedString.common.labelPagingPage}</span>
        <Input
          type="number"
          value={activePage}
          style={{ width: '50%' }}
          inputProps={{
            min: 1,
            max: totalPage,
            step: 1,
            style: { textAlign: 'center' },
          }}
          onChange={({ nativeEvent }) => onChangePaging(
            RXTABLEFIELD_ACTIVE_PAGE,
            parseInt(nativeEvent.target.value, 10),
          )}
        />
        <span style={{ fontWeight: 500 }}>
          {`${LocalizedString.common.labelPagingPageOf} ${totalPage}`}
        </span>
      </div>

      <Button
        variant="contained"
        style={{ flexGrow: 1 }}
        disabled={activePage === totalPage}
        onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage + 1)}
      >
        {LocalizedString.common.labelPagingNextButton}
      </Button>
    </div>
  );

  const renderTableCellHeader = (label, fieldId) => (
    <TableCell>
      <TableSortLabel
        active={
          isSortFieldActive(sortField, fieldId)
        }
        IconComponent={getSortIcon(
          isSortFieldActive(sortField, fieldId),
          sortDirection,
        )}
        onClick={
          () => handleChangeSort(fieldId)
        }
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  const renderTableCellStringFilter = field => (
    <TableCell>
      <Input
        type="text"
        fullWidth
        value={filters[field]}
        onChange={
          ({ nativeEvent }) => onChangeFilter(
            field,
            nativeEvent.target.value,
          )
        }
      />
    </TableCell>
  );

  return (
    <>
      <CssBaseline />
      <div>
        <MainMenu />
      </div>

      <div
        className={classes.contentContainer}
        style={{ marginLeft: menuOpen ? DRAWER_WIDTH : 0 }}
      >
        <div>
          <PageHeader
            pageTitle={LocalizedString.orderListExportIn.title}
            pageCaption={LocalizedString.orderListExportIn.caption}
          />

          <div style={{ flexGrow: 0, width: '100%' }}>
            <Card classes={{ root: classes.cardRoot }}>
              <CardHeader
                avatar={(
                  <Avatar classes={{ root: classes.cardAvatar }}>
                    <Icon>list_alt</Icon>
                  </Avatar>
                )}
                title={(
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Select
                      value={pageSize}
                      onChange={event => onChangePaging(
                        RXTABLEFIELD_PAGE_SIZE,
                        event.target.value,
                      )}
                    >
                      {DEFAULT_PAGE_SIZE_OPTIONS.map(item => (
                        <MenuItem value={item} key={item}>
                          {item}
                          &nbsp;
                          {LocalizedString.common.labelRows}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}
              />
              <CardContent>
                <div style={{ width: '100%', overflow: 'auto' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {
                          renderTableCellHeader(
                            LocalizedString.orderListCustomer.labelBillTo,
                            TABLE_FIELD_ORDER_LIST_BILL_TO,
                          )
                        }
                        {
                          renderTableCellHeader(
                            LocalizedString.orderListCustomer.labelBlNo,
                            TABLE_FIELD_ORDER_LIST_BL_NO,
                          )
                        }
                        <TableCell className={classes.cellAction}>
                          {LocalizedString.common.labelActions}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        {renderTableCellStringFilter(TABLE_FIELD_ORDER_LIST_BILL_TO)}
                        {renderTableCellStringFilter(TABLE_FIELD_ORDER_LIST_BL_NO)}
                        <TableCell className={classes.cellACtion} />
                      </TableRow>
                    </TableHead>

                    <TableBody>{renderDataAsTable()}</TableBody>
                  </Table>
                </div>

                {renderTablePagination()}
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
OrderListExportInPage.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  menuOpen: PropTypes.bool.isRequired,
  orders: PropTypes.arrayOf(PropTypes.any).isRequired,
  activePage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  filters: PropTypes.shape(PropTypes.any).isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onChangePaging: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
};

export default withStyles(styles)(OrderListExportInPage);
