import * as Action from '../action';
import * as Constant from '../../constant';

const initialState = {
  searchBarText: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.SET_TARIFF_SIMULATION_RELEASE_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    default:
      return state;
  }
};
