import * as Helper from '../../../helper';
import { setFormResponseDetail, downloadingFormResponseDetail, showFormResponseDetail } from '../simple-action';

export default id => async (dispatch, getState) => {
  try {
    dispatch(downloadingFormResponseDetail(true));
    const { token } = getState().authentication;
    const detail = await Helper.downloadFormResponseDetail(id, token);
    dispatch(setFormResponseDetail(detail));
    dispatch(showFormResponseDetail(true));
  } finally {
    dispatch(downloadingFormResponseDetail(false));
  }
};
