import * as Helper from '../../../helper';
import * as Validation from '../../../validation';
import { uploadingField } from '../simple-action';

export default values => async (dispatch, getState) => {
  try {
    dispatch(uploadingField(true));

    Validation.validateNewField(values);

    const { token } = getState().authentication;

    const body = {
      formId: values.formName,
      order: Helper.toNumber(values.order),
      name: values.name,
      label: values.label,
      required: values.required === 'true' || false,
      type: values.type,
      status: values.status,
    };

    await Helper.uploadNewField(body, token);
  } finally {
    dispatch(uploadingField(false));
  }
};
