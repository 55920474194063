import * as Helper from '../../../helper';
import { downloadingTariffSimulationReleaseSearch, addTariffSimulationReleaseOrder } from '../simple-action';


export default (blNo) => async (dispatch, getState) => {
  try {
    dispatch(downloadingTariffSimulationReleaseSearch(true));
    const { token } = getState().authentication;
    const tariffSimulationOrder = await Helper.downloadTariffSimulationUnassignedOrder(blNo, token);

    if (tariffSimulationOrder) {
      dispatch(addTariffSimulationReleaseOrder(tariffSimulationOrder));
    }
  } finally {
    dispatch(downloadingTariffSimulationReleaseSearch(false));
  }
};
