import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  Typography,
  Icon,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Input,
  TableSortLabel,
  Button,
} from '@material-ui/core';
import LocalizedString from '../../localization';
import {
  RXTABLEFIELD_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS,
  TABLE_SORT_ASCENDING,
  TABLE_SORT_DESCENDING,
  RXTABLEFIELD_ACTIVE_PAGE,
  TABLE_FIELD_ADMIN_PAYMENT_HISTORY_DETAIL_BL_NO,
  TABLE_FIELD_ADMIN_PAYMENT_HISTORY_DETAIL_INVOICE_NO,
  TABLE_FIELD_ADMIN_PAYMENT_HISTORY_DETAIL_AMOUNT,
  TABLE_FIELD_ADMIN_PAYMENT_HISTORY_DETAIL_SERVICE,
  TABLE_FIELD_ADMIN_PAYMENT_HISTORY_DETAIL_INVOICE_NAME,
} from '../../constant';
import { isSortFieldActive, getSortIcon, toCurrency } from '../../helper';

const styles = theme => ({
  dialogActions: {
    margin: '0 -24px',
    marginTop: '24px',
    padding: 24,
    borderTop: '1px solid #C4C4C4',
    justifyContent: 'space-between',
    paddingBottom: 0,
    display: 'flex',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemPerRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  alignRight: {
    textAlign: 'right',
  },
});

class PaymentDetailDialog extends Component {
  renderTableItemPerRow = () => {
    const { classes, pageSize, onChangePaging } = this.props;
    return (
      <div className={classes.itemPerRowContainer}>
        <Select
          value={pageSize}
          onChange={event => onChangePaging(RXTABLEFIELD_PAGE_SIZE, event.target.value)
          }
        >
          {DEFAULT_PAGE_SIZE_OPTIONS.map(item => (
            <MenuItem value={item} key={item}>
              {item}
              &nbsp;
              {LocalizedString.common.labelRows}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }

  renderTablePagination = () => {
    const {
      classes, activePage, totalPage, onChangePaging,
    } = this.props;

    return (
      <div className={classes.tablePagination}>
        <Button
          variant="contained"
          style={{ flexGrow: 1 }}
          disabled={activePage === 1}
          onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage - 1)}
        >
          {LocalizedString.common.labelPagingPrevButton}
        </Button>

        <div className={classes.tablePaginationPageInput}>
          <span style={{ fontWeight: 500 }}>{LocalizedString.common.labelPagingPage}</span>
          <Input
            type="number"
            value={activePage}
            style={{ width: '50%' }}
            inputProps={{
              min: 1,
              max: totalPage,
              step: 1,
              style: { textAlign: 'center' },
            }}
            onChange={({ nativeEvent }) => onChangePaging(
              RXTABLEFIELD_ACTIVE_PAGE,
              parseInt(nativeEvent.target.value, 10),
            )}
          />
          <span style={{ fontWeight: 500 }}>
            {`${LocalizedString.common.labelPagingPageOf} ${totalPage}`}
          </span>
        </div>

        <Button
          variant="contained"
          style={{ flexGrow: 1 }}
          disabled={activePage === totalPage}
          onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage + 1)}
        >
          {LocalizedString.common.labelPagingNextButton}
        </Button>
      </div>
    );
  }

  handleChangeSort = (newSortField) => {
    const { sortField, sortDirection, onChangeSort } = this.props;
    if (newSortField === sortField) {
      onChangeSort(
        sortField,
        sortDirection === TABLE_SORT_ASCENDING
          ? TABLE_SORT_DESCENDING
          : TABLE_SORT_ASCENDING,
      );
    } else {
      onChangeSort(newSortField, TABLE_SORT_ASCENDING);
    }
  };

  renderTableCellHeader = (label, fieldId) => {
    const {
      sortField,
      sortDirection,
    } = this.props;
    return (
      <TableCell>
        <TableSortLabel
          active={
            isSortFieldActive(sortField, fieldId)
          }
          IconComponent={getSortIcon(
            isSortFieldActive(sortField, fieldId),
            sortDirection,
          )}
          onClick={() => this.handleChangeSort(fieldId)}
        >
          {label}
        </TableSortLabel>
      </TableCell>
    );
  }

  renderTableCellStringFilter = (field) => {
    const {
      filters,
      onChangeFilter,
    } = this.props;
    return (
      <TableCell>
        <Input
          type="text"
          fullWidth
          value={filters[field]}
          onChange={
            ({ nativeEvent }) => onChangeFilter(
              field,
              nativeEvent.target.value,
            )
          }
          placeholder={LocalizedString.common.placeholderSearch}
        />
      </TableCell>
    );
  }

  renderTableCellBody = (text, className) => (
    <TableCell className={className}>
      <Typography>
        {text || '-'}
      </Typography>
    </TableCell>
  );

  render() {
    const {
      classes,
      open,

      paymentDetail,

      onClose,
    } = this.props;

    const {
      labelAmount,
      labelBlNo,
      labelInvoiceNo,
      labelInvoiceName,
      labelService,
    } = LocalizedString.adminPaymentHistory;

    return (
      <>
        <Dialog
          open={open}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
              }}
            >
              <Typography color="primary" variant="h6">
                Payment Detail
              </Typography>
              <IconButton onClick={onClose}>
                <Icon>close</Icon>
              </IconButton>
            </div>
          </DialogTitle>

          <DialogContent>
            {this.renderTableItemPerRow()}

            <div style={{ width: '100%', overflow: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {this.renderTableCellHeader(labelBlNo, TABLE_FIELD_ADMIN_PAYMENT_HISTORY_DETAIL_BL_NO)}
                    {this.renderTableCellHeader(labelInvoiceNo, TABLE_FIELD_ADMIN_PAYMENT_HISTORY_DETAIL_INVOICE_NO)}
                    {this.renderTableCellHeader(labelAmount, TABLE_FIELD_ADMIN_PAYMENT_HISTORY_DETAIL_AMOUNT)}
                    {this.renderTableCellHeader(labelService, TABLE_FIELD_ADMIN_PAYMENT_HISTORY_DETAIL_SERVICE)}
                    {this.renderTableCellHeader(labelInvoiceName, TABLE_FIELD_ADMIN_PAYMENT_HISTORY_DETAIL_INVOICE_NAME)}
                  </TableRow>
                  <TableRow>
                    {this.renderTableCellStringFilter(TABLE_FIELD_ADMIN_PAYMENT_HISTORY_DETAIL_BL_NO)}
                    {this.renderTableCellStringFilter(TABLE_FIELD_ADMIN_PAYMENT_HISTORY_DETAIL_INVOICE_NO)}
                    {this.renderTableCellStringFilter(TABLE_FIELD_ADMIN_PAYMENT_HISTORY_DETAIL_AMOUNT)}
                    {this.renderTableCellStringFilter(TABLE_FIELD_ADMIN_PAYMENT_HISTORY_DETAIL_SERVICE)}
                    {this.renderTableCellStringFilter(TABLE_FIELD_ADMIN_PAYMENT_HISTORY_DETAIL_INVOICE_NAME)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentDetail && paymentDetail.map(item => (
                    <TableRow>
                      {this.renderTableCellBody(item.blNumber)}
                      {this.renderTableCellBody(item.invoiceNo)}
                      {this.renderTableCellBody(toCurrency(item.amount), classes.alignRight)}
                      {this.renderTableCellBody(item.service)}
                      {this.renderTableCellBody(item.paymentOnBehalfOf)}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>

            {this.renderTablePagination()}
          </DialogContent>
        </Dialog>
      </>
    );
  }
}
PaymentDetailDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  open: PropTypes.bool.isRequired,

  paymentDetail: PropTypes.arrayOf(PropTypes.any).isRequired,

  pageSize: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  filters: PropTypes.shape(PropTypes.any).isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,

  onChangeFilter: PropTypes.func.isRequired,
  onChangePaging: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(PaymentDetailDialog);
