import {
  SET_TARIFF_SIMULATION_RELEASE_SEARCH_SEARCH_TEXT,
  DOWNLOADING_TARIFF_SIMULATION_RELEASE_SEARCH,
  DOWNLOADING_TARIFF_SIMULATION_HOST_BL,
  SHOW_HOST_BL_CARD,
  SUBMITTING_HOST_BL,
} from '../action';

const initialState = {
  searchBarText: '',
  downloading: false,
  downloadingHostBL: false,
  showHostBLCard: false,
  submittingHostBL: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TARIFF_SIMULATION_RELEASE_SEARCH_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case DOWNLOADING_TARIFF_SIMULATION_RELEASE_SEARCH:
      return { ...state, downloading: action.value };
    case DOWNLOADING_TARIFF_SIMULATION_HOST_BL:
      return { ...state, downloadingHostBL: action.status };
    case SHOW_HOST_BL_CARD:
      return { ...state, showHostBLCard: action.visible };
    case SUBMITTING_HOST_BL:
      return { ...state, submittingHostBL: action.status };
    default:
      return state;
  }
};
