import * as Helper from '../../../helper';
import { addTariffSimulationOrder, downloadingTariffSimulationOrder } from '../simple-action';


export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingTariffSimulationOrder(true));
    const { token } = getState().authentication;
    const tariffSimulationOrder = await Helper.downloadTariffSimulationOrder(token);

    if (tariffSimulationOrder) {
      dispatch(addTariffSimulationOrder(tariffSimulationOrder));
    }
  } finally {
    dispatch(downloadingTariffSimulationOrder(false));
  }
};
