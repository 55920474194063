import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  Typography,
  Icon,
  Button,
} from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import { LoadingIndicator } from '../../component';
import LocalizedString from '../../localization';
import {
  DEFAULT_STYLES,
  RXFIELD_FORM_ORDER,
  RXFIELD_FORM_TITLE,
  RXFIELD_FORM_DESCRIPTION,
  RXFIELD_FORM_STATUS,
  status,
  RXFORM_FORM_EDIT,
} from '../../constant';
import {
  renderReduxFormTextField,
  renderReduxFormTextArea,
  renderReduxFormFilterableSelectField,
} from '../../helper';

const styles = () => ({
  btnPrimary: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  dialogActions: {
    margin: '0 -24px',
    marginTop: '24px',
    padding: 24,
    borderTop: '1px solid #C4C4C4',
    justifyContent: 'space-between',
    paddingBottom: 0,
    display: 'flex',
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
});

const FormEditDialog = ({
  classes,
  open,
  loading,
  currentUserRole,

  handleSubmit,
  onSubmitPressed,
  onAppear,
  onClose,
  onStatusSelected,
}) => (
  <>
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onEnter={() => onAppear(currentUserRole)}
    >
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <Typography color="primary" variant="h6">
            {LocalizedString.form.editTite}
          </Typography>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit(onSubmitPressed)}>
          <Field
            name={RXFIELD_FORM_ORDER}
            component={renderReduxFormTextField}
            label={LocalizedString.form.labelOrder}
            required
            type="number"
          />
          <Field
            name={RXFIELD_FORM_TITLE}
            component={renderReduxFormTextField}
            label={LocalizedString.form.labelTitle}
            required
          />
          <Field
            name={RXFIELD_FORM_DESCRIPTION}
            component={renderReduxFormTextArea}
            label={LocalizedString.form.labelDescription}
            required
          />
          <Field
            name={RXFIELD_FORM_STATUS}
            component={renderReduxFormFilterableSelectField}
            options={status}
            label={LocalizedString.form.labelStatus}
            onValueChange={onStatusSelected}
          />
          <div className={classes.dialogActions}>
            <div style={{ flexGrow: 1 }} />
            <div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.btnPrimary}
                disabled={loading}
              >
                Update
              </Button>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
    {loading && <LoadingIndicator />}
  </>
);
FormEditDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onStatusSelected: PropTypes.func.isRequired,
};

export default reduxForm({
  form: RXFORM_FORM_EDIT,
  enableReinitialize: true,
})(withStyles(styles)(FormEditDialog));
