import { downloadingAdminPayableBlPaymentOwner, setAdminPayableBlPaymentOwner } from '../simple-action';
import { downloadAdminPayableBlPaymentOwner } from '../../../helper';

export default companyId => async (dispatch, getState) => {
  try {
    dispatch(downloadingAdminPayableBlPaymentOwner(true));

    const { token } = getState().authentication;
    const result = await downloadAdminPayableBlPaymentOwner(companyId, token);
    dispatch(setAdminPayableBlPaymentOwner(result));
  } finally {
    dispatch(downloadingAdminPayableBlPaymentOwner(false));
  }
}
