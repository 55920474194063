import { connect } from 'react-redux';
import ContainerDetailDialog from './container-detail-dialog.presentation';
import {
  setContainerDetailTabIndex,
  downloadReeferDataDetailsAsync,
  setUIError,
  downloadFeederServiceOrderDetailAsync,
} from '../../redux/action';
import { RXSTATE_CONTAINER_TRACKING_DIALOG } from '../../constant';

const getContainerInfo = state => state
  .containerTrackingDetail[state.uiContainerTracking.tappedItemNo] || {};

const isFeederServiceAvailable = (state) => {
  const { tappedItemNo } = state.uiContainerTracking;
  const { feederServiceOrderDetail } = state;
  const container = feederServiceOrderDetail[tappedItemNo];
  const { downloading } = state.uiFeederServiceDetail;
  return !!container && !downloading;
};

const mapStateToProps = state => ({
  selectedTab: state.uiContainerTrackingDialog.tappedTabIndex,
  downloadingReeferData: state.uiReeferDataContainer.downloadingReeferDataDetails,
  containerInfo: getContainerInfo(state),
  isFeederServiceAvailable: isFeederServiceAvailable(state),
});

const mapDispatchToProps = dispatch => ({
  onAppear: (blNo, containerNo) => {
    try {
      dispatch(downloadReeferDataDetailsAsync(blNo, containerNo));
      dispatch(downloadFeederServiceOrderDetailAsync(blNo));
    } catch (error) {
      dispatch(setUIError(RXSTATE_CONTAINER_TRACKING_DIALOG, error.message));
    }
  },
  onTabPressed: (tappedTabIndex) => {
    dispatch(setContainerDetailTabIndex(tappedTabIndex));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerDetailDialog);
