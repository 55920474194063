import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  Typography,
  Icon,
} from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import { LoadingIndicator } from '../../component';
import LocalizedString from '../../localization';
import {
  DEFAULT_STYLES,
  RXFIELD_FIELD_FORM,
  RXFIELD_FIELD_NAME,
  RXFIELD_FIELD_LABEL,
  RXFIELD_FIELD_ORDER,
  RXFIELD_FIELD_REQUIRED,
  RXFIELD_FIELD_TYPE,
  BOOLEAN_OPTION,
  type,
  RXFORM_FIELD,
} from '../../constant';
import {
  renderReduxFormTextField,
} from '../../helper';

const styles = () => ({
  btnPrimary: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  dialogActions: {
    margin: '0 -24px',
    marginTop: '24px',
    padding: 24,
    borderTop: '1px solid #C4C4C4',
    justifyContent: 'space-between',
    paddingBottom: 0,
    display: 'flex',
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
});

const FieldDetailDialog = ({
  open,
  loading,
  forms,
  currentUserRole,

  onAppear,
  onClose,
}) => (
  <>
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onEnter={() => onAppear(currentUserRole)}
    >
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <Typography color="primary" variant="h6">
            {LocalizedString.field.detailTite}
          </Typography>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <Field
          name={RXFIELD_FIELD_FORM}
          component={renderReduxFormTextField}
          options={forms}
          label={LocalizedString.field.labelForm}
          disabled
        />
        <Field
          name={RXFIELD_FIELD_NAME}
          component={renderReduxFormTextField}
          label={LocalizedString.field.labelName}
          required
          disabled
        />
        <Field
          name={RXFIELD_FIELD_LABEL}
          component={renderReduxFormTextField}
          label={LocalizedString.field.labelLabel}
          required
          disabled
        />
        <Field
          name={RXFIELD_FIELD_ORDER}
          component={renderReduxFormTextField}
          label={LocalizedString.field.labelOrder}
          required
          type="number"
          disabled
        />
        <Field
          name={RXFIELD_FIELD_REQUIRED}
          component={renderReduxFormTextField}
          options={BOOLEAN_OPTION}
          label={LocalizedString.field.labelRequired}
          required
          disabled
        />
        <Field
          name={RXFIELD_FIELD_TYPE}
          component={renderReduxFormTextField}
          options={type}
          label={LocalizedString.field.labelType}
          required
          disabled
        />
      </DialogContent>
    </Dialog>
    {loading && <LoadingIndicator />}
  </>
);
FieldDetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,

  onAppear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default reduxForm({
  form: RXFORM_FIELD,
  enableReinitialize: true,
})(withStyles(styles)(FieldDetailDialog));
