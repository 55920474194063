import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import {
  withStyles,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  Icon,
  DialogContent,
  Button,
} from '@material-ui/core';
import LocalizedString from '../../localization';
import { renderReduxFormTextField, renderReduxFormFilterableSelectField, renderCheckbox } from '../../helper';
import {
  RXFIELD_SO_NO,
  RXFIELD_BL_NO,
  RXFIELD_FORWARDER_ID,
  RXFORM_ASSIGN_CLEARANCE,
  RXFIELD_IS_TRUCKER,
  RXFIELD_TRUCKER_ID,
  DEFAULT_STYLES,
} from '../../constant';

const styles = () => ({
  btnPrimary: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  dialogActions: {
    margin: '0 -24px',
    marginTop: '24px',
    padding: 24,
    borderTop: '1px solid #C4C4C4',
    justifyContent: 'space-between',
    paddingBottom: 0,
    display: 'flex',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const AssignBlDialog = ({
  classes,
  open,
  submitting,

  lstPpjk,
  lstTrucker,
  isTruckerChecked,

  handleSubmit,
  onSubmitPressed,
  onClose,
}) => (
  <>
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <div className={classes.dialogHeader}>
          <Typography color="primary" variant="h6">
            Assign Multiple Document
          </Typography>
          <IconButton
            onClick={onClose}
          >
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <form>
          <Field
            name={RXFIELD_SO_NO}
            component={renderReduxFormTextField}
            label={LocalizedString.assign.labelSoNo}
            disabled
          />
          <Field
            name={RXFIELD_BL_NO}
            component={renderReduxFormTextField}
            label={LocalizedString.assign.labelBlNumber}
            disabled
          />
          <Field
            name={RXFIELD_FORWARDER_ID}
            component={renderReduxFormFilterableSelectField}
            options={lstPpjk}
            label={LocalizedString.assign.labelPpjk}
            required
          />
          <Field
            name={RXFIELD_IS_TRUCKER}
            component={renderCheckbox}
            label={LocalizedString.assign.labelTruckerCheckbox}
          />
          {isTruckerChecked && (
            <Field
              name={RXFIELD_TRUCKER_ID}
              component={renderReduxFormFilterableSelectField}
              options={lstTrucker}
              label={LocalizedString.assign.labelTrucker}
            />
          )}
        </form>
        <div className={classes.dialogActions}>
          <div style={{ flexGrow: 1 }} />
          <div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.btnPrimary}
              onClick={handleSubmit(onSubmitPressed)}
              disabled={submitting}
            >
              Submit
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  </>
);
AssignBlDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  open: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,

  lstPpjk: PropTypes.arrayOf(PropTypes.any).isRequired,
  lstTrucker: PropTypes.arrayOf(PropTypes.any).isRequired,
  isTruckerChecked: PropTypes.bool.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default reduxForm({
  form: RXFORM_ASSIGN_CLEARANCE,
  enableReinitialize: true,
})(withStyles(styles)(AssignBlDialog));
