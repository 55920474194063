import _ from 'lodash';
import { downloadingExportPriceDetail, addExportPriceDetail } from '../simple-action';
import * as Helper from '../../../helper';

export default blNo => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const selectedBl = _.values(getState().exportOrderHistory)
    .find(item => item.coRef === blNo);
  try {
    dispatch(downloadingExportPriceDetail(true));
    const exportPriceDetail = await Helper.downloadExportPriceDetail(selectedBl.blNo, selectedBl.paymentOnBehalf, token);
    dispatch(addExportPriceDetail(exportPriceDetail));
  } finally {
    dispatch(downloadingExportPriceDetail(false));
  }
};
