import { retryingErpPayment, setErpPaymentLogConfirmRetryDialog } from '../simple-action';
import * as Helper from '../../../helper';
import downloadErpPaymentLogDetailAsync from './downloadErpPaymentLogDetailAsync';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { selectedErpPaymentId } = getState().uiErpPaymentLog;

  try {
    dispatch(retryingErpPayment(true));
    await Helper.retryErpPayment(selectedErpPaymentId, token);
    dispatch(setErpPaymentLogConfirmRetryDialog(false));
    dispatch(downloadErpPaymentLogDetailAsync(selectedErpPaymentId));
  } finally {
    dispatch(retryingErpPayment(false));
  }
};
