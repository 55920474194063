import { downloadingAdminPayableBl, setAdminPayableBl, clearAdminPayableBl } from '../simple-action';
import { downloadAdminPayableBl } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAdminPayableBl(true));

    const { token } = getState().authentication;

    const data = await downloadAdminPayableBl('', token);
    dispatch(setAdminPayableBl(data));
  } finally {
    dispatch(downloadingAdminPayableBl(false));
  }
};
