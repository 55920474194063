import * as Helper from '../../../helper';
import { addUser, downloadingMasterUser } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingMasterUser(true));
    const { token } = getState().authentication;
    const { userSearchString } = getState().uiFormResponse;
    const profiles = await Helper.downloadAllProfiles(token, userSearchString);

    profiles.forEach((userInfo) => {
      dispatch(addUser(userInfo));
    });

    return profiles.map(x => ({
      value: x.id,
      label: x.fullName,
    }));
  } finally {
    dispatch(downloadingMasterUser(false));
  }
};
