import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  withStyles,
  CssBaseline,
  Grid,
  Card,
  CardContent,
  Fab,
  Icon,
  Typography,
  IconButton,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  CardActions,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Checkbox,
} from '@material-ui/core';
import _ from 'lodash';
import {
  PageHeaderUser,
  TopUpDialog,
  PdfViewer,
  LoadingIndicator,
  BankOptionDialog,
  MoneyLoanTncDialog,
  CheckoutTncDialog,
} from '../../component';
import {
  ROUTE_NAME_DASHBOARD_USER,
  DRAWER_NOTIFICATION_WIDTH,
  DEFAULT_STYLES,
  PAYMENT_TYPE_DEPOSIT,
  PAYMENT_TYPE_CASH,
  ROLE_PPJK,
  MONEY_LOAN_STATUS_CLEAR,
  MONEY_LOAN_STATUS_NOT_APPLICABLE,
  COLOR_PRIMARY,
} from '../../constant';
import LocalizedString from '../../localization';
import { toCurrency } from '../../helper';
import ConfirmationDialog from './confirmation-dialog';
import { MoneyLoanInfoShape } from '../../type';

const styles = theme => ({
  backIcon: {
    ...DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  balanceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 4,
    borderBottom: '1px solid #E5E5E5',
    alignItems: 'center',
  },
  btnSubmit: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  buttonTopUp: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
    marginBottom: 8,
  },
  btnPay: {
    backgroundColor: '#5CB860',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#558b2f',
    },
  },
  btnPaymentHistory: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
    textTransform: 'initial',
  },
  upperCase: {
    textTransform: 'uppercase',
  },
  centeredActions: {
    justifyContent: 'center',
    paddingBottom: '24px',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  currencyColor: {
    color: '#3f51b5',
  },
  errorMessage: {
    marginTop: 8,
    color: '#ff0000',
  },
  labelDeposit: {
    marginTop: '8px',
    fontWeight: 'bold',
  },
  labelBalance: {
    marginTop: 4,
    marginBottom: 8,
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    color: '#0266B4',
  },
  paymentCompleteTitle: {
    textTransform: 'uppercase',
    color: '#5CB860',
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '16px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  paymentConfirmTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '16px',
  },
  paymentSuccessInfoItems: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 0px',
    borderBottom: '1px solid #EEEEEE',
  },
  root: {
    width: '100%',
  },
  selectAllContainer: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: '-24px',
    zIndex: 1,
  },
  paper: {
    marginTop: '24px',
    '&:nth-child(1)': {
      marginTop: 0,
    },
  },
  cardPaymentMethod: {
    marginTop: '-24px',
    marginBottom: '16px',
  },
  cartItemHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '24px',
    flexGrow: 1,
    paddingRight: '8px !important',
  },
  cartItemDetail: {
    display: 'flex',
    alignItems: 'baseline',
    borderTop: '1px solid #EEEEEE',
    padding: '16px 8px',
    flexGrow: 1,
  },
  expandIcon: {
    transition: 'transform 0.2s',
  },
  expandIconActivated: {
    transform: 'rotate(-180deg)',
  },
  leftColumn: {
    padding: '0 12px',
    width: '96px',
  },
  rightColumn: {
    padding: '0 12px',
    borderLeft: '1px solid silver',
    flexGrow: 1,
  },
  rightColumnDetail: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  noBorder: {
    border: 'none',
  },
  expansionPanelSummary: {
    padding: 0,
    margin: '0px !important',
  },
  expansionPanelSummaryExpanded: {
    margin: '0px !important',
  },
});

const calculateTotalPayment = shoppingCart => _.flatMap(shoppingCart)
  .reduce((accumulated, current) => accumulated + current.amount, 0);

const CartPage = ({
  classes,
  error,
  notificationOpen,
  userCompany,
  password,
  selectedBank,
  shoppingCheckout,
  expandedBlNumber,
  paymentResult,
  uploadingPayment,
  dialogPaymentConfirmStatus,
  dialogPaymentCompleteStatus,
  dialogPaymentSelectBankStatus,
  dialogConfirmClosePaymentStatus,
  dialogTopUpStatus,
  moneyLoanInfo,
  termAndConditionChecked,

  onChangePasswordText,
  onExpansionPanelPressed,
  onPayPressed,
  onCancelPressed,
  onSubmitPressed,
  onGoToPaymentHistoryPressed,
  onOkClosePaymentPressed,
  onCancelClosePaymentPressed,
  onBanOptionkSelected,
  onOkBankOptionPressed,
  onCancelBankOptionPressed,
  onCheckboxPressed,
  onTopUpPressed,
  onTopUpClosePressed,
  onMoneyLoanPressed,
  onTermAndConditionPressed,
}) => {
  const isPpjkAndAllAllowDeposit = userCompany.role.toUpperCase() === ROLE_PPJK
      && Object.values(shoppingCheckout)
        .every(blNumber => blNumber.every(({ item }) => item.allowPpjkToUseDeposit));

  const renderCheckoutItemDetail = items => (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {items && items.map(item => (
        <div className={classes.cartItemDetail}>
          <div style={{ flexGrow: 1, display: 'flex' }}>
            <div className={classes.leftColumn}>
              <Typography>
                {item.service}
              </Typography>
            </div>
            <div
              className={
                classNames(classes.rightColumn,
                  classes.rightColumnDetail,
                  classes.noBorder)
              }
            >
              <div>
                <Typography variant="caption">
                  {LocalizedString.cart.labelPaymentExpect}
                </Typography>
                <Typography>
                  Rp.&nbsp;
                  {toCurrency(item.amount)}
                </Typography>
              </div>
              <div style={{ textAlign: 'right' }}>
                <Typography variant="caption">
                  {LocalizedString.cart.labelStatus}
                </Typography>
                <Typography>Confirmed</Typography>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const renderCheckoutItemExpansible = (blNumber, cartItem) => (
    <ExpansionPanel
      className={classes.paper}
      expanded={expandedBlNumber.includes(blNumber)}
    >
      <ExpansionPanelSummary
        classes={{
          root: classes.expansionPanelSummary,
          content: classes.expansionPanelSummaryExpanded,
        }}
        onClick={() => onExpansionPanelPressed(blNumber, expandedBlNumber)}
      >
        <div className={classes.cartItemHeader}>
          <div style={{ flexGrow: 1, display: 'flex' }}>
            <div className={classes.leftColumn}>
              <Typography>{LocalizedString.cart.labelBlNumber}</Typography>
            </div>
            <div className={classes.rightColumn}>
              <Typography color="primary">
                <strong>{blNumber}</strong>
              </Typography>
              <br />
              <Typography variant="caption">
                {`${cartItem.length} item(s)`}
              </Typography>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <IconButton className={
              classNames(classes.expandIcon,
                {
                  [classes.expandIconActivated]: expandedBlNumber.includes(blNumber),
                })
              }
            >
              <Icon>expand_more</Icon>
            </IconButton>
          </div>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expansionPanelSummary}>
        {renderCheckoutItemDetail(cartItem)}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );

  const renderPaymentMethod = () => {
    if (userCompany && userCompany.paymentMethod === PAYMENT_TYPE_DEPOSIT) {
      const { balance } = userCompany.depositAccount;
      return (
        <>
          <Card className={classes.cardPaymentMethod}>
            <CardContent>
              <Typography variant="body2">Deposit</Typography>
              <div className={classes.balanceContainer}>
                <div>
                  <Typography className={classes.labelDeposit}>
                  Your balance
                  </Typography>
                  <Typography className={classes.labelBalance}>
                  IDR&nbsp;
                    <span className={classes.currencyColor}>{toCurrency(balance)}</span>
                  </Typography>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onTopUpPressed}
                  className={classes.buttonTopUp}
                >
                  {LocalizedString.depositHistory.buttonCaptionTopUp}
                &nbsp;
                  <Icon>account_balance_wallet</Icon>
                </Button>
              </div>
            </CardContent>
          </Card>
          { moneyLoanInfo.status !== MONEY_LOAN_STATUS_NOT_APPLICABLE && (
          <Card style={{ marginTop: '16px', marginBottom: '16px' }}>
            <CardContent>
              <Typography variant="body2">{LocalizedString.checkout.labelLoan}</Typography>
              <div className={classes.balanceContainer}>
                <div>
                  <Typography className={classes.labelDeposit}>
                    {LocalizedString.checkout.labelOutstandingBalance}
                  </Typography>
                  <Typography className={classes.labelBalance}>
                  IDR&nbsp;
                    <span className={classes.currencyColor}>
                      {toCurrency(moneyLoanInfo.loan) || 0}
                    </span>
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>
          )}
        </>
      );
    }
    if (userCompany && userCompany.paymentMethod === PAYMENT_TYPE_CASH) {
      return (
        <Card className={classes.cardPaymentMethod}>
          <CardContent>
            <Typography variant="body2" className={classes.upperCase}>
              {LocalizedString.checkout.labelPaymentMethod}
            </Typography>
            <Typography className={classes.labelDeposit}>Cash</Typography>
          </CardContent>
        </Card>
      );
    }
    return null;
  };

  const renderCheckboxAgreement = () => (
    <div style={{
      display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: -15,
    }}
    >
      <Checkbox
        checked={termAndConditionChecked}
        onClick={() => { onCheckboxPressed(termAndConditionChecked); }}
      />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Typography variant="body2">
          {LocalizedString.checkout.labelTermAndCondition}
        </Typography>
        <Button
          variant="text"
          onClick={() => { onTermAndConditionPressed(); }}
          style={{
            minHeight: 0,
            minWidth: 0,
            padding: 0,
            margin: 2,
            textTransform: 'none',
            color: COLOR_PRIMARY,
          }}
        >
          {LocalizedString.checkout.buttonCaptionTermAndCondition}
        </Button>
        <Typography variant="body2">
          {LocalizedString.checkout.labelTermAndCondition2}
        </Typography>
      </div>
    </div>
  );

  const renderTotalPayment = () => {
    const { paymentMethod, depositAccount, staticVa } = userCompany;
    const balance = depositAccount ? depositAccount.balance : 0;
    const sumAmount = calculateTotalPayment(shoppingCheckout);
    const isClearToLoan = moneyLoanInfo.status === MONEY_LOAN_STATUS_CLEAR;
    const isDepositUser = paymentMethod === PAYMENT_TYPE_DEPOSIT;

    const checkPayButton = () => {
      if (Object.keys(shoppingCheckout).length === 0) {
        return {
          caption: LocalizedString.checkout.btnPay,
          onPress: () => onPayPressed(staticVa),
          disabled: true,
        };
      }
      if (isDepositUser && balance > sumAmount) {
        return {
          caption: LocalizedString.checkout.btnPay,
          onPress: () => onPayPressed(staticVa),
          disabled: termAndConditionChecked === false,
        };
      } if (isDepositUser && isClearToLoan) {
        return {
          caption: LocalizedString.checkout.buttonCaptionMoneyLoan,
          onPress: () => onMoneyLoanPressed(),
          disabled: false,
        };
      } if (isDepositUser && balance < sumAmount) {
        return {
          caption: LocalizedString.checkout.btnPay,
          onPress: () => onPayPressed(staticVa),
          disabled: true,
        };
      }
      return {
        caption: LocalizedString.checkout.btnPay,
        onPress: () => onPayPressed(staticVa),
        disabled: termAndConditionChecked === false,
      };
    };
    return (
      <Card>
        <CardContent>
          { (isDepositUser && isClearToLoan) ? null : renderCheckboxAgreement() }
          <Typography variant="body2" className={classes.upperCase}>
            {LocalizedString.cart.labelTotalPayment}
          </Typography>
          <Typography variant="h6" color="primary">
            Rp.&nbsp;
            {toCurrency(sumAmount)}
          </Typography>
        </CardContent>
        <CardActions style={{ borderTop: '1px solid #EEEEEE' }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            className={classes.btnPay}
            disabled={checkPayButton().disabled}
            onClick={checkPayButton().onPress}
          >
            {checkPayButton().caption}
          </Button>
        </CardActions>
      </Card>
    );
  };

  const renderPaymentConfirmationDialogContent = () => {
    if (isPpjkAndAllAllowDeposit) {
      const checkoutItem = Object.values(shoppingCheckout).shift();
      const billToName = checkoutItem && checkoutItem.length > 0 ? checkoutItem[0].item.billTo : '';
      return (
        <>
          <Typography className={classes.paymentConfirmTitle}>
            {`${LocalizedString.cart.msgEnterPinAllowPPJKTrue} ${billToName}`}
          </Typography>
          <TextField
            type="password"
            label="PIN"
            value={password}
            fullWidth
            InputProps={{
              endAdornment: (<InputAdornment position="end"><Icon color="disabled">lock</Icon></InputAdornment>),
            }}
            onChange={({ nativeEvent }) => { onChangePasswordText(nativeEvent.target.value); }}
          />
          {error && (
            <Typography className={classes.errorMessage}>{error}</Typography>
          )}
        </>
      );
    }

    if (userCompany.paymentMethod === PAYMENT_TYPE_DEPOSIT) {
      return (
        <>
          <Typography className={classes.paymentConfirmTitle}>
            Please enter your PIN
          </Typography>
          <TextField
            type="password"
            label="PIN"
            value={password}
            fullWidth
            InputProps={{
              endAdornment: (<InputAdornment position="end"><Icon color="disabled">lock</Icon></InputAdornment>),
            }}
            onChange={({ nativeEvent }) => { onChangePasswordText(nativeEvent.target.value); }}
          />
          {error && (
            <Typography className={classes.errorMessage}>{error}</Typography>
          )}
        </>
      );
    }

    return (
      <>
        <Typography className={classes.paymentConfirmTitle}>
          Please enter your login password
        </Typography>
        <TextField
          type="password"
          label="Password"
          value={password}
          fullWidth
          InputProps={{
            endAdornment: (<InputAdornment position="end"><Icon color="disabled">lock</Icon></InputAdornment>),
          }}
          onChange={({ nativeEvent }) => { onChangePasswordText(nativeEvent.target.value); }}
        />
        {error && (
          <Typography className={classes.errorMessage}>{error}</Typography>
        )}
      </>
    );
  };

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />

        <PageHeaderUser />

        <div
          className={
            classNames(classes.content, {
              [classes.contentDrawerOpen]: notificationOpen,
              [classes.contentDrawerClosed]: !notificationOpen,
            })
          }
          style={{ padding: '16px' }}
        >
          <Grid container spacing={24}>
            <Grid item xs={12} style={{ zIndex: 3 }}>
              <Card>
                <CardContent>
                  <div className={classes.pageNavigatorContainer}>
                    <Fab
                      variant="round"
                      classes={{ root: classes.backIcon }}
                      component={Link}
                      to={ROUTE_NAME_DASHBOARD_USER}
                    >
                      <Icon>chevron_left</Icon>
                    </Fab>
                    <div style={{ marginLeft: '32px' }}>
                      <Typography variant="h5" className={classes.pageTitle}>{LocalizedString.checkout.title}</Typography>
                      <Typography variant="caption">{LocalizedString.checkout.caption}</Typography>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              {Object.keys(shoppingCheckout)
                .map(key => renderCheckoutItemExpansible(key, shoppingCheckout[key]))}
            </Grid>
            <Grid item xs={12} md={4}>
              {renderPaymentMethod()}
              {renderTotalPayment()}
            </Grid>
          </Grid>
        </div>
      </div>

      <Dialog
        open={dialogPaymentConfirmStatus}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          {renderPaymentConfirmationDialogContent()}

        </DialogContent>
        <DialogActions className={classes.centeredActions}>
          <Button
            color="primary"
            onClick={() => onCancelPressed()}
          >
            {LocalizedString.common.buttonCaptionCancel}
          </Button>
          &emsp;&emsp;
          <Button
            variant="contained"
            color="primary"
            className={classes.btnSubmit}
            onClick={() => onSubmitPressed(password, isPpjkAndAllAllowDeposit)}
          >
            {LocalizedString.common.buttonCaptionSubmit}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogPaymentCompleteStatus}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <Typography className={classes.paymentCompleteTitle}>
            <Icon>check_circle</Icon>
            &emsp;Payment Success
          </Typography>

          <div style={{ marginTop: '48px' }}>
            <div className={classes.paymentSuccessInfoItems}>
              <Typography>Proforma No</Typography>
              <Typography variant="caption">
                {paymentResult && paymentResult.proformaNo}
              </Typography>
            </div>
            <div className={classes.paymentSuccessInfoItems}>
              <Typography>Payment Channel</Typography>
              <Typography variant="caption">
                {paymentResult && paymentResult.paymentChannel}
              </Typography>
            </div>
            <div className={classes.paymentSuccessInfoItems}>
              <Typography>Amount</Typography>
              <Typography className={classes.currencyColor}>
                Rp.&nbsp;
                {paymentResult && paymentResult.amount}
              </Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.centeredActions}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.btnPaymentHistory}
            onClick={() => onGoToPaymentHistoryPressed()}
          >
            {LocalizedString.checkout.btnGoToPaymentHistory}
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        open={dialogConfirmClosePaymentStatus}
        title={LocalizedString.common.alertTitleConfirmation}
        message={LocalizedString.common.msgConfirmCloseWindow}
        onOkPressed={onOkClosePaymentPressed}
        onCancelPressed={onCancelClosePaymentPressed}
      />

      <BankOptionDialog
        open={dialogPaymentSelectBankStatus}
        selectedBank={selectedBank}
        onBankOptionSelected={(value) => { onBanOptionkSelected(value); }}
        onOkPressed={onOkBankOptionPressed}
        onCancelPressed={onCancelBankOptionPressed}
      />

      <TopUpDialog
        open={dialogTopUpStatus}
        onClose={onTopUpClosePressed}
      />

      <PdfViewer />

      <MoneyLoanTncDialog />

      <CheckoutTncDialog />

      {uploadingPayment && <LoadingIndicator />}
    </>
  );
};
CartPage.propTypes = {
  moneyLoanInfo: MoneyLoanInfoShape.isRequired,
  classes: PropTypes.shape(PropTypes.any).isRequired,
  error: PropTypes.string.isRequired,
  termAndConditionText: PropTypes.string.isRequired,
  notificationOpen: PropTypes.bool.isRequired,
  shoppingCheckout: PropTypes.shape(PropTypes.any).isRequired,
  userCompany: PropTypes.shape(PropTypes.any).isRequired,
  password: PropTypes.string.isRequired,
  selectedBank: PropTypes.string.isRequired,
  expandedBlNumber: PropTypes.arrayOf(PropTypes.string).isRequired,
  paymentResult: PropTypes.instanceOf({
    paymentId: PropTypes.string.isRequired,
    proformaNo: PropTypes.string.isRequired,
    paymentChannel: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
  }).isRequired,
  uploadingPayment: PropTypes.bool.isRequired,
  dialogPaymentConfirmStatus: PropTypes.bool.isRequired,
  dialogPaymentCompleteStatus: PropTypes.bool.isRequired,
  dialogPaymentSelectBankStatus: PropTypes.bool.isRequired,
  dialogConfirmClosePaymentStatus: PropTypes.bool.isRequired,
  dialogTopUpStatus: PropTypes.bool.isRequired,
  dialogTermAndCondition: PropTypes.bool.isRequired,
  termAndConditionChecked: PropTypes.bool.isRequired,

  onChangePasswordText: PropTypes.func.isRequired,
  onExpansionPanelPressed: PropTypes.func.isRequired,
  onPayPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onGoToPaymentHistoryPressed: PropTypes.func.isRequired,
  onOkClosePaymentPressed: PropTypes.func.isRequired,
  onCancelClosePaymentPressed: PropTypes.func.isRequired,
  onBanOptionkSelected: PropTypes.func.isRequired,
  onOkBankOptionPressed: PropTypes.func.isRequired,
  onCancelBankOptionPressed: PropTypes.func.isRequired,
  onCheckboxPressed: PropTypes.func.isRequired,
  onTopUpPressed: PropTypes.func.isRequired,
  onTopUpClosePressed: PropTypes.func.isRequired,
  onMoneyLoanPressed: PropTypes.func.isRequired,
  onTermAndConditionPressed: PropTypes.func.isRequired,
  onTermAndConditionDialogClosePressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(CartPage);
