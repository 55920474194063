import { removingShoppingCartItem, removeShoppingCartItem } from '../simple-action';

export default itemIds => async (dispatch) => {
  try {
    dispatch(removingShoppingCartItem(true));

    itemIds.forEach((item) => {
      dispatch(removeShoppingCartItem(item.itemId));
    });
  } finally {
    dispatch(removingShoppingCartItem(false));
  }
};
