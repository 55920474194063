import { downloadingReeferDataDetails, addReeferDataDetails } from '../simple-action';
import * as Helper from '../../../helper';

export default (blNo, containerNo) => async (dispatch, getState) => {
  const { token } = getState().authentication;
  try {
    dispatch(downloadingReeferDataDetails(true));
    const reeferDataDetails = await Helper.downloadReeferDataDetails(blNo, containerNo, token);
    dispatch(addReeferDataDetails(reeferDataDetails));
  } finally {
    dispatch(downloadingReeferDataDetails(false));
  }
};
