import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
  downloadDepositBalanceAutoAsync,
  downloadMyNotificationAsync,
  setNotificationNextAutoUpdate,
  downloadingMyNotification,
  updatingDepositBalance,
  setDepositBalanceNextAutoUpdate,
} from '../../redux/action';

const mapDispatchToProps = dispatch => ({
  onAppear: async () => {
    // reset deposit balance state
    dispatch(updatingDepositBalance(false));
    dispatch(setDepositBalanceNextAutoUpdate(undefined));

    // reset notification state
    dispatch(downloadingMyNotification(false));
    dispatch(setNotificationNextAutoUpdate(undefined));

    dispatch(downloadDepositBalanceAutoAsync());
    dispatch(downloadMyNotificationAsync());
    setInterval(async () => {
      dispatch(downloadDepositBalanceAutoAsync());
      dispatch(downloadMyNotificationAsync());
    }, 1000);
  },
});

class Presentation extends Component {
  componentDidMount() {
    this.props.onAppear();
  }

  render = () => (<></>);
}
Presentation.propTypes = {
  onAppear: PropTypes.func.isRequired,
};

export default connect(() => ({}), mapDispatchToProps)(withRouter(Presentation));
