import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Card,
  CardHeader,
  Typography,
  CardContent,
  Grid,
} from '@material-ui/core';
import LocalizedString from '../../localization';
import { toMoment } from '../../helper';
import {
  COLOR_STATUS_DONE,
  COLOR_STATUS_HAS_BEEN_REVIEWED,
  COLOR_STATUS_NOT_PPK,
  COLOR_STATUS_ON_REVIEW,
  COLOR_STATUS_PPK,
  COLOR_STATUS_READY_TO_BE_REVIEWED,
  COLOR_STATUS_WAITING_LIST,
  MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING, TRACKING_STATUS_BEHANDLE_STATUS_DONE,
  TRACKING_STATUS_BEHANDLE_STATUS_HAS_BEEN_REVIEWED, TRACKING_STATUS_BEHANDLE_STATUS_NOT_PPK,
  TRACKING_STATUS_BEHANDLE_STATUS_ON_REVIEW, TRACKING_STATUS_BEHANDLE_STATUS_PPK,
  TRACKING_STATUS_BEHANDLE_STATUS_READY_TO_BE_REVIEWED,
  TRACKING_STATUS_BEHANDLE_STATUS_WAITING_LIST,
} from '../../constant';

const statusContainer = {
  width: '40%',
  borderRadius: 16,
  padding: 8,
  overflow: 'hidden',
  marginBottom: 16,
};

const styles = theme => ({
  cardBlDataHeader: {
    marginBottom: theme.spacing.unit * 2,
  },
  detailHeader: {
    color: '#0066CB',
  },
  text: {
    marginBottom: 16,
  },
  waitingListContainer: {
    backgroundColor: COLOR_STATUS_WAITING_LIST,
    ...statusContainer,
  },
  notPpkContainer: {
    backgroundColor: COLOR_STATUS_NOT_PPK,
    ...statusContainer,
  },
  ppkContainer: {
    backgroundColor: COLOR_STATUS_PPK,
    ...statusContainer,
  },
  readyToBeReviewedContainer: {
    backgroundColor: COLOR_STATUS_READY_TO_BE_REVIEWED,
    ...statusContainer,
  },
  onReviewContainer: {
    backgroundColor: COLOR_STATUS_ON_REVIEW,
    ...statusContainer,
  },
  hasBeenReviewedContainer: {
    backgroundColor: COLOR_STATUS_HAS_BEEN_REVIEWED,
    ...statusContainer,
  },
  doneContainer: {
    backgroundColor: COLOR_STATUS_DONE,
    ...statusContainer,
  },
  statusText: {
    textAlign: 'center',
  },
});

const OnProgressDetail = ({
  classes,
  selectedData,
}) => {
  const getStatusBackgroundColor = () => {
    switch (selectedData.status) {
      case TRACKING_STATUS_BEHANDLE_STATUS_WAITING_LIST:
        return classes.waitingListContainer;
      case TRACKING_STATUS_BEHANDLE_STATUS_NOT_PPK:
        return classes.notPpkContainer;
      case TRACKING_STATUS_BEHANDLE_STATUS_PPK:
        return classes.ppkContainer;
      case TRACKING_STATUS_BEHANDLE_STATUS_READY_TO_BE_REVIEWED:
        return classes.readyToBeReviewedContainer;
      case TRACKING_STATUS_BEHANDLE_STATUS_ON_REVIEW:
        return classes.onReviewContainer;
      case TRACKING_STATUS_BEHANDLE_STATUS_HAS_BEEN_REVIEWED:
        return classes.hasBeenReviewedContainer;
      case TRACKING_STATUS_BEHANDLE_STATUS_DONE:
      default: return classes.doneContainer;
    }
  };

  if (!selectedData) {
    return null;
  }

  return (
    <>
      <Card className={classes.cardBlDataHeader}>
        <CardHeader
          title={(
            <Grid container>
              <Grid item xs={6} md={6}>
                <Typography color="primary" variant="h6">
                  {LocalizedString.trackingStatusBehandle.labelContainerNo}
                </Typography>
                <Typography color="primary" variant="h6">
                  {selectedData.containerNo}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography color="primary" variant="h6">
                  {LocalizedString.trackingStatusBehandle.labelBlNo}
                </Typography>
                <Typography color="primary" variant="h6">
                  {selectedData.blNum}
                </Typography>
              </Grid>
            </Grid>
          )}
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs sm md>
              <Grid item>
                <Typography variant="caption">
                  {LocalizedString.trackingStatusBehandle.labelCustomer}
                </Typography>
                <Typography className={classes.text}>
                  {selectedData.customer || '-'}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="caption">
                  {LocalizedString.trackingStatusBehandle.labelPibNo}
                </Typography>
                <Typography className={classes.text}>
                  {selectedData.pibNum || '-'}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="caption">
                  {LocalizedString.trackingStatusBehandle.labelPibDate}
                </Typography>
                <Typography className={classes.text}>
                  {selectedData.timePib
                    ? toMoment(selectedData.timePib)
                      .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)
                    : '-'}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="caption">
                  {LocalizedString.trackingStatusBehandle.labelGateInDate}
                </Typography>
                <Typography className={classes.text}>
                  {selectedData.timeGateIn
                    ? toMoment(selectedData.timeGateIn)
                      .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)
                    : '-'}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="caption">
                  {LocalizedString.trackingStatusBehandle.labelGateOutDelivery}
                </Typography>
                <Typography className={classes.text}>
                  {selectedData.gateOutDelivery
                    ? toMoment(selectedData.gateOutDelivery)
                      .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)
                    : '-'}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="caption">
                  {LocalizedString.trackingStatusBehandle.labelReturnCyDate}
                </Typography>
                <Typography className={classes.text}>
                  {selectedData.timeReturnCy
                    ? toMoment(selectedData.timeReturnCy)
                      .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)
                    : '-'}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="caption">
                  {LocalizedString.trackingStatusBehandle.labelPendokDate}
                </Typography>
                <Typography className={classes.text}>
                  {selectedData.timePendok
                    ? toMoment(selectedData.timePendok)
                      .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)
                    : '-'}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="caption">
                  {LocalizedString.trackingStatusBehandle.labelStatus}
                </Typography>
                <div className={getStatusBackgroundColor()}>
                  <Typography className={classes.statusText}>
                    {selectedData.status || '-'}
                  </Typography>
                </div>
              </Grid>
            </Grid>

            <Grid item xs sm md>
              <Grid item>
                <Typography variant="caption">
                  {LocalizedString.trackingStatusBehandle.labelPpkDate}
                </Typography>
                <Typography className={classes.text}>
                  {selectedData.timePpk
                    ? toMoment(selectedData.timePpk)
                      .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)
                    : '-'}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="caption">
                  {LocalizedString.trackingStatusBehandle.labelPkbDate}
                </Typography>
                <Typography className={classes.text}>
                  {selectedData.timePkb
                    ? toMoment(selectedData.timePkb)
                      .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)
                    : '-'}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="caption">
                  {LocalizedString.trackingStatusBehandle.labelRelocationDate}
                </Typography>
                <Typography className={classes.text}>
                  {selectedData.timeRelocation
                    ? toMoment(selectedData.timeRelocation)
                      .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)
                    : '-'}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="caption">
                  {LocalizedString.trackingStatusBehandle.labelOpenSealDate}
                </Typography>
                <Typography className={classes.text}>
                  {selectedData.timeOpenSeal
                    ? toMoment(selectedData.timeOpenSeal)
                      .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)
                    : '-'}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="caption">
                  {LocalizedString.trackingStatusBehandle.labelCloseSealDate}
                </Typography>
                <Typography className={classes.text}>
                  {selectedData.timeCloseSeal
                    ? toMoment(selectedData.timeCloseSeal)
                      .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)
                    : '-'}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="caption">
                  {LocalizedString.trackingStatusBehandle.labelBhdLocation}
                </Typography>
                <Typography className={classes.text}>
                  {`Blok: ${selectedData.block || '-'}, Slot: ${selectedData.slot || '-'}`}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="caption">
                  {LocalizedString.trackingStatusBehandle.labelCyLocation}
                </Typography>
                <Typography className={classes.text}>
                  {`Blok: ${selectedData.blokCy || '-'}, Slot: ${selectedData.slotCy || '-'}, Row: ${selectedData.rowCy || '-'}, Tier: ${selectedData.tierCy || '-'}`}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="caption">
                  {LocalizedString.trackingStatusBehandle.labelNamaPemeriksa}
                </Typography>
                <Typography className={classes.text}>
                  {selectedData.namaPemeriksa || '-'}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="caption">
                  {LocalizedString.trackingStatusBehandle.labelPhoneNumber}
                </Typography>
                <Typography className={classes.text}>
                  {selectedData.telpPemeriksa || '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

OnProgressDetail.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  selectedData: PropTypes.shape(PropTypes.any).isRequired,
};

export default withStyles(styles)(OnProgressDetail);
