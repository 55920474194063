/**
 * Notes:
 * Ada possible perlu input PIN saat klik Process.
 * Kondisi muncul input pin dan nama company yang dimunculkan sesuai kondisi berikut:
 * - billTo: Cash; PaymentOwner: Cash = No PIN
 * - billTo: Cash; PaymentOwner: Deposit = PIN for PaymentOwner
 * - billTo: Deposit; PaymentOwner: Cash = kalau AllowPpjkToUseDeposit true, PIN for billTo, otherwise, no PIN
 * - billTo: Deposit; PaymentOwner: Deposit = kalau AllowPpjkToUseDeposit true, PIN for billTo, otherwise, PIN for PaymentOwner
 *
 * Setelah klik process, dapat paymentId, gunakan cara sama untuk munculkan halaman doku
 */


import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { change, clearFields } from 'redux-form';
import CreatePaymentDialog from './create-payment-dialog.presentation';
import {
  showErrorSnackbar,
  downloadAllAdminPayableBlAsync,
  downloadAllAdminPayableBlByFilterAsync,
  uploadAdminPaymentAsync,
  downloadAllAdminPayableBlPaymentOwnerAsync,
  addAdminPaymentData,
  clearAdminPaymentData,
  setDialogAdminPaymentPinInputStatus,
  clearAdminPayableBlPaymentOwner,
  setAdminPaymentPinText,
  setAdminPaymentHistoryDialogAddStatus,
  removeShoppingCartAdminPayment,
  addToShoppingCartAdminPaymentAsync,
} from '../../redux/action';
import {
  RXFORM_ADD_ADMIN_PAYMENT,
  RXFIELD_ADMIN_PAYMENT_BL_NO,
  RXFIELD_ADMIN_PAYMENT_INVOICE_NO,
  RXFIELD_ADMIN_PAYMENT_BILL_TO,
  PAYMENT_TYPE_DEPOSIT,
  RXFIELD_ADMIN_PAYMENT_PAYMENT_OWNER,
} from '../../constant';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';
import { validateAdminPayment } from '../../validation';

const mapStateToProps = state => ({
  loading: state.uiAdminPaymentHistoryAdd.downloading
    || state.uiAdminPaymentHistoryAdd.uploading
    || state.uiAdminPaymentHistoryAdd.downloadingPaymentOwner,
  bl: state.adminPaymentHistoryAdd.bl,
  blOptions: Object.values(state.adminPaymentHistoryAdd.bl)
    .map(item => ({ label: `${item.blNumber} - ${item.service} - ${item.billTo}`, value: item.blNumber })),
  paymentOwners: state.adminPaymentHistoryAdd.paymentOwner,
  lstPaymentOwner: Object.values(state.adminPaymentHistoryAdd.paymentOwner)
    .map(item => ({ label: item.name, value: item.id })),

  paymentData: state.adminPaymentHistoryAdd.paymentData,
  dialogPinInputStatus: state.uiAdminPaymentHistoryAdd.dialogPinInputStatus,
  pin: state.uiAdminPaymentHistoryAdd.pin,
  cartItem: Object.values(state.shoppingCartAdminPayment),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    try {
      dispatch(clearAdminPaymentData());
      dispatch(clearAdminPayableBlPaymentOwner());
      await dispatch(downloadAllAdminPayableBlAsync(''));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    }
  },
  onSearchTextChange: async (searchText) => {
    let result = [];
    try {
      result = Object.values(await dispatch(downloadAllAdminPayableBlByFilterAsync(searchText)));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
    return result.map(item => ({ label: `${item.blNo} - ${item.billTo}`, value: item.blNo }));
  },
  onSelectedBlChange: async (value, bl) => {
    const data = bl[value] || {};

    dispatch(clearAdminPaymentData());
    dispatch(addAdminPaymentData(data));
    dispatch(change(RXFORM_ADD_ADMIN_PAYMENT, RXFIELD_ADMIN_PAYMENT_BL_NO, data.blNo || ''));
    dispatch(change(RXFORM_ADD_ADMIN_PAYMENT, RXFIELD_ADMIN_PAYMENT_INVOICE_NO, data.invoiceNo || ''));
    dispatch(change(RXFORM_ADD_ADMIN_PAYMENT, RXFIELD_ADMIN_PAYMENT_BILL_TO, data.billTo || ''));

    // load payment owner
    try {
      await dispatch(downloadAllAdminPayableBlPaymentOwnerAsync(data.billTo));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },

  onSelectedPaymentOwnerChange: async (value, lstPaymentOwner) => {
    const selectedPaymentOwnerObj = lstPaymentOwner[value] || {};
    const paymentOwnerData = {
      paymentOwner: selectedPaymentOwnerObj.id,
      paymentOwnerPaymentMethod: selectedPaymentOwnerObj.paymentMethod,
      allowPpjkToUseDeposit: selectedPaymentOwnerObj.allowPpjkToUseDeposit,
    };

    dispatch(addAdminPaymentData(paymentOwnerData));
  },

  onSubmitPressed: async () => {
    try {
      await dispatch(addToShoppingCartAdminPaymentAsync());
      dispatch(clearAdminPayableBlPaymentOwner());
      dispatch(clearAdminPaymentData());
      dispatch(clearFields(RXFORM_ADD_ADMIN_PAYMENT,
        false,
        false,
        RXFIELD_ADMIN_PAYMENT_BL_NO,
        RXFIELD_ADMIN_PAYMENT_INVOICE_NO,
        RXFIELD_ADMIN_PAYMENT_BILL_TO,
        RXFIELD_ADMIN_PAYMENT_PAYMENT_OWNER));
      // validateAdminPayment(paymentData);

      // const useDeposit = (paymentData.billToPaymentMethod.toUpperCase() === PAYMENT_TYPE_DEPOSIT
      //     && paymentData.allowPpjkToUseDeposit)
      //   || paymentData.paymentOwnerPaymentMethod.toUpperCase() === PAYMENT_TYPE_DEPOSIT;

      // if (useDeposit) {
      //   dispatch(setDialogAdminPaymentPinInputStatus(true));
      // } else {
      //   await dispatch(uploadAdminPaymentAsync(''));
      // }
    } catch (error) {
      // show some error
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onPinTextChange: (text) => {
    dispatch(setAdminPaymentPinText(text));
  },
  onPinCancelPressed: () => {
    dispatch(setDialogAdminPaymentPinInputStatus(false));
  },
  onPinSubmitPressed: async (pin) => {
    try {
      await dispatch(uploadAdminPaymentAsync(pin));
      dispatch(setDialogAdminPaymentPinInputStatus(false));
      dispatch(setAdminPaymentHistoryDialogAddStatus(false));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onDeleteCartItemPressed: (invoiceNo) => {
    dispatch(removeShoppingCartAdminPayment(invoiceNo));
  },
  onProcessPaymentPressed: async (cartItems) => {
    try {
      if (!cartItems || cartItems.length === 0) return;
      const useDeposit = (cartItems[0].billToPaymentMethod.toUpperCase() === PAYMENT_TYPE_DEPOSIT
          && cartItems[0].allowPpjkToUseDeposit)
        || cartItems[0].paymentOwnerPaymentMethod.toUpperCase() === PAYMENT_TYPE_DEPOSIT;

      if (useDeposit) {
        dispatch(setDialogAdminPaymentPinInputStatus(true));
      } else {
        await dispatch(uploadAdminPaymentAsync(''));
        dispatch(setAdminPaymentHistoryDialogAddStatus(false));
      }
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatePaymentDialog));
