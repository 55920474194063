import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import {
} from '../../helper';
import {
} from '../../constant';
import LocalizedString from '../../localization';
import { LoadingIndicator } from '../../component';

const styles = () => ({
  dialogActions: {
    margin: '0 -24px',
    marginTop: '24px',
    padding: 24,
    borderTop: '1px solid #C4C4C4',
    justifyContent: 'space-between',
    paddingBottom: 0,
    display: 'flex',
  },
  expansionPanelNewParty: {
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  expansionPanelSummary: {
    borderBottom: '1px solid silver',
  },
  expansionPanelSummaryExpanded: {
    minHeight: 'inherit !important',
  },
  flexGrow: {
    flexGrow: 1,
  },
  btnAdd: {
    backgroundColor: '#ffffff',
  },
  thumbnail: {
    width: '48px',
    height: '48px',
    objectFit: 'cover',
  },
  labelFileName: {
    marginLeft: '24px',
    marginRight: '24px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '480px',
  },
  labelSelectPhotoInfo: {
    marginLeft: '24px',
    fontStyle: 'italic',
  },
  hidden: {
    display: 'none',
  },
  partyItem: {
    padding: 16,
  },
});

const PartyInfo = ({
  party,
}) => (
  <div style={{ marginTop: '16px', width: '100%' }}>
    <Grid container spacing={24}>
      <Grid item xs={6} md={2}>
        <TextField
          readOnly
          fullWidth
          value={party.numberOfParty}
          label={LocalizedString.bookExport.fieldPartyVol}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <TextField
          readOnly
          fullWidth
          value={party.sizeOfParty}
          label={LocalizedString.bookExport.fieldPartySize}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <TextField
          readOnly
          fullWidth
          value={party.typeOfParty}
          label={LocalizedString.bookExport.fieldPartyType}
        />
      </Grid>
    </Grid>
  </div>
);
PartyInfo.propTypes = {
  party: PropTypes.shape(PropTypes.any).isRequired,
};

class PartyTab extends Component {
  fileInputRef = createRef();

  render() {
    const {
      classes,
      parties,
      uploadingBookingStatus,
      downloadingPartyList,
      onPreviousPressed,
      onNextPressed,
    } = this.props;

    const renderPartyInfo = party => (
      <PartyInfo
        party={party}
      />
    );

    return (
      <>
        {parties && parties.map(item => (
          <div
            key={item.id}
            className={classes.partyItem}
          >
            {renderPartyInfo(item)}
          </div>
        ))}
        <div style={{ marginTop: '24px' }} />

        <div className={classes.dialogActions}>
          <div>
            <Button
              type="button"
              color="primary"
              onClick={onPreviousPressed}
            >
              Previous
            </Button>
          </div>
          <div style={{ flexGrow: 1 }} />
          <div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={onNextPressed}
            >
              Next
            </Button>
          </div>
        </div>
        {(downloadingPartyList || uploadingBookingStatus) && <LoadingIndicator />}
      </>
    );
  }
}
PartyTab.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  parties: PropTypes.arrayOf(PropTypes.any).isRequired,
  uploadingBookingStatus: PropTypes.bool.isRequired,
  downloadingPartyList: PropTypes.bool.isRequired,
  onPreviousPressed: PropTypes.func.isRequired,
  onNextPressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(PartyTab);
