import loadImageToReduxStateAsync from './loadImageToReduxStateAsync';
import downloadProfilePictureAsync from './downloadProfilePictureAsync';
import * as Constant from '../../../constant';
import { clearAllStates } from '../simple-action';

export default navigateTo => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { email: forgetPasswordEmail } = getState().forgetPasswordInfo;
  const { email: registrationEmail } = getState().registrationInfo;
  if (token) {
    const { currentUser } = getState();
    const user = currentUser ? getState().users[currentUser.id] : undefined;
    if (user) {
      if (user.profilePicture) {
        await dispatch(loadImageToReduxStateAsync(user.profilePicture));
      } else {
        await dispatch(downloadProfilePictureAsync(user.id));
      }

      if (user.role === Constant.ROLE_ADMIN) {
        navigateTo(Constant.ROUTE_NAME_MASTER_USERS);
      } else if (user.role === Constant.ROLE_BILLING) {
        navigateTo(Constant.ROUTE_NAME_ADMIN_PAYMENT_HISTORY);
      } else {
        navigateTo(Constant.ROUTE_NAME_DASHBOARD_USER);
      }
    } else {
      navigateTo(Constant.ROUTE_NAME_LOGIN);
    }
  } else if (forgetPasswordEmail) {
    navigateTo(Constant.ROUTE_NAME_PIN_VERIFICATION_FORGET_PASSWORD);
  } else if (registrationEmail) {
    navigateTo(Constant.ROUTE_NAME_PIN_VERIFICATION_REGISTER_EMAIL);
  } else {
    dispatch(clearAllStates());
    navigateTo(Constant.ROUTE_NAME_LOGIN);
  }
};
