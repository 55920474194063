import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import TruckingBlCard from './bl-card.presentation';
import { setTappedFeederServiceBlNo, downloadFeederServiceOrderDetailAsync, showErrorSnackbar, downloadFeederServicePriceDetailAsync } from '../../redux/action';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const getFilteredFeederService = state => _.values(state.feederServiceOrderHistories)
  .filter(item => item.refNo.toUpperCase()
    .indexOf(state.uiFeederService.searchBarText.toUpperCase()) >= 0)
  .sort((left, right) => right.bookNo.localeCompare(left.bookNo));

const mapStateToProps = state => ({
  bl: getFilteredFeederService(state),
  selectedBlNo: state.uiFeederService.tappedBlNo,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onMorePress: (tappedBlNo) => {
    const p1 = dispatch(setTappedFeederServiceBlNo(tappedBlNo));
    const p2 = dispatch(downloadFeederServiceOrderDetailAsync(tappedBlNo));
    const p3 = dispatch(downloadFeederServicePriceDetailAsync(tappedBlNo));
    
    Promise.all([p1, p2, p3])
      .catch((error) => {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
        unauthorizedErrorHandler(error, ownProps.history.push);
      });
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TruckingBlCard));
