import { connect } from 'react-redux';
import _ from 'lodash';
import MasterForwarderPage from './master-forwarder.presentation';
import {
  setMasterForwarderFilter,
  setMasterForwarderPaging,
  setMasterForwarderSort,
  setMasterForwarderSelectedId,
  setMasterForwarderDetailDialogStatus,
  clearUIError,
  setUIError,
  downloadAllForwarderAsync,
  setMasterForwarderAddDialogStatus,
} from '../../redux/action';
import * as Constant from '../../constant';

const getFilteredForwarders = (state) => {
  const filteredForwarder = _.values(state.forwarders);

  const { filters, sortField, sortDirection } = state.uiMasterForwarder;

  return filteredForwarder
    .sort((left, right) => {
      if (typeof left[sortField] === 'string') {
        if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
          return left[sortField].localeCompare(right[sortField]);
        }
        return right[sortField].localeCompare(left[sortField]);
      }

      if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
        return left[sortField] - right[sortField];
      }
      return right[sortField] - left[sortField];
    })
    .filter(
      item => item.id
        .toUpperCase()
        .indexOf(
          filters[Constant.TABLE_FIELD_MASTER_FORWARDER_ID].toUpperCase(),
        ) >= 0,
    )
    .filter(
      item => item.name
        .toUpperCase()
        .indexOf(
          filters[Constant.TABLE_FIELD_MASTER_FORWARDER_NAME].toUpperCase(),
        ) >= 0,
    )
    .filter(
      item => item.addr1
        .toUpperCase()
        .indexOf(
          filters[Constant.TABLE_FIELD_MASTER_FORWARDER_ADDR].toUpperCase(),
        ) >= 0,
    )
    .filter(
      item => item.npwp
        .toUpperCase()
        .indexOf(
          filters[Constant.TABLE_FIELD_MASTER_FORWARDER_NPWP].toUpperCase(),
        ) >= 0,
    )
    .filter(
      item => item.city
        .toUpperCase()
        .indexOf(
          filters[Constant.TABLE_FIELD_MASTER_FORWARDER_CITY].toUpperCase(),
        ) >= 0,
    )
    .filter(
      item => item.province
        .toUpperCase()
        .indexOf(
          filters[
            Constant.TABLE_FIELD_MASTER_FORWARDER_PROVINCE
          ].toUpperCase(),
        ) >= 0,
    );
};

const getPageFilteredForwarders = (state) => {
  const { activePage, pageSize } = state.uiMasterForwarder;
  const filteredForwarder = getFilteredForwarders(state);

  if (filteredForwarder) {
    const refinedActivePage = activePage || 1;
    const start = Math.min(
      (refinedActivePage - 1) * pageSize,
      filteredForwarder.length,
    );
    const end = Math.min(start + pageSize, filteredForwarder.length);
    return filteredForwarder.slice(start, end);
  }

  return [];
};

const getTotalPage = (state) => {
  const filteredForwarder = getFilteredForwarders(state);
  const { pageSize } = state.uiMasterForwarder;
  const totalData = filteredForwarder ? filteredForwarder.length : 0;

  return Math.max(Math.ceil(totalData / pageSize), 1);
};

const mapStateToProps = state => ({
  downloadingForwarders: state.uiMasterForwarder.downloadingForwarders,
  menuOpen: state.uiMenu.menuOpen,
  forwarders: getPageFilteredForwarders(state),
  filters: state.uiMasterForwarder.filters,
  activePage: state.uiMasterForwarder.activePage,
  pageSize: state.uiMasterForwarder.pageSize,
  totalPage: getTotalPage(state),
  sortField: state.uiMasterForwarder.sortField,
  sortDirection: state.uiMasterForwarder.sortDirection,

  dialogAddStatus: state.uiMasterForwarder.dialogAddStatus,
  dialogDetailStatus: state.uiMasterForwarder.dialogDetailStatus,
});

const mapDispatchToProps = dispatch => ({
  onAppear: async () => {
    try {
      clearUIError(Constant.RXSTATE_MASTER_FORWARDER);
      await dispatch(downloadAllForwarderAsync());
    } catch (error) {
      setUIError(Constant.RXSTATE_MASTER_FORWARDER, error.message);
    }
  },
  onChangeFilter: (filterName, value) => {
    dispatch(setMasterForwarderFilter(filterName, value));
  },
  onChangePaging: (pageField, value) => {
    dispatch(setMasterForwarderPaging(pageField, value));
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setMasterForwarderSort(sortField, sortDirection));
  },
  onAddNewPressed: () => {
    dispatch(setMasterForwarderAddDialogStatus(true));
  },
  onAddDialogClosePressed: () => {
    dispatch(setMasterForwarderAddDialogStatus(false));
  },
  onDetailPressed: (forwarderId) => {
    dispatch(setMasterForwarderSelectedId(forwarderId));
    dispatch(setMasterForwarderDetailDialogStatus(true));
  },
  onDetailDialogClosePressed: () => {
    dispatch(setMasterForwarderDetailDialogStatus(false));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MasterForwarderPage);
