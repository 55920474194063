import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  withStyles, Menu, MenuItem, CssBaseline, Grid, Card, CardContent, Fab, Icon, Typography, Button,
} from '@material-ui/core';
import { Link, Route } from 'react-router-dom';
import LocalizedString from '../../localization';
import { ProfileShape } from '../../type';
import * as Constant from '../../constant';
import {
  CurrentProfilePicture, ErrorMessage, LoadingIndicator, PageHeaderUser,
} from '../../component';
import ProfileInfo from './profile-info.container';
import ProfilePictureDialog from './profile-picture-dialog';
import ChangePassword from './change-password.container';
import ChangePin from './change-pin.container';

const styles = theme => ({
  avatar: {
    margin: 10,
    width: 128,
    height: 128,
    boxShadow: '0px 4px 32px 0px silver',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '-64px',
  },
  backIcon: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${Constant.DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    color: '#0266B4',
  },
  profileHeaderBackground: {
    height: '172px',
    backgroundImage: 'url("/assets/images/bg.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  profileHeaderBackgroundOverlay: {
    width: '100%',
    height: '100%',
    backgroundImage: 'linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1))',
  },
  profileInfoContainer: {
    [theme.breakpoints.up('md')]: {
      width: '90%',
    },
  },
  root: {
    width: '100%',
  },
});

class ProfileScreen extends Component {
  state = {
    anchorEl: null,
    openImageDialog: false,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChange = () => {
    this.setState({ anchorEl: null, openImageDialog: true });
  }

  handleRemove = () => {
    this.setState({ anchorEl: null });
    this.props.onRemoveProfilePicture(this.props.profile.id);
  };

  handleDialogClose = () => {

  }

  onSaveProfilePicture = (image) => {
    this.setState({ openImageDialog: false });
    this.props.onSaveProfilePicture(image);
  }

  onCancelProfilePicture = () => {
    this.setState({ openImageDialog: false });
  }

  render() {
    const {
      classes, errorMsg,
      companyPaymentMethod,
      uploadingProfilePicture, notificationOpen,
    } = this.props;
    const { anchorEl } = this.state;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <PageHeaderUser />

        <div
          className={
            classNames(classes.content, {
              [classes.contentDrawerOpen]: notificationOpen,
              [classes.contentDrawerClosed]: !notificationOpen,
            })
          }
        >
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <div className={classes.pageNavigatorContainer}>
                    <Fab
                      variant="round"
                      classes={{ root: classes.backIcon }}
                      component={Link}
                      to={Constant.ROUTE_NAME_DASHBOARD_USER}
                    >
                      <Icon>chevron_left</Icon>
                    </Fab>
                    <div style={{ marginLeft: '32px' }}>
                      <Typography variant="h5" className={classes.pageTitle}>{LocalizedString.profileScreen.title}</Typography>
                      <Typography variant="caption">{LocalizedString.profileScreen.breadcrumb}</Typography>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <div className={classes.profileHeaderBackground}>
                    <div className={classes.profileHeaderBackgroundOverlay}>
                      &nbsp;
                    </div>
                  </div>
                  <Grid container spacing={24}>
                    <Grid item xs={12} md={3}>
                      <div className={classes.avatarContainer}>
                        <CurrentProfilePicture
                          onClick={this.handleClick}
                          className={classes.avatar}
                        />
                        <div style={{ marginTop: '24px' }} />
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={this.handleChange}
                        >
                          Change
                        </Button>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Route
                        path={Constant.ROUTE_NAME_PROFILE}
                        exact
                        render={() => (
                          <div className={classes.profileInfoContainer}>
                            <ProfileInfo />
                          </div>
                        )}
                      />

                      <Route
                        path={Constant.ROUTE_NAME_PROFILE_CHANGE_PASSWORD}
                        component={ChangePassword}
                      />
                      {companyPaymentMethod.toUpperCase() === Constant.PAYMENT_TYPE_DEPOSIT && (
                        <Route
                          path={Constant.ROUTE_NAME_PROFILE_CHANGE_PIN}
                          component={ChangePin}
                        />
                      )}
                      {uploadingProfilePicture && <LoadingIndicator />}
                      {errorMsg && <ErrorMessage message={errorMsg} />}
                    </Grid>
                  </Grid>

                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleMenuClose}
                  >
                    <MenuItem onClick={this.handleChange}>Change</MenuItem>
                    <MenuItem onClick={this.handleRemove}>Remove</MenuItem>
                  </Menu>
                  <ProfilePictureDialog
                    open={this.state.openImageDialog}
                    onSave={this.onSaveProfilePicture}
                    onCancel={this.onCancelProfilePicture}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(ProfileScreen);

ProfileScreen.propTypes = {
  uploadingProfilePicture: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string,
  notificationOpen: PropTypes.bool,
  profile: ProfileShape.isRequired,
  classes: PropTypes.object.isRequired,
  companyPaymentMethod: PropTypes.string.isRequired,
  onRemoveProfilePicture: PropTypes.func.isRequired,
  onSaveProfilePicture: PropTypes.func.isRequired,
};

ProfileScreen.defaultProps = {
  errorMsg: '',
  notificationOpen: false,
};
