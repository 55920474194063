import { connect } from 'react-redux';
import LocalizedString from '../../localization';
import {
  downloadMoneyLoanTermsAndConditionsAsync, setMoneyLoanTncDialog,
  showErrorSnackbar, submitMoneyLoanAsync,
} from '../../redux/action';
import MoneyLoanTncDialog from './money-loan-tnc-dialog.presentation';

export const mapStateToProps = state => ({
  downloading: state.uiMoneyLoanInfo.downloadingTnc,
  submitting: state.uiMoneyLoanInfo.submittingMoneyLoan,
  tnc: state.uiMoneyLoanInfo.tnc,
  visibility: state.uiMoneyLoanInfo.tncDialog,
});

export const mapDispatchToProps = dispatch => ({
  onAppear: () => {
    dispatch(downloadMoneyLoanTermsAndConditionsAsync())
      .catch((error) => {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      });
  },
  onSubmitPressed: (pin) => {
    dispatch(submitMoneyLoanAsync(pin))
      .catch((error) => {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      });
  },
  onCancelPressed: () => {
    dispatch(setMoneyLoanTncDialog(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MoneyLoanTncDialog);
