import {
  clearFields, deletingField, setFieldDeleteDialogStatus, setFieldSort,
} from '../simple-action';
import * as Helper from '../../../helper';
import * as Constant from '../../../constant';
import downloadAllFieldAsync from './downloadAllFieldAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(deletingField(true));
    const { token } = getState().authentication;
    const { selectedFieldId } = getState().uiField;

    await Helper.deleteField(selectedFieldId, token);
    dispatch(clearFields());
    dispatch(setFieldSort('order', Constant.TABLE_SORT_ASCENDING));
    dispatch(downloadAllFieldAsync());
  } finally {
    dispatch(deletingField(false));
    dispatch(setFieldDeleteDialogStatus(false));
  }
};
