import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { withStyles, CssBaseline } from '@material-ui/core';
import EmailSubmission from './email-submission.container';
import PinVerification from './pin-verification.container';
import ResetPassword from './reset-password.container';
import { ErrorMessage } from '../../component';
import * as Constant from '../../constant';

const styles = () => ({
  rootContainer: {
    display: 'flex',
    height: '100%',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundImage: `url("${Constant.IMAGE_BACKGROUND_LOGIN}")`,
    backgroundSize: 'cover',
    backgroundColor: 'rgba(0,0,0,0.6)',
    backgroundBlendMode: 'soft-light',
  },
});

const ForgetPassword = ({ classes, errorMsg }) => (
  <>
    <CssBaseline />
    <div className={classes.rootContainer}>
      <Route
        exact
        path={Constant.ROUTE_NAME_FORGET_PASSWORD}
        component={EmailSubmission}
      />
      <Route
        path={Constant.ROUTE_NAME_PIN_VERIFICATION_FORGET_PASSWORD}
        component={PinVerification}
      />
      <Route
        path={Constant.ROUTE_NAME_RESET_PASSWORD}
        component={ResetPassword}
      />
    </div>
  </>
);

export default withStyles(styles)(ForgetPassword);

ForgetPassword.propTypes = {
  errorMsg: PropTypes.string,
};

ForgetPassword.defaultProps = {
  errorMsg: '',
};
