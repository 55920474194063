import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  TextField, Button, withStyles, Typography, Card, CardHeader, Avatar, CardContent,
} from '@material-ui/core';
import LocalizedString from '../../localization';
import { RoundedButton, ErrorMessage } from '../../component';

const styles = theme => ({
  titleContainer: {
    width: '90%',
    padding: '16px',
  },
  footerContainer: {
    width: '90%',
    padding: '16px',
    textAlign: 'right',
  },
  button: {
    margin: theme.spacing.unit,
  },
  cardRoot: {
    overflow: 'visible',
    padding: theme.spacing.unit,
    minWidth: '360px',
    maxWidth: '95%',
  },
  cardHeaderAvatar: {
    margin: 0,
    width: '100%',
  },
  cardAvatar: {
    height: 96,
    // width: 'calc(100% - 24px)',
    width: '100%',
    padding: '12px',
    borderRadius: 4,
    marginTop: theme.spacing.unit * -6,

    background: 'linear-gradient(60deg, #0164b2, #014982)',
    boxShadow: '0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)',
    color: '#ffffff',
  },

  labelTitle: {
    fontWeight: 500,
    color: '#ffffff',
  },
  labelSubtitle: {
    fontWeight: 400,
    color: '#ffffff',
  },
  labelFooter: {
    fontWeight: 400,
    color: '#ffffff',
  },
});

const LoginCardAvatar = withStyles(styles)(props => (
  <Avatar classes={{ root: props.classes.cardAvatar }}>
    <img title="Logo" alt="Logo" src="/assets/images/logo.png" />
  </Avatar>
));

class EmailSubmission extends Component {
    state = {
      pin: this.props.pin,
      minute: '00',
      second: '00',
    }


    componentDidMount() {
      this.interval = setInterval(this.onInterval, 1000);
    }

    componentWillUnmount() {
      clearInterval(this.interval);
    }

    onInterval = () => {
      if (this.props.pinExpirationDate) {
        const now = moment(new Date());
        const endDate = moment(this.props.pinExpirationDate);

        if (endDate.diff(now, 'seconds') <= 0) {
          clearInterval(this.interval);
          this.props.onPinExpired();
          return;
        }

        let minute = endDate.diff(now, 'minutes');
        minute = (`${minute}`).length === 1 ? `0${minute}` : minute;
        let second = endDate.diff(now, 'seconds') % 60;
        second = (`${second}`).length === 1 ? `0${second}` : second;

        this.setState({ minute, second });
      }
    }

    onSubmitButtonClicked = () => {
      this.props.onNext(this.state.pin);
    }

    onCancel = () => {

    }

    renderPinRemainingTime = () => (
      <div style={{ textAlign: 'center' }}>
        {LocalizedString.pinVerificationScreen.expirationLabel}
        {' '}
        {`${this.state.minute}:${this.state.second}`}
      </div>
    );


    render() {
      const {
        classes, errorMsg, onCancel,
      } = this.props;
      return (
        <>
          <div className={classes.titleContainer}>
            <Typography variant="title">
              <span className={classes.labelTitle}>{LocalizedString.loginScreen.title}</span>
              &nbsp;
              <span variant="title" className={classes.labelSubtitle}>{LocalizedString.loginScreen.subTitle}</span>
            </Typography>
          </div>
          <Card classes={{ root: classes.cardRoot }}>
            <CardHeader
              avatar={<LoginCardAvatar />}
              classes={{ avatar: classes.cardHeaderAvatar }}
            />
            <CardContent>
              <Typography variant="title">
                {LocalizedString.pinVerificationScreen.title}
              </Typography>

              <Typography variant="body1">
                {LocalizedString.pinVerificationScreen.subTitle}
              </Typography>

              <TextField
                fullWidth
                label={LocalizedString.pinVerificationScreen.labelPin}
                value={this.state.pin}
                onChange={e => this.setState({ pin: e.target.value })}
                margin="normal"
              />
              {this.renderPinRemainingTime()}

              <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '32px' }}>
                <Button color="primary" onClick={onCancel}>
                  {LocalizedString.common.buttonCaptionCancel}
                </Button>
                <RoundedButton type="button" className={classes.button} onClick={this.onSubmitButtonClicked}>
                  {LocalizedString.common.buttonCaptionNext}
                </RoundedButton>
              </div>

              {errorMsg && <ErrorMessage message={errorMsg} />}
            </CardContent>
          </Card>
          <div className={classes.footerContainer}>
            <Typography variant="body2" className={classes.labelFooter}>{LocalizedString.loginScreen.footer}</Typography>
          </div>
        </>
      );
    }
}

EmailSubmission.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  errorMsg: PropTypes.string.isRequired,
  pin: PropTypes.string,
  pinExpirationDate: PropTypes.string,
  onNext: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onPinExpired: PropTypes.func.isRequired,
};

EmailSubmission.defaultProps = {
  pin: '',
  pinExpirationDate: '',
};

export default withStyles(styles)(EmailSubmission);
