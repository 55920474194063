import { connect } from 'react-redux';
import DashboardUserScreen from './dashboard-user.presentation';
import {
  ROUTE_NAME_CONTAINER_TRACKING,
  ROUTE_NAME_TARIFF_SIMULATION,
  ROUTE_NAME_EXPORT,
  ROUTE_NAME_TRUCKING,
  ROUTE_NAME_ASSIGN,
  ROUTE_NAME_MY_PAYMENT,
  ROUTE_NAME_REEFER_DATA,
  ROUTE_NAME_CONTACT_US,
  ROUTE_NAME_ABOUT_US,
  ROUTE_NAME_PROFILE,
  ROUTE_NAME_MASTER_USERS,
  ROLE_ADMIN,
  ROLE_BILLING,
  ROUTE_NAME_TARIFF_SIMULATION_RELEASE_SEARCH,
  ROUTE_NAME_TRACKING_STATUS_BEHANDLE,
  ROUTE_NAME_DOCUMENT_SUBMISSION,
} from '../../constant';
import LocalizedString from '../../localization';
import { downloadMoneyLoanInfoAsync, setShowAllMenu, showErrorSnackbar } from '../../redux/action';

const MENU_ITEMS = [
  { link: ROUTE_NAME_CONTAINER_TRACKING, title: LocalizedString.dashboardUser.labelContainerTracking, icon: '/assets/images/container.png' },
  { link: ROUTE_NAME_EXPORT, title: LocalizedString.dashboardUser.labelExport, icon: '/assets/images/export.png' },
  { link: ROUTE_NAME_TARIFF_SIMULATION, title: LocalizedString.dashboardUser.labelImport, icon: '/assets/images/import.png' },
  { link: ROUTE_NAME_TRUCKING, title: LocalizedString.dashboardUser.labelFeederService, icon: '/assets/images/feeder.png' },
  { link: ROUTE_NAME_ASSIGN, title: LocalizedString.dashboardUser.labelAssign, icon: '/assets/images/assign.png' },
  { link: ROUTE_NAME_MY_PAYMENT, title: LocalizedString.dashboardUser.labelMyPayment, icon: '/assets/images/my_payment.png' },
  { link: ROUTE_NAME_DOCUMENT_SUBMISSION, title: LocalizedString.dashboardUser.labelSubmitRequest, icon: '/assets/images/submit_request.png' },
  { link: ROUTE_NAME_REEFER_DATA, title: LocalizedString.dashboardUser.labelReeferData, icon: '/assets/images/reefer.png' },
  { link: ROUTE_NAME_TARIFF_SIMULATION_RELEASE_SEARCH, title: LocalizedString.dashboardUser.labelImportRelease, icon: '/assets/images/import.png' },
  { link: ROUTE_NAME_TRACKING_STATUS_BEHANDLE, title: LocalizedString.dashboardUser.labelTrackingStatusBehandle, icon: '/assets/images/container.png' },
];

const ADMIN_MENU_ITEMS = [{ link: ROUTE_NAME_MASTER_USERS, title: 'Admin', icon: '/assets/images/admin.png' }];

const BILLING_MENU_ITEMS = [
  { link: ROUTE_NAME_MY_PAYMENT, title: LocalizedString.dashboardUser.labelMyPayment, icon: '/assets/images/my_payment.png' },
];

const GLOBAL_MENU_ITEMS = [
  { link: ROUTE_NAME_PROFILE, title: LocalizedString.dashboardUser.labelMyAccount, icon: '/assets/images/account.png' },
  { link: ROUTE_NAME_CONTACT_US, title: LocalizedString.dashboardUser.labelContactUs, icon: '/assets/images/contact_us.png' },
  { link: ROUTE_NAME_ABOUT_US, title: LocalizedString.dashboardUser.labelAboutUs, icon: '/assets/images/about_us.png' },
];

const getMenuItems = ({ currentUser }) => {
  let menuItems = [];

  if (currentUser.role === ROLE_ADMIN || currentUser.corporateAdmin) {
    menuItems = [
      ...MENU_ITEMS,
      ...GLOBAL_MENU_ITEMS,
      ...ADMIN_MENU_ITEMS,
    ];
  } else if (currentUser.role === ROLE_BILLING) {
    menuItems = [
      ...BILLING_MENU_ITEMS,
      ...GLOBAL_MENU_ITEMS,
    ];
  } else {
    menuItems = [
      ...MENU_ITEMS,
      ...GLOBAL_MENU_ITEMS,
    ];
  }
  return menuItems;
};

const mapStateToProps = state => ({
  notificationOpen: true,
  currentUser: state.currentUser,
  userCompany: state.currentUser.company,
  showAllMenu: state.dashboard.showAllMenu,
  menuItems: getMenuItems(state),
  moneyLoanInfo: state.moneyLoanInfo,
});

const mapDispatchToProps = dispatch => ({
  onAppear: () => {
    dispatch(downloadMoneyLoanInfoAsync())
      .catch((error) => {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      });
  },
  onToggleMainMenu: (currentValue) => {
    dispatch(setShowAllMenu(!currentValue));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardUserScreen);
