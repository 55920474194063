import { connect } from 'react-redux';
import _ from 'lodash';
import AdminPaymentHistory from './admin-payment-history.presentation';
import {
  setMasterUserSelectedId,
  showErrorSnackbar,
  setAdminPaymentHistoryFilter,
  setAdminPaymentHistoryPaging,
  setAdminPaymentHistorySort,
  setAdminPaymentHistoryDialogAddStatus,
  setAdminPaymentHistoryDialogDetailStatus,
  setTappedAdminPaymentHistoryItemNo,
  clearAdminPaymentHistoryDetailState,
  setAdminPaymentHistorySearchText,
  setAdminPaymentDownloadLimitOption,
  downloadAdminPaymentHistoryCsvAsync,
  setDialogAdminPaymentSuccessStatus,
} from '../../redux/action';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';
import downloadAdminPaymentHistoryAsync from '../../redux/action/async/downloadAdminPaymentHistoryAsync';

const getTotalPage = (state) => {
  const { totalData } = state.adminPaymentHistory;
  const { pageSize } = state.uiAdminPaymentHistory;

  return Math.max(Math.ceil(totalData / pageSize), 1);
};

const debouncedDownloadAllPaymentHistory = _.debounce(async (dispatch, ownProps) => {
  try {
    await dispatch(downloadAdminPaymentHistoryAsync());
  } catch (error) {
    dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    unauthorizedErrorHandler(error, ownProps.history.push);
  }
}, 800);

const mapStateToProps = state => ({
  downloading: state.uiAdminPaymentHistory.downloading,
  menuOpen: state.uiMenu.menuOpen,
  payments: state.adminPaymentHistory.payments,

  downloadLimitOption: state.uiAdminPaymentHistory.downloadLimitOption,

  searchText: state.uiAdminPaymentHistory.searchText,
  // filters: state.uiAdminPaymentHistory.filters,
  activePage: state.uiAdminPaymentHistory.activePage,
  pageSize: state.uiAdminPaymentHistory.pageSize,
  totalPage: getTotalPage(state),
  sortField: state.uiAdminPaymentHistory.sortField,
  sortDirection: state.uiAdminPaymentHistory.sortDirection,

  dialogAddStatus: state.uiAdminPaymentHistory.dialogAddStatus,
  dialogDetailStatus: state.uiAdminPaymentHistory.dialogDetailStatus,
  dialogPaymentCompleteStatus: state.uiAdminPaymentHistory.dialogPaymentCompleteStatus,
  paymentResult: state.uiAdminPaymentHistory.paymentResult,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    // load user data
    try {
      await dispatch(downloadAdminPaymentHistoryAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onSearchTextChange: (text) => {
    dispatch(setAdminPaymentHistorySearchText(text));
    debouncedDownloadAllPaymentHistory(dispatch, ownProps);
  },
  // onChangeFilter: async (filterName, value) => {
  //   dispatch(setAdminPaymentHistoryFilter(filterName, value));
  //   debouncedDownloadAllPaymentHistory(dispatch, ownProps);
  // },
  onChangePaging: async (pageField, value) => {
    dispatch(setAdminPaymentHistoryPaging(pageField, value));
    try {
      await dispatch(downloadAdminPaymentHistoryAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onChangeSort: async (sortField, sortDirection) => {
    dispatch(setAdminPaymentHistorySort(sortField, sortDirection));
    try {
      await dispatch(downloadAdminPaymentHistoryAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onAddNewPressed: () => {
    dispatch(setAdminPaymentHistoryDialogAddStatus(true));
  },
  onAddDialogClosePressed: () => {
    dispatch(setAdminPaymentHistoryDialogAddStatus(false));
  },
  onEditPressed: (proformaNo) => {
    dispatch(setTappedAdminPaymentHistoryItemNo(proformaNo));
    dispatch(clearAdminPaymentHistoryDetailState());
    dispatch(setAdminPaymentHistoryDialogDetailStatus(true));
  },
  onEditDialogClosePressed: () => {
    dispatch(setAdminPaymentHistoryDialogDetailStatus(false));
  },
  onChangeDownloadOption: (value) => {
    dispatch(setAdminPaymentDownloadLimitOption(value));
  },
  onDownloadPressed: () => {
    dispatch(downloadAdminPaymentHistoryCsvAsync());
  },
  onClosePaymentCompletePressed: () => {
    dispatch(setDialogAdminPaymentSuccessStatus(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPaymentHistory);
