import * as Helper from '../../../helper';
import * as Constant from '../../../constant';
import * as Action from '../simple-action';

export default userInfo => async (dispatch, getState) => {
  try {
    dispatch(
      Action.setSavingStatus(Constant.RXSTATE_USER_MANAGEMENT, true),
    );
    const { token } = getState().authentication;
    await Helper.updateUser(userInfo, token);
  } finally {
    dispatch(
      Action.setSavingStatus(Constant.RXSTATE_USER_MANAGEMENT, false),
    );
  }
};
