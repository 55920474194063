import * as Helper from '../../../helper';
import { addContainerTrackings, downloadingContainerTrackingsData, clearContainerTrackings } from '../simple-action';


export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingContainerTrackingsData(true));
    const { token } = getState().authentication;
    // const blNumber = getState().uiContainerTracking.searchBarText;
    const containerTrackings = await Helper.downloadContainerTrackings('', token);

    if (containerTrackings) {
      dispatch(clearContainerTrackings()); // should clear all container trackings first
      dispatch(addContainerTrackings(containerTrackings));
    }
  } finally {
    dispatch(downloadingContainerTrackingsData(false));
  }
};
