import { connect } from 'react-redux';
import PdfViewerDialog from './pdf-viewer.presentation';
import { setPdfViewerStatus } from '../../redux/action';

const mapStateToProps = state => ({
  open: state.uiPdfViewer.openViewer,
  sourceUrl: state.uiPdfViewer.sourceUrl,
  title: state.uiPdfViewer.tappedTitle,
});

const mapDispatchToProps = dispatch => ({
  onClosePressed: () => {
    dispatch(setPdfViewerStatus(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PdfViewerDialog);
