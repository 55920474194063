import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  Typography,
  Icon,
} from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import { LoadingIndicator, RoundedButton } from '../../component';
import LocalizedString from '../../localization';
import {
  RXFIELD_MASTER_VENDOR_ID,
  RXFIELD_MASTER_VENDOR_NAME,
  RXFORM_MASTER_VENDOR,
} from '../../constant';
import {
  renderReduxFormTextField,
} from '../../helper';

const styles = () => ({
  fontWeightBold: {
    fontWeight: 'bold',
  },
});

const VendorEditDialog = ({
  classes,
  open,
  loading,

  vendor,

  handleSubmit,
  onSubmitPressed,
  onAppear,
  onClose,
}) => {
  if (!vendor) {
    return <></>;
  }

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onEnter={() => onAppear()}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
            }}
          >
            <Typography color="primary" variant="h6">
              {vendor.name}
            </Typography>
            <IconButton onClick={onClose}>
              <Icon>close</Icon>
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit(onSubmitPressed)}>
            <Field
              name={RXFIELD_MASTER_VENDOR_ID}
              component={renderReduxFormTextField}
              label={LocalizedString.masterVendor.labelVendorId}
            />
            <Field
              name={RXFIELD_MASTER_VENDOR_NAME}
              component={renderReduxFormTextField}
              label={LocalizedString.masterVendor.labelVendorName}
            />
            <div style={{ textAlign: 'center', padding: '16px' }}>
              <RoundedButton type="submit" disabled={loading}>
                Save
              </RoundedButton>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      {loading && <LoadingIndicator />}
    </>
  );
};
VendorEditDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,

  vendor: PropTypes.shape(PropTypes.any).isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
};

export default reduxForm({
  form: RXFORM_MASTER_VENDOR,
  enableReinitialize: true,
})(withStyles(styles)(VendorEditDialog));
