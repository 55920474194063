import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  withStyles,
  Grid,
  Card,
  CardContent,
  Typography,
  ButtonBase,
  Avatar,
  Button,
} from '@material-ui/core';
import LocalizedString from '../../localization';
import { MainMenuShape } from '../../type';
import {
  setTappedFeederServiceBlNo
} from '../../redux/action';


const styles = () => ({
  buttonPrimary: {
    backgroundColor: '#50abf1',
  },
  cardMainMenu: {
    width: '100%',
    height: '100%',
  },
  cardProfileHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainMenuIcon: {
    width: 'auto',
    height: 96,
    borderRadius: 0,
    '& > img': {
      objectFit: 'contain !important',
    },
  },
  menuTitle: {
    fontWeight: '500',
    fontSize: '1rem',
    whiteSpace: 'nowrap',
  },
  textColorLink: {
    color: '#0066cb',
  },
});

const ClearSelectedBl=()=>
  {

    console.log('klik');
  }


const MainMenuScreen = ({
  classes,
  items,
  showAllMenu,
  onToggleMainMenu,
  clearSelectedData,
}) => {
  const toRenderMenuItems = showAllMenu ? items : items.slice(0, 6);

  const renderShowMoreLessButton = () => {
    if (items.length <= 6) {
      return null;
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column', padding: '8px 0px' }}>
        <Button
          variant="contained"
          color="primary"
          style={{ flex: 1, alignSelf: 'flex-end' }}
          className={classes.buttonPrimary}
          onClick={() => onToggleMainMenu(showAllMenu)}
        >
          {showAllMenu
            ? LocalizedString.dashboardUser.labelLess
            : LocalizedString.dashboardUser.labelMore}
        </Button>
      </div>
    );
  };

  return (
  <>
    <Grid container spacing={24}>
      <Grid item xs={12} md={12}>
        <Card>
          <CardContent>
            <Typography variant="subtitle1" className={classes.textColorLink}>{LocalizedString.dashboardUser.labelMainMenu}</Typography>
          </CardContent>
        </Card>
      </Grid>
        {toRenderMenuItems.map(item => (
        <Grid item xs={12} md={4} key={item.title}>


          <ButtonBase
            className={classes.cardMainMenu}
            component={item.link ? Link : 'div'}
            onClick={clearSelectedData}
            to={item.link || ''}
          >
            <Card className={classes.cardMainMenu}>
              <CardContent>
                <div className={classes.cardProfileHeader}>
                  <Avatar
                    src={item.icon}
                    className={classes.mainMenuIcon}
                    alt={item.title}
                  />
                  <Typography className={classes.menuTitle}>{item.title}</Typography>
                </div>
              </CardContent>
            </Card>
          </ButtonBase>
        </Grid>
      ))}
    </Grid>

    {/* <div style={{ marginTop: '12px', height: '1px', backgroundColor: '#50ABF1' }} /> */}

      {renderShowMoreLessButton()}
  </>
);
};
MainMenuScreen.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  items: MainMenuShape.isRequired,
  showAllMenu: PropTypes.bool.isRequired,
  onToggleMainMenu: PropTypes.func.isRequired,
  clearSelectedData:PropTypes.func.isRequired,
};
export default withStyles(styles)(MainMenuScreen);
