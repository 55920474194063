import _ from 'lodash';
import * as Action from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case Action.ADD_SHOPPING_CART_ITEM:
      return { ...state, [action.item.itemId]: action.item };
    case Action.REMOVE_SHOPPING_CART_ITEM:
      return _.omit(state, action.itemId);
    default: return state;
  }
};
