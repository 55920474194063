import { downloadingExportOrderHistory, addExportOrderHistory } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  try {
    dispatch(downloadingExportOrderHistory(true));
    const exportOrderHistory = await Helper.downloadExportOrderHistory(token);
    dispatch(addExportOrderHistory(exportOrderHistory));
  } finally {
    dispatch(downloadingExportOrderHistory(false));
  }
};
