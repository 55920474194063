import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Grid, Button, withStyles } from '@material-ui/core';
import { renderReduxFormTextField, renderReduxFormFilterableSelectField } from '../../helper';
import {
  DEFAULT_STYLES, PARTY_SIZE, PARTY_TYPE, RXFIELD_NUMBER_OF_PARTY, RXFIELD_SIZE_OF_PARTY, RXFIELD_TYPE_OF_PARTY,
} from '../../constant';
import LocalizedString from '../../localization';

const styles = () => ({
  btnPrimary: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
});

const PartyField = ({
  classes,
  form,
  partyId,
  partySizes,
  partyTypes,
  handleSubmit,
  onCancelPressed,
  onSubmitPressed,
}) => {
  const handleCancelPressed = () => {
    if (onCancelPressed) {
      onCancelPressed(partyId);
    }
  };

  const handleSubmitPressed = (values) => {
    if (onSubmitPressed) {
      onSubmitPressed(partyId, values);
    }
  };

  const isDefaultParty = typeof partyId !== 'string';

  return (
    <form onSubmit={handleSubmit(handleSubmitPressed)}>
      <Grid container spacing={24}>
        <Grid item xs={6} md={2}>
          <Field
            name={RXFIELD_NUMBER_OF_PARTY}
            fullWidth
            component={renderReduxFormTextField}
            label={LocalizedString.bookingFeeder.fieldPartyVol}
            autofocus
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <Field
            name={RXFIELD_SIZE_OF_PARTY}
            fullWidth
            // component={renderReduxFormTextField}
            component={renderReduxFormFilterableSelectField}
            options={partySizes}
            label={LocalizedString.bookingFeeder.fieldPartySize}
            disabled={isDefaultParty}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <Field
            name={RXFIELD_TYPE_OF_PARTY}
            fullWidth
            // component={renderReduxFormTextField}
            component={renderReduxFormFilterableSelectField}
            options={partyTypes}
            label={LocalizedString.bookingFeeder.fieldPartyType}
            disabled={isDefaultParty}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          {/* <Field
            name="loadOfParty"
            fullWidth
            disabled
            component={renderReduxFormTextField}
            label="Status"
          /> */}
        </Grid>
        <Grid item xs={12} md={12}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              color="primary"
              onClick={handleCancelPressed}
            >
              Cancel
            </Button>
            &emsp;
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.btnPrimary}
            >
              Submit
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
PartyField.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  form: PropTypes.string.isRequired,
  partyId: PropTypes.string.isRequired,
  partySizes: PropTypes.arrayOf(PropTypes.any).isRequired,
  partyTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
};

export default reduxForm()(withStyles(styles)(PartyField));
