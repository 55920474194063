import * as Action from '../action';

const initialState = {
  result: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.SET_PAYMENT_RESULT:
      return { ...state, result: action.result };
    default: return state;
  }
};
