import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import FieldDeleteDialog from './field-delete-dialog.presentation';
import {
  showErrorSnackbar,
} from '../../redux/action';
import LocalizedString from '../../localization';
import deleteFieldAsync from '../../redux/action/async/deleteFieldAsync';


const mapStateToProps = state => ({
  deleting: state.uiField.deleting,
  selectedId: state.uiField.selectedFieldId,
});

const mapDispatchToProps = dispatch => ({
  onConfirmDeletePressed: async (userId) => {
    try {
      await dispatch(deleteFieldAsync(userId));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    }
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FieldDeleteDialog));
