import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoadingIndicator } from '../../component';

export default class SplashScreen extends Component {
  componentWillMount() {
    this.props.onAppear();
  }

  render() {
    return (
      // <div>
      //   Splash Screen
      // </div>
      <LoadingIndicator />
    );
  }
}

SplashScreen.propTypes = {
  onAppear: PropTypes.func.isRequired,
};
