import { submitMoneyLoan } from '../../../helper';
import LocalizedString from '../../../localization';
import { setMoneyLoanTncDialog, showErrorSnackbar, submittingMoneyLoan } from '../simple-action';
import downloadDepositHistoryAsync from './downloadDepositHistoryAsync';
import downloadMoneyLoanHistoryAsync from './downloadMoneyLoanHistoryAsync';
import downloadMoneyLoanInfoAsync from './downloadMoneyLoanInfoAsync';
import updateDepositBalanceAsync from './updateDepositBalanceAsync';

export default pin => async (dispatch, getState) => {
  try {
    dispatch(submittingMoneyLoan(true));
    const { token } = getState().authentication;

    await submitMoneyLoan(pin, token);

    dispatch(setMoneyLoanTncDialog(false));
    dispatch(downloadMoneyLoanInfoAsync())
      .catch((error) => {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      });
    dispatch(updateDepositBalanceAsync())
      .catch((error) => {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      });
    dispatch(downloadDepositHistoryAsync())
      .catch((error) => {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      });
    dispatch(downloadMoneyLoanHistoryAsync())
      .catch((error) => {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      });
  } finally {
    dispatch(submittingMoneyLoan(false));
  }
};
