import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  withStyles,
  CssBaseline,
  Grid,
  Card,
  CardContent,
  Fab,
  Icon,
  Typography,
  TextField,
  CardHeader,
  Select,
  MenuItem,
  TableCell,
  TableSortLabel,
  Input,
  Button,
  Table,
  TableHead,
  TableRow,
  TableBody,
  CardActions,
  Checkbox,
} from '@material-ui/core';
import { PageHeaderUser, LoadingIndicator } from '../../component';
import {
  ROUTE_NAME_DASHBOARD_USER,
  DEFAULT_STYLES,
  DRAWER_NOTIFICATION_WIDTH,
  RXTABLEFIELD_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS,
  TABLE_SORT_ASCENDING,
  TABLE_SORT_DESCENDING,
  RXTABLEFIELD_ACTIVE_PAGE,
  TABLE_FIELD_STATUS,
  TABLE_FIELD_ORDER_TYPE,
  TABLE_FIELD_CONSIGNEE,
  TABLE_FIELD_TRUCKER,
  ROLE_PPJK,
  ROLE_CUSTOMER,
  CART_SERVICE_NAME_EXPORT,
} from '../../constant';
import LocalizedString from '../../localization';
import { isSortFieldActive, getSortIcon } from '../../helper';
import { AssignOrderTypeShape } from '../../type';
import AssignContainerDialog from './assign-container-dialog.container';
import AssignClearanceDialog from './assign-bl-dialog.container';
import BulkAssignDialog from './bulk-assign-bl-dialog.container';
import BlCardContainer from './bl-card.container';

const styles = theme => ({
  alignRight: {
    textAlign: 'right',
  },
  assignMultipleButton: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  assignTruckButton: {
    backgroundColor: '#ffffff',
  },
  assignBlButton: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
    width: '256px',
  },
  btnDetail: {
    backgroundColor: '#ffffff',
  },
  backIcon: {
    ...DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  cardActionPrimary: {
    display: 'flex',
    alignItems: 'baseline',
    padding: 16,
    justifyContent: 'space-between',
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  cardActionText: {
    textTransform: 'uppercase',
  },
  cardBlDataHeader: {
    marginBottom: theme.spacing.unit * 2,
  },
  cardItem: {
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '6px',
    boxSizing: 'border-box',
    padding: '16px',
    marginBottom: theme.spacing.unit,
    '&:last-child': {
      marginBottom: 0,
    },
    transition: 'background-color 0.4s',
  },
  cardItemActive: {
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  cardItemContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: theme.spacing.unit * 2,
    maxBlockSize: '500px',
    height: '500px',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  detailHeader: {
    color: '#0066CB',
  },
  itemPerRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    color: '#0266B4',
  },
  searchButton: {
    ...DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

const isChecked = (multiSelectedBl, item) => !!multiSelectedBl
  .find(bl => bl.blNo === item.blNo && bl.exim === item.exim);

const isTruckingButtonDisabled = (item, userRole) => {
  const { trucker, forwarderId, invoiceNo } = item;

  if (userRole.toUpperCase() === ROLE_CUSTOMER) {
    return !(!!forwarderId || !!invoiceNo);
  } if (userRole.toUpperCase() === ROLE_PPJK) {
    return !trucker;
  }
  return true;
};

class AssignPage extends Component {
  componentDidMount() {
    this.props.onAppear();
  }

  handleChangeSort = (newSortField) => {
    const { sortField, sortDirection, onChangeSort } = this.props;
    if (newSortField === sortField) {
      onChangeSort(
        sortField,
        sortDirection === TABLE_SORT_ASCENDING
          ? TABLE_SORT_DESCENDING
          : TABLE_SORT_ASCENDING,
      );
    } else {
      onChangeSort(newSortField, TABLE_SORT_ASCENDING);
    }
  };

  renderTableCellHeader = (label, fieldId) => {
    const {
      sortField,
      sortDirection,
    } = this.props;
    return (
      <TableCell>
        <TableSortLabel
          active={
            isSortFieldActive(sortField, fieldId)
          }
          IconComponent={getSortIcon(
            isSortFieldActive(sortField, fieldId),
            sortDirection,
          )}
          onClick={() => this.handleChangeSort(fieldId)}
        >
          {label}
        </TableSortLabel>
      </TableCell>
    );
  }

  renderTableCellStringFilter = (field) => {
    const {
      filters,
      onChangeFilter,
    } = this.props;
    return (
      <TableCell>
        <Input
          type="text"
          fullWidth
          value={filters[field]}
          onChange={
            ({ nativeEvent }) => onChangeFilter(
              field,
              nativeEvent.target.value,
            )
          }
          placeholder={LocalizedString.common.placeholderSearch}
        />
      </TableCell>
    );
  }

  renderTableCellBody = text => (
    <TableCell>
      <Typography>
        {text}
      </Typography>
    </TableCell>
  );

  renderTableCellBodyAction = (blNo, soNo) => {
    const { classes, onDetailMorePressed } = this.props;
    return (
      <TableCell>
        <Button
          variant="outlined"
          color="primary"
          className={classes.btnDetail}
          onClick={() => onDetailMorePressed(blNo, soNo)}
        >
          {LocalizedString.common.buttonCaptionMore}
        </Button>
      </TableCell>
    );
  };

  renderTablePagination = () => {
    const {
      classes, activePage, totalPage, onChangePaging,
    } = this.props;

    return (
      <div className={classes.tablePagination}>
        <Button
          variant="contained"
          style={{ flexGrow: 1 }}
          disabled={activePage === 1}
          onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage - 1)}
        >
          {LocalizedString.common.labelPagingPrevButton}
        </Button>

        <div className={classes.tablePaginationPageInput}>
          <span style={{ fontWeight: 500 }}>{LocalizedString.common.labelPagingPage}</span>
          <Input
            type="number"
            value={activePage}
            style={{ width: '50%' }}
            inputProps={{
              min: 1,
              max: totalPage,
              step: 1,
              style: { textAlign: 'center' },
            }}
            onChange={({ nativeEvent }) => onChangePaging(
              RXTABLEFIELD_ACTIVE_PAGE,
              parseInt(nativeEvent.target.value, 10),
            )}
          />
          <span style={{ fontWeight: 500 }}>
            {`${LocalizedString.common.labelPagingPageOf} ${totalPage}`}
          </span>
        </div>

        <Button
          variant="contained"
          style={{ flexGrow: 1 }}
          disabled={activePage === totalPage}
          onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage + 1)}
        >
          {LocalizedString.common.labelPagingNextButton}
        </Button>
      </div>
    );
  }

  renderCheckbox(item) {
    const {
      multiSelectBl,
      userRole,
      onCheckboxPressed,
    } = this.props;

    const clearanceDisabled = item.forwarderId || userRole === ROLE_PPJK || item.invoiceNo;

    if (clearanceDisabled) {
      return null;
    }

    return (
      <Checkbox
        checked={isChecked(multiSelectBl, item)}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onCheckboxPressed(multiSelectBl, item);
        }}
      />
    );
  }

  renderBlHeader() {
    const {
      classes,
      selectedBlNo,
      selectedBl,
      userRole,

      onAssignBlPressed,
    } = this.props;

    if (!selectedBlNo) {
      return null;
    }

    const titleLabel = selectedBl
      .exim.toUpperCase() !== CART_SERVICE_NAME_EXPORT.toUpperCase()
      ? LocalizedString.assign.labelBlNumber
      : LocalizedString.assign.labelDoNo;

    return (
      <>
        <Card className={classes.cardBlDataHeader}>
          <CardHeader
            title={(
              <>
                <Typography variant="h6" className={classes.detailHeader}>
                  {titleLabel}
                  &nbsp;
                  {selectedBlNo}
                </Typography>
              </>
            )}
          />
          <CardActions className={classes.cardActionPrimary}>
            <Typography variant="body1" className={classes.cardActionText}>
              {LocalizedString.assign.labelDocumentClearance}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={selectedBl.forwarderId || userRole === ROLE_PPJK || selectedBl.invoiceNo}
              className={classes.assignBlButton}
              onClick={() => onAssignBlPressed(selectedBl)}
            >
              {selectedBl.forwarderId
                ? LocalizedString.assign.buttonCaptionAssigned
                : LocalizedString.assign.buttonCaptionAssignBl}
            </Button>
          </CardActions>
        </Card>
      </>
    );
  }

  renderBlDetail() {
    const {
      classes,
      selectedBl,
      assignOrderType,
      userRole,
      pageSize,
      onChangePaging,
    } = this.props;

    if (!selectedBl) {
      return null;
    }

    const truckingButtonDisabled = isTruckingButtonDisabled(selectedBl, userRole);
    if (truckingButtonDisabled) {
      return null;
    }

    return (
      <Card>
        <CardHeader
          title={(
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Typography variant="h6" className={classes.detailHeader}>
                    {LocalizedString.assign.labelOrderType}
                  </Typography>
                </div>
                <div>
                  <Select
                    value={pageSize}
                    onChange={event => onChangePaging(
                      RXTABLEFIELD_PAGE_SIZE,
                      event.target.value,
                    )}
                  >
                    {DEFAULT_PAGE_SIZE_OPTIONS.map(item => (
                      <MenuItem value={item} key={item}>
                        {item}
                        &nbsp;
                        {LocalizedString.common.labelRows}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </>
          )}
        />
        <CardContent>
          <div style={{ width: '100%', overflow: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  {this.renderTableCellHeader(LocalizedString.assign.labelOrderType,
                    TABLE_FIELD_ORDER_TYPE)}
                  {this.renderTableCellHeader(LocalizedString.assign.labelStatus,
                    TABLE_FIELD_STATUS)}
                  {this.renderTableCellHeader(LocalizedString.assign.labelConsignee,
                    TABLE_FIELD_CONSIGNEE)}
                  {this.renderTableCellHeader(LocalizedString.assign.labelTrucker,
                    TABLE_FIELD_TRUCKER)}
                  <TableCell>
                    {LocalizedString.common.labelActions}
                  </TableCell>
                </TableRow>
                <TableRow>
                  {this.renderTableCellStringFilter(TABLE_FIELD_ORDER_TYPE)}
                  {this.renderTableCellStringFilter(TABLE_FIELD_STATUS)}
                  {this.renderTableCellStringFilter(TABLE_FIELD_CONSIGNEE)}
                  {this.renderTableCellStringFilter(TABLE_FIELD_TRUCKER)}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {assignOrderType && assignOrderType.map(item => (
                  <TableRow>
                    {this.renderTableCellBody(item.orderType)}
                    {this.renderTableCellBody(item.status)}
                    {this.renderTableCellBody(item.billTo)}
                    {this.renderTableCellBody(item.forwarderId)}
                    {this.renderTableCellBodyAction(item.blNo, item.soNo)}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>

          {this.renderTablePagination()}
        </CardContent>
      </Card>
    );
  }

  renderAssignMultipleClearanceButton() {
    const {
      classes,
      multiSelectBl,
      onAssignMultipleBlPressed,
    } = this.props;

    if (multiSelectBl.length > 0) {
      return (
        <div style={{ marginTop: 16 }}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            className={classes.assignMultipleButton}
            onClick={() => onAssignMultipleBlPressed(multiSelectBl)}
          >
            {LocalizedString.assign.labelBulkAssign}
          </Button>
        </div>
      );
    }
    return null;
  }

  render() {
    const {
      classes,
      notificationOpen,
      searchBarText,

      downloading,
      dialogAssignContainerStatus,
      dialogAssignClearanceStatus,
      dialogBulkAssignClearanceStatus,

      onChangeSearchBarText,
      onDetailClosePressed,
      onClearanceClosePressed,

      onCloseAssignMultipleBlPressed,
    } = this.props;

    return (
      <>
        <div className={classes.root}>
          <CssBaseline />

          <PageHeaderUser />

          <div
            className={
              classNames(classes.content, {
                [classes.contentDrawerOpen]: notificationOpen,
                [classes.contentDrawerClosed]: !notificationOpen,
              })
            }
            style={{ padding: '16px' }}
          >
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <div className={classes.pageNavigatorContainer}>
                      <Fab
                        variant="round"
                        classes={{ root: classes.backIcon }}
                        component={Link}
                        to={ROUTE_NAME_DASHBOARD_USER}
                      >
                        <Icon>chevron_left</Icon>
                      </Fab>
                      <div style={{ marginLeft: '32px' }}>
                        <Typography variant="h5" className={classes.pageTitle}>{LocalizedString.assign.title}</Typography>
                        <Typography variant="caption">{LocalizedString.assign.caption}</Typography>
                      </div>
                    </div>
                    <div style={{ flexGrow: 1 }} />
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <div style={{ display: 'flex' }}>
                      <div style={{ flexGrow: 1, alignSelf: 'flex-end' }}>
                        <TextField
                          fullWidth
                          placeholder="Search BL no"
                          value={searchBarText}
                          onChange={event => onChangeSearchBarText(event.target.value)}
                        />
                      </div>
                      <div style={{ display: 'inline-flex', marginLeft: '8px' }}>
                        <Fab
                          size="small"
                          variant="round"
                          color="default"
                          className={classes.searchButton}
                        >
                          <Icon>search</Icon>
                        </Fab>
                      </div>
                    </div>
                    {this.renderAssignMultipleClearanceButton()}
                    <div className={classes.cardItemContainer}>
                      <BlCardContainer />
                    </div>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={8}>
                {this.renderBlHeader()}
                {this.renderBlDetail()}
              </Grid>
            </Grid>
          </div>
        </div>

        <AssignContainerDialog
          open={dialogAssignContainerStatus}
          onClose={onDetailClosePressed}
        />

        <AssignClearanceDialog
          open={dialogAssignClearanceStatus}
          onClose={onClearanceClosePressed}
        />

        <BulkAssignDialog
          open={dialogBulkAssignClearanceStatus}
          onClose={onCloseAssignMultipleBlPressed}
        />

        {downloading && <LoadingIndicator />}
      </>
    );
  }
}
AssignPage.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  notificationOpen: PropTypes.bool.isRequired,
  searchBarText: PropTypes.string.isRequired,
  selectedBlNo: PropTypes.string.isRequired,
  selectedBl: PropTypes.shape(PropTypes.any).isRequired,
  multiSelectBl: PropTypes.arrayOf(PropTypes.any).isRequired,
  userRole: PropTypes.string.isRequired,

  assignOrderType: AssignOrderTypeShape.isRequired,

  pageSize: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  filters: PropTypes.shape(PropTypes.any).isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,

  downloading: PropTypes.bool.isRequired,
  dialogAssignContainerStatus: PropTypes.bool.isRequired,
  dialogAssignClearanceStatus: PropTypes.bool.isRequired,
  dialogBulkAssignClearanceStatus: PropTypes.bool.isRequired,

  onAppear: PropTypes.func.isRequired,
  onChangeSearchBarText: PropTypes.func.isRequired,
  onAssignBlPressed: PropTypes.func.isRequired,
  onDetailMorePressed: PropTypes.func.isRequired,
  onDetailClosePressed: PropTypes.func.isRequired,
  onClearanceClosePressed: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onChangePaging: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,

  onCheckboxPressed: PropTypes.func.isRequired,
  onAssignMultipleBlPressed: PropTypes.func.isRequired,
  onCloseAssignMultipleBlPressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(AssignPage);
