import * as Action from '../action';

const initialState = {
  menuOpen: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.TOGGLE_MENU:
      return { ...state, menuOpen: action.status };
    default: return state;
  }
};
