import * as Action from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case Action.SET_DOC_SUBMISSION_MY_RESPONSE_DETAIL:
      return { ...action.value };
    case Action.CLEAR_DOC_SUBMISSION_MY_RESPONSE_DETAIL:
      return {};
    default:
      return state;
  }
};
