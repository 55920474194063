import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  CssBaseline,
  Grid,
  Card,
  CardContent,
  Fab,
  Icon,
  Typography,
  withStyles,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Input,
  CardHeader,
  Select,
  MenuItem,
  CardActions,
} from '@material-ui/core';
import * as Constant from '../../constant';
import { getSortIcon, isSortFieldActive, toCurrency } from '../../helper';
import LocalizedString from '../../localization';
import {
  LoadingIndicator, PageHeaderAdmin, MainMenu,
} from '../../component';
import TruckingDetailDialog from './trucking-history-detail-dialog.container';
import TruckingBlCard from './bl-card.container';

const styles = theme => ({
  backIcon: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  btnNewBooking: {
    backgroundColor: '#5CB860',
    color: '#ffffff',
    width: '256px',
    '&:hover': {
      backgroundColor: '#558b2f',
    },
  },
  btnAddToCart: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
    width: '256px',
  },
  detailButton: {
    backgroundColor: '#ffffff',
  },
  grow: {
    flexGrow: 1,
  },
  searchButton: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  moreButton: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  cardActionPrimary: {
    display: 'flex',
    alignItems: 'baseline',
    padding: 16,
    justifyContent: 'space-between',
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  cardBlDataHeader: {
    marginBottom: theme.spacing.unit * 2,
  },
  cardItem: {
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '6px',
    boxSizing: 'border-box',
    padding: '16px',
    marginBottom: theme.spacing.unit,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  cardItemActive: {
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  cardItemContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: theme.spacing.unit * 2,
    maxBlockSize: '500px',
    height: '500px',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentMenuDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '256px',
      width: 'auto',
    },
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${Constant.DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  detailHeader: {
    color: '#0066CB',
  },
  itemPerRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    color: '#0266B4',
  },
  root: {
    width: '100%',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  textJumlahPembayaran: {
    textTransform: 'uppercase',
  },
  textTotalPrice: {
    fontSize: '22px',
    fontWeight: 500,
    color: '#0065B3',
    marginRight: theme.spacing.unit * 2,
  },
});

const renderBlDetail = (
  classes, selectedBlInfo, feederServiceDetail,
  filters, sortField, sortDirection,
  pageSize, activePage, totalPage,
  handleChangeSort, onChangePaging, onChangeFilter,
  onDetailPressed,
) => {
  if (!selectedBlInfo) {
    return (<></>);
  }

  return (
    <Card>
      <CardHeader
        title={(
          <>
            <Typography variant="h6" className={classes.detailHeader}>
              Detail
            </Typography>
            <div className={classes.itemPerRowContainer}>
              <Select
                value={pageSize}
                onChange={event => onChangePaging(
                  Constant.RXTABLEFIELD_PAGE_SIZE,
                  event.target.value,
                )}
              >
                {Constant.DEFAULT_PAGE_SIZE_OPTIONS.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                    &nbsp;
                    {LocalizedString.common.labelRows}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </>
        )}
      />
      <CardContent>
        <div style={{ width: '100%', overflow: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={
                      isSortFieldActive(sortField, Constant.TABLE_FIELD_CONTAINER_NUMBER)
                    }
                    IconComponent={getSortIcon(
                      isSortFieldActive(sortField, Constant.TABLE_FIELD_CONTAINER_NUMBER),
                      sortDirection,
                    )}
                    onClick={
                      () => handleChangeSort(Constant.TABLE_FIELD_CONTAINER_NUMBER)
                    }
                  >
                    {LocalizedString.trucking.lblContainerNumber}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={isSortFieldActive(sortField, Constant.TABLE_FIELD_SEAL_NUMBER)}
                    IconComponent={getSortIcon(
                      isSortFieldActive(sortField, Constant.TABLE_FIELD_SEAL_NUMBER),
                      sortDirection,
                    )}
                    onClick={
                      () => handleChangeSort(Constant.TABLE_FIELD_SEAL_NUMBER)
                    }
                  >
                    {LocalizedString.trucking.lblESil}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={isSortFieldActive(sortField, Constant.TABLE_FIELD_STATUS_HAULAGE)}
                    IconComponent={getSortIcon(
                      isSortFieldActive(sortField, Constant.TABLE_FIELD_STATUS_HAULAGE),
                      sortDirection,
                    )}
                    onClick={
                      () => handleChangeSort(Constant.TABLE_FIELD_STATUS_HAULAGE)
                    }
                  >
                    {LocalizedString.trucking.lblContainerTransportStatus}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  Action(s)
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Input
                    type="text"
                    fullWidth
                    value={filters[Constant.TABLE_FIELD_CONTAINER_NUMBER]}
                    onChange={
                      ({ nativeEvent }) => onChangeFilter(
                        Constant.TABLE_FIELD_CONTAINER_NUMBER,
                        nativeEvent.target.value,
                      )
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    type="text"
                    fullWidth
                    value={filters[Constant.TABLE_FIELD_SEAL_NUMBER]}
                    onChange={
                      ({ nativeEvent }) => onChangeFilter(
                        Constant.TABLE_FIELD_SEAL_NUMBER,
                        nativeEvent.target.value,
                      )
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    type="text"
                    fullWidth
                    value={filters[Constant.TABLE_FIELD_STATUS_HAULAGE]}
                    onChange={
                      ({ nativeEvent }) => onChangeFilter(
                        Constant.TABLE_FIELD_STATUS_HAULAGE,
                        nativeEvent.target.value,
                      )
                    }
                  />
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {feederServiceDetail && feederServiceDetail.map(container => (
                <TableRow key={container.id} className={classes.tableRow}>
                  <TableCell>
                    {container.containerNo}
                  </TableCell>
                  <TableCell>
                    {container.sealNo}
                  </TableCell>
                  <TableCell>
                    {container.statusHaulage}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="primary"
                      classes={{ outlinedPrimary: classes.detailButton }}
                      onClick={() => onDetailPressed(container.containerNo)}
                    >
                      Detail
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <div className={classes.tablePagination}>
          <Button
            variant="contained"
            style={{ flexGrow: 1 }}
            disabled={activePage === 1}
            onClick={() => onChangePaging(Constant.RXTABLEFIELD_ACTIVE_PAGE, activePage - 1)}
          >
            {LocalizedString.common.labelPagingPrevButton}
          </Button>

          <div className={classes.tablePaginationPageInput}>
            <span style={{ fontWeight: 500 }}>{LocalizedString.common.labelPagingPage}</span>
            <Input
              type="number"
              value={activePage}
              style={{ width: '50%' }}
              inputProps={{
                min: 1,
                max: totalPage,
                step: 1,
                style: { textAlign: 'center' },
              }}
              onChange={({ nativeEvent }) => onChangePaging(
                Constant.RXTABLEFIELD_ACTIVE_PAGE,
                parseInt(nativeEvent.target.value, 10),
              )}
            />
            <span style={{ fontWeight: 500 }}>
              {`${LocalizedString.common.labelPagingPageOf} ${totalPage}`}
            </span>
          </div>

          <Button
            variant="contained"
            style={{ flexGrow: 1 }}
            disabled={activePage === totalPage}
            onClick={() => onChangePaging(Constant.RXTABLEFIELD_ACTIVE_PAGE, activePage + 1)}
          >
            {LocalizedString.common.labelPagingNextButton}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

class TruckingBase extends Component {
  componentDidMount() {
    this.props.onAppear();
  }

  handleChangeSort = (newSortField) => {
    const { sortField, sortDirection, onChangeSort } = this.props;
    if (newSortField === sortField) {
      onChangeSort(
        sortField,
        sortDirection === Constant.TABLE_SORT_ASCENDING
          ? Constant.TABLE_SORT_DESCENDING
          : Constant.TABLE_SORT_ASCENDING,
      );
    } else {
      onChangeSort(newSortField, Constant.TABLE_SORT_ASCENDING);
    }
  };

  renderBlHeader = () => {
    const {
      classes, selectedBlInfo,
    } = this.props;
    if (selectedBlInfo) {
      return (
        <Card className={classes.cardBlDataHeader}>
          <CardHeader
            title={(
              <>
                <Typography variant="h6" className={classes.detailHeader}>
                  {LocalizedString.trucking.lblBlNo}
                  &nbsp;
                  {selectedBlInfo.refNo}
                </Typography>
              </>
            )}
          />
          <CardActions className={classes.cardActionPrimary}>
            <Typography variant="body1" className={classes.textJumlahPembayaran}>{LocalizedString.trucking.lblTotalPrice}</Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" className={classes.textTotalPrice}>
                Rp.&nbsp;
                {toCurrency(selectedBlInfo.totalPrice || 0)}
              </Typography>
            </div>
          </CardActions>
        </Card>
      );
    }
    return null;
  };

  render() {
    const {
      classes,
      menuOpen,
      downloadingFeederService,
      downloadingFeederServiceDetail,
      feederServiceDetail,
      searchBarText,
      selectedBlInfo,
      selectedContainerNo,
      activePage,
      totalPage,
      filters,
      sortField,
      sortDirection,
      pageSize,
      onChangeSearchBarText,
      onChangePaging,
      onChangeFilter,
      onDetailPressed,
      onCloseDetailDialogPressed,
    } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />

        <PageHeaderAdmin />

        <MainMenu />

        <div
          className={
            classNames(classes.content, {
              [classes.contentMenuDrawerOpen]: menuOpen,
            })
          }
          style={{ padding: '16px' }}
        >
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <div className={classes.pageNavigatorContainer}>
                    {/* <Fab
                      variant="round"
                      classes={{ root: classes.backIcon }}
                      component={Link}
                      to={Constant.ROUTE_NAME_DASHBOARD_USER}
                    >
                      <Icon>chevron_left</Icon>
                    </Fab> */}
                    {/* <div style={{ marginLeft: '32px' }}> */}
                    <div>
                      <Typography variant="h5" className={classes.pageTitle}>{LocalizedString.trucking.title}</Typography>
                      <Typography variant="caption">{LocalizedString.trucking.caption}</Typography>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <div style={{ display: 'flex' }}>
                    <div style={{ flexGrow: 1, alignSelf: 'flex-end' }}>
                      <TextField
                        fullWidth
                        placeholder="Search BL no"
                        value={searchBarText}
                        onChange={event => onChangeSearchBarText(event.target.value)}
                      />
                    </div>
                    <div style={{ display: 'inline-flex', marginLeft: '8px' }}>
                      <Fab
                        size="small"
                        variant="round"
                        color="default"
                        className={classes.searchButton}
                      >
                        <Icon>search</Icon>
                      </Fab>
                    </div>
                  </div>

                  <div className={classes.cardItemContainer}>
                    <TruckingBlCard />
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              {this.renderBlHeader()}

              {renderBlDetail(
                classes, selectedBlInfo, feederServiceDetail,
                filters, sortField, sortDirection,
                pageSize, activePage, totalPage,
                this.handleChangeSort, onChangePaging, onChangeFilter,
                onDetailPressed,
              )}
            </Grid>
          </Grid>
        </div>

        {(downloadingFeederService || downloadingFeederServiceDetail) && <LoadingIndicator />}

        <TruckingDetailDialog
          open={Boolean(selectedContainerNo)}
          onClose={onCloseDetailDialogPressed}
        />
      </div>
    );
  }
}
TruckingBase.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  menuOpen: PropTypes.bool.isRequired,
  downloadingFeederService: PropTypes.bool.isRequired,
  downloadingFeederServiceDetail: PropTypes.bool.isRequired,
  searchBarText: PropTypes.string.isRequired,
  selectedBlInfo: PropTypes.shape(PropTypes.any).isRequired,
  selectedContainerNo: PropTypes.string.isRequired,
  feederServiceDetail: PropTypes.shape(PropTypes.any).isRequired,
  filters: PropTypes.shape(PropTypes.any).isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  activePage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onAppear: PropTypes.func.isRequired,
  onChangeSearchBarText: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onChangePaging: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onDetailPressed: PropTypes.func.isRequired,
  onCloseDetailDialogPressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(TruckingBase);
