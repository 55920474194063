import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router';
import PartyTab from './party.presentation';
import {
  setBookExportActiveForm,
} from '../../redux/action';

const getParty = state => _.values(state.exportBookPartyList.bookParties)
  .filter(party => !party.deleted);

const mapStateToProps = state => ({
  parties: getParty(state),
});

const mapDispatchToProps = dispatch => ({
  onPreviousPressed: () => {
    dispatch(setBookExportActiveForm(0));
  },
  onNextPressed: async () => {
    dispatch(setBookExportActiveForm(2));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartyTab));
