import _ from 'lodash';
import * as Action from '../action';

const initialState = {
  bookNo: 'FI-190306-143716',
  blNo: '',
  modifiedPartyId: 0,
  modalAddPartyVisibility: false,
  modalModifyPartyVisibility: false,
  downloadingPartyList: false,
  uploadingBookPartyDocument: false,
  expandedDropdown: [],
  uploadedAttachment: [],

  party: [],
  inEditParty: [],
  newParty: [],
};

const filteredId = (array = [], id) => array.filter(e => e !== id);
const filteredAttachment = (array = [], id) => array.filter(e => (e.id !== id));

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.ADD_BOOK_NO:
      return { ...state, bookNo: action.bookNo };
    case Action.ADD_BL_NO:
      return { ...state, blNo: action.blNo };
    case Action.DOWNLOADING_FEEDER_SERVICE_BOOK_PARTY_LIST: // to remove?
      return { ...state, downloadingPartyList: action.status };
    case Action.UPLOADING_BOOK_PARTY_DOCUMENT:
      return { ...state, uploadingBookPartyDocument: action.status };
    case Action.SET_EXPANDED_DROPDOWN_ARRAY:
      return { ...state, expandedDropdown: [...state.expandedDropdown, action.id] };
    case Action.SET_COLLAPSED_DROPDOWN_ARRAY:
      return { ...state, expandedDropdown: filteredId(state.expandedDropdown, action.id) };
    case Action.SET_UPLOADED_ATTACHMENT:
      return {
        ...state,
        uploadedAttachment: [...state.uploadedAttachment, {
          id: action.uploadedFile.id,
          content: action.uploadedFile.content,
          fileName: action.uploadedFileName,
          fileSize: action.uploadedFileSize,
        }],
      };
    case Action.REMOVE_UPLOADED_ATTACHMENT:
      return {
        ...state,
        uploadedAttachment: filteredAttachment(state.uploadedAttachment, action.uploadedFileId),
      };
      // case Action.SET_ADD_PARTY_MODAL_VISIBILTIY:
      //   return { ...state, modalAddPartyVisibility: action.status };
      // case Action.SET_MODIFY_PARTY_MODAL_VISIBILTIY:
      //   return { ...state, modalModifyPartyVisibility: action.status };
      // case Action.SET_MODIFIED_PARTY_ID:
      //   return { ...state, modifiedPartyId: action.id };

    case Action.SET_NEW_PARTY:
      return { ...state, newParty: [...state.newParty, action.partyId] };
    case Action.REMOVE_NEW_PARTY:
      return { ...state, newParty: _.without(state.newParty, action.partyId) };
    // case Action.ADD_BOOK_PARTY:
    //   return { ...state, party: { ...state.party, [action.partyId]: action.value } };
    // case Action.REMOVE_BOOK_PARTY:
    //   return { ...state, party: _.omit(state.party, action.partyId) };
    case Action.SET_EDIT_PARTY:
      return { ...state, inEditParty: [...state.inEditParty, action.value] };
    case Action.REMOVE_EDIT_PARTY:
      return { ...state, inEditParty: _.without(state.inEditParty, action.value) };
    // case Action.SET_EDITED_PARTY:
    //   return { ...state, party: { ...state.party, [action.partyId]: action.value } };
    default: return state;
  }
};
