import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import ContainerTrackingBlCard from './bl-card.presentation';
import {
  showErrorSnackbar,
  setTappedAssignBlNo,
  setTappedAssignBaseService,
  downloadAssignOrderTypeAsync,
  addMultiSelectAssignBl,
  removeMultiSelectAssignBl,
} from '../../redux/action';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const getFilteredAssignBl = state => Object.keys(state.assign)
  // .sort((left, right) => {
  //   if (left.toLowerCase() < right.toLowerCase()) {
  //     return -1;
  //   }
  //   return 0;
  // })
  .map((key, index) => ({ ...state.assign[key], uniqueKey: index }))
  .filter(item => item.blNo.toLowerCase().indexOf(state.uiAssign.searchBarText.toLowerCase()) >= 0)
  .sort((left, right) => {
    if (right.soNo.toLowerCase() < left.soNo.toLowerCase()) {
      return -1;
    }
    return 0;
  });

const getSelectedAssignBl = state => _.values(state.assign)
  .find(item => item.blNo === state.uiAssign.tappedBlNo
    && item.exim === state.uiAssign.tappedService);

const mapStateToProps = state => ({
  billingLines: getFilteredAssignBl(state),
  selectedBl: getSelectedAssignBl(state),
  multiSelectBl: state.uiAssign.multiTappedBl,
  userRole: state.currentUser.company.role,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onMorePressed: async (tappedBlNo, tappedService) => {
    try {
      dispatch(setTappedAssignBlNo(tappedBlNo));
      dispatch(setTappedAssignBaseService(tappedService));
      await dispatch(downloadAssignOrderTypeAsync(tappedBlNo));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onCheckboxPressed: (multiSelectBl, bl) => {
    const match = multiSelectBl.find(item => item.blNo === bl.blNo && item.exim === bl.exim);
    if (!match) {
      dispatch(addMultiSelectAssignBl(bl));
    } else {
      dispatch(removeMultiSelectAssignBl(bl));
    }
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContainerTrackingBlCard));
