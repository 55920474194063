import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import {
  withStyles,
  ListItem,
  Typography,
  AppBar,
  Divider,
  Toolbar,
  Icon,
  IconButton,
  Badge,
  Drawer,
  List,
  ListSubheader,
  Button,
  Tooltip,
} from '@material-ui/core';
import * as Constant from '../../constant';
import LocalizedString from '../../localization';
import { LoadingIndicator, MySnackbar } from '..';
import { toMoment } from '../../helper';

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  backgroundWhite: {
    backgroundColor: '#ffffff',
  },
  cartDisabled: {
    borderRadius: 32,
    backgroundColor: 'rgba(80, 171, 241, 0.2) !important',
    color: '#0066CB !important',
    boxShadow: 'none',
  },
  drawerContentMarginTop: {
    marginTop: 0,
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing.unit * 8,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing.unit * 8,
    },
  },
  drawerRoot: {
    marginTop: 64,
    height: 'calc(100% - 64px)',
    [theme.breakpoints.up('md')]: {
      width: Constant.DRAWER_NOTIFICATION_WIDTH,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  grow: {
    flexGrow: 1,
  },
  link: {
    textDecoration: 'none',
    marginLeft: theme.spacing.unit * 4,
  },
  spacing: {
    marginLeft: theme.spacing.unit * 4,
  },
  toolbar: {
    backgroundColor: 'white',
  },
});

const renderCartButton = (classes, shoppingCart) => {
  if (!shoppingCart) {
    return (
      <Button disabled variant="contained" size="medium" color="primary" classes={{ disabled: classes.cartDisabled }}>
        <Typography color="inherit" style={{ textTransform: 'initial' }}>
          {LocalizedString.common.labelCartEmpty}
          &emsp;
        </Typography>
        <Icon>shopping_cart</Icon>
      </Button>
    );
  }

  return (
    <Button
      variant="contained"
      size="medium"
      color="primary"
      className={classes.cartDisabled}
      component={Link}
      to={Constant.ROUTE_NAME_CART_PAGE}
    >
      <Typography color="inherit" style={{ textTransform: 'initial' }}>
        {LocalizedString.common.labelCartNotEmpty.replace('{0}', shoppingCart.length)}
        &emsp;
      </Typography>
      <Icon>shopping_cart</Icon>
    </Button>
  );
};

class PageHeaderUser extends Component {
  state = {
    drawNotificationNumber: 10,
  };

  componentDidMount() {
    this.props.onAppear();
  }

  showMore = () => {
    this.setState(current => ({
      drawNotificationNumber: current.drawNotificationNumber + 10,
    }));
  }

  showLess = () => {
    this.setState({
      drawNotificationNumber: 10,
    });
  }

  onNotificationPressed = () => {
    const {
      alwaysOpen,
      notifications,
      notificationOpen,
      onToggleNotificationPressed,
      onMarkAsReadOnly,
    } = this.props;

    if (!alwaysOpen) {
      onToggleNotificationPressed(!notificationOpen, notifications);
    } else {
      onMarkAsReadOnly(notifications);
    }
  }

  renderNotificationIcon = () => {
    const {
      classes,
      alwaysOpen, notificationOpen, notifications,
      onToggleNotificationPressed,
    } = this.props;

    const notificationCount = _.values(notifications).filter(item => !item.read).length;
    if (notificationCount > 0) {
      return (
        <Tooltip title="Notifications">
          <IconButton
            className={classes.spacing}
            onClick={() => this.onNotificationPressed()}
          >
            <Badge
              badgeContent={notificationCount}
              max={Constant.MAX_NOTIFICATION_COUNT}
              color="secondary"
              showZero={false}
            >
              <Icon
                style={{ fontSize: '24pt' }}
                color={notificationCount ? 'secondary' : 'default'}
              >
                notifications_none
              </Icon>
            </Badge>
          </IconButton>
        </Tooltip>
      );
    }

    return (
      <Tooltip title="Notifications">
        <IconButton
          className={classes.spacing}
          onClick={() => !alwaysOpen
            && onToggleNotificationPressed(!notificationOpen, notifications)}
        >
          <Icon
            style={{ fontSize: '24pt' }}
            color={notificationCount ? 'secondary' : 'default'}
          >
            notifications_none
          </Icon>
        </IconButton>
      </Tooltip>
    );
  }

  render() {
    const {
      classes,
      notificationOpen,
      notifications,
      alwaysOpen,
      loggingOut,
      globalLoading,
      shoppingCart,
      onLogoutButtonPressed,
    } = this.props;

    const renderNotifications = () => {
      if (notifications.length > 0 && (notificationOpen || alwaysOpen)) {
        return (
          <>
            {notifications.slice(0, this.state.drawNotificationNumber).map(notification => (
              <Fragment key={notification.id}>
                <ListItem>
                  <div>
                    <Typography variant="body2">
                      {notification.title}
                    </Typography>
                    <Typography variant="body1">
                      <div style={{ whiteSpace: 'pre-line' }}>
                        {notification.message}
                      </div>
                    </Typography>
                    <Typography variant="caption">
                      <span>
                        {toMoment(notification.receivedOn).format('DD MMM YYYY')}
                      </span>
                      <span>&nbsp;|&nbsp;</span>
                      <span>
                        {toMoment(notification.receivedOn).format('HH:mm A')}
                      </span>
                    </Typography>
                  </div>
                </ListItem>
                <Divider />
              </Fragment>
            ))}
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              {this.state.drawNotificationNumber < notifications.length && (
                <Button
                  onClick={this.showMore}
                  color="primary"
                  style={{ flexGrow: 1 }}
                >
                  Show More
                </Button>
              )}
              {this.state.drawNotificationNumber > 10 && (
                <Button
                  onClick={this.showLess}
                  color="secondary"
                  style={{ flexGrow: 1 }}
                >
                  Show Less
                </Button>
              )}
            </div>
          </>
        );
      }

      return (
        <ListItem>
          <Typography>{LocalizedString.common.labelNotificationEmpty}</Typography>
        </ListItem>
      );
    };

    return (
      <>
        {(loggingOut || globalLoading) && <LoadingIndicator />}

        <AppBar position="sticky" elevation={1} className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <img src={Constant.IMAGE_LOGO_CDP_2} alt="logo" />
            <Typography>
              <Link to={Constant.ROUTE_NAME_ABOUT_US} className={classes.link}>
                About Us
              </Link>
            </Typography>
            <Typography>
              <Link to={Constant.ROUTE_NAME_CONTACT_US} className={classes.link}>
                Contact Us
              </Link>
            </Typography>
            <div className={classes.grow} />
            <div>
              {renderCartButton(classes, shoppingCart)}

              {this.renderNotificationIcon()}
              <Tooltip title="Log Out">
                <IconButton
                  onClick={onLogoutButtonPressed}
                >
                  <Icon style={{ fontSize: '24pt' }}>exit_to_app</Icon>
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          open={notificationOpen || alwaysOpen}
          variant="persistent"
          anchor="right"
          classes={{
            paper: classes.drawerRoot,
          }}
        >
          {/* <div className={classes.drawerContentMarginTop} /> */}
          <List>
            <ListSubheader className={classes.backgroundWhite}>
              <Typography variant="h6">{LocalizedString.common.labelNotificationHeader}</Typography>
            </ListSubheader>
            {renderNotifications()}
          </List>
        </Drawer>

        <MySnackbar />
      </>
    );
  }
}
PageHeaderUser.propTypes = {
  classes: PropTypes.shape(PropTypes.any),
  notifications: PropTypes.shape(PropTypes.any),
  notificationOpen: PropTypes.bool,
  alwaysOpen: PropTypes.bool,
  loggingOut: PropTypes.bool,
  globalLoading: PropTypes.bool.isRequired,
  shoppingCart: PropTypes.arrayOf(PropTypes.any).isRequired,
  onToggleNotificationPressed: PropTypes.func.isRequired,
  onMarkAsReadOnly: PropTypes.func.isRequired,
  onLogoutButtonPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,

};
PageHeaderUser.defaultProps = {
  classes: {},
  notifications: {},
  notificationOpen: false,
  alwaysOpen: false,
  loggingOut: false,
};

export default withStyles(styles)(PageHeaderUser);
