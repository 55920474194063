import * as Action from '../action';

const initialState = {
  loggingIn: false,
  error: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.LOGGING_IN:
      return { ...state, loggingIn: action.status };
    default: return state;
  }
};
