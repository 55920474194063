import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  CssBaseline,
  Card,
  CardHeader,
  Avatar,
  Icon,
  Select,
  MenuItem,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Input,
  TableBody,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import {
  MainMenu, PageHeader, RoundedButton, LoadingIndicator,
} from '../../component';
import {
  DRAWER_WIDTH,
  RXTABLEFIELD_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS,
  TABLE_SORT_ASCENDING,
  TABLE_SORT_DESCENDING,
  RXTABLEFIELD_ACTIVE_PAGE,
  TABLE_FIELD_MASTER_VENDOR_ID,
  TABLE_FIELD_MASTER_VENDOR_VENDOR_ID,
  TABLE_FIELD_MASTER_VENDOR_VENDOR_NAME,
} from '../../constant';
import LocalizedString from '../../localization';
import { isSortFieldActive, getSortIcon } from '../../helper';
import VendorEditDialog from './vendor-edit-dialog.container';
import VendorAddDialog from './vendor-add-dialog.container';

const styles = theme => ({
  cellAction: {
    width: '128px',
  },
  contentContainer: {
    padding: '16px 32px',
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100%',
  },
  cardRoot: {
    overflow: 'visible',
  },
  cardAvatar: {
    height: 64,
    width: 64,
    padding: '12px',
    borderRadius: 4,
    marginTop: theme.spacing.unit * -5,
    background: 'linear-gradient(60deg, #0164b2, #014982)',
    boxShadow:
      '0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)',
  },
  iconButtonPadding4: {
    padding: '4px',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

class MasterVendorPage extends Component {
  componentDidMount() {
    this.props.onAppear();
  }

  render() {
    const {
      classes,
      downloadingVendors,
      menuOpen,
      vendors,
      activePage,
      totalPage,
      pageSize,
      filters,
      sortField,
      sortDirection,
      onChangeFilter,
      onChangePaging,
      onChangeSort,

      dialogAddStatus,
      dialogEditStatus,
      onAddNewPressed,
      onAddDialogClosePressed,
      onEditPressed,
      onEditDialogClosePressed,
    } = this.props;

    const handleChangeSort = (newSortField) => {
      if (newSortField === sortField) {
        onChangeSort(
          sortField,
          sortDirection === TABLE_SORT_ASCENDING
            ? TABLE_SORT_DESCENDING
            : TABLE_SORT_ASCENDING,
        );
      } else {
        onChangeSort(newSortField, TABLE_SORT_ASCENDING);
      }
    };

    const renderDataAsTable = () => {
      if (vendors) {
        return (
          <>
            {vendors.map(vendor => (
              <TableRow className={classes.tableRow}>
                <TableCell>
                  <Typography>{vendor.index}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{vendor.id}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{vendor.name}</Typography>
                </TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    className={classes.iconButtonPadding4}
                    onClick={() => onEditPressed(vendor.id)}
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </>
        );
      }

      return (<></>);
    };

    const renderTablePagination = () => (
      <div className={classes.tablePagination}>
        <Button
          variant="contained"
          style={{ flexGrow: 1 }}
          disabled={activePage === 1}
          onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage - 1)}
        >
          {LocalizedString.common.labelPagingPrevButton}
        </Button>

        <div className={classes.tablePaginationPageInput}>
          <span style={{ fontWeight: 500 }}>{LocalizedString.common.labelPagingPage}</span>
          <Input
            type="number"
            value={activePage}
            style={{ width: '50%' }}
            inputProps={{
              min: 1,
              max: totalPage,
              step: 1,
              style: { textAlign: 'center' },
            }}
            onChange={({ nativeEvent }) => onChangePaging(
              RXTABLEFIELD_ACTIVE_PAGE,
              parseInt(nativeEvent.target.value, 10),
            )}
          />
          <span style={{ fontWeight: 500 }}>
            {`${LocalizedString.common.labelPagingPageOf} ${totalPage}`}
          </span>
        </div>

        <Button
          variant="contained"
          style={{ flexGrow: 1 }}
          disabled={activePage === totalPage}
          onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage + 1)}
        >
          {LocalizedString.common.labelPagingNextButton}
        </Button>
      </div>
    );

    return (
      <>
        <CssBaseline />
        <div>
          <MainMenu />
        </div>

        <div
          className={classes.contentContainer}
          style={{ marginLeft: menuOpen ? DRAWER_WIDTH : 0 }}
        >
          <div>
            <PageHeader
              pageTitle={LocalizedString.masterVendor.title}
              pageCaption={LocalizedString.masterVendor.caption}
            />

            <div style={{ flexGrow: 0, width: '100%' }}>
              <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                  avatar={(
                    <Avatar classes={{ root: classes.cardAvatar }}>
                      <Icon>business</Icon>
                    </Avatar>
                  )}
                  title={(
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <RoundedButton
                        size="medium"
                        onClick={() => onAddNewPressed()}
                      >
                        {LocalizedString.masterVendor.btnAddNew}
                      </RoundedButton>
                      <Select
                        value={pageSize}
                        onChange={event => onChangePaging(
                          RXTABLEFIELD_PAGE_SIZE,
                          event.target.value,
                        )}
                      >
                        {DEFAULT_PAGE_SIZE_OPTIONS.map(item => (
                          <MenuItem value={item} key={item}>
                            {item}
                            &nbsp;
                            {LocalizedString.common.labelRows}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  )}
                />
                <CardContent>
                  <div style={{ width: '100%', overflow: 'auto' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <TableSortLabel
                              active={
                                isSortFieldActive(sortField, TABLE_FIELD_MASTER_VENDOR_ID)
                              }
                              IconComponent={getSortIcon(
                                isSortFieldActive(sortField, TABLE_FIELD_MASTER_VENDOR_ID),
                                sortDirection,
                              )}
                              onClick={
                                () => handleChangeSort(TABLE_FIELD_MASTER_VENDOR_ID)
                              }
                            >
                              {LocalizedString.masterVendor.labelId}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={
                                isSortFieldActive(sortField, TABLE_FIELD_MASTER_VENDOR_VENDOR_ID)
                              }
                              IconComponent={getSortIcon(
                                isSortFieldActive(sortField, TABLE_FIELD_MASTER_VENDOR_VENDOR_ID),
                                sortDirection,
                              )}
                              onClick={
                                () => handleChangeSort(TABLE_FIELD_MASTER_VENDOR_VENDOR_ID)
                              }
                            >
                              {LocalizedString.masterVendor.labelVendorId}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={
                                isSortFieldActive(sortField, TABLE_FIELD_MASTER_VENDOR_VENDOR_NAME)
                              }
                              IconComponent={getSortIcon(
                                isSortFieldActive(sortField, TABLE_FIELD_MASTER_VENDOR_VENDOR_NAME),
                                sortDirection,
                              )}
                              onClick={
                                () => handleChangeSort(TABLE_FIELD_MASTER_VENDOR_VENDOR_NAME)
                              }
                            >
                              {LocalizedString.masterVendor.labelVendorName}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.cellAction}>
                            {LocalizedString.common.labelActions}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <Input
                              type="text"
                              fullWidth
                              value={filters[TABLE_FIELD_MASTER_VENDOR_ID]}
                              onChange={
                                ({ nativeEvent }) => onChangeFilter(
                                  TABLE_FIELD_MASTER_VENDOR_ID,
                                  nativeEvent.target.value,
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Input
                              type="text"
                              fullWidth
                              value={filters[TABLE_FIELD_MASTER_VENDOR_VENDOR_ID]}
                              onChange={
                                ({ nativeEvent }) => onChangeFilter(
                                  TABLE_FIELD_MASTER_VENDOR_VENDOR_ID,
                                  nativeEvent.target.value,
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Input
                              type="text"
                              fullWidth
                              value={filters[TABLE_FIELD_MASTER_VENDOR_VENDOR_NAME]}
                              onChange={
                                ({ nativeEvent }) => onChangeFilter(
                                  TABLE_FIELD_MASTER_VENDOR_VENDOR_NAME,
                                  nativeEvent.target.value,
                                )
                              }
                            />
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>

                      <TableBody>{renderDataAsTable()}</TableBody>
                    </Table>
                  </div>

                  {renderTablePagination()}
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
        <VendorEditDialog
          open={dialogEditStatus}
          onClose={onEditDialogClosePressed}
        />

        <VendorAddDialog
          open={dialogAddStatus}
          onClose={onAddDialogClosePressed}
        />

        {downloadingVendors && <LoadingIndicator />}
      </>
    );
  }
}
MasterVendorPage.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  downloadingVendors: PropTypes.bool.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  vendors: PropTypes.arrayOf(PropTypes.any).isRequired,
  activePage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  filters: PropTypes.shape(PropTypes.any).isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onChangePaging: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,

  dialogAddStatus: PropTypes.bool.isRequired,
  dialogEditStatus: PropTypes.bool.isRequired,
  onAddNewPressed: PropTypes.func.isRequired,
  onAddDialogClosePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onEditDialogClosePressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(MasterVendorPage);
