import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import {
  withStyles, ButtonBase, Grid, Typography,
} from '@material-ui/core';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import * as Constant from '../../constant';
import LocalizedString from '../../localization';
import { toMoment } from '../../helper';

const getItemSize = items => index => (items.length - 1 === index ? 304 : 312);

const styles = theme => ({
  cardItem: {
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '6px',
    boxSizing: 'border-box',
    padding: '16px',
    marginBottom: theme.spacing.unit,
    '&:last-child': {
      marginBottom: 0,
    },
    '&:after': {
      content: '',
      position: 'absolute',
      bottom: '0px',
      left: 0,
      width: '100%',
      height: '10px',
      backgroundColor: '#f4f4f4',
    },
  },
  cardItemActive: {
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  spacer: {
    height: '8px',
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

const ContainerTrackingBlCard = ({
  classes,
  billingLines,
  selectedBlNo,
  onMorePress,
}) => (
  <>
    <AutoSizer>
      {({ width, height }) => (
        <List
          autoHeight
          className="List"
          height={height}
          itemCount={billingLines.length}
          itemSize={getItemSize(billingLines)}
          width={width}
        >
          {({ index, style }) => {
            const item = billingLines[index];
            return (
              <div style={style}>
                <ButtonBase
                  component="div"
                  key={item.blNo}
                  className={classNames(classes.cardItem, {
                    [classes.cardItemActive]: selectedBlNo
                      && item.coRef.toUpperCase() === selectedBlNo.toUpperCase(),
                  })}
                  onClick={() => onMorePress(item.coRef)}
                >
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <Typography color="primary" variant="h6" className={classes.ellipsis}>
                        {LocalizedString.export.labelBlNo}
                        &nbsp;
                        {item.blNo}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography variant="caption" className={classes.ellipsis}>{LocalizedString.export.labelInvoiceNo}</Typography>
                      <Typography className={classes.ellipsis}>
                        {item.invoiceNo || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="caption">{LocalizedString.export.labelDoNo}</Typography>
                      <Typography className={classes.ellipsis}>
                        {item.coRef}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.alignRight}>
                      <Typography variant="caption" className={classes.ellipsis}>{LocalizedString.export.labelShippingLineVesselVoyage}</Typography>
                      <Typography className={classes.ellipsis}>
                        {item.vessel}
                        -
                        {item.voyage}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Typography variant="caption" className={classes.ellipsis}>{LocalizedString.export.labelCDPClosingTime}</Typography>
                      <Typography className={classes.ellipsis}>
                        {item.cdpClosingTime ? toMoment(item.cdpClosingTime).format(Constant.MOMENT_DATE_FORMAT_STRING) : '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Typography variant="caption">{LocalizedString.export.labelKmtDate}</Typography>
                      <Typography className={classes.ellipsis}>
                        {item.kmtDate ? toMoment(item.kmtDate).format(Constant.MOMENT_DATE_FORMAT_STRING) : '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Typography variant="caption" className={classes.ellipsis}>{LocalizedString.export.labelBillTo}</Typography>
                      <Typography className={classes.ellipsis}>
                        {item.billTo || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Typography variant="caption">{LocalizedString.export.labelForwarderId}</Typography>
                      <Typography className={classes.ellipsis}>
                        {item.forwarderId}
                      </Typography>
                    </Grid>
                  </Grid>
                </ButtonBase>
                <div className={classNames({
                  [classes.spacer]: index !== billingLines.length - 1,
                })}
                />
              </div>
            );
          }}
        </List>
      )}
    </AutoSizer>
  </>
);

export default withStyles(styles)(ContainerTrackingBlCard);
