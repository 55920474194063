import {
  submittingRegistrationInfo, addToken, addRegistrationInfo,
  downloadMyProfileAsync, downloadProfilePictureAsync, clearAllStates,
} from '..';
import * as Helper from '../../../helper';
import * as Validation from '../../../validation';
import * as Constant from '../../../constant';

export default (info, navigateTo) => async (dispatch, getState) => {
  try {
    dispatch(submittingRegistrationInfo(true));

    Validation.validateRegistrationInfo(info);

    dispatch(addRegistrationInfo(info));

    const { registrationInfo } = getState();

    const stateInfo = { ...registrationInfo, username: registrationInfo.email };
    const response = await Helper.register(stateInfo);

    dispatch(clearAllStates());
    dispatch(addToken(response.token));

    await dispatch(downloadMyProfileAsync());
    const { id } = getState().currentUser;
    await dispatch(downloadProfilePictureAsync(id));

    navigateTo(Constant.ROUTE_NAME_HOME);
  } finally {
    dispatch(submittingRegistrationInfo(false));
  }
};
