import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Fab, Typography, Icon,
} from '@material-ui/core';
import { CurrentProfilePicture } from '..';

const styles = theme => ({
  container: {
    display: 'flex',
    marginBottom: '32px',
    flexShrink: 0,
  },
  moreMenuIcon: {
    backgroundColor: '#ffffff',
    color: '#000000',
    '&:hover': {
      backgroundColor: theme.palette.background.primary,
    },
  },
  avatar: {
    width: '24px',
    height: '24px',
  },
});

const PageHeader = ({
  classes, pageTitle, pageCaption, menuOpen, onToggleMenuPressed, onProfilePicturePressed,
}) => (
  <div className={classes.container}>
    <div style={{ flexGrow: 0 }}>
      <Fab
        size="small"
        color="inherit"
        classes={{ colorInherit: classes.moreMenuIcon }}
        onClick={() => onToggleMenuPressed(!menuOpen)}
      >
        <Icon>more_vert</Icon>
      </Fab>
    </div>
    <div style={{ flexGrow: 1, padding: '8px 16px' }}>
      <Typography variant="h4">{pageTitle}</Typography>
      <Typography variant="caption">{pageCaption}</Typography>
    </div>
    <div style={{ flexGrow: 0, display: 'flex', verticalAlign: 'baseline' }}>
      <Typography variant="body1">UserName</Typography>
      <CurrentProfilePicture onClick={onProfilePicturePressed} className={classes.avatar} />
    </div>
  </div>
);
PageHeader.propTypes = {
  classes: PropTypes.shape(PropTypes.any),
  pageTitle: PropTypes.string,
  pageCaption: PropTypes.string,
  menuOpen: PropTypes.bool,
  onToggleMenuPressed: PropTypes.func,
  onProfilePicturePressed: PropTypes.func,
};
PageHeader.defaultProps = {
  classes: {},
  pageTitle: '',
  pageCaption: '',
  menuOpen: true,
  onToggleMenuPressed: () => {},
  onProfilePicturePressed: () => {},
};

export default withStyles(styles)(PageHeader);
