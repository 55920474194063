import { connect } from 'react-redux';
import {
  updatePasswordAsync, clearUIError, setUIError, updatingPassword,
} from '../../redux/action';
import * as Constant from '../../constant';
import ChangePassword from './change-password.presentation';

const mapStateToProps = state => ({
  updatingPassword: state.uiProfile.updatingPassword,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onUpdatePassword: async (values) => {
    try {
      dispatch(updatingPassword(true));
      dispatch(clearUIError(Constant.RXSTATE_PROFILE_PAGE));

      await dispatch(updatePasswordAsync(
        values.oldPassword,
        values.newPassword,
        ownProps.history.push,
      ));
    } catch (error) {
      dispatch(setUIError(Constant.RXSTATE_PROFILE_PAGE, error.message));
    } finally {
      dispatch(updatingPassword(false));
    }
  },
  onCancel: () => {
    ownProps.history.push(Constant.ROUTE_NAME_PROFILE);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
