import { downloadingExportDGClassList, addExportDGClassList } from '../simple-action';
import { downloadExportDGClassList } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  try {
    dispatch(downloadingExportDGClassList(true));
    const dgClassList = await downloadExportDGClassList(token);
    dispatch(addExportDGClassList(dgClassList));
  } finally {
    dispatch(downloadingExportDGClassList(false));
  }
};
