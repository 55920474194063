import * as Action from '../action';

const initialState = {
  selectedBls: [],
  assignClearanceForm: {
    forwarderId: undefined,
    truckerId: undefined,
  },
  submitting: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.ADD_ASSIGN_MULTIPLE_CLEARANCE:
      return { ...state, selectedBls: action.assignClearance };
    case Action.REMOVE_ASSIGN_MULTIPLE_CLEARANCE:
      return initialState;
    case Action.UPLOADING_ASSIGN_CLEARANCE:
      return { ...state, submitting: action.status };
    default: return state;
  }
};
