import { downloadingFeederServiceBLList, setFeederServiceBLList } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { bookNo } = getState().uiBookFeederParty;

  // const bookNo = 'FI-180625-153554';
  try {
    dispatch(downloadingFeederServiceBLList(true));
    const blList = await Helper.downloadFeederServiceBLList(token);
    dispatch(setFeederServiceBLList(blList));
  } finally {
    dispatch(downloadingFeederServiceBLList(false));
  }
};
