import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment-timezone';
import {
  withStyles, ButtonBase, Grid, Typography, TextField, Fab, Icon,
} from '@material-ui/core';
import LocalizedString from '../../localization';
import {
  MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING,
  DEFAULT_STYLES,
  COLOR_ALMOST_DUE_CONTAINER,
  COLOR_PAST_DUE_CONTAINER,
  COLOR_PAST_DUE_CIRCLE,
  COLOR_ALMOST_DUE_CIRCLE,
} from '../../constant';
import { LoadingIndicator } from '../../component';
import { toMoment } from '../../helper';

const styles = theme => ({
  alignRight: {
    textAlign: 'right',
  },
  cardItem: {
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '6px',
    boxSizing: 'border-box',
    padding: '16px',
    marginBottom: theme.spacing.unit,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  cardItemActive: {
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  cardItemContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: theme.spacing.unit * 2,
    maxBlockSize: '500px',
  },
  indicatorContainer: {
    padding: '4px 8px !important',
    display: 'flex',
    backgroundColor: '#FFE8A4',
    alignItems: 'center',
    maxWidth: 'unset',
    margin: '-4px',
    width: 'calc(100% + 12px)',
    flexBasis: 'auto',
  },
  indicatorCircle: {
    marginLeft: '8px',
    width: 16,
    height: 16,
    borderRadius: '16px',
    background: 'blue',
  },
  indicatorText: {
    marginLeft: '12px',
  },
  searchButton: {
    ...DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
});

class TermCard extends Component {
  componentDidMount() {
    this.props.onAppear();
  }

  renderCardIndicator = (dueDate, daysToNotify) => {
    const { classes } = this.props;
    const nowStr = moment().format('YYYY-MM-DDT00:00:00');
    const now = moment(nowStr);
    const timeDiff = moment(dueDate).diff(now, 'days');
    const indicatorCountdown = daysToNotify - timeDiff;

    const indicatorStatus = indicatorCountdown < daysToNotify
      ? LocalizedString.myPayment.labelAlmostDue : LocalizedString.myPayment.labelPastDue;
    const indicatorColor = indicatorCountdown < daysToNotify
      ? COLOR_ALMOST_DUE_CONTAINER : COLOR_PAST_DUE_CONTAINER;
    const circleColor = indicatorCountdown < daysToNotify
      ? COLOR_ALMOST_DUE_CIRCLE : COLOR_PAST_DUE_CIRCLE;

    if (timeDiff <= daysToNotify) {
      return (
        <Grid
          item
          xs={12}
          className={classes.indicatorContainer}
          style={{ backgroundColor: indicatorColor }}
        >
          <div className={classes.indicatorCircle} style={{ backgroundColor: circleColor }} />
          <div className={classes.indicatorText}>
            {indicatorStatus}
          </div>
        </Grid>
      );
    }
    return null;
  }

  render() {
    const {
      classes,
      bl,
      searchBarText,
      selectedBlNo,
      downloading,
      onChangeSearchBarText,
      onMorePressed,
    } = this.props;

    return (
      <>
        <div style={{ display: 'flex', marginTop: '24px' }}>
          <div style={{ flexGrow: 1, alignSelf: 'flex-end' }}>
            <TextField
              fullWidth
              placeholder="Search BL no"
              value={searchBarText}
              onChange={event => onChangeSearchBarText(event.target.value)}
            />
          </div>
          <div style={{ display: 'inline-flex', marginLeft: '8px' }}>
            <Fab
              size="small"
              variant="round"
              color="default"
              className={classes.searchButton}
            >
              <Icon>search</Icon>
            </Fab>
          </div>
        </div>

        <div className={classes.cardItemContainer}>
          {bl.map(item => (
            <>
              <ButtonBase
                component="div"
                key={item.invoiceNo}
                className={classNames(classes.cardItem, {
                  [classes.cardItemActive]: selectedBlNo
                    && item.invoiceNo.toUpperCase() === selectedBlNo.toUpperCase(),
                })}
                onClick={() => onMorePressed(item.invoiceNo)}
              >
                <Grid container spacing={24}>
                  {this.renderCardIndicator(item.termPaymentDueDate,
                    item.notificationDaysBeforeDueDate)}
                  <Grid item xs={12}>
                    <Typography color="primary" variant="h6">
                      {LocalizedString.myPaymentTerm.labelBlNo}
                      &nbsp;
                      {item.blNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption">
                      {LocalizedString.myPaymentTerm.labelInvoiceNo}
                    </Typography>
                    <Typography>
                      {item.invoiceNo}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.alignRight}>
                    <Typography variant="caption">
                      {LocalizedString.myPaymentTerm.labelDueDate}
                    </Typography>
                    <Typography>
                      {toMoment(item.termPaymentDueDate)
                        .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)}
                    </Typography>
                  </Grid>
                </Grid>
              </ButtonBase>
            </>
          ))}
        </div>
        {downloading && <LoadingIndicator />}
      </>
    );
  }
}
TermCard.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  bl: PropTypes.arrayOf(PropTypes.any).isRequired,
  searchBarText: PropTypes.string.isRequired,
  selectedBlNo: PropTypes.string.isRequired,
  downloading: PropTypes.bool.isRequired,

  onAppear: PropTypes.func.isRequired,
  onChangeSearchBarText: PropTypes.func.isRequired,
  onMorePressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(TermCard);
