import { downloadTariffSimulationHostBL } from '../../../helper';
import {
  setTariffSimulationHostBL, downloadingTariffSimulationHostBL, clearTariffSimulationHostBL,
  showHostBLCard,
} from '../simple-action';


export default blNo => async (dispatch, getState) => {
  try {
    dispatch(downloadingTariffSimulationHostBL(true));
    dispatch(clearTariffSimulationHostBL(true));

    const { token } = getState().authentication;
    const tariffSimulationHostBL = await downloadTariffSimulationHostBL(blNo, token);

    if (tariffSimulationHostBL) {
      dispatch(setTariffSimulationHostBL(tariffSimulationHostBL));
      dispatch(showHostBLCard(true));
    }
  } finally {
    dispatch(downloadingTariffSimulationHostBL(false));
  }
};
