import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from '@material-ui/core';
import { reduxForm } from 'redux-form';
import { LoadingIndicator } from '../../component';
import LocalizedString from '../../localization';
import {
  DEFAULT_STYLES,
  RXFORM_MASTER_USER,
} from '../../constant';

const styles = () => ({
  btnPrimary: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  dialogActions: {
    margin: '0 -24px',
    marginTop: '24px',
    padding: 24,
    borderTop: '1px solid #C4C4C4',
    justifyContent: 'space-between',
    paddingBottom: 0,
    display: 'flex',
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
});

const FieldDeleteDialog = ({
  deleting,
  open,
  selectedId,
  onClose,
  onConfirmDeletePressed,
}) => (
  <>
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{LocalizedString.common.alertTitleConfirmation}</DialogTitle>
      <DialogContent>
        {LocalizedString.common.msgDeleteConfirmation}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onConfirmDeletePressed(selectedId)}
        >
          {LocalizedString.common.buttonCaptionYes}
        </Button>
        <Button
          color="secondary"
          onClick={onClose}
        >
          {LocalizedString.common.buttonCaptionCancel}
        </Button>
      </DialogActions>
    </Dialog>
    {deleting && <LoadingIndicator />}
  </>
);
FieldDeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  deleting: PropTypes.bool.isRequired,

  selectedId: PropTypes.string.isRequired,

  onClose: PropTypes.func.isRequired,
};

export default reduxForm({
  form: RXFORM_MASTER_USER,
  enableReinitialize: true,
})(withStyles(styles)(FieldDeleteDialog));
