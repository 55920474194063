import { 
  setChangingOrderPaymentStatus,
  addPaymentId,
  setCheckoutPasswordTextInput,
  showErrorSnackbar,
  setMyPaymentOutstandingSearchText,
  removeAllCheckoutItem,
} from '../simple-action';
import { changeOrderPayment, displayAlertError, openNewTab } from '../../../helper';
import downloadMyPaymentOutstandingAsync from './downloadMyPaymentOutstandingAsync';
import LocalizedString from '../../../localization';
import { URL_PAYMENT } from '../../../constant';

export default password => async (dispatch, getState) => {
  try {
    dispatch(setChangingOrderPaymentStatus(true));

    const { token } = getState().authentication;
    const { tappedItemNo } = getState().uiMyPaymentOutstanding;
    const { company } = getState().currentUser;
    const { staticVa } = company;
    const { selectedBank } = getState().uiCheckout;

    const retval = await changeOrderPayment(staticVa, password,
      tappedItemNo, selectedBank, token);
    const { paymentId, proformaNo } = retval;
    dispatch(addPaymentId(paymentId));
    dispatch(setCheckoutPasswordTextInput(''));

    // const { shoppingCheckout } = getState();
    // Object.values(shoppingCheckout).forEach((header) => {
    //   header.items.forEach((x) => {
    //     if (x.checked) {
    //       dispatch(removeShoppingCartItem(x.blNumber, x.itemId));
    //     }
    //   });
    // });

    // if (company.staticVa) {
    //   dispatch(downloadMyPaymentOutstandingAsync())
    //     .catch(error => {
    //       dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    //     });
    //   dispatch(startPaymentProcess(false));
    //   dispatch(setMyPaymentOutstandingSearchText(proformaNo));
    //   // dispatch(setMyPaymentActiveTab(1));
    //   dispatch(removeAllCheckoutItem());
    //   NavigationService.resetTo(NAV_NAME_MY_PAYMENT_HISTORY_MAIN);
    // } else {
    //   dispatch(removeAllCheckoutItem());
    //   NavigationService.resetTo(NAV_NAME_PAYMENT);
    // }
    
    if (staticVa) {
      dispatch(downloadMyPaymentOutstandingAsync())
        .catch(error => {
          dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
        });
      dispatch(setMyPaymentOutstandingSearchText(proformaNo));
      dispatch(removeAllCheckoutItem());
    } else {
      dispatch(downloadMyPaymentOutstandingAsync())
        .catch(error => {
          dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
        });
      dispatch(setMyPaymentOutstandingSearchText(proformaNo));
      dispatch(removeAllCheckoutItem());
      openNewTab(URL_PAYMENT.replace(/\{id\}/, paymentId));
    }
  } finally {
    dispatch(setChangingOrderPaymentStatus(false));
  }
};
