import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  withStyles,
  CssBaseline,
  Grid,
  Card,
  CardContent,
  Fab,
  Typography,
  Icon,
  Button,
  CircularProgress,
} from '@material-ui/core';
import {
  PageHeaderUser,
  LoadingIndicator,
  PdfViewer,
  TopUpDialog,
  MoneyLoanTncDialog,
} from '../../component';
import * as Constant from '../../constant';
import { toCurrency } from '../../helper';
import LocalizedString from '../../localization';
import DepositHistoryDetail from './deposit-history-detail.container';
import { MoneyLoanInfoShape } from '../../type';
import DepositHistoryTabs from './tabs';
import LoanHistoryDetail from './loan-history-detail.container';

const styles = theme => ({
  backIcon: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  balanceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 4,
    borderBottom: '1px solid #E5E5E5',
  },
  buttonTopUp: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
    marginBottom: 8,
  },
  moneyLoanButton: {
    backgroundColor: Constant.COLOR_ACCENT,
    marginBottom: 8,
  },
  circularProgress: {
    height: '24px !important',
    width: '24px !important',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${Constant.DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  depositCurrency: {
    marginRight: 12,
    color: '#0066CB',
  },
  depositBalance: {
    fontSize: '2rem',
    color: '#0066CB',
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    color: '#0266B4',
  },
  root: {
    width: '100%',
  },
});

class DepositHistoryPage extends Component {
  state = {
    selectedTab: 0,
  };

  componentDidMount() {
    this.props.onAppear();
  }

  setSelectedTab = (tab) => {
    this.setState({
      selectedTab: tab,
    });
  };

  renderMinimumBalanceInfo = () => {
    const {
      classes,
      userCompany,
    } = this.props;

    return (
      <Card>
        <CardContent>
          <div>
            <Typography variant="body2" color="primary">
              {LocalizedString.depositHistory.labelMinimumBalance}
            </Typography>
          </div>
          <div className={classes.balanceContainer}>
            <div style={{ display: 'flex' }}>
              <Typography className={classes.depositCurrency}>IDR</Typography>
              <Typography className={classes.depositBalance}>
                {toCurrency(userCompany.minimumBalance)}
              </Typography>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }

  renderAccountBalance = () => {
    const {
      classes, updating, depositBalance, onTopUpPressed,
    } = this.props;

    return (
      <Card>
        <CardContent>
          <div>
            <Typography variant="body2" color="primary">
              {LocalizedString.depositHistory.labelBalance}
            </Typography>
          </div>
          <div className={classes.balanceContainer}>
            <div style={{ display: 'flex' }}>
              <Typography className={classes.depositCurrency}>IDR</Typography>
              <Typography className={classes.depositBalance}>
                {toCurrency(depositBalance)}
              </Typography>
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={onTopUpPressed}
              className={classes.buttonTopUp}
              disabled={updating}
            >
              {updating
                ? (<CircularProgress className={classes.circularProgress} />)
                : (
                  <>
                    {LocalizedString.depositHistory.buttonCaptionTopUp}
                    &nbsp;
                    <Icon>account_balance_wallet</Icon>
                  </>
                )}
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }

  renderOutstandingBalance = () => {
    const {
      classes, moneyLoanInfo, downloadingMoneyLoanInfo, onMoneyLoanPressed,
    } = this.props;

    if (moneyLoanInfo.status !== Constant.MONEY_LOAN_STATUS_NOT_APPLICABLE) {
      return (
        <Card>
          <CardContent>
            <div>
              <Typography variant="body2" color="primary">
                {LocalizedString.depositHistory.labelOutstandingBalance}
              </Typography>
            </div>
            <div className={classes.balanceContainer}>
              <div style={{ display: 'flex' }}>
                <Typography className={classes.depositCurrency}>IDR</Typography>
                <Typography className={classes.depositBalance}>
                  {toCurrency(moneyLoanInfo.loan) || 0}
                </Typography>
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={onMoneyLoanPressed}
                className={classes.moneyLoanButton}
                disabled={downloadingMoneyLoanInfo
                || moneyLoanInfo.status === Constant.MONEY_LOAN_STATUS_PENDING}
              >
                {downloadingMoneyLoanInfo
                  ? <CircularProgress className={classes.circularProgress} />
                  : LocalizedString.depositHistory.buttonCaptionMoneyLoan
              }
              </Button>
            </div>
          </CardContent>
        </Card>
      );
    }
    return null;
  }

  render() {
    const {
      classes,
      notificationOpen,
      navigator,
      refreshing,
      dialogTopUpStatus,
      moneyLoanInfo,
      onTopUpClosePressed,
    } = this.props;

    return (
      <>
        <div className={classes.root}>
          <CssBaseline />

          <PageHeaderUser />

          <div
            className={
              classNames(classes.content, {
                [classes.contentDrawerOpen]: notificationOpen,
                [classes.contentDrawerClosed]: !notificationOpen,
              })
            }
            style={{ padding: '16px' }}
          >
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <div className={classes.pageNavigatorContainer}>
                      <Fab
                        variant="round"
                        classes={{ root: classes.backIcon }}
                        component={Link}
                        to={Constant.ROUTE_NAME_DASHBOARD_USER}
                      >
                        <Icon>chevron_left</Icon>
                      </Fab>
                      <div style={{ marginLeft: '32px' }}>
                        <Typography variant="h5" className={classes.pageTitle}>{LocalizedString.depositHistory.title}</Typography>
                        <Typography variant="caption">{LocalizedString.depositHistory.caption}</Typography>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={4}>
                {this.renderAccountBalance()}
                <div style={{ marginTop: '2rem' }} />
                {this.renderOutstandingBalance()}
                <div style={{ marginTop: '2rem' }} />
                {this.renderMinimumBalanceInfo()}
                <div style={{ marginTop: '2rem' }} />
              </Grid>
              <Grid item xs={12} md={8}>
                {moneyLoanInfo.status !== Constant.MONEY_LOAN_STATUS_NOT_APPLICABLE && (
                  <DepositHistoryTabs
                    classes={classes}
                    selectedTab={this.state.selectedTab}
                    onTabPressed={this.setSelectedTab}
                  />
                )}
                {this.state.selectedTab === 0 ? (
                  <DepositHistoryDetail history={navigator} />
                ) : (
                  <LoanHistoryDetail />
                )}
              </Grid>
            </Grid>
          </div>
        </div>

        <TopUpDialog
          open={dialogTopUpStatus}
          onClose={onTopUpClosePressed}
        />

        <PdfViewer />
        <MoneyLoanTncDialog />

        {refreshing && <LoadingIndicator />}
      </>
    );
  }
}
DepositHistoryPage.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  userCompany: PropTypes.shape(PropTypes.any).isRequired,
  navigator: PropTypes.shape(PropTypes.any).isRequired,
  notificationOpen: PropTypes.bool.isRequired,
  refreshing: PropTypes.bool.isRequired,
  updating: PropTypes.bool.isRequired,
  downloadingMoneyLoanInfo: PropTypes.bool.isRequired,
  dialogTopUpStatus: PropTypes.bool.isRequired,
  moneyLoanInfo: MoneyLoanInfoShape.isRequired,
  depositBalance: PropTypes.number.isRequired,

  onAppear: PropTypes.func.isRequired,
  onTopUpPressed: PropTypes.func.isRequired,
  onTopUpClosePressed: PropTypes.func.isRequired,
  onMoneyLoanPressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(DepositHistoryPage);
