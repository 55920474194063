import { connect } from 'react-redux';
import DepositHistoryPage from './deposit-history.presentation';
import {
  showErrorSnackbar,
  downloadDepositHistoryAsync,
  setTopUpDialogStatus,
  downloadMoneyLoanInfoAsync,
  downloadMoneyLoanHistoryAsync,
  setMoneyLoanTncDialog,
  setDepositHistoryFilter,
  setDepositHistoryValidationMessages,
} from '../../redux/action';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';
import { TABLE_FIELD_END_DATE, TABLE_FIELD_START_DATE } from '../../constant';

const formatDate = (val) => {
  const date = new Date(val);
  const day = date.toLocaleString('default', { day: '2-digit' });
  const month = date.toLocaleString('default', { month: '2-digit' });
  const year = date.toLocaleString('default', { year: 'numeric' });
  return `${year}-${month}-${day}`;
};

const mapStateToProps = (state, ownProps) => ({
  userCompany: state.currentUser.company,
  notificationOpen: state.uiWebUserMain.notificationDrawerOpen,
  navigator: ownProps.history,

  depositBalance: state.currentUser.company.depositAccount
    ? state.currentUser.company.depositAccount.balance
    : 0,
  refreshing: state.uiDepositHistory.downloading,
  updating: state.uiDepositBalance.updating,
  dialogTopUpStatus: state.uiDepositHistory.dialogTopUpStatus,

  moneyLoanInfo: state.moneyLoanInfo,
  downloadingMoneyLoanInfo: state.uiMoneyLoanInfo.downloading,
  filters: state.uiDepositHistory.filters,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: () => {
    const catchError = (error) => {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    };
    const date = new Date();
    const lastDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    const startDate = formatDate(new Date().setDate(new Date().getDate() - lastDayOfCurrentMonth));
    const endDate = formatDate(Date.now());

    dispatch(setDepositHistoryValidationMessages(TABLE_FIELD_START_DATE, ''));
    dispatch(setDepositHistoryValidationMessages(TABLE_FIELD_END_DATE, ''));

    dispatch(setDepositHistoryFilter(TABLE_FIELD_START_DATE, startDate));
    dispatch(setDepositHistoryFilter(TABLE_FIELD_END_DATE, endDate));
    dispatch(downloadDepositHistoryAsync())
      .catch(error => catchError(error));
    dispatch(downloadMoneyLoanInfoAsync())
      .catch(error => catchError(error));
    dispatch(downloadMoneyLoanHistoryAsync())
      .catch(error => catchError(error));
  },
  onTopUpPressed: () => {
    dispatch(setTopUpDialogStatus(true));
  },
  onTopUpClosePressed: () => {
    dispatch(setTopUpDialogStatus(false));
  },
  onMoneyLoanPressed: () => {
    dispatch(setMoneyLoanTncDialog(true));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DepositHistoryPage);
