import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  withStyles,
  CssBaseline,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Select,
  MenuItem,
  TableCell,
  TableSortLabel,
  Input,
  IconButton,
  Icon,
  Table,
  TableHead,
  TableRow,
  CardHeader,
  TableBody,
} from '@material-ui/core';
import { DatePicker } from 'material-ui-pickers';
import {
  DEFAULT_STYLES,
  DRAWER_NOTIFICATION_WIDTH,
  RXTABLEFIELD_ACTIVE_PAGE,
  TABLE_SORT_ASCENDING,
  TABLE_SORT_DESCENDING,
  RXTABLEFIELD_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS,
  TABLE_FIELD_MASTER_USER_INDEX,
  TABLE_FIELD_MASTER_USER_USERNAME,
  TABLE_FIELD_MASTER_USER_FULLNAME,
  TABLE_FIELD_MASTER_USER_COMPANY_NAME,
  TABLE_FIELD_MASTER_USER_REGISTERED_ON,
  TABLE_FIELD_MASTER_USER_EMAIL,
  MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING,
  MOMENT_DATE_FORMAT_STRING,
} from '../../constant';
import { MainMenu, PageHeaderAdmin, LoadingIndicator } from '../../component';
import LocalizedString from '../../localization';
import { isSortFieldActive, getSortIcon, toMoment } from '../../helper';
import UserAddDialog from './user-add-dialog.container';
import UserEditDialog from './user-edit-dialog.container';

const styles = theme => ({
  backIcon: {
    ...DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  btnNewBooking: {
    backgroundColor: '#5CB860',
    color: '#ffffff',
    width: '256px',
    '&:hover': {
      backgroundColor: '#558b2f',
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentMenuDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '256px',
      width: 'auto',
    },
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  detailHeader: {
    color: '#0066CB',
  },
  grow: {
    flexGrow: 1,
  },
  itemPerRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    color: '#0266B4',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

class MasterUserBase extends Component {
  componentDidMount() {
    this.props.onAppear();
  }

  handleChangeSort = (newSortField) => {
    const { sortField, sortDirection, onChangeSort } = this.props;

    if (newSortField === sortField) {
      onChangeSort(
        sortField,
        sortDirection === TABLE_SORT_ASCENDING
          ? TABLE_SORT_DESCENDING
          : TABLE_SORT_ASCENDING,
      );
    } else {
      onChangeSort(newSortField, TABLE_SORT_ASCENDING);
    }
  };

  renderTableItemPerRow = () => {
    const { classes, pageSize, onChangePaging } = this.props;

    return (
      <div className={classes.itemPerRowContainer}>
        <Select
          value={pageSize}
          onChange={event => onChangePaging(
            RXTABLEFIELD_PAGE_SIZE,
            event.target.value,
          )}
        >
          {DEFAULT_PAGE_SIZE_OPTIONS.map(item => (
            <MenuItem value={item} key={item}>
              {item}
              &nbsp;
              {LocalizedString.common.labelRows}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }

  renderTableCellHeader = (label, fieldId) => {
    const { sortField, sortDirection } = this.props;
    return (
      <TableCell>
        <TableSortLabel
          active={
            isSortFieldActive(sortField, fieldId)
          }
          IconComponent={getSortIcon(
            isSortFieldActive(sortField, fieldId),
            sortDirection,
          )}
          onClick={
            () => this.handleChangeSort(fieldId)
          }
        >
          {label}
        </TableSortLabel>
      </TableCell>
    );
  }

  renderTableCellStringFilter = (field) => {
    const { filters, onChangeFilter } = this.props;
    return (
      <TableCell>
        <Input
          type="text"
          fullWidth
          value={filters[field]}
          onChange={
            ({ nativeEvent }) => onChangeFilter(
              field,
              nativeEvent.target.value,
            )
          }
          placeholder={LocalizedString.common.placeholderSearch}
        />
      </TableCell>
    );
  }

  renderTableCellDateFilter = (field) => {
    const { filters, onChangeFilter } = this.props;
    return (
      <TableCell>
        <DatePicker
          keyboard
          value={filters[field] || null}
          format={MOMENT_DATE_FORMAT_STRING}
          placeholder={MOMENT_DATE_FORMAT_STRING}
          mask={(dateValue) => {
            if (dateValue) {
              return [/\d/, /\d/, ' ', /\w/, /\w/, /\w/, ' ', /\d/, /\d/, /\d/, /\d/];
            }
            return [];
          }}
          readOnly
          onChange={value => onChangeFilter(field, value)}
          clearable
        />
      </TableCell>
    );
  };

  renderTableCellBody = text => (
    <TableCell>
      <Typography>
        {text || '-'}
      </Typography>
    </TableCell>
  );

  renderTableCellAction = (user) => {
    const { classes, onEditPressed } = this.props;
    return (
      <TableCell>
        <IconButton
          color="primary"
          className={classes.iconButtonPadding4}
          onClick={() => onEditPressed(user.id)}
        >
          <Icon>edit</Icon>
        </IconButton>
      </TableCell>
    );
  }

  renderTablePagination = () => {
    const {
      classes, activePage, totalPage, onChangePaging,
    } = this.props;

    return (
      <div className={classes.tablePagination}>
        <Button
          variant="contained"
          style={{ flexGrow: 1 }}
          disabled={activePage === 1}
          onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage - 1)}
        >
          {LocalizedString.common.labelPagingPrevButton}
        </Button>

        <div className={classes.tablePaginationPageInput}>
          <span style={{ fontWeight: 500 }}>{LocalizedString.common.labelPagingPage}</span>
          <Input
            type="number"
            value={activePage}
            style={{ width: '50%' }}
            inputProps={{
              min: 1,
              max: totalPage,
              step: 1,
              style: { textAlign: 'center' },
            }}
            onChange={({ nativeEvent }) => onChangePaging(
              RXTABLEFIELD_ACTIVE_PAGE,
              parseInt(nativeEvent.target.value, 10),
            )}
          />
          <span style={{ fontWeight: 500 }}>
            {`${LocalizedString.common.labelPagingPageOf} ${totalPage}`}
          </span>
        </div>

        <Button
          variant="contained"
          style={{ flexGrow: 1 }}
          disabled={activePage === totalPage}
          onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage + 1)}
        >
          {LocalizedString.common.labelPagingNextButton}
        </Button>
      </div>
    );
  }

  render() {
    const {
      classes,
      menuOpen,
      downloading,

      users,

      dialogAddStatus,
      dialogEditStatus,

      onAddNewPressed,
      onAddDialogClosePressed,
      onEditDialogClosePressed,
    } = this.props;

    return (
      <>
        <div className={classes.root}>
          <CssBaseline />

          <PageHeaderAdmin />

          <MainMenu />

          <div
            className={
              classNames(classes.content, {
                [classes.contentMenuDrawerOpen]: menuOpen,
              })
            }
            style={{ padding: '16px' }}
          >
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <div className={classes.pageNavigatorContainer}>
                      <div>
                        <Typography variant="h5" className={classes.pageTitle}>{LocalizedString.masterUser.title}</Typography>
                        <Typography variant="caption">{LocalizedString.masterUser.caption}</Typography>
                      </div>
                      <div className={classes.grow} />
                      <div>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          classes={{ containedSecondary: classes.btnNewBooking }}
                          onClick={onAddNewPressed}
                        >
                          {LocalizedString.masterUser.btnAddNew}
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    title={(
                      <>
                        <Typography variant="h6" className={classes.detailHeader}>
                          {LocalizedString.masterUser.detailTitle}
                        </Typography>
                        {this.renderTableItemPerRow()}
                      </>
                    )}
                  />
                  <CardContent>
                    <div style={{ width: '100%', overflow: 'auto' }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {this.renderTableCellHeader(LocalizedString.masterUser.labelIndex,
                              TABLE_FIELD_MASTER_USER_INDEX)}
                            {this.renderTableCellHeader(LocalizedString.masterUser.labelUsername,
                              TABLE_FIELD_MASTER_USER_USERNAME)}
                            {this.renderTableCellHeader(LocalizedString.masterUser.labelEmail,
                              TABLE_FIELD_MASTER_USER_EMAIL)}
                            {this.renderTableCellHeader(LocalizedString.masterUser.labelFullName,
                              TABLE_FIELD_MASTER_USER_FULLNAME)}
                            {this.renderTableCellHeader(LocalizedString.masterUser.labelCompanyName,
                              TABLE_FIELD_MASTER_USER_COMPANY_NAME)}
                            {this.renderTableCellHeader(
                              LocalizedString.masterUser.labelRegisteredOn,
                              TABLE_FIELD_MASTER_USER_REGISTERED_ON,
                            )}
                            <TableCell>{LocalizedString.common.labelActions}</TableCell>
                          </TableRow>
                          <TableRow>
                            {this.renderTableCellStringFilter(TABLE_FIELD_MASTER_USER_INDEX)}
                            {this.renderTableCellStringFilter(TABLE_FIELD_MASTER_USER_USERNAME)}
                            {this.renderTableCellStringFilter(TABLE_FIELD_MASTER_USER_EMAIL)}
                            {this.renderTableCellStringFilter(TABLE_FIELD_MASTER_USER_FULLNAME)}
                            {this.renderTableCellStringFilter(TABLE_FIELD_MASTER_USER_COMPANY_NAME)}
                            {this.renderTableCellDateFilter(
                              TABLE_FIELD_MASTER_USER_REGISTERED_ON,
                            )}
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {users && users.map(user => (
                            <TableRow>
                              {this.renderTableCellBody(user.index)}
                              {this.renderTableCellBody(user.username)}
                              {this.renderTableCellBody(user.email)}
                              {this.renderTableCellBody(user.fullName)}
                              {this.renderTableCellBody((user.company && user.company.name) || 'CDP')}
                              {this.renderTableCellBody(toMoment(user.registeredOn)
                                .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING))}
                              {this.renderTableCellAction(user)}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>

                    {this.renderTablePagination()}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>

        <UserAddDialog
          open={dialogAddStatus}
          onClose={onAddDialogClosePressed}
        />
        <UserEditDialog
          open={dialogEditStatus}
          onClose={onEditDialogClosePressed}
        />

        {downloading && <LoadingIndicator />}
      </>
    );
  }
}
MasterUserBase.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  menuOpen: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,

  users: PropTypes.arrayOf(PropTypes.any).isRequired,

  dialogAddStatus: PropTypes.bool.isRequired,
  dialogEditStatus: PropTypes.bool.isRequired,

  filters: PropTypes.shape(PropTypes.any).isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  activePage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,

  onAddNewPressed: PropTypes.func.isRequired,
  onAddDialogClosePressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onChangePaging: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onEditDialogClosePressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(MasterUserBase);
