import { addPpjkListByAssign, searchingAssignTruckVendorList } from '../simple-action';
import * as Helper from '../../../helper';

export default text => async (dispatch, getState) => {
  const { token } = getState().authentication;

  try {
    dispatch(searchingAssignTruckVendorList(true));
    const ppjkList = await Helper.downloadPpjkListByAssign(text, token);
    dispatch(addPpjkListByAssign(ppjkList));
  } finally {
    dispatch(searchingAssignTruckVendorList(false));
  }
};
