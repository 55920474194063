import { downloadingExportOrderHistoryDetail, addExportOrderHistoryDetail } from '../simple-action';
import * as Helper from '../../../helper';

export default coRef => async (dispatch, getState) => {
  const { token } = getState().authentication;
  try {
    dispatch(downloadingExportOrderHistoryDetail(true));
    const exportOrderHistoryDetail = await Helper.downloadExportOrderHistoryDetail(coRef, token);
    dispatch(addExportOrderHistoryDetail(exportOrderHistoryDetail));
  } finally {
    dispatch(downloadingExportOrderHistoryDetail(false));
  }
};
