import * as Action from '../action';

export default (state = [], action) => {
  switch (action.type) {
    case Action.SET_FORM_RESPONSE_FILTER_STATUSES:
      return action.data;
    case Action.CLEAR_FORM_RESPONSE_FILTER_STATUSES:
      return {};
    default: return state;
  }
};
