import * as Helper from '../../../helper';
import * as Validation from '../../../validation';
import { uploadingMasterUser } from '../simple-action';

export default values => async (dispatch, getState) => {
  try {
    dispatch(uploadingMasterUser(true));

    Validation.validateNewUser(values);

    const { token } = getState().authentication;
    const { selectedUserId } = getState().uiMasterUser;

    const {
      fullName, companyId, role, corporateAdmin,
    } = values;

    const requestBody = {
      fullName,
      companyId,
      role,
      corporateAdmin,
      enabled: true,
    };

    await Helper.uploadUpdateUser(selectedUserId, requestBody, token);
  } finally {
    dispatch(uploadingMasterUser(false));
  }
};
