import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Select,
  Button,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Input,
  TableBody,
  Typography,
  withStyles,
} from '@material-ui/core';
import { DatePicker } from 'material-ui-pickers';
import { isSortFieldActive, getSortIcon, toMoment } from '../../helper';
import {
  TABLE_SORT_ASCENDING,
  TABLE_SORT_DESCENDING,
  DEFAULT_PAGE_SIZE_OPTIONS,
  RXTABLEFIELD_PAGE_SIZE,
  TABLE_FIELD_SHIFT_ID,
  TABLE_FIELD_SHIFT_DATE,
  TABLE_FIELD_SETTING_TEMPERATURE,
  TABLE_FIELD_AIR_SUPPLY,
  RXTABLEFIELD_ACTIVE_PAGE,
  MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING,
} from '../../constant';
import LocalizedString from '../../localization';

const styles = theme => ({
  alignRight: {
    textAlign: 'right',
  },
  dialogContent: {
    width: '100%',
    overflow: 'auto',
    marginTop: '24px',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemPerRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '24px',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

const ReeferDataTable = ({
  activePage,
  classes,
  filters,
  pageSize,
  reeferData,
  sortField,
  sortDirection,
  totalPage,
  onChangeFilter,
  onChangePaging,
  onChangeSort,
}) => {
  const handleChangeSort = (newSortField) => {
    if (newSortField === sortField) {
      onChangeSort(
        sortField,
        sortDirection === TABLE_SORT_ASCENDING
          ? TABLE_SORT_DESCENDING
          : TABLE_SORT_ASCENDING,
      );
    } else {
      onChangeSort(newSortField, TABLE_SORT_ASCENDING);
    }
  };

  return (
    <>
      <div className={classes.itemPerRowContainer}>
        <Select
          value={pageSize}
          onChange={event => onChangePaging(RXTABLEFIELD_PAGE_SIZE, event.target.value)
          }
        >
          {DEFAULT_PAGE_SIZE_OPTIONS.map(item => (
            <MenuItem value={item} key={item}>
              {item}
              &nbsp;
              {LocalizedString.common.labelRows}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div className={classes.dialogContent}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={isSortFieldActive(sortField, TABLE_FIELD_SHIFT_ID)}
                  IconComponent={getSortIcon(
                    isSortFieldActive(sortField, TABLE_FIELD_SHIFT_ID),
                    sortDirection,
                  )}
                  onClick={() => handleChangeSort(TABLE_FIELD_SHIFT_ID)}
                >
                  {LocalizedString.reeferDataDetail.lblShiftId}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={isSortFieldActive(sortField, TABLE_FIELD_SHIFT_DATE)}
                  IconComponent={getSortIcon(
                    isSortFieldActive(sortField, TABLE_FIELD_SHIFT_DATE),
                    sortDirection,
                  )}
                  onClick={() => handleChangeSort(TABLE_FIELD_SHIFT_DATE)}
                >
                  {LocalizedString.reeferDataDetail.lblShiftDate}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={isSortFieldActive(
                    sortField,
                    TABLE_FIELD_SETTING_TEMPERATURE,
                  )}
                  IconComponent={getSortIcon(
                    isSortFieldActive(
                      sortField,
                      TABLE_FIELD_SETTING_TEMPERATURE,
                    ),
                    sortDirection,
                  )}
                  onClick={() => handleChangeSort(TABLE_FIELD_SETTING_TEMPERATURE)}
                >
                  {LocalizedString.reeferDataDetail.lblSettingPointTemp}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={isSortFieldActive(sortField, TABLE_FIELD_AIR_SUPPLY)}
                  IconComponent={getSortIcon(
                    isSortFieldActive(sortField, TABLE_FIELD_AIR_SUPPLY),
                    sortDirection,
                  )}
                  onClick={() => handleChangeSort(TABLE_FIELD_AIR_SUPPLY)}
                >
                  {LocalizedString.reeferDataDetail.lblSupplyAirTemp}
                </TableSortLabel>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Input
                  type="number"
                  value={filters[TABLE_FIELD_SHIFT_ID]}
                  onChange={({ nativeEvent }) => onChangeFilter(
                    TABLE_FIELD_SHIFT_ID,
                    nativeEvent.target.value,
                  )}
                  fullWidth
                  placeholder={LocalizedString.common.placeholderSearch}
                />
              </TableCell>
              <TableCell>
                {/* <Input
                  type="date"
                  value={filters[TABLE_FIELD_SHIFT_DATE]}
                  onChange={({ nativeEvent }) => onChangeFilter(
                    TABLE_FIELD_SHIFT_DATE,
                    nativeEvent.target.value,
                  )}
                  fullWidth
                /> */}
                <DatePicker
                  value={filters[TABLE_FIELD_SHIFT_DATE] || null}
                  clearable
                  emptyLabel="DD MMM YYYY"
                  format="DD MMM YYYY"
                  onChange={value => onChangeFilter(
                    TABLE_FIELD_SHIFT_DATE,
                    value,
                  )}
                />
              </TableCell>
              <TableCell>
                <Input
                  type="text"
                  value={filters[TABLE_FIELD_SETTING_TEMPERATURE]}
                  onChange={({ nativeEvent }) => onChangeFilter(
                    TABLE_FIELD_SETTING_TEMPERATURE,
                    nativeEvent.target.value,
                  )}
                  fullWidth
                  placeholder={LocalizedString.common.placeholderSearch}
                />
              </TableCell>
              <TableCell>
                <Input
                  type="text"
                  value={filters[TABLE_FIELD_AIR_SUPPLY]}
                  onChange={({ nativeEvent }) => onChangeFilter(
                    TABLE_FIELD_AIR_SUPPLY,
                    nativeEvent.target.value,
                  )}
                  fullWidth
                  placeholder={LocalizedString.common.placeholderSearch}
                />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {reeferData
              && reeferData.map(reefer => (
                <TableRow key={reefer.shiftId} className={classes.tableRow}>
                  <TableCell>
                    <Typography>{reefer.shiftId}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {toMoment(reefer.shiftDate).format(
                        MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING,
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.alignRight}>
                    <Typography>
                      {reefer.settingPointTemp}
                      {LocalizedString.common.tempSymbol}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.alignRight}>
                    <Typography>
                      {reefer.supplyAirTemp}
                      {LocalizedString.common.tempSymbol}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>

      <div className={classes.tablePagination}>
        <Button
          variant="contained"
          style={{ flexGrow: 1 }}
          disabled={!activePage || activePage === 1}
          onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage - 1)
          }
        >
          {LocalizedString.common.labelPagingPrevButton}
        </Button>

        <div className={classes.tablePaginationPageInput}>
          <span style={{ fontWeight: 500 }}>
            {LocalizedString.common.labelPagingPage}
          </span>
          <Input
            type="number"
            value={activePage}
            style={{ width: '50%' }}
            inputProps={{
              min: 1,
              max: totalPage,
              step: 1,
              style: { textAlign: 'center' },
            }}
            onChange={({ nativeEvent }) => onChangePaging(
              RXTABLEFIELD_ACTIVE_PAGE,
              parseInt(nativeEvent.target.value, 10),
            )
            }
          />
          <span style={{ fontWeight: 500 }}>
            {`${LocalizedString.common.labelPagingPageOf} ${totalPage}`}
          </span>
        </div>

        <Button
          variant="contained"
          style={{ flexGrow: 1 }}
          disabled={activePage && activePage === totalPage}
          onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage + 1)
          }
        >
          {LocalizedString.common.labelPagingNextButton}
        </Button>
      </div>
    </>
  );
};
ReeferDataTable.propTypes = {
  activePage: PropTypes.number.isRequired,
  classes: PropTypes.shape(PropTypes.any).isRequired,
  filters: PropTypes.shape(PropTypes.any).isRequired,
  pageSize: PropTypes.number.isRequired,
  reeferData: PropTypes.arrayOf(PropTypes.any).isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  totalPage: PropTypes.number.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onChangePaging: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
};
export default withStyles(styles)(ReeferDataTable);
