import { connect } from 'react-redux';
import _ from 'lodash';
import ForwarderAddDialog from './forwarder-add-dialog.presentation';

const mapStateToProps = state => ({
  initialValues: {},
  loading: false,
});

const mapDispatchToProps = dispatch => ({
  onAppear: () => { },
  onSubmitPressed: (values) => { },
});

export default connect(mapStateToProps, mapDispatchToProps)(ForwarderAddDialog);
