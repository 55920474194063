import { connect } from 'react-redux';
import * as Action from '../../redux/action';
import ProfileInfo from './profile.presentation';
import * as Constant from '../../constant';

const getProfile = (state) => {
  const user = state.users[state.currentUser.id];
  const image = state.images[user.profilePicture];

  return {
    ...state.currentUser,
    ...user,
    profilePicture: image ? image.content : undefined,
  };
};

const mapStateToProps = state => ({
  profile: getProfile(state),
  companyPaymentMethod: state.currentUser.company.paymentMethod || '',
  notificationOpen: state.uiWebUserMain.notificationDrawerOpen,
  uploadingProfilePicture: state.uiProfile.uploadingProfilePicture,
  removingPicture: state.uiProfile.removingProfilePicture,
  errorMsg: state.uiProfile.error,
});

const mapDispatchToProps = dispatch => ({
  onRemoveProfilePicture: async (userId) => {
    try {
      dispatch(Action.clearUIError(Constant.RXSTATE_PROFILE_PAGE));
      await dispatch(Action.removeProfilePictureAsync(userId));
    } catch (error) {
      dispatch(Action.setUIError(Constant.RXSTATE_PROFILE_PAGE, error.message));
    }
  },
  onSaveProfilePicture: async (image) => {
    try {
      dispatch(Action.clearUIError(Constant.RXSTATE_PROFILE_PAGE));
      await dispatch(Action.uploadProfilePictureAsync(image));
    } catch (error) {
      dispatch(Action.setUIError(Constant.RXSTATE_PROFILE_PAGE, error.message));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo);
