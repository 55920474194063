import { connect } from 'react-redux';
import { isEmpty, isObject } from 'lodash';
import { change } from 'redux-form';
import ResponsePage from './response.presentation';
import {
  clearFormResponses,
  downloadFormResponseDetailFileAsync,
  setFormResponsPageSize,
  setFormResponseActivePage,
  setFormResponseFilter,
  setMasterUserSort,
  setTempSelectedStatusResponseFilter,
  setTempSelectedUserResponseFilter,
  showErrorSnackbar,
  showFormResponseDetail,
  showResponseFilterDialog,
  showSnackbar,
} from '../../redux/action';
import LocalizedString from '../../localization';
import {
  debounceSearch, sortDesc, toMoment, unauthorizedErrorHandler,
} from '../../helper';
import downloadFormResponsesAsync from '../../redux/action/async/downloadFormResponsesAsync';
import {
  MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING, FORM_RESPONSE_STATUSES,
  RXFIELD_FORM_RESPONSE_DETAIL_FORM_ID,
  RXFIELD_FORM_RESPONSE_DETAIL_FORM_NAME,
  RXFIELD_FORM_RESPONSE_DETAIL_USER_ID,
  RXFIELD_FORM_RESPONSE_DETAIL_USER_NAME,
  RXFIELD_FORM_RESPONSE_DETAIL_SUBMIT_DATE,
  RXFIELD_FORM_RESPONSE_DETAIL_STATUS,
  RXFIELD_FORM_RESPONSE_DETAIL_STATUS_NOTES,
  RXFIELD_FORM_RESPONSE_DETAIL_USER,
  RXFORM_RESPONSE_FILTER_DIALOG,
  RXFIELD_FORM_RESPONSE_DETAIL_SUBMIT_ID,
  TABLE_FIELD_FORM_RESPONSE_FORM_TITLE,
  TABLE_FIELD_FORM_RESPONSE_SUBMIT_ID,
  RXFIELD_FORM_RESPONSE_DETAIL_PAYMENT_ON_BEHALF_ID,
  RXFIELD_FORM_RESPONSE_DETAIL_PAYMENT_ON_BEHALF_NAME,
} from '../../constant';
import downloadFormResponseDetailAsync from '../../redux/action/async/downloadFormResponseDetailAsync';

const getFormResponses = (state) => {
  const { formResponses, uiFormResponse } = state;
  const { content } = formResponses;
  const { filters } = uiFormResponse;
  if (content) {
    const data = content
      .sort((a, b) => sortDesc(a.submitDate, b.submitDate))
      .map((x) => {
        const formattedDate = toMoment(x.submitDate)
          .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING);
        const formStatus = FORM_RESPONSE_STATUSES.find(y => (x.status === y.value));
        return {
          ...x,
          submitDate: formattedDate,
          status: formStatus ? formStatus.label : '-',
        };
      });

    const titleFilter = filters[TABLE_FIELD_FORM_RESPONSE_FORM_TITLE];
    const submitIdFilter = filters[TABLE_FIELD_FORM_RESPONSE_SUBMIT_ID];

    return data.filter((x) => {
      const lowerCaseTitle = x.form.title.toLowerCase();
      const lowerCaseSubmitId = x.submitId.toLowerCase();

      if (titleFilter && submitIdFilter) {
        return lowerCaseTitle.includes(titleFilter.toLowerCase())
        && lowerCaseSubmitId.includes(submitIdFilter.toLowerCase());
      }
      if (titleFilter) {
        return lowerCaseTitle.includes(titleFilter.toLowerCase());
      }
      if (submitIdFilter) {
        return lowerCaseSubmitId.includes(submitIdFilter.toLowerCase());
      }
      return true;
    });
  }
  return [];
};

const getInitialValues = (state) => {
  const { formResponseDetail } = state;

  const status = formResponseDetail.status ? FORM_RESPONSE_STATUSES
    .find(y => formResponseDetail.status === y.value) : '-';

  return isEmpty(formResponseDetail) ? ({
    [RXFIELD_FORM_RESPONSE_DETAIL_FORM_ID]: '',
    [RXFIELD_FORM_RESPONSE_DETAIL_FORM_NAME]: '',
    [RXFIELD_FORM_RESPONSE_DETAIL_USER_ID]: '',
    [RXFIELD_FORM_RESPONSE_DETAIL_USER_NAME]: '',
    [RXFIELD_FORM_RESPONSE_DETAIL_SUBMIT_ID]: '',
    [RXFIELD_FORM_RESPONSE_DETAIL_SUBMIT_DATE]: '',
    [RXFIELD_FORM_RESPONSE_DETAIL_STATUS]: '',
    [RXFIELD_FORM_RESPONSE_DETAIL_STATUS_NOTES]: '',
    [RXFIELD_FORM_RESPONSE_DETAIL_PAYMENT_ON_BEHALF_ID]: '',
    [RXFIELD_FORM_RESPONSE_DETAIL_PAYMENT_ON_BEHALF_NAME]: '',
  }) : ({
    [RXFIELD_FORM_RESPONSE_DETAIL_FORM_ID]: formResponseDetail.form.id,
    [RXFIELD_FORM_RESPONSE_DETAIL_FORM_NAME]: formResponseDetail.form.title,
    [RXFIELD_FORM_RESPONSE_DETAIL_USER_ID]: formResponseDetail.user.id,
    [RXFIELD_FORM_RESPONSE_DETAIL_USER_NAME]: formResponseDetail.user.fullName,
    [RXFIELD_FORM_RESPONSE_DETAIL_SUBMIT_ID]: formResponseDetail.submitId,
    [RXFIELD_FORM_RESPONSE_DETAIL_SUBMIT_DATE]: toMoment(formResponseDetail.submitDate)
      .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING),
    [RXFIELD_FORM_RESPONSE_DETAIL_STATUS]: isObject(status) ? status.label : status,
    [RXFIELD_FORM_RESPONSE_DETAIL_STATUS_NOTES]: formResponseDetail.statusNotes,
    [RXFIELD_FORM_RESPONSE_DETAIL_PAYMENT_ON_BEHALF_ID]: formResponseDetail.paymentOnBehalf.id,
    [RXFIELD_FORM_RESPONSE_DETAIL_PAYMENT_ON_BEHALF_NAME]: formResponseDetail.paymentOnBehalf.name,
  });
};

const mapStateToProps = state => ({
  downloading: state.uiFormResponse.downloading,
  downloadingDetail: state.uiFormResponse.downloadingDetail,
  showDetail: state.uiFormResponse.showDetail,
  menuOpen: state.uiMenu.menuOpen,
  formResponses: getFormResponses(state),
  initialValues: getInitialValues(state),
  selectedStatus: state.uiFormResponse.selectedStatus,
  selectedUser: state.uiFormResponse.selectedUser,
  pageSize: state.uiFormResponse.pageSize,
  filters: state.uiFormResponse.filters,
  detail: state.formResponseDetail,

  activePage: state.uiFormResponse.activePage,
  totalPage: state.formResponses.totalPages,
  sortField: state.uiMasterUser.sortField,
  sortDirection: state.uiMasterUser.sortDirection,
  showResponseFilterDialog: state.uiFormResponse.showResponseFilterDialog,
  downloadingFile: state.uiFormResponse.downloadingFile,
});

const searchResponseDebounce = debounceSearch(
  (dispatch) => {
    dispatch(downloadFormResponsesAsync())
      .catch(error => showErrorSnackbar(error));
  },
);

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    dispatch(clearFormResponses());
    dispatch(change(
      RXFORM_RESPONSE_FILTER_DIALOG, RXFIELD_FORM_RESPONSE_DETAIL_STATUS, '',
    ));
    dispatch(change(
      RXFORM_RESPONSE_FILTER_DIALOG, RXFIELD_FORM_RESPONSE_DETAIL_USER, '',
    ));
    try {
      await dispatch(downloadFormResponsesAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onChangePaging: async (value) => {
    dispatch(setFormResponseActivePage(value));
    try {
      await dispatch(downloadFormResponsesAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setMasterUserSort(sortField, sortDirection));
  },
  onDetailPressed: async (item) => {
    try {
      await dispatch(downloadFormResponseDetailAsync(item));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onBackPressed: () => {
    dispatch(showFormResponseDetail(false));
  },
  onResponseFilterDialogClosePressed: (selectedStatus, selectedUser) => {
    dispatch(showResponseFilterDialog(false));
    dispatch(change(
      RXFORM_RESPONSE_FILTER_DIALOG, RXFIELD_FORM_RESPONSE_DETAIL_STATUS, selectedStatus,
    ));
    dispatch(change(
      RXFORM_RESPONSE_FILTER_DIALOG, RXFIELD_FORM_RESPONSE_DETAIL_USER, selectedUser,
    ));
    dispatch(setTempSelectedStatusResponseFilter(''));
    dispatch(setTempSelectedUserResponseFilter(''));
  },
  onFilterPressed: () => {
    dispatch(showResponseFilterDialog(true));
  },
  onChangeRow: async (pageField, value) => {
    dispatch(setFormResponsPageSize(value));
    try {
      await dispatch(downloadFormResponsesAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onChangeFilter: async (filterName, value, pageSize) => {
    dispatch(setFormResponseFilter(filterName, value));
    dispatch(setFormResponseActivePage(0));
    dispatch(setFormResponsPageSize(value.length >= 3 ? 200 : pageSize));
    if (value.length >= 3 || value.length === 0) {
      searchResponseDebounce(dispatch);
    }
  },
  onDownloadFilePressed: (file) => {
    dispatch(downloadFormResponseDetailFileAsync(file))
      .then((data) => {
        const fileName = file.split('/')[4] || 'file';
        const linkSource = data;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        dispatch(showSnackbar(
          LocalizedString.common.labelSuccess,
          LocalizedString.submitRequest.downloadFileSuccess,
        ));
      })
      .catch((error) => {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsePage);
