import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import AssignPage from './assign-base.presentation';
import {
  setAssignSearchText,
  setAssignFilter,
  setAssignPaging,
  setAssignSort,
  downloadAssignAsync,
  setTappedAssignOrderTypeNo,
  downloadAssignContainerAsync,
  setAssignContainerDialogStatus,
  addAssignClearance,
  downloadPpjkListByAssignAsync,
  setAssignClearanceDialogStatus,
  showErrorSnackbar,
  setAssignContainerActiveForm,
  addMultiSelectAssignBl,
  removeMultiSelectAssignBl,
  addAssignMultipleClearance,
  setAssignMutipleClearanceDialogStatus,
} from '../../redux/action';
import * as Constant from '../../constant';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const getSelectedAssignBl = state => _.values(state.assign)
  .find(item => item.blNo === state.uiAssign.tappedBlNo
    && item.exim === state.uiAssign.tappedService);

const getFilteredOrderType = (state) => {
  const assignOrderType = _.values(state.assignOrderType)
    .filter(item => item.blNo === state.uiAssign.tappedBlNo);
  const {
    filters, sortField, sortDirection,
  } = state.uiAssign;

  return assignOrderType.sort((left, right) => {
    if (typeof left[sortField] === 'string') {
      if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
        return left[sortField].localeCompare(right[sortField]);
      }
      return right[sortField].localeCompare(left[sortField]);
    }

    if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
      return left[sortField] - right[sortField];
    }
    return right[sortField] - left[sortField];
  })
    .filter(item => item.orderType.toUpperCase().indexOf(
      filters[Constant.TABLE_FIELD_ORDER_TYPE].toUpperCase(),
    ) >= 0)
    .filter(item => item.status.toUpperCase().indexOf(
      filters[Constant.TABLE_FIELD_STATUS].toUpperCase(),
    ) >= 0)
    .filter(item => (item.billTo || '-').toUpperCase().indexOf(
      filters[Constant.TABLE_FIELD_CONSIGNEE].toUpperCase(),
    ) >= 0)
    .filter(item => (item.forwarderId || '-').toUpperCase()
      .indexOf(filters[Constant.TABLE_FIELD_TRUCKER].toUpperCase()) >= 0);
};

const getPageFilteredOrderType = (state) => {
  const { activePage, pageSize } = state.uiAssign;
  const filteredOrderType = getFilteredOrderType(state);

  const refinedActivePage = activePage || 1;
  const start = Math.min((refinedActivePage - 1) * pageSize, filteredOrderType.length);
  const end = Math.min(start + pageSize, filteredOrderType.length);
  return filteredOrderType.slice(start, end);
};

const getTotalPage = (state) => {
  const totalData = getFilteredOrderType(state).length;
  const { pageSize } = state.uiContainerTracking;

  return Math.max(Math.ceil(totalData / pageSize), 1);
};

const mapStateToProps = state => ({
  notificationOpen: state.uiWebUserMain.notificationDrawerOpen,
  selectedBl: getSelectedAssignBl(state),
  selectedBlNo: state.uiAssign.tappedBlNo,
  searchBarText: state.uiAssign.searchBarText,
  userRole: state.currentUser.company.role,
  multiSelectBl: state.uiAssign.multiTappedBl,

  assignOrderType: getPageFilteredOrderType(state),

  pageSize: state.uiAssign.pageSize,
  totalPage: getTotalPage(state, state.uiAssign.tappedBlNo),
  activePage: state.uiAssign.activePage,
  filters: state.uiAssign.filters,
  sortField: state.uiAssign.sortField,
  sortDirection: state.uiAssign.sortDirection,

  downloading: state.uiAssign.downloadingAssignBl
    || state.uiAssignOrderType.downloading
    || state.uiAssignContainer.downloading
    || state.uiAssignTruck.submitting
    || state.uiAssignClearance.submitting,
  dialogAssignContainerStatus: state.uiAssign.dialogAssignContainerStatus,
  dialogAssignClearanceStatus: state.uiAssign.dialogAssignClearanceStatus,
  dialogBulkAssignClearanceStatus: state.uiAssign.dialogBulkAssignClearanceStatus,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    try {
      await dispatch(downloadAssignAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onChangeSearchBarText: (text) => {
    dispatch(setAssignSearchText(text));
  },
  onCheckboxPressed: (multiSelectBl, bl) => {
    const match = multiSelectBl.find(item => item.blNo === bl.blNo && item.exim === bl.exim);
    if (!match) {
      dispatch(addMultiSelectAssignBl(bl));
    } else {
      dispatch(removeMultiSelectAssignBl(bl));
    }
  },
  onAssignMultipleBlPressed: async (multiBl) => {
    try {
      dispatch(addAssignMultipleClearance(multiBl));
      await dispatch(downloadPpjkListByAssignAsync());
      dispatch(setAssignMutipleClearanceDialogStatus(true));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onCloseAssignMultipleBlPressed: () => {
    dispatch(setAssignMutipleClearanceDialogStatus(false));
  },
  onAssignBlPressed: async (item) => {
    try {
      dispatch(addAssignClearance(item));
      await dispatch(downloadPpjkListByAssignAsync());
      dispatch(setAssignClearanceDialogStatus(true));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onClearanceClosePressed: () => {
    dispatch(setAssignClearanceDialogStatus(false));
  },
  onDetailMorePressed: async (tappedBlNo, tappedSoNo) => {
    try {
      dispatch(setTappedAssignOrderTypeNo(tappedBlNo, tappedSoNo));
      await dispatch(downloadAssignContainerAsync(tappedBlNo, tappedSoNo));
      dispatch(setAssignContainerDialogStatus(true));
      dispatch(setAssignContainerActiveForm(0));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onDetailClosePressed: () => {
    dispatch(setAssignContainerDialogStatus(false));
  },
  onChangeFilter: (filterName, value) => {
    dispatch(setAssignFilter(filterName, value));
  },
  onChangePaging: (pageField, value) => {
    dispatch(setAssignPaging(pageField, value));
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setAssignSort(sortField, sortDirection));
  },
  // onBulkAssignPressed: () => {
  //   dispatch(setDialogBulkAssignClearanceStatus(true));
  // },
  // onCloseBulkAssignPressed: () => {
  //   dispatch(setDialogBulkAssignClearanceStatus(false));
  // },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssignPage));
