import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { change, formValueSelector } from 'redux-form';
import FeederTab from './feeder.presentation';
import {
  MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING,
  RXSTATE_BOOK_EXPORT_PAGE,
  ROLE_CUSTOMER,
  RXFORM_BOOK_EXPORT_FEEDER,
  RXFIELD_PAYMENT_ON_BEHALF_OF,
  ROLE_PPJK,
  RXFIELD_DO_DATE,
  RXFIELD_PEB_NO,
  RXFIELD_PEB_DATE,
  RXFIELD_VESSEL,
  RXFIELD_VOYAGE,
  RXFIELD_VOYAGE_TO,
  RXFIELD_VOYAGE_ETD,
  RXFIELD_VOYAGE_VESSEL_OWNER,
  RXFIELD_VOYAGE_TO_CLOSING_DATETIME,
  RXFIELD_VOYAGE_ATA,
  RXFIELD_VOYAGE_VESSEL_ID,
  RXFIELD_VOYAGE_ATA_FORMATTED,
  RXFIELD_VOYAGE_ETD_FORMATTED,
  RXFIELD_VOYAGE_TO_CLOSING_DATETIME_FORMATTED,
  RXFIELD_VOYAGE_SHIPPING_LINE,
  RXFIELD_BILL_TO,
  RXFIELD_DO_NO,
  RXFIELD_DG_CLASS,
  RXFIELD_DG_SUB_CLASS,
} from '../../constant';
import { extractNameFromObj, unauthorizedErrorHandler, toMoment } from '../../helper';
import {
  downloadVesselByNameAsync,
  clearUIError,
  downloadVoyageByNameAsync,
  clearVoyageList,
  addSelectedBookExportVessel,
  downloadVoyageDetailAsync,
  addSelectedBookExportVoyage,
  clearVoyageDetail,
  addBookExportService,
  setBookExportActiveForm,
  downloadCustomerCompanyListAsync,
  downloadDoListAsync,
  downloadPpjkCompanyListAsync,
  downloadingBookExportList,
  showErrorSnackbar,
  downloadExportDGClassListAsync,
} from '../../redux/action';
import LocalizedString from '../../localization';
import uploadBookExportPartyAsync from '../../redux/action/async/uploadBookExportPartyAsync';

const formatTimeDisplay = time => (time ? toMoment(time)
  .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING) : time);

const convertStringArrayToDropdownOption = arr => arr.map(item => (
  { label: item.name, value: item.id }
));

const getpaymentBehalfOfList = (state, doList, customerCompanyList, currCompany) => {
  const currentCompany = { label: currCompany.name, value: currCompany.id };
  if (currCompany.role.toUpperCase() === ROLE_CUSTOMER) {
    return [currentCompany];
  }
  if (currCompany.role.toUpperCase() === ROLE_PPJK) {
    const selector = formValueSelector(RXFORM_BOOK_EXPORT_FEEDER);
    const selectedDoNo = selector(state, RXFIELD_DO_NO);
    const doDetails = doList[selectedDoNo];

    if (doDetails) {
      const billToCompany = { label: doDetails.billToName, value: doDetails.billTo };
      return [currentCompany, billToCompany];
    }
    return [currentCompany];
  }
  const list = Object.values(customerCompanyList).map(item => ({
    label: item.name,
    value: item.id,
  }));
  return [...list, currentCompany];
};

const getInitialValues = state => ({
  ...state.bookExport.bookExportInfo,
  paymentOnBehalfOf: state.bookExport.bookExportInfo.paymentOnBehalfOf
    || state.currentUser.company.role.toUpperCase() === ROLE_CUSTOMER
    ? state.currentUser.company.id
    : undefined,
  forwarderId: state.bookExport.bookExportInfo.forwarderId
    || state.currentUser.company.role.toUpperCase() === ROLE_PPJK
    ? state.currentUser.company.id
    : undefined,
  billTo: state.currentUser.company.role.toUpperCase() === ROLE_CUSTOMER
    ? state.currentUser.company.id : undefined,
  toClosingDateTime: formatTimeDisplay(state.bookExport.toClosingDateTime),
  etd: formatTimeDisplay(state.bookExport.etd),
  ata: formatTimeDisplay(state.bookExport.ata),
  etdFormatted: state.bookExport.bookExportInfo.etd
    ? toMoment(state.bookExport.bookExportInfo.etd).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)
    : '',
  ataFormatted: state.bookExport.bookExportInfo.ata
    ? toMoment(state.bookExport.bookExportInfo.ata).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)
    : '',
  toClosingDateTimeFormatted: state.bookExport.bookExportInfo.toClosingDateTime
    ? toMoment(state.bookExport.bookExportInfo.toClosingDateTime)
      .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)
    : '',
});

const getSelectedCompanyRole = (selectedCompany, customerList, ppjkList = []) => {
  if (selectedCompany !== '') {
    if (Object.keys(ppjkList).length !== 0) {
      return ppjkList.find(x => x === selectedCompany) === undefined
        ? ROLE_CUSTOMER : ROLE_PPJK;
    }
    return customerList.find(x => x === selectedCompany) === undefined
      ? ROLE_PPJK : ROLE_CUSTOMER;
  }
  return null;
};

const getSelectedPaymentBehalfOf = (state) => {
  const selector = formValueSelector(RXFORM_BOOK_EXPORT_FEEDER);
  return selector(state, RXFIELD_PAYMENT_ON_BEHALF_OF);
};

const getDgSubclassList = (state) => {
  const selectedDgClass = formValueSelector(RXFORM_BOOK_EXPORT_FEEDER)(state, RXFIELD_DG_CLASS);

  return Object.values(state.dgClass)
    .filter(item => selectedDgClass && item.classId === selectedDgClass)
    .map(item => ({
      label: item.subClassName || '-',
      value: item.subClassId || '-',
    }));
};

const mapStateToProps = state => ({
  downloading: state.uiBookExportFeeder.downloading,
  currCompanyRole: state.currentUser.company.role,
  currCompanyName: state.currentUser.company.id,
  userCompany: state.currentUser.company,
  initialValues: getInitialValues(state),
  voyageDetail: {
    toid: state.bookExport.toid,
    toClosingDateTime: state.bookExport.toClosingDateTime
      ? toMoment(state.bookExport.toClosingDateTime)
        .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)
      : '',
    vesselId: state.bookExport.vesselId,
    etd: state.bookExport.etd ? toMoment(state.bookExport.etd).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING) : '',
    ata: state.bookExport.ata ? toMoment(state.bookExport.ata).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING) : '',
    vesselOwner: state.bookExport.vesselOwner,
  },

  lstBillTo: Object.values(state.customerCompanyList)
    .map(item => ({ label: item.name, value: item.id })),
  lstForwarder: Object.values(state.ppjkCompanyList)
    .map(item => ({ label: item.name, value: item.id })),
  lstPaymentBehalfOf: getpaymentBehalfOfList(
    state, state.exportDoList, state.customerCompanyList, state.currentUser.company,
  ),
  lstVessel: convertStringArrayToDropdownOption(_.values(state.exportVesselList)
    .filter(vessel => vessel.id)),
  // depends on vessel

  lstDgClass: Object.values(state.dgClass)
    .reduce((acc, curr) => (acc.some(item => item.classId === curr.classId)
      ? acc
      : [...acc, curr]
    ), [])
    .map(item => ({
      label: item.enabled
        ? item.className
        : `${item.className} (${LocalizedString.common.labelNotAvailable})` || '-',
      value: item.classId || '-',
    })),
  lstDgSubclass: getDgSubclassList(state),
  lstVoyage: convertStringArrayToDropdownOption(_.values(state.exportVoyageList)),
  lstDO: extractNameFromObj(state.exportDoList, 'doNo').map(item => ({ label: item, value: item })),
  lstDOObj: Object.values(state.exportDoList),
  selectedPaymentBehalfOf: getSelectedPaymentBehalfOf(state),
  selectedPaymentBehalfOfRole: getSelectedCompanyRole(
    getSelectedPaymentBehalfOf(state),
    extractNameFromObj(state.customerCompanyList, 'id'),
    extractNameFromObj(state.ppjkCompanyList, 'id'),
  ),

  uploadingNewBooking: state.uiBookExportFeeder.booking
    || state.uiBookExportParty.uploadingBookPartyDocument,
  downloadingVesselList: state.uiBookExportFeeder.searchingVesselList,
  downloadingVoyageList: state.uiBookExportFeeder.searchingVoyageList,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async (currCompanyRole) => {
    try {
      dispatch(clearUIError(RXSTATE_BOOK_EXPORT_PAGE));
      dispatch(downloadingBookExportList(true));

      const promises = [
        dispatch(downloadDoListAsync()),
        dispatch(downloadExportDGClassListAsync()),
        dispatch(downloadVesselByNameAsync('')),
      ];
      if (currCompanyRole.toUpperCase() === ROLE_PPJK) {
        promises.push(dispatch(downloadCustomerCompanyListAsync()));
      } else {
        promises.push(dispatch(downloadPpjkCompanyListAsync()));
      }
      await Promise.all(promises);
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    } finally {
      dispatch(downloadingBookExportList(false));
    }
  },
  onDoSelected: async (selectedDo, listDo) => {
    try {
      const selectedDoStr = selectedDo ? selectedDo.toString() : '';
      const selectedData = listDo.find(x => x.doNo === selectedDoStr) || {};
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER, RXFIELD_DO_DATE, selectedData.doDate || ''));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER, RXFIELD_VESSEL, selectedData.vessel || ''));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER, RXFIELD_VOYAGE_VESSEL_ID, selectedData.vesselId || ''));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER, RXFIELD_VOYAGE, selectedData.voyage || ''));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER, RXFIELD_VOYAGE_SHIPPING_LINE, selectedData.shippingLine || ''));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER, RXFIELD_PEB_DATE, selectedData.pebDate || ''));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER, RXFIELD_PEB_NO, selectedData.pebNo || ''));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER, RXFIELD_BILL_TO, selectedData.billTo || ''));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER, RXFIELD_PAYMENT_ON_BEHALF_OF, ''));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onDgClassSelected: () => {
    dispatch(change(RXFORM_BOOK_EXPORT_FEEDER, RXFIELD_DG_SUB_CLASS, null));
  },
  onNextPressed: async (values) => {
    try {
      dispatch(clearUIError(RXSTATE_BOOK_EXPORT_PAGE));
      // await dispatch(bookExportAsync(values));
      dispatch(addBookExportService(values));
      await dispatch(uploadBookExportPartyAsync());
      dispatch(setBookExportActiveForm(1));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onVesselSelected: async (selectedVessel) => {
    try {
      dispatch(clearUIError(RXSTATE_BOOK_EXPORT_PAGE));
      dispatch(clearVoyageList());
      dispatch(clearVoyageDetail());
      dispatch(addSelectedBookExportVessel(selectedVessel));

      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER, RXFIELD_VOYAGE, null));

      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER, RXFIELD_VOYAGE_TO, null));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER, RXFIELD_VOYAGE_ETD, null));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER, RXFIELD_VOYAGE_ETD_FORMATTED, null));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER, RXFIELD_VOYAGE_VESSEL_OWNER, null));

      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER, RXFIELD_VOYAGE_TO_CLOSING_DATETIME, null));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER,
        RXFIELD_VOYAGE_TO_CLOSING_DATETIME_FORMATTED, null));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER, RXFIELD_VOYAGE_ATA, null));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER, RXFIELD_VOYAGE_ATA_FORMATTED, null));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER, RXFIELD_VOYAGE_VESSEL_ID, null));

      await dispatch(downloadVoyageByNameAsync(''));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onVoyageSelected: async (selectedVoyage) => {
    await dispatch(addSelectedBookExportVoyage(selectedVoyage));

    if (selectedVoyage) {
      const voyageInfo = await dispatch(downloadVoyageDetailAsync());

      const etd = voyageInfo ? voyageInfo[RXFIELD_VOYAGE_ETD] : null;
      const ata = voyageInfo ? voyageInfo[RXFIELD_VOYAGE_ATA] : null;
      const tcDate = voyageInfo ? voyageInfo[RXFIELD_VOYAGE_TO_CLOSING_DATETIME] : null;

      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER,
        RXFIELD_VOYAGE_TO, voyageInfo[RXFIELD_VOYAGE_TO]));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER,
        RXFIELD_VOYAGE_VESSEL_OWNER, voyageInfo[RXFIELD_VOYAGE_VESSEL_OWNER]));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER,
        RXFIELD_VOYAGE_VESSEL_ID, voyageInfo[RXFIELD_VOYAGE_VESSEL_ID]));

      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER, RXFIELD_VOYAGE_ATA, ata));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER,
        RXFIELD_VOYAGE_ATA_FORMATTED, formatTimeDisplay(ata)));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER, RXFIELD_VOYAGE_ETD, etd));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER,
        RXFIELD_VOYAGE_ETD_FORMATTED, formatTimeDisplay(etd)));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER,
        RXFIELD_VOYAGE_TO_CLOSING_DATETIME, tcDate));
      dispatch(change(RXFORM_BOOK_EXPORT_FEEDER,
        RXFIELD_VOYAGE_TO_CLOSING_DATETIME_FORMATTED, formatTimeDisplay(tcDate)));
    }
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FeederTab));
