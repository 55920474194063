import * as Action from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case Action.ADD_PPJK_COMPANY_LIST:
      return { ...action.ppjkCompanyList };
    default:
      return state;
  }
};
