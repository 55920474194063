import {
  requestingPrint, setPdfViewerSourceUrl, setTappedPdfViewerTitle,
} from '../simple-action';
import * as Helper from '../../../helper';
import * as Constant from '../../../constant';
import LocalizedString from '../../../localization';

const getTitle = (printType) => {
  switch (printType) {
    case Constant.PRINT_TYPE_INVOICE:
      return LocalizedString.pdfViewerScreen.titleInvoice;
    case Constant.PRINT_TYPE_KMT:
      return LocalizedString.pdfViewerScreen.titleKMT;
    case Constant.PRINT_TYPE_SP2:
      return LocalizedString.pdfViewerScreen.titleSP2;
    default:
      return '';
  }
};

export default data => async (dispatch, getState) => {
  try {
    dispatch(requestingPrint(true, data.invoiceNo, data.printType));

    const { token } = getState().authentication;
    const response = await Helper.requestPrint(data, token);
    const url = Constant.URL_PRINT.replace(/\{id\}/, response.printId);
    const title = getTitle(data.printType);

    dispatch(setTappedPdfViewerTitle(title));
    dispatch(setPdfViewerSourceUrl(url));
  } finally {
    dispatch(requestingPrint(false));
  }
};
