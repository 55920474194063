import {
  clearForms, deletingForm, setFormDeleteDialogStatus,
} from '../simple-action';
import * as Helper from '../../../helper';
import downloadAllFormAsync from './downloadAllFormAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(deletingForm(true));
    const { token } = getState().authentication;
    const { selectedFormId } = getState().uiForm;

    await Helper.deleteForm(selectedFormId, token);
    dispatch(clearForms());
    dispatch(downloadAllFormAsync());
  } finally {
    dispatch(deletingForm(false));
    dispatch(setFormDeleteDialogStatus(false));
  }
};
