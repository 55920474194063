import * as Action from '../action';
import * as Constant from '../../constant';

const initialState = {
  downloading: false,
  uploading: false,
  deleting: false,

  dialogAddStatus: false,
  dialogEditStatus: false,
  dialogDetailStatus: false,
  dialogDeleteStatus: false,
  dialogFilterStatus: false,

  selectedFieldId: '',

  filters: {
    [Constant.TABLE_FIELD_FIELD_NAME]: '',
    [Constant.RXFIELD_FIELD_FORM]: '',
  },
  tempFormFilter: '',
  sortField: '',
  sortDirection: '',
  activePage: 0,
  pageSize: 20,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.DOWNLOADING_FIELD:
      return { ...state, downloading: action.value };
    case Action.UPLOADING_FIELD:
      return { ...state, uploading: action.value };
    case Action.DELETING_FIELD:
      return { ...state, deleting: action.value };
    case Action.SET_FIELD_SELECTED_ID:
      return { ...state, selectedFieldId: action.value };
    case Action.SET_FIELD_ADD_DIALOG_STATUS:
      return { ...state, dialogAddStatus: action.value };
    case Action.SET_FIELD_EDIT_DIALOG_STATUS:
      return { ...state, dialogEditStatus: action.value };
    case Action.SET_FIELD_DETAIL_DIALOG_STATUS:
      return { ...state, dialogDetailStatus: action.value };
    case Action.SET_FIELD_DELETE_DIALOG_STATUS:
      return { ...state, dialogDeleteStatus: action.value };
    case Action.SET_FIELD_FILTER_DIALOG_STATUS:
      return { ...state, dialogFilterStatus: action.value };
    case Action.SET_FIELD_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.value,
        },
      };
    }
    case Action.SET_FIELD_PAGING: {
      return { ...state, ...action.value };
    }
    case Action.SET_FIELD_SORT: {
      return { ...state, sortField: action.sortField, sortDirection: action.sortDirection };
    }
    case Action.SET_TEMP_FILTER_FORM: {
      return { ...state, tempFormFilter: action.value };
    }
    case Action.CLEAR_FIELDS:
      return initialState;
    default:
      return state;
  }
};
