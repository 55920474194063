import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import {
  CssBaseline,
  Grid,
  Card,
  CardContent,
  Fab,
  Icon,
  Typography,
  withStyles,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Input,
  CardHeader,
  Select,
  MenuItem,
  CardActions,
  ButtonBase,
  Dialog,
  DialogContent,
  IconButton,
  DialogActions,
  Divider,
} from '@material-ui/core';
import Calendar from 'react-calendar';
import * as Constant from '../../constant';
import {
  isSortFieldActive, getSortIcon, toCurrency, toMoment,
} from '../../helper';
import { TariffSimulationShape, TariffSimulationDetailShape } from '../../type';
import LocalizedString from '../../localization';
import { PageHeaderUser, LoadingIndicator, FilterableSelectAsync, PdfViewer } from '../../component';
import PasswordInputDialog from './password-input-dialog.presentation';
import BlCard from './bl-card.container';

const styles = theme => ({
  backIcon: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  btnAddToCart: {
    backgroundColor: '#5CB860',
    color: '#ffffff',
    width: '256px',
    '&:hover': {
      backgroundColor: '#558b2f',
    },
  },
  btnNext: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  btnPrint: {
    marginLeft: 8,
    backgroundColor: '#ffffff',
  },
  calendarPicker: {
    '& .react-calendar__navigation__next2-button': {
      display: 'none',
    },
    '& .react-calendar__navigation__prev2-button': {
      display: 'none',
    },
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  cardActionPrimary: {
    display: 'flex',
    alignItems: 'baseline',
    padding: 16,
    justifyContent: 'space-between',
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  cardBlDataHeader: {
    marginBottom: theme.spacing.unit * 2,
  },
  cardItem: {
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '6px',
    boxSizing: 'border-box',
    padding: '16px',
    marginBottom: theme.spacing.unit,
    '&:last-child': {
      marginBottom: 0,
    },
    transition: 'background-color 0.4s',
  },
  cardItemActive: {
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  cardItemContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: theme.spacing.unit * 2,
    maxBlockSize: '500px',
    height: '500px',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${Constant.DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  detailHeader: {
    color: '#0066CB',
  },
  detailButton: {
    backgroundColor: '#ffffff',
  },
  dialogContent: {
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    marginTop: '24px',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: '24px',
  },
  dialogActions: {
    margin: 0,
    padding: theme.spacing.unit * 3,
    borderTop: '1px solid #C4C4C4',
  },
  grow: {
    flexGrow: 1,
  },
  searchButton: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  moreButton: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  marginLeft: {
    marginLeft: theme.spacing.unit * 8,
  },
  itemPerRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    color: '#0266B4',
  },
  root: {
    width: '100%',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  textJumlahPembayaran: {
    textTransform: 'uppercase',
  },
  textTotalPrice: {
    fontSize: '22px',
    fontWeight: 500,
    color: '#0065B3',
    marginRight: theme.spacing.unit * 2,
  },
});

const isAddedToCart = (blNo, shoppingCart) => {
  if (shoppingCart) {
    return Boolean(shoppingCart.find(shopItem => shopItem.blNumber === blNo
      && shopItem.service === Constant.CART_SERVICE_NAME_IMPORT));
  }

  return false;
};

const isAddedToTerm = (blNo, termOrder) => {
  if (termOrder) {
    return termOrder.find(order => order.blNumber === blNo
      && order.service === Constant.CART_SERVICE_NAME_IMPORT);
  }

  return false;
};

const renderCalendarDialog = (
  classes, currentUser,
  selectedBlNo, selectedDate, paymentBehalfOfList, selectedPaymentBehalfOf,
  dialogCalendarStatus, onChangeDate, onClose, onNextPress, onPaymentBehalfOfSelected,
  onCalendarDialogAppear, minDate, maxDate,
) => {
  const handleNextPress = () => {
    onNextPress(selectedBlNo, selectedDate, selectedPaymentBehalfOf);
  };

  return (
    <Dialog
      open={dialogCalendarStatus}
      maxWidth="md"
      fullWidth
      onEnter={() => onCalendarDialogAppear(currentUser)}
    >
      <DialogContent>
        <div className={classes.dialogHeader}>
          <Typography color="primary" variant="h6">
            {LocalizedString.tariffSimulation.dialogTitleDelivery}
          </Typography>
          <IconButton
            onClick={onClose}
          >
            <Icon>close</Icon>
          </IconButton>
        </div>

        <div className={classes.dialogContent}>
          <div>
            <Calendar
              minDate={minDate}
              maxDate={maxDate}
              onChange={onChangeDate}
              prevLabel={<Typography color="primary">Previous</Typography>}
              nextLabel={<Typography color="primary">Next</Typography>}
              className={classes.calendarPicker}
            />
          </div>
          <div style={{ marginLeft: '24px', flexGrow: 1 }}>
            <div>
              <Typography variant="caption">Selected Date *</Typography>
              <Typography>
                {selectedDate
                  && moment(selectedDate).format(Constant.MOMENT_DATE_LONG_FORMAT_STRING)}
              </Typography>
            </div>
            <div style={{ marginTop: '24px' }}>
              <FilterableSelectAsync
                required
                options={paymentBehalfOfList}
                label={LocalizedString.tariffSimulation.lblPaymentBehalfOf}
                input={{
                  value: selectedPaymentBehalfOf,
                  onChange: value => onPaymentBehalfOfSelected(value),
                }}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedDate}
            onClick={handleNextPress}
            classes={{
              containedPrimary: classes.btnNext,
            }}
          >
            Next
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

const renderTariffSimulation = (
  classes,
  addingShoppingCartItem,
  selectedBlNo,
  shoppingCart,
  termOrder,
  tariffSimulationInfo,
  userCompany,
  onAddToCartPressed,
  onAddToTermPressed,
  onPrintProformaInvoicePressed,
) => {
  if (!tariffSimulationInfo || !tariffSimulationInfo.blNo || !selectedBlNo) {
    return (<></>);
  }

  const renderAddToCartButton = () => {
    if (isAddedToCart(selectedBlNo, shoppingCart)) {
      return (
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.btnAddToCart}
          disabled
        >
          {LocalizedString.tariffSimulation.btnAlreadyInCart}
        </Button>
      );
    }

    return (
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.btnAddToCart}
        disabled={addingShoppingCartItem}
        onClick={() => onAddToCartPressed(tariffSimulationInfo)}
      >
        {LocalizedString.tariffSimulation.btnAddToCart}
      </Button>
    );
  };

  const renderAddToTermButton = () => (
    <Button
      variant="contained"
      color="primary"
      size="large"
      className={classes.btnAddToCart}
      disabled={addingShoppingCartItem}
      onClick={() => onAddToTermPressed(tariffSimulationInfo)}
    >
      {LocalizedString.tariffSimulation.btnAddToTerm}
    </Button>
  );

  const renderAddToButton = () => {
    if (userCompany.paymentMethod === Constant.PAYMENT_TYPE_TERM
      || tariffSimulationInfo.billToPaymentMethod === Constant.PAYMENT_TYPE_TERM) {
      return renderAddToTermButton();
    }
    return renderAddToCartButton();
  };

  const renderPrintButton = () => {
    return (
      <Button
        variant="outlined"
        color="primary"
        size="large"
        className={classes.btnPrint}
        onClick={() => onPrintProformaInvoicePressed(tariffSimulationInfo.preInv)}
      >
        {LocalizedString.tariffSimulation.btnPrintProformaInvoice}
      </Button>
    );
  }

  return (
    <Card className={classes.cardBlDataHeader}>
      <CardHeader
        title={(
          <>
            <Typography variant="h6" className={classes.detailHeader}>
              {LocalizedString.trucking.lblBlNo}
              &nbsp;
              {selectedBlNo}
            </Typography>
          </>
        )}
      />
      <CardContent>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <div style={{ display: 'flex' }}>
              <div>
                <Typography variant="caption">{LocalizedString.tariffSimulation.lblGateInCdp}</Typography>
                <Typography>
                  {toMoment(tariffSimulationInfo.gateInCdp)
                    .format(Constant.MOMENT_DATE_FORMAT_STRING)}
                </Typography>
              </div>
              <div className={classes.marginLeft}>
                <Typography variant="caption">{LocalizedString.tariffSimulation.lblSP2Expectation}</Typography>
                <Typography>
                  {toMoment(tariffSimulationInfo.sp2Expectation)
                    .format(Constant.MOMENT_DATE_FORMAT_STRING)}
                </Typography>
              </div>
              <div className={classes.marginLeft}>
                <Typography variant="caption">{LocalizedString.tariffSimulation.lblParty}</Typography>
                <Typography>
                  {tariffSimulationInfo.partyDesc}
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Divider color="primary" />
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={0}>
              <Grid item xs={8} md={8}>
                <Typography variant="caption">
                  {LocalizedString.tariffSimulation.lblTotalBeforeDiscount}
                </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography variant="body1" align="right">
                  {toCurrency(tariffSimulationInfo.totalGross)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={0}>
              <Grid item xs={8} md={8}>
                <Typography variant="caption">
                  {LocalizedString.tariffSimulation.lblAdministration}
                </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography variant="body1" align="right">
                  {toCurrency(tariffSimulationInfo.adminFee)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={0}>
              <Grid item xs={8} md={8}>
                <Typography variant="caption">
                  {LocalizedString.tariffSimulation.lblFatPPN}
                </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography variant="body1" align="right">
                  {toCurrency(tariffSimulationInfo.vat)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={0}>
              <Grid item xs={8} md={8}>
                <Typography variant="caption">
                  {LocalizedString.tariffSimulation.lblDiscount}
                </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography variant="body1" align="right">
                  {toCurrency(tariffSimulationInfo.discount)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={0}>
              <Grid item xs={8} md={8}>
                <Typography variant="caption">
                  {LocalizedString.tariffSimulation.lblTaxBasis}
                </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography variant="body1" align="right">
                  {toCurrency(tariffSimulationInfo.taxBasis)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActionPrimary}>
        <Typography variant="body1" className={classes.textJumlahPembayaran}>{LocalizedString.trucking.lblTotalPrice}</Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" className={classes.textTotalPrice}>
            Rp.&nbsp;
            {toCurrency(tariffSimulationInfo.totalPayment)}
          </Typography>
          {renderAddToButton()}
          {renderPrintButton()}
        </div>
      </CardActions>
    </Card>
  );
};

const renderBlCard = (
  classes, billingLines, selectedBlNo, shoppingCart, termOrder, onMorePress,
) => {
  if (!billingLines) {
    return (<Typography>No BL available</Typography>);
  }

  return billingLines.map(item => (
    <ButtonBase
      component="div"
      key={item.blNo}
      className={classNames(classes.cardItem, {
        [classes.cardItemActive]: selectedBlNo && item.blNo === selectedBlNo,
      })}
      onClick={() => onMorePress(item.blNo,
        isAddedToCart(item.blNo, shoppingCart),
        shoppingCart,
        Boolean(isAddedToTerm(item.blNo, termOrder)),
        isAddedToTerm(item.blNo, termOrder))}
    >
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Typography color="primary" variant="h6">
            {LocalizedString.tariffSimulation.lblBlNo}
            &nbsp;
            {item.blNo}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="caption">{LocalizedString.tariffSimulation.lblVesselVoyage}</Typography>
          <Typography>
            {item.vessel}
            &nbsp;-&nbsp;
            {item.voyage}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="caption">{LocalizedString.tariffSimulation.lblPpjk}</Typography>
          <Typography className={classes.capitalize}>{item.forwarderId || '-'}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="caption">{LocalizedString.tariffSimulation.lblGateInCdp}</Typography>
          <Typography>
            {moment(item.gateInCdp).format(Constant.MOMENT_DATE_FORMAT_STRING)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="caption">{LocalizedString.tariffSimulation.lblInvoiceNo}</Typography>
          <Typography className={classes.capitalize}>{item.invoiceNo || '-'}</Typography>
        </Grid>
      </Grid>
    </ButtonBase>
  ));
};

const renderBlDetail = (
  classes, tariffSimulationDetail,
  filters, sortField, sortDirection,
  pageSize, activePage, totalPage,
  onChangeFilter, onChangePaging, handleChangeSort, selectedBlNo,
) => {
  if (!tariffSimulationDetail || !selectedBlNo) {
    return (<></>);
  }

  return (
    <Card>
      <CardHeader
        title={(
          <>
            <Typography variant="h6" className={classes.detailHeader}>
              Detail
            </Typography>
            <div className={classes.itemPerRowContainer}>
              <Select
                value={pageSize}
                onChange={event => onChangePaging(
                  Constant.RXTABLEFIELD_PAGE_SIZE,
                  event.target.value,
                )}
              >
                {Constant.DEFAULT_PAGE_SIZE_OPTIONS.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                    &nbsp;
                    {LocalizedString.common.labelRows}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </>
        )}
      />
      <CardContent>
        <div style={{ width: '100%', overflow: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={
                      isSortFieldActive(sortField,
                        Constant.TABLE_FIELD_TARIFF_SIMULATION_DESCRIPTION)
                    }
                    IconComponent={getSortIcon(
                      isSortFieldActive(sortField,
                        Constant.TABLE_FIELD_TARIFF_SIMULATION_DESCRIPTION),
                      sortDirection,
                    )}
                    onClick={
                      () => handleChangeSort(Constant.TABLE_FIELD_TARIFF_SIMULATION_DESCRIPTION)
                    }
                  >
                    {LocalizedString.tariffSimulation.lblDescription}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={
                      isSortFieldActive(sortField, Constant.TABLE_FIELD_TARIFF_SIMULATION_DAYS)
                    }
                    IconComponent={getSortIcon(
                      isSortFieldActive(sortField, Constant.TABLE_FIELD_TARIFF_SIMULATION_DAYS),
                      sortDirection,
                    )}
                    onClick={
                      () => handleChangeSort(Constant.TABLE_FIELD_TARIFF_SIMULATION_DAYS)
                    }
                  >
                    {LocalizedString.tariffSimulation.lblDays}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={
                      isSortFieldActive(sortField, Constant.TABLE_FIELD_TARIFF_SIMULATION_PRICE)
                    }
                    IconComponent={getSortIcon(
                      isSortFieldActive(sortField, Constant.TABLE_FIELD_TARIFF_SIMULATION_PRICE),
                      sortDirection,
                    )}
                    onClick={
                      () => handleChangeSort(Constant.TABLE_FIELD_TARIFF_SIMULATION_PRICE)
                    }
                  >
                    {LocalizedString.tariffSimulation.lblPrice}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={
                      isSortFieldActive(sortField, Constant.TABLE_FIELD_TARIFF_SIMULATION_TOTAL)
                    }
                    IconComponent={getSortIcon(
                      isSortFieldActive(sortField, Constant.TABLE_FIELD_TARIFF_SIMULATION_TOTAL),
                      sortDirection,
                    )}
                    onClick={
                      () => handleChangeSort(Constant.TABLE_FIELD_TARIFF_SIMULATION_TOTAL)
                    }
                  >
                    {LocalizedString.tariffSimulation.lblSubprice}
                  </TableSortLabel>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Input
                    type="text"
                    fullWidth
                    value={filters[Constant.TABLE_FIELD_TARIFF_SIMULATION_DESCRIPTION]}
                    onChange={
                      ({ nativeEvent }) => onChangeFilter(
                        Constant.TABLE_FIELD_TARIFF_SIMULATION_DESCRIPTION,
                        nativeEvent.target.value,
                      )
                    }
                    placeholder={LocalizedString.common.placeholderSearch}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    type="number"
                    fullWidth
                    value={filters[Constant.TABLE_FIELD_TARIFF_SIMULATION_DAYS]}
                    onChange={
                      ({ nativeEvent }) => onChangeFilter(
                        Constant.TABLE_FIELD_TARIFF_SIMULATION_DAYS,
                        nativeEvent.target.value,
                      )
                    }
                    placeholder={LocalizedString.common.placeholderSearch}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    type="number"
                    fullWidth
                    value={filters[Constant.TABLE_FIELD_TARIFF_SIMULATION_PRICE]}
                    onChange={
                      ({ nativeEvent }) => onChangeFilter(
                        Constant.TABLE_FIELD_TARIFF_SIMULATION_PRICE,
                        nativeEvent.target.value,
                      )
                    }
                    placeholder={LocalizedString.common.placeholderSearch}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    type="number"
                    fullWidth
                    value={filters[Constant.TABLE_FIELD_TARIFF_SIMULATION_TOTAL]}
                    onChange={
                      ({ nativeEvent }) => onChangeFilter(
                        Constant.TABLE_FIELD_TARIFF_SIMULATION_TOTAL,
                        nativeEvent.target.value,
                      )
                    }
                    placeholder={LocalizedString.common.placeholderSearch}
                  />
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {tariffSimulationDetail && tariffSimulationDetail.map(detail => (
                <TableRow key={detail.index} className={classes.tableRow}>
                  <TableCell>
                    <Typography>
                      {detail.description}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>
                      {detail.days}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>
                      {toCurrency(detail.price)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>
                      {toCurrency(detail.total)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <div className={classes.tablePagination}>
          <Button
            variant="contained"
            style={{ flexGrow: 1 }}
            disabled={activePage === 1}
            onClick={() => onChangePaging(Constant.RXTABLEFIELD_ACTIVE_PAGE, activePage - 1)}
          >
            {LocalizedString.common.labelPagingPrevButton}
          </Button>

          <div className={classes.tablePaginationPageInput}>
            <span style={{ fontWeight: 500 }}>{LocalizedString.common.labelPagingPage}</span>
            <Input
              type="number"
              value={activePage}
              style={{ width: '50%' }}
              inputProps={{
                min: 1,
                max: totalPage,
                step: 1,
                style: { textAlign: 'center' },
              }}
              onChange={({ nativeEvent }) => onChangePaging(
                Constant.RXTABLEFIELD_ACTIVE_PAGE,
                parseInt(nativeEvent.target.value, 10),
              )}
            />
            <span style={{ fontWeight: 500 }}>
              {`${LocalizedString.common.labelPagingPageOf} ${totalPage}`}
            </span>
          </div>

          <Button
            variant="contained"
            style={{ flexGrow: 1 }}
            disabled={activePage === totalPage}
            onClick={() => onChangePaging(Constant.RXTABLEFIELD_ACTIVE_PAGE, activePage + 1)}
          >
            {LocalizedString.common.labelPagingNextButton}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

class TariffSimulationBase extends Component {
  componentDidMount() {
    this.props.onAppear(this.props.userCompany.role);
  }

  handleChangeSort = (newSortField) => {
    const { sortField, sortDirection, onChangeSort } = this.props;
    if (newSortField === sortField) {
      onChangeSort(
        sortField,
        sortDirection === Constant.TABLE_SORT_ASCENDING
          ? Constant.TABLE_SORT_DESCENDING
          : Constant.TABLE_SORT_ASCENDING,
      );
    } else {
      onChangeSort(newSortField, Constant.TABLE_SORT_ASCENDING);
    }
  };

  renderClearSelectionButton = () => {
    const {
      selectedBlNo,
      onClearTappedBlNoPressed,
    } = this.props;
    if (!selectedBlNo) {
      return (<></>);
    }
    return (
      <div style={{ display: 'flex', marginBottom: '1rem' }}>
        <div style={{ flexGrow: 1 }} />
        <div>
          <Button variant="outlined" onClick={onClearTappedBlNoPressed}>Clear Selection</Button>
        </div>
      </div>
    );
  }

  render() {
    const {
      classes,
      notificationOpen,
      addingShoppingCartItem,
      downloadingTariffSimulationOrder,
      downloadingTariffSimulation,
      downloadingProformaInvoice,
      dialogCalendarStatus,
      currentUser,
      selectedDate,
      selectedBlNo,
      searchBarText,
      shoppingCart,
      termOrder,
      userCompany,
      tariffSimulationInfo,
      tariffSimulationDetail,
      paymentBehalfOfList,
      selectedPaymentBehalfOf,
      minDate,
      maxDate,

      activePage,
      totalPage,
      filters,
      sortField,
      sortDirection,
      pageSize,
      onAddToCartPressed,
      onAddToTermPressed,
      onPrintProformaInvoicePressed,
      onChangeSearchBarText,
      onChangeDate,
      onPaymentBehalfOfSelected,
      onCalendarDialogAppear,
      onCloseCalendarDialog,
      onNextPress,
      onChangeFilter,
      onChangePaging,

      dialogPasswordInputStatus,
      password,
      dialogError,
      onCancelPasswordPressed,
      onSubmitPasswordPressed,
      onChangePasswordText,
    } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />

        <PageHeaderUser />

        <div
          className={
            classNames(classes.content, {
              [classes.contentDrawerOpen]: notificationOpen,
              [classes.contentDrawerClosed]: !notificationOpen,
            })
          }
          style={{ padding: '16px' }}
        >
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <div className={classes.pageNavigatorContainer}>
                    <Fab
                      variant="round"
                      classes={{ root: classes.backIcon }}
                      component={Link}
                      to={Constant.ROUTE_NAME_DASHBOARD_USER}
                    >
                      <Icon>chevron_left</Icon>
                    </Fab>
                    <div style={{ marginLeft: '32px' }}>
                      <Typography variant="h5" className={classes.pageTitle}>{LocalizedString.tariffSimulation.title}</Typography>
                      <Typography variant="caption">{LocalizedString.tariffSimulation.caption}</Typography>
                    </div>
                    <div className={classes.grow} />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography>
                        <i>Information</i>
                      </Typography>
                      &nbsp;
                      <Icon fontSize="large" color="primary">info</Icon>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <div style={{ display: 'flex' }}>
                    <div style={{ flexGrow: 1, alignSelf: 'flex-end' }}>
                      <TextField
                        fullWidth
                        placeholder="Search BL no"
                        value={searchBarText}
                        onChange={event => onChangeSearchBarText(event.target.value)}
                      />
                    </div>
                    <div style={{ display: 'inline-flex', marginLeft: '8px' }}>
                      <Fab
                        size="small"
                        variant="round"
                        color="default"
                        className={classes.searchButton}
                      >
                        <Icon>search</Icon>
                      </Fab>
                    </div>
                  </div>

                  <div className={classes.cardItemContainer}>
                    <BlCard />
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              {this.renderClearSelectionButton()}
              {renderTariffSimulation(
                classes, addingShoppingCartItem,
                selectedBlNo, shoppingCart, termOrder,
                tariffSimulationInfo, userCompany,
                onAddToCartPressed, onAddToTermPressed,
                onPrintProformaInvoicePressed,
              )}

              {renderBlDetail(
                classes, tariffSimulationDetail,
                filters, sortField, sortDirection,
                pageSize, activePage, totalPage,
                onChangeFilter, onChangePaging, this.handleChangeSort, selectedBlNo,
              )}
            </Grid>
          </Grid>
        </div>

        {
          renderCalendarDialog(
            classes, currentUser, selectedBlNo, selectedDate,
            paymentBehalfOfList, selectedPaymentBehalfOf,
            dialogCalendarStatus,
            onChangeDate, onCloseCalendarDialog, onNextPress,
            onPaymentBehalfOfSelected,
            onCalendarDialogAppear, minDate, maxDate,
          )
        }

        <PasswordInputDialog
          open={dialogPasswordInputStatus}
          password={password}
          error={dialogError}
          onCancelPressed={onCancelPasswordPressed}
          onSubmitPressed={() => onSubmitPasswordPressed(password, tariffSimulationInfo)}
          onChangePasswordText={onChangePasswordText}
        />

        <PdfViewer />

        {(downloadingTariffSimulationOrder
          || downloadingTariffSimulation
          || addingShoppingCartItem
          || downloadingProformaInvoice)
          && <LoadingIndicator />}
      </div>
    );
  }
}
TariffSimulationBase.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  notificationOpen: PropTypes.bool.isRequired,
  addingShoppingCartItem: PropTypes.bool.isRequired,
  // downloadingContainerTrackings: PropTypes.bool.isRequired,
  downloadingTariffSimulation: PropTypes.bool.isRequired,
  downloadingTariffSimulationOrder: PropTypes.bool.isRequired,
  downloadingProformaInvoice: PropTypes.bool.isRequired,
  dialogCalendarStatus: PropTypes.bool.isRequired,
  selectedDate: PropTypes.string.isRequired,
  selectedBlNo: PropTypes.string.isRequired,
  searchBarText: PropTypes.string.isRequired,
  shoppingCart: PropTypes.arrayOf(PropTypes.any).isRequired,
  termOrder: PropTypes.arrayOf(PropTypes.any).isRequired,
  minDate: PropTypes.shape(Date).isRequired,
  maxDate: PropTypes.shape(Date).isRequired,
  userCompany: PropTypes.shape(PropTypes.any).isRequired,
  tariffSimulationDetail: PropTypes.arrayOf(TariffSimulationDetailShape).isRequired,
  tariffSimulationInfo: TariffSimulationShape.isRequired,
  paymentBehalfOfList: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedPaymentBehalfOf: PropTypes.string.isRequired,
  currentUser: PropTypes.shape(PropTypes.any).isRequired,

  filters: PropTypes.shape(PropTypes.any).isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  activePage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,

  onChangeSearchBarText: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onAddToCartPressed: PropTypes.func.isRequired,
  onAddToTermPressed: PropTypes.func.isRequired,
  onPrintProformaInvoicePressed: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  onPaymentBehalfOfSelected: PropTypes.func.isRequired,
  onCalendarDialogAppear: PropTypes.func.isRequired,
  onCloseCalendarDialog: PropTypes.func.isRequired,
  onNextPress: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onChangePaging: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,

  // password dialog
  dialogPasswordInputStatus: PropTypes.bool.isRequired,
  password: PropTypes.string.isRequired,
  dialogError: PropTypes.string.isRequired,
  onCancelPasswordPressed: PropTypes.func.isRequired,
  onSubmitPasswordPressed: PropTypes.func.isRequired,
  onChangePasswordText: PropTypes.func.isRequired,
  onClearTappedBlNoPressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(TariffSimulationBase);
