import { connect } from 'react-redux';
import RegisterPasswordPage from './register-password.presentation';
import {
  clearUIError, setUIError, uploadRegisterPasswordAsync,
} from '../../redux/action';
import { RXSTATE_REGISTER_PASSWORD } from '../../constant';

const isResetPin = (router) => {
  const queryString = router.location.search;
  const resetPinMatcher = new RegExp(/\?(reset_pin)=([A-z0-9]+)&?/);
  const result: string[] = queryString.match(resetPinMatcher);
  return result ? result[2] === 'true' : false;
};

const mapStateToProps = (state, ownProps) => ({
  submitting: state.uiRegisterPassword.submitting,
  errorMsg: state.uiRegisterPassword.error,
  resetPin: isResetPin(ownProps.history),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmitPressed: async (values) => {
    const userId = ownProps.match.params.id;
    try {
      dispatch(clearUIError(RXSTATE_REGISTER_PASSWORD));
      await dispatch(uploadRegisterPasswordAsync(userId, values, ownProps.history.push));
    } catch (error) {
      dispatch(setUIError(RXSTATE_REGISTER_PASSWORD, error.message));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPasswordPage);
