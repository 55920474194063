import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import TermCard from './term-card.presentation';
import {
  setTappedTermBlNo,
  setMyPaymentTermSearchText,
  setUIError,
  downloadMyPaymentTermOrderAsync,
  showErrorSnackbar,
} from '../../redux/action';
import { RXSTATE_MY_PAYMENT_TERM } from '../../constant';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const getFilteredBl = state => _.values(state.myTermOrder)
  .filter((item) => {
    const searchText = state.uiMyPaymentTerm.searchBarText.toLowerCase();
    return item.invoiceNo.toLowerCase().indexOf(searchText) >= 0
      || item.blNumber.toLowerCase().indexOf(searchText) >= 0
      || item.service.toLowerCase().indexOf(searchText) >= 0;
  });

const mapStateToProps = state => ({
  downloading: state.uiMyPaymentTerm.downloading,
  bl: getFilteredBl(state),
  searchBarText: state.uiMyPaymentTerm.searchBarText,
  selectedBlNo: state.uiMyPaymentTerm.tappedBlNo,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    try {
      await dispatch(downloadMyPaymentTermOrderAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onChangeSearchBarText: (text) => {
    dispatch(setMyPaymentTermSearchText(text));
  },
  onMorePressed: (tappedBlNo) => {
    dispatch(setTappedTermBlNo(tappedBlNo));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TermCard));
