import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import * as Constant from '../../constant';
import LocalizedString from '../../localization';
import * as Helper from '../../helper';
import * as Validation from '../../validation';
import { LoadingIndicator } from '../../component';

const styles = {
  buttonContainer: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnPrimary: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
};

const ChangePasswordPage = ({
  handleSubmit, updatingPin, onUpdatePinPressed, onCancel,
}) => (
  <div>
    <form onSubmit={handleSubmit(onUpdatePinPressed)}>
      <Grid container spacing={12}>
        <Grid item xs={12} md={12}>
          <Field
            name={Constant.RXFIELD_OLD_PIN}
            type="password"
            disabled={updatingPin}
            component={Helper.renderReduxFormTextField}
            label={LocalizedString.profileScreen.labelOldPin}
          />

          <Field
            name={Constant.RXFIELD_NEW_PIN}
            type="password"
            disabled={updatingPin}
            component={Helper.renderReduxFormTextField}
            label={LocalizedString.profileScreen.labelNewPin}
          />

          <Field
            name={Constant.RXFIELD_RE_NEW_PIN}
            type="password"
            disabled={updatingPin}
            component={Helper.renderReduxFormTextField}
            label={LocalizedString.profileScreen.labelConfirmNewPin}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <div style={styles.buttonContainer}>
            <Button disabled={updatingPin} color="primary" onClick={onCancel}>
              {LocalizedString.common.buttonCaptionCancel}
            </Button>
            <Button variant="contained" color="primary" type="submit" disabled={updatingPin} style={styles.btnPrimary}>
              {LocalizedString.common.buttonCaptionSave}
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
    {updatingPin && <LoadingIndicator />}
  </div>
);

export default reduxForm({
  form: Constant.RXFORM_PROFILE_SCREEN_PIN,
  validate: Validation.rxformValidateChangePin,
})(ChangePasswordPage);

ChangePasswordPage.propTypes = {
  updatingPin: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onUpdatePinPressed: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ChangePasswordPage.defaultProps = {
  updatingPin: false,
};
