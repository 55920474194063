import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  withStyles,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Button,
  Input,
  TableBody,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  IconButton,
  Icon,
} from '@material-ui/core';
import { DatePicker } from 'material-ui-pickers';
import * as Constant from '../../constant';
import { getSortIcon, isSortFieldActive, toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { LoadingIndicator } from '../../component';

const styles = theme => ({
  alignRight: {
    textAlign: 'right',
  },
  dialogContent: {
    width: '100%',
    overflow: 'auto',
    marginTop: '24px',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemPerRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '24px',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

class ReeferDataDetail extends Component {
  handleDialogEnter = () => {
    this.props.onAppear();
  }

  handleChangeSort = (newSortField) => {
    const { sortField, sortDirection, onChangeSort } = this.props;
    if (newSortField === sortField) {
      onChangeSort(
        sortField,
        sortDirection === Constant.TABLE_SORT_ASCENDING
          ? Constant.TABLE_SORT_DESCENDING
          : Constant.TABLE_SORT_ASCENDING,
      );
    } else {
      onChangeSort(newSortField, Constant.TABLE_SORT_ASCENDING);
    }
  };

  handleDialogClose = () => {
    this.props.onClose();
  }

  render() {
    const {
      classes,
      open,
      downloadingReeferDataDetails,
      containerNo,
      reeferData,
      sortField,
      sortDirection,
      filters,
      activePage,
      totalPage,
      pageSize,
      onChangeFilter,
      onChangePaging,
      onClose,
    } = this.props;

    return (
      <>
        <Dialog
          open={open}
          fullWidth
          maxWidth="md"
          scroll="paper"
          onEnter={this.handleDialogEnter}
          onClose={this.handleDialogClose}
        >
          <DialogContent>
            <div className={classes.dialogHeader}>
              <Typography color="primary" variant="h6">
                {LocalizedString.reeferDataDetail.lblContainerNumber}
                &nbsp;
                {containerNo}
              </Typography>
              <IconButton
                onClick={onClose}
              >
                <Icon>close</Icon>
              </IconButton>
            </div>

            <div className={classes.itemPerRowContainer}>
              <Select
                value={pageSize}
                onChange={event => onChangePaging(
                  Constant.RXTABLEFIELD_PAGE_SIZE,
                  event.target.value,
                )}
              >
                {Constant.DEFAULT_PAGE_SIZE_OPTIONS.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                    &nbsp;
                    {LocalizedString.common.labelRows}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className={classes.dialogContent}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={
                          isSortFieldActive(sortField, Constant.TABLE_FIELD_SHIFT_ID)
                        }
                        IconComponent={getSortIcon(
                          isSortFieldActive(sortField, Constant.TABLE_FIELD_SHIFT_ID),
                          sortDirection,
                        )}
                        onClick={() => this.handleChangeSort(Constant.TABLE_FIELD_SHIFT_ID)}
                      >
                        {LocalizedString.reeferDataDetail.lblShiftId}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={isSortFieldActive(sortField, Constant.TABLE_FIELD_SHIFT_DATE)}
                        IconComponent={getSortIcon(
                          isSortFieldActive(sortField, Constant.TABLE_FIELD_SHIFT_DATE),
                          sortDirection,
                        )}
                        onClick={() => this.handleChangeSort(Constant.TABLE_FIELD_SHIFT_DATE)}
                      >
                        {LocalizedString.reeferDataDetail.lblShiftDate}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={
                          isSortFieldActive(sortField, Constant.TABLE_FIELD_SETTING_TEMPERATURE)
                        }
                        IconComponent={getSortIcon(
                          isSortFieldActive(sortField, Constant.TABLE_FIELD_SETTING_TEMPERATURE),
                          sortDirection,
                        )}
                        onClick={() => this.handleChangeSort(Constant.TABLE_FIELD_SETTING_TEMPERATURE)}
                      >
                        {LocalizedString.reeferDataDetail.lblSettingPointTemp}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={isSortFieldActive(sortField, Constant.TABLE_FIELD_AIR_SUPPLY)}
                        IconComponent={getSortIcon(
                          isSortFieldActive(sortField, Constant.TABLE_FIELD_AIR_SUPPLY),
                          sortDirection,
                        )}
                        onClick={() => this.handleChangeSort(Constant.TABLE_FIELD_AIR_SUPPLY)}
                      >
                        {LocalizedString.reeferDataDetail.lblSupplyAirTemp}
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Input
                        type="number"
                        value={filters[Constant.TABLE_FIELD_SHIFT_ID]}
                        onChange={
                          ({ nativeEvent }) => onChangeFilter(
                            Constant.TABLE_FIELD_SHIFT_ID,
                            nativeEvent.target.value,
                          )
                        }
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      {/* <Input
                        type="date"
                        value={filters[Constant.TABLE_FIELD_SHIFT_DATE]}
                        onChange={
                          ({ nativeEvent }) => onChangeFilter(
                            Constant.TABLE_FIELD_SHIFT_DATE,
                            nativeEvent.target.value,
                          )
                        }
                        fullWidth
                      /> */}
                      <DatePicker
                        value={filters[Constant.TABLE_FIELD_SHIFT_DATE] || null}
                        clearable
                        emptyLabel="DD MMM YYYY"
                        format="DD MMM YYYY"
                        onChange={value => onChangeFilter(
                          Constant.TABLE_FIELD_SHIFT_DATE,
                          value,
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Input
                        type="text"
                        value={filters[Constant.TABLE_FIELD_SETTING_TEMPERATURE]}
                        onChange={
                          ({ nativeEvent }) => onChangeFilter(
                            Constant.TABLE_FIELD_SETTING_TEMPERATURE,
                            nativeEvent.target.value,
                          )
                        }
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <Input
                        type="text"
                        value={filters[Constant.TABLE_FIELD_AIR_SUPPLY]}
                        onChange={
                          ({ nativeEvent }) => onChangeFilter(
                            Constant.TABLE_FIELD_AIR_SUPPLY,
                            nativeEvent.target.value,
                          )
                        }
                        fullWidth
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {reeferData && reeferData.map(reefer => (
                    <TableRow key={reefer.shiftId} className={classes.tableRow}>
                      <TableCell>
                        <Typography>{reefer.shiftId}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {toMoment(reefer.shiftDate)
                            .format(Constant.MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.alignRight}>
                        <Typography>
                          {reefer.settingPointTemp}
                          {LocalizedString.common.tempSymbol}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.alignRight}>
                        <Typography>
                          {reefer.supplyAirTemp}
                          {LocalizedString.common.tempSymbol}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>

            <div className={classes.tablePagination}>
              <Button
                variant="contained"
                style={{ flexGrow: 1 }}
                disabled={!activePage || activePage === 1}
                onClick={() => onChangePaging(Constant.RXTABLEFIELD_ACTIVE_PAGE, activePage - 1)}
              >
                {LocalizedString.common.labelPagingPrevButton}
              </Button>

              <div className={classes.tablePaginationPageInput}>
                <span style={{ fontWeight: 500 }}>{LocalizedString.common.labelPagingPage}</span>
                <Input
                  type="number"
                  value={activePage}
                  style={{ width: '50%' }}
                  inputProps={{
                    min: 1,
                    max: totalPage,
                    step: 1,
                    style: { textAlign: 'center' },
                  }}
                  onChange={({ nativeEvent }) => onChangePaging(
                    Constant.RXTABLEFIELD_ACTIVE_PAGE,
                    parseInt(nativeEvent.target.value, 10),
                  )}
                />
                <span style={{ fontWeight: 500 }}>
                  {`${LocalizedString.common.labelPagingPageOf} ${totalPage}`}
                </span>
              </div>

              <Button
                variant="contained"
                style={{ flexGrow: 1 }}
                disabled={activePage && activePage === totalPage}
                onClick={() => onChangePaging(Constant.RXTABLEFIELD_ACTIVE_PAGE, activePage + 1)}
              >
                {LocalizedString.common.labelPagingNextButton}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        {downloadingReeferDataDetails && <LoadingIndicator />}
      </>
    );
  }
}
ReeferDataDetail.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  open: PropTypes.bool.isRequired,
  containerNo: PropTypes.string.isRequired,
  reeferData: PropTypes.arrayOf(PropTypes.any).isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  filters: PropTypes.shape(PropTypes.any).isRequired,
  totalPage: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onChangePaging: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  downloadingReeferDataDetails: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ReeferDataDetail);
