import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentTz from 'moment-timezone';
import {
  withStyles,
  Dialog,
  Tab,
  Tabs,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Icon,
  Grid,
  TextField,
} from '@material-ui/core';
import { MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING, MOMENT_DATE_LONG_FORMAT_STRING, FLAG_REEFER_CONTAINER } from '../../constant';
import { LoadingIndicator } from '../../component';
import LocalizedString from '../../localization';
import ReeferDataTable from './reefer-data.container';
import TruckingContainerInfo from './trucking.container';

const styles = theme => ({
  dialogContent: {
    marginTop: '24px',
  },
  tabIndicator: {
    display: 'none',
  },
  tabRoot: {
    marginTop: theme.spacing.unit,
    overflow: 'visible',
  },
  tabElement: {
    backgroundColor: 'rgba(200, 200, 200, 0.2)',
    textTransform: 'capitalize',
  },
  tabElementActive: {
    backgroundColor: '#3f51b5',
    color: '#ffffff !important',
    transform: 'scaleY(1.1)',
    borderRadius: '4px',
    boxShadow: '0px 4px 20px rgba(0, 102, 203, 0.45)',
  },
  tabScroller: {
    overflowX: 'visible',
  },
});

const renderContainerDetail = (classes, containerInfo) => (
  <>
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <TextField
          readOnly
          fullWidth
          value={
            containerInfo && containerInfo.bcDate ? `${containerInfo.bcNo} and ${moment(containerInfo.gateOutWh).format(MOMENT_DATE_LONG_FORMAT_STRING)}` : '-'
          }
          label={LocalizedString.containerTracking.lblContainerBcNoAndDate}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          readOnly
          fullWidth
          value={
            containerInfo && containerInfo.bcPos ? containerInfo.bcPos : '-'
          }
          label={LocalizedString.containerTracking.lblContainerBcPos}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          readOnly
          fullWidth
          value={
            containerInfo && containerInfo.vessel ? containerInfo.vessel : '-'
          }
          label={LocalizedString.containerTracking.lblContainerVesselName}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          readOnly
          fullWidth
          value={
            containerInfo && containerInfo.voyage ? containerInfo.voyage : '-'
          }
          label={LocalizedString.containerTracking.lblContainerVoyageNo}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          readOnly
          fullWidth
          value={
            containerInfo && containerInfo.ctrSize ? containerInfo.ctrSize : '-'
          }
          label={LocalizedString.containerTracking.lblContainerSize}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          readOnly
          fullWidth
          value={
            containerInfo && containerInfo.ctrType ? containerInfo.ctrType : '-'
          }
          label={LocalizedString.containerTracking.lblContainerType}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          readOnly
          fullWidth
          value={
            containerInfo && containerInfo.toid ? containerInfo.toid : '-'
          }
          label={LocalizedString.containerTracking.lblContainerSeaPort}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          readOnly
          fullWidth
          value={
            containerInfo && containerInfo.gateOutTo ? moment(containerInfo.gateOutTo).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING) : '-'
          }
          label={LocalizedString.containerTracking.lblContainerGateOutSeaPort}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          readOnly
          fullWidth
          value={
            containerInfo && containerInfo.gateInCdp ? moment(containerInfo.gateInCdp).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING) : '-'
          }
          label={LocalizedString.containerTracking.lblContainerGateInCDP}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          readOnly
          fullWidth
          value={
            containerInfo && containerInfo.gateOutCdp
              ? momentTz(containerInfo.gateOutCdp)
                .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)
              : '-'
          }
          label={LocalizedString.containerTracking.lblContainerPickUpByConsignee}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          readOnly
          fullWidth
          value={
            containerInfo && containerInfo.dt ? containerInfo.dt : '-'
          }
          label={LocalizedString.containerTracking.lblContainerDwellingTime}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          readOnly
          fullWidth
          value={
            containerInfo && containerInfo.shipper ? containerInfo.shipper : '-'
          }
          label={LocalizedString.containerTracking.lblContainerShipper}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          readOnly
          fullWidth
          value={
            containerInfo && containerInfo.etd ? containerInfo.etd : '-'
          }
          label={LocalizedString.containerTracking.lblContainerEta}
        />
      </Grid>
    </Grid>
  </>
);

const ContainerDetailDialog = ({
  classes,
  open,
  downloadingReeferData,
  isFeederServiceAvailable,
  containerInfo,
  selectedTab,
  onClose,
  onTabPressed,
  onAppear,
}) => (
  <>
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onEnter={() => onAppear(containerInfo.blNo, containerInfo.containerNo)}
    >
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
          <Typography color="primary" variant="h6">
            {LocalizedString.containerTracking.lblTitleContainerNo}
            &nbsp;
            {containerInfo.containerNo}
          </Typography>
          <IconButton
            onClick={onClose}
          >
            <Icon>close</Icon>
          </IconButton>
        </div>

        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          classes={{
            root: classes.tabRoot,
            indicator: classes.tabIndicator,
            scroller: classes.tabScroller,
          }}
          fullWidth
          centered
        >
          <Tab
            value={0}
            label="Detail"
            classes={{
              root: classes.tabElement,
              selected: classes.tabElementActive,
            }}
            onClick={() => onTabPressed(0)}
          />
          {(containerInfo && containerInfo.ctrType === FLAG_REEFER_CONTAINER) && (
            <Tab
              value={1}
              label="Reefer Data"
              classes={{
                root: classes.tabElement,
                selected: classes.tabElementActive,
              }}
              onClick={() => onTabPressed(1)}
            />
          )}
          {isFeederServiceAvailable && (
            <Tab
              value={2}
              label="Trucking"
              classes={{
                root: classes.tabElement,
                selected: classes.tabElementActive,
              }}
              onClick={() => onTabPressed(2)}
            />
          )}
        </Tabs>
      </DialogTitle>

      <DialogContent>
        <div className={classes.dialogContent}>
          {selectedTab === 0 && renderContainerDetail(classes, containerInfo)}
          {selectedTab === 1 && <ReeferDataTable />}
          {selectedTab === 2 && <TruckingContainerInfo />}
        </div>
      </DialogContent>
    </Dialog>
    {downloadingReeferData && <LoadingIndicator />}
  </>
);
ContainerDetailDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  open: PropTypes.bool.isRequired,
  downloadingReeferData: PropTypes.bool.isRequired,
  isFeederServiceAvailable: PropTypes.bool.isRequired,
  containerInfo: PropTypes.shape(PropTypes.any).isRequired,
  selectedTab: PropTypes.number.isRequired,
  onAppear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onTabPressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(ContainerDetailDialog);
