import { ADD_EXPORT_DG_CLASS_LIST } from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case ADD_EXPORT_DG_CLASS_LIST:
      return { ...action.dgClassList };
    default:
      return state;
  }
};
