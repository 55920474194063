import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import AddUserDialog from './add-user-dialog.presentation';
import * as Action from '../../redux/action';
import * as Constant from '../../constant';

const mapStateToProps = (state) => {
  const selector = formValueSelector(Constant.RXFORM_ADD_USER);

  return {
    isLoading: state.uiUserManagement.isDialogLoading,
    isSaving: state.uiUserManagement.isSaving,
    initialValues: {
      email: '',
      username: '',
      role: null,
      companyId: null,
      corporateAdmin: false,
    },
    vendors: state.vendors,
    roles: [
      { label: 'Admin', value: 'ADMIN' },
      { label: 'Customer', value: 'CUSTOMER' },
      { label: 'PPJK', value: 'PPJK' },
      { label: 'CS', value: 'CS' },
    ],
    rxRole: selector(state, 'role'),
  };
};

const mapDispatchToProps = dispatch => ({
  onAppear: async () => {
    try {
      dispatch(Action.clearUIError(Constant.RXSTATE_USER_MANAGEMENT));
      await dispatch(Action.downloadAllVendorAsync());
    } catch (error) {
      dispatch(Action.setUIError(Constant.RXSTATE_USER_MANAGEMENT, error.message));
    }
  },
  onSaveButtonPressed: async (values) => {
    try {
      const formValues = { ...values };
      if (formValues.roles === Constant.ROLE_ADMIN) {
        formValues.companyId = undefined;
      }

      dispatch(Action.clearUIError(Constant.RXSTATE_USER_MANAGEMENT));
      dispatch(Action.addUserAsync(formValues));
    } catch (error) {
      dispatch(Action.setUIError(Constant.RXSTATE_USER_MANAGEMENT, error.message));
    }
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AddUserDialog),
);
