import { connect } from 'react-redux';
import _ from 'lodash';
import TruckingBlCard from './bl-card.presentation';
import { setTappedFeederServiceBlNo, downloadFeederServiceOrderDetailAsync } from '../../redux/action';

const getFilteredFeederService = state => _.values(state.feederServiceOrderHistories)
  .filter(item => item.refNo.toUpperCase()
    .indexOf(state.uiFeederService.searchBarText.toUpperCase()) >= 0);

const mapStateToProps = state => ({
  bl: getFilteredFeederService(state),
  selectedBlNo: state.uiFeederService.tappedBlNo,
});

const mapDispatchToProps = dispatch => ({
  onMorePress: (tappedBlNo) => {
    try {
      dispatch(setTappedFeederServiceBlNo(tappedBlNo));
      dispatch(downloadFeederServiceOrderDetailAsync(tappedBlNo));
    } catch (error) {
      // dispatch(setUIError(Constant.RXSTATE_FEEDER_SERVICE, error.message));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TruckingBlCard);
