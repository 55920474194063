import React from 'react';
import classNames from 'classnames';
import {
  withStyles, ButtonBase, Grid, Typography,
} from '@material-ui/core';
import * as Constant from '../../constant';
import { toMoment } from '../../helper';

const styles = theme => ({
  cardItem: {
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '6px',
    boxSizing: 'border-box',
    padding: '16px',
    marginBottom: theme.spacing.unit,
    '&:last-child': {
      marginBottom: 0,
    },
    '&:after': {
      content: '',
      position: 'absolute',
      bottom: '0px',
      left: 0,
      width: '100%',
      height: '10px',
      backgroundColor: '#f4f4f4',
    },
  },
  cardItemActive: {
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  spacer: {
    height: '8px',
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

const ContainerTrackingBlCard = ({
  classes,
  billingLines,
  selectedBlNo,
  onMorePress,
}) => (
  billingLines.map((item, index) => (
    <div>
      <ButtonBase
        component="div"
        key={item.blNo}
        className={classNames(classes.cardItem, {
          [classes.cardItemActive]: selectedBlNo && item.blNo === selectedBlNo,
        })}
        onClick={() => onMorePress(item.blNo)}
      >
        <Grid container spacing={24}>
          <Grid item xs={12} md={12}>
            <Typography color="primary" variant="h6" className={classes.ellipsis}>
            BL No.
            &nbsp;
              {item.blNo}
            </Typography>
          </Grid>
          <Grid item xs={8} md={8}>
            <Typography variant="caption">Gate In CDP</Typography>
            <Typography className={classes.ellipsis}>
              {toMoment(item.gateInCdp).format(Constant.MOMENT_DATE_FORMAT_STRING)}
            </Typography>
          </Grid>
          <Grid item xs={4} md={4} className={classes.alignRight}>
            <Typography variant="caption">Service</Typography>
            <Typography className={classNames(classes.capitalize, classes.ellipsis)}>
              {item.exim}
            </Typography>
          </Grid>
          <Grid item xs={8} md={8}>
            <Typography variant="caption">Vessel-voyage</Typography>
            <Typography className={classes.ellipsis}>{`${item.vessel} - ${item.voyage}`}</Typography>
          </Grid>
          <Grid item xs={4} md={4} className={classes.alignRight}>
            <Typography variant="caption">Party</Typography>
            <Typography className={classes.ellipsis}>{item.total}</Typography>
          </Grid>
          <Grid item xs={8} md={8}>
            <Typography variant="caption">Aju No.</Typography>
            <Typography className={classes.ellipsis}>{item.ajuNo}</Typography>
          </Grid>
        </Grid>
      </ButtonBase>
      <div className={classNames({
        [classes.spacer]: index !== billingLines.length - 1,
      })}
      />
    </div>
  ))
);

export default withStyles(styles)(ContainerTrackingBlCard);
