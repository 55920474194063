import { downloadingNewDocumentMyForms, setNewDocumentMyForms } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;

  try {
    dispatch(downloadingNewDocumentMyForms(true));
    const data = await Helper.downloadNewDocumentMyForms(token);
    dispatch(setNewDocumentMyForms(data));
  } finally {
    dispatch(downloadingNewDocumentMyForms(false));
  }
};
