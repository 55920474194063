import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { change } from 'redux-form';
import ResponseFilterDialog from './response-filter-dialog.presentation';
import {
  showErrorSnackbar,
  setSelectedStatusResponseFilter,
  setSelectedUserResponseFilter,
  downloadAllProfilesAsync,
  showResponseFilterDialog,
  setResponseFilterUserSearchText,
  setTempSelectedStatusResponseFilter,
  setTempSelectedUserResponseFilter,
  downloadFormResponseFilterStatusesAsync,
} from '../../redux/action';
import {
  RXFIELD_FORM_RESPONSE_DETAIL_STATUS, RXFIELD_FORM_RESPONSE_DETAIL_USER,
  RXFORM_RESPONSE_FILTER_DIALOG,
} from '../../constant';
import LocalizedString from '../../localization';
import downloadFormResponsesAsync from '../../redux/action/async/downloadFormResponsesAsync';
import { sortAsc } from '../../helper';

const getUsers = (state) => {
  const { users } = state;
  const data = Object.values(users)
    .map(x => ({
      label: `${x.fullName} (${x.company.id})`,
      value: x.id,
    }))
    .sort((a, b) => sortAsc(a.fullName, b.fullName));
  return data;
};

const getStatuses = (state) => {
  const { formResponseFilterStatuses: filterStatuses } = state;
  const data = filterStatuses.length > 0 ? filterStatuses.map(x => ({
    label: x,
    value: x.replace(/ +/g, ''),
  })) : [];
  return data;
};

const getInitialValues = (state) => {
  const {
    tempSelectedStatus, tempSelectedUser,
    selectedStatus, selectedUser,
  } = state.uiFormResponse;
  const status = tempSelectedStatus || selectedStatus;
  const user = tempSelectedUser || selectedUser;

  return ({
    [RXFIELD_FORM_RESPONSE_DETAIL_USER]: user,
    [RXFIELD_FORM_RESPONSE_DETAIL_STATUS]: status,
  });
};

const mapStateToProps = state => ({
  downloadingUser: state.uiMasterUser.downloading,
  downloadingStatus: state.uiFormResponse.downloadingResponseFilterStatuses,

  users: getUsers(state),
  statuses: getStatuses(state),
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = dispatch => ({
  onAppear: async () => {
    try {
      Promise.all([
        dispatch(downloadAllProfilesAsync()),
        dispatch(downloadFormResponseFilterStatusesAsync()),
      ]);
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    }
  },
  onUserTextChanged: async (text) => {
    dispatch(setResponseFilterUserSearchText(text));
    const result = await dispatch(downloadAllProfilesAsync())
      .catch(error => showErrorSnackbar(error));
    return result;
  },
  onSubmitPressed: async (values) => {
    dispatch(showResponseFilterDialog(false));
    dispatch(setSelectedStatusResponseFilter(values[RXFIELD_FORM_RESPONSE_DETAIL_STATUS]));
    dispatch(setSelectedUserResponseFilter(values[RXFIELD_FORM_RESPONSE_DETAIL_USER]));
    dispatch(setTempSelectedStatusResponseFilter(''));
    dispatch(setTempSelectedUserResponseFilter(''));
    try {
      await dispatch(downloadFormResponsesAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    }
  },
  onStatusSelected: (value) => {
    dispatch(setTempSelectedStatusResponseFilter(value));
    dispatch(change(RXFORM_RESPONSE_FILTER_DIALOG, RXFIELD_FORM_RESPONSE_DETAIL_STATUS, value));
  },
  onUserSelected: (value) => {
    dispatch(setTempSelectedUserResponseFilter(value));
    dispatch(change(RXFORM_RESPONSE_FILTER_DIALOG, RXFIELD_FORM_RESPONSE_DETAIL_USER, value));
  },
  onResetPressed: async () => {
    dispatch(showResponseFilterDialog(false));
    dispatch(change(RXFORM_RESPONSE_FILTER_DIALOG, RXFIELD_FORM_RESPONSE_DETAIL_STATUS, ''));
    dispatch(change(RXFORM_RESPONSE_FILTER_DIALOG, RXFIELD_FORM_RESPONSE_DETAIL_USER, ''));
    dispatch(setResponseFilterUserSearchText(''));
    dispatch(setSelectedStatusResponseFilter(''));
    dispatch(setSelectedUserResponseFilter(''));
    dispatch(setTempSelectedStatusResponseFilter(''));
    dispatch(setTempSelectedUserResponseFilter(''));
    try {
      await dispatch(downloadFormResponsesAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    }
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResponseFilterDialog));
