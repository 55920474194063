import { addVoyageDetail } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { selectedVessel, selectedVoyage } = getState().uiBookExportFeeder;

  if (selectedVessel !== '') {
    const voyageDetail = await Helper.downloadVoyageDetail(selectedVessel, selectedVoyage, token);
    dispatch(addVoyageDetail(voyageDetail));
    return voyageDetail;
  }
};
