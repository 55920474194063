import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  Typography,
  Icon,
} from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import { LoadingIndicator, RoundedButton } from '../../component';
import LocalizedString from '../../localization';
import {
  RXFIELD_MASTER_TRUCK_NO,
  RXFIELD_MASTER_TRUCK_RFID,
  RXFIELD_MASTER_TRUCK_LICENSE_PLATE_NO,
  RXFIELD_MASTER_TRUCK_CID,
  RXFIELD_MASTER_TRUCK_VENDOR,
  RXFIELD_MASTER_TRUCK_WEIGHT,
  RXFORM_MASTER_TRUCK_ADD,
} from '../../constant';
import {
  renderReduxFormTextField,
  renderReduxFormFilterableSelectField,
} from '../../helper';

const styles = () => ({
  fontWeightBold: {
    fontWeight: 'bold',
  },
});

const TruckAddDialog = ({
  classes,
  open,
  loading,
  vendors,

  initialize,
  handleSubmit,
  onSubmitPressed,
  onAppear,
  onClose,
}) => {
  const handleOnDialogEnter = () => {
    initialize();
    onAppear();
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onEnter={() => handleOnDialogEnter()}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
            }}
          >
            <Typography color="primary" variant="h6">
              {LocalizedString.masterTruck.btnAddNew}
            </Typography>
            <IconButton onClick={onClose}>
              <Icon>close</Icon>
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit(onSubmitPressed)}>
            <Field
              name={RXFIELD_MASTER_TRUCK_NO}
              component={renderReduxFormTextField}
              label={LocalizedString.masterTruck.labelTruckNo}
            />
            <Field
              name={RXFIELD_MASTER_TRUCK_RFID}
              component={renderReduxFormTextField}
              label={LocalizedString.masterTruck.labelRfid}
            />
            <Field
              name={RXFIELD_MASTER_TRUCK_LICENSE_PLATE_NO}
              component={renderReduxFormTextField}
              label={LocalizedString.masterTruck.labelPlateNumber}
            />
            <Field
              name={RXFIELD_MASTER_TRUCK_VENDOR}
              component={renderReduxFormFilterableSelectField}
              options={vendors}
              label={LocalizedString.masterTruck.labelVendor}
            />
            <Field
              name={RXFIELD_MASTER_TRUCK_CID}
              component={renderReduxFormTextField}
              label={LocalizedString.masterTruck.labelCid}
            />
            <Field
              name={RXFIELD_MASTER_TRUCK_WEIGHT}
              component={renderReduxFormTextField}
              label={LocalizedString.masterTruck.labelWeight}
            />

            <div style={{ textAlign: 'center', padding: '16px' }}>
              <RoundedButton type="submit" disabled={loading}>
                Save
              </RoundedButton>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      {loading && <LoadingIndicator />}
    </>
  );
};
TruckAddDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  vendors: PropTypes.arrayOf(PropTypes.any).isRequired,

  initialize: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
};

export default reduxForm({
  form: RXFORM_MASTER_TRUCK_ADD,
  enableReinitialize: true,
})(withStyles(styles)(TruckAddDialog));
