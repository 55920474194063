import React, { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  withStyles,
  Typography,
  Icon,
  List,
  ListItem,
  Card,
  CardContent,
  Grid,
  CssBaseline,
  Divider,
  ListItemIcon,
  ListItemText,
  Button,
  ButtonBase,
} from '@material-ui/core';
import * as Constant from '../../constant';
import { CurrentProfilePicture, PageHeaderUser } from '../../component';
import LocalizedString from '../../localization';
import { toCurrency } from '../../helper';
import { MainMenuShape, MoneyLoanInfoShape } from '../../type';
import MainMenu from './main-menu.container';

const styles = theme => ({
  buttonWhite: {
    backgroundColor: '#ffffff',
    textTransform: 'capitalize',
  },
  cardProfileAction: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing.unit * 2,
  },
  cardProfileHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${Constant.DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  currentProfilePicture: {
    margin: `${theme.spacing.unit * 4}px auto 4px`,
  },
  profilePicture: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit,
    width: 96,
    height: 96,
  },
  root: {
    width: '100%',
  },
  rowBalance: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textColorLink: {
    color: '#0066cb',
  },
  userRole: {
    textTransform: 'uppercase',
  },
  depositIcon: {
    display: 'flex',
    backgroundColor: Constant.COLOR_ACCENT,
    width: 16,
    height: 16,
    borderRadius: 8,
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  loanIcon: {
    display: 'flex',
    backgroundColor: Constant.COLOR_PAST_DUE_CIRCLE,
    width: 16,
    height: 16,
    borderRadius: 8,
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
});

const DashboardUserPage = (props) => {
  const {
    classes,
    currentUser,
    userCompany,
    notificationOpen,
    menuItems,
    showAllMenu,
    onToggleMainMenu,
    onAppear,
    moneyLoanInfo,
  } = props;

  useEffect(() => onAppear(), []);

  const renderDepositBalance = () => {
    const { depositAccount } = currentUser.company;
    if (depositAccount) {
      return (
        <>
          <ButtonBase
            style={{ width: '100%' }}
            component={Link}
            to={Constant.ROUTE_NAME_DEPOSIT_HISTORY}
          >
            <ListItem>
              <ListItemIcon><Icon>account_balance_wallet</Icon></ListItemIcon>
              <ListItemText>
                {moneyLoanInfo.status === Constant.MONEY_LOAN_STATUS_NOT_APPLICABLE ? (
                  <div>{`IDR ${toCurrency(depositAccount.balance || 0)}`}</div>
                ) : (
                  <>
                    <div className={classes.row}>
                      <div className={classes.depositIcon}>+</div>
                      <div>{`IDR ${toCurrency(depositAccount.balance || 0)}`}</div>
                    </div>
                    {moneyLoanInfo.status !== Constant.MONEY_LOAN_STATUS_NOT_APPLICABLE && (
                    <div className={classes.row}>
                      <div className={classes.loanIcon}>-</div>
                      <div>{`IDR ${toCurrency(moneyLoanInfo.loan) || 0}`}</div>
                    </div>
                    )}
                  </>
                )}
              </ListItemText>
            </ListItem>
          </ButtonBase>
          <Divider />
        </>
      );
    }
    return (<></>);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      <PageHeaderUser alwaysOpen />

      <div
        className={
          classNames(classes.content, {
            [classes.contentDrawerOpen]: notificationOpen,
            [classes.contentDrawerClosed]: !notificationOpen,
          })
        }
      >
        <div style={{ padding: '16px' }}>
          <Grid container spacing={24}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <div className={classes.cardProfileHeader}>
                    <CurrentProfilePicture
                      className={classes.profilePicture}
                    />
                    <Typography variant="subtitle1" className={classes.textColorLink}>{currentUser.fullName}</Typography>
                    <Typography variant="body2" className={classes.userRole}>{userCompany.role}</Typography>
                  </div>
                  <div>
                    <List>
                      {renderDepositBalance()}
                      <ListItem>
                        <ListItemIcon><Icon>location_city</Icon></ListItemIcon>
                        <ListItemText>{currentUser.company.name}</ListItemText>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemIcon><Icon>place</Icon></ListItemIcon>
                        <ListItemText>{currentUser.company.address}</ListItemText>
                      </ListItem>
                      <Divider />
                    </List>
                  </div>
                  <div className={classes.cardProfileAction}>
                    <Button
                      color="primary"
                      variant="outlined"
                      size="large"
                      className={classes.buttonWhite}
                      component={Link}
                      to={Constant.ROUTE_NAME_PROFILE}
                    >
                      {LocalizedString.dashboardUser.buttonViewProfile}
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              <MainMenu
                items={menuItems}
                onToggleMainMenu={onToggleMainMenu}
                showAllMenu={showAllMenu}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

DashboardUserPage.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  currentUser: PropTypes.shape(PropTypes.any).isRequired,
  userCompany: PropTypes.shape(PropTypes.any).isRequired,
  notificationOpen: PropTypes.bool.isRequired,
  menuItems: MainMenuShape.isRequired,
  showAllMenu: PropTypes.bool.isRequired,
  moneyLoanInfo: MoneyLoanInfoShape,

  onAppear: PropTypes.func.isRequired,
  onToggleMainMenu: PropTypes.func.isRequired,
};

DashboardUserPage.defaultProps = {
  moneyLoanInfo: {},
};

export default withStyles(styles)(DashboardUserPage);
