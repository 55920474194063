import { connect } from 'react-redux';
import { readFile } from '../../helper';
import ImageInputField from './file-picker-field.presentation';

const mapStateToProps = state => ({
  state,
});

const mapDispatchToProps = () => ({
  onFileChange: async (e, onFileSelected) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const dataUrl = await readFile(file);
      const url = dataUrl.replace(/^.+base64,/, '');
      onFileSelected(url, file.name);
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageInputField);
