import { connect } from 'react-redux';
import _ from 'lodash';
import TruckingBaseScreen from './trucking-history.presentation';
import {
  downloadFeederServiceOrderHistoriesAsync,
  setUIError,
  setFeederServiceSort,
  setFeederServicePaging,
  setFeederServiceFilter,
  setFeederServiceSearchText,
  setFeederServiceDetailTappedContainerNo,
  setNewBookingDialogStatus,
  clearUIError,
  downloadMyPaymentTermOrderAsync,
  addToTermOrderTruckingAsync,
  setFeederServicePasswordTextInput,
  setFeederServicePasswordInputDialog,
} from '../../redux/action';
import * as Constant from '../../constant';
import addToShoppingCartTruckingAsync from '../../redux/action/async/addToShoppingCartTruckingAsync';

const getFilteredFeederServiceDetail = (state) => {
  const containers = _.values(state.feederServiceOrderDetail)
    .filter(item => item.blNo === state.uiFeederService.tappedBlNo);
  const {
    filters, sortField, sortDirection,
  } = state.uiFeederServiceDetail;

  return containers.sort((left, right) => {
    if (typeof left[sortField] === 'string') {
      if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
        return left[sortField].localeCompare(right[sortField]);
      }
      return right[sortField].localeCompare(left[sortField]);
    }

    if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
      return left[sortField] - right[sortField];
    }
    return right[sortField] - left[sortField];
  })
    .filter(item => item.containerNo.toUpperCase().indexOf(
      filters[Constant.TABLE_FIELD_CONTAINER_NUMBER].toUpperCase(),
    ) >= 0)
    .filter(item => item.sealNo.toUpperCase().indexOf(
      filters[Constant.TABLE_FIELD_SEAL_NUMBER].toUpperCase(),
    ) >= 0)
    .filter(item => item.statusHaulage.toUpperCase().indexOf(
      filters[Constant.TABLE_FIELD_STATUS_HAULAGE].toUpperCase(),
    ) >= 0);
};

const getPageFilteredFeederServiceDetail = (state) => {
  const {
    activePage, pageSize,
  } = state.uiFeederServiceDetail;
  const filteredFeederServiceDetail = getFilteredFeederServiceDetail(state);

  if (filteredFeederServiceDetail) {
    const refinedActivePage = activePage || 1;
    const start = Math.min((refinedActivePage - 1) * pageSize,
      filteredFeederServiceDetail.length);
    const end = Math.min(start + pageSize, filteredFeederServiceDetail.length);
    return filteredFeederServiceDetail.slice(start, end);
  }

  return undefined;
};

const getTotalPage = (state) => {
  const feederServiceDetail = getFilteredFeederServiceDetail(state);
  const { pageSize } = state.uiFeederServiceDetail;
  const totalData = feederServiceDetail ? feederServiceDetail.length : 0;

  return Math.max(Math.ceil(totalData / pageSize), 1);
};

const mapStateToProps = state => ({
  menuOpen: state.uiMenu.menuOpen,
  downloadingFeederService:
    state.uiFeederService.downloadingFeederServiceOrderHistories,
  downloadingFeederServiceDetail:
    state.uiFeederServiceDetail.downloading,

  userCompany: state.currentUser.company,
  selectedBlInfo: state.feederServiceOrderHistories[state.uiFeederService.tappedBlNo],
  selectedContainerNo: state.uiFeederServiceDetail.tappedContainerNo,
  feederServiceDetail: getPageFilteredFeederServiceDetail(state),
  searchBarText: state.uiFeederService.searchBarText,

  pageSize: state.uiFeederServiceDetail.pageSize,
  totalPage: getTotalPage(state),
  activePage: state.uiFeederServiceDetail.activePage,
  filters: state.uiFeederServiceDetail.filters,
  sortField: state.uiFeederServiceDetail.sortField,
  sortDirection: state.uiFeederServiceDetail.sortDirection,
});

const mapDispatchToProps = dispatch => ({
  onAppear: () => {
    Promise.all([
      dispatch(downloadFeederServiceOrderHistoriesAsync()), // change to admin
    ])
      .catch(error => dispatch(setUIError(Constant.RXSTATE_FEEDER_SERVICE, error.message)));
  },
  onChangeSearchBarText: (text) => {
    dispatch(setFeederServiceSearchText(text));
  },
  onChangeFilter: (filterName, value) => {
    dispatch(setFeederServiceFilter(filterName, value));
  },
  onChangePaging: (pageField, value) => {
    dispatch(setFeederServicePaging(pageField, value));
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setFeederServiceSort(sortField, sortDirection));
  },
  onDetailPressed: (tappedContainerNo) => {
    dispatch(setFeederServiceDetailTappedContainerNo(tappedContainerNo));
  },
  onCloseDetailDialogPressed: () => {
    dispatch(setFeederServiceDetailTappedContainerNo(''));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TruckingBaseScreen);
