import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, Button } from '@material-ui/core';
import LocalizedString from '../../localization';
import { toCurrency } from '../../helper';

const styles = () => ({
  btnInquiries: {
    backgroundColor: '#5CB860',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#558b2f',
    },
  },
  dialogActions: {
    margin: '0 -24px',
    marginTop: '24px',
    padding: 24,
    borderTop: '1px solid #C4C4C4',
    justifyContent: 'space-between',
    paddingBottom: 0,
    display: 'flex',
  },
  priceRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,

    '&:nth-of-type(odd)': {
      backgroundColor: '#F7F7F7',
    },
  },
  totalPrice: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
    alignItems: 'baseline',
  },
});

const PriceTab = ({
  classes,
  nilTotal,
  onPreviousPressed,
  onNextPressed,
}) => (
  <>
    <div className={classes.totalPrice}>
      <Typography>{LocalizedString.trucking.lblTotalPrice}</Typography>
      <Typography variant="h6" color="primary">
        Rp.&nbsp;
        {toCurrency(nilTotal)}
      </Typography>
    </div>

    <div className={classes.dialogActions}>
      <div>
        <Button
          type="button"
          color="primary"
          onClick={onPreviousPressed}
        >
          Previous
        </Button>
      </div>
      <div style={{ flexGrow: 1 }} />
      <div>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          classes={{
            containedPrimary: classes.btnInquiries,
          }}
          onClick={onNextPressed}
        >
          Inquiries
        </Button>
      </div>
    </div>
  </>


);
PriceTab.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  nilTotal: PropTypes.number.isRequired,
  onPreviousPressed: PropTypes.func.isRequired,
  onNextPressed: PropTypes.func.isRequired,
};
export default withStyles(styles)(PriceTab);
