import * as Helper from '../../../helper';
import { setDocSubmissionMyResponseDetail, downloadingDocSubmissionMyResponseDetail } from '../simple-action';

export default id => async (dispatch, getState) => {
  try {
    dispatch(downloadingDocSubmissionMyResponseDetail(true));
    const { token } = getState().authentication;
    const detail = await Helper.downloadDocSubmissionMyResponseDetail(id, token);
    dispatch(setDocSubmissionMyResponseDetail(detail));
  } finally {
    dispatch(downloadingDocSubmissionMyResponseDetail(false));
  }
};
