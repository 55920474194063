import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  Typography,
  Icon,
  Button,
} from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import { LoadingIndicator } from '../../component';
import LocalizedString from '../../localization';
import {
  COLOR_PRIMARY,
  DEFAULT_STYLES,
  RXFIELD_FIELD_FORM,
  RXFORM_FIELD_FILTER,
} from '../../constant';
import {
  renderReduxFormFilterableSelectAsyncField,
} from '../../helper';

const styles = () => ({
  btnPrimary: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  dialogActions: {
    margin: '0 -24px',
    marginTop: '24px',
    padding: 24,
    borderTop: '1px solid #C4C4C4',
    justifyContent: 'space-between',
    paddingBottom: 0,
    display: 'flex',
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  reject: {
    color: COLOR_PRIMARY,
  },
});

const FieldFilterDialog = ({
  classes,
  open,
  loading,
  currentFormRole,
  downloadingForm,
  forms,

  onFormSelected,
  handleSubmit,
  onSubmitPressed,
  onAppear,
  onClose,
  onFormTextChanged,
  onResetPressed,
}) => (
  <>
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onEnter={() => onAppear(currentFormRole)}
    >
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <Typography color="primary" variant="h6">
            {LocalizedString.response.labelFilter}
          </Typography>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit(values => onSubmitPressed(values))}>
          <Field
            name={RXFIELD_FIELD_FORM}
            component={renderReduxFormFilterableSelectAsyncField}
            options={forms}
            label={LocalizedString.field.labelForm}
            onValueChange={onFormSelected}
            disabled={downloadingForm}
            onLazyLoad={onFormTextChanged}
          />
          <div className={classes.dialogActions}>
            <div style={{ flexGrow: 1 }} />
            <div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.btnPrimary}
                disabled={loading}
              >
                Apply
              </Button>
              <Button variant="text" className={classes.reject} onClick={onResetPressed}>
                {LocalizedString.response.labelReset}
              </Button>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
    {loading && <LoadingIndicator />}
  </>
);
FieldFilterDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  open: PropTypes.bool.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onFormSelected: PropTypes.func.isRequired,
  onFormTextChanged: PropTypes.func.isRequired,
};

export default reduxForm({
  form: RXFORM_FIELD_FILTER,
  enableReinitialize: true,
})(withStyles(styles)(FieldFilterDialog));
