import { addTruckList, searchingTruckList } from '../simple-action';
import * as Helper from '../../../helper';

export default text => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const vendorId = getState().currentUser.company.id;

  try {
    dispatch(searchingTruckList(true));
    const truckList = await Helper.downloadTruckList(token, vendorId, text);
    dispatch(addTruckList(truckList));
  } finally {
    dispatch(searchingTruckList(false));
  }
};
