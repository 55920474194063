import * as Action from '../action';

const initialState = {
  downloading: false,
  downloadingDetail: false,
  downloadingFile: false,
  tappedId: '',
  searchBarText: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.DOWNLOADING_DOC_SUBMISSION_MY_RESPONSES:
      return { ...state, downloading: action.status };
    case Action.DOWNLOADING_DOC_SUBMISSION_MY_RESPONSE_DETAIL:
      return { ...state, downloadingDetail: action.status };
    case Action.DOWNLOADING_DOC_SUBMISSION_MY_RESPONSE_DETAIL_FILE:
      return { ...state, downloadingDetail: action.status };
    case Action.SET_DOC_SUBMISSION_MY_RESPONSE_TAPPED_ID:
      return { ...state, tappedId: action.value };
    case Action.SET_DOC_SUBMISSION_MY_RESPONSE_SEARCHBAR_TEXT:
      return { ...state, searchBarText: action.value };
    default:
      return state;
  }
};
