import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Icon from '@material-ui/core/Icon';
import {
  Drawer, Divider, List, ListItem, ListItemIcon, ListItemText, Collapse, Typography, withStyles,
} from '@material-ui/core';
import * as Constant from '../../constant';

const styles = theme => ({
  paper: {
    // backgroundImage: `url("${Constant.IMAGE_BACKGROUND_LOGIN}")`,
    // backgroundSize: 'cover',
    // backgroundColor: 'rgba(0,0,0,0.6)',
    // backgroundBlendMode: 'soft-light',
    color: 'white',
    width: Constant.DRAWER_WIDTH,
    // padding: '8px 16px',
    boxShadow: '0px 0 15px 0px silver',
  },
  menuItemRoot: {
    borderLeft: '4px solid #ffffff',
    // marginTop: '12px',
    padding: '16px 32px',
    color: '#000000',
    '&:hover': {
      backgroundColor: '#0066CB',
      color: '#ffffff',
    },
  },
  iconRoot: {
    marginRight: 0,
  },
  primaryTextColor: {
    color: 'black',
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  activeLink: {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: '#DFF1FF',
    borderColor: '#0066CB',
    color: '#000',
  },
  container: {
    borderRadius: '4px',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  rootMenu: {
    marginTop: '62px',
  },
});

class MainMenu extends Component {
  state = {};

  componentDidMount() {
  }

  componentWillReceiveProps() {
    // this.props.menuItems.forEach((menu) => {
    //   const includes = this.props.match.url.includes(menu.url);
    // });
  }

  onListItemClick = (menuId) => {
    this.setState(state => (
      {
        [menuId]: !state[menuId],
      }
    ));
  };

  navigateTo = (url) => {
    this.props.history.push(url);
  }

  renderMenuItems = (menuItem, isChild = false) => {
    const { classes } = this.props;
    if (menuItem.hasChild) {
      return (
        <div key={menuItem.menuId}>
          <ListItem
            button
            onClick={() => this.onListItemClick(menuItem.menuId)}
            classes={{
              root: this.props.match.url.includes(menuItem.url) ? classes.container : undefined,
            }}
          >
            {/* <ListItemIcon
              classes={{
                root: classes.iconRoot,
              }}
            >
              <Icon
                color="primary"
                classes={{
                  colorPrimary: classes.primaryTextColor,
                }}
              >
                {menuItem.iconName}

              </Icon>
            </ListItemIcon> */}
            <ListItemText>
              <Typography color="inherit">{menuItem.menuName}</Typography>
            </ListItemText>
            {this.state[menuItem.menuId] ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
          </ListItem>
          <Collapse in={this.state[menuItem.menuId]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {menuItem.children.map(menu => this.renderMenuItems(menu, true))}
            </List>
          </Collapse>
        </div>
      );
    }
    return (
      <div key={menuItem.menuId}>
        <ListItem
          button
          onClick={() => this.navigateTo(menuItem.url)}
          className={
              classNames(
                this.props.classes.menuItemRoot,
                isChild ? this.props.classes.nested : null,
                menuItem.url === this.props.match.url ? this.props.classes.activeLink : null,
              )}
        >
          {/* <ListItemIcon classes={{
            root: classes.iconRoot,
          }}
          >
            <Icon
              color="primary"
              classes={{
                colorPrimary: classes.primaryTextColor,
              }}
            >
              {menuItem.iconName}

            </Icon>
          </ListItemIcon> */}
          <ListItemText>
            <Typography color="inherit">
              {menuItem.menuName}
            </Typography>
          </ListItemText>
        </ListItem>
        <Divider />
      </div>
    );
  }

  render() {
    const { open, menuItems = [], classes } = this.props;

    return (
      <div>
        <nav>
          <Drawer
            variant="persistent"
            anchor="left"
            elevation={4}
            open={open}
            classes={{
              paper: classes.paper,
            }}
          >
            <div className={classes.rootMenu}>
              <List>
                {menuItems.map(menu => this.renderMenuItems(menu, false))}
              </List>
            </div>
          </Drawer>
        </nav>
      </div>
    );
  }
}

MainMenu.propTypes = {
  open: PropTypes.bool,
  history: PropTypes.shape(PropTypes.any),
  menuItems: PropTypes.arrayOf(PropTypes.any),
  match: PropTypes.shape(PropTypes.any),
  classes: PropTypes.shape(PropTypes.any),
};
MainMenu.defaultProps = {
  open: true,
  history: {},
  match: {},
  menuItems: [],
  classes: {},
};

export default withRouter(withStyles(styles)(MainMenu));
