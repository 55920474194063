import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import OnProgressDetail from './on-progress-detail.presentation';

const mapStateToProps = state => ({
  selectedData: state.trackingStatusBehandleOnProgresses[state.uiTrackingStatusBehandleOnProgress
    .tappedId],

});

const mapDispatchToProps = dispatch => ({
  onAppear: () => {},
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnProgressDetail));
