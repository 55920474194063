import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import * as Constant from '../constant';

const PrivateRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      authenticated === true
        ? <Component {...props} />
        : <Redirect to={Constant.ROUTE_NAME_LOGIN} />
    )}
  />
);

const mapStateToProps = state => ({
  authenticated: !!state.authentication.token,
});
export default connect(mapStateToProps)(PrivateRoute);
