import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField, Button } from '@material-ui/core';
import LocalizedString from '../../localization';
import { LoadingIndicator } from '../../component';

export default class EmailSubmission extends Component {
    state = {
      email: '',
    }

    onSubmitButtonClicked = () => {
      this.props.onSubmit(this.state.email);
    }

    render() {
      const { submitting } = this.props;
      return (
        <div>
          <h2>{LocalizedString.registerEmailScreen.title}</h2>

          <div>
            {LocalizedString.registerEmailScreen.description}
          </div>

          <div>

            <TextField
              label={LocalizedString.registerEmailScreen.labelEmail}
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value })}
              margin="normal"
            />
          </div>

          <Button variant="contained" color="primary" disabled={submitting} onClick={this.onSubmitButtonClicked}>
            {LocalizedString.common.buttonCaptionSubmit}
          </Button>
          {submitting && <LoadingIndicator />}
        </div>
      );
    }
}

EmailSubmission.propTypes = {
  submitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
