import {
  submittingRegisterPassword,
  addToken,
  removeToken,
  setUIError,
} from '../simple-action';
import { validateRegisterPassword, validateRegisterPin } from '../../../validation';
import downloadMyProfileAsync from './downloadMyProfileAsync';
import * as Constant from '../../../constant';
import * as Helper from '../../../helper';
import downloadProfilePictureAsync from './downloadProfilePictureAsync';
import LocalizedString from '../../../localization';

export default (userId, values, navigateTo) => async (dispatch, getState) => {
  try {
    dispatch(submittingRegisterPassword(true));
    validateRegisterPassword(values);

    if (values.pin) {
      validateRegisterPin(values);
    }

    let data = {
      id: userId,
      password: values.password,
    };

    if (values.pin) {
      data = {
        ...data,
        pin: values.pin,
      };
    }

    const auth = await Helper.uploadRegisterInitialPassword(data);

    dispatch(addToken(auth.token));

    await dispatch(downloadMyProfileAsync());

    const { id, role, corporateAdmin } = getState().currentUser;
    if (role === Constant.ROLE_ADMIN || corporateAdmin) {
      await dispatch(downloadProfilePictureAsync(id));

      // navigateTo(Constant.ROUTE_NAME_USER_MANAGEMENT);
      navigateTo(Constant.ROUTE_NAME_MASTER_USERS);
    } else if (role === Constant.ROLE_BILLING) {
      navigateTo(Constant.ROUTE_NAME_ADMIN_PAYMENT_HISTORY);
    } else if (role !== Constant.ROLE_ADMIN) {
      await dispatch(downloadProfilePictureAsync(id));

      navigateTo(Constant.ROUTE_NAME_DASHBOARD_USER);
    } else {
      dispatch(removeToken());
      dispatch(setUIError(Constant.RXSTATE_LOGIN_PAGE, LocalizedString.loginScreen.errNotAdmin));
    }
  } finally {
    dispatch(submittingRegisterPassword(false));
  }
};
