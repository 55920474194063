import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import ContainerTrackingBaseScreen from './container-tracking.presentation';
import * as Constant from '../../constant';
import {
  setContainerTrackingSearchText,
  setReeferDataDetailPaging,
  setReeferDataDetailFilter,
  setTappedContainerTrackingItemNo,
  setContainerTrackingFilter,
  setContainerTrackingPaging,
  setContainerTrackingSort,
  setTappedContainerTrackingBlNo,
  downloadContainerTrackingAsync,
  setChosenTariffSimulationDate,
  setSelectedTariffSimulationPaymentBehalfOf,
  addTariffSimulation,
  setDialogCalendarStatus,
  setTariffSimulationSearchText,
  setMyPaymentSelectedTab,
  setTappedTermBlNo,
  showErrorSnackbar,
  setTariffSimulationAction,
} from '../../redux/action';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';


const getBlByBlNo = (state, blNo) => state.containerTracking[blNo] || undefined;

const getFilteredContainer = (state, blNo) => {
  const activeBl = getBlByBlNo(state, blNo);
  const containers = _.values(state.containerTrackingDetail).map((item, index) => ({
    ...item,
    party: index + 1,
  }));
  const { filters, sortField, sortDirection } = state.uiContainerTracking;

  if (
    !activeBl
    || activeBl.exim
      .toUpperCase()
      .indexOf(filters[Constant.TABLE_FIELD_SERVICE].toUpperCase()) === -1
  ) {
    return [];
  }

  const filteredContainers = containers
    .sort((left, right) => {
      if (typeof left[sortField] === 'string') {
        if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
          return left[sortField].localeCompare(right[sortField]);
        }
        return right[sortField].localeCompare(left[sortField]);
      }

      if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
        return left[sortField] - right[sortField];
      }
      return right[sortField] - left[sortField];
    })
    .filter(
      item => item.containerNo.indexOf(
        filters[Constant.TABLE_FIELD_CONTAINER_NUMBER].toUpperCase(),
      ) >= 0,
    )
    .filter((item) => {
      if (filters[Constant.TABLE_FIELD_SIZE] !== '') {
        return (
          item.ctrSize === parseInt(filters[Constant.TABLE_FIELD_SIZE], 10)
        );
      }
      return true;
    })
    .filter((item) => {
      if (filters[Constant.TABLE_FIELD_GATE_IN_CDP]) {
        return (
          moment(item.gateInCdp).format(Constant.MOMENT_DATE_FORMAT_STRING)
          === moment(filters[Constant.TABLE_FIELD_GATE_IN_CDP]).format(
            Constant.MOMENT_DATE_FORMAT_STRING,
          )
        );
      }

      return true;
    });

  return filteredContainers;
};

const getPageFilteredContainer = (state, blNo) => {
  const { activePage, pageSize } = state.uiContainerTracking;
  const filteredContainers = getFilteredContainer(state, blNo);

  const refinedActivePage = activePage || 1;
  const start = Math.min(
    (refinedActivePage - 1) * pageSize,
    filteredContainers.length,
  );
  const end = Math.min(start + pageSize, filteredContainers.length);
  return filteredContainers.slice(start, end);
};

const getTotalPage = (state, blNo) => {
  const totalData = getFilteredContainer(state, blNo).length;
  const { pageSize } = state.uiContainerTracking;

  return Math.max(Math.ceil(totalData / pageSize), 1);
};

const mapStateToProps = state => ({
  notificationOpen: state.uiWebUserMain.notificationDrawerOpen,
  downloadingContainerTrackingsData:
    state.uiContainerTracking.downloadingContainerTrackingsData,
  selectedContainerNo: state.uiContainerTracking.tappedItemNo,
  userCompany: state.currentUser.company,
  shoppingCart: _.values(state.shoppingCart),
  termOrder: _.values(state.myTermOrder),

  pageSize: state.uiContainerTracking.pageSize,
  totalPage: getTotalPage(state, state.uiContainerTracking.tappedBlNo),
  activePage: state.uiContainerTracking.activePage,
  filters: state.uiContainerTracking.filters,
  sortField: state.uiContainerTracking.sortField,
  sortDirection: state.uiContainerTracking.sortDirection,
  activeBl: getBlByBlNo(state, state.uiContainerTracking.tappedBlNo),
  containerTracking: getPageFilteredContainer(
    state,
    state.uiContainerTracking.tappedBlNo,
  ),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    try {
      await dispatch(downloadContainerTrackingAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onDetailPress: (tappedContainerNo) => {
    dispatch(setTappedContainerTrackingItemNo(tappedContainerNo));
    // reset filters
    dispatch(setReeferDataDetailPaging(Constant.RXTABLEFIELD_ACTIVE_PAGE, 1));
    dispatch(setReeferDataDetailFilter(Constant.TABLE_FIELD_SHIFT_ID, ''));
    dispatch(setReeferDataDetailFilter(Constant.TABLE_FIELD_SHIFT_DATE, ''));
    dispatch(
      setReeferDataDetailFilter(Constant.TABLE_FIELD_SETTING_TEMPERATURE, ''),
    );
    dispatch(setReeferDataDetailFilter(Constant.TABLE_FIELD_AIR_SUPPLY, ''));
  },
  onChangeSearchBarText: (text) => {
    dispatch(setContainerTrackingSearchText(text));
    // refreshList(dispatch);
  },
  onChangeFilter: (filterName, value) => {
    dispatch(setContainerTrackingFilter(filterName, value));
  },
  onChangePaging: (pageField, value) => {
    dispatch(setContainerTrackingPaging(pageField, value));
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setContainerTrackingSort(sortField, sortDirection));
  },
  onCloseDetailDialogPressed: () => {
    dispatch(setTappedContainerTrackingItemNo(''));
  },
  onSp2ImportPressed: (tappedBlNo, cartItem, termItem) => {
    if (termItem) {
      dispatch(setMyPaymentSelectedTab(1));
      dispatch(setTappedTermBlNo(termItem.invoiceNo));
      ownProps.history.push(Constant.ROUTE_NAME_MY_PAYMENT);
    } else {
      dispatch(setTariffSimulationAction(Constant.TARIFF_SIMULATION_ACTION_NEXT));
      dispatch(setChosenTariffSimulationDate(''));
      dispatch(setSelectedTariffSimulationPaymentBehalfOf(''));
      dispatch(addTariffSimulation({}));
      dispatch(setTariffSimulationSearchText(''));
      dispatch(setTappedContainerTrackingBlNo(tappedBlNo));
      dispatch(setDialogCalendarStatus(true));
      ownProps.history.push(Constant.ROUTE_NAME_TARIFF_SIMULATION);
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContainerTrackingBaseScreen);
