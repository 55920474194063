import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  Typography,
  Icon,
} from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import { LoadingIndicator, RoundedButton } from '../../component';
import LocalizedString from '../../localization';
import {
  RXFIELD_MASTER_FORWARDER_ID,
  RXFIELD_MASTER_FORWARDER_NAME,
  RXFIELD_MASTER_FORWARDER_ADDR1,
  RXFIELD_MASTER_FORWARDER_NPWP,
  RXFIELD_MASTER_FORWARDER_CITY,
  RXFIELD_MASTER_FORWARDER_PROVINCE,
  RXFORM_MASTER_FORWARDER_ADD,
} from '../../constant';
import { renderReduxFormTextField, renderReduxFormTextArea } from '../../helper';

const styles = () => ({
  fontWeightBold: {
    fontWeight: 'bold',
  },
});

const ForwarderAddDialog = ({
  classes,
  open,
  loading,

  initialize,
  handleSubmit,
  onSubmitPressed,
  onAppear,
  onClose,
}) => {
  const handleOnDialogEnter = () => {
    initialize();
    onAppear();
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onEnter={() => handleOnDialogEnter()}
        className={classes.dialog}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
            }}
          >
            <Typography color="primary" variant="h6">
              {LocalizedString.masterForwarder.btnAddNew}
            </Typography>
            <IconButton onClick={onClose}>
              <Icon>close</Icon>
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit(onSubmitPressed)}>
            <Field
              name={RXFIELD_MASTER_FORWARDER_ID}
              component={renderReduxFormTextField}
              label={LocalizedString.masterForwarder.labelForwarderId}
            />
            <Field
              name={RXFIELD_MASTER_FORWARDER_NAME}
              component={renderReduxFormTextField}
              label={LocalizedString.masterForwarder.labelForwarderName}
            />
            <Field
              name={RXFIELD_MASTER_FORWARDER_ADDR1}
              component={renderReduxFormTextArea}
              label={LocalizedString.masterForwarder.labelForwarderAddr1}
            />
            <Field
              name={RXFIELD_MASTER_FORWARDER_NPWP}
              component={renderReduxFormTextField}
              label={LocalizedString.masterForwarder.labelNpwp}
            />
            <Field
              name={RXFIELD_MASTER_FORWARDER_CITY}
              component={renderReduxFormTextField}
              label={LocalizedString.masterForwarder.labelCity}
            />
            <Field
              name={RXFIELD_MASTER_FORWARDER_PROVINCE}
              component={renderReduxFormTextField}
              label={LocalizedString.masterForwarder.labelProvince}
            />

            <div style={{ textAlign: 'center', padding: '16px' }}>
              <RoundedButton type="submit" disabled={loading}>
                Save
              </RoundedButton>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      {loading && <LoadingIndicator />}
    </>
  );
};
ForwarderAddDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,

  initialize: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
};

export default reduxForm({
  form: RXFORM_MASTER_FORWARDER_ADD,
  enableReinitialize: true,
})(withStyles(styles)(ForwarderAddDialog));
