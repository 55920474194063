import React, { Component } from 'react';
import {
  Dialog, DialogTitle, Button, DialogContent, Typography, IconButton, Icon,
} from '@material-ui/core';
import AvatarEditor from 'react-avatar-editor';
import PropTypes from 'prop-types';
import LocalizedString from '../../localization';
import * as Helper from '../../helper';
import { DEFAULT_STYLES } from '../../constant';

const styles = {
  avatarContainer: {
    margin: 12,
  },
  btnPrimary: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
};

export default class ProfilePictureDialog extends Component {
    state = {
      image: null,
    }

    onImageChange = (event) => {
      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.setState({ image: e.target.result });
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    }

    onSave = async () => {
      if (this.editor) {
        // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
        // drawn on another canvas, or added to the DOM.
        // const canvas = this.editor.getImage();

        // If you want the image resized to the canvas size (also a HTMLCanvasElement)
        const canvas = this.editor.getImageScaledToCanvas().toDataURL();
        const response = await fetch(canvas);
        const blob = await response.blob();
        const base64Image = await Helper.convertBlobToBase64(blob);

        this.props.onSave(base64Image);
      }
    }

    setEditorRef = (editor) => { this.editor = editor; }

    render() {
      return (
        <Dialog
          onClose={this.handleDialogClose}
          open={this.props.open}
        >
          <DialogTitle>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
              }}
            >
              <Typography color="primary" variant="h6">
                {LocalizedString.profileScreen.dialogTitleSelectPicture}
              </Typography>
              <IconButton onClick={this.props.onCancel}>
                <Icon>close</Icon>
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <input type="file" accept="image/*" onChange={this.onImageChange} />
              <div style={styles.avatarContainer}>
                <AvatarEditor
                  ref={this.setEditorRef}
                  image={this.state.image}
                  width={200}
                  height={200}
                  borderRadius={100}
                  border={50}
                  color={[255, 255, 255, 0.6]} // RGBA
                  scale={1.2}
                  rotate={0}
                />
              </div>
              <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}>
                <Button color="primary" type="button" onClick={this.props.onCancel}>
                  {LocalizedString.common.buttonCaptionCancel}
                </Button>
                <Button variant="contained" color="primary" type="button" onClick={this.onSave} style={styles.btnPrimary}>
                  {LocalizedString.common.buttonCaptionSave}
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      );
    }
}

ProfilePictureDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
