import * as Action from '../action';

const initialState = {
  assignClearanceForm: {
    soNo: undefined,
    blNo: undefined,
    forwarderId: undefined,
    truckerId: undefined,
  },
  submitting: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.ADD_ASSIGN_CLEARANCE:
      return { ...state, assignClearanceForm: { ...action.assignClearance } };
    case Action.REMOVE_ASSIGN_CLEARANCE:
      return initialState;
    case Action.UPLOADING_ASSIGN_CLEARANCE:
      return { ...state, submitting: action.status };
    default: return state;
  }
};
