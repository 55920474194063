import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router';
import moment from 'moment';
import FeederTab from './feeder.presentation';
import {
  ROLE_PPJK, ROLE_CUSTOMER,
  TABLE_SORT_ASCENDING,
  TABLE_SORT_DESCENDING,
  DEFAULT_PAGE_SIZE_OPTIONS,
  RXTABLEFIELD_PAGE_SIZE,
  RXFIELD_BL_NO,
  RXFIELD_DO_NO,
  RXFIELD_DO_EXPIRED,
  TABLE_FIELD_SHIFT_ID,
  TABLE_FIELD_SHIFT_DATE,
  TABLE_FIELD_SETTING_TEMPERATURE,
  TABLE_FIELD_AIR_SUPPLY,
  RXTABLEFIELD_ACTIVE_PAGE,
  MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING,
  RXFORM_ADD_BOOKING_TRUCK,
  RXFIELD_PAYMENT_ON_BEHALF_OF,
  RXFIELD_BILL_TO,
  RXFIELD_WAREHOUSE,
} from '../../constant';
import {
  downloadingBookFeederList,
  downloadFeederServiceForwarderListAsync,
  downloadFeederServiceEmptyDepoListAsync,
  downloadFeederServiceAreaListAsync,
  downloadFeederServiceWarehouseListAsync,
  downloadCustomerCompanyListAsync,
  downloadPpjkCompanyListAsync,
  downloadingFeederServiceBLList,
  addSelectedCompany,
  setBookFeederActiveForm,
  bookFeederAsync,
  setUploadingNewBookingStatus,
  showErrorSnackbar,
} from '../../redux/action';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler, toMoment } from '../../helper';


const extractNameFromObjToDropdownOption = (objList, field) => Object.values(objList)
  .map(e => ({
    label: e[field],
    value: e[field],
  }));

const getCompanyList = (ppjkList, customerList, currentCompany) => {
  const listObj = Object.keys(ppjkList).length === 0 ? customerList : ppjkList;
  const listArr = extractNameFromObjToDropdownOption(listObj, 'id');
  listArr.push({ label: currentCompany, value: currentCompany });
  return listArr;
};

const getCompanyRole = (selectedCompany = [], ppjkList = [], customerList) => {
  if (selectedCompany.length !== 0) {
    if (ppjkList.length !== 0) {
      return ppjkList.find(x => x === selectedCompany[0]) === undefined
        ? ROLE_CUSTOMER
        : ROLE_PPJK;
    }
    return customerList.find(x => x === selectedCompany[0]) === undefined
      ? ROLE_PPJK
      : ROLE_CUSTOMER;
  }
  return null;
};

const getpaymentBehalfOfList = (state) => {
  const currentCompany = state.currentUser.company;

  if (currentCompany.role.toUpperCase() === ROLE_CUSTOMER) {
    return [{ label: currentCompany.name || '-', value: currentCompany.id || '-' }];
  }

  const { feederServiceBLList } = state;
  const selector = formValueSelector(RXFORM_ADD_BOOKING_TRUCK);
  const selectedBlNo = selector(state, RXFIELD_BL_NO);
  const blDetails = feederServiceBLList[selectedBlNo];

  if (blDetails) {
    return [
      { label: blDetails.billToName, value: blDetails.billTo },
      { label: blDetails.forwarderIdName, value: blDetails.forwarderId },
    ].filter(item => item.value);
  }
  return [];
};

const getForwarderList = state => (state.currentUser.company.role.toUpperCase() === ROLE_PPJK
  ? [state.currentUser.company].map(item => ({ label: item.name, value: item.id }))
  : extractNameFromObjToDropdownOption(state.ppjkCompanyList, 'id'));

const getBLList = state => Object.values(state.feederServiceBLList)
  .map(item => ({
    label: item.blNo || '-',
    value: item.blNo || '-',
  }));

const getBLListObj = state => Object.values(state.feederServiceBLList);

const convertStringArrayToDropdownOption = arr => arr.map(item => ({ label: item, value: item }));

const getEmptyDepoList = state => Object.values(state.feederServiceEmptyDepoList)
  .map(item => ({
    label: item.depoName || '-',
    value: item.mtId || '-',
  }));

const getAreaList = state => Object.values(state.feederServiceAreaList)
  .map(item => ({
    label: item.routePlan,
    value: item.code,
  }));

const getSelectedBl = (state) => {
  const { feederServiceBLList } = state;
  const selector = formValueSelector(RXFORM_ADD_BOOKING_TRUCK);
  const selectedBlNo = selector(state, RXFIELD_BL_NO);
  return feederServiceBLList[selectedBlNo] || undefined;
};

const getBillToList = (state) => {
  const { feederServiceBLList } = state;
  const selector = formValueSelector(RXFORM_ADD_BOOKING_TRUCK);
  const selectedBlNo = selector(state, RXFIELD_BL_NO);
  const blDetails = feederServiceBLList[selectedBlNo];

  if (blDetails) {
    return [
      { label: blDetails.billToName, value: blDetails.billTo },
    ].filter(item => item.value);
  }
  return [];
};

const getBillTo = (state) => {
  const { currentUser } = state;
  if (currentUser.company.role.toUpperCase() === ROLE_CUSTOMER) {
    return currentUser.company.id;
  }

  return state.bookFeeder.feederInfo.billTo;
};

const mapStateToProps = state => ({
  initialValues: {
    blNo: state.bookFeeder.feederInfo.blNo,
    billTo: getBillTo(state),
    paymentOnBehalfOf: state.bookFeeder.feederInfo.paymentOnBehalfOf,
    forwarderId: state.currentUser.company.role.toUpperCase() === ROLE_PPJK
      ? state.currentUser.company.id
      : state.bookFeeder.feederInfo.forwarderId,
    doNo: state.bookFeeder.feederInfo.doNo,
    doExpired: state.bookFeeder.feederInfo.doExpired,
    emptyDepo: state.bookFeeder.feederInfo.emptyDepo,
    area: state.bookFeeder.feederInfo.area,
    warehouse: state.bookFeeder.feederInfo.warehouse,
    spcDate: state.bookFeeder.feederInfo.spcDate,
    planDate: state.bookFeeder.feederInfo.planDate
      ? toMoment(state.bookFeeder.feederInfo.planDate)
      : null,
  },

  lstBillTo: getBillToList(state),
  // lstBillTo: extractNameFromObjToDropdownOption(
  //   state.customerCompanyList,
  //   'id',
  // ),
  lstCompany: getCompanyList(
    state.ppjkCompanyList,
    state.customerCompanyList,
    state.currentUser.company.id,
  ),
  lstEmptyDepo: getEmptyDepoList(state),
  lstArea: getAreaList(state),
  lstWarehouse: extractNameFromObjToDropdownOption(
    state.feederServiceWarehouseList,
    'warehouseId',
  ),
  lstPaymentBehalfOf: getpaymentBehalfOfList(state),
  lstForwarder: getForwarderList(state),
  lstBillNo: getBLList(state),
  lstBillNoObj: Object.values(state.feederServiceBLList),
  currentUser: state.currentUser,
  selectedCompany: convertStringArrayToDropdownOption(
    state.uiBookFeederFeeder.selectedCompany,
  ),
  selectedCompanyRole: getCompanyRole(
    state.uiBookFeederFeeder.selectedCompany,
    extractNameFromObjToDropdownOption(state.ppjkCompanyList, 'id'),
    extractNameFromObjToDropdownOption(state.customerCompanyList, 'id'),
  ),
  selectedBl: getSelectedBl(state),
  uploadingNewBooking: state.uiBookFeederFeeder.uploadingNewBooking,
  downloading: state.uiBookFeederFeeder.downloading
    || state.uiBookFeederFeeder.downloadingFeederServiceDisclaimer
    || state.uiFeederService.downloadingFeederServiceList,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async (currentUserRole) => {
    dispatch(downloadingBookFeederList(true));
    const p1 = dispatch(downloadFeederServiceForwarderListAsync());
    const p2 = dispatch(downloadFeederServiceEmptyDepoListAsync());
    const p3 = dispatch(downloadFeederServiceAreaListAsync());
    let p5;
    if (currentUserRole.toUpperCase() === ROLE_PPJK) {
      p5 = dispatch(downloadCustomerCompanyListAsync());
    } else {
      p5 = dispatch(downloadPpjkCompanyListAsync());
    }

    Promise.all([p1, p2, p3, p5])
      .catch((error) => {
        dispatch(
          showErrorSnackbar(
            LocalizedString.common.alertTitleError,
            error.message,
          ),
        );
        unauthorizedErrorHandler(error, ownProps.history.push);
      })
      .finally(() => dispatch(downloadingBookFeederList(false)));
  },
  onChangePaymentOnBehalfOf: (newValue) => {
    dispatch(addSelectedCompany(newValue));
  },
  onNextPressed: async (values) => {
    try {
      dispatch(setUploadingNewBookingStatus(true));
      await dispatch(bookFeederAsync(values));
      dispatch(setBookFeederActiveForm(1));
    } catch (error) {
      dispatch(
        showErrorSnackbar(LocalizedString.common.alertTitleError, error.message),
      );
      unauthorizedErrorHandler(error, ownProps.history.push);
    } finally {
      dispatch(setUploadingNewBookingStatus(false));
    }
  },
  onChangeBL: async (value, lstBL, currentUserRole) => {
    try {
      dispatch(downloadingBookFeederList(true));

      dispatch(change(RXFORM_ADD_BOOKING_TRUCK, RXFIELD_WAREHOUSE, ''));

      const blItem = lstBL.find(x => x.blNo === value);
      if (blItem) {
        dispatch(change(RXFORM_ADD_BOOKING_TRUCK, RXFIELD_DO_NO, blItem.doNo));
        dispatch(change(RXFORM_ADD_BOOKING_TRUCK, RXFIELD_DO_EXPIRED, blItem.doDate || ''));
        dispatch(change(RXFORM_ADD_BOOKING_TRUCK, RXFIELD_PAYMENT_ON_BEHALF_OF, ''));

        if (currentUserRole.toUpperCase() === ROLE_PPJK) {
          dispatch(change(RXFORM_ADD_BOOKING_TRUCK, RXFIELD_BILL_TO, blItem.billTo));
        }

        const promiseWarehouse = dispatch(downloadFeederServiceWarehouseListAsync(blItem.billTo));
        const promiseArea = dispatch(downloadFeederServiceAreaListAsync(blItem.exim));
        await Promise.all([promiseWarehouse, promiseArea]);
      } else {
        dispatch(change(RXFORM_ADD_BOOKING_TRUCK, RXFIELD_BL_NO, value));
      }
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    } finally {
      dispatch(downloadingBookFeederList(false));
    }
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FeederTab),
);
