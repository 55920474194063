import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import uuid from 'uuid/v4';
import _ from 'lodash';
import OutstandingDetail from './outstanding-detail.presentation';
import {
  addToShoppingCartAsync,
  setMyPaymentOutstandingFilter,
  setMyPaymentOutstandingPaging,
  setMyPaymentOutstandingSort,
  requestPrintAsync,
  setPdfViewerStatus,
  showErrorSnackbar,
  cancelOrderAsync,
  setCheckoutBankSelection,
  setDialogSelectBankStatus,
  setDialogPaymentConfirmStatus,
  setCheckoutPasswordTextInput,
  changeOrderPaymentAsync,
  setUploadingPayment,
} from '../../redux/action';
import {
  PAYMENT_TYPE_CASH,
  TABLE_FIELD_MY_PAYMENT_HISTORY_BL_NUMBER,
  TABLE_SORT_ASCENDING,
  TABLE_FIELD_MY_PAYMENT_HISTORY_SERVICE,
  TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_NUMBER,
  TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_TO,
  TABLE_FIELD_MY_PAYMENT_HISTORY_AMOUNT,
  PRINT_TYPE_INVOICE,
  PRINT_TYPE_KMT,
  PRINT_TYPE_SP2,
} from '../../constant';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const getShoppingCartItems = state => _.groupBy(state.shoppingCheckout, 'blNumber');

const getSelectedBlInfo = state => _.values(state.myOutstandingOrder)
  .find((item) => {
    if (item.outstandingType === PAYMENT_TYPE_CASH) {
      return item.proformaNo.toLowerCase()
        .indexOf(state.uiMyPaymentOutstanding.tappedItemNo.toLowerCase()) >= 0;
    }
    if (item.invoiceNo) {
      return item.invoiceNo.toLowerCase()
        .indexOf(state.uiMyPaymentOutstanding.tappedItemNo.toLowerCase()) >= 0;
    }
    return true;
  });
//  _.values(state.myOutstandingOrder)
//   .find(item => (item.paymentMethod === PAYMENT_TYPE_CASH
//     ? item.proformaNo.toLowerCase() === state.uiMyPaymentOutstanding.tappedItemNo.toLowerCase()
//     : (item.invoiceNo && item.invoiceNo.toLowerCase() === state.uiMyPaymentOutstanding.tappedItemNo.toLowerCase()));

const getFilteredSelectedBlItem = (state) => {
  const selectedBl = getSelectedBlInfo(state);
  const selectedBlItems = (selectedBl && selectedBl.items) || [];
  const { filters, sortField, sortDirection } = state.uiMyPaymentOutstanding;

  return selectedBlItems.sort((left, right) => {
    if (typeof left[sortField] === 'string') {
      if (sortDirection === TABLE_SORT_ASCENDING) {
        return left[sortField].localeCompare(right[sortField]);
      }
      return right[sortField].localeCompare(left[sortField]);
    }

    if (sortDirection === TABLE_SORT_ASCENDING) {
      return left[sortField] - right[sortField];
    }
    return right[sortField] - left[sortField];
  })
    .filter(item => item.blNumber.toLowerCase()
      .indexOf(filters[TABLE_FIELD_MY_PAYMENT_HISTORY_BL_NUMBER].toLowerCase()) >= 0)
    .filter(item => item.service.toLowerCase()
      .indexOf(filters[TABLE_FIELD_MY_PAYMENT_HISTORY_SERVICE].toLowerCase()) >= 0)
    .filter(item => (item.invoiceNo || '-').toLowerCase()
      .indexOf(filters[TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_NUMBER].toLowerCase()) >= 0)
    .filter(item => (item.paymentOnBehalfOf || '-').toLowerCase()
      .indexOf(filters[TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_TO].toLowerCase()) >= 0)
    .filter(item => `${item.amount}`
      .indexOf(filters[TABLE_FIELD_MY_PAYMENT_HISTORY_AMOUNT].toLowerCase()) >= 0);
};

const getProformaDetailTotalPage = (state) => {
  const selectedBlItems = getFilteredSelectedBlItem(state);
  const { pageSize } = state.uiMyPaymentOutstanding;
  const totalData = selectedBlItems && selectedBlItems ? selectedBlItems.length : 0;

  return Math.max(Math.ceil(totalData / pageSize), 1);
};

const mapStateToProps = state => ({
  selectedBl: getSelectedBlInfo(state),
  selectedBlItems: getFilteredSelectedBlItem(state),
  shoppingCart: _.values(state.shoppingCart),
  addingShoppingCartItem: state.uiShoppingCart.addingItem,
  userCompany: state.currentUser.company,
  shoppingCheckoutItems: getShoppingCartItems(state),
  error: state.uiCheckout.error,
  changingOrderPaymentStatus: state.uiMyPaymentOutstanding.changingOrderPaymentStatus,

  // proforma detail
  pageSize: state.uiMyPaymentOutstanding.pageSize,
  totalPage: getProformaDetailTotalPage(state),
  activePage: state.uiMyPaymentOutstanding.activePage,
  filters: state.uiMyPaymentOutstanding.filters,
  sortField: state.uiMyPaymentOutstanding.sortField,
  sortDirection: state.uiMyPaymentOutstanding.sortDirection,

  // bank options
  dialogPaymentSelectBankStatus: state.uiCheckout.dialogPaymentSelectBankStatus,
  dialogPaymentConfirmStatus: state.uiCheckout.dialogPaymentConfirmStatus,
  selectedBank: state.uiCheckout.selectedBank,
  password: state.uiCheckout.password,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddToCartPressed: async (termOrder) => {
    const itemId = uuid();
    const { blNumber, amount, service } = termOrder;
    try {
      await dispatch(addToShoppingCartAsync(itemId, blNumber, service, amount, termOrder));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },

  // proforma detail actions
  onChangeFilter: (filterName, value) => {
    dispatch(setMyPaymentOutstandingFilter(filterName, value));
  },
  onChangePaging: (pageField, value) => {
    dispatch(setMyPaymentOutstandingPaging(pageField, value));
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setMyPaymentOutstandingSort(sortField, sortDirection));
  },

  // proforma detail actions
  onInvoicePressed: async (item) => {
    try {
      const data = {
        printType: PRINT_TYPE_INVOICE,
        invoiceNo: item.invoiceNo,
        blNo: item.blNumber,
        service: item.service,
      };
      await dispatch(requestPrintAsync(data));
      dispatch(setPdfViewerStatus(true));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onKmtPressed: async (item) => {
    try {
      const data = {
        printType: PRINT_TYPE_KMT,
        invoiceNo: item.invoiceNo,
        blNo: item.blNumber,
        service: item.service,
      };
      await dispatch(requestPrintAsync(data));
      dispatch(setPdfViewerStatus(true));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onSp2Pressed: async (item) => {
    try {
      const data = {
        printType: PRINT_TYPE_SP2,
        invoiceNo: item.invoiceNo,
        blNo: item.blNumber,
        service: item.service,
      };
      await dispatch(requestPrintAsync(data));
      dispatch(setPdfViewerStatus(true));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },

  onCancelPaymentPressed: async (item) => {
    try {
      await dispatch(cancelOrderAsync(item.proformaNo, ownProps.history));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onChangePaymentPressed: async (item, staticVa, paymentChannel) => {
    if (!staticVa) {
      dispatch(setDialogPaymentConfirmStatus(true));
    } else {
      dispatch(setDialogSelectBankStatus(true));
    }
    // dispatch(setDialogSelectBankStatus(true));

    // dispatch(setMyPaymentOutstandingTappedProformaNo(item.proformaNo));

    // if (!staticVa) {
    //   dispatch(setCheckoutPasswordInputDialog(true));
    // } else {
    //   dispatch(setCheckoutPaymentChannel(paymentChannel));
    //   dispatch(setCheckoutPaymentChannelDialog(true));
    // }
  },

  onBankOptionSelected: (value) => {
    dispatch(setCheckoutBankSelection(value));
  },
  onOkBankOptionPressed: () => {
    dispatch(setDialogSelectBankStatus(false));
    dispatch(setDialogPaymentConfirmStatus(true));
  },
  onCancelBankOptionPressed: () => {
    dispatch(setDialogSelectBankStatus(false));
  },

  onChangePasswordText: (value) => {
    dispatch(setCheckoutPasswordTextInput(value));
  },
  onCancelPasswordInputPressed: () => {
    dispatch(setDialogPaymentConfirmStatus(false));
    dispatch(setCheckoutPasswordTextInput(''));
  },
  onSubmitPasswordInputPressed: async (password, useCustomerDeposit) => {
    try {
      dispatch(setUploadingPayment(true));
      await dispatch(changeOrderPaymentAsync(password));
      dispatch(setDialogPaymentConfirmStatus(false));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    } finally {
      dispatch(setUploadingPayment(false));
    }
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(OutstandingDetail));
