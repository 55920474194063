import React, { Component } from 'react';
import { Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';

export default class CurrentProfilePicture extends Component {
  componentDidMount() {
    this.props.onAppear();
  }

  render() {
    const { profilePicture, className, onClick } = this.props;
    return (
      <Avatar onClick={onClick} alt="profile" src={profilePicture} className={className} />
    );
  }
}

CurrentProfilePicture.propTypes = {
  profilePicture: PropTypes.string,
  className: PropTypes.string,
  onAppear: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

CurrentProfilePicture.defaultProps = {
  profilePicture: undefined,
  className: '',
  onClick: () => {},
};
