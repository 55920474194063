import { connect } from 'react-redux';
import OrderDetailCustomerPage from './order-detail-customer.presentation';
import {
  setOrderDetailCustomerFilter, setOrderDetailCustomerPaging, setOrderDetailCustomerSort, setOrderDetailCustomerSelectedTab,
} from '../../redux/action';
import * as Constant from '../../constant';

const getFilteredOrderDetails = (state) => {
  const filteredOrderDetail = [{
    id: 1,
    containerNo: '00000000000',
    soNo: '',
    blNo: '',
    containerNoOut: 'MSKU2805695',
    soNoOut: '515237.2IS',
    blNoOut: 'MCB651085',
    status: 'Confirmed',
    gateInCdp: '',
    gateOutCdp: '',
    driverName: '',
    truckNo: '',

  }, {
    id: 2,
    containerNo: '00000000000',
    soNo: '',
    blNo: '',
    containerNoOut: 'TRHU1142267',
    soNoOut: '515262.2IS',
    blNoOut: 'MCB655520',
    gateInCdp: '',
    gateOutCdp: '',
    status: 'Confirmed',
    driverName: '',
    truckNo: '',
  }];

  const {
    filters, sortField, sortDirection, selectedTab,
  } = state.uiOrderDetailCustomer;

  return filteredOrderDetail.sort((left, right) => {
    if (typeof left[sortField] === 'string') {
      if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
        return left[sortField].localeCompare(right[sortField]);
      }
      return right[sortField].localeCompare(left[sortField]);
    }

    if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
      return left[sortField] - right[sortField];
    }
    return right[sortField] - left[sortField];
  })
    .filter(item => (`${item.containerNo}`).toUpperCase().indexOf(
      filters[Constant.TABLE_FIELD_ORDER_DETAIL_CONTAINER_NO].toUpperCase(),
    ) >= 0)
    .filter(item => item.soNo.toUpperCase().indexOf(
      filters[Constant.TABLE_FIELD_ORDER_DETAIL_SO_NO].toUpperCase(),
    ) >= 0)
    .filter(item => item.blNo.toUpperCase().indexOf(
      filters[Constant.TABLE_FIELD_ORDER_DETAIL_BL_NO].toUpperCase(),
    ) >= 0)
    .filter(item => item.containerNoOut.toUpperCase().indexOf(
      filters[Constant.TABLE_FIELD_ORDER_DETAIL_CONTAINER_NO_OUT].toUpperCase(),
    ) >= 0)
    .filter(item => item.soNoOut.toUpperCase().indexOf(
      filters[Constant.TABLE_FIELD_ORDER_DETAIL_SO_NO_OUT].toUpperCase(),
    ) >= 0)
    .filter(item => item.status.toUpperCase().indexOf(
      filters[Constant.TABLE_FIELD_ORDER_DETAIL_STATUS].toUpperCase(),
    ) >= 0);
};

const getPageFilteredOrderDetails = (state) => {
  const {
    activePage, pageSize,
  } = state.uiOrderDetailCustomer;
  const filteredOrderDetail = getFilteredOrderDetails(state);

  if (filteredOrderDetail) {
    const refinedActivePage = activePage || 1;
    const start = Math.min((refinedActivePage - 1) * pageSize,
      filteredOrderDetail.length);
    const end = Math.min(start + pageSize, filteredOrderDetail.length);
    return filteredOrderDetail.slice(start, end);
  }

  return [];
};

const getTotalPage = (state) => {
  const filteredOrderDetail = getFilteredOrderDetails(state);
  const { pageSize } = state.uiOrderDetailCustomer;
  const totalData = filteredOrderDetail ? filteredOrderDetail.length : 0;

  return Math.max(Math.ceil(totalData / pageSize), 1);
};

const mapStateToProps = state => ({
  menuOpen: state.uiMenu.menuOpen,
  orders: getPageFilteredOrderDetails(state),
  selectedTab: state.uiOrderDetailCustomer.selectedTab,
  filters: state.uiOrderDetailCustomer.filters,
  activePage: state.uiOrderDetailCustomer.activePage,
  pageSize: state.uiOrderDetailCustomer.pageSize,
  totalPage: getTotalPage(state),
  sortField: state.uiOrderDetailCustomer.sortField,
  sortDirection: state.uiOrderDetailCustomer.sortDirection,
});

const mapDispatchToProps = dispatch => ({
  onChangeFilter: (filterName, value) => {
    dispatch(setOrderDetailCustomerFilter(filterName, value));
  },
  onChangePaging: (pageField, value) => {
    dispatch(setOrderDetailCustomerPaging(pageField, value));
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setOrderDetailCustomerSort(sortField, sortDirection));
  },
  onTabPressed: (tappedTabIndex) => {
    dispatch(setOrderDetailCustomerSelectedTab(tappedTabIndex));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailCustomerPage);
