import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, CircularProgress, Dialog,
  DialogActions,
  DialogContent, DialogTitle, Typography, withStyles,
} from '@material-ui/core';
import LocalizedString from '../../localization';

const styles = () => ({
  pinContainer: {
    margin: '16px',
  },
  circularProgress: {
    height: '24px !important',
    width: '24px !important',
  },
});

const getTncLabel = tnc => tnc.split('\n').map(string => <p>{string}</p>);

const CheckoutTncDialog = ({
  visibility, tnc, downloading, classes,
  onAppear, onCancelPressed,
}) => {
  useEffect(() => {
    if (visibility) {
      onAppear();
    }
  }, [visibility]);
  return (
    <Dialog
      open={visibility}
    >
      <DialogTitle>
        <div className={classes.dialogHeader}>
          <Typography color="primary" variant="h6">
            {LocalizedString.checkout.buttonCaptionTermAndCondition}
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        {downloading ? (
          <CircularProgress className={classes.circularProgress} />
        ) : getTncLabel(tnc)}
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={() => onCancelPressed()}
        >
          {LocalizedString.common.buttonCaptionOK}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(CheckoutTncDialog);

CheckoutTncDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  visibility: PropTypes.bool,
  downloading: PropTypes.bool,
  tnc: PropTypes.string,
};

CheckoutTncDialog.defaultProps = {
  downloading: false,
  visibility: false,
  tnc: '',
};
