import { connect } from 'react-redux';
import VendorEditDialog from './vendor-edit-dialog.presentation';
import { setUIError, clearUIError } from '../../redux/action';
import { RXSTATE_MASTER_VENDOR } from '../../constant';

const getVendorDetail = (state) => {
  const { selectedVendorId } = state.uiMasterVendor;
  return state.vendors[selectedVendorId] || {};
};

const getInitialValues = state => getVendorDetail(state);

const mapStateToProps = state => ({
  vendor: getVendorDetail(state),
  initialValues: getInitialValues(state),
  loading: false,
});

const mapDispatchToProps = dispatch => ({
  onAppear: () => { },
  onSubmitPressed: async (values) => {
    try {
      clearUIError(RXSTATE_MASTER_VENDOR);
    } catch (error) {
      setUIError(RXSTATE_MASTER_VENDOR, error.message);
    }
  },

});

export default connect(mapStateToProps, mapDispatchToProps)(VendorEditDialog);
