import uuid from 'uuid/v4';
import addToShoppingCartAsync from './addToShoppingCartAsync';
import * as Constant from '../../../constant';

export default feederServiceOrderHistory => async (dispatch) => {
  const { refNo, totalPrice } = feederServiceOrderHistory;
  const itemId = uuid();

  await dispatch(addToShoppingCartAsync(itemId,
    refNo,
    Constant.CART_SERVICE_NAME_TRUCKING,
    totalPrice,
    feederServiceOrderHistory));
};
