import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  withStyles,
  CssBaseline,
  Card,
  CardContent,
  Fab,
  Icon,
  Grid,
  Typography,
  MobileStepper,
  IconButton,
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { PageHeaderUser } from '../../component';
import * as Constant from '../../constant';
import LocalizedString from '../../localization';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = theme => ({
  backIcon: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${Constant.DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    color: '#0266B4',
  },
  root: {
    width: '100%',
  },
  stepperRoot: {
    position: 'absolute',
    background: 'none',
    bottom: 8,
    height: '100%',
  },
  stepperDot: {
    width: 32,
    height: 4,
    borderRadius: 0,
  },
  stepperDotActive: {
    backgroundColor: '#ffffff',
  },
  stepperDots: {
    alignSelf: 'flex-end',
  },
  stepperIconPrimary: {
    color: '#ffffff',
  },
  stepperNavigatorIcon: {
    fontSize: 48,
  },
  stepperImage: {
    display: 'flex',
    height: 400,
    width: '100%',
    overflow: 'hidden',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  stepperLabel: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    padding: 16,
    color: '#ffffff',
    alignSelf: 'center',
    marginBottom: 48,
    maxWidth: '60%',
  },
});

class AboutUsPage extends Component {
  state = {
    activeStep: 0,
  };

  handleStepChange = (activeStep) => {
    this.setState({
      activeStep,
    });
  }

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
  }

  handlePrev = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  }

  render() {
    const { activeStep } = this.state;
    const {
      classes,
      notificationOpen,
      stepperContents,
    } = this.props;

    const totalSteps = stepperContents.length;

    return (
      <div className={classes.root}>
        <CssBaseline />

        <PageHeaderUser />

        <div
          className={
            classNames(classes.content, {
              [classes.contentDrawerOpen]: notificationOpen,
              [classes.contentDrawerClosed]: !notificationOpen,
            })
          }
          style={{ padding: '16px' }}
        >
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <div className={classes.pageNavigatorContainer}>
                    <Fab
                      variant="round"
                      classes={{ root: classes.backIcon }}
                      component={Link}
                      to={Constant.ROUTE_NAME_DASHBOARD_USER}
                    >
                      <Icon>chevron_left</Icon>
                    </Fab>
                    <div style={{ marginLeft: '32px' }}>
                      <Typography variant="h5" className={classes.pageTitle}>{LocalizedString.aboutUs.title}</Typography>
                      <Typography variant="caption">{LocalizedString.aboutUs.caption}</Typography>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <div style={{ position: 'relative' }}>
                    <AutoPlaySwipeableViews
                      axis="x"
                      index={activeStep}
                      onChangeIndex={this.handleStepChange}
                      enableMouseEvents
                    >
                      {stepperContents.map((step, index) => (
                        <div key={step.label}>
                          {Math.abs(activeStep - index) <= 2 ? (
                            <div
                              style={{
                                backgroundImage: `url('${step.imgPath}')`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                              }}
                              className={classes.stepperImage}
                            >
                              {step.label && (
                                <div className={classes.stepperLabel}>
                                  {step.label}
                                </div>
                              )}
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </AutoPlaySwipeableViews>
                    <MobileStepper
                      steps={totalSteps}
                      position="bottom"
                      activeStep={activeStep}
                      classes={{
                        root: classes.stepperRoot,
                        dots: classes.stepperDots,
                        dot: classes.stepperDot,
                        dotActive: classes.stepperDotActive,
                      }}
                      nextButton={(
                        <IconButton
                          color="primary"
                          classes={{ colorPrimary: classes.stepperIconPrimary }}
                          onClick={this.handleNext}
                          disabled={activeStep === totalSteps - 1}
                        >
                          <Icon className={classes.stepperNavigatorIcon}>chevron_right</Icon>
                        </IconButton>
                      )}
                      backButton={(
                        <IconButton
                          color="primary"
                          classes={{ colorPrimary: classes.stepperIconPrimary }}
                          onClick={this.handlePrev}
                          disabled={activeStep === 0}
                        >
                          <Icon className={classes.stepperNavigatorIcon}>chevron_left</Icon>
                        </IconButton>
                      )}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
AboutUsPage.propTypes = {
  classes: PropTypes.shape(PropTypes.any),
  notificationOpen: PropTypes.bool,
  stepperContents: PropTypes.arrayOf(PropTypes.any).isRequired,
};
AboutUsPage.defaultProps = {
  classes: {},
  notificationOpen: false,
};

export default withStyles(styles)(AboutUsPage);
