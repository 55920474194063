import * as Helper from '../../../helper';
import * as Validation from '../../../validation';
import { uploadingForm } from '../simple-action';

export default values => async (dispatch, getState) => {
  try {
    dispatch(uploadingForm(true));

    Validation.validateNewForm(values);

    const { token } = getState().authentication;
    const { selectedFormId } = getState().uiForm;

    const {
      order, title, description, status,
    } = values;


    const requestBody = {
      id: selectedFormId,
      order: Helper.toNumber(order),
      title,
      description,
      status,
    };

    await Helper.uploadUpdateForm(selectedFormId, requestBody, token);
  } finally {
    dispatch(uploadingForm(false));
  }
};
