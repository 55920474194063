import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import EmailSubmission from './email-submission.container';
import PinVerification from './pin-verification.container';
import RegistrationInfo from './registration-info.container';
import { ErrorMessage } from '../../component';
import * as Constant from '../../constant';

const ForgetPassword = ({ errorMsg }) => (
  <div>
    <Route
      exact
      path={Constant.ROUTE_NAME_REGISTER_EMAIL}
      component={EmailSubmission}
    />
    <Route
      path={Constant.ROUTE_NAME_PIN_VERIFICATION_REGISTER_EMAIL}
      component={PinVerification}
    />
    <Route
      path={Constant.ROUTE_NAME_REGISTRATION_INFO}
      component={RegistrationInfo}
    />
    {errorMsg && <ErrorMessage message={errorMsg} />}
  </div>
);

export default ForgetPassword;

ForgetPassword.propTypes = {
  errorMsg: PropTypes.string,
};

ForgetPassword.defaultProps = {
  errorMsg: '',
};
