import React, { Component } from 'react';
import PropTypes from 'prop-types';
import momentTz from 'moment-timezone';
import { reduxForm, Field } from 'redux-form';
import {
  Grid, withStyles, Button, Typography,
} from '@material-ui/core';
import {
  renderReduxFormFilterableSelectField,
  renderReduxFormTextField,
  renderReduxFormDateField,
  renderReduxFormDateTimeField,
  renderReduxFormFilterableSelectAsyncField,
  toMoment,
  renderReduxFormFilterableSelectCreateAsyncField,
} from '../../helper';
import {
  ROLE_PPJK,
  ROLE_CUSTOMER,
  RXFIELD_BL_NO,
  RXFIELD_PAYMENT_ON_BEHALF_OF,
  RXFIELD_DO_NO,
  RXFIELD_DO_EXPIRED,
  RXFIELD_EMPTY_DEPO,
  RXFIELD_WAREHOUSE,
  RXFIELD_SPC_DATE,
  RXFIELD_BILL_TO,
  RXFIELD_AREA,
  RXFIELD_PLAN_DATE,
  RXFIELD_FORWARDER_ID,
  CART_SERVICE_NAME_EXPORT,
} from '../../constant';
import { ProfileShape, DropdownOption } from '../../type';
import LocalizedString from '../../localization';
import { LoadingIndicator } from '../../component';

const styles = () => ({
  dialogActions: {
    margin: '0 -24px',
    marginTop: '24px',
    padding: 24,
    borderTop: '1px solid #C4C4C4',
    justifyContent: 'space-between',
    paddingBottom: 0,
    display: 'flex',
  },
  disclaimerTitle: {
    fontWeight: 'bold',
    fontSize: '1rem',
    color: '#404040',
  },
});

class FeederTab extends Component {
  componentDidMount() {
    // this.props.onAppear(this.props.currentUser.role);
    this.props.onAppear(this.props.currentUser.company.role);
  }

  render() {
    const {
      classes,
      currentUser,
      selectedCompany,
      selectedCompanyRole,
      selectedBl,
      uploadingNewBooking,
      downloading,
      lstBillTo,
      lstBillNo,
      lstBillNoObj,
      // lstCompany,
      lstPaymentBehalfOf,
      lstForwarder,
      lstEmptyDepo,
      lstArea,
      lstWarehouse,

      handleSubmit,
      onChangeBL,
      onChangePaymentOnBehalfOf,
      onNextPressed,

    } = this.props;

    const initialPlanDateTime = toMoment(momentTz(new Date()).set('hours', 1).set('minutes', 0));

    const renderBillTo = () => {
      if (currentUser.company.role.toUpperCase() === ROLE_PPJK) {
        return (
          <Field
            name={RXFIELD_BILL_TO}
            component={renderReduxFormFilterableSelectField}
            options={lstBillTo}
            disabled
            label={LocalizedString.bookingFeeder.labelBillTo}
            required
          />
        );
        // if (selectedCompanyRole
        //   && selectedCompanyRole.toUpperCase() === ROLE_CUSTOMER) {
        //   return (
        //     <Field
        //       name={RXFIELD_BILL_TO}
        //       component={renderReduxFormFilterableSelectField}
        //       options={selectedCompany}
        //       disabled={uploadingNewBooking}
        //       label={LocalizedString.bookingFeeder.labelBillTo}
        //       required
        //     />
        //   );
        // }
        // return (
        //   <Field
        //     name={RXFIELD_BILL_TO}
        //     component={renderReduxFormFilterableSelectField}
        //     options={lstBillTo}
        //     disabled={uploadingNewBooking}
        //     label={LocalizedString.bookingFeeder.labelBillTo}
        //     required
        //   />
        // );
      }

      return null;
    };

    const renderForwarder = () => {
      if (currentUser.company.role.toUpperCase() === ROLE_CUSTOMER) {
        return null;
      }
      return (
        <Field
          name={RXFIELD_FORWARDER_ID}
          component={renderReduxFormFilterableSelectField}
          options={lstForwarder}
          disabled
          label={LocalizedString.bookingFeeder.labelForwarder}
          required
        />
      );
    };

    const renderSpcDate = () => {
      console.log(selectedBl);
      if (selectedBl
        && selectedBl.exim.toUpperCase() !== CART_SERVICE_NAME_EXPORT.toUpperCase()) {
        return (
          <Grid item xs={12} md={6}>
            <Field
              name={RXFIELD_SPC_DATE}
              component={renderReduxFormDateField}
              disabled={uploadingNewBooking}
              keyboard
              label={LocalizedString.bookingFeeder.labelSpcDate}
              required
            />
          </Grid>
        );
      }
      return null;
    };

    return (
      <>
        <form onSubmit={handleSubmit(onNextPressed)}>
          <Grid container xs={12} spacing={24}>
            <Grid item xs={12}>
              <Field
                name={RXFIELD_BL_NO}
                component={renderReduxFormFilterableSelectCreateAsyncField}
                disableDefaultOptions
                options={lstBillNo}
                disabled={uploadingNewBooking}
                label={LocalizedString.bookingFeeder.labelBlNumber}
                onValueChange={value => onChangeBL(value, lstBillNoObj, currentUser.company.role)}
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Field
                name={RXFIELD_PAYMENT_ON_BEHALF_OF}
                component={renderReduxFormFilterableSelectField}
                options={lstPaymentBehalfOf}
                disabled={uploadingNewBooking}
                label={LocalizedString.bookingFeeder.labelPaymentOnBehalfOf}
                required
                onValueChange={value => onChangePaymentOnBehalfOf(value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {renderBillTo()}
            </Grid>

            <Grid item xs={12} md={6}>
              <Field
                name={RXFIELD_DO_NO}
                component={renderReduxFormTextField}
                disabled
                label={LocalizedString.bookingFeeder.labelDoNo}
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              {renderForwarder()}
            </Grid>

            <Grid item xs={12} md={6}>
              <Field
                name={RXFIELD_DO_EXPIRED}
                component={renderReduxFormDateField}
                disabled
                keyboard
                label={LocalizedString.bookingFeeder.labelDoExpired}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name={RXFIELD_EMPTY_DEPO}
                component={renderReduxFormFilterableSelectAsyncField}
                options={lstEmptyDepo}
                disabled={uploadingNewBooking}
                label={LocalizedString.bookingFeeder.labelEmptyDepo}
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Field
                name={RXFIELD_AREA}
                component={renderReduxFormFilterableSelectAsyncField}
                options={lstArea}
                disabled={uploadingNewBooking}
                label={LocalizedString.bookingFeeder.labelArea}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name={RXFIELD_WAREHOUSE}
                component={renderReduxFormFilterableSelectAsyncField}
                options={lstWarehouse}
                disabled={uploadingNewBooking}
                label={LocalizedString.bookingFeeder.labelWarehouse}
                required
              />
            </Grid>

            {renderSpcDate()}
            <Grid item xs={12} md={6}>
              <Field
                name={RXFIELD_PLAN_DATE}
                component={renderReduxFormDateTimeField}
                initialFocusedDate={initialPlanDateTime}
                disabled={uploadingNewBooking}
                keyboard
                label={LocalizedString.bookingFeeder.labelPlanDate}
                required
              />
            </Grid>
          </Grid>
          <div className={classes.dialogActions}>
            <div style={{ flexGrow: 1 }} />
            <div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                Next
              </Button>
            </div>
          </div>
        </form>
        {(uploadingNewBooking || downloading) && <LoadingIndicator />}
      </>
    );
  }
}
FeederTab.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  currentUser: PropTypes.shape(ProfileShape),
  uploadingNewBooking: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  selectedCompany: PropTypes.arrayOf(DropdownOption),
  selectedCompanyRole: PropTypes.string,
  selectedBl: PropTypes.shape(PropTypes.any).isRequired,

  lstBillTo: PropTypes.arrayOf(DropdownOption).isRequired,
  lstBillNo: PropTypes.arrayOf(PropTypes.any).isRequired,
  lstBillNoObj: PropTypes.arrayOf(PropTypes.any).isRequired,
  // lstCompany: PropTypes.arrayOf(DropdownOption).isRequired,
  lstPaymentBehalfOf: PropTypes.arrayOf(DropdownOption).isRequired,
  lstForwarder: PropTypes.arrayOf(DropdownOption).isRequired,
  lstEmptyDepo: PropTypes.arrayOf(DropdownOption).isRequired,
  lstArea: PropTypes.arrayOf(DropdownOption).isRequired,
  lstWarehouse: PropTypes.arrayOf(DropdownOption).isRequired,
  disclaimer: PropTypes.shape(PropTypes.any).isRequired,

  handleSubmit: PropTypes.func,
  onAppear: PropTypes.func.isRequired,
  onChangePaymentOnBehalfOf: PropTypes.func.isRequired,
  onChangeBL: PropTypes.func.isRequired,
  onNextPressed: PropTypes.func.isRequired,
};
FeederTab.defaultProps = {
  currentUser: {},
  selectedCompany: [],
  selectedCompanyRole: undefined,
  handleSubmit: () => {},
};

export default reduxForm({ form: 'addBooking' })(
  withStyles(styles)(FeederTab),
);
