import { downloadingDocSubmissionMyResponses, setDocSubmissionMyResponses } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;

  try {
    dispatch(downloadingDocSubmissionMyResponses(true));
    const data = await Helper.downloadDocSubmissionMyResponses(token);
    dispatch(setDocSubmissionMyResponses(data));
  } finally {
    dispatch(downloadingDocSubmissionMyResponses(false));
  }
};
