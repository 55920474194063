import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import * as Constant from '../../constant';
import AssignContainerList from './assign-container-list.presentation';
import {
  setAssignContainerActiveForm,
  downloadPpjkListByAssignAsync,
  downloadTruckListAsync,
  setAssignContainerFilter,
  setAssignContainerPaging,
  setAssignContainerSort,
  addAssignTruck,
  showErrorSnackbar,
} from '../../redux/action';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const getFilteredContainer = (state) => {
  const {
    filters, sortField, sortDirection,
  } = state.uiAssignContainer;

  const assignContainer = _.values(state.assignContainer)
    .filter(item => item.blNo === state.uiAssignOrderType.tappedBlNo
      && item.soNo === state.uiAssignOrderType.tappedSoNo);

  return assignContainer.sort((left, right) => {
    if (typeof left[sortField] === 'string') {
      if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
        return left[sortField].localeCompare(right[sortField]);
      }
      return right[sortField].localeCompare(left[sortField]);
    }

    if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
      return left[sortField] - right[sortField];
    }
    return right[sortField] - left[sortField];
  })
    .filter(item => !item.containerNo || item.containerNo.toUpperCase().indexOf(
      filters[Constant.TABLE_FIELD_CONTAINER_NUMBER].toUpperCase(),
    ) >= 0)
    .filter(item => (item.forwarderId || '').toUpperCase().indexOf(
      filters[Constant.TABLE_FIELD_TRUCKER].toUpperCase(),
    ) >= 0)
    .filter(item => (item.billTo || '').toUpperCase().indexOf(
      filters[Constant.TABLE_FIELD_CONSIGNEE].toUpperCase(),
    ) >= 0);
};

const getPageFilteredContainer = (state) => {
  const { activePage, pageSize } = state.uiAssignContainer;
  const filteredOrderType = getFilteredContainer(state);

  const refinedActivePage = activePage || 1;
  const start = Math.min((refinedActivePage - 1) * pageSize, filteredOrderType.length);
  const end = Math.min(start + pageSize, filteredOrderType.length);
  return filteredOrderType.slice(start, end);
};

const getTotalPage = (state) => {
  const totalData = getFilteredContainer(state).length;
  const { pageSize } = state.uiContainerTracking;

  return Math.max(Math.ceil(totalData / pageSize), 1);
};

const mapStateToProps = state => ({
  assignContainer: getPageFilteredContainer(state),
  companyRole: state.currentUser.company.role,

  pageSize: state.uiAssignContainer.pageSize,
  totalPage: getTotalPage(state, state.uiAssignContainer.tappedBlNo),
  activePage: state.uiAssignContainer.activePage,
  filters: state.uiAssignContainer.filters,
  sortField: state.uiAssignContainer.sortField,
  sortDirection: state.uiAssignContainer.sortDirection,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAssignToPressed: (item) => {
    Promise.all([
      dispatch(downloadPpjkListByAssignAsync()),
      dispatch(downloadTruckListAsync()),
    ]).then(() => {
      dispatch(setAssignContainerActiveForm(1));
      dispatch(addAssignTruck(item));
    }).catch((error) => {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    });
  },
  onChangeFilter: (filterName, value) => {
    dispatch(setAssignContainerFilter(filterName, value));
  },
  onChangePaging: (pageField, value) => {
    dispatch(setAssignContainerPaging(pageField, value));
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setAssignContainerSort(sortField, sortDirection));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssignContainerList));
