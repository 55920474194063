import * as Action from '../action';
import * as Constant from '../../constant';

const initialState = {
  downloading: false,
  tappedId: '',
  dialogTopUpStatus: false,

  filters: {
    [Constant.TABLE_FIELD_DEPOSIT_HISTORY_TYPE]: '',
    [Constant.TABLE_FIELD_DEPOSIT_HISTORY_DATE]: null,
    [Constant.TABLE_FIELD_DEPOSIT_HISTORY_AMOUNT]: '',
    [Constant.TABLE_FIELD_DEPOSIT_HISTORY_INVOICE_NO]: '',
    [Constant.TABLE_FIELD_START_DATE]: '',
    [Constant.TABLE_FIELD_END_DATE]: '',
  },
  validationMessages: {
    [Constant.TABLE_FIELD_START_DATE]: '',
    [Constant.TABLE_FIELD_END_DATE]: '',
  },
  sortField: Constant.TABLE_FIELD_DEPOSIT_HISTORY_DATE,
  sortDirection: Constant.TABLE_SORT_DESCENDING,
  activePage: 1,
  pageSize: 5,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.DOWNLOADING_DEPOSIT_HISTORY:
      return { ...state, downloading: action.status };
    case Action.SET_TAPPED_DEPOSIT_HISTORY_ID:
      return { ...state, tappedId: action.tappedId };
    case Action.SET_DEPOSIT_HISTORY_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.value,
        },
      };
    }
    case Action.SET_DEPOSIT_HISTORY_VALIDATION_MESSAGES: {
      return {
        ...state,
        validationMessages: {
          ...state.validationMessages,
          ...action.value,
        },
      };
    }
    case Action.SET_DEPOSIT_HISTORY_PAGING: {
      return { ...state, ...action.value };
    }
    case Action.SET_DEPOSIT_HISTORY_SORT: {
      return { ...state, sortField: action.sortField, sortDirection: action.sortDirection };
    }
    case Action.SET_TOP_UP_DIALOG_STATUS:
      return { ...state, dialogTopUpStatus: action.status };
    default: return state;
  }
};
