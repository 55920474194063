import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import {
  Grid, withStyles, Button, Typography,
} from '@material-ui/core';
import {
  renderReduxFormFilterableSelectField,
  renderReduxFormTextField,
  renderReduxFormDateField,
  renderReduxFormTextArea,
  renderReduxFormFilterableSelectAsyncField,
} from '../../helper';
import {
  RXFIELD_DO_NO,
  RXFIELD_DO_DATE,
  RXFIELD_PEB_NO,
  RXFIELD_PEB_DATE,
  RXFIELD_VESSEL,
  RXFIELD_VOYAGE,
  RXFIELD_VOYAGE_SHIPPING_LINE,
  RXFIELD_VOYAGE_PO_DESTINATION,
  RXFIELD_VOYAGE_PO_TRANSIT,
  RXFIELD_BRUTO,
  RXFIELD_CONTAINER_VOLUME,
  RXFIELD_CONTAINER_TYPE,
  RXFIELD_CONTAINER_AMOUNT,
  RXFIELD_CONTAINER_BRAND,
  RXFIELD_CONTAINER_DESC,
  RXFIELD_CONTAINER_HSC,
  RXFIELD_CONTAINER_CONSIGNEE,
  RXFIELD_CONTAINER_CONSIGNEE_ADDR,
  RXFORM_BOOK_EXPORT_FEEDER,
  RXFIELD_VOYAGE_VESSEL_ID,
  RXFIELD_VOYAGE_TO_CLOSING_DATETIME,
  RXFIELD_VOYAGE_TO,
  RXFIELD_VOYAGE_ETD,
  RXFIELD_VOYAGE_ATA,
  RXFIELD_VOYAGE_VESSEL_OWNER,
  RXFIELD_VOYAGE_TO_CLOSING_DATETIME_FORMATTED,
  RXFIELD_VOYAGE_ETD_FORMATTED,
  RXFIELD_VOYAGE_ATA_FORMATTED,
  RXFIELD_PAYMENT_ON_BEHALF_OF,
  ROLE_PPJK,
  ROLE_CUSTOMER,
  RXFIELD_BILL_TO,
  RXFIELD_FORWARDER_ID,
  RXFIELD_DG_CLASS,
  RXFIELD_DG_SUB_CLASS,
} from '../../constant';
import LocalizedString from '../../localization';
import { LoadingIndicator } from '../../component';

const styles = () => ({
  displayNone: {
    display: 'none',
  },
  dialogActions: {
    margin: '0 -24px',
    marginTop: '24px',
    padding: 24,
    borderTop: '1px solid #C4C4C4',
    justifyContent: 'space-between',
    paddingBottom: 0,
    display: 'flex',
  },
  segmentHeader: {
    marginTop: '24px',
    fontWeight: 600,
  },
});

class FeederTab extends Component {
  componentDidMount() {
    this.props.onAppear(this.props.currCompanyRole);
  }

  getForwarderList = (role, userCompany, companyList) => (
    role.toUpperCase() === ROLE_PPJK ? [{ label: userCompany.name, value: userCompany.id }] : companyList);

  renderBillTo = () => {
    const {
      lstPaymentBehalfOf, selectedPaymentBehalfOf, selectedPaymentBehalfOfRole, currCompanyRole, lstBillTo, userCompany,
    } = this.props;

    if (currCompanyRole.toUpperCase() === ROLE_PPJK) {
      const list = selectedPaymentBehalfOfRole
        && selectedPaymentBehalfOfRole.toUpperCase() === ROLE_CUSTOMER
        ? [lstPaymentBehalfOf.find(item => item.value === selectedPaymentBehalfOf)] : lstBillTo;

      return (
        <Grid item xs={12}>
          <Field
            name={RXFIELD_BILL_TO}
            component={renderReduxFormFilterableSelectField}
            options={list}
            label={LocalizedString.bookExport.fieldBillTo}
          />
        </Grid>
      );
    }
    const list = [{ label: userCompany.name, value: userCompany.id }];
    return (
      <Grid item xs={12}>
        <Field
          name={RXFIELD_BILL_TO}
          component={renderReduxFormFilterableSelectField}
          options={list}
          label={LocalizedString.bookExport.fieldBillTo}
        />
      </Grid>
    );
  };

  render() {
    const {
      classes,
      uploadingNewBooking,

      currCompanyRole,
      currCompanyName,
      userCompany,

      lstDgClass,
      lstDgSubclass,
      lstDO,
      lstDOObj,
      lstForwarder,
      lstPaymentBehalfOf,
      lstVessel,
      lstVoyage,
      downloadingVesselList,
      downloadingVoyageList,
      downloading,

      handleSubmit,
      onDoSelected,
      onDgClassSelected,
      onNextPressed,
      onVesselSelected,
      onVoyageSelected,
    } = this.props;


    return (
      <>
        <form onSubmit={handleSubmit(onNextPressed)}>
          <Typography className={classes.segmentHeader}>
            Document
          </Typography>
          <Grid container xs={12} spacing={24}>
            <Grid item xs={6}>
              <Field
                name={RXFIELD_DO_NO}
                component={renderReduxFormFilterableSelectAsyncField}
                disableDefaultOptions
                disabled={uploadingNewBooking}
                label={LocalizedString.bookExport.fieldDoNo}
                options={lstDO}
                onValueChange={value => onDoSelected(value, this.props.lstDOObj)}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name={RXFIELD_DO_DATE}
                component={renderReduxFormDateField}
                keyboard
                label={LocalizedString.bookExport.fieldDoDate}
                required
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name={RXFIELD_PEB_NO}
                component={renderReduxFormTextField}
                disabled={uploadingNewBooking}
                label={LocalizedString.bookExport.fieldPeb}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name={RXFIELD_PEB_DATE}
                component={renderReduxFormDateField}
                keyboard
                label={LocalizedString.bookExport.fieldPebDate}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <Field
                name={RXFIELD_DG_CLASS}
                component={renderReduxFormFilterableSelectAsyncField}
                label={LocalizedString.bookExport.fieldDGClass}
                options={lstDgClass}
                onValueChange={onDgClassSelected}
              />
            </Grid>

            <Grid item xs={6}>
              <Field
                name={RXFIELD_DG_SUB_CLASS}
                component={renderReduxFormFilterableSelectAsyncField}
                label={LocalizedString.bookExport.fieldDGSubClass}
                options={lstDgSubclass}
              />
            </Grid>
          </Grid>

          <Typography className={classes.segmentHeader}>
            Vessel
          </Typography>
          <Grid container xs={12} spacing={24}>
            <Grid item xs={6}>
              <Field
                name={RXFIELD_VESSEL}
                component={renderReduxFormTextField}
                // options={lstVessel}
                disabled
                label={LocalizedString.bookExport.fieldVessel}
                // onValueChange={value => onVesselSelected(value)}

              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name={RXFIELD_VOYAGE}
                component={renderReduxFormTextField}
                // options={lstVoyage}
                disabled
                label={LocalizedString.bookExport.fieldVoyage}
                // onValueChange={value => onVoyageSelected(value)}

              />
            </Grid>

            {/* <Grid item xs={6}>
              <Field
                name={RXFIELD_VOYAGE_TO}
                component={renderReduxFormTextField}
                readonly
                label={LocalizedString.bookExport.fieldVoyageTo}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name={RXFIELD_VOYAGE_TO_CLOSING_DATETIME_FORMATTED}
                component={renderReduxFormTextField}
                readonly
                label={LocalizedString.bookExport.fieldVoyageToClosingDate}
              />
              <Field
                name={RXFIELD_VOYAGE_TO_CLOSING_DATETIME}
                component={renderReduxFormTextField}
                readonly
                label={LocalizedString.bookExport.fieldVoyageToClosingDate}
                className={classes.displayNone}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name={RXFIELD_VOYAGE_ETD_FORMATTED}
                component={renderReduxFormTextField}
                readonly
                label={LocalizedString.bookExport.fieldEtd}
              />
              <Field
                name={RXFIELD_VOYAGE_ETD}
                component={renderReduxFormTextField}
                readonly
                label={LocalizedString.bookExport.fieldEtd}
                className={classes.displayNone}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name={RXFIELD_VOYAGE_ATA_FORMATTED}
                component={renderReduxFormTextField}
                readonly
                label={LocalizedString.bookExport.fieldAta}
              />
              <Field
                name={RXFIELD_VOYAGE_ATA}
                component={renderReduxFormTextField}
                readonly
                label={LocalizedString.bookExport.fieldAta}
                className={classes.displayNone}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name={RXFIELD_VOYAGE_VESSEL_OWNER}
                component={renderReduxFormTextField}
                readonly
                label={LocalizedString.bookExport.fieldVesselOwner}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name={RXFIELD_VOYAGE_VESSEL_ID}
                component={renderReduxFormTextField}
                readonly
                label={LocalizedString.bookExport.fieldVesselId}
              />
            </Grid>
            */}
            <Grid item xs={12}>
              <Field
                name={RXFIELD_VOYAGE_SHIPPING_LINE}
                component={renderReduxFormTextField}
                disabled
                label={LocalizedString.bookExport.fieldShippingLine}

              />
            </Grid>
            {/* <Grid item xs={12}>
              <Field
                name={RXFIELD_VOYAGE_PO_TRANSIT}
                component={renderReduxFormTextField}
                label={LocalizedString.bookExport.fieldPoTransit}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name={RXFIELD_VOYAGE_PO_DESTINATION}
                component={renderReduxFormTextField}
                label={LocalizedString.bookExport.fieldPoDestination}
                required
              />
            </Grid> */}
            <Grid item xs={12}>
              <Field
                name={RXFIELD_PAYMENT_ON_BEHALF_OF}
                component={renderReduxFormFilterableSelectField}
                options={lstPaymentBehalfOf}
                required
                label={LocalizedString.bookExport.fieldPaymentOnBehalfOf}
              />
            </Grid>

            {this.renderBillTo()}
          </Grid>

          <Grid container xs={12} spacing={24}>
            <Grid item xs={12}>
              <Field
                name={RXFIELD_FORWARDER_ID}
                component={renderReduxFormFilterableSelectField}
                options={this.getForwarderList(currCompanyRole, userCompany, lstForwarder)}
                label={LocalizedString.bookExport.fieldForwarder}
              />
            </Grid>
          </Grid>

          <div className={classes.dialogActions}>
            <div style={{ flexGrow: 1 }} />
            <div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                Next
              </Button>
            </div>
          </div>
        </form>
        {(uploadingNewBooking || downloadingVesselList || downloadingVoyageList || downloading)
          && <LoadingIndicator />}
      </>
    );
  }
}
FeederTab.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  uploadingNewBooking: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,

  currCompanyName: PropTypes.string.isRequired,
  currCompanyRole: PropTypes.string.isRequired,
  userCompany: PropTypes.shape(PropTypes.any).isRequired,

  lstDgClass: PropTypes.arrayOf(PropTypes.any).isRequired,
  lstDgSubclass: PropTypes.arrayOf(PropTypes.any).isRequired,
  lstDO: PropTypes.arrayOf(PropTypes.any).isRequired,
  lstDOObj: PropTypes.arrayOf(PropTypes.any).isRequired,
  lstBillTo: PropTypes.arrayOf(PropTypes.any).isRequired,
  lstForwarder: PropTypes.arrayOf(PropTypes.any).isRequired,
  lstPaymentBehalfOf: PropTypes.arrayOf(PropTypes.any).isRequired,
  lstVessel: PropTypes.arrayOf(PropTypes.any).isRequired,
  lstVoyage: PropTypes.arrayOf(PropTypes.any).isRequired,
  downloadingVesselList: PropTypes.bool.isRequired,
  downloadingVoyageList: PropTypes.bool.isRequired,

  selectedPaymentBehalfOf: PropTypes.string.isRequired,
  selectedPaymentBehalfOfRole: PropTypes.string.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onDoSelected: PropTypes.func.isRequired,
  onDgClassSelected: PropTypes.func.isRequired,
  onNextPressed: PropTypes.func.isRequired,
  onVesselSelected: PropTypes.func.isRequired,
  onVoyageSelected: PropTypes.func.isRequired,
};
FeederTab.defaultProps = {
};

export default reduxForm({
  form: RXFORM_BOOK_EXPORT_FEEDER,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  withStyles(styles)(FeederTab),
);
