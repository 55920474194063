import * as Action from '../action';
import * as Constant from '../../constant';

const initialState = {
  openSnackbarStatus: false,
  title: '',
  message: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.SHOW_SNACKBAR: {
      return {
        ...state,
        openSnackbarStatus: true,
        title: action.title,
        message: action.message,
        type: Constant.SNACKBAR_SUCCESS,
      };
    }
    case Action.SHOW_ERROR_SNACKBAR: {
      return {
        ...state,
        openSnackbarStatus: true,
        title: action.title,
        message: action.message,
        type: Constant.SNACKBAR_ERROR,
      };
    }
    case Action.HIDE_SNACKBAR: {
      return { ...state, openSnackbarStatus: false };
    }
    default: {
      return state;
    }
  }
};
