import {
  SET_ADMIN_PAYABLE_BL_PAYMENT_OWNER,
  SET_ADMIN_PAYABLE_BL,
  CLEAR_ADMIN_PAYABLE_BL,
  ADD_ADMIN_PAYMENT_DATA,
  CLEAR_ADMIN_PAYMENT_DATA,
  CLEAR_ADMIN_PAYABLE_BL_PAYMENT_OWNER,
} from '../action';

const initialState = {
  bl: {},
  paymentOwner: {},
  paymentData: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ADMIN_PAYABLE_BL:
      return { ...state, bl: action.value };
    case CLEAR_ADMIN_PAYABLE_BL:
      return { ...state, bl: initialState.bl };
    case SET_ADMIN_PAYABLE_BL_PAYMENT_OWNER:
      return { ...state, paymentOwner: action.value };
    case CLEAR_ADMIN_PAYABLE_BL_PAYMENT_OWNER:
      return { ...state, paymentOwner: initialState.paymentOwner };
    case ADD_ADMIN_PAYMENT_DATA:
      return { ...state, paymentData: { ...state.paymentData, ...action.value } };
    case CLEAR_ADMIN_PAYMENT_DATA:
      return { ...state, paymentData: initialState.paymentData };
    default: return state;
  }
};
