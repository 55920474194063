import { downloadingAssign, addAssignedBls } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;

  try {
    dispatch(downloadingAssign(true));
    const assign = await Helper.downloadAssign(token);
    dispatch(addAssignedBls(assign));
  } finally {
    dispatch(downloadingAssign(false));
  }
};
