import * as Action from '../action';

const initialState = {
  downloading: false,
  uploadingNewBooking: false,
  downloadingFeederServiceDisclaimer: false,
  error: undefined,
  selectedCompany: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.UPLOADING_NEW_BOOKING:
      return { ...state, uploadingNewBooking: action.status };
    case Action.DOWNLOADING_FEEDER_SERVICE_DISCLAIMER:
      return { ...state, downloadingFeederServiceDisclaimer: action.status };
    case Action.DOWNLOADING_BOOK_FEEDER_LIST:
      return { ...state, downloading: action.status };
    case Action.ADD_SELECTED_COMPANY:
      return { ...state, selectedCompany: [action.selectedCompany] };
    default: return state;
  }
};
