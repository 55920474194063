import loadImageToReduxStateAsync from './loadImageToReduxStateAsync';
import downloadProfilePictureAsync from './downloadProfilePictureAsync';

export default () => async (dispatch, getState) => {
  const { currentUser, images } = getState();
  const user = getState().users[currentUser.id];
  if (user) {
    const { profilePicture } = user;
    if (profilePicture) {
      if (!images[profilePicture]) {
        await dispatch(loadImageToReduxStateAsync(profilePicture));
      } else {
        await dispatch(downloadProfilePictureAsync(user.id));
      }
    }
  }
};
