import uuid from 'uuid/v4';
import * as Helper from '../../../helper';
import {
  uploadingBookExportPartyDocument,
  setBookExportActiveForm,
  addExportBookPriceInfo,
  addExportBookParty,
} from '../simple-action';
import { rxformValidateBookExportParty } from '../../../validation';

export default () => async (dispatch, getState) => {
  try {
    dispatch(uploadingBookExportPartyDocument(true));

    const { token } = getState().authentication;
    const {
      bookExportInfo, etd, ata, toClosingDateTime,
    } = getState().bookExport;

    const {
      ataFormatted, etdFormatted, toClosingDateTimeFormatted, ...bookExportInfos
    } = bookExportInfo;

    const bookInfo = {
      ...bookExportInfos,
      etd,
      ata,
      toClosingDateTime,
    };

    const response = await Helper.bookExportService(bookInfo, token);
    if (response.parties) {
      const parties = response.parties.map(item => ({ ...item, id: uuid() }));
      dispatch(addExportBookParty(parties));
    }
    if (response.price) {
      dispatch(addExportBookPriceInfo(response.price));
    }
  } finally {
    dispatch(uploadingBookExportPartyDocument(false));
  }
};
