import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import DocumentSubmissionDetail from './document-submission-detail.presentation';
import {
  clearDocSubmissionMyResponseDetail, downloadDocSubmissionMyResponseDetailAsync,
  downloadDocSubmissionMyResponseDetailFileAsync, showErrorSnackbar, showSnackbar,
} from '../../redux/action';
import {
  FIELD_TYPE_TEXT, RXFIELD_DOC_SUBMISSION_DETAIL_FORM,
  RXFIELD_DOC_SUBMISSION_DETAIL_STATUS, RXFIELD_DOC_SUBMISSION_DETAIL_STATUS_NOTES,
  RXFIELD_DOC_SUBMISSION_DETAIL_SUBMIT_DATE, RXFIELD_DOC_SUBMISSION_DETAIL_SUBMIT_ID,
  RXFIELD_DOC_SUBMISSION_DETAIL_USER, RXFIELD_NEW_DOCUMENT_PAYMENT_ON_BEHALF,
  MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';

const getInitialValues = (myResponse) => {
  if (!myResponse) return {};
  const result = {
    submitId: {
      type: FIELD_TYPE_TEXT,
      name: RXFIELD_DOC_SUBMISSION_DETAIL_SUBMIT_ID,
      label: LocalizedString.submitRequest.labelSubmitId,
      value: myResponse.submitId || '-',
    },
    submitDate: {
      type: FIELD_TYPE_TEXT,
      name: RXFIELD_DOC_SUBMISSION_DETAIL_SUBMIT_DATE,
      label: LocalizedString.submitRequest.labelSubmitDate,
      value: toMoment(myResponse.submitDate).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING) || '-',
    },
    status: {
      type: FIELD_TYPE_TEXT,
      name: RXFIELD_DOC_SUBMISSION_DETAIL_STATUS,
      label: LocalizedString.submitRequest.labelStatus,
      value: myResponse.status || '-',
    },
    statusNotes: {
      type: FIELD_TYPE_TEXT,
      name: RXFIELD_DOC_SUBMISSION_DETAIL_STATUS_NOTES,
      label: LocalizedString.submitRequest.labelStatusNotes,
      value: myResponse.statusNotes || '-',
    },
    form: {
      type: FIELD_TYPE_TEXT,
      name: RXFIELD_DOC_SUBMISSION_DETAIL_FORM,
      label: LocalizedString.submitRequest.labelForm,
      value: myResponse.form ? myResponse.form.title : '-',
    },
    user: {
      type: FIELD_TYPE_TEXT,
      name: RXFIELD_DOC_SUBMISSION_DETAIL_USER,
      label: LocalizedString.submitRequest.labelUser,
      value: myResponse.user ? myResponse.user.fullName : '-',
    },
    paymentOnBehalf: {
      type: FIELD_TYPE_TEXT,
      name: RXFIELD_NEW_DOCUMENT_PAYMENT_ON_BEHALF,
      label: LocalizedString.submitRequest.labelPaymentOnBehalf,
      value: myResponse.paymentOnBehalf ? myResponse.paymentOnBehalf.name : '-',
    },
    response: myResponse.response ? myResponse.response.reduce((arr, item) => {
      arr.push({
        type: item.field.type,
        name: item.field.name,
        label: item.field.label,
        value: item.value,
        order: item.field.order,
      });
      return arr;
    }, []).sort((a, b) => a.order - b.order) : [],
  };
  return result;
};

const mapStateToProps = state => ({
  tappedId: state.uiDocSubmissionMyResponse.tappedId,
  initialValues: getInitialValues(state.docSubmissionMyResponseDetail),
  downloadingDetail: state.uiDocSubmissionMyResponse.downloadingDetail,
  downloadingFile: state.uiDocSubmissionMyResponse.downloadingFile,
});

const mapDispatchToProps = dispatch => ({
  onAppear: async (tappedId) => {
    if (tappedId) {
      dispatch(clearDocSubmissionMyResponseDetail());
      dispatch(downloadDocSubmissionMyResponseDetailAsync(tappedId));
    }
  },
  onDownloadFilePressed: (file) => {
    dispatch(downloadDocSubmissionMyResponseDetailFileAsync(file))
      .then((data) => {
        const fileName = file.split('/')[4] || 'file';
        const linkSource = data;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        dispatch(showSnackbar(
          LocalizedString.common.labelSuccess,
          LocalizedString.submitRequest.downloadFileSuccess,
        ));
      })
      .catch((error) => {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      });
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentSubmissionDetail));
