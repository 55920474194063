import * as Action from '../action';

const initialState = {
  progress: 0,
  filePath: '',
  sourceUrl: '',
  loadingComplete: false,
  tappedTitle: '',

  openViewer: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case Action.SET_PDF_VIEWER_PROGRESS:
    //   return { ...state, progress: action.progress };
    // case Action.SET_PDF_VIEWER_DOWNLOADED_FILE_PATH:
    //   return { ...state, filePath: action.path };
    case Action.SET_PDF_VIEWER_SOURCE_URL:
      return { ...state, sourceUrl: action.url };
    // case Action.SET_PDF_VIEWER_LOADING_COMPLETE:
    //   return { ...state, loadingComplete: action.status };
    case Action.SET_TAPPED_PDF_VIEWER_TITLE:
      return { ...state, tappedTitle: action.tappedTitle };
    case Action.SET_PDF_VIEWER_STATUS:
      return { ...state, openViewer: action.value };
    default: return state;
  }
};
