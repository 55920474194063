import * as Action from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case Action.ADD_ERP_PAYMENT_LOG_DETAIL:
      return action.data;
    default:
      return state;
  }
};
