import { connect } from 'react-redux';
import _, { isEmpty } from 'lodash';
import FieldBase from './field.presentation';
import * as Constant from '../../constant';
import {
  setFieldFilter,
  setFieldPaging,
  setFieldSort,
  setFieldAddDialogStatus,
  setFieldEditDialogStatus,
  setFieldSelectedId,
  showErrorSnackbar,
  setFieldDetailDialogStatus,
  setFieldDeleteDialogStatus,
  downloadAllFieldAsync,
  downloadAllFormAsync,
  setFormSort,
  setFieldFilterDialogStatus,
  setTempFilterForm,
} from '../../redux/action';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const getFilteredFields = (state) => {
  const filteredFields = _.values(state.fields.data)
    .map((item, index) => ({ ...item, index: index + 1 }));

  const { sortField, sortDirection } = state.uiField;

  const sorted = filteredFields
    .sort((left, right) => {
      if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
        return left[sortField] - right[sortField];
      }
      return right[sortField] - left[sortField];
    });

  return sorted;
};

const getPageFilteredFields = (state) => {
  const { filters } = state.uiField;
  const filteredField = getFilteredFields(state);

  if (!isEmpty(filteredField)) {
    let filteredData = filteredField;

    if (filters.formName !== '') {
      filteredData = filteredField.filter(
        x => x.form.id.toLowerCase().includes(filters.formName.toLowerCase()),
      );
    }
    if (filters.name !== '') {
      filteredData = filteredField.filter(
        x => x.name.toLowerCase().includes(filters.name.toLowerCase()),
      );
    }
    return filteredData;
  }

  return filteredField;
};

const mapStateToProps = state => ({
  downloading: state.uiField.downloading,
  menuOpen: state.uiMenu.menuOpen,
  fields: getPageFilteredFields(state),

  filters: state.uiField.filters,
  activePage: state.uiField.activePage,
  pageSize: state.uiField.pageSize,
  totalPage: state.fields.meta.totalPages,
  sortField: state.uiField.sortField,
  sortDirection: state.uiField.sortDirection,

  dialogAddStatus: state.uiField.dialogAddStatus,
  dialogEditStatus: state.uiField.dialogEditStatus,
  dialogDetailStatus: state.uiField.dialogDetailStatus,
  dialogDeleteStatus: state.uiField.dialogDeleteStatus,
  dialogFilterStatus: state.uiField.dialogFilterStatus,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    try {
      dispatch(setFieldAddDialogStatus(false));
      dispatch(setFieldEditDialogStatus(false));
      dispatch(setFieldDetailDialogStatus(false));
      dispatch(setFieldDeleteDialogStatus(false));
      dispatch(setFieldFilterDialogStatus(false));
      dispatch(setFieldFilter(Constant.TABLE_FIELD_FIELD_NAME, ''));
      dispatch(setFieldFilter(Constant.RXFIELD_FIELD_FORM, ('')));
      dispatch(setFieldPaging(Constant.RXTABLEFIELD_PAGE_SIZE, 20));
      dispatch(setFieldPaging(Constant.RXTABLEFIELD_ACTIVE_PAGE, 0));

      await dispatch(downloadAllFieldAsync());
      dispatch(setFieldSort('order', Constant.TABLE_SORT_ASCENDING));
      await dispatch(downloadAllFormAsync());
      dispatch(setFormSort('order', Constant.TABLE_SORT_ASCENDING));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onChangeFilter: async (filterName, value) => {
    dispatch(setFieldFilter(filterName, value));
    dispatch(setFieldSort('order', Constant.TABLE_SORT_ASCENDING));
    await dispatch(downloadAllFieldAsync());
  },
  onChangePaging: async (pageField, value) => {
    dispatch(setFieldPaging(pageField, value));
    dispatch(setFieldSort('order', Constant.TABLE_SORT_ASCENDING));
    await dispatch(downloadAllFieldAsync(value));
  },
  onChangeRow: async (pageField, value) => {
    dispatch(setFieldPaging(pageField, value));
    dispatch(setFieldSort('order', Constant.TABLE_SORT_ASCENDING));
    await dispatch(downloadAllFieldAsync());
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setFieldSort(sortField, sortDirection));
  },
  onAddNewPressed: () => {
    dispatch(setFieldAddDialogStatus(true));
  },
  onAddDialogClosePressed: () => {
    dispatch(setFieldAddDialogStatus(false));
  },
  onDeletePressed: (userId) => {
    dispatch(setFieldSelectedId(userId));
    dispatch(setFieldDeleteDialogStatus(true));
  },
  onDeleteDialogClosePressed: () => {
    dispatch(setFieldDeleteDialogStatus(false));
  },
  onDetailPressed: (userId) => {
    dispatch(setFieldSelectedId(userId));
    dispatch(setFieldDetailDialogStatus(true));
  },
  onDetailDialogClosePressed: () => {
    dispatch(setFieldDetailDialogStatus(false));
  },
  onEditPressed: (userId) => {
    dispatch(setFieldSelectedId(userId));
    dispatch(setFieldEditDialogStatus(true));
  },
  onEditDialogClosePressed: () => {
    dispatch(setFieldEditDialogStatus(false));
  },
  onFilterPressed: () => {
    dispatch(setFieldFilterDialogStatus(true));
  },
  onFilterDialogClosePressed: () => {
    dispatch(setFieldFilterDialogStatus(false));
    dispatch(setTempFilterForm(''));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FieldBase);
