import * as Action from '../action';

const initialState = {
  assignTruckForm: {
    containerNo: undefined,
    blNo: undefined,
    vendorId: undefined,
    truckNo: undefined,
  },
  submitting: false,
  searchingVendorList: false,
  searchingTruckList: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.ADD_ASSIGN_TRUCK:
      return { ...state, assignTruckForm: { ...action.assignTruck } };
    case Action.REMOVE_ASSIGN_TRUCK:
      return initialState;
    case Action.UPLOADING_ASSIGN_TRUCK:
      return { ...state, submitting: action.status };
    case Action.SEARCHING_ASSIGN_TRUCK_VENDOR_LIST:
      return { ...state, searchingVendorList: action.status };
    case Action.SEARCHING_TRUCK_LIST:
      return { ...state, searchingTruckList: action.status };
    default: return state;
  }
};
