import _ from 'lodash';
import { downloadingFeederServicePriceDetail, addFeederServicePriceDetail } from '../simple-action';
import * as Helper from '../../../helper';

export default blNo => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const selectedBl = _.values(getState().feederServiceOrderHistories)
    .find(item => item.refNo === blNo);
  try {
    dispatch(downloadingFeederServicePriceDetail(true));
    const exportPriceDetail = await Helper.downloadFeederServicePriceDetail(selectedBl.refNo, selectedBl.paymentOnBehalf, token);
    dispatch(addFeederServicePriceDetail(exportPriceDetail));
  } finally {
    dispatch(downloadingFeederServicePriceDetail(false));
  }
};
