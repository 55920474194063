import * as Helper from '../../../helper';
import { validateAssignClearance } from '../../../validation';
import { uploadingAssignClearance, addAssignedBl, removeMultiSelectAssignBl } from '../simple-action';
import downloadAssignAsync from './downloadAssignAsync';

export default assignClearance => async (dispatch, getState) => {
  try {
    dispatch(uploadingAssignClearance(true));

    const { token } = getState().authentication;
    const assignedBls = getState()
      .uiAssignMultipleClearance
      .selectedBls
      .map(item => ({
        soNo: item.soNo,
        blNo: item.blNo,
        forwarderId: assignClearance.forwarderId,
        truckerId: assignClearance.truckerId,
        isTrucker: assignClearance.isTrucker,
      }));
    for (let i = 0; i < assignedBls.length; i += 1) {
      const item = assignedBls[i];
      validateAssignClearance(item);

      // eslint-disable-next-line no-await-in-loop
      await Helper.uploadAssignClearance(item, token);
      const { exim, billTo } = getState().assign[item.blNo];
      const assignedBl = {
        soNo: item.soNo,
        blNo: item.blNo,
        forwarderId: item.forwarderId,
        exim,
        billTo,
      };
      dispatch(addAssignedBl(assignedBl));
      dispatch(removeMultiSelectAssignBl(assignedBl));
    }
  } finally {
    dispatch(uploadingAssignClearance(false));
  }
};
