import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Icon,
} from '@material-ui/core';

const styles = () => ({
  dialogRoot: {
    height: '100%',
  },
  dialogContentRoot: {
    overflowY: 'hidden',
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
});

const PdfViewerDialog = ({
  classes,
  open,
  sourceUrl,
  title,

  onClosePressed,
}) => {
  const renderDialogContent = () => {
    if (open) {
      return (<iframe src={sourceUrl} title={title} className={classes.iframe} />);
    }
    return null;
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        classes={{
          paper: classes.dialogRoot,
        }}
      >
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
            <Typography color="primary" variant="h6">
              {title}
            </Typography>
            <IconButton
              onClick={onClosePressed}
            >
              <Icon>close</Icon>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
          {renderDialogContent()}
        </DialogContent>
      </Dialog>
    </>
  );
};
PdfViewerDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  open: PropTypes.bool.isRequired,
  sourceUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,

  onClosePressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(PdfViewerDialog);
