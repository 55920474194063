import * as Action from '../action';

const initialState = {
  uploadingProfilePicture: false,
  uploadingProfileDetail: false,
  downloadingProfilePicture: false,
  removingProfilePicture: false,
  updatingPassword: false,
  updatingPin: false,
  loggingOut: false,
  editable: false,
  error: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.UPLOADING_PROFILE_PICTURE:
      return { ...state, uploadingProfilePicture: action.status };
    case Action.UPLOADING_PROFILE_DETAILS:
      return { ...state, uploadingProfileDetail: action.status };
    case Action.DOWNLOADING_PROFILE_PICTURE:
      return { ...state, downloadingProfilePicture: action.status };
    case Action.REMOVING_PROFILE_PICTURE:
      return { ...state, removingProfilePicture: action.status };
    case Action.UPDATING_PASSWORD:
      return { ...state, updatingPassword: action.status };
    case Action.UPDATING_PIN:
      return { ...state, updatingPin: action.status };
    case Action.SET_PROFILE_SCREEN_EDIT_MODE:
      return { ...state, editable: action.status };
    default: return state;
  }
};
