import { downloadingCustomerCompanyList, addCustomerCompanyList } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { id } = getState().currentUser.company;

  try {
    dispatch(downloadingCustomerCompanyList(true));
    const customerCompanyList = await Helper.downloadCustomerCompanyList(id, token);
    dispatch(addCustomerCompanyList(customerCompanyList));
  } finally {
    dispatch(downloadingCustomerCompanyList(false));
  }
};
