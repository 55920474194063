import {
  addMyPaymentTermOrder, addingMyPaymentTermOrderItem,
  setExportPasswordTextInput, setExportPasswordInputDialog,
} from '../simple-action';
import { uploadTermOrderItem } from '../../../helper';
import LocalizedString from '../../../localization';

export default (item, serviceName, password) => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { blNo, totalAmount } = item;

  try {
    dispatch(addingMyPaymentTermOrderItem(true));

    if (!password) {
      throw new Error(LocalizedString.common.errMsgEmptyPassword);
    }

    const itemSent = {
      blNumber: blNo,
      service: serviceName,
      amount: totalAmount,
      // paymentOnBehalfOf,
    };

    const itemGot = await uploadTermOrderItem(itemSent, token, password);

    dispatch(addMyPaymentTermOrder(itemGot));

    dispatch(setExportPasswordInputDialog(false));
  } finally {
    dispatch(addingMyPaymentTermOrderItem(false));
  }
};
