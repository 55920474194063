import _ from 'lodash';
import * as Action from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case Action.ADD_CHECKOUT_ITEM:
      return { ...state, [action.item.itemId]: action.item };
    case Action.REMOVE_CHECKOUT_ITEM:
      return _.omit(state, action.itemId);
    case Action.REMOVE_ALL_CHECKOUT_ITEMS:
      return {};
    default: return state;
  }
};
