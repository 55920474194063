import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import ContainerTrackingBlCard from './bl-card.presentation';
import {
  showErrorSnackbar,
  setTappedExportBlNo,
  downloadExportOrderHistoryDetailAsync,
  downloadExportPriceDetailAsync,
} from '../../redux/action';
import LocalizedString from '../../localization';
import * as Constant from '../../constant';
import { unauthorizedErrorHandler } from '../../helper';

const getFilteredOrderHistory = state => _.values(state.exportOrderHistory)
  .filter(item => item.blNo.toUpperCase()
    .indexOf(state.uiExport.searchBarText.toUpperCase()) >= 0);

const mapStateToProps = state => ({
  billingLines: getFilteredOrderHistory(state),
  selectedBlNo: state.uiExport.tappedBlNo,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onMorePress: async (tappedBlNo) => {
    const p1 = dispatch(setTappedExportBlNo(tappedBlNo));
    const p2 = dispatch(downloadExportOrderHistoryDetailAsync(tappedBlNo));
    const p3 = dispatch(downloadExportPriceDetailAsync(tappedBlNo));

    Promise.all([p1, p2, p3])
      .catch((error) => {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
        unauthorizedErrorHandler(error, ownProps.history.push);
      });
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContainerTrackingBlCard));
