import { connect } from 'react-redux';
import EmailSubmission from './email-submission.presentation';
import {
  clearUIError, setUIError, registerEmailAsync,
} from '../../redux/action';
import * as Constant from '../../constant';

const mapStateToProps = state => ({
  submitting: state.uiRegistration.submittingEmail,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: async (email) => {
    try {
      dispatch(clearUIError(Constant.RXSTATE_REGISTRATION_PAGE));

      await dispatch(registerEmailAsync(email, ownProps.history.push));
    } catch (error) {
      dispatch(setUIError(Constant.RXSTATE_REGISTRATION_PAGE, error.message));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailSubmission);
