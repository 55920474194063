import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  withStyles,
  CssBaseline,
  Card,
  CardContent,
  Fab,
  Icon,
  Grid,
  Typography,
} from '@material-ui/core';
import { PageHeaderUser } from '../../component';
import * as Constant from '../../constant';
import LocalizedString from '../../localization';

const styles = theme => ({
  backIcon: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  buttonSend: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing.unit,
    ...Constant.DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  contactIcon: {
    width: 18,
    height: 18,
    lineHeight: 18,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${Constant.DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  inlineFlex: {
    display: 'inline-flex',
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    color: '#0266B4',
  },
  root: {
    width: '100%',
  },
  socialMediaLink: {
    marginLeft: theme.spacing.unit * 2,
  },
  socialMediaIcon: {
    width: '32px',
    height: '32px',
    borderRadius: '8px',
  },
});

const ContactUsPage = (props) => {
  const {
    classes,
    notificationOpen,
    socialMedia,
  } = props;
  return (
    <div className={classes.root}>
      <CssBaseline />

      <PageHeaderUser />

      <div
        className={
          classNames(classes.content, {
            [classes.contentDrawerOpen]: notificationOpen,
            [classes.contentDrawerClosed]: !notificationOpen,
          })
        }
        style={{ padding: '16px' }}
      >
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <div className={classes.pageNavigatorContainer}>
                  <Fab
                    variant="round"
                    classes={{ root: classes.backIcon }}
                    component={Link}
                    to={Constant.ROUTE_NAME_DASHBOARD_USER}
                  >
                    <Icon>chevron_left</Icon>
                  </Fab>
                  <div style={{ marginLeft: '32px' }}>
                    <Typography variant="h5" className={classes.pageTitle}>{LocalizedString.contactUs.title}</Typography>
                    <Typography variant="caption">{LocalizedString.contactUs.caption}</Typography>
                  </div>
                  <div className={classes.grow} />
                  <div className={classes.inlineFlex}>
                    {socialMedia.map(item => (
                      <a href={item.link} key={item.link} className={classes.socialMediaLink}>
                        <img
                          src={item.imgPath}
                          alt={item.link}
                          className={classes.socialMediaIcon}
                        />
                      </a>
                    ))}
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6">{LocalizedString.contactUs.labelOfficeLocation}</Typography>
                <Grid container spacing={24}>
                  <Grid item xs={12} md={6}>
                    <iframe
                      title="CDP Location"
                      src={Constant.MAP_URL_CONTACT_US}
                      height="450"
                      frameBorder="0"
                      style={{ border: 0, width: '100%' }}
                      allowFullScreen
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <div style={{ display: 'inline-flex' }}>
                      <div style={{ display: 'inline-flex', marginRight: '48px' }}>
                        <img
                          src={Constant.IMAGE_ICON_PHONE}
                          className={classes.contactIcon}
                          alt={LocalizedString.contactUs.phoneAlt}
                        />
                        &emsp;
                        <Typography variant="body1">{LocalizedString.contactUs.phoneNumber}</Typography>
                      </div>

                      <div style={{ display: 'inline-flex' }}>
                        <img
                          src={Constant.IMAGE_ICON_MESSAGE}
                          className={classes.contactIcon}
                          alt={LocalizedString.contactUs.messageAlt}
                        />
                        &emsp;
                        <Typography variant="body1">{LocalizedString.contactUs.messageNumber}</Typography>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
ContactUsPage.propTypes = {
  classes: PropTypes.shape(PropTypes.any),
  notificationOpen: PropTypes.bool,
  socialMedia: PropTypes.arrayOf(
    PropTypes.shape({
      imgPath: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
};
ContactUsPage.defaultProps = {
  classes: {},
  notificationOpen: false,
  submittingMessage: false,
  socialMedia: [],
};

export default withStyles(styles)(ContactUsPage);
