import * as Helper from '../../../helper';
import * as Action from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(Action.downloadingVendors(true));
    const { token } = getState().authentication;
    const vendors = await Helper.downloadAllVendor(token);
    // vendors.forEach((vendor) => {
    //   dispatch(Action.addVendor(vendor));
    // });
    dispatch(Action.addVendor(vendors));
  } finally {
    dispatch(Action.downloadingVendors(false));
  }
};
