import * as Helper from '../../../helper';
import * as Action from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(Action.downloadingForwarders(true));
    // const { token } = getState().authentication;
    // const forwarders = await Helper.downloadAllForwarders(token);
    // forwarders.forEach((fwd) => {
    //   dispatch(Action.addForwarder(fwd));
    // });

    const dummyFwds = [
      {
        id: 'NYK NWL',
        name: 'PT. NYK New Wave Logistics Indonesia',
        addr1: 'MM2100 Industrial Town Blok EE-4',
        npwp: '34.2232-41123-123',
        city: 'Cikarang Barat Bekasi',
        province: 'Indonesia 17520',
      },
      {
        id: 'DHL',
        name: 'PT. DHL Global Forwarding Indonesia',
        addr1:
          'Soewarna Business Park Block A Lot 8 Soekarno Hatta Intl. Airport',
        npwp: '',
        city: 'Cengkareng Jakarta',
        province: 'Indonesia 19110',
      },
    ];
    dispatch(Action.removeAllForwarders());
    dummyFwds.forEach((fwd) => {
      dispatch(Action.addForwarder(fwd));
    });
  } finally {
    dispatch(Action.downloadingForwarders(false));
  }
};
