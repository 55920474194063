import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import DepositHistoryDetail from './deposit-history-detail.presentation';
import {
  TABLE_SORT_ASCENDING,
  TABLE_FIELD_DEPOSIT_HISTORY_TYPE,
  TABLE_FIELD_DEPOSIT_HISTORY_AMOUNT,
  TABLE_FIELD_DEPOSIT_HISTORY_INVOICE_NO,
  TABLE_FIELD_DEPOSIT_HISTORY_DATE,
  MOMENT_DATE_FORMAT_STRING,
  TRANSACTION_TYPE_PURCHASE,
  ROUTE_NAME_MY_PAYMENT,
  TABLE_FIELD_END_DATE,
  TABLE_FIELD_START_DATE,
} from '../../constant';
import {
  setDepositHistoryFilter,
  setDepositHistoryPaging,
  setDepositHistorySort,
  setMyPaymentHistorySearchText,
  setMyPaymentSelectedTab,
  setTappedPaymentHistoryProformaNo,
  downloadDepositHistoryCsvAsync,
  downloadDepositHistoryAsync,
  showErrorSnackbar,
  setDepositHistoryValidationMessages,
} from '../../redux/action';
import LocalizedString from '../../localization';

const formatDate = (val) => {
  const date = new Date(val);
  const day = date.toLocaleString('default', { day: '2-digit' });
  const month = date.toLocaleString('default', { month: '2-digit' });
  const year = date.toLocaleString('default', { year: 'numeric' });
  return `${year}-${month}-${day}`;
};

const getFilteredDepositHistory = (state) => {
  const exportOrderHistoryDetail = _.values(state.depositHistory);
  const { filters, sortField, sortDirection } = state.uiDepositHistory;

  return exportOrderHistoryDetail.sort((left, right) => {
    if (typeof left[sortField] === 'string') {
      if (sortDirection === TABLE_SORT_ASCENDING) {
        return left[sortField].localeCompare(right[sortField]);
      }
      return right[sortField].localeCompare(left[sortField]);
    }

    if (sortDirection === TABLE_SORT_ASCENDING) {
      return left[sortField] - right[sortField];
    }
    return right[sortField] - left[sortField];
  })
    .filter(item => item.type.toUpperCase().indexOf(
      filters[TABLE_FIELD_DEPOSIT_HISTORY_TYPE].toUpperCase(),
    ) >= 0)
    .filter(item => String(item.amount).indexOf(
      filters[TABLE_FIELD_DEPOSIT_HISTORY_AMOUNT].toUpperCase(),
    ) >= 0)
    .filter(item => (item.referenceNo
      ? item.referenceNo.toUpperCase()
        .indexOf(filters[TABLE_FIELD_DEPOSIT_HISTORY_INVOICE_NO].toUpperCase()) >= 0
      : true))
    .filter((item) => {
      if (!filters[TABLE_FIELD_DEPOSIT_HISTORY_DATE]) {
        return true;
      }

      const formattedRegisteredOn = moment(item.date)
        .format(MOMENT_DATE_FORMAT_STRING);
      const formattedFilter = moment(filters[TABLE_FIELD_DEPOSIT_HISTORY_DATE])
        .format(MOMENT_DATE_FORMAT_STRING);
      return formattedRegisteredOn === formattedFilter;
    });
};

const getPageFilteredDepositHistory = (state) => {
  const {
    activePage, pageSize,
  } = state.uiDepositHistory;

  const filteredOrderHistoryDetail = getFilteredDepositHistory(state);

  if (filteredOrderHistoryDetail) {
    const refinedActivePage = activePage || 1;
    const start = Math.min((refinedActivePage - 1) * pageSize,
      filteredOrderHistoryDetail.length);
    const end = Math.min(start + pageSize, filteredOrderHistoryDetail.length);
    return filteredOrderHistoryDetail.slice(start, end);
  }

  return undefined;
};

const getTotalPage = (state) => {
  const exportOrderDetail = getFilteredDepositHistory(state);
  const { pageSize } = state.uiExport;
  const totalData = exportOrderDetail ? exportOrderDetail.length : 0;

  return Math.max(Math.ceil(totalData / pageSize), 1);
};

const checkDate = (filters, dispatch) => {
  const { startDate, endDate } = filters;
  if (Date.parse(startDate) > Date.parse(endDate)) {
    dispatch(
      setDepositHistoryValidationMessages(
        TABLE_FIELD_START_DATE,
        LocalizedString.depositHistory.errMsgStartDate,
      ),
    );
    return false;
  }
  if (Date.parse(endDate) < Date.parse(startDate)) {
    dispatch(
      setDepositHistoryValidationMessages(
        TABLE_FIELD_END_DATE,
        LocalizedString.depositHistory.errMsgEndDate,
      ),
    );
    return false;
  }
  return true;
};

const mapStateToProps = state => ({
  depositHistory: getPageFilteredDepositHistory(state),

  pageSize: state.uiDepositHistory.pageSize,
  totalPage: getTotalPage(state),
  activePage: state.uiDepositHistory.activePage,
  filters: state.uiDepositHistory.filters,
  validationMessages: state.uiDepositHistory.validationMessages,
  sortField: state.uiDepositHistory.sortField,
  sortDirection: state.uiDepositHistory.sortDirection,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onBtnDownloadHistory: (filters) => {
    const check = checkDate(filters, dispatch);
    if (check) {
      dispatch(setDepositHistoryValidationMessages(TABLE_FIELD_START_DATE, ''));
      dispatch(setDepositHistoryValidationMessages(TABLE_FIELD_END_DATE, ''));
      dispatch(downloadDepositHistoryCsvAsync())
        .catch(error => dispatch(showErrorSnackbar(
          LocalizedString.common.alertTitleError,
          error.message,
        )));
    }
  },
  onOpenPressed: (item) => {
    if (item.type === TRANSACTION_TYPE_PURCHASE) {
      const { referenceNo } = item;
      const blNo = referenceNo.length > 10 ? referenceNo.slice(0, 10) : referenceNo;
      dispatch(setMyPaymentHistorySearchText(blNo));
      dispatch(setTappedPaymentHistoryProformaNo(item.referenceNo));
      dispatch(setMyPaymentSelectedTab(2));
      ownProps.history.push(ROUTE_NAME_MY_PAYMENT);
    }
  },
  onChangeFilter: (filterName, value) => {
    const newValue = filterName === TABLE_FIELD_START_DATE || filterName === TABLE_FIELD_END_DATE
      ? formatDate(value) : value;
    dispatch(setDepositHistoryFilter(filterName, newValue));
  },
  onChangePaging: (pageField, value) => {
    dispatch(setDepositHistoryPaging(pageField, value));
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setDepositHistorySort(sortField, sortDirection));
  },
  onShowButtonPressed: (filters) => {
    const check = checkDate(filters, dispatch);
    if (check) {
      dispatch(setDepositHistoryValidationMessages(TABLE_FIELD_START_DATE, ''));
      dispatch(setDepositHistoryValidationMessages(TABLE_FIELD_END_DATE, ''));
      dispatch(downloadDepositHistoryAsync())
        .catch(error => dispatch(showErrorSnackbar(
          LocalizedString.common.alertTitleError,
          error.message,
        )));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DepositHistoryDetail);
