import { downloadingFeederServiceBookPartyList, addFeederServiceBookPartyList } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { bookNo } = getState().uiBookFeederParty;

  // const bookNo = 'FI-180625-153554';
  try {
    dispatch(downloadingFeederServiceBookPartyList(true));
    const bookPartyList = await Helper.downloadFeederServiceBookPartyList(bookNo, token);
    dispatch(addFeederServiceBookPartyList(bookPartyList));
  } finally {
    dispatch(downloadingFeederServiceBookPartyList(false));
  }
};
