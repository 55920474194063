import { downloadingNewDocumentMyFormDetail, setNewDocumentMyFormDetail } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { authentication, uiNewDocumentMyForm } = getState();
  const { token } = authentication;
  const { tappedId } = uiNewDocumentMyForm;

  try {
    dispatch(downloadingNewDocumentMyFormDetail(true));
    const data = await Helper.downloadNewDocumentMyFormDetail(token, tappedId);
    dispatch(setNewDocumentMyFormDetail({ ...data, formKey: Date.now() }));
  } finally {
    dispatch(downloadingNewDocumentMyFormDetail(false));
  }
};
