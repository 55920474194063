import * as Action from '../action';

const initialState = {
  errorMsg: '',
  submitting: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.SUBMITTING_REGISTER_PASSWORD:
      return { ...state, submitting: action.status };
    default: return state;
  }
};
