import {
  uploadingAdminPayment, showSnackbar, setDialogAdminPaymentSuccessStatus, setAdminPaymentResult, showErrorSnackbar, clearShoppingCartAdminPayment,
} from '../simple-action';
import { uploadAdminPayment, openNewTab } from '../../../helper';
import { URL_PAYMENT, PAYMENT_TYPE_DEPOSIT } from '../../../constant';
import downloadAdminPaymentHistoryAsync from './downloadAdminPaymentHistoryAsync';
import LocalizedString from '../../../localization';

export default pin => async (dispatch, getState) => {
  try {
    dispatch(uploadingAdminPayment(true));

    const { token } = getState().authentication;
    const itemsToPay = Object.values(getState().shoppingCartAdminPayment);

    const data = {
      items: itemsToPay.map(paymentData => ({
        refId: paymentData.invoiceNo,
        paymentOwner: paymentData.paymentOwner,
      })),
      pin,
    };

    const useDeposit = (itemsToPay[0].billToPaymentMethod.toUpperCase() === PAYMENT_TYPE_DEPOSIT
          && itemsToPay[0].allowPpjkToUseDeposit)
        || itemsToPay[0].paymentOwnerPaymentMethod.toUpperCase() === PAYMENT_TYPE_DEPOSIT;

    const result = await uploadAdminPayment(data, token);
    if (!useDeposit) {
      openNewTab(URL_PAYMENT.replace(/\{id\}/, result.paymentId));
    } else {
      dispatch(setAdminPaymentResult({
        ...result,
        amount: itemsToPay.reduce((acc, curr) => acc + curr.amount, 0),
      }));
      dispatch(setDialogAdminPaymentSuccessStatus(true));
    }
    dispatch(clearShoppingCartAdminPayment());
    dispatch(downloadAdminPaymentHistoryAsync())
      .catch((error) => {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      });
  } finally {
    dispatch(uploadingAdminPayment(false));
  }
};
