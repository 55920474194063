import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { change, reset } from 'redux-form';
import _, { isEmpty } from 'lodash';
import FieldEditDialog from './field-edit-dialog.presentation';
import {
  showErrorSnackbar,
  uploadUpdateFieldAsync,
  setFieldEditDialogStatus,
  downloadFieldDetailAsync,
  downloadAllFieldAsync,
  setFormFilter,
  setFieldSort,
} from '../../redux/action';
import {
  BOOLEAN_OPTION,
  RXFORM_FIELD_EDIT,
  RXFIELD_FIELD_FORM,
  RXFIELD_FIELD_REQUIRED,
  RXFIELD_FIELD_TYPE,
  TABLE_SORT_ASCENDING,
  RXFIELD_FIELD_STATUS,
  STATUS_ENABLED,
  status,
} from '../../constant';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler, getDataType } from '../../helper';

const getFilteredForms = (state) => {
  const filteredForms = _.values(state.forms.data)
    .map((item, index) => ({ ...item, index: index + 1 }));

  const sorted = filteredForms
    .map(x => ({
      label: x.title,
      value: x.id,
    }))
    .sort((left, right) => (left.label.toLowerCase().localeCompare(right.label.toLowerCase())));

  return sorted;
};

const getPageFilteredForms = (state) => {
  const { filters } = state.uiForm;
  const { formName } = filters;
  const filteredForm = getFilteredForms(state);

  if (!isEmpty(filteredForm)) {
    if (formName !== '') {
      const form = filteredForm.filter(
        x => (x.label ? x.label.toLowerCase().includes(formName.toLowerCase()) : ''),
      );
      return form;
    }
    return filteredForm;
  }

  return [];
};

const getUserEdit = (state) => {
  const {
    fields, uiField,
  } = state;
  const { data } = fields;
  const { selectedFieldId } = uiField;

  const fieldEdit = (data && (selectedFieldId !== '')) ? data[selectedFieldId] : {};

  if (!isEmpty(fieldEdit)) {
    return {
      formName: fieldEdit.form.id,
      name: fieldEdit.name,
      label: fieldEdit.label,
      order: fieldEdit.order,
      required: fieldEdit.required ? BOOLEAN_OPTION[0].value : BOOLEAN_OPTION[1].value,
      type: getDataType(fieldEdit.type),
      status: fieldEdit.status === STATUS_ENABLED ? status[0] : status[1],
    };
  }

  return {
    [RXFIELD_FIELD_FORM]: '',
    name: '',
    label: '',
    order: 0,
    required: BOOLEAN_OPTION[1],
    type: { label: '', value: '' },
  };
};

const mapStateToProps = state => ({
  initialValues: getUserEdit(state),
  loading: false,
  downloadingForm: state.uiForm.downloading,
  forms: getPageFilteredForms(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    try {
      await dispatch(downloadFieldDetailAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    }
  },
  onSubmitPressed: async (values) => {
    const formValues = {
      ...values,
      status: values.status.value ? values.status.value : values.status,
    };
    try {
      await dispatch(uploadUpdateFieldAsync(formValues));
      dispatch(setFieldSort('order', TABLE_SORT_ASCENDING));
      await dispatch(downloadAllFieldAsync());
      dispatch(setFieldEditDialogStatus(false));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onFormSelected: (option) => {
    dispatch(change(RXFORM_FIELD_EDIT, RXFIELD_FIELD_FORM, option));
  },
  onFormTextChanged: async (text) => {
    dispatch(setFormFilter(RXFIELD_FIELD_FORM, text));
  },
  onRequiredSelected: (option) => {
    dispatch(change(RXFORM_FIELD_EDIT, RXFIELD_FIELD_REQUIRED, option));
  },
  onTypeSelected: (option) => {
    dispatch(change(RXFORM_FIELD_EDIT, RXFIELD_FIELD_TYPE, option));
  },
  onStatusSelected: (option) => {
    dispatch(change(RXFORM_FIELD_EDIT, RXFIELD_FIELD_STATUS, option));
  },
  onClose: () => {
    dispatch(setFieldEditDialogStatus(false));
    dispatch(reset(RXFORM_FIELD_EDIT));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FieldEditDialog));
