import * as Action from '../action';

const initialState = {
  assignContainerForm: {
    containerNo: undefined,
    blNo: undefined,
    vendorId: undefined,
    truckNo: undefined,
    ctrSize: undefined,
    ctrType: undefined,
  },
  submitting: false,
  searchingVendorList: false,
  searchingTruckList: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.ADD_ASSIGN_CONTAINER_ADD:
      return { ...state, assignContainerForm: { ...action.assignContainerAdd } };
    case Action.REMOVE_ASSIGN_CONTAINER_ADD:
      return initialState;
    case Action.UPLOADING_ASSIGN_CONTAINER_ADD:
      return { ...state, submitting: action.status };
    case Action.SEARCHING_ASSIGN_TRUCK_VENDOR_LIST:
      return { ...state, searchingVendorList: action.status };
    case Action.SEARCHING_TRUCK_LIST:
      return { ...state, searchingTruckList: action.status };
    // case Action.SEARCHING_ASSIGN_CONTAINER_ADD_VENDOR_LIST:
    //   return { ...state, searchingVendorList: action.status };
    // case Action.SEARCHING_ASSIGN_CONTAINER_TRUCK_LIST:
    //   return { ...state, searchingTruckList: action.status };
    default: return state;
  }
};
