import * as Action from '../action';

const initialState = {
  paymentId: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.ADD_PAYMENT_ID:
      return { ...state, paymentId: action.id };
    default:
      return state;
  }
};
