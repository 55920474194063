import * as Action from '../action';

const markAsRead = (state, id) => ({
  [id]: {
    ...state[id],
    read: true,
  },
});

export default (state = {}, action) => {
  switch (action.type) {
    case Action.ADD_NOTIFICATION:
      return { ...action.value };
    case Action.SET_NOTIFICATION_AS_READ:
      return { ...state, ...markAsRead(state, action.id) };
    default: return state;
  }
};
