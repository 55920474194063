import {
  addMyPaymentTermOrder,
  addingMyPaymentTermOrderItem,
  setTariffSimulationPasswordInputDialog,
} from '../simple-action';
import { uploadTermOrderItem } from '../../../helper';
import LocalizedString from '../../../localization';

export default (item, serviceName, password) => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { blNo, totalPayment } = item;

  try {
    dispatch(addingMyPaymentTermOrderItem(true));

    if (!password) {
      throw new Error(LocalizedString.common.errMsgEmptyPassword);
    }

    const itemSent = {
      blNumber: blNo,
      service: serviceName,
      amount: totalPayment,
      info: getState().uiTariffSimulation.chosenDate,
      paymentOnBehalfOf: getState().uiTariffSimulation.selectedPaymentBehalfOf,
    };

    const itemGot = await uploadTermOrderItem(itemSent, token, password);

    dispatch(addMyPaymentTermOrder(itemGot));

    dispatch(setTariffSimulationPasswordInputDialog(false));
  } finally {
    dispatch(addingMyPaymentTermOrderItem(false));
  }
};
