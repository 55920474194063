import * as Action from '../action';

const initialState = {
  activeForm: 0,
  openDialogStatus: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.SET_BOOK_EXPORT_ACTIVE_FORM:
      return { ...state, activeForm: action.value };
    case Action.SET_NEW_BOOKING_EXPORT_DIALOG_STATUS:
      return { ...state, openDialogStatus: action.value };
    default:
      return state;
  }
};
