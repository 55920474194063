import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  Typography,
  Icon,
  Button,
} from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import { LoadingIndicator } from '../../component';
import LocalizedString from '../../localization';
import {
  DEFAULT_STYLES,
  RXFIELD_FIELD_TYPE,
  type,
  RXFIELD_FIELD_REQUIRED,
  BOOLEAN_OPTION,
  RXFIELD_FIELD_ORDER,
  RXFIELD_FIELD_LABEL,
  RXFIELD_FIELD_NAME,
  RXFIELD_FIELD_FORM,
  RXFORM_FIELD_EDIT,
  RXFIELD_FIELD_STATUS,
  status,
} from '../../constant';
import {
  renderReduxFormTextField,
  renderReduxFormFilterableSelectField,
  renderReduxFormFilterableSelectAsyncField,
} from '../../helper';

const styles = () => ({
  btnPrimary: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  dialogActions: {
    margin: '0 -24px',
    marginTop: '24px',
    padding: 24,
    borderTop: '1px solid #C4C4C4',
    justifyContent: 'space-between',
    paddingBottom: 0,
    display: 'flex',
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
});

const FieldEditDialog = ({
  classes,
  open,
  loading,
  forms,
  downloadingForm,
  currentUserRole,

  handleSubmit,
  onSubmitPressed,
  onAppear,
  onClose,
  onFormSelected,
  onFormTextChanged,
  onRequiredSelected,
  onTypeSelected,
  onStatusSelected,
}) => (
  <>
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onEnter={() => onAppear(currentUserRole)}
    >
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <Typography color="primary" variant="h6">
            {LocalizedString.field.editTite}
          </Typography>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit(onSubmitPressed)}>
          <Field
            name={RXFIELD_FIELD_FORM}
            component={renderReduxFormFilterableSelectAsyncField}
            options={forms}
            label={LocalizedString.field.labelForm}
            onValueChange={onFormSelected}
            disabled={downloadingForm}
            onLazyLoad={onFormTextChanged}
          />
          <Field
            name={RXFIELD_FIELD_NAME}
            component={renderReduxFormTextField}
            label={LocalizedString.field.labelName}
            required
          />
          <Field
            name={RXFIELD_FIELD_LABEL}
            component={renderReduxFormTextField}
            label={LocalizedString.field.labelLabel}
            required
          />
          <Field
            name={RXFIELD_FIELD_ORDER}
            component={renderReduxFormTextField}
            label={LocalizedString.field.labelOrder}
            required
            type="number"
          />
          <Field
            name={RXFIELD_FIELD_REQUIRED}
            component={renderReduxFormFilterableSelectField}
            options={BOOLEAN_OPTION}
            label={LocalizedString.field.labelRequired}
            onValueChange={onRequiredSelected}
            required
          />
          <Field
            name={RXFIELD_FIELD_TYPE}
            component={renderReduxFormFilterableSelectField}
            options={type}
            label={LocalizedString.field.labelType}
            onValueChange={onTypeSelected}
            required
          />
          <Field
            name={RXFIELD_FIELD_STATUS}
            component={renderReduxFormFilterableSelectField}
            options={status}
            label={LocalizedString.field.labelStatus}
            onValueChange={onStatusSelected}
            required
          />
          <div className={classes.dialogActions}>
            <div style={{ flexGrow: 1 }} />
            <div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.btnPrimary}
                disabled={loading}
              >
                Update
              </Button>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
    {loading && <LoadingIndicator />}
  </>
);
FieldEditDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  downloadingForm: PropTypes.bool.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onFormSelected: PropTypes.func.isRequired,
  onFormTextChanged: PropTypes.func.isRequired,
  onRequiredSelected: PropTypes.func.isRequired,
  onTypeSelected: PropTypes.func.isRequired,
  onStatusSelected: PropTypes.func.isRequired,
};

export default reduxForm({
  form: RXFORM_FIELD_EDIT,
  enableReinitialize: true,
})(withStyles(styles)(FieldEditDialog));
