import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Button,
} from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import {
  DEFAULT_STYLES,
  RXFIELD_CONTAINER_NO,
  RXFIELD_BL_NO,
  RXFIELD_VENDOR_ID,
  RXFIELD_TRUCK_NO,
  RXFORM_ASSIGN_CONTAINER_TRUCK,
  RXFIELD_ASSIGN_CONTAINER_TYPE,
  RXFIELD_ASSIGN_CONTAINER_SIZE,
  ROLE_PPJK,
  ROLE_CUSTOMER,
  DOC_NOT_RELEASED_STATUS,
  CART_SERVICE_NAME_EXPORT,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormTextField, renderReduxFormFilterableSelectAsyncField } from '../../helper';
import { AssignContainerShape } from '../../type';

const styles = () => ({
  btnPrimary: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  dialogActions: {
    margin: '0 -24px',
    marginTop: '24px',
    padding: 24,
    borderTop: '1px solid #C4C4C4',
    justifyContent: 'space-between',
    paddingBottom: 0,
    display: 'flex',
  },
});


class AssignContainerTruck extends Component {
  handleSubmitPressed = (values) => {
    const { onSubmitPressed, selectedAssignBl } = this.props;
    onSubmitPressed(values, selectedAssignBl);
  }

  render() {
    const {
      classes,

      lstVendor,
      lstTruck,

      selectedAssignBl,
      assignContainer,
      userRole,
      handleSubmit,
      onPreviousPressed,
      onSelectedVendor,
      onSelectedTruckNo,
    } = this.props;

    if (!selectedAssignBl) {
      return null;
    }
    const disableSubmit = (userRole === ROLE_PPJK && !!assignContainer.gateIn)
      || (userRole === ROLE_CUSTOMER && !!assignContainer.forwarderId);
    const disableSelectTruck = assignContainer.status === DOC_NOT_RELEASED_STATUS
      || disableSubmit;

    const titleLabel = selectedAssignBl
      .exim.toUpperCase() !== CART_SERVICE_NAME_EXPORT.toUpperCase()
      ? LocalizedString.assign.labelBlNumber
      : LocalizedString.assign.labelDoNo;

    const disableContainerNo = selectedAssignBl.exim
      .toUpperCase() !== CART_SERVICE_NAME_EXPORT.toUpperCase();

    return (
      <>
        <form>
          <Field
            name={RXFIELD_CONTAINER_NO}
            component={renderReduxFormTextField}
            disabled={disableContainerNo}
            required={!disableContainerNo}
            label={LocalizedString.assign.labelContainerNo}
          />
          <Field
            name={RXFIELD_BL_NO}
            component={renderReduxFormTextField}
            disabled
            label={titleLabel}
          />

          <Field
            name={RXFIELD_ASSIGN_CONTAINER_SIZE}
            component={renderReduxFormTextField}
            disabled
            label={LocalizedString.assign.labelCtrSize}
          />
          <Field
            name={RXFIELD_ASSIGN_CONTAINER_TYPE}
            component={renderReduxFormTextField}
            disabled
            label={LocalizedString.assign.labelCtrType}
          />

          <Field
            name={RXFIELD_VENDOR_ID}
            component={renderReduxFormFilterableSelectAsyncField}
            options={lstVendor}
            disabled={disableSubmit}
            label={LocalizedString.assign.labelVendor}
            onValueChange={onSelectedVendor}
          />

          <Field
            name={RXFIELD_TRUCK_NO}
            component={renderReduxFormFilterableSelectAsyncField}
            options={lstTruck}
            disabled={disableSelectTruck}
            label={LocalizedString.assign.labelTrucker}
            onValueChange={onSelectedTruckNo}
          />
        </form>
        <div className={classes.dialogActions}>
          <div>
            <Button
              type="button"
              color="primary"
              onClick={onPreviousPressed}
            >
              Previous
            </Button>
          </div>
          <div style={{ flexGrow: 1 }} />
          <div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.btnPrimary}
              disabled={disableSubmit}
              onClick={handleSubmit(this.handleSubmitPressed)}
            >
              Submit
            </Button>
          </div>
        </div>
      </>
    );
  }
}
AssignContainerTruck.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,

  lstVendor: PropTypes.arrayOf(PropTypes.any).isRequired,
  lstTruck: PropTypes.arrayOf(PropTypes.any).isRequired,

  selectedAssignBl: PropTypes.shape(PropTypes.any).isRequired,
  assignContainer: AssignContainerShape.isRequired,
  userRole: PropTypes.string.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onPreviousPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onSelectedVendor: PropTypes.func.isRequired,
  onSelectedTruckNo: PropTypes.func.isRequired,
};

export default reduxForm({
  form: RXFORM_ASSIGN_CONTAINER_TRUCK,
  enableReinitialize: true,
})(withStyles(styles)(AssignContainerTruck));
