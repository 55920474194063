import * as Action from '../action';

const initialState = {
  bookExportInfo: {
    doNo: undefined,
    doDate: undefined,
    pebNo: undefined,
    pebDate: undefined,

    vessel: undefined,
    voyage: undefined,
    toid: undefined,
    toClosingDateTime: undefined,
    shippingLineCode: undefined,
    vesselId: undefined,
    etd: undefined,
    ata: undefined,
    poTransit: undefined,
    poDestination: undefined,
    vesselOwner: undefined,

    billTo: undefined,

    gross: undefined,
    containerVolume: undefined,
    containerType: undefined,
    containerAmount: undefined,
    containerBrand: undefined,
    containerHsc: undefined,
    containerDescription: undefined,
    containerConsignee: undefined,
    containerConsigneeAddress: undefined,

    forwarderId: undefined,
  },
  etd: undefined,
  ata: undefined,
  toClosingDateTime: undefined,
  priceInfo: {
    adminFee: 0,
    taxBasis: 0,
    totalPayment: 0,
    vat: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.ADD_BOOK_EXPORT_SERVICE:
      return { ...state, bookExportInfo: action.bookExportServiceInfo };
    case Action.ADD_VOYAGE_DETAIL:
      return {
        ...state,
        bookExportInfo: { ...action.voyageDetail },
        etd: action.voyageDetail.etd,
        ata: action.voyageDetail.ata,
        toClosingDateTime: action.voyageDetail.toClosingDateTime,
      };
    case Action.CLEAR_VOYAGE_DETAIL: {
      return {
        ...state,
        vessel: undefined,
        voyage: undefined,
        toid: undefined,
        toClosingDateTime: undefined,
        shippingLine: undefined,
        vesselId: undefined,
        etd: undefined,
        ata: undefined,
        poTransit: undefined,
        poDestination: undefined,
        vesselOwner: undefined,
      };
    }
    case Action.REMOVE_BOOK_EXPORT_SERVICE_INFO:
      return initialState;
    case Action.ADD_EXPORT_BOOK_PRICE_INFO:
      return { ...state, priceInfo: { ...action.priceInfo } };
    default: return state;
  }
};
