import * as Action from '../action';
import { OBJ_KEY_ASSIGN } from '../../constant';

export default (state = {}, action) => {
  switch (action.type) {
    case Action.ADD_ASSIGNED_BLS:
      return { ...action.assign };
    case Action.ADD_ASSIGNED_BL: {
      const { assign } = action;
      return { ...state, [assign[OBJ_KEY_ASSIGN]]: assign };
    }
    default: return state;
  }
};
