import _ from 'lodash';
import { ADD_SHOPPING_CART_ADMIN_PAYMENT, REMOVE_SHOPPING_CART_ADMIN_PAYMENT, CLEAR_SHOPPING_CART_ADMIN_PAYMENT } from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case ADD_SHOPPING_CART_ADMIN_PAYMENT:
      return { ...state, [action.value.invoiceNo]: action.value };
    case REMOVE_SHOPPING_CART_ADMIN_PAYMENT:
      return _.omit(state, action.value);
    case CLEAR_SHOPPING_CART_ADMIN_PAYMENT:
      return {};
    default: return state;
  }
};
