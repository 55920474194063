import { downloadingFeederServiceWarehouseList, addFeederServiceWarehouseList } from '../simple-action';
import * as Helper from '../../../helper';

export default billTo => async (dispatch, getState) => {
  const { token } = getState().authentication;
  try {
    dispatch(downloadingFeederServiceWarehouseList(true));
    const feederServiceWarehouseList = await Helper.downloadFeederServiceWarehouseList(billTo, token);
    dispatch(addFeederServiceWarehouseList(feederServiceWarehouseList));
  } finally {
    dispatch(downloadingFeederServiceWarehouseList(false));
  }
};
