import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import uuid from 'uuid/v4';
import _ from 'lodash';
import TermDetail from './term-detail.presentation';
import {
  addToShoppingCartAsync,
  showErrorSnackbar,
  requestPrintAsync,
  setPdfViewerStatus,
} from '../../redux/action';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';
import {
  PRINT_TYPE_INVOICE,
  PRINT_TYPE_KMT,
  PRINT_TYPE_SP2,
} from '../../constant';

const getSelectedBlInfo = state => _.values(state.myTermOrder)
  .find(item => item.invoiceNo.toLowerCase() === state.uiMyPaymentTerm.tappedBlNo.toLowerCase());

const mapStateToProps = state => ({
  selectedBl: getSelectedBlInfo(state),
  shoppingCart: _.values(state.shoppingCart),
  addingShoppingCartItem: state.uiShoppingCart.addingItem,
  userCompany: state.currentUser.company,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddToCartPressed: async (termOrder) => {
    const itemId = uuid();
    const { blNumber, amount, service } = termOrder;
    try {
      await dispatch(addToShoppingCartAsync(itemId, blNumber, service, amount, termOrder));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onInvoicePressed: async (item) => {
    try {
      const data = {
        printType: PRINT_TYPE_INVOICE,
        invoiceNo: item.invoiceNo,
        blNo: item.blNumber,
        service: item.service,
      };
      await dispatch(requestPrintAsync(data));
      dispatch(setPdfViewerStatus(true));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onKmtPressed: async (item) => {
    try {
      const data = {
        printType: PRINT_TYPE_KMT,
        invoiceNo: item.invoiceNo,
        blNo: item.blNumber,
        service: item.service,
      };
      await dispatch(requestPrintAsync(data));
      dispatch(setPdfViewerStatus(true));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onSp2Pressed: async (item) => {
    try {
      const data = {
        printType: PRINT_TYPE_SP2,
        invoiceNo: item.invoiceNo,
        blNo: item.blNumber,
        service: item.service,
      };
      await dispatch(requestPrintAsync(data));
      dispatch(setPdfViewerStatus(true));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(TermDetail));
