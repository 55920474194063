import * as Constant from '../../constant';
import * as Action from '../action';

const initialState = {
  downloadingForwarders: false,
  filters: {
    [Constant.TABLE_FIELD_MASTER_FORWARDER_ID]: '',
    [Constant.TABLE_FIELD_MASTER_FORWARDER_NAME]: '',
    [Constant.TABLE_FIELD_MASTER_FORWARDER_ADDR]: '',
    [Constant.TABLE_FIELD_MASTER_FORWARDER_NPWP]: '',
    [Constant.TABLE_FIELD_MASTER_FORWARDER_CITY]: '',
    [Constant.TABLE_FIELD_MASTER_FORWARDER_PROVINCE]: '',
  },
  sortField: '',
  sortDirection: '',
  activePage: 1,
  pageSize: 5,
  selectedForwarderId: null,
  dialogAddStatus: false,
  dialogDetailStatus: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.SET_MASTER_FORWARDER_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.value,
        },
      };
    }
    case Action.SET_MASTER_FORWARDER_PAGING: {
      return { ...state, ...action.value };
    }
    case Action.SET_MASTER_FORWARDER_SORT: {
      return { ...state, sortField: action.sortField, sortDirection: action.sortDirection };
    }
    case Action.SET_MASTER_FORWARDER_DETAIL_DIALOG_STATUS: {
      return { ...state, dialogDetailStatus: action.value };
    }
    case Action.SET_MASTER_FORWARDER_ADD_DIALOG_STATUS: {
      return { ...state, dialogAddStatus: action.value };
    }
    case Action.SET_MASTER_FORWARDER_SELECTED_ID: {
      return { ...state, selectedForwarderId: action.value };
    }
    case Action.DOWNLOADING_FORWARDER_LIST: {
      return { ...state, downloadingForwarders: action.value };
    }
    default: {
      return state;
    }
  }
};
