import * as Helper from '../../../helper';
import * as Action from '../simple-action';

export default () => async (dispatch, getState) => {
  const { authentication, uiField } = getState();
  const { token } = authentication;
  const {
    selectedFieldId,
  } = uiField;

  try {
    dispatch(Action.downloadingField(true));
    const form = await Helper.downloadFieldDetail(token, selectedFieldId);
    dispatch(Action.addField(form));
  } finally {
    dispatch(Action.downloadingField(false));
  }
};
