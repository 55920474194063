import * as Action from '../action';
import * as Constant from '../../constant';

const initialState = {
  downloading: false,
  uploading: false,

  dialogAddStatus: false,
  dialogEditStatus: false,
  selectedUserId: '',

  filters: {
    [Constant.TABLE_FIELD_MASTER_USER_INDEX]: '',
    [Constant.TABLE_FIELD_MASTER_USER_USERNAME]: '',
    [Constant.TABLE_FIELD_MASTER_USER_EMAIL]: '',
    [Constant.TABLE_FIELD_MASTER_USER_FULLNAME]: '',
    [Constant.TABLE_FIELD_MASTER_USER_COMPANY_NAME]: '',
    [Constant.TABLE_FIELD_MASTER_USER_REGISTERED_ON]: '',
  },
  sortField: '',
  sortDirection: '',
  activePage: 1,
  pageSize: 5,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.DOWNLOADING_MASTER_USER:
      return { ...state, downloading: action.value };
    case Action.UPLOADING_MASTER_USER:
      return { ...state, uploading: action.value };
    case Action.SET_MASTER_USER_SELECTED_ID:
      return { ...state, selectedUserId: action.value };
    case Action.SET_MASTER_USER_ADD_DIALOG_STATUS:
      return { ...state, dialogAddStatus: action.value };
    case Action.SET_MASTER_USER_EDIT_DIALOG_STATUS:
      return { ...state, dialogEditStatus: action.value };
    case Action.SET_MASTER_USER_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.value,
        },
      };
    }
    case Action.SET_MASTER_USER_PAGING: {
      return { ...state, ...action.value };
    }
    case Action.SET_MASTER_USER_SORT: {
      return { ...state, sortField: action.sortField, sortDirection: action.sortDirection };
    }
    default:
      return state;
  }
};
