import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router';
import PageHeader from './page-header-user.presentation';
import {
  toggleNotificationDrawer,
  logoutAsync,
  clearUIError,
  setUIError,
  downloadMyNotificationAsync,
  uploadNotificationReadStatusAsync,
  downloadingMyNotification,
} from '../../redux/action';
import * as Constant from '../../constant';
import LocalizedString from '../../localization';

let interval;

const getNotifications = state => _.values(state.notification)
  .filter(item => item.destination === state.users[state.currentUser.id].username)
  .sort((left, right) => {
    if (left.receivedOn < right.receivedOn) {
      return 1;
    }
    if (left.receivedOn > right.receivedOn) {
      return -1;
    }
    return 0;
  });

const mapStateToProps = state => ({
  loggingOut: state.uiWebUserMain.loggingOut,
  notificationOpen: state.uiWebUserMain.notificationDrawerOpen,
  notifications: getNotifications(state),
  shoppingCart: _.values(state.shoppingCart),
  globalLoading: state.uiWebUserMain.globalLoading,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {},
  onToggleNotificationPressed: (drawerState, notifications) => {
    if (drawerState) {
      notifications.filter(item => !item.read)
        .map(item => dispatch(uploadNotificationReadStatusAsync(item.id)));
    }
    dispatch(toggleNotificationDrawer(drawerState));
  },
  onMarkAsReadOnly: (notifications) => {
    notifications.filter(item => !item.read)
      .map(item => dispatch(uploadNotificationReadStatusAsync(item.id)));
  },
  onLogoutButtonPressed: async () => {
    try {
      dispatch(clearUIError(Constant.RXSTATE_WEB_USER_MAIN));
      await dispatch(logoutAsync(ownProps.history));
    } catch (error) {
      dispatch(setUIError(Constant.RXSTATE_WEB_USER_MAIN, error.message));
    }
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageHeader));
