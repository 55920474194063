import {
  loggingIn, addToken, removeToken, setUIError,
} from '../simple-action';
import downloadMyProfileAsync from './downloadMyProfileAsync';
import downloadProfilePictureAsync from './downloadProfilePictureAsync';
import * as Helper from '../../../helper';
import LocalizedString from '../../../localization';
import * as Constant from '../../../constant';

export default (username, password, navigateTo) => async (dispatch, getState) => {
  if (username === '' || password === '') {
    throw new Error(LocalizedString.loginScreen.errEmptyUsernamePassword);
  }

  try {
    dispatch(loggingIn(true));
    const auth = await Helper.login(username, password);
    dispatch(addToken(auth.token));

    await dispatch(downloadMyProfileAsync());

    const { id, role, corporateAdmin } = getState().currentUser;
    if (role === Constant.ROLE_ADMIN || corporateAdmin) {
      await dispatch(downloadProfilePictureAsync(id));

      // navigateTo(Constant.ROUTE_NAME_USER_MANAGEMENT);
      navigateTo(Constant.ROUTE_NAME_MASTER_USERS);
    } else if (role === Constant.ROLE_BILLING) {
      navigateTo(Constant.ROUTE_NAME_ADMIN_PAYMENT_HISTORY);
    } else if (role !== Constant.ROLE_ADMIN) {
      await dispatch(downloadProfilePictureAsync(id));

      navigateTo(Constant.ROUTE_NAME_DASHBOARD_USER);
    } else {
      dispatch(removeToken());
      dispatch(setUIError(Constant.RXSTATE_LOGIN_PAGE, LocalizedString.loginScreen.errNotAdmin));
    }
  } finally {
    dispatch(loggingIn(false));
  }
};
