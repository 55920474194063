import * as Action from '../action';

const initialState = {
  downloadingFeederServiceList: false,
  downloadingFeederServiceOrderHistories: false,
  tappedBlNo: '',
  tappedItemNo: '',
  searchBarText: '',

  passwordInputDialog: false,
  password: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.DOWNLOADING_FEEDER_SERVICE_ORDER_HISTORIES:
      return { ...state, downloadingFeederServiceOrderHistories: action.status };
    case Action.SET_TAPPED_FEEDER_SERVICE_BL_NO:
      return { ...state, tappedBlNo: action.tappedBlNo };
    case Action.SET_TAPPED_FEEDER_SERVICE_ITEM_NO:
      return { ...state, tappedItemNo: action.tappedItemNo };
    case Action.SET_FEEDER_SERVICE_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case Action.SET_FEEDER_SERVICE_PASSWORD_INPUT_DIALOG:
      return { ...state, passwordInputDialog: action.status };
    case Action.SET_FEEDER_SERVICE_PASSWORD_TEXT_INPUT:
      return { ...state, password: action.password };
    case Action.DOWNLOADING_FEEDER_SERVICE_BL_LIST:
      return { ...state, downloadingFeederServiceList: action.status };
    default: return state;
  }
};
