import { connect } from 'react-redux';
import MainMenu from './main-menu.presentation';
import * as Constant from '../../constant';
import LocalizedString from '../../localization';

const getMenuItems = (state) => {
  if (state.currentUser.role === Constant.ROLE_CUSTOMER) {
    return (
      [
        {
          menuId: Constant.MENU_ID_DASHBOARD,
          menuName: LocalizedString.masterMenu.labelDashboard,
          url: Constant.ROUTE_NAME_DASHBOARD_USER,
        },
        {
          menuId: Constant.MENU_ID_USER_MANAGEMENT,
          menuName: LocalizedString.masterMenu.labelMasterUser,
          url: Constant.ROUTE_NAME_MASTER_USERS,
        },
      ]
    );
  }

  if (state.currentUser.role === Constant.ROLE_BILLING) {
    return [
      {
        menuId: Constant.MENU_ID_ADMIN_PAYMENT_HISTORY,
        menuName: LocalizedString.masterMenu.labelAdminPaymentHistory,
        url: Constant.ROUTE_NAME_ADMIN_PAYMENT_HISTORY,
      },
      {
        menuId: Constant.MENU_ID_ADMIN_ERP_PAYMENT_LOG,
        menuName: LocalizedString.masterMenu.labelAdminErpPaymentLog,
        url: Constant.ROUTE_NAME_ADMIN_ERP_PAYMENT_LOG,
      },
    ];
  }

  if (state.currentUser.role === Constant.ROLE_ADMIN) {
    return (
      [
        {
          menuId: Constant.MENU_ID_USER_MANAGEMENT,
          menuName: LocalizedString.masterMenu.labelMasterUser,
          url: Constant.ROUTE_NAME_MASTER_USERS,
        },
        {
          menuId: Constant.MENU_ID_ADMIN_PAYMENT_HISTORY,
          menuName: LocalizedString.masterMenu.labelAdminPaymentHistory,
          url: Constant.ROUTE_NAME_ADMIN_PAYMENT_HISTORY,
        },
        {
          menuId: Constant.MENU_ID_ADMIN_ERP_PAYMENT_LOG,
          menuName: LocalizedString.masterMenu.labelAdminErpPaymentLog,
          url: Constant.ROUTE_NAME_ADMIN_ERP_PAYMENT_LOG,
        },
        {
          menuId: Constant.MENU_ID_FORM,
          menuName: LocalizedString.masterMenu.labelForm,
          url: Constant.ROUTE_NAME_FORM,
        },
        {
          menuId: Constant.MENU_ID_FIELD,
          menuName: LocalizedString.masterMenu.labelField,
          url: Constant.ROUTE_NAME_FIELD,
        },
        {
          menuId: Constant.MENU_ID_RESPONSE,
          menuName: LocalizedString.masterMenu.labelResponse,
          url: Constant.ROUTE_NAME_RESPONSE,
        },
      ]
    );
  }

  return [
    {
      menuId: Constant.MENU_ID_USER_MANAGEMENT,
      menuName: LocalizedString.masterMenu.labelMasterUser,
      url: Constant.ROUTE_NAME_MASTER_USERS,
    },
    {
      menuId: Constant.MENU_ID_ADMIN_PAYMENT_HISTORY,
      menuName: LocalizedString.masterMenu.labelAdminPaymentHistory,
      url: Constant.ROUTE_NAME_ADMIN_PAYMENT_HISTORY,
    },
    // hide all history hingga demo
    // {
    //   menuId: Constant.MENU_ID_HISTORY_IMPORT,
    //   menuName: LocalizedString.masterMenu.labelHistoryImport,
    //   url: Constant.ROUTE_NAME_TARIFF_SIMULATION,
    // },
    // {
    //   menuId: Constant.MENU_ID_HISTORY_EXPORT,
    //   menuName: LocalizedString.masterMenu.labelHistoryExport,
    //   url: Constant.ROUTE_NAME_EXPORT_HISTORY,
    // },
    // {
    //   menuId: Constant.MENU_ID_HISTORY_TRUCKING,
    //   menuName: LocalizedString.masterMenu.labelHistoryTrucking,
    //   url: Constant.ROUTE_NAME_TRUCKING_HISTORY,
    // },
    // {
    //   menuId: Constant.MENU_ID_HISTORY_CONTAINER_TRACKING,
    //   menuName: LocalizedString.masterMenu.labelHistoryContainerTracking,
    //   url: Constant.ROUTE_NAME_CONTAINER_TRACKING_HISTORY,
    // },

    // {
    //   menuId: Constant.MENU_ID_HISTORY_PAYMENT,
    //   menuName: LocalizedString.masterMenu.labelHistoryPayment,
    //   url: Constant.ROUTE_NAME_USER_PAYMENT_HISTORY,
    // },
  ];
};

const mapStateToProps = state => ({
  open: state.uiMenu.menuOpen,
  menuItems: getMenuItems(state),
});

const mapDispatchToState = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToState)(MainMenu);
