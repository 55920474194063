import { downloadingFeederServiceDisclaimer, setFeederServiceDisclaimer } from '../simple-action';
import { downloadFeederServiceDisclaimer } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  try {
    dispatch(downloadingFeederServiceDisclaimer(true));
    const disclaimer = await downloadFeederServiceDisclaimer(token);
    dispatch(setFeederServiceDisclaimer(disclaimer));
  } finally {
    dispatch(downloadingFeederServiceDisclaimer(false));
  }
};
