import { connect } from 'react-redux';
import EmailSubmission from './email-submission.presentation';
import {
  forgetPasswordAsync, submittingForgetPassword, clearUIError, setUIError,
} from '../../redux/action';
import * as Constant from '../../constant';

const mapStateToProps = state => ({
  errorMsg: state.uiForgetPassword.error,
  submitting: state.uiForgetPassword.submittingRequest,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: async (email) => {
    try {
      dispatch(submittingForgetPassword(true));
      dispatch(clearUIError(Constant.RXSTATE_FORGET_PASSWORD_PAGE));

      await dispatch(forgetPasswordAsync(email, ownProps.history.push));
    } catch (error) {
      dispatch(setUIError(Constant.RXSTATE_FORGET_PASSWORD_PAGE, error.message));
    } finally {
      dispatch(submittingForgetPassword(false));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailSubmission);
