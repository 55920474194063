import * as Action from '../action';
import * as Constant from '../../constant';

const initialState = {
  downloadingAssignBl: false,
  searchBarText: '',
  tappedBlNo: '',
  tappedItemNo: '',
  tappedService: '',

  multiTappedBl: [],

  dialogAssignContainerStatus: false,
  dialogAssignClearanceStatus: false,
  dialogAddContainerStatus: false,
  dialogBulkAssignClearanceStatus: false,

  filters: {
    [Constant.TABLE_FIELD_ORDER_TYPE]: '',
    [Constant.TABLE_FIELD_STATUS]: '',
    [Constant.TABLE_FIELD_CONSIGNEE]: '',
    [Constant.TABLE_FIELD_TRUCKER]: '',
  },
  sortField: '',
  sortDirection: '',
  activePage: 1,
  pageSize: 5,
};

const removeMultiTappedBl = (multiTappedBl, toRemove) => multiTappedBl
  .filter(item => !(item.blNo === toRemove.blNo && item.exim === toRemove.exim));

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.DOWNLOADING_ASSIGN:
      return { ...state, downloadingAssignBl: action.status };
    case Action.SET_TAPPED_ASSIGN_BL_NO:
      return { ...state, tappedBlNo: action.tappedBlNo };
    // case Action.SET_TAPPED_ASSIGN_ITEM_NO:
    //   return { ...state, tappedItemNo: action.tappedItemNo };
    case Action.SET_TAPPED_ASSIGN_BASE_SERVICE:
      return { ...state, tappedService: action.tappedService };
    case Action.SET_ASSIGN_CONTAINER_DIALOG_STATUS:
      return { ...state, dialogAssignContainerStatus: action.value };
    case Action.SET_ASSIGN_CLEARANCE_DIALOG_STATUS:
      return { ...state, dialogAssignClearanceStatus: action.value };
    case Action.SET_DIALOG_ASSIGN_ADD_CONTAINER_STATUS:
      return { ...state, dialogAddContainerStatus: action.value };
    case Action.SET_ASSIGN_MULTIPLE_CLEARANCE_DIALOG_STATUS:
      return { ...state, dialogBulkAssignClearanceStatus: action.value };
    case Action.SET_ASSIGN_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case Action.SET_ASSIGN_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.value,
        },
      };
    }
    case Action.SET_ASSIGN_PAGING: {
      return { ...state, ...action.value };
    }
    case Action.SET_ASSIGN_SORT: {
      return { ...state, sortField: action.sortField, sortDirection: action.sortDirection };
    }
    case Action.ADD_MULTI_SELECT_ASSIGN_BL:
      return { ...state, multiTappedBl: [...state.multiTappedBl, action.value] };
    case Action.REMOVE_MULTI_SELECT_ASSIGN_BL:
      return { ...state, multiTappedBl: removeMultiTappedBl(state.multiTappedBl, action.value) };
    default:
      return state;
  }
};
