import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Typography,
  withStyles,
  Grid,
  TextField,
} from '@material-ui/core';
import {
  MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING,
} from '../../constant';
import LocalizedString from '../../localization';
import { toMoment } from '../../helper';

const styles = () => ({
  dialogContent: {
    width: '100%',
    overflow: 'auto',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formTitle: {
    fontWeight: 600,
    fontSize: '1.1em',
  },
  timelineContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px !important',
    position: 'relative',
  },
  timelineLine: {
    width: '1px',
    height: '100%',
    backgroundColor: '#979797',
    marginTop: '12px',
  },
  timelineItem: {
    width: '32px',
    border: '1px solid #0066CB',
    borderRadius: '48px',
    position: 'absolute',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    fontWeight: 'bolder',
    marginTop: '12px',
  },
});

const ReeferDataTable = ({
  classes,
  container,
}) => (
  <>
    <Grid container spacing={24} xs={12} md={12}>
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.formTitle}>
          {LocalizedString.trucking.lblTitleDriver}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          readOnly
          fullWidth
          value={(container && container.driverName) || '-'}
          label={LocalizedString.trucking.lblDriverName}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          readOnly
          fullWidth
          value={(container && container.driverPhone) || '-'}
          label={LocalizedString.trucking.lblPhoneNumber}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          readOnly
          fullWidth
          value={(container && container.truckLicenseNumber) || '-'}
          label={LocalizedString.trucking.lblPlateNumber}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1" className={classes.formTitle}>
          {LocalizedString.trucking.lblTitleContainer}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          readOnly
          fullWidth
          value={(container && container.ctrSize) || '-'}
          label={LocalizedString.trucking.lblContainerSize}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          readOnly
          fullWidth
          value={(container && container.ctrType) || '-'}
          label={LocalizedString.trucking.lblContainerType}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          readOnly
          fullWidth
          value={(container && container.ctrStatus) || '-'}
          label={LocalizedString.trucking.lblContainerStatus}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          readOnly
          fullWidth
          value={(container && container.sealNo) || '-'}
          label={LocalizedString.trucking.lblContainerSealNo}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          readOnly
          fullWidth
          value={(container && container.blNo) || '-'}
          label={LocalizedString.trucking.lblBlNo}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1" className={classes.formTitle}>
          {LocalizedString.trucking.lblTitlePort}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={24}>
          <Grid item xs={1} className={classes.timelineContainer}>
            <div className={classes.timelineLine} />
            <div className={classes.timelineItem}>1</div>
          </Grid>
          <Grid item xs={11}>
            <Grid container spacing={24}>
              <Grid item xs={12} md={6}>
                <TextField
                  readOnly
                  fullWidth
                  value={container && container.gateInCdp ? toMoment(container.gateInCdp).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING) : '-'}
                  label={LocalizedString.trucking.lblGateInCdp}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  readOnly
                  fullWidth
                  value={container && container.gateOutDelivery ? toMoment(container.gateOutDelivery).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING) : '-'}
                  label={LocalizedString.trucking.lblGateOutDelivery}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={24}>
          <Grid item xs={1} className={classes.timelineContainer}>
            <div className={classes.timelineLine} />
            <div className={classes.timelineItem}>2</div>
          </Grid>
          <Grid item xs={11}>
            <Grid container spacing={24}>
              <Grid item xs={12} md={6}>
                <TextField
                  readOnly
                  fullWidth
                  value={container && container.getInWh ? toMoment(container.getInWh).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING) : '-'}
                  label={LocalizedString.trucking.lblGateInWarehouse}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  readOnly
                  fullWidth
                  value={container && container.gateOutWh ? toMoment(container.gateOutWh).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING) : '-'}
                  label={LocalizedString.trucking.lblGateOutWarehouse}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={24}>
          <Grid item xs={1} className={classes.timelineContainer}>
            <div className={classes.timelineItem}>3</div>
          </Grid>
          <Grid item xs={11}>
            <Grid container spacing={24}>
              <Grid item xs={12} md={6}>
                <TextField
                  readOnly
                  fullWidth
                  value={container && container.gateInCdpMty ? toMoment(container.gateInCdpMty).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING) : '-'}
                  label={LocalizedString.trucking.lblGateInCdpMty}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  readOnly
                  fullWidth
                  value={container && container.gateOutCdpMty ? toMoment(container.gateOutCdpMty).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING) : '-'}
                  label={LocalizedString.trucking.lblGateOutCdpDepo}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </>
);
ReeferDataTable.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  container: PropTypes.shape(PropTypes.any).isRequired,
};
export default withStyles(styles)(ReeferDataTable);
