import _ from 'lodash';
import * as Action from '../action';

const initialState = {
  data: {},
  meta: {
    pageSize: 0,
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.SET_FORMS: {
      return {
        data: { ...action.data.data },
        meta: action.data.meta,
      };
    }
    case Action.ADD_FORM: {
      return { ...state, [action.data.id]: action.data };
    }
    case Action.CLEAR_FORMS:
      return _.omit(state, action.id);
    default:
      return state;
  }
};
