import * as Action from '../action';

const initialState = {
  updating: false,
  nextAutoUpdate: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.UPDATING_DEPOSIT_BALANCE:
      return { ...state, updating: action.status };
    case Action.SET_DEPOSIT_BALANCE_NEXT_AUTO_UPDATE:
      return { ...state, nextAutoUpdate: action.value };
    default: return state;
  }
};
