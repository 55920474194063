import {
  uploadingProfilePicture, removeImage, addImage, addProfilePicture,
} from '../simple-action';
import * as Helper from '../../../helper';

export default newImageData => async (dispatch, getState) => {
  try {
    dispatch(uploadingProfilePicture(true));

    const { id } = getState().currentUser;
    const user = getState().users[id];
    const { token } = getState().authentication;

    await Helper.uploadProfilePicture(newImageData, user.id, token);
    await Helper.removeProfilePictureFromStorage(user.id, getState());
    dispatch(removeImage(user.profilePicture));

    const img = await Helper.saveImageToFile(newImageData);
    dispatch(addImage(img));
    dispatch(addProfilePicture(user.id, img.id));
  } finally {
    dispatch(uploadingProfilePicture(false));
  }
};
