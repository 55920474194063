import * as Action from '../action';

const initialState = {
  submittingRequest: false,
  submittingResetPassword: false,
  error: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.SUBMITTING_FORGET_PASSWORD:
      return { ...state, submittingRequest: action.status };
    case Action.SUBMITTING_RESET_PASSWORD:
      return { ...state, submittingResetPassword: action.status };
    default: return state;
  }
};
