import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import * as Constant from '../../constant';
import LocalizedString from '../../localization';
import * as Helper from '../../helper';
import * as Validation from '../../validation';
import { LoadingIndicator } from '../../component';


const ChangePasswordPage = ({
  handleSubmit, submitting, onSubmit, onBack,
}) => (
  <div>
    <h2>{LocalizedString.registrationInfoScreen.title}</h2>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        name={Constant.RXFIELD_FULLNAME}
        type="text"
        disabled={submitting}
        required
        component={Helper.renderReduxFormTextField}
        label={LocalizedString.registrationInfoScreen.labelFullName}
      />
      <Field
        name={Constant.RXFIELD_PHONE}
        type="text"
        required
        disabled={submitting}
        component={Helper.renderReduxFormTextField}
        label={LocalizedString.registrationInfoScreen.labelPhone}
      />
      <Field
        name={Constant.RXFIELD_JOB_TITLE}
        type="text"
        required
        disabled={submitting}
        component={Helper.renderReduxFormTextField}
        label={LocalizedString.registrationInfoScreen.labelJobTitle}
      />
      <Field
        name={Constant.RXFIELD_PASSWORD}
        type="password"
        required
        disabled={submitting}
        component={Helper.renderReduxFormTextField}
        label={LocalizedString.registrationInfoScreen.labelPassword}
      />
      <Field
        name={Constant.RXFIELD_REPASSWORD}
        type="password"
        required
        disabled={submitting}
        component={Helper.renderReduxFormTextField}
        label={LocalizedString.registrationInfoScreen.labelConfirmPassword}
      />
      <Button disabled={submitting} onClick={onBack}>
        {LocalizedString.common.buttonCaptionBack}
      </Button>
      <Button variant="contained" color="primary" type="submit" disabled={submitting}>
        {LocalizedString.registrationInfoScreen.buttonCaptionRegister}
      </Button>
    </form>
    {submitting && <LoadingIndicator />}
  </div>
);

export default reduxForm({
  form: Constant.RXFORM_REGISTRATION_INFO_SCREEN,
  validate: Validation.rxformValidateRegistrationInfo,
})(ChangePasswordPage);

ChangePasswordPage.propTypes = {
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};
