import {
  DOWNLOADING_MONEY_LOAN_INFO, DOWNLOADING_MONEY_LOAN_TNC,
  SET_MONEY_LOAN_TNC, SET_MONEY_LOAN_TNC_DIALOG, SUBMITTING_MONEY_LOAN,
} from '../action/simple-action';

const initialState = {
  downloading: false,
  downloadingTnc: false,
  tnc: '',
  tncDialog: false,
  submittingMoneyLoan: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_MONEY_LOAN_INFO:
      return { ...state, downloading: action.status };
    case DOWNLOADING_MONEY_LOAN_TNC:
      return { ...state, downloadingTnc: action.status };
    case SET_MONEY_LOAN_TNC:
      return { ...state, tnc: action.text };
    case SET_MONEY_LOAN_TNC_DIALOG:
      return { ...state, tncDialog: action.status };
    case SUBMITTING_MONEY_LOAN:
      return { ...state, submittingMoneyLoan: action.status };
    default: return state;
  }
};
