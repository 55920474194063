import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  withStyles,
  CssBaseline,
  Grid,
  Card,
  CardContent,
  Fab,
  Typography,
  Icon,
  Button,
  TextField,
  CardHeader,
  CardActions,
  ButtonBase,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Input,
  TableBody,
} from '@material-ui/core';
import { PageHeaderUser, LoadingIndicator } from '../../component';
import * as Constant from '../../constant';
import LocalizedString from '../../localization';
import {
  toCurrency, isSortFieldActive, getSortIcon, toMoment,
} from '../../helper';
import BookNewDialog from './book-new-dialog.container';
import ExportDetailDialog from './export-detail-dialog.container';
import PasswordInputDialog from './password-input-dialog.presentation';
import BlCard from './bl-card.container';

const styles = theme => ({
  alignRight: {
    textAlign: 'right',
  },
  backIcon: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  btnNewBooking: {
    backgroundColor: '#5CB860',
    color: '#ffffff',
    width: '256px',
    '&:hover': {
      backgroundColor: '#558b2f',
    },
  },
  btnAddToCart: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
    width: '256px',
  },
  detailButton: {
    backgroundColor: '#ffffff',
  },
  grow: {
    flexGrow: 1,
  },
  searchButton: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  cardActionPrimary: {
    display: 'flex',
    alignItems: 'baseline',
    padding: 16,
    justifyContent: 'space-between',
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  cardBlDataHeader: {
    marginBottom: theme.spacing.unit * 2,
  },
  cardItem: {
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '6px',
    boxSizing: 'border-box',
    padding: '16px',
    marginBottom: theme.spacing.unit,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  cardItemActive: {
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  cardItemContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: theme.spacing.unit * 2,
    maxBlockSize: '500px',
    height: '500px',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${Constant.DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  detailHeader: {
    color: '#0066CB',
  },
  itemPerRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    color: '#0266B4',
  },
  root: {
    width: '100%',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  textJumlahPembayaran: {
    textTransform: 'uppercase',
  },
  textTotalPrice: {
    fontSize: '22px',
    fontWeight: 500,
    color: '#0065B3',
    marginRight: theme.spacing.unit * 2,
  },
});

const isAddedToCart = (blNo, shoppingCart) => {
  if (shoppingCart) {
    return Boolean(shoppingCart
      .find(shopItem => shopItem.blNumber.toUpperCase() === blNo.toUpperCase()
      && shopItem.service === Constant.CART_SERVICE_NAME_EXPORT));
  }

  return false;
};

const isAddedToTerm = (blNo, termOrder) => {
  if (termOrder) {
    return Boolean(termOrder.find(order => order.blNumber === blNo
      && order.service === Constant.CART_SERVICE_NAME_EXPORT));
  }

  return false;
};

class ExportScreen extends Component {
  componentDidMount() {
    this.props.onAppear();
  }

  handleChangeSort = (newSortField) => {
    const { onChangeSort, sortField, sortDirection } = this.props;
    if (newSortField === sortField) {
      onChangeSort(
        sortField,
        sortDirection === Constant.TABLE_SORT_ASCENDING
          ? Constant.TABLE_SORT_DESCENDING
          : Constant.TABLE_SORT_ASCENDING,
      );
    } else {
      onChangeSort(newSortField, Constant.TABLE_SORT_ASCENDING);
    }
  };

  renderTableCellHeader = (label, fieldId) => {
    const { sortField, sortDirection } = this.props;
    return (
      <TableCell>
        <TableSortLabel
          active={
            isSortFieldActive(sortField, fieldId)
          }
          IconComponent={getSortIcon(
            isSortFieldActive(sortField, fieldId),
            sortDirection,
          )}
          onClick={
            () => this.handleChangeSort(fieldId)
          }
        >
          {label}
        </TableSortLabel>
      </TableCell>
    );
  }

  renderTableCellStringFilter = (field) => {
    const { filters, onChangeFilter } = this.props;
    return (
      <TableCell>
        <Input
          type="text"
          fullWidth
          value={filters[field]}
          onChange={
            ({ nativeEvent }) => onChangeFilter(
              field,
              nativeEvent.target.value,
            )
          }
          placeholder={LocalizedString.common.placeholderSearch}
        />
      </TableCell>
    );
  }

  renderBlPriceHeader = () => {
    const {
      classes,
      selectedBlInfo,
      priceDetail,
    } = this.props;

    if (!selectedBlInfo) {
      return (<></>);
    }

    return (
      <Card className={classes.cardBlDataHeader}>
        <CardHeader
          title={(
            <>
              <Typography variant="h6" className={classes.detailHeader}>
                {LocalizedString.trucking.lblBlNo}
                &nbsp;
                {selectedBlInfo.blNo}
              </Typography>
            </>
          )}
        />
        <CardContent>
          <Grid container spacing={24}>
            <Grid item xs={12} md={4}>
              <Grid container spacing={0}>
                <Grid item xs={8} md={8}>
                  <Typography variant="caption">
                    {LocalizedString.priceDetail.lblTotalBeforeDiscount}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography variant="body1" align="right">
                    {toCurrency(priceDetail.totalGross)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={0}>
                <Grid item xs={8} md={8}>
                  <Typography variant="caption">
                    {LocalizedString.priceDetail.lblAdministration}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography variant="body1" align="right">
                    {toCurrency(priceDetail.adminFee)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={0}>
                <Grid item xs={8} md={8}>
                  <Typography variant="caption">
                    {LocalizedString.priceDetail.lblFatPPN}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography variant="body1" align="right">
                    {toCurrency(priceDetail.vat)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={0}>
                <Grid item xs={8} md={8}>
                  <Typography variant="caption">
                    {LocalizedString.priceDetail.lblDiscount}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography variant="body1" align="right">
                    {toCurrency(priceDetail.discount)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={0}>
                <Grid item xs={8} md={8}>
                  <Typography variant="caption">
                    {LocalizedString.priceDetail.lblTaxBasis}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography variant="body1" align="right">
                    {toCurrency(priceDetail.taxBasis)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActionPrimary}>
          <Typography variant="body1" className={classes.textJumlahPembayaran}>{LocalizedString.trucking.lblTotalPrice}</Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" className={classes.textTotalPrice}>
              Rp.&nbsp;
              {toCurrency(priceDetail.totalPayment)}
            </Typography>
          </div>
        </CardActions>
      </Card>
    );
  };

  renderBlPriceDetail = () => {
    const {
      classes,
      selectedBlInfo,
      priceDetail,
    } = this.props;

    if (!selectedBlInfo) {
      return (<></>);
    }

    return (
      <Card>
        <CardHeader title={(
          <>
            <Typography variant="h6" className={classes.detailHeader}>
              Detail
            </Typography>
          </>
        )}
        />
        <CardContent>
          <div style={{ width: '100%', overflow: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{LocalizedString.priceDetail.lblDescription}</TableCell>
                  <TableCell>{LocalizedString.priceDetail.lblDays}</TableCell>
                  <TableCell>{LocalizedString.priceDetail.lblPrice}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {(selectedBlInfo && priceDetail && priceDetail.items) && priceDetail.items.map(detail => (
                  <TableRow key={detail.index} className={classes.tableRow}>
                    <TableCell>
                      <Typography>
                        {detail.description}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography>
                        {detail.days}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography>
                        {toCurrency(detail.price)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    );
  };

  renderBlDetail = () => {
    const {
      classes,
      selectedBlInfo,
      exportOrderHistoryDetail,
      pageSize,
      activePage,
      totalPage,
      onChangePaging,
      onDetailPressed,
    } = this.props;

    if (!selectedBlInfo) {
      return (<></>);
    }

    return (
      <Card className={classes.cardBlDataHeader}>
        <CardHeader
          title={(
            <>
              <Typography variant="h6" className={classes.detailHeader}>
                More
              </Typography>
              <div className={classes.itemPerRowContainer}>
                <Select
                  value={pageSize}
                  onChange={event => onChangePaging(
                    Constant.RXTABLEFIELD_PAGE_SIZE,
                    event.target.value,
                  )}
                >
                  {Constant.DEFAULT_PAGE_SIZE_OPTIONS.map(item => (
                    <MenuItem value={item} key={item}>
                      {item}
                      &nbsp;
                      {LocalizedString.common.labelRows}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </>
          )}
        />
        <CardContent>
          <div style={{ width: '100%', overflow: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  {this.renderTableCellHeader(LocalizedString.export.labelContainerNumber,
                    Constant.TABLE_FIELD_CONTAINER_NUMBER)}
                  {this.renderTableCellHeader(LocalizedString.export.labelDoNo,
                    Constant.TABLE_FIELD_BL_NO)}
                  {this.renderTableCellHeader(LocalizedString.export.labelSoNo,
                    Constant.TABLE_FIELD_SEAL_NUMBER)}
                  {this.renderTableCellHeader(LocalizedString.export.labelKmtNo,
                    Constant.TABLE_FIELD_KMT_NO)}
                  <TableCell>
                    Action(s)
                  </TableCell>
                </TableRow>

                <TableRow>
                  {this.renderTableCellStringFilter(Constant.TABLE_FIELD_CONTAINER_NUMBER)}
                  {this.renderTableCellStringFilter(Constant.TABLE_FIELD_BL_NO)}
                  {this.renderTableCellStringFilter(Constant.TABLE_FIELD_SEAL_NUMBER)}
                  {this.renderTableCellStringFilter(Constant.TABLE_FIELD_KMT_NO)}
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {exportOrderHistoryDetail && exportOrderHistoryDetail.map(container => (
                  <TableRow key={container.containerNo} className={classes.tableRow}>
                    <TableCell>
                      {container.containerNo}
                    </TableCell>
                    <TableCell>
                      {container.blNo}
                    </TableCell>
                    <TableCell>
                      {container.sealNo || '-'}
                    </TableCell>
                    <TableCell>
                      {container.kmtNo || '-'}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        classes={{ outlinedPrimary: classes.detailButton }}
                        onClick={() => onDetailPressed(container.containerNo)}
                      >
                        {LocalizedString.common.buttonCaptionDetail}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>

          <div className={classes.tablePagination}>
            <Button
              variant="contained"
              style={{ flexGrow: 1 }}
              disabled={activePage === 1}
              onClick={() => onChangePaging(Constant.RXTABLEFIELD_ACTIVE_PAGE, activePage - 1)}
            >
              {LocalizedString.common.labelPagingPrevButton}
            </Button>

            <div className={classes.tablePaginationPageInput}>
              <span style={{ fontWeight: 500 }}>{LocalizedString.common.labelPagingPage}</span>
              <Input
                type="number"
                value={activePage}
                style={{ width: '50%' }}
                inputProps={{
                  min: 1,
                  max: totalPage,
                  step: 1,
                  style: { textAlign: 'center' },
                }}
                onChange={({ nativeEvent }) => onChangePaging(
                  Constant.RXTABLEFIELD_ACTIVE_PAGE,
                  parseInt(nativeEvent.target.value, 10),
                )}
              />
              <span style={{ fontWeight: 500 }}>
                {`${LocalizedString.common.labelPagingPageOf} ${totalPage}`}
              </span>
            </div>

            <Button
              variant="contained"
              style={{ flexGrow: 1 }}
              disabled={activePage === totalPage}
              onClick={() => onChangePaging(Constant.RXTABLEFIELD_ACTIVE_PAGE, activePage + 1)}
            >
              {LocalizedString.common.labelPagingNextButton}
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  };

  getTotalPrice = (blInfo) => {
    const { shoppingCart } = this.props;
    const itemCart = shoppingCart.find(shopItem => shopItem.blNumber
      .toUpperCase() === blInfo.blNo.toUpperCase()
      && shopItem.service === Constant.CART_SERVICE_NAME_EXPORT);
    return (itemCart && itemCart.amount) || (blInfo.totalAmount || 0);
  };

  renderAddToCartButton = () => {
    const {
      classes, addingShoppingCartItem, selectedBlInfo, shoppingCart, onAddToCartPressed,
    } = this.props;

    const disableButton = !!selectedBlInfo.invoiceNo;

    if (isAddedToCart(selectedBlInfo.blNo, shoppingCart)) {
      return (
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.btnAddToCart}
          disabled
        >
          {LocalizedString.trucking.btnAlreadyInCart}
        </Button>
      );
    }

    return (
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.btnAddToCart}
        disabled={addingShoppingCartItem || disableButton}
        onClick={() => onAddToCartPressed(selectedBlInfo)}
      >
        {LocalizedString.trucking.btnAddToCart}
      </Button>
    );
  };

  renderAddToTermButton = () => {
    const {
      classes, addingShoppingCartItem, selectedBlInfo, termOrder, onAddToTermPressed,
    } = this.props;

    const disableButton = !!selectedBlInfo.invoiceNo;

    if (isAddedToTerm(selectedBlInfo.blNo, termOrder)) {
      return (
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.btnAddToCart}
          disabled
        >
          {LocalizedString.tariffSimulation.btnAlreadyInTerm}
        </Button>
      );
    }

    return (
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.btnAddToCart}
        disabled={addingShoppingCartItem || disableButton}
        onClick={() => onAddToTermPressed(selectedBlInfo)}
      >
        {LocalizedString.tariffSimulation.btnAddToTerm}
      </Button>
    );
  };

  renderAddToButton = () => {
    const { userCompany, selectedBlInfo } = this.props;
    return (userCompany.paymentMethod === Constant.PAYMENT_TYPE_TERM
      || selectedBlInfo.billToPaymentMethod === Constant.PAYMENT_TYPE_TERM
      ? this.renderAddToTermButton()
      : this.renderAddToCartButton());
  }

  renderBlHeader = () => {
    const { classes, selectedBlInfo } = this.props;
    if (selectedBlInfo) {
      return (
        <Card className={classes.cardBlDataHeader}>
          <CardHeader
            title={(
              <>
                <Typography variant="h6" className={classes.detailHeader}>
                  {LocalizedString.export.labelBlNo}
                  &nbsp;
                  {selectedBlInfo.blNo}
                </Typography>
              </>
            )}
          />
          <CardActions className={classes.cardActionPrimary}>
            <Typography variant="body1" className={classes.textJumlahPembayaran}>{LocalizedString.trucking.lblTotalPrice}</Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" className={classes.textTotalPrice}>
                Rp.&nbsp;
                {toCurrency(this.getTotalPrice(selectedBlInfo))}
              </Typography>
              {this.renderAddToButton()}
            </div>
          </CardActions>
        </Card>
      );
    }
    return null;
  };

  render() {
    const {
      classes,
      notificationOpen,
      searchBarText,
      selectedBlInfo,
      downloadingExportOrder,
      downloadingExportOrderDetail,
      addingShoppingCartItem,

      dialogNewBookingStatus,
      dialogContainerDetailStatus,

      onAddNewBookingPressed,
      onChangeSearchBarText,
      onCloseNewBookingDialogPressed,
      onCloseDetailDialogPressed,

      dialogPasswordInputStatus,
      password,
      dialogError,
      onCancelPasswordPressed,
      onSubmitPasswordPressed,
      onChangePasswordText,
    } = this.props;

    return (
      <>
        <div className={classes.root}>
          <CssBaseline />

          <PageHeaderUser />

          <div
            className={
              classNames(classes.content, {
                [classes.contentDrawerOpen]: notificationOpen,
                [classes.contentDrawerClosed]: !notificationOpen,
              })
            }
            style={{ padding: '16px' }}
          >
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <div className={classes.pageNavigatorContainer}>
                      <Fab
                        variant="round"
                        classes={{ root: classes.backIcon }}
                        component={Link}
                        to={Constant.ROUTE_NAME_DASHBOARD_USER}
                      >
                        <Icon>chevron_left</Icon>
                      </Fab>
                      <div style={{ marginLeft: '32px' }}>
                        <Typography variant="h5" className={classes.pageTitle}>{LocalizedString.export.title}</Typography>
                        <Typography variant="caption">{LocalizedString.export.caption}</Typography>
                      </div>
                      <div className={classes.grow} />
                      <div>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          classes={{ containedSecondary: classes.btnNewBooking }}
                          onClick={onAddNewBookingPressed}
                        >
                          {LocalizedString.trucking.btnNewBooking}
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <div style={{ display: 'flex' }}>
                      <div style={{ flexGrow: 1, alignSelf: 'flex-end' }}>
                        <TextField
                          fullWidth
                          placeholder="Search BL no"
                          value={searchBarText}
                          onChange={event => onChangeSearchBarText(event.target.value)}
                        />
                      </div>
                      <div style={{ display: 'inline-flex', marginLeft: '8px' }}>
                        <Fab
                          size="small"
                          variant="round"
                          color="default"
                          className={classes.searchButton}
                        >
                          <Icon>search</Icon>
                        </Fab>
                      </div>
                    </div>

                    <div className={classes.cardItemContainer}>
                      {/* {renderBlCard()} */}
                      <BlCard />
                    </div>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={8}>
                {this.renderBlHeader()}

                {this.renderBlDetail()}

                {this.renderBlPriceHeader()}

                {this.renderBlPriceDetail()}
              </Grid>
            </Grid>
          </div>

          {(downloadingExportOrder || downloadingExportOrderDetail || addingShoppingCartItem) && <LoadingIndicator />}
        </div>

        <BookNewDialog
          open={dialogNewBookingStatus}
          onClose={onCloseNewBookingDialogPressed}
        />

        <ExportDetailDialog
          open={dialogContainerDetailStatus}
          onClose={onCloseDetailDialogPressed}
        />

        <PasswordInputDialog
          open={dialogPasswordInputStatus}
          password={password}
          error={dialogError}
          onCancelPressed={onCancelPasswordPressed}
          onSubmitPressed={() => onSubmitPasswordPressed(password, selectedBlInfo)}
          onChangePasswordText={onChangePasswordText}
        />
      </>
    );
  }
}
ExportScreen.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  notificationOpen: PropTypes.bool.isRequired,
  userCompany: PropTypes.shape(PropTypes.any).isRequired,

  addingShoppingCartItem: PropTypes.bool.isRequired,
  searchBarText: PropTypes.string.isRequired,
  downloadingExportOrder: PropTypes.bool.isRequired,
  downloadingExportOrderDetail: PropTypes.bool.isRequired,
  addingShoppingCartItem: PropTypes.bool.isRequired,
  shoppingCart: PropTypes.arrayOf(PropTypes.any).isRequired,
  termOrder: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedBlInfo: PropTypes.shape(PropTypes.any).isRequired,
  exportOrderHistoryDetail: PropTypes.arrayOf(PropTypes.any).isRequired,
  priceDetail: PropTypes.shape(PropTypes.any).isRequired,

  dialogNewBookingStatus: PropTypes.bool.isRequired,
  dialogContainerDetailStatus: PropTypes.bool.isRequired,

  filters: PropTypes.shape(PropTypes.any).isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  activePage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,

  onAppear: PropTypes.func.isRequired,
  onAddNewBookingPressed: PropTypes.func.isRequired,
  onAddToCartPressed: PropTypes.func.isRequired,
  onAddToTermPressed: PropTypes.func.isRequired,
  onChangeSearchBarText: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onChangePaging: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onDetailPressed: PropTypes.func.isRequired,
  onCloseNewBookingDialogPressed: PropTypes.func.isRequired,
  onCloseDetailDialogPressed: PropTypes.func.isRequired,

  // password dialog
  dialogPasswordInputStatus: PropTypes.bool.isRequired,
  password: PropTypes.string.isRequired,
  dialogError: PropTypes.string.isRequired,
  onCancelPasswordPressed: PropTypes.func.isRequired,
  onSubmitPasswordPressed: PropTypes.func.isRequired,
  onChangePasswordText: PropTypes.func.isRequired,
};

export default withStyles(styles)(ExportScreen);
