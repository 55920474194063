import { downloadCheckoutTermAndConditionText } from '../../../helper';
import { downloadingCheckoutTermAndConditions, setCheckoutTermsAndConditionText } from '../simple-action';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;

  try {
    dispatch(downloadingCheckoutTermAndConditions(true));
    const info = await downloadCheckoutTermAndConditionText(token);
    dispatch(setCheckoutTermsAndConditionText(info));
  } finally {
    dispatch(downloadingCheckoutTermAndConditions(false));
  }
};
