import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  container: {
    backgroundColor: 'rgba(0,0,0,0.7)',
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 9999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    width: '144px',
    height: '144px',
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
  },
  loadingBoxSmall: {
    width: '48px',
    height: '48px',
    backgroundColor: '#0266B4',
  },
  empty: {
    background: 'none',
  },
  indicator: {
    position: 'absolute',
    width: '48px',
    height: '48px',
    backgroundColor: '#50ABF1',
    animation: 'loadingIndicator 1s',
    animationIterationCount: 'infinite',
    transition: 'top linear, left linear, right linear, bottom linear',
  },
});

const LoadingComponent = ({ classes }) => (
  <div className={classes.container}>
    <div className={classes.innerContainer}>
      <div className={classes.loadingBoxSmall} />
      <div className={classes.loadingBoxSmall} />
      <div className={classes.loadingBoxSmall} />
      <div className={classes.loadingBoxSmall} />
      <div className={classNames(classes.loadingBoxSmall, classes.empty)} />
      {/* <div className={`${classes.loadingBoxSmall}, ${classes.empty}`} /> */}
      <div className={classes.loadingBoxSmall} />
      <div className={classes.loadingBoxSmall} />
      <div className={classes.loadingBoxSmall} />
      <div className={classes.loadingBoxSmall} />
      <div className={classes.indicator} />
    </div>
  </div>
);
LoadingComponent.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
};

export default withStyles(styles)(LoadingComponent);
