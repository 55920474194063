import { connect } from 'react-redux';
import _ from 'lodash';
import PartyField from './party-field.presentation';
import { PARTY_SIZE, PARTY_TYPE } from '../../constant';

const getInitialValues = (state, ownProps) => {
  const result = _.values(state.feederServiceBookPartyList.bookParties)
    .find(party => party.id === ownProps.partyId);

  if (!result.loadOfParty) {
    result.loadOfParty = 'FCL';
  }

  return result;
};

const mapStateToProps = (state, ownProps) => ({
  form: ownProps.form,
  initialValues: getInitialValues(state, ownProps),
  partySizes: PARTY_SIZE.map(item => ({ label: item, value: item })),
  partyTypes: PARTY_TYPE.map(item => ({ label: item, value: item })),
});

export default connect(mapStateToProps, {})(PartyField);
