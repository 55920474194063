import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getFormValues } from 'redux-form';
import BulkAssignBlDialog from './bulk-assign-bl-dialog.presentation';
import { RXFORM_ASSIGN_CLEARANCE } from '../../constant';
import {
  showErrorSnackbar,
  uploadBulkAssignClearanceAsync,
  downloadAssignAsync,
  setTappedAssignBlNo,
  setAssignMutipleClearanceDialogStatus,
} from '../../redux/action';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const getIsTruckerValue = (state) => {
  const rxForm = getFormValues(RXFORM_ASSIGN_CLEARANCE)(state);
  return rxForm ? rxForm.isTrucker : false;
};

const getSoNo = state => state.uiAssignMultipleClearance.selectedBls.map(item => item.soNo).join(', ');
const getBlNo = state => state.uiAssignMultipleClearance.selectedBls.map(item => item.blNo).join(', ');

const mapStateToProps = state => ({
  submitting: state.uiAssignMultipleClearance.submitting,
  lstPpjk: Object.values(state.ppjkListByAssign)
    .map(item => ({ label: item.name, value: item.id })),
  lstTrucker: Object.values(state.ppjkListByAssign)
    .map(item => ({ label: item.name, value: item.id })),
  initialValues: {
    soNo: getSoNo(state),
    blNo: getBlNo(state),
    forwarderId: undefined,
    isTrucker: false,
    truckerId: undefined,
  },
  isTruckerChecked: getIsTruckerValue(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmitPressed: async (values) => {
    try {
      const payload = values.isTrucker ? values : { ...values, truckerId: '' };
      await dispatch(uploadBulkAssignClearanceAsync(payload));
      dispatch(setAssignMutipleClearanceDialogStatus(false));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    } finally {
      dispatch(setTappedAssignBlNo(''));
      dispatch(downloadAssignAsync());
    }
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BulkAssignBlDialog));
