import * as Action from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case Action.ADD_CURRENT_USER:
      return action.user;
    case Action.EDIT_CURRENT_USER:
      return { ...state, ...action.user };
    case Action.UPDATE_DEPOSIT_BALANCE: {
      const { company } = state;
      const { depositAccount } = company;

      return {
        ...state,
        company: {
          ...company,
          depositAccount: { ...depositAccount, balance: action.balance },
        },
      };
    }
    default:
      return state;
  }
};
