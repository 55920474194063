import React, { Component } from 'react';
import {
  Typography,
  withStyles,
  Button,
  Card,
  CardHeader,
  Avatar,
  Icon,
  Select,
  CardContent,
  Input,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  CssBaseline,
  IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import _ from 'lodash';
import {
  MainMenu, RoundedButton, PageHeader, ReactSelect,
} from '../../component';
import * as Constant from '../../constant';
import LocalizedString from '../../localization';
import AddUserDialog from './add-user-dialog.container';
import UpdateUserDialog from './update-user-dialog.container';

const styles = theme => ({
  contentContainer: {
    padding: '16px 32px',
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100%',
  },
  cardRoot: {
    overflow: 'visible',
  },
  cardAvatar: {
    height: 64,
    width: 64,
    padding: '12px',
    borderRadius: 4,
    marginTop: theme.spacing.unit * -5,
    background: 'linear-gradient(60deg, #0164b2, #014982)', // "linear-gradient(60deg, #ab47bc, #8e24aa)",
    boxShadow:
      '0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tabPaper: {
    backgroundColor: '#e8e8e8',
  },
  tabRoot: {
    overflow: 'visible',
  },
  tabScroller: {
    overflow: 'visible',
  },
  tabIndicator: {
    height: '110%',
    top: '-5%',
    zIndex: 1,
    borderRadius: '4px',
    boxShadow:
      '0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)',
  },
  tabElement: {
    textTransform: 'capitalize',
    zIndex: 10,
  },
  activeTabElement: {
    color: '#ffffff !important',
  },
  iconButtonPadding4: {
    padding: '4px',
  },
});

class UserListScreen extends Component {
  componentDidMount() {
    this.props.onAppear(this.props.filters, {
      field: this.props.sortField,
      direction: this.props.sortDirection,
    }, {
      pageSize: this.props.pageSize,
      activePage: this.props.activePage,
    });
  }

  onFilterChange = (field, value) => {
    this.props.onFilterChange(field, value);
    this.props.onFilter(this.props.filters, {
      field: this.props.sortField,
      direction: this.props.sortDirection,
    }, {
      pageSize: this.props.pageSize,
      activePage: this.props.activePage,
    });
  };

  onPageSizeChange = (value) => {
    this.props.onPageSizeChange(value);
    this.props.onFilter(this.props.filters, {
      field: this.props.sortField,
      direction: this.props.sortDirection,
    }, {
      pageSize: this.props.pageSize,
      activePage: this.props.activePage,
    });
  };

  onPrevPressed = () => {
    const { activePage } = this.props;
    this.onPageChange(activePage > 1 ? activePage - 1 : 1);
    this.props.onFilter(this.props.filters, {
      field: this.props.sortField,
      direction: this.props.sortDirection,
    }, {
      pageSize: this.props.pageSize,
      activePage: this.props.activePage,
    });
  };

  onNextPressed = () => {
    const { activePage, totalPage } = this.props;
    this.onPageChange(
      activePage + 1 <= totalPage ? activePage + 1 : activePage,
    );
    this.props.onFilter(this.props.filters, {
      field: this.props.sortField,
      direction: this.props.sortDirection,
    }, {
      pageSize: this.props.pageSize,
      activePage: this.props.activePage,
    });
  };

  onPageChange = (value) => {
    const { totalPage } = this.props;
    if (value > totalPage) {
      this.props.onPageChange(totalPage);
    } else if (value <= 0) {
      this.props.onPageChange(1);
    } else {
      this.props.onPageChange(value);
    }
    this.props.onFilter(this.props.filters, {
      field: this.props.sortField,
      direction: this.props.sortDirection,
    }, {
      pageSize: this.props.pageSize,
      activePage: this.props.activePage,
    });
  };

  renderDataAsTable = () => {
    // filter data based on filter
    const {
      users, classes, onOpenUpdateUserDialog,
    } = this.props;

    return _.values(users)
      .map(user => (
        <TableRow className={classes.tableRow}>
          <TableCell>{user.username}</TableCell>
          <TableCell>{user.email}</TableCell>
          <TableCell>{user.role}</TableCell>
          <TableCell>{user.registeredFrom}</TableCell>
          <TableCell>
            {moment(user.registeredOn).format(
              Constant.MOMENT_DATE_TIME_FORMAT_STRING,
            )}
          </TableCell>
          <TableCell>moment</TableCell>
          <TableCell>{user.approvedOn ? 'Confirmed' : 'Unconfirmed'}</TableCell>
          <TableCell>
            {user.enabled ? (
              <RoundedButton size="medium">Disable</RoundedButton>
            ) : (
              <RoundedButton size="medium">Enable</RoundedButton>
            )}
          </TableCell>
          <TableCell>
            <IconButton color="primary" className={classes.iconButtonPadding4}>
              <Icon>person</Icon>
            </IconButton>
            <IconButton color="primary" className={classes.iconButtonPadding4} onClick={() => onOpenUpdateUserDialog(user.id)}>
              <Icon>edit</Icon>
            </IconButton>
            <IconButton color="primary" className={classes.iconButtonPadding4}>
              <Icon>email</Icon>
            </IconButton>
            <IconButton
              color="secondary"
              className={classes.iconButtonPadding4}
              onClick={this.onDeleteUserPressed}
            >
              <Icon>delete</Icon>
            </IconButton>
          </TableCell>
        </TableRow>
      ));
  };

  render() {
    const {
      classes, menuItems, menuOpen, roles,
      openAddDialog, openUpdateDialog, onOpenAddUserDialog,
      onCloseAddUserDialog, onCloseUpdateUserDialog,
    } = this.props;

    return (
      <>
        <CssBaseline />
        <div>
          <MainMenu />
        </div>
        <div
          className={classes.contentContainer}
          style={{ marginLeft: menuOpen ? Constant.DRAWER_WIDTH : 0 }}
        >
          <div>
            <PageHeader pageTitle="User Management" pageCaption="home > manage user" />

            <div style={{ flexGrow: 0, width: '100%' }}>
              <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                  avatar={(
                    <Avatar classes={{ root: classes.cardAvatar }}>
                      <Icon>person</Icon>
                    </Avatar>
                  )}
                  title={(
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <RoundedButton
                        size="medium"
                        onClick={onOpenAddUserDialog}
                      >
                        Add New User
                      </RoundedButton>
                      <Select
                        value={this.props.pageSize}
                        onChange={event => this.onPageSizeChange(event.target.value)
                        }
                      >
                        <MenuItem value={5}>5 rows</MenuItem>
                        <MenuItem value={10}>10 rows</MenuItem>
                      </Select>
                    </div>
                  )}
                />
                <CardContent>
                  <div style={{ width: '100%', overflow: 'auto' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sortDirection="desc">
                            <TableSortLabel active>Username</TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel>Email</TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel>Role</TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel>Registration IP</TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel>Registration Time</TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel>Last Login</TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel>Confirmation</TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel>Block Status</TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel>Actions</TableSortLabel>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <Input
                              type="text"
                              fullWidth
                              value={this.props.filters.username}
                              onChange={({ nativeEvent }) => this.onFilterChange(
                                'username',
                                nativeEvent.target.value,
                              )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Input
                              type="text"
                              fullWidth
                              value={this.props.filters.email}
                              onChange={({ nativeEvent }) => this.onFilterChange(
                                'email',
                                nativeEvent.target.value,
                              )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <ReactSelect options={roles} value={this.props.filters.role} onChange={value => this.onFilterChange('role', value)} />
                          </TableCell>
                          <TableCell>
                            <Input
                              type="text"
                              fullWidth
                              value={this.props.filters.registrationIp}
                              onChange={({ nativeEvent }) => this.onFilterChange(
                                'registrationIp',
                                nativeEvent.target.value,
                              )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Input
                              type="text"
                              fullWidth
                              value={this.props.filters.registeredOn}
                              onChange={({ nativeEvent }) => this.onFilterChange(
                                'registeredOn',
                                nativeEvent.target.value,
                              )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Input
                              type="text"
                              fullWidth
                              value={this.props.filters.lastLogin}
                              onChange={({ nativeEvent }) => this.onFilterChange(
                                'lastLogin',
                                nativeEvent.target.value,
                              )
                              }
                            />
                          </TableCell>
                          <TableCell />
                          <TableCell />
                          <TableCell />
                        </TableRow>
                      </TableHead>

                      <TableBody>{this.renderDataAsTable()}</TableBody>
                    </Table>
                  </div>

                  <div className={classes.tablePagination}>
                    <Button
                      disabled={this.props.activePage === 1}
                      variant="contained"
                      style={{ flexGrow: 1 }}
                      onClick={this.onPrevPressed}
                    >
                      Prev
                    </Button>

                    <div className={classes.tablePaginationPageInput}>
                      <span style={{ fontWeight: 500 }}>Page</span>
                      <Input
                        type="number"
                        value={this.props.activePage}
                        style={{ width: '50%' }}
                        inputProps={{
                          min: 1,
                          max: this.props.totalPage,
                          step: 1,
                          style: { textAlign: 'center' },
                        }}
                        onChange={({ nativeEvent }) => this.onPageChange(nativeEvent.target.value)
                        }
                      />
                      <span style={{ fontWeight: 500 }}>
                        of&nbsp;
                        {this.props.totalPage}
                      </span>
                    </div>

                    <Button
                      disabled={this.props.activePage === this.props.totalPage}
                      variant="contained"
                      style={{ flexGrow: 1 }}
                      onClick={this.onNextPressed}
                    >
                      Next
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          <div id="footer" style={{ textAlign: 'right', marginTop: '24px' }}>
            <Typography variant="caption">
              {LocalizedString.common.footer}
            </Typography>
          </div>
        </div>
        <AddUserDialog
          open={openAddDialog}
          onCancelButtonPressed={onCloseAddUserDialog}
        />
        <UpdateUserDialog
          open={openUpdateDialog}
          onCancelButtonPressed={onCloseUpdateUserDialog}
        />

        {/* <UpdateUserDialog
                    open={this.state.open}
                    onCancelButtonPressed={this.onCancelButtonPressed} />
                <DeleteUserDialog
                    open={this.state.openDeleteDialog}
                    onCancelButtonPressed={this.onCancelDeleteUserPressed}/> */}
      </>
    );
  }
}

UserListScreen.propTypes = {
  classes: PropTypes.shape(PropTypes.any),
  menuItems: PropTypes.arrayOf(PropTypes.any),
  menuOpen: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.any),
  filters: PropTypes.shape({
    username: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.number,
    registrationIp: PropTypes.string,
    registeredOn: PropTypes.string,
    lastLogin: PropTypes.string,
  }),
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  activePage: PropTypes.number,
  totalPage: PropTypes.number,
  pageSize: PropTypes.number,
  users: PropTypes.shape(PropTypes.any),
  openAddDialog: PropTypes.bool,
  openUpdateDialog: PropTypes.bool,
  onPageSizeChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  onFilter: PropTypes.func,
  onPageChange: PropTypes.func,
  onAppear: PropTypes.func,
  onOpenAddUserDialog: PropTypes.func.isRequired,
  onOpenUpdateUserDialog: PropTypes.func.isRequired,
  onCloseAddUserDialog: PropTypes.func.isRequired,
  onCloseUpdateUserDialog: PropTypes.func.isRequired,
};

UserListScreen.defaultProps = {
  classes: null,
  menuItems: [],
  menuOpen: true,
  roles: [],
  filters: {
    username: '',
    email: '',
    role: null,
    registrationIp: '',
    registeredOn: '',
    lastLogin: '',
  },
  sortField: 'username',
  sortDirection: 'desc',
  activePage: 1,
  totalPage: 1,
  pageSize: 5,
  users: {},
  openAddDialog: false,
  openUpdateDialog: false,
  onPageSizeChange: () => {},
  onFilterChange: () => {},
  onFilter: () => {},
  onPageChange: () => {},
  onAppear: () => {},
};

export default withStyles(styles)(UserListScreen);
