import { validateAdminPayment } from '../../../validation';
import { addShoppingCartAdminPayment, uploadingAdminPayment } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(uploadingAdminPayment(true));
    const { paymentData } = getState().adminPaymentHistoryAdd;

    validateAdminPayment(paymentData);

    const existingPayment = Object.values(getState().shoppingCartAdminPayment);
    const sameBillToPaymentOwner = existingPayment.every(item => item.billTo === paymentData.billTo
      && item.paymentOwner === paymentData.paymentOwner);

    if (!sameBillToPaymentOwner) {
      throw new Error('Cannot add payment with different payment owner or method');
    }

    dispatch(addShoppingCartAdminPayment(paymentData));
  } finally {
    dispatch(uploadingAdminPayment(false));
  }
};
