import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Snackbar, Slide, withStyles, SnackbarContent, Typography, Icon, IconButton, Fade,
} from '@material-ui/core';
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '../../constant';

const styles = () => ({
  defaultStyle: {
    backgroundColor: '#5CB860',
  },
  defaultTitle: {
    color: '#ffffff',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  defaultMessage: {
    color: '#ffffff',
  },
  defaultIcon: {
    position: 'absolute',
    top: '-16px',
    left: '16px',
    backgroundColor: 'white',
    padding: '4px',
    borderRadius: '48px',
    width: '32px',
    height: '32px',
    color: '#5cb860',
    boxShadow: '0px 1px 8px darkgrey',
  },
  errorStyle: {
    backgroundColor: '#D91F1F',
  },
  messageStyle: {
    padding: '16px 0',
    width: 'calc(100% - 64px)',
  },
});

const SlideIn = props => (
  <Slide {...props} direction="left" />
);

const MySnackbar = ({
  classes,
  className,
  openSnackbarStatus,
  anchorOrigin: { horizontal = 'right', vertical = 'top' },
  enterTransition,
  title,
  message,
  type,
  onClose,
}) => (
  <Snackbar
    open={openSnackbarStatus}
    anchorOrigin={{
      horizontal,
      vertical,
    }}
    autoHideDuration={5000}
    onClose={event => !event && onClose()}
    TransitionComponent={SlideIn}
  >
    <SnackbarContent
      className={className || classNames({
        [classes.defaultStyle]: type === SNACKBAR_SUCCESS,
        [classes.errorStyle]: type === SNACKBAR_ERROR,
      })}
      classes={{
        message: classes.messageStyle,
      }}
      message={(
        <>
          <div className={classes.defaultIcon}>
            <Icon>notification_important</Icon>
          </div>
          <Typography className={classes.defaultTitle}>{title}</Typography>
          <Typography className={classes.defaultMessage}>{message}</Typography>
        </>
      )}
      action={(
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <Icon className={classes.icon}>close</Icon>
        </IconButton>
      )}
    />
  </Snackbar>
);
MySnackbar.propTypes = {
  openSnackbarStatus: PropTypes.bool.isRequired,
  classes: PropTypes.shape(PropTypes.any).isRequired,
  className: PropTypes.shape(PropTypes.any),
  anchorOrigin: PropTypes.shape({
    horizontal: PropTypes.oneOf(['right', 'left', 'center']),
    vertical: PropTypes.oneOf(['top', 'bottom']),
  }),
  enterTransition: PropTypes.oneOf(['left', 'right', 'bottom', 'top']),
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};
MySnackbar.defaultProps = {
  className: null,
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'top',
  },
  enterTransition: 'left',
  onClose: () => {},
};
export default withStyles(styles)(MySnackbar);
