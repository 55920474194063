import { setNotificationAsRead, uploadingNotificationReadStatus } from '../simple-action';
import { uploadReadNotification } from '../../../helper';

export default notificationId => async (dispatch, getState) => {
  const { token } = getState().authentication;

  try {
    dispatch(uploadingNotificationReadStatus(true));
    const notificationDetail = {
      notificationId,
      read: true,
    };
    await uploadReadNotification(notificationDetail, token);
    dispatch(setNotificationAsRead(notificationId));
  } finally {
    dispatch(uploadingNotificationReadStatus(false));
  }
};
