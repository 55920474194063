import { downloadingAssignContainer, addAssignContainer } from '../simple-action';
import * as Helper from '../../../helper';

export default (blNo, soNo) => async (dispatch, getState) => {
  const { token } = getState().authentication;

  try {
    dispatch(downloadingAssignContainer(true));
    const assignContainer = await Helper.downloadAssignContainer(token, blNo, soNo);
    dispatch(addAssignContainer(assignContainer));
  } finally {
    dispatch(downloadingAssignContainer(false));
  }
};
