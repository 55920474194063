import * as Action from '../action';

const initialState = {
  email: undefined,
  pinExpirationDate: undefined,
  pin: undefined,
  fullName: undefined,
  phone: undefined,
  jobTitle: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.ADD_REGISTRATION_INFO:
      return { ...state, ...action.info };
    case Action.REMOVE_REGISTRATION_INFO:
      return initialState;
    default: return state;
  }
};
