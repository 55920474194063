import { searchingVoyageList, addVoyageList } from '../simple-action';
import * as Helper from '../../../helper';

export default name => async (dispatch, getState) => {
  try {
    const { token } = getState().authentication;
    const { selectedVessel } = getState().uiBookExportFeeder;

    dispatch(searchingVoyageList(true));
    const voyageList = await Helper.downloadVoyageList(selectedVessel, name, token);
    dispatch(addVoyageList(voyageList));
  } finally {
    dispatch(searchingVoyageList(false));
  }
};
