import * as Helper from '../../../helper';
import * as Validation from '../../../validation';
import { uploadingMasterUser } from '../simple-action';

export default values => async (dispatch, getState) => {
  try {
    dispatch(uploadingMasterUser(true));

    Validation.validateNewUser(values);

    const { token } = getState().authentication;

    await Helper.uploadNewUser(values, token);
  } finally {
    dispatch(uploadingMasterUser(false));
  }
};
