import * as Helper from '../../../helper';
import {
  setFormResponseFilterStatuses,
  downloadingFormResponseFilterStatuses,
  clearFormResponseFilterStatuses,
} from '../simple-action';


export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingFormResponseFilterStatuses(true));
    const { token } = getState().authentication;
    const filterStatuses = await Helper.downloadFormResponseFilterStatuses(token);

    if (filterStatuses) {
      dispatch(clearFormResponseFilterStatuses());
      dispatch(setFormResponseFilterStatuses(filterStatuses));
    }
  } finally {
    dispatch(downloadingFormResponseFilterStatuses(false));
  }
};
