import { downloadMoneyLoanHistory } from '../../../helper';
import { downloadingMoneyLoanHistory, setMoneyLoanHistory } from '../simple-action';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  try {
    dispatch(downloadingMoneyLoanHistory(true));
    const history = await downloadMoneyLoanHistory(token);
    dispatch(setMoneyLoanHistory(history));
  } finally {
    dispatch(downloadingMoneyLoanHistory(false));
  }
};
