import * as Action from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case Action.ADD_TRUCK: {
      return { ...state, [action.value.id]: action.value };
    }
    case Action.REMOVE_ALL_TRUCKS: {
      return {};
    }
    default:
      return state;
  }
};
