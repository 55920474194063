import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getFormValues } from 'redux-form';
import _ from 'lodash';
import AssignBlDialog from './assign-bl-dialog.presentation';
import { RXFORM_ASSIGN_CLEARANCE, RXSTATE_ASSIGN_CLEARANCE } from '../../constant';
import {
  setUIError, uploadAssignClearanceAsync, setAssignClearanceDialogStatus, downloadAssignOrderTypeAsync, showErrorSnackbar,
} from '../../redux/action';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const getIsTruckerValue = (state) => {
  const rxForm = getFormValues(RXFORM_ASSIGN_CLEARANCE)(state);
  return rxForm ? rxForm.isTrucker : false;
};

const getSelectedAssignBl = state => _.values(state.assign)
  .find(item => item.blNo === state.uiAssign.tappedBlNo);

const mapStateToProps = state => ({
  selectedBl: getSelectedAssignBl(state) || null,
  submitting: state.uiAssignClearance.submitting,
  lstPpjk: Object.values(state.ppjkListByAssign)
    .map(item => ({ label: item.name, value: item.id })),
  lstTrucker: Object.values(state.ppjkListByAssign)
    .map(item => ({ label: item.name, value: item.id })),
  initialValues: {
    soNo: state.uiAssignClearance.assignClearanceForm.soNo,
    blNo: state.uiAssignClearance.assignClearanceForm.blNo,
    forwarderId: undefined,
    isTrucker: false,
    truckerId: undefined,
  },
  isTruckerChecked: getIsTruckerValue(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmitPressed: async (values) => {
    try {
      const payload = values.isTrucker ? values : { ...values, truckerId: '' };
      await dispatch(uploadAssignClearanceAsync(payload));
      dispatch(downloadAssignOrderTypeAsync(payload.blNo));
      dispatch(setAssignClearanceDialogStatus(false));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssignBlDialog));
