import { setExportDoList, downloadingDoList } from '../simple-action';
import * as Helper from '../../../helper';

export default text => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const vendorId = getState().currentUser.company.id;

  try {
    dispatch(downloadingDoList(true));
    const doList = await Helper.downloadDoList(token);
    dispatch(setExportDoList(doList));
  } finally {
    dispatch(downloadingDoList(false));
  }
};
