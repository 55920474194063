import { setGlobalLoading } from '../simple-action';
import { openNewTab, downloadAdminPaymentHistoryCsv } from '../../../helper';
import { URL_ADMIN_PAYMENT_HISTORY_CSV } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(setGlobalLoading(true));

    const { token } = getState().authentication;
    const { downloadLimitOption } = getState().uiAdminPaymentHistory;

    const { printId } = await downloadAdminPaymentHistoryCsv(token);

    const csvUrl = URL_ADMIN_PAYMENT_HISTORY_CSV
      .replace(/{id}/, printId)
      .replace(/{duration}/, downloadLimitOption);
    openNewTab(csvUrl);
  } finally {
    dispatch(setGlobalLoading(false));
  }
};
