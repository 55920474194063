import * as Helper from '../../../helper';
import { addTariffSimulation, downloadingTariffSimulationInfo } from '../simple-action';
import LocalizedString from '../../../localization';

export default (blNo, etd, paymentOnBehalf) => async (dispatch, getState) => {
  try {
    dispatch(downloadingTariffSimulationInfo(true));

    if (!etd || !paymentOnBehalf) {
      throw new Error(LocalizedString.common.errMsgEmptyRequiredFields);
    }

    const { token } = getState().authentication;
    const tariffSimulation = await Helper.downloadTariffSimulation(blNo, etd, paymentOnBehalf, token);

    if (tariffSimulation) {
      dispatch(addTariffSimulation(tariffSimulation));
    }
  } finally {
    dispatch(downloadingTariffSimulationInfo(false));
  }
};
