import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentTz from 'moment-timezone';
import {
  withStyles,
  Dialog,
  Tab,
  Tabs,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Icon,
  Grid,
  TextField,
} from '@material-ui/core';
import {
  MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING, MOMENT_DATE_LONG_FORMAT_STRING, FLAG_REEFER_CONTAINER, CART_SERVICE_NAME_EXPORT,
} from '../../constant';
import { LoadingIndicator } from '../../component';
import LocalizedString from '../../localization';
import ReeferDataTable from './reefer-data.container';
import TruckingContainerInfo from './trucking.container';
import { toMoment } from '../../helper';

const styles = theme => ({
  dialogContent: {
    marginTop: '24px',
  },
  tabIndicator: {
    display: 'none',
  },
  tabRoot: {
    marginTop: theme.spacing.unit,
    overflow: 'visible',
  },
  tabElement: {
    backgroundColor: 'rgba(200, 200, 200, 0.2)',
    textTransform: 'capitalize',
  },
  tabElementActive: {
    backgroundColor: '#3f51b5',
    color: '#ffffff !important',
    transform: 'scaleY(1.1)',
    borderRadius: '4px',
    boxShadow: '0px 4px 20px rgba(0, 102, 203, 0.45)',
  },
  tabScroller: {
    overflowX: 'visible',
  },
});

const ContainerDetail = ({
  classes,
  containerInfo,
  containerTrackingInfo,
}) => {
  const gateOutCdpLabel = containerInfo.exim.toUpperCase() === CART_SERVICE_NAME_EXPORT.toUpperCase()
    ? LocalizedString.containerTracking.lblContainerGateInSeaport
    : LocalizedString.containerTracking.lblContainerGateOutSeaPort;

  const pickedUpByLabel = containerInfo.exim.toUpperCase() === CART_SERVICE_NAME_EXPORT.toUpperCase()
    ? LocalizedString.containerTracking.lblContainerGateOutCDP
    : LocalizedString.containerTracking.lblContainerPickUpByConsignee;

  const shipperLabel = containerInfo.exim.toUpperCase() === CART_SERVICE_NAME_EXPORT.toUpperCase()
    ? LocalizedString.containerTracking.lblContainerExporter
    : LocalizedString.containerTracking.lblContainerShipper;

  return (
    <>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <TextField
            readOnly
            fullWidth
            value={
              containerTrackingInfo && containerTrackingInfo.bcDate ? `${containerTrackingInfo.bcNo} and ${toMoment(containerTrackingInfo.bcDate).format(MOMENT_DATE_LONG_FORMAT_STRING)}` : '-'
            }
            label={LocalizedString.containerTracking.lblContainerBcNoAndDate}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            readOnly
            fullWidth
            value={
              containerTrackingInfo && containerTrackingInfo.bcPos ? containerTrackingInfo.bcPos : '-'
            }
            label={LocalizedString.containerTracking.lblContainerBcPos}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            readOnly
            fullWidth
            value={
              containerTrackingInfo && containerTrackingInfo.vessel ? containerTrackingInfo.vessel : '-'
            }
            label={LocalizedString.containerTracking.lblContainerVesselName}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            readOnly
            fullWidth
            value={
              containerTrackingInfo && containerTrackingInfo.voyage ? containerTrackingInfo.voyage : '-'
            }
            label={LocalizedString.containerTracking.lblContainerVoyageNo}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            readOnly
            fullWidth
            value={
              containerTrackingInfo && containerTrackingInfo.ctrSize ? containerTrackingInfo.ctrSize : '-'
            }
            label={LocalizedString.containerTracking.lblContainerSize}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            readOnly
            fullWidth
            value={
              containerTrackingInfo && containerTrackingInfo.ctrType ? containerTrackingInfo.ctrType : '-'
            }
            label={LocalizedString.containerTracking.lblContainerType}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            readOnly
            fullWidth
            value={
              containerTrackingInfo && containerTrackingInfo.toid ? containerTrackingInfo.toid : '-'
            }
            label={LocalizedString.containerTracking.lblContainerSeaPort}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            readOnly
            fullWidth
            value={
              containerTrackingInfo && containerTrackingInfo.gateOutTo ? toMoment(containerTrackingInfo.gateOutTo).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING) : '-'
            }
            label={gateOutCdpLabel}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            readOnly
            fullWidth
            value={
              containerTrackingInfo && containerTrackingInfo.gateInCdp ? toMoment(containerTrackingInfo.gateInCdp).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING) : '-'
            }
            label={LocalizedString.containerTracking.lblContainerGateInCDP}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            readOnly
            fullWidth
            value={
              containerTrackingInfo && containerTrackingInfo.gateOutCdp
                ? toMoment(containerTrackingInfo.gateOutCdp)
                  .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)
                : '-'
            }
            label={pickedUpByLabel}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            readOnly
            fullWidth
            value={
              containerTrackingInfo && containerTrackingInfo.dt ? containerTrackingInfo.dt : '-'
            }
            label={LocalizedString.containerTracking.lblContainerDwellingTime}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            readOnly
            fullWidth
            value={
              containerTrackingInfo && containerTrackingInfo.shipper ? containerTrackingInfo.shipper : '-'
            }
            label={shipperLabel}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            readOnly
            fullWidth
            value={
              containerTrackingInfo && containerTrackingInfo.etd ? containerTrackingInfo.etd : '-'
            }
            label={LocalizedString.containerTracking.lblContainerEta}
          />
        </Grid>
      </Grid>
    </>
  );
};
ContainerDetail.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  containerInfo: PropTypes.shape(PropTypes.any).isRequired,
  containerTrackingInfo: PropTypes.shape(PropTypes.any).isRequired,
};

const ContainerDetailDialog = ({
  classes,
  open,
  downloadingReeferData,
  isFeederServiceAvailable,
  containerInfo,
  containerTrackingInfo,
  selectedTab,
  onClose,
  onTabPressed,
  onAppear,
}) => (
  <>
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onEnter={() => onAppear(containerTrackingInfo.blNo, containerTrackingInfo.containerNo)}
    >
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
          <Typography color="primary" variant="h6">
            {LocalizedString.containerTracking.lblTitleContainerNo}
            &nbsp;
            {containerTrackingInfo.containerNo}
          </Typography>
          <IconButton
            onClick={onClose}
          >
            <Icon>close</Icon>
          </IconButton>
        </div>

        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          classes={{
            root: classes.tabRoot,
            indicator: classes.tabIndicator,
            scroller: classes.tabScroller,
          }}
          fullWidth
          centered
        >
          <Tab
            value={0}
            label="Detail"
            classes={{
              root: classes.tabElement,
              selected: classes.tabElementActive,
            }}
            onClick={() => onTabPressed(0)}
          />
          {(containerTrackingInfo && containerTrackingInfo.ctrType === FLAG_REEFER_CONTAINER) && (
            <Tab
              value={1}
              label="Reefer Data"
              classes={{
                root: classes.tabElement,
                selected: classes.tabElementActive,
              }}
              onClick={() => onTabPressed(1)}
            />
          )}
          {isFeederServiceAvailable && (
            <Tab
              value={2}
              label="Trucking"
              classes={{
                root: classes.tabElement,
                selected: classes.tabElementActive,
              }}
              onClick={() => onTabPressed(2)}
            />
          )}
        </Tabs>
      </DialogTitle>

      <DialogContent>
        <div className={classes.dialogContent}>
          {selectedTab === 0 && (
            <ContainerDetail
              classes={classes}
              containerInfo={containerInfo}
              containerTrackingInfo={containerTrackingInfo}
            />
          )}
          {selectedTab === 1 && <ReeferDataTable />}
          {selectedTab === 2 && <TruckingContainerInfo />}
        </div>
      </DialogContent>
    </Dialog>
    {downloadingReeferData && <LoadingIndicator />}
  </>
);
ContainerDetailDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  open: PropTypes.bool.isRequired,
  downloadingReeferData: PropTypes.bool.isRequired,
  isFeederServiceAvailable: PropTypes.bool.isRequired,
  containerInfo: PropTypes.shape(PropTypes.any).isRequired,
  containerTrackingInfo: PropTypes.shape(PropTypes.any).isRequired,
  selectedTab: PropTypes.number.isRequired,
  onAppear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onTabPressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(ContainerDetailDialog);
