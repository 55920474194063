import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import OutstandingCard from './outstanding-card.presentation';
import {
  downloadMyPaymentOutstandingAsync,
  setMyPaymentOutstandingSearchText,
  setTappedOutstandingItemNo,
  showErrorSnackbar,
} from '../../redux/action';
import { PAYMENT_TYPE_CASH } from '../../constant';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';


const getFilteredBl = state => _.values(state.myOutstandingOrder)
  .filter((item) => {
    const searchText = state.uiMyPaymentOutstanding.searchBarText.toLowerCase();

    if (item.outstandingType === PAYMENT_TYPE_CASH) {
      return item.proformaNo.toLowerCase().indexOf(searchText) >= 0
        || (item.blNumber && item.blNumber.toLowerCase().indexOf(searchText) >= 0)
        || (item.items && item.items
          .some(innerItem => innerItem.blNumber.toLowerCase().indexOf(searchText) >= 0))
        || (item.service && item.service.toLowerCase().indexOf(searchText) >= 0);
    }
    if (item.invoiceNo) {
      return item.invoiceNo.toLowerCase().indexOf(searchText) >= 0
        || item.blNumber.toLowerCase().indexOf(searchText) >= 0
        || item.service.toLowerCase().indexOf(searchText) >= 0;
    }

    return true;
  });

const mapStateToProps = state => ({
  downloading: state.uiMyPaymentOutstanding.downloading,
  bl: getFilteredBl(state),
  searchBarText: state.uiMyPaymentOutstanding.searchBarText,
  selectedItemNo: state.uiMyPaymentOutstanding.tappedItemNo,
  userCompany: state.currentUser.company,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    try {
      await dispatch(downloadMyPaymentOutstandingAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onChangeSearchBarText: (text) => {
    dispatch(setMyPaymentOutstandingSearchText(text));
  },
  onMorePressed: (tappedItemNo) => {
    dispatch(setTappedOutstandingItemNo(tappedItemNo));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OutstandingCard));
