import * as Helper from '../../../helper';
import * as Validation from '../../../validation';
import { uploadingField } from '../simple-action';

export default values => async (dispatch, getState) => {
  try {
    dispatch(uploadingField(true));

    Validation.validateNewField(values);

    const { token } = getState().authentication;
    const { selectedFieldId } = getState().uiField;

    const {
      order, name, label, required, type, formName, status,
    } = values;

    const requestBody = {
      id: selectedFieldId,
      order: Helper.toNumber(order),
      name,
      label,
      required: required === 'true',
      type,
      formId: formName,
      status,
    };

    await Helper.uploadUpdateField(selectedFieldId, requestBody, token);
  } finally {
    dispatch(uploadingField(false));
  }
};
