import { TABLE_SORT_DESCENDING } from '../../constant';
import {
  SET_ADMIN_PAYMENT_HISTORY_FILTER,
  SET_ADMIN_PAYMENT_HISTORY_PAGING,
  SET_ADMIN_PAYMENT_HISTORY_SORT,
  DOWNLOADING_ADMIN_PAYMENT_HISTORY_STATUS,
  SET_ADMIN_PAYMENT_HISTORY_DIALOG_ADD_STATUS,
  SET_ADMIN_PAYMENT_HISTORY_DIALOG_DETAIL_STATUS,
  SET_TAPPED_ADMIN_PAYMENT_HISTORY_ITEM_NO,
  SET_ADMIN_PAYMENT_HISTORY_SEARCH_TEXT,
  SET_ADMIN_PAYMENT_DOWNLOAD_LIMIT_OPTION,
  SET_ADMIN_PAYMENT_RESULT,
  SET_DIALOG_ADMIN_PAYMENT_SUCCESS_STATUS,
} from '../action';

const initialState = {
  downloading: false,
  tappedItemNo: '',

  downloadLimitOption: 7,

  dialogAddStatus: false,
  dialogDetailStatus: false,
  dialogPaymentCompleteStatus: false,

  searchText: '',
  filters: {
  },
  sortField: 'proformaNo',
  sortDirection: TABLE_SORT_DESCENDING,
  activePage: 1,
  pageSize: 5,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TAPPED_ADMIN_PAYMENT_HISTORY_ITEM_NO:
      return { ...state, tappedItemNo: action.value };
    case SET_ADMIN_PAYMENT_HISTORY_DIALOG_ADD_STATUS:
      return { ...state, dialogAddStatus: action.status };
    case SET_ADMIN_PAYMENT_HISTORY_DIALOG_DETAIL_STATUS:
      return { ...state, dialogDetailStatus: action.status };
    case DOWNLOADING_ADMIN_PAYMENT_HISTORY_STATUS:
      return { ...state, downloading: action.status };
    case SET_ADMIN_PAYMENT_HISTORY_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.value,
        },
      };
    }
    case SET_ADMIN_PAYMENT_HISTORY_PAGING: {
      return { ...state, ...action.value };
    }
    case SET_ADMIN_PAYMENT_HISTORY_SORT: {
      return { ...state, sortField: action.sortField, sortDirection: action.sortDirection };
    }
    case SET_ADMIN_PAYMENT_HISTORY_SEARCH_TEXT: {
      return { ...state, searchText: action.value };
    }
    case SET_ADMIN_PAYMENT_DOWNLOAD_LIMIT_OPTION:
      return { ...state, downloadLimitOption: action.value };

    case SET_DIALOG_ADMIN_PAYMENT_SUCCESS_STATUS: {
      return { ...state, dialogPaymentCompleteStatus: action.status };
    }
    case SET_ADMIN_PAYMENT_RESULT: {
      return { ...state, paymentResult: action.value };
    }
    default: return state;
  }
};
