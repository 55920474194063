import { connect } from 'react-redux';
import _ from 'lodash';
import { reset } from 'redux-form';
import NewDocumentPage from './new-document.presentation';
import {
  clearUIError,
  setUIError,
  downloadNewDocumentMyFormsAsync,
  setNewDocumentMyFormTappedId,
  setNewDocumentMyFormFieldValue,
  setNewDocumentMyFormDetail,
} from '../../redux/action';
import * as Constant from '../../constant';

const getSortedMyForms = (state) => {
  const myResponses = _.values(state.newDocumentMyForm);
  return myResponses.sort((left, right) => left.order - right.order);
};

const mapStateToProps = state => ({
  myForms: getSortedMyForms(state),
  tappedId: state.uiNewDocumentMyForm.tappedId,
  downloadingMyForms: state.uiNewDocumentMyForm.downloading,
  uploadingResponse: state.uiNewDocumentMyForm.uploadingResponse,
  notificationOpen: state.uiWebUserMain.notificationDrawerOpen,
});

const mapDispatchToProps = dispatch => ({
  onAppear: async () => {
    try {
      clearUIError(Constant.RXSTATE_NEW_DOCUMENT_MY_FORM);
      await dispatch(downloadNewDocumentMyFormsAsync());
    } catch (error) {
      setUIError(Constant.RXSTATE_NEW_DOCUMENT_MY_FORM, error.message);
    }
  },
  onCardItemPressed: (id, previousTappedId) => {
    if (previousTappedId !== id) {
      dispatch(reset(Constant.RXFORM_NEW_DOCUMENT_MY_FORM));
      dispatch(setNewDocumentMyFormTappedId(id));
      dispatch(setNewDocumentMyFormFieldValue({}));
      dispatch(setNewDocumentMyFormDetail({}));
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewDocumentPage);
