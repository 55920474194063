import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Button, Avatar, withStyles, Typography, Card, CardHeader, CardContent, TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import * as Constant from '../../constant';
import LocalizedString from '../../localization';
import * as Helper from '../../helper';
import * as Validation from '../../validation';
import { LoadingIndicator, RoundedButton, ErrorMessage } from '../../component';

const styles = theme => ({
  titleContainer: {
    width: '90%',
    padding: '16px',
  },
  footerContainer: {
    width: '90%',
    padding: '16px',
    textAlign: 'right',
  },
  button: {
    margin: theme.spacing.unit,
  },
  cardRoot: {
    overflow: 'visible',
    padding: theme.spacing.unit,
    minWidth: '360px',
    maxWidth: '95%',
  },
  cardHeaderAvatar: {
    margin: 0,
    width: '100%',
  },
  cardAvatar: {
    height: 96,
    // width: 'calc(100% - 24px)',
    width: '100%',
    padding: '12px',
    borderRadius: 4,
    marginTop: theme.spacing.unit * -6,

    background: 'linear-gradient(60deg, #0164b2, #014982)',
    boxShadow: '0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)',
    color: '#ffffff',
  },

  labelTitle: {
    fontWeight: 500,
    color: '#ffffff',
  },
  labelSubtitle: {
    fontWeight: 400,
    color: '#ffffff',
  },
  labelFooter: {
    fontWeight: 400,
    color: '#ffffff',
  },
});

const LoginCardAvatar = withStyles(styles)(props => (
  <Avatar classes={{ root: props.classes.cardAvatar }}>
    <img title="Logo" alt="Logo" src="/assets/images/logo.png" />
  </Avatar>
));

const ChangePasswordPage = ({
  classes, errorMsg, handleSubmit, submitting, onSubmit, onBack,
}) => (
  <>
    <div className={classes.titleContainer}>
      <Typography variant="title">
        <span className={classes.labelTitle}>{LocalizedString.loginScreen.title}</span>
        &nbsp;
        <span variant="title" className={classes.labelSubtitle}>{LocalizedString.loginScreen.subTitle}</span>
      </Typography>
    </div>
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        avatar={<LoginCardAvatar />}
        classes={{ avatar: classes.cardHeaderAvatar }}
      />
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Field
            name={Constant.RXFIELD_PASSWORD}
            type="password"
            disabled={submitting}
            component={Helper.renderReduxFormTextField}
            label={LocalizedString.forgetPasswordScreen.labelNewPassword}
          />
          <Field
            name={Constant.RXFIELD_REPASSWORD}
            type="password"
            disabled={submitting}
            component={Helper.renderReduxFormTextField}
            label={LocalizedString.forgetPasswordScreen.labelConfirmNewPassword}
          />

          <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '32px' }}>
            <Button color="primary" onClick={onBack}>
              {LocalizedString.common.buttonCaptionBack}
            </Button>
            <RoundedButton type="submit" className={classes.button}>
              {LocalizedString.common.buttonCaptionSave}
            </RoundedButton>
          </div>

          {errorMsg && <ErrorMessage message={errorMsg} />}
        </form>
      </CardContent>
    </Card>
    <div className={classes.footerContainer}>
      <Typography variant="body2" className={classes.labelFooter}>{LocalizedString.loginScreen.footer}</Typography>
    </div>
    {submitting && <LoadingIndicator />}
  </>
);

export default reduxForm({
  form: Constant.RXFORM_RESET_PASSWORD_SCREEN,
  validate: Validation.rxformValidateResetPassword,
})(withStyles(styles)(ChangePasswordPage));

ChangePasswordPage.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  errorMsg: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};
