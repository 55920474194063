import { connect } from 'react-redux';
import _ from 'lodash';
import ForwarderEditDialog from './forwarder-edit-dialog.presentation';

const getForwarderDetail = (state) => {
  const { forwarders } = state;
  const { selectedForwarderId } = state.uiMasterForwarder;
  return forwarders[selectedForwarderId] || {};
};

const getInitialValues = state => getForwarderDetail(state);

const mapStateToProps = state => ({
  forwarder: getForwarderDetail(state),
  initialValues: getInitialValues(state),
  loading: false,
});

const mapDispatchToProps = dispatch => ({
  onAppear: () => { },
  onSubmitPressed: (values) => { },
});

export default connect(mapStateToProps, mapDispatchToProps)(ForwarderEditDialog);
