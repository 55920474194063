import * as Helper from '../../../helper';
import {
  uploadingBookPartyDocument, setBookFeederActiveForm, addBookFeederPriceContent,
} from '../simple-action';
import { rxformValidateBookExportParty } from '../../../validation';

export default () => async (dispatch, getState) => {
  try {
    dispatch(uploadingBookPartyDocument(true));

    const { token } = getState().authentication;
    const { blNo, bookNo } = getState().uiBookFeederParty;
    const { uploadedAttachment } = getState().uiBookFeederParty;
    const { bookParties } = getState().feederServiceBookPartyList;

    rxformValidateBookExportParty(bookParties);

    const documents = uploadedAttachment.map((x) => {
      const { id, fileSize, ...other } = x;
      return { ...other, content: x.content.substr(x.content.indexOf(',') + 1) };
    });

    const parties = bookParties.map((x) => {
      const { guid, id, ...other } = x;
      if (!Number.isFinite(id)) {
        return other;
      }
      return { ...other, id };
    });

    const stateInfo = {
      blNo,
      bookNo,
      parties,
      documents,
    };

    // const dummyStateInfo = {
    //   blNo: 'AWB0100186',
    //   bookNo: 'FI-180625-153554',
    //   parties: [
    //     {
    //       id: 35892,
    //       sizeOfParty: '20',
    //       typeOfParty: 'DRY',
    //       loadOfParty: 'FCL',
    //       numberOfParty: 1,
    //       deleted: false,
    //     },
    //     {
    //       id: 35893,
    //       sizeOfParty: '40',
    //       typeOfParty: 'DRY',
    //       loadOfParty: 'FCL',
    //       numberOfParty: 10,
    //       deleted: true,
    //     },
    //     {
    //       id: 99999,
    //       sizeOfParty: '60',
    //       typeOfParty: 'DRY',
    //       loadOfParty: 'FCL',
    //       numberOfParty: 1,
    //       deleted: false,
    //     },
    //   ],
    //   documents,
    // };

    const bookFeederPriceContent = await Helper.uploadBookPartyDocument(stateInfo, token);

    dispatch(addBookFeederPriceContent(bookFeederPriceContent));
    dispatch(setBookFeederActiveForm(2));
  } finally {
    dispatch(uploadingBookPartyDocument(false));
  }
};
