import { downloadingFeederServiceEmptyDepoList, addFeederServiceEmptyDepoList } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  try {
    dispatch(downloadingFeederServiceEmptyDepoList(true));
    const feederServiceEmptyDepoList = await Helper.downloadFeederServiceEmptyDepoList(token);
    dispatch(addFeederServiceEmptyDepoList(feederServiceEmptyDepoList));
  } finally {
    dispatch(downloadingFeederServiceEmptyDepoList(false));
  }
};
