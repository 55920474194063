import { CLEAR_ADMIN_PAYMENT_HISTORY_DATA, ADD_ADMIN_PAYMENT_HISTORY_DATA } from '../action';

const initialState = {
  payments: undefined,
  paymentInfo: undefined,
  totalData: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ADMIN_PAYMENT_HISTORY_DATA:
      return initialState;
    case ADD_ADMIN_PAYMENT_HISTORY_DATA:
      return { payments: action.data, paymentInfo: action.dataAsObj, totalData: action.totalData };
    default: return state;
  }
};
