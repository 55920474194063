import * as Action from '../action';
import * as Constant from '../../constant';

const initialState = {
  loading: true,
  filters: {
    username: '',
    email: '',
    role: '',
    registrationIp: '',
    registeredOn: '',
    lastLogin: '',
  },
  sortField: 'username',
  sortDirection: 'desc',
  isFirstPage: true,
  isLastPage: true,
  totalPage: 1,
  currentPage: 1,
  pageSize: 5,
};

export default (state = initialState, action) => {
  if (action.rxState !== Constant.RXSTATE_USER_MANAGEMENT) return state;

  switch (action.type) {
    case Action.ADD_FILTER:
      return {
        ...state,
        filters: { ...state.filters, [action.field]: action.value },
      };
    case Action.ADD_SORT:
      return {
        ...state,
        sortField: action.field,
        sortDirection: action.direction,
      };
    case Action.SET_ACTIVE_PAGE:
      return { ...state, currentPage: action.currentPage };
    case Action.SET_PAGESIZE:
      return { ...state, pageSize: action.pageSize };
    case Action.LOADING_FILTERED_USER_MANAGEMENT:
      return { ...state, loadingFiltered: action.status };
    case Action.SET_OPEN_ADD_DIALOG_STATUS:
      return { ...state, openAddDialog: action.status };
    case Action.SET_OPEN_UPDATE_DIALOG_STATUS:
      return { ...state, openUpdateDialog: action.status };
    case Action.SET_DIALOG_LOADING:
      return { ...state, isDialogLoading: action.status };
    case Action.SET_SAVE_STATUS:
      return { ...state, isSaving: action.status };
    case Action.SET_UPDATE_DIALOG_PARAM:
      return {
        ...state,
        updateDialogParam: { ...state.updateDialogParam, ...action.param },
      };
    default:
      return state;
  }
};
