import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import NewDocumentDetail from './new-document-detail.presentation';
import {
  downloadNewDocumentMyFormDetailAsync, downloadPaymentOnBehalfListAsync,
  setNewDocumentMyFormFieldValue, showErrorSnackbar, uploadNewDocumentMyFormResponseAsync,
} from '../../redux/action';
import LocalizedString from '../../localization';
import { FIELD_TYPE_FILE, FIELD_TYPE_NUMBER } from '../../constant';

const convertStringArrayToDropdownOption = arr => arr.map(item => (
  { label: item.name, value: item.id }
));

const getSortedMyForm = (detail) => {
  const myForm = _.values(detail.fields).sort((a, b) => a.order - b.order);
  return {
    ...detail,
    fields: myForm,
  };
};

const mapStateToProps = state => ({
  tappedId: state.uiNewDocumentMyForm.tappedId,
  downloadingMyFormDetail: state.uiNewDocumentMyForm.downloadingDetail,
  myForm: getSortedMyForm(state.newDocumentMyFormDetail.detail),
  fieldValue: state.newDocumentMyFormDetail.fieldValue,
  paymenOnBehalfList: convertStringArrayToDropdownOption(
    _.values(state.newDocumentPaymentOnBehalfList),
  ),
  downloadingPaymentOnBehalfList: state.newDocumentMyFormDetail.downloadingPaymentOnBehalfList,
  uploadingResponse: state.uiNewDocumentMyForm.uploadingResponse,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async (tappedId) => {
    if (tappedId) {
      Promise.all([
        dispatch(downloadNewDocumentMyFormDetailAsync()),
        dispatch(downloadPaymentOnBehalfListAsync()),
      ]).catch((error) => {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      });
    }
  },
  onFieldChange: (value, fileName, field, fieldValue) => {
    dispatch(setNewDocumentMyFormFieldValue({
      ...fieldValue,
      [field.name]: {
        fieldId: field.id,
        value: field.type === FIELD_TYPE_NUMBER ? +value : value,
        ...(field.type === FIELD_TYPE_FILE && { fileExtension: fileName.split('.').pop() }),
      },
    }));
  },
  onImageChanged: (val, name, id, fieldValue) => {
    if (!val) {
      dispatch(setNewDocumentMyFormFieldValue({
        ...fieldValue,
        [name]: { fieldId: id, value: null, fileExtension: null },
      }));
    }
  },
  onSubmitButtonPressed: async (val, paymentOnBehalf, myForm) => {
    try {
      await dispatch(uploadNewDocumentMyFormResponseAsync(val, paymentOnBehalf, myForm,
        ownProps.history.push));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    }
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewDocumentDetail));
