import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import MasterUserBase from './master-user-base.presentation';
import * as Constant from '../../constant';
import {
  setMasterUserFilter,
  setMasterUserPaging,
  setMasterUserSort,
  setMasterUserAddDialogStatus,
  setMasterUserEditDialogStatus,
  setMasterUserSelectedId,
  downloadAllProfilesAsync,
  showErrorSnackbar,
} from '../../redux/action';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler, toMoment } from '../../helper';

const getFilteredUsers = (state) => {
  const filteredUsers = _.values(state.users).map((item, index) => ({ ...item, index: index + 1 }));

  const { filters, sortField, sortDirection } = state.uiMasterUser;

  return filteredUsers
    .sort((left, right) => {
      if (typeof left[sortField] === 'string') {
        if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
          return left[sortField].localeCompare(right[sortField]);
        }
        return right[sortField].localeCompare(left[sortField]);
      }

      if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
        return left[sortField] - right[sortField];
      }
      return right[sortField] - left[sortField];
    })
    .filter(
      item => item.index
        .toString()
        .indexOf(
          filters[Constant.TABLE_FIELD_MASTER_USER_INDEX].toUpperCase(),
        ) >= 0,
    )
    .filter(
      item => item.username
        .toUpperCase()
        .indexOf(
          filters[Constant.TABLE_FIELD_MASTER_USER_USERNAME].toUpperCase(),
        ) >= 0,
    )
    .filter(
      item => item.email
        .toUpperCase()
        .indexOf(
          filters[Constant.TABLE_FIELD_MASTER_USER_EMAIL].toUpperCase(),
        ) >= 0,
    )
    .filter(
      item => item.fullName
        .toUpperCase()
        .indexOf(
          filters[Constant.TABLE_FIELD_MASTER_USER_FULLNAME].toUpperCase(),
        ) >= 0,
    )
    .filter(
      item => ((item.company && item.company.name) || 'CDP')
        .toUpperCase()
        .indexOf(
          filters[Constant.TABLE_FIELD_MASTER_USER_COMPANY_NAME].toUpperCase(),
        ) >= 0,
    )
    .filter((item) => {
      if (!filters[Constant.TABLE_FIELD_MASTER_USER_REGISTERED_ON]) {
        return true;
      }

      const formattedRegisteredOn = toMoment(item.registeredOn)
        .format(Constant.MOMENT_DATE_FORMAT_STRING);
      const formattedFilter = moment(filters[Constant.TABLE_FIELD_MASTER_USER_REGISTERED_ON])
        .format(Constant.MOMENT_DATE_FORMAT_STRING);
      return formattedRegisteredOn === formattedFilter;
    });
};

const getPageFilteredUsers = (state) => {
  const { activePage, pageSize } = state.uiMasterUser;
  const filteredTruck = getFilteredUsers(state);

  if (filteredTruck) {
    const refinedActivePage = activePage || 1;
    const start = Math.min(
      (refinedActivePage - 1) * pageSize,
      filteredTruck.length,
    );
    const end = Math.min(start + pageSize, filteredTruck.length);
    return filteredTruck.slice(start, end);
  }

  return [];
};

const getTotalPage = (state) => {
  const filteredUsers = getFilteredUsers(state);
  const { pageSize } = state.uiMasterUser;
  const totalData = filteredUsers ? filteredUsers.length : 0;

  return Math.max(Math.ceil(totalData / pageSize), 1);
};

const mapStateToProps = state => ({
  downloading: state.uiMasterUser.downloading,
  menuOpen: state.uiMenu.menuOpen,
  users: getPageFilteredUsers(state),

  filters: state.uiMasterUser.filters,
  activePage: state.uiMasterUser.activePage,
  pageSize: state.uiMasterUser.pageSize,
  totalPage: getTotalPage(state),
  sortField: state.uiMasterUser.sortField,
  sortDirection: state.uiMasterUser.sortDirection,

  dialogAddStatus: state.uiMasterUser.dialogAddStatus,
  dialogEditStatus: state.uiMasterUser.dialogEditStatus,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    // load user data
    try {
      await dispatch(downloadAllProfilesAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onChangeFilter: (filterName, value) => {
    dispatch(setMasterUserFilter(filterName, value));
  },
  onChangePaging: (pageField, value) => {
    dispatch(setMasterUserPaging(pageField, value));
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setMasterUserSort(sortField, sortDirection));
  },
  onAddNewPressed: () => {
    dispatch(setMasterUserAddDialogStatus(true));
  },
  onAddDialogClosePressed: () => {
    dispatch(setMasterUserAddDialogStatus(false));
  },
  onEditPressed: (userId) => {
    dispatch(setMasterUserSelectedId(userId));
    dispatch(setMasterUserEditDialogStatus(true));
  },
  onEditDialogClosePressed: () => {
    dispatch(setMasterUserEditDialogStatus(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MasterUserBase);
