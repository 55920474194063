import _ from 'lodash';
import * as Action from '../action';

const initialState = {
  downloadingTermAndConditions: false,
  error: '',
  termAndConditionText: '',
  expandedBlNumber: [],
  dialogPaymentConfirmStatus: false,
  dialogPaymentCompleteStatus: false,
  dialogPaymentSelectBankStatus: false,
  dialogTermAndCondition: false,
  uploadingPayment: false,
  password: '',
  selectedBank: '',
  paymentProcessStarted: false,
  termAndConditionChecked: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.SET_EXPANDED_CHECKOUT_ITEM: {
      return { ...state, expandedBlNumber: [...state.expandedBlNumber, action.blNumber] };
    }
    case Action.REMOVE_EXPANDED_CHECKOUT_ITEM: {
      return { ...state, expandedBlNumber: _.without(state.expandedBlNumber, action.blNumber) };
    }
    case Action.SET_DIALOG_PAYMENT_CONFIRM_STATUS: {
      return { ...state, dialogPaymentConfirmStatus: action.value };
    }
    case Action.SET_DIALOG_PAYMENT_COMPLETE_STATUS: {
      return { ...state, dialogPaymentCompleteStatus: action.value };
    }
    case Action.SET_UPLOADING_PAYMENT: {
      return { ...state, uploadingPayment: action.value };
    }
    case Action.SET_CHECKOUT_PASSWORD_TEXT_INPUT: {
      return { ...state, password: action.value };
    }
    case Action.START_PAYMENT_PROCESS:
      return { ...state, paymentProcessStarted: action.status };
    case Action.SET_DIALOG_SELECT_BANK_STATUS:
      return { ...state, dialogPaymentSelectBankStatus: action.value };
    case Action.SET_CHECKOUT_BANK_SELECTION:
      return { ...state, selectedBank: action.value };
    case Action.DOWNLOADING_CHECKOUT_TERM_AND_CONDITIONS: {
      return { ...state, downloadingTermAndConditions: action.value };
    }
    case Action.SET_CHECKOUT_TERM_AND_CONDITIONS_CHECKED:
      return { ...state, termAndConditionChecked: action.status };
    case Action.SET_DIALOG_CHECKOUT_TERM_AND_CONDITION: {
      return { ...state, dialogTermAndCondition: action.value };
    }
    case Action.SET_CHECKOUT_TERM_AND_CONDITIONS_TEXT: {
      return { ...state, termAndConditionText: action.value };
    }
    default: {
      return state;
    }
  }
};
