import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import OnProgressCard from './on-progress-card.presentation';
import {
  downloadTrackingStatusBehandleOnProgressesAsync,
  setTrackingStatusBehandleOnProgressSearchText,
  setTrackingStatusBehandleOnProgressTappedId,
  showErrorSnackbar,
} from '../../redux/action';
import LocalizedString from '../../localization';
import { toMoment, unauthorizedErrorHandler } from '../../helper';

const getFilteredData = state => _.values(state.trackingStatusBehandleOnProgresses)
  .filter((item) => {
    const searchText = state.uiTrackingStatusBehandleOnProgress.searchBarText.toLowerCase();
    return item.containerNo.toLowerCase().indexOf(searchText) >= 0
        || (item.customer && item.customer.toLowerCase().indexOf(searchText) >= 0)
        || (item.pibNum && item.pibNum.toLowerCase().indexOf(searchText) >= 0)
        || (item.blNum && item.blNum.toLowerCase().indexOf(searchText) >= 0);
  })
  .sort((left, right) => toMoment(right.timeGateIn) - toMoment(left.timeGateIn));

const mapStateToProps = state => ({
  downloading: state.uiTrackingStatusBehandleOnProgress.downloading,
  data: getFilteredData(state),
  searchBarText: state.uiTrackingStatusBehandleOnProgress.searchBarText,
  selectedItemNo: state.uiTrackingStatusBehandleOnProgress.tappedId,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    try {
      await dispatch(downloadTrackingStatusBehandleOnProgressesAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onChangeSearchBarText: (text) => {
    dispatch(setTrackingStatusBehandleOnProgressSearchText(text));
  },
  onMorePressed: (tappedItemNo) => {
    dispatch(setTrackingStatusBehandleOnProgressTappedId(tappedItemNo));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnProgressCard));
