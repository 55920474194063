import uuid from 'uuid/v4';
import * as Action from '../action';

const filteredBookParty = (array = [], id) => {
  array.forEach((e) => {
    const itemDeleteVal = e.id === id ? e.deleted = true : e.deleted;
    return itemDeleteVal;
  });
  return array;
};

const modifyBookParty = (array = [], info) => {
  const index = array.findIndex(x => x.id === info.id);
  // eslint-disable-next-line no-param-reassign
  array[index] = info;
  return array;
};

const initialState = {
  bookParties: [],
  newBookParty: {
    id: 0,
    sizeOfParty: undefined,
    typeOfParty: undefined,
    loadOfParty: 'FCL',
    numberOfParty: undefined,
    deleted: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.ADD_FEEDER_SERVICE_BOOK_PARTY_LIST:
      return { ...state, bookParties: Object.values(action.bookPartyList) };
    case Action.ADD_BOOK_PARTY: {
      const addedParty = { ...action.info, guid: uuid() };
      return { ...state, bookParties: [...state.bookParties, addedParty] }; }
    case Action.MODIFY_BOOK_PARTY:
      return {
        ...state,
        bookParties: modifyBookParty(state.bookParties, action.info),
      };
    case Action.REMOVE_BOOK_PARTY:
      return {
        ...state, bookParties: filteredBookParty(state.bookParties, action.partyId),
      };
    default:
      return state;
  }
};
