import { connect } from 'react-redux';
import MainMenuScreen from './main-menu.presentation';
import {
  setTappedExportBlNo,
  setTappedContainerTrackingBlNo,
  setTappedFeederServiceBlNo,
  setTappedAssignBlNo,
  setTappedTermBlNo,
  setTappedPaymentHistoryProformaNo,
  setTappedOutstandingItemNo,
  setCombineTariffSimulationOrderListWithImportReleaseOrder,
  setTariffSimulationReleaseSearchText,
} from '../../redux/action';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  clearSelectedData: () => {
    dispatch(setTappedExportBlNo(''));
    dispatch(setTappedContainerTrackingBlNo(''));
    dispatch(setTappedFeederServiceBlNo(''));
    dispatch(setTappedAssignBlNo(''));
    dispatch(setTappedTermBlNo(''));
    dispatch(setTappedPaymentHistoryProformaNo(''));
    dispatch(setTappedOutstandingItemNo(''));
    dispatch(setCombineTariffSimulationOrderListWithImportReleaseOrder(false));
    dispatch(setTariffSimulationReleaseSearchText(''))
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainMenuScreen);
