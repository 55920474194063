import * as Action from '../action';
import * as Constant from '../../constant';

const initialState = {
  downloadingContainerTrackingsData: false,
  tappedBlNo: '',
  tappedItemNo: '',
  searchBarText: '',
  filters: {
    [Constant.TABLE_FIELD_CONTAINER_NUMBER]: '',
    [Constant.TABLE_FIELD_SERVICE]: '',
    [Constant.TABLE_FIELD_SIZE]: '',
    [Constant.TABLE_FIELD_GATE_IN_CDP]: '',
    [Constant.TABLE_FIELD_PARTY]: '',
  },
  sortField: '',
  sortDirection: '',
  activePage: 1,
  pageSize: 5,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.DOWNLOADING_CONTAINER_TRACKINGS_DATA:
      return { ...state, downloadingContainerTrackingsData: action.status };
    case Action.SET_TAPPED_CONTAINER_TRACKING_BL_NO:
      return { ...state, tappedBlNo: action.tappedBlNo };
    case Action.SET_TAPPED_CONTAINER_TRACKING_ITEM_NO:
      return { ...state, tappedItemNo: action.tappedItemNo };
    case Action.SET_CONTAINER_TRACKING_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case Action.SET_CONTAINER_TRACKING_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.value,
        },
      };
    }
    case Action.SET_CONTAINER_TRACKING_PAGING: {
      return { ...state, ...action.value };
    }
    case Action.SET_CONTAINER_TRACKING_SORT: {
      return { ...state, sortField: action.sortField, sortDirection: action.sortDirection };
    }
    default:
      return state;
  }
};
