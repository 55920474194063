import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  withStyles,
  CssBaseline,
  Grid,
  Card,
  CardContent,
  Fab,
  Icon,
  Typography,
} from '@material-ui/core';
import * as Constant from '../../constant';
import { PageHeaderUser, LoadingIndicator } from '../../component';
import LocalizedString from '../../localization';
import MyPaymentTabs from './tabs';
import HistoryBlCard from './history-card.container';
import HistoryBlDetail from './history-detail.container';
import OutstandingBlCard from './outstanding-card.container';
import OutstandingBlDetail from './outstanding-detail.container';
import TermBlCard from './term-card.container';
import TermBlDetail from './term-detail.container';
import OrderTab from './order-tab.container';

const styles = theme => ({
  backIcon: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  btnNewBooking: {
    backgroundColor: '#5CB860',
    color: '#ffffff',
    width: '256px',
    '&:hover': {
      backgroundColor: '#558b2f',
    },
  },
  btnAddToCart: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
    width: '256px',
  },
  detailButton: {
    backgroundColor: '#ffffff',
  },
  grow: {
    flexGrow: 1,
  },
  searchButton: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  moreButton: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  cardActionPrimary: {
    display: 'flex',
    alignItems: 'baseline',
    padding: 16,
    justifyContent: 'space-between',
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  cardBlDataHeader: {
    marginBottom: theme.spacing.unit * 2,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${Constant.DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  detailHeader: {
    color: '#0066CB',
  },
  itemPerRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    color: '#0266B4',
  },
  root: {
    width: '100%',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  textJumlahPembayaran: {
    textTransform: 'uppercase',
  },
  textTotalPrice: {
    fontSize: '22px',
    fontWeight: 500,
    color: '#0065B3',
    marginRight: theme.spacing.unit * 2,
  },
});

class MyPaymentBase extends Component {
  componentDidMount() {
    this.props.onAppear(this.props.selectedTab);
  }

  render() {
    const {
      classes,
      notificationOpen,
      selectedTab,
      userCompany,
      onTabPressed,
      isDownloading,
    } = this.props;

    return (
      <>
        <div className={classes.root}>
          <CssBaseline />

          <PageHeaderUser />

          <div
            className={
              classNames(classes.content, {
                [classes.contentDrawerOpen]: notificationOpen,
                [classes.contentDrawerClosed]: !notificationOpen,
              })
            }
            style={{ padding: '16px' }}
          >
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <div className={classes.pageNavigatorContainer}>
                      <Fab
                        variant="round"
                        classes={{ root: classes.backIcon }}
                        component={Link}
                        to={Constant.ROUTE_NAME_DASHBOARD_USER}
                      >
                        <Icon>chevron_left</Icon>
                      </Fab>
                      <div style={{ marginLeft: '32px' }}>
                        <Typography variant="h5" className={classes.pageTitle}>{LocalizedString.myPayment.title}</Typography>
                        <Typography variant="caption">{LocalizedString.myPayment.caption}</Typography>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <div>
                      <MyPaymentTabs
                        selectedTab={selectedTab}
                        onTabPressed={onTabPressed}
                        userCompany={userCompany}
                      />
                    </div>

                    {selectedTab === 0 && <OutstandingBlCard />}
                    {selectedTab === 1 && <TermBlCard />}
                    {selectedTab === 2 && <HistoryBlCard />}
                    {selectedTab === 3 && <OrderTab />}
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={8}>
                {selectedTab === 0 && <OutstandingBlDetail />}
                {selectedTab === 1 && <TermBlDetail />}
                {selectedTab === 2 && <HistoryBlDetail />}
              </Grid>
            </Grid>
          </div>
        </div>

        {(isDownloading) && <LoadingIndicator />}
      </>
    );
  }
}
MyPaymentBase.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  notificationOpen: PropTypes.bool.isRequired,
  selectedTab: PropTypes.number.isRequired,
  userCompany: PropTypes.shape(PropTypes.any).isRequired,
  isDownloading: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onTabPressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(MyPaymentBase);
