import * as Action from '../action';

const initialState = {
  detail: {},
  fieldValue: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.SET_NEW_DOCUMENT_MY_FORM_DETAIL:
      return { ...state, detail: action.value };
    case Action.SET_NEW_DOCUMENT_MY_FORM_FIELD_VALUE:
      return { ...state, fieldValue: action.value };
    default:
      return state;
  }
};
