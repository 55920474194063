import React, { Component } from 'react';
import {
  Typography,
  TextField,
  MenuItem,
  Paper,
  Chip,
  Icon,
  withStyles,
  withTheme,
} from '@material-ui/core';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import Select from 'react-select';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    marginTop: '6px',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    // right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

const NoOptionsMessage = props => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.noOptionsMessage}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);
NoOptionsMessage.propTypes = {
  selectProps: PropTypes.shape(PropTypes.any),
  innerProps: PropTypes.shape(PropTypes.any),
  children: PropTypes.shape(PropTypes.any),
};
NoOptionsMessage.defaultProps = {
  selectProps: {},
  innerProps: {},
  children: {},
};

const inputComponent = ({ inputRef, ...props }) => (
  <div ref={inputRef} {...props} />
);
inputComponent.propTypes = {
  inputRef: PropTypes.shape(PropTypes.any),
};
inputComponent.defaultProps = {
  inputRef: undefined,
};

const Control = props => (
  <TextField
    fullWidth
    InputProps={{
      inputComponent,
      inputProps: {
        className: props.selectProps.classes.input,
        inputRef: props.innerRef,
        children: props.children,
        ...props.innerProps,
      },
    }}
    {...props.selectProps.textFieldProps}
  />
);
Control.propTypes = {
  selectProps: PropTypes.shape(PropTypes.any),
  innerProps: PropTypes.shape(PropTypes.any),
  children: PropTypes.shape(PropTypes.any),
  innerRef: PropTypes.shape(PropTypes.any),
};
Control.defaultProps = {
  selectProps: {},
  innerProps: {},
  children: {},
  innerRef: undefined,
};

const Option = props => (
  <MenuItem
    buttonRef={props.innerRef}
    selected={props.isFocused}
    component="div"
    style={{
      fontWeight: props.isSelected ? 500 : 400,
    }}
    {...props.innerProps}
  >
    {props.children}
  </MenuItem>
);
Option.propTypes = {
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  innerProps: PropTypes.shape(PropTypes.any),
  children: PropTypes.shape(PropTypes.any),
  innerRef: PropTypes.shape(PropTypes.any),
};
Option.defaultProps = {
  isFocused: false,
  isSelected: false,
  innerProps: {},
  children: undefined,
  innerRef: undefined,
};

const Placeholder = props => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.placeholder}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);
Placeholder.propTypes = {
  selectProps: PropTypes.shape(PropTypes.any),
  innerProps: PropTypes.shape(PropTypes.any),
  children: PropTypes.shape(PropTypes.any),
};
Placeholder.defaultProps = {
  selectProps: {},
  innerProps: {},
  children: undefined,
};

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}
SingleValue.propTypes = {
  selectProps: PropTypes.shape(PropTypes.any),
  innerProps: PropTypes.shape(PropTypes.any),
  children: PropTypes.shape(PropTypes.any),
};
SingleValue.defaultProps = {
  selectProps: {},
  innerProps: {},
  children: undefined,
};

const ValueContainer = props => (
  <div className={props.selectProps.classes.valueContainer}>
    {props.children}
  </div>
);
ValueContainer.propTypes = {
  selectProps: PropTypes.shape(PropTypes.any),
  children: PropTypes.shape(PropTypes.any),
};
ValueContainer.defaultProps = {
  selectProps: {},
  children: undefined,
};

const MultiValue = props => (
  <Chip
    tabIndex={-1}
    label={props.children}
    className={classNames(props.selectProps.classes.chip, {
      [props.selectProps.classes.chipFocused]: props.isFocused,
    })}
    onDelete={props.removeProps.onClick}
    deleteIcon={<Icon {...props.removeProps}>close</Icon>}
  />
);
MultiValue.propTypes = {
  selectProps: PropTypes.shape(PropTypes.any),
  children: PropTypes.shape(PropTypes.any),
  isFocused: PropTypes.bool,
  removeProps: PropTypes.shape(PropTypes.any),
};
MultiValue.defaultProps = {
  selectProps: {},
  children: undefined,
  isFocused: false,
  removeProps: {},
};

const Menu = props => (
  <Paper
    square
    className={props.selectProps.classes.paper}
    {...props.innerProps}
  >
    {props.children}
  </Paper>
);
Menu.propTypes = {
  selectProps: PropTypes.shape(PropTypes.any),
  innerProps: PropTypes.shape(PropTypes.any),
  children: PropTypes.shape(PropTypes.any),
};
Menu.defaultProps = {
  selectProps: {},
  innerProps: {},
  children: undefined,
};

const DropdownIndicator = () => <Icon>arrow_drop_down</Icon>;

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  DropdownIndicator,
};

class FilterableSelect extends Component {
  state = {
    open: false,
  };

  onBlur = () => {
    this.setState({ open: false });
  };

  onFocus = () => {
    this.setState({
      open: true,
    });
  };

  render() {
    const {
      classes,
      theme,
      options,
      label,
      value,
      onChange,
      required,
    } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    const selectedOption = options.filter(option => option.value === value);

    return (
      <Select
        required={required}
        classes={classes}
        styles={selectStyles}
        options={options}
        components={components}
        value={selectedOption}
        onChange={option => onChange(option && option.value)}
        onFocus={this.onFocus}
        textFieldProps={{
          label: label + (required ? ' *' : ''),
          InputLabelProps: {
            shrink: !!(selectedOption.length > 0 || this.state.open),
          },
        }}
        menuPosition="absolute"
        placeholder=""
        isClearable
      />
    );
  }
}

FilterableSelect.propTypes = {
  classes: PropTypes.shape(PropTypes.any),
  theme: PropTypes.shape(PropTypes.any),
  options: PropTypes.arrayOf(PropTypes.any),
  label: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

FilterableSelect.defaultProps = {
  classes: {},
  theme: {},
  options: [],
  label: '',
  value: null,
  onChange: () => {},
  required: false,
};

const FilterableSelectWithStyles = withStyles(styles)(FilterableSelect);

export default withTheme()(FilterableSelectWithStyles);
