import {
  DOWNLOADING_MONEY_LOAN_HISTORY, SET_MONEY_LOAN_HISTORY_FILTER, SET_MONEY_LOAN_HISTORY_PAGE,
  SET_MONEY_LOAN_HISTORY_SORT, SET_MONEY_LOAN_HISTORY_TAPPED_ID,
} from '../action/simple-action';
import {
  TABLE_FIELD_LOAN_HISTORY_AMOUNT, TABLE_FIELD_LOAN_HISTORY_TERM_DAYS,
  TABLE_FIELD_LOAN_HISTORY_STATUS, TABLE_SORT_DESCENDING,
  TABLE_FIELD_LOAN_HISTORY_CREATED_DATE,
} from '../../constant';

const initialState = {
  downloading: false,
  tappedId: '',
  filters: {
    [TABLE_FIELD_LOAN_HISTORY_AMOUNT]: '',
    [TABLE_FIELD_LOAN_HISTORY_TERM_DAYS]: '',
    [TABLE_FIELD_LOAN_HISTORY_STATUS]: '',
    [TABLE_FIELD_LOAN_HISTORY_CREATED_DATE]: null,
  },
  activePage: 1,
  pageSize: 5,
  sortField: TABLE_FIELD_LOAN_HISTORY_CREATED_DATE,
  sortDirection: TABLE_SORT_DESCENDING,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_MONEY_LOAN_HISTORY:
      return { ...state, downloading: action.status };
    case SET_MONEY_LOAN_HISTORY_TAPPED_ID:
      return { ...state, tappedId: action.tappedId };
    case SET_MONEY_LOAN_HISTORY_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.pageField]: action.value,
        },
      };
    }
    case SET_MONEY_LOAN_HISTORY_PAGE: {
      return {
        ...state,
        [action.pageField]: action.value,
      };
    }
    case SET_MONEY_LOAN_HISTORY_SORT: {
      return {
        ...state,
        sortField: action.sortField,
        sortDirection: action.sortDirection,
      };
    }
    default: return state;
  }
};
