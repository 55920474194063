import { downloadingFeederServiceForwarderList, addFeederServiceForwarderList } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  try {
    dispatch(downloadingFeederServiceForwarderList(true));
    const feederServiceForwarderList = await Helper.downloadFeederServiceForwarderList(token);
    dispatch(addFeederServiceForwarderList(feederServiceForwarderList));
  } finally {
    dispatch(downloadingFeederServiceForwarderList(false));
  }
};
