import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  withStyles,
  Grid,
  IconButton,
  Icon,
  Typography,
  Button,
  TextField,
} from '@material-ui/core';
import {
} from '../../helper';
import {
} from '../../constant';
import PartyField from './party-field.container';
import { LoadingIndicator } from '../../component';
import LocalizedString from '../../localization';

const styles = () => ({
  dialogActions: {
    margin: '0 -24px',
    marginTop: '24px',
    padding: 24,
    borderTop: '1px solid #C4C4C4',
    justifyContent: 'space-between',
    paddingBottom: 0,
    display: 'flex',
  },
  disclaimerTitle: {
    fontWeight: 'bold',
    fontSize: '1rem',
    color: '#404040',
  },
  expansionPanelNewParty: {
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  expansionPanelSummary: {
    borderBottom: '1px solid silver',
  },
  expansionPanelSummaryExpanded: {
    minHeight: 'inherit !important',
  },
  flexGrow: {
    flexGrow: 1,
  },
  btnAdd: {
    backgroundColor: '#ffffff',
  },
  thumbnail: {
    width: '48px',
    height: '48px',
    objectFit: 'cover',
  },
  labelFileName: {
    marginLeft: '24px',
    marginRight: '24px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '480px',
  },
  labelSelectPhotoInfo: {
    marginLeft: '24px',
    fontStyle: 'italic',
  },
  hidden: {
    display: 'none',
  },
  partyItem: {
    padding: 16,
  },
});

const isInEditMode = (
  existingParty, inEditParty, newParty,
) => existingParty.filter(item => !item.deleted)
  .some((party) => {
    const inEdit = inEditParty.some(item => item === party.id);
    const inNew = newParty.some(item => item === party.id);

    return inEdit || inNew;
  });

const PartyInfo = ({
  party,
  onEditPartyPressed,
  onRemovePartyPressed,
}) => (
  <div style={{ marginTop: '16px', width: '100%' }}>
    <Grid container spacing={24}>
      <Grid item xs={6} md={2}>
        <TextField
          readOnly
          fullWidth
          value={party.numberOfParty}
          label={LocalizedString.bookingFeeder.fieldPartyVol}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <TextField
          readOnly
          fullWidth
          value={party.sizeOfParty}
          label={LocalizedString.bookingFeeder.fieldPartySize}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <TextField
          readOnly
          fullWidth
          value={party.typeOfParty}
          label={LocalizedString.bookingFeeder.fieldPartyType}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        {/* <TextField
          readOnly
          fullWidth
          value={party.loadOfParty}
          label="Status"
        /> */}
        <IconButton
          size="small"
          color="primary"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onEditPartyPressed(party.id);
          }}
        >
          <Icon>edit</Icon>
        </IconButton>
        <IconButton
          size="small"
          color="secondary"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onRemovePartyPressed(party.id);
          }}
        >
          <Icon>delete</Icon>
        </IconButton>
      </Grid>
    </Grid>
  </div>
);
PartyInfo.propTypes = {
  party: PropTypes.shape(PropTypes.any).isRequired,
  onEditPartyPressed: PropTypes.func.isRequired,
  onRemovePartyPressed: PropTypes.func.isRequired,
};

class PartyTab extends Component {
  fileInputRef = createRef();

  componentDidMount() {
    this.props.onAppear();
  }

  render() {
    const {
      classes,
      parties,
      inEditParty,
      newParty,
      disclaimer,
      uploadedAttachment,
      downloadingPartyList,
      onAddParty,
      onRemoveParty,
      onRemoveAttachment,
      onSelectedMedia,
      onEditPartyPressed,
      onCancelEditPressed,
      onSubmitEditPressed,
      onPreviousPressed,
      onNextPressed,
    } = this.props;

    const renderPhoto = () => uploadedAttachment.map(photo => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={photo.content} className={classes.thumbnail} alt="" />
        <Typography className={classes.labelFileName}>{photo.fileName}</Typography>
        <IconButton
          onClick={() => onRemoveAttachment(photo.id)}
          color="secondary"
        >
          <Icon>close</Icon>
        </IconButton>
      </div>
    ));

    // const renderPartyHeader = (party, index) => {
    //   if (newParty && newParty.includes(party.id)) {
    //     return (
    //       <ExpansionPanelSummary
    //         classes={{
    //           root: classes.expansionPanelSummary,
    //           expanded: classes.expansionPanelSummaryExpanded,
    //         }}
    //       >
    //         <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
    //           <Typography>{`Party #${index + 1}`}</Typography>
    //         </div>
    //       </ExpansionPanelSummary>
    //     );
    //   }
    //   return (
    //     <ExpansionPanelSummary
    //       classes={{
    //         root: classes.expansionPanelSummary,
    //         expanded: classes.expansionPanelSummaryExpanded,
    //       }}
    //       expandIcon={<Icon>expand_more</Icon>}
    //       onClick={() => onExpandPanelPressed(party.id, expandedPanels)}
    //     >
    //       <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
    //         <Typography>{`Party #${index + 1}`}</Typography>
    //         <IconButton
    //           size="small"
    //           color="secondary"
    //           onClick={(event) => {
    //             event.preventDefault();
    //             event.stopPropagation();
    //             onRemoveParty(party.id);
    //           }}
    //         >
    //           <Icon>delete</Icon>
    //         </IconButton>
    //         <div className={classes.flexGrow} />
    //         <Button
    //           color="primary"
    //           onClick={(event) => {
    //             event.preventDefault();
    //             event.stopPropagation();
    //             onEditPartyPressed(party.id);
    //           }}
    //         >
    //           Edit
    //         </Button>
    //       </div>
    //     </ExpansionPanelSummary>
    //   );
    // };

    const renderPartyInfo = (party) => {
      if (inEditParty.includes(party.id)
        || newParty.includes(party.id)) {
        return (
          <PartyField
            form={`party_${party.id}`}
            partyId={party.id}
            onCancelPressed={() => onCancelEditPressed(party.id, newParty)}
            onSubmitPressed={onSubmitEditPressed}
          />
        );
      }
      return (
        <PartyInfo
          party={party}
          onEditPartyPressed={onEditPartyPressed}
          onRemovePartyPressed={onRemoveParty}
        />
      );
    };

    return (
      <>
        {parties && parties.map(item => (
          <div
            key={item.id}
            className={classNames(classes.partyItem, {
              [classes.expansionPanelNewParty]: newParty.includes(item.id)
                || inEditParty.includes(item.id),
            })}
          >
            {renderPartyInfo(item)}
          </div>
        ))}
        <div style={{ marginTop: '24px' }} />
        <Button
          variant="outlined"
          color="primary"
          className={classes.btnAdd}
          onClick={onAddParty}
        >
          Add Party
        </Button>

        <div style={{ marginTop: '24px' }} />

        {renderPhoto()}

        <div style={{ marginTop: '24px', display: 'flex', alignItems: 'baseline' }}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.btnAdd}
            onClick={() => this.fileInputRef.current.click()}
          >
            {LocalizedString.bookingFeeder.btnAddPhoto}
          </Button>
          <div className={classes.labelSelectPhotoInfo}>
            <Typography className={classes.disclaimerTitle}>
              {(disclaimer && disclaimer.subject) || '-'}
            </Typography>
            <Typography variant="caption">
              {(disclaimer && disclaimer.description) || '-'}
            </Typography>
          </div>
          <input
            type="file"
            multiple
            accept="image/*"
            ref={this.fileInputRef}
            style={{ display: 'none' }}
            onChange={({ nativeEvent }) => onSelectedMedia(nativeEvent.target.files)}
          />
        </div>

        <div className={classes.dialogActions}>
          <div>
            <Button
              type="button"
              color="primary"
              onClick={onPreviousPressed}
            >
              Previous
            </Button>
          </div>
          <div style={{ flexGrow: 1 }} />
          <div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={onNextPressed}
              disabled={isInEditMode(parties, inEditParty, newParty)}
            >
              Next
            </Button>
          </div>
        </div>
        {downloadingPartyList && <LoadingIndicator />}
      </>
    );
  }
}
PartyTab.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  parties: PropTypes.arrayOf(PropTypes.any).isRequired,
  inEditParty: PropTypes.arrayOf(PropTypes.string).isRequired,
  newParty: PropTypes.arrayOf(PropTypes.string).isRequired,
  disclaimer: PropTypes.shape(PropTypes.any).isRequired,
  uploadedAttachment: PropTypes.arrayOf(PropTypes.any).isRequired,
  downloadingPartyList: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onAddParty: PropTypes.func.isRequired,
  onRemoveParty: PropTypes.func.isRequired,
  onRemoveAttachment: PropTypes.func.isRequired,
  onSelectedMedia: PropTypes.func.isRequired,
  onEditPartyPressed: PropTypes.func.isRequired,
  onCancelEditPressed: PropTypes.func.isRequired,
  onSubmitEditPressed: PropTypes.func.isRequired,
  onPreviousPressed: PropTypes.func.isRequired,
  onNextPressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(PartyTab);
