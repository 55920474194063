import moment from 'moment-timezone';
import {
  downloadingMyNotification, addNotification, setNotificationNextAutoUpdate, showErrorSnackbar,
} from '../simple-action';
import { downloadMyNotifications } from '../../../helper';
import { NOTIF_TITLE_MONEY_LOAN, NOTIF_TITLE_PAYMENT, NOTIF_TITLE_TOPUP } from '../../../constant';
import downloadMoneyLoanHistoryAsync from './downloadMoneyLoanHistoryAsync';
import downloadMoneyLoanInfoAsync from './downloadMoneyLoanInfoAsync';
import updateDepositBalanceAsync from './updateDepositBalanceAsync';
import LocalizedString from '../../../localization';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { notification } = getState();
  const { nextNotificationAutoUpdate } = getState().uiWebUserMain;
  const now = moment();

  if (!token) {
    return;
  }

  if (nextNotificationAutoUpdate === null
    || moment(nextNotificationAutoUpdate).toDate().valueOf() > now.toDate().valueOf()) {
    return;
  }

  try {
    dispatch(downloadingMyNotification(true));
    dispatch(setNotificationNextAutoUpdate(null));
    const list = await downloadMyNotifications(token);

    const sortedNotifications = Object.values(list)
      .sort((a, b) => (moment(a.receivedOn) > moment(b.receivedOn) ? -1 : 1));
    const [first] = sortedNotifications;
    const isOldNotif = Object.values(notification).find(item => item.id === first.id);

    if (!isOldNotif) {
      if (first.title.toUpperCase().includes(NOTIF_TITLE_PAYMENT)
      || first.title.toUpperCase().includes(NOTIF_TITLE_MONEY_LOAN)
      || first.title.toUpperCase().includes(NOTIF_TITLE_TOPUP)) {
        try {
          dispatch(updateDepositBalanceAsync());
          dispatch(downloadMoneyLoanInfoAsync());
          dispatch(downloadMoneyLoanHistoryAsync());
        } catch (error) {
          dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
        }
      }
    }

    dispatch(addNotification(list));
  } catch {
    // do nothing
  } finally {
    const nextUpdate = now.add('10', 'seconds');
    dispatch(downloadingMyNotification(false));
    dispatch(setNotificationNextAutoUpdate(nextUpdate));
  }

  // const { token } = getState().authentication;
  // const { downloadingNotification } = getState().uiWebUserMain;

  // if (!downloadingNotification) {
  //   if (token) {
  //     try {
  //       dispatch(downloadingMyNotification(true));
  //       const notifications = await downloadMyNotifications(token);
  //       dispatch(addNotification(notifications));
  //     } finally {
  //       dispatch(downloadingMyNotification(false));
  //     }
  //   } else {
  //     throw new Error({ status: 401, message: LocalizedString.common.sessionExpired });
  //   }
  // }
};
