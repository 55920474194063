import { connect } from 'react-redux';
import ResetPassword from './reset-password.presentation';
import {
  submittingForgetPassword, clearUIError, setUIError, resetPasswordAsync,
} from '../../redux/action';
import * as Constant from '../../constant';

const mapStateToProps = state => ({
  errorMsg: state.uiForgetPassword.error,
  submitting: state.uiForgetPassword.submittingResetPassword,
  initialValues: {
    password: state.forgetPasswordInfo.password,
    repassword: state.forgetPasswordInfo.password,
  },
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: async ({ password }) => {
    try {
      dispatch(submittingForgetPassword(true));
      dispatch(clearUIError(Constant.RXSTATE_FORGET_PASSWORD_PAGE));

      await dispatch(resetPasswordAsync(password, ownProps.history.push));
    } catch (error) {
      dispatch(setUIError(Constant.RXSTATE_FORGET_PASSWORD_PAGE, error.message));
    } finally {
      dispatch(submittingForgetPassword(false));
    }
  },
  onBack: () => {
    ownProps.history.push(Constant.ROUTE_NAME_PIN_VERIFICATION_FORGET_PASSWORD);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
