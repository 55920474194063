import React from 'react';
import PropTypes from 'prop-types';
import { Button, withStyles } from '@material-ui/core';

const styles = () => ({
  roundButton: {
    borderRadius: '72px',
    boxShadow: 'none',
  },
  roundButtonContained: {
    borderRadius: '72px',
    backgroundColor: '#ffbf00',
    boxShadow: 'none',
  },
});

const RoundedButton = ({ classes, variant, ...props }) => (
  <Button size="large" variant={variant || 'contained'} color="primary" classes={{ root: variant ? classes.roundButton : classes.roundButtonContained }} {...props}>{props.children}</Button>
);
RoundedButton.propTypes = {
  classes: PropTypes.shape(PropTypes.any),
  variant: PropTypes.string,
  children: PropTypes.shape(PropTypes.any),
};
RoundedButton.defaultProps = {
  classes: {},
  variant: undefined,
  children: {},
};

export default withStyles(styles)(RoundedButton);
