import { downloadingTrackingStatusBehandleHistories, setTrackingStatusBehandleHistories } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;

  try {
    dispatch(downloadingTrackingStatusBehandleHistories(true));
    const data = await Helper.downloadTrackingStatusBehandleHistories(token);
    dispatch(setTrackingStatusBehandleHistories(data));
  } finally {
    dispatch(downloadingTrackingStatusBehandleHistories(false));
  }
};
