import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logoutAsync } from '../redux/action';

class LogoutScreen extends Component {
  componentWillMount() {
    this.props.onAppear();
  }

  render() {
    return (
      <div>Logging out...</div>
    );
  }
}

LogoutScreen.propTypes = {
  onAppear: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: () => {
    dispatch(logoutAsync(ownProps.history.push));
  },
});

export default connect(null, mapDispatchToProps)(LogoutScreen);
