import {
  bookingFeederService,
  addBookFeederService,
  addBookNo,
  addBlNo,
} from '../simple-action';
import * as Validation from '../../../validation';
import * as Helper from '../../../helper';
import * as Constant from '../../../constant';
import LocalizedString from '../../../localization';

export default info => async (dispatch, getState) => {
  try {
    dispatch(bookingFeederService(true));

    const { currentUser, authentication, feederServiceBLList } = getState();

    let bookFeederInfo = {};

    if (currentUser.company.role === Constant.ROLE_CUSTOMER) {
      bookFeederInfo = { ...info, billTo: currentUser.company.id };
    } else {
      bookFeederInfo = { ...info };
    }

    const blInfo = feederServiceBLList[bookFeederInfo.blNo];
    Validation.validateBookFeeder(bookFeederInfo,
      blInfo.exim.toUpperCase() === Constant.CART_SERVICE_NAME_EXPORT.toUpperCase());

    if (currentUser.company.role.toUpperCase() !== Constant.ROLE_CUSTOMER) {
      if (!bookFeederInfo[Constant.RXFIELD_FORWARDER_ID]) {
        throw new Error(LocalizedString.common.errMsgEmptyRequiredFields);
      }
    }

    dispatch(addBookFeederService(bookFeederInfo));

    const stateInfo = {
      doNo: bookFeederInfo.doNo,
      paymentOnBehalfOf: bookFeederInfo.paymentOnBehalfOf,
      doDate: bookFeederInfo.doExpired,
      spcDate: bookFeederInfo.spcDate,
      planDate: bookFeederInfo.planDate,
      forwarderId: bookFeederInfo.forwarderId,
      billTo: bookFeederInfo.billTo,
      blNo: bookFeederInfo.blNo,
      emptyDepo: bookFeederInfo.emptyDepo,
      area: bookFeederInfo.area,
      warehouseId: bookFeederInfo.warehouse,
    };
    const bookNo = await Helper.bookFeederService(stateInfo, authentication.token);
    dispatch(addBookNo(bookNo.bookNo));
    dispatch(addBlNo(bookFeederInfo.blNo));
  } finally {
    dispatch(bookingFeederService(false));
  }
};
