import { connect } from 'react-redux';
import _ from 'lodash';
import UserList from './user-list.presentation';
import * as Action from '../../redux/action';
import LocalizedString from '../../localization';
import * as Constant from '../../constant';

const mapStateToProps = state => ({
  roles: [
    { label: 'Admin', value: 'ADMIN' },
    { label: 'Customer', value: 'CUSTOMER' },
    { label: 'PPJK', value: 'PPJK' },
    { label: 'CS', value: 'CS' },
  ],
  menuOpen: state.uiMenu.menuOpen,
  openAddDialog: state.uiUserManagement.openAddDialog,
  openUpdateDialog: state.uiUserManagement.openUpdateDialog,
  users: state.users,
  isFirstPage: state.uiUserManagement.isFirstPage,
  isLastPage: state.uiUserManagement.isLastPage,
  totalPage: state.uiUserManagement.totalPage,
  activePage: state.uiUserManagement.currentPage,
  pageSize: state.uiUserManagement.pageSize,
  filters: state.uiUserManagement.filters,
  sortField: state.uiUserManagement.sortField,
  sortDirection: state.uiUserManagement.sortDirection,
});

const mapDispatchToProps = dispatch => ({
  onAppear: (filters, sort, paging) => {
    try {
      dispatch(Action.clearUIError(Constant.RXSTATE_USER_MANAGEMENT));
      dispatch(Action.downloadAllProfilesByFiltersAsync(filters, sort, paging));
    } catch (error) {
      dispatch(
        Action.setUIError(Constant.RXSTATE_USER_MANAGEMENT, error.message),
      );
    }
  },
  onToggleMenuPressed: (status) => {
    dispatch(Action.toggleMenu(status));
  },
  onFilterChange: (field, value) => {
    dispatch(Action.addFilter(Constant.RXSTATE_USER_MANAGEMENT, field, value));
  },
  onPageSizeChange: (value) => {
    dispatch(Action.setPageSize(Constant.RXSTATE_USER_MANAGEMENT, value));
  },
  onPageChange: (value) => {
    dispatch(Action.setActivePage(Constant.RXSTATE_USER_MANAGEMENT, value));
  },
  onFilter: _.debounce((filters, sort, paging) => {
    try {
      dispatch(Action.clearUIError(Constant.RXSTATE_USER_MANAGEMENT));
      dispatch(Action.downloadAllProfilesByFiltersAsync(filters, sort, paging));
    } catch (error) {
      dispatch(
        Action.setUIError(Constant.RXSTATE_USER_MANAGEMENT, error.message),
      );
    }
  }, 800),
  onOpenAddUserDialog: () => {
    dispatch(
      Action.setOpenAddDialogStatus(Constant.RXSTATE_USER_MANAGEMENT, true),
    );
  },
  onCloseAddUserDialog: () => {
    dispatch(
      Action.setOpenAddDialogStatus(Constant.RXSTATE_USER_MANAGEMENT, false),
    );
  },
  onOpenUpdateUserDialog: (userId) => {
    dispatch(
      Action.setUpdateDialogParam(Constant.RXSTATE_USER_MANAGEMENT, { userId }),
    );
    dispatch(
      Action.setOpenUpdateDialogStatus(Constant.RXSTATE_USER_MANAGEMENT, true),
    );
  },
  onCloseUpdateUserDialog: () => {
    dispatch(
      Action.setOpenUpdateDialogStatus(Constant.RXSTATE_USER_MANAGEMENT, false),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserList);
