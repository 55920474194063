import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Typography,
  Select,
  MenuItem,
  TableCell,
  TableSortLabel,
  Input,
  Button,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';
import LocalizedString from '../../localization';
import {
  DEFAULT_PAGE_SIZE_OPTIONS,
  RXTABLEFIELD_PAGE_SIZE,
  TABLE_SORT_ASCENDING,
  TABLE_SORT_DESCENDING,
  RXTABLEFIELD_ACTIVE_PAGE,
  TABLE_FIELD_CONTAINER_NUMBER,
  TABLE_FIELD_TRUCKER,
  TABLE_FIELD_CONSIGNEE,
  DEFAULT_STYLES,
  ROLE_PPJK,
} from '../../constant';
import { isSortFieldActive, getSortIcon } from '../../helper';
import { AssignContainerShape } from '../../type';

const styles = theme => ({
  actionCell: {
    width: '192px',
  },
  btnPrimary: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  dialogActions: {
    margin: '0 -24px',
    marginTop: '24px',
    padding: 24,
    borderTop: '1px solid #C4C4C4',
    justifyContent: 'space-between',
    paddingBottom: 0,
    display: 'flex',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemPerRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

class AssignContainerList extends Component {
  handleChangeSort = (newSortField) => {
    const { sortField, sortDirection, onChangeSort } = this.props;
    if (newSortField === sortField) {
      onChangeSort(
        sortField,
        sortDirection === TABLE_SORT_ASCENDING
          ? TABLE_SORT_DESCENDING
          : TABLE_SORT_ASCENDING,
      );
    } else {
      onChangeSort(newSortField, TABLE_SORT_ASCENDING);
    }
  };

  renderTableCellHeader = (label, fieldId) => {
    const {
      sortField,
      sortDirection,
    } = this.props;
    return (
      <TableCell>
        <TableSortLabel
          active={
            isSortFieldActive(sortField, fieldId)
          }
          IconComponent={getSortIcon(
            isSortFieldActive(sortField, fieldId),
            sortDirection,
          )}
          onClick={() => this.handleChangeSort(fieldId)}
        >
          {label}
        </TableSortLabel>
      </TableCell>
    );
  }

  renderTableCellStringFilter = (field) => {
    const {
      filters,
      onChangeFilter,
    } = this.props;
    return (
      <TableCell>
        <Input
          type="text"
          fullWidth
          value={filters[field]}
          onChange={
            ({ nativeEvent }) => onChangeFilter(
              field,
              nativeEvent.target.value,
            )
          }
          placeholder={LocalizedString.common.placeholderSearch}
        />
      </TableCell>
    );
  }

  renderTableCellBody = text => (
    <TableCell>
      <Typography>
        {text}
      </Typography>
    </TableCell>
  );

  renderTableCellBodyAction = (item) => {
    const { classes, onAssignToPressed, companyRole } = this.props;
    const isPpjk = companyRole.toUpperCase() === ROLE_PPJK;
    const wasAssigned = ((isPpjk && item.truckNo)
      || (!isPpjk && (item.forwarderId || item.truckNo)));
    const caption = wasAssigned
      ? LocalizedString.assign.buttonCaptionView
      : LocalizedString.assign.buttonCaptionAssignTruck;
    return (
      <TableCell className={classes.actionCell}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.btnDetail}
          onClick={() => onAssignToPressed(item)}
        >
          {caption}
        </Button>
      </TableCell>
    );
  };

  renderTablePagination = () => {
    const {
      classes, activePage, totalPage, onChangePaging,
    } = this.props;

    return (
      <div className={classes.tablePagination}>
        <Button
          variant="contained"
          style={{ flexGrow: 1 }}
          disabled={activePage === 1}
          onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage - 1)}
        >
          {LocalizedString.common.labelPagingPrevButton}
        </Button>

        <div className={classes.tablePaginationPageInput}>
          <span style={{ fontWeight: 500 }}>{LocalizedString.common.labelPagingPage}</span>
          <Input
            type="number"
            value={activePage}
            style={{ width: '50%' }}
            inputProps={{
              min: 1,
              max: totalPage,
              step: 1,
              style: { textAlign: 'center' },
            }}
            onChange={({ nativeEvent }) => onChangePaging(
              RXTABLEFIELD_ACTIVE_PAGE,
              parseInt(nativeEvent.target.value, 10),
            )}
          />
          <span style={{ fontWeight: 500 }}>
            {`${LocalizedString.common.labelPagingPageOf} ${totalPage}`}
          </span>
        </div>

        <Button
          variant="contained"
          style={{ flexGrow: 1 }}
          disabled={activePage === totalPage}
          onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage + 1)}
        >
          {LocalizedString.common.labelPagingNextButton}
        </Button>
      </div>
    );
  }

  renderTableItemPerRow = () => {
    const {
      classes,
      pageSize,
      onChangePaging,
    } = this.props;

    return (
      <div className={classes.itemPerRowContainer}>
        <Select
          value={pageSize}
          onChange={event => onChangePaging(RXTABLEFIELD_PAGE_SIZE, event.target.value)
          }
        >
          {DEFAULT_PAGE_SIZE_OPTIONS.map(item => (
            <MenuItem value={item} key={item}>
              {item}
              &nbsp;
              {LocalizedString.common.labelRows}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }

  render() {
    const {
      assignContainer,
    } = this.props;

    return (
      <>
        {this.renderTableItemPerRow()}

        <div style={{ width: '100%', overflow: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                {this.renderTableCellHeader(LocalizedString.assign.labelContainerNo,
                  TABLE_FIELD_CONTAINER_NUMBER)}
                {this.renderTableCellHeader(LocalizedString.assign.labelPpjk,
                  TABLE_FIELD_TRUCKER)}
                {this.renderTableCellHeader(LocalizedString.assign.labelCustomer,
                  TABLE_FIELD_CONSIGNEE)}
                <TableCell>
                  {LocalizedString.common.labelActions}
                </TableCell>
              </TableRow>
              <TableRow>
                {this.renderTableCellStringFilter(TABLE_FIELD_CONTAINER_NUMBER)}
                {this.renderTableCellStringFilter(TABLE_FIELD_TRUCKER)}
                {this.renderTableCellStringFilter(TABLE_FIELD_CONSIGNEE)}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {assignContainer && assignContainer.map(item => (
                <TableRow>
                  {this.renderTableCellBody(item.containerNo)}
                  {this.renderTableCellBody(item.forwarderId)}
                  {this.renderTableCellBody(item.billTo)}
                  {this.renderTableCellBodyAction(item)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        {this.renderTablePagination()}
      </>
    );
  }
}
AssignContainerList.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,

  assignContainer: AssignContainerShape.isRequired,
  companyRole: PropTypes.string.isRequired,

  pageSize: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  filters: PropTypes.shape(PropTypes.any).isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,

  onAssignToPressed: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onChangePaging: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
};
export default withStyles(styles)(AssignContainerList);
