import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { change, formValueSelector, reset } from 'redux-form';
import FormEditDialog from './form-edit-dialog.presentation';
import {
  showErrorSnackbar,
  uploadUpdateFormAsync,
  setFormEditDialogStatus,
  downloadFormDetailAsync,
  downloadAllFormAsync,
} from '../../redux/action';
import {
  RXFORM_FORM_EDIT,
  RXFIELD_FORM_STATUS,
  STATUS_ENABLED,
  status,
} from '../../constant';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const getUserEdit = (state) => {
  const {
    forms, uiForm,
  } = state;
  const { data } = forms;
  const { selectedFormId } = uiForm;

  const formEdit = data[selectedFormId] || {};

  if (formEdit) {
    return {
      order: formEdit.order,
      title: formEdit.title,
      description: formEdit.description,
      status: formEdit.status === STATUS_ENABLED ? status[0] : status[1],
    };
  }

  return formEdit;
};

const mapStateToProps = state => ({
  initialValues: getUserEdit(state),
  loading: false,
  selectedStatus: formValueSelector(RXFORM_FORM_EDIT)(state, RXFIELD_FORM_STATUS),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    try {
      await dispatch(downloadFormDetailAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    }
  },
  onSubmitPressed: async (values) => {
    try {
      const formValues = {
        ...values,
        status: values.status.value ? values.status.value : values.status,
      };
      await dispatch(uploadUpdateFormAsync(formValues));
      await dispatch(downloadAllFormAsync());
      dispatch(setFormEditDialogStatus(false));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onStatusSelected: (option) => {
    dispatch(change(RXFORM_FORM_EDIT, RXFIELD_FORM_STATUS, option));
  },
  onClose: () => {
    dispatch(setFormEditDialogStatus(false));
    dispatch(reset(RXFORM_FORM_EDIT));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormEditDialog));
