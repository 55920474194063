import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class RememberMeHandler extends PureComponent {
  componentWillMount() {
    window.onbeforeunload = this.onExit.bind(this);
  }

  onExit = () => {
    const { rememberMe } = this.props;

    if (!rememberMe) {
      localStorage.clear();
    }
  }

  render() {
    return (<></>);
  }
}
RememberMeHandler.propTypes = {
  rememberMe: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  rememberMe: state.authentication.rememberMe,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RememberMeHandler);
