import { connect } from 'react-redux';
import {
  MOMENT_DATE_FORMAT_STRING, RXTABLEFIELD_ACTIVE_PAGE, TABLE_FIELD_LOAN_HISTORY_AMOUNT,
  TABLE_FIELD_LOAN_HISTORY_CREATED_DATE, TABLE_FIELD_LOAN_HISTORY_STATUS,
  TABLE_FIELD_LOAN_HISTORY_TERM_DAYS, TABLE_SORT_ASCENDING,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { downloadMoneyLoanHistoryCsvAsync } from '../../redux/action';
import {
  setMoneyLoanHistoryFilter, setMoneyLoanHistoryPage, setMoneyLoanHistorySort,
  setMoneyLoanHistoryTappedId, showErrorSnackbar,
} from '../../redux/action/simple-action';
import LoanHistoryDetail from './loan-history-detail.presentation';

const getFilteredLoanHistory = (state) => {
  const exportOrderHistoryDetail = Object.values(state.moneyLoanHistory);
  const { filters, sortField, sortDirection } = state.uiMoneyLoanHistory;

  return exportOrderHistoryDetail.sort((left, right) => {
    if (typeof left[sortField] === 'string') {
      if (sortDirection === TABLE_SORT_ASCENDING) {
        return left[sortField].localeCompare(right[sortField]);
      }
      return right[sortField].localeCompare(left[sortField]);
    }

    if (sortDirection === TABLE_SORT_ASCENDING) {
      return left[sortField] - right[sortField];
    }
    return right[sortField] - left[sortField];
  })
    .filter(item => String(item.amount).indexOf(filters[TABLE_FIELD_LOAN_HISTORY_AMOUNT]) >= 0)
    .filter(item => String(item.termDays).indexOf(filters[TABLE_FIELD_LOAN_HISTORY_TERM_DAYS]) >= 0)
    .filter(item => item.status.toUpperCase()
      .indexOf(filters[TABLE_FIELD_LOAN_HISTORY_STATUS].toUpperCase()) >= 0)
    .filter((item) => {
      if (!filters[TABLE_FIELD_LOAN_HISTORY_CREATED_DATE]) {
        return true;
      }

      const formattedRegisteredOn = toMoment(item.createdDate)
        .format(MOMENT_DATE_FORMAT_STRING);
      const formattedFilter = toMoment(filters[TABLE_FIELD_LOAN_HISTORY_CREATED_DATE])
        .format(MOMENT_DATE_FORMAT_STRING);
      return formattedRegisteredOn === formattedFilter;
    });
};

const getMoneyLoanHistory = (state) => {
  const { activePage, pageSize } = state.uiMoneyLoanHistory;
  const filteredList = getFilteredLoanHistory(state);

  if (filteredList) {
    const refinedActivePage = activePage || 1;
    const start = Math.min((refinedActivePage - 1) * pageSize, filteredList.length);
    const end = Math.min(start + pageSize, filteredList.length);
    return filteredList.slice(start, end);
  }

  return undefined;
};

const getTotalPage = (state) => {
  const history = getFilteredLoanHistory(state);
  const { pageSize } = state.uiMoneyLoanHistory;
  const totalData = history ? history.length : 0;

  return Math.max(Math.ceil(totalData / pageSize), 1);
};

const mapStateToProps = state => ({
  moneyLoanHistory: getMoneyLoanHistory(state),
  pageSize: state.uiMoneyLoanHistory.pageSize,
  totalPage: getTotalPage(state),
  activePage: state.uiMoneyLoanHistory.activePage,
  filters: state.uiMoneyLoanHistory.filters,
  sortField: state.uiMoneyLoanHistory.sortField,
  sortDirection: state.uiMoneyLoanHistory.sortDirection,
  tappedId: state.uiMoneyLoanHistory.tappedId,
});

export const mapDispatchToProps = dispatch => ({
  onOpenPressed: (id) => {
    dispatch(setMoneyLoanHistoryTappedId(id));
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setMoneyLoanHistorySort(sortField, sortDirection));
  },
  onChangePage: (pageField, value) => {
    dispatch(setMoneyLoanHistoryPage(pageField, value));
  },
  onChangeFilter: (filterName, value) => {
    dispatch(setMoneyLoanHistoryFilter(filterName, value));
    dispatch(setMoneyLoanHistoryPage(RXTABLEFIELD_ACTIVE_PAGE, 1));
  },
  onDownloadCsvPressed: () => {
    dispatch(downloadMoneyLoanHistoryCsvAsync())
      .catch((error) => {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoanHistoryDetail);
