import * as Action from '../action';
import * as Constant from '../../constant';

const initialState = {
  downloadingReeferDataDetails: false,
  tappedContainerNo: '',
  searchBarText: '',
  filters: {
    [Constant.TABLE_FIELD_SHIFT_ID]: '',
    [Constant.TABLE_FIELD_SHIFT_DATE]: '',
    [Constant.TABLE_FIELD_SETTING_TEMPERATURE]: '',
    [Constant.TABLE_FIELD_AIR_SUPPLY]: '',
  },
  sortField: '',
  sortDirection: '',
  activePage: 1,
  pageSize: 5,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.SET_TAPPED_REEFER_DATA_CONTAINER_NO:
      return {
        ...state,
        tappedContainerNo: action.tappedContainerNo,
      };
    case Action.SET_REEFER_DATA_CONTAINER_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case Action.DOWNLOADING_REEFER_DATA_DETAILS: {
      return { ...state, downloadingReeferDataDetails: action.status };
    }
    case Action.SET_REEFER_DATA_DETAIL_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.value,
        },
      };
    }
    case Action.SET_REEFER_DATA_DETAIL_PAGING: {
      return { ...state, ...action.value };
    }
    case Action.SET_REEFER_DATA_DETAIL_SORT: {
      return { ...state, sortField: action.sortField, sortDirection: action.sortDirection };
    }
    default: return state;
  }
};
