import { updatingPassword } from '../simple-action';
import * as Constant from '../../../constant';
import * as Helper from '../../../helper';

export default (oldPassword, newPassword, navigateTo) => async (dispatch, getState) => {
  try {
    dispatch(updatingPassword(true));

    const { token } = getState().authentication;

    await Helper.updatePassword(oldPassword, newPassword, token);

    navigateTo(Constant.ROUTE_NAME_PROFILE);
  } finally {
    dispatch(updatingPassword(false));
  }
};
