import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CurrentProfilePicture from './current-profile-picture.presentation';
import * as Action from '../../redux/action';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const mapStateToProps = (state) => {
  const { currentUser, users, images } = state;
  const imageId = users[currentUser.id].profilePicture;
  return {
    profilePicture: images[imageId] ? images[imageId].content : '',
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    try {
      await dispatch(Action.loadCurrentUserProfilePicture());
    } catch (error) {
      dispatch(Action.showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CurrentProfilePicture));
