import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import moment from 'moment';
import ContainerTrackingBlCard from './bl-card.presentation';
import {
  showErrorSnackbar,
  setTappedContainerTrackingBlNo,
  downloadContainerTrackingDetailAsync,
  setContainerTrackingPaging,
  setContainerTrackingFilter,
} from '../../redux/action';
import LocalizedString from '../../localization';
import * as Constant from '../../constant';
import { unauthorizedErrorHandler } from '../../helper';

const getFilteredBl = ({
  containerTracking,
  uiContainerTracking,
}) => _.values(containerTracking)
  .filter(item => item.blNo.toUpperCase()
    .indexOf(uiContainerTracking.searchBarText.toUpperCase()) >= 0)
  .sort((left, right) => moment(right.gateInCdp) - moment(left.gateInCdp));

const mapStateToProps = state => ({
  billingLines: getFilteredBl(state),
  selectedBlNo: state.uiContainerTracking.tappedBlNo,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onMorePress: async (tappedBlNo) => {
    try {
      dispatch(setTappedContainerTrackingBlNo(tappedBlNo));
      await dispatch(downloadContainerTrackingDetailAsync(tappedBlNo));
      // reset filters
      dispatch(setContainerTrackingPaging(Constant.RXTABLEFIELD_ACTIVE_PAGE, 1));
      dispatch(
        setContainerTrackingFilter(Constant.TABLE_FIELD_CONTAINER_NUMBER, ''),
      );
      dispatch(setContainerTrackingFilter(Constant.TABLE_FIELD_SERVICE, ''));
      dispatch(setContainerTrackingFilter(Constant.TABLE_FIELD_SIZE, ''));
      dispatch(setContainerTrackingFilter(Constant.TABLE_FIELD_GATE_IN_CDP, ''));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContainerTrackingBlCard));
