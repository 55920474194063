import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
} from '@material-ui/core';
import LocalizedString from '../localization';

const styles = {
  center: {
    display: 'flex',
    alignItems: 'center',
  },
};

const BankOptionDialog = ({
  classes,
  open,
  selectedBank,
  onBankOptionSelected,
  onOkPressed,
  onCancelPressed,
}) => (
  <Dialog
    open={open}
  >
    <DialogTitle>Payment Channel</DialogTitle>
    <DialogContent>
      <div className={classes.center}>
        <Checkbox
          checked={selectedBank === 41}
          color="primary"
          onClick={() => onBankOptionSelected(41)}
        />
        <img src="/assets/images/mandiri.png" alt="Mandiri" width="192px" />
      </div>
      <div className={classes.center}>
        <Checkbox
          checked={selectedBank === 33}
          color="primary"
          onClick={() => onBankOptionSelected(33)}
        />
        <img src="/assets/images/danamon.png" alt="Danamon" width="192px" />
      </div>
      <div className={classes.center}>
        <Checkbox
          checked={selectedBank === 36}
          color="primary"
          onClick={() => onBankOptionSelected(36)}
        />
        <img src="/assets/images/permata.png" alt="Permata Bank" width="192px" />
      </div>
      <div className={classes.center}>
        <Checkbox
          checked={selectedBank === 32}
          color="primary"
          onClick={() => onBankOptionSelected(32)}
        />
        <img src="/assets/images/niaga.png" alt="CIMB Niaga" width="192px" />
      </div>
    </DialogContent>
    <DialogActions>
      <Button
        color="primary"
        onClick={onCancelPressed}
      >
        {LocalizedString.common.buttonCaptionCancel}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={onOkPressed}
      >
        {LocalizedString.common.buttonCaptionNext}
      </Button>
    </DialogActions>
  </Dialog>
);
BankOptionDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any),
  open: PropTypes.bool.isRequired,
  selectedBank: PropTypes.number.isRequired,
  onBankOptionSelected: PropTypes.func.isRequired,
  onOkPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
};
BankOptionDialog.defaultProps = {
  classes: {},
};

export default withStyles(styles)(BankOptionDialog);
