import { connect } from 'react-redux';
import PinVerification from './pin-verification.presentation';
import {
  clearUIError, setUIError, removeForgetPasswordInfo, addForgetPasswordInfo,
} from '../../redux/action';
import * as Validation from '../../validation';
import * as Constant from '../../constant';

const mapStateToProps = state => ({
  errorMsg: state.uiForgetPassword.error,
  pin: state.forgetPasswordInfo.pin,
  pinExpirationDate: state.forgetPasswordInfo.pinExpirationDate,
});

const cancelPinVerification = (dispatch, props) => {
  dispatch(removeForgetPasswordInfo());
  props.history.push(Constant.ROUTE_NAME_LOGIN);
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onNext: async (pin) => {
    try {
      dispatch(clearUIError(Constant.RXSTATE_FORGET_PASSWORD_PAGE));

      Validation.validatePin(pin);
      dispatch(addForgetPasswordInfo({ pin }));
      ownProps.history.push(Constant.ROUTE_NAME_RESET_PASSWORD);
    } catch (error) {
      dispatch(setUIError(Constant.RXSTATE_FORGET_PASSWORD_PAGE, error.message));
    }
  },
  onCancel: () => {
    cancelPinVerification(dispatch, ownProps);
  },
  onPinExpired: () => {
    cancelPinVerification(dispatch, ownProps);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PinVerification);
