import * as Action from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case Action.SET_NEW_DOCUMENT_MY_FORMS: {
      return { ...action.value };
    }
    case Action.CLEAR_NEW_DOCUMENT_MY_FORMS: {
      return {};
    }
    default:
      return state;
  }
};
