import {
  DOWNLOADING_ADMIN_PAYABLE_BL,
  UPLOADING_ADMIN_PAYMENT,
  DOWNLOADING_ADMIN_PAYABLE_BL_PAYMENT_OWNER,
  SET_DIALOG_ADMIN_PAYMENT_PIN_INPUT_STATUS,
  SET_ADMIN_PAYMENT_PIN_TEXT,
} from '../action';

const initialState = {
  downloading: false,
  downloadingPaymentOwner: false,
  uploading: false,
  dialogPinInputStatus: false,
  pin: '',

  selectedBl: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_ADMIN_PAYABLE_BL:
      return { ...state, downloading: action.status };
    case DOWNLOADING_ADMIN_PAYABLE_BL_PAYMENT_OWNER:
      return { ...state, downloadingPaymentOwner: action.status };
    case UPLOADING_ADMIN_PAYMENT:
      return { ...state, uploading: action.status };
    case SET_DIALOG_ADMIN_PAYMENT_PIN_INPUT_STATUS:
      return { ...state, dialogPinInputStatus: action.status };
    case SET_ADMIN_PAYMENT_PIN_TEXT:
      return { ...state, pin: action.value };
    default: return state;
  }
};
