import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import HistoryCard from './history-card.presentation';
import {
  downloadTrackingStatusBehandleHistoriesAsync,
  setTrackingStatusBehandleHistorySearchText,
  setTrackingStatusBehandleHistoryTappedId,
  showErrorSnackbar,
} from '../../redux/action';
import LocalizedString from '../../localization';
import { toMoment, unauthorizedErrorHandler } from '../../helper';

const getFilteredData = state => _.values(state.trackingStatusBehandleHistories)
  .filter((item) => {
    const searchText = state.uiTrackingStatusBehandleHistory.searchBarText.toLowerCase();
    return item.containerNo.toLowerCase().indexOf(searchText) >= 0
        || (item.customer && item.customer.toLowerCase().indexOf(searchText) >= 0)
        || (item.pibNum && item.pibNum.toLowerCase().indexOf(searchText) >= 0)
        || (item.blNum && item.blNum.toLowerCase().indexOf(searchText) >= 0);
  })
  .sort((left, right) => toMoment(right.timeGateIn) - toMoment(left.timeGateIn));

const mapStateToProps = state => ({
  downloading: state.uiTrackingStatusBehandleHistory.downloading,
  data: getFilteredData(state),
  searchBarText: state.uiTrackingStatusBehandleHistory.searchBarText,
  selectedItemNo: state.uiTrackingStatusBehandleHistory.tappedId,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    try {
      await dispatch(downloadTrackingStatusBehandleHistoriesAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onChangeSearchBarText: (text) => {
    dispatch(setTrackingStatusBehandleHistorySearchText(text));
  },
  onMorePressed: (tappedItemNo) => {
    dispatch(setTrackingStatusBehandleHistoryTappedId(tappedItemNo));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HistoryCard));
