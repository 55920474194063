import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  withStyles,
  CssBaseline,
  Card,
  Icon,
  CardContent,
  Typography,
  Grid,
  Fab,
  ButtonBase,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  LoadingIndicator,
  PageHeaderAdmin,
} from '../../component';
import LocalizedString from '../../localization';
import * as Constant from '../../constant';
import NewDocumentDetail from './new-document-detail.container';

const styles = theme => ({
  backIcon: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${Constant.DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    color: '#0266B4',
  },
  cardListContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: theme.spacing.unit * 2,
    maxBlockSize: '500px',
  },
  cardItem: {
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '6px',
    boxSizing: 'border-box',
    padding: '16px',
    marginBottom: theme.spacing.unit,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  buttonPrimary: {
    backgroundColor: '#50abf1',
  },
  description: {
    color: '#8C8C8C',
  },
  selected: {
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  notFound: {
    color: '#8C8C8C',
    textAlign: 'center',
  },
});

const renderCardList = (
  classes, myForms, tappedId, uploadingResponse, onCardItemPressed,
) => {
  if (!myForms || myForms.length === 0) {
    return (
      <Typography className={classes.notFound}>
        {LocalizedString.submitRequest.labelNoDocuments}
      </Typography>
    );
  }

  return myForms.map(item => (
    <ButtonBase
      component="div"
      key={item.id}
      className={classNames(classes.cardItem, {
        [classes.selected]: item.id === tappedId,
      })}
      onClick={() => onCardItemPressed(item.id, tappedId)}
      disabled={uploadingResponse}
    >
      <Grid container spacing={24}>
        <Grid item xs={12} md={12}>
          <Typography className={classes.title} variant="h6">
            {item.title}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="subtitle1" className={classes.description}>
            {LocalizedString.submitRequest.labelDescription}
          </Typography>
          <Typography variant="subtitle2" className={classes.description}>
            {item.description || '-'}
          </Typography>
        </Grid>
      </Grid>
    </ButtonBase>
  ));
};


class NewDocumentPage extends Component {
  componentDidMount() {
    this.props.onAppear();
  }

  render() {
    const {
      classes,
      myForms,
      tappedId,
      downloadingMyForms,
      uploadingResponse,
      notificationOpen,
      onCardItemPressed,
    } = this.props;

    return (
      <>
        <CssBaseline />
        <PageHeaderAdmin />

        <div
          className={
            classNames(classes.content, {
              [classes.contentDrawerOpen]: notificationOpen,
              [classes.contentDrawerClosed]: !notificationOpen,
            })
          }
          style={{ padding: '16px' }}
        >
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <div className={classes.pageNavigatorContainer}>
                    <Fab
                      variant="round"
                      classes={{ root: classes.backIcon }}
                      component={Link}
                      to={Constant.ROUTE_NAME_DASHBOARD_USER}
                    >
                      <Icon>chevron_left</Icon>
                    </Fab>
                    <div style={{ marginLeft: '32px' }}>
                      <Typography variant="h5" className={classes.title}>{LocalizedString.submitRequest.labelNewDocument}</Typography>
                      <Typography variant="caption">{LocalizedString.submitRequest.newDocumentCaption}</Typography>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <div className={classes.cardListContainer}>
                    {renderCardList(
                      classes, myForms, tappedId,
                      uploadingResponse, onCardItemPressed,
                    )}
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              <NewDocumentDetail />
            </Grid>
          </Grid>
        </div>
        {downloadingMyForms && <LoadingIndicator />}
      </>
    );
  }
}
NewDocumentPage.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  myForms: PropTypes.arrayOf(PropTypes.any).isRequired,
  tappedId: PropTypes.string.isRequired,
  downloadingMyForms: PropTypes.bool.isRequired,
  uploadingResponse: PropTypes.bool.isRequired,
  notificationOpen: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCardItemPressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(NewDocumentPage);
