import * as Helper from '../../../helper';
import { validateAssignTruck } from '../../../validation';
import { uploadingAssignTruck, showErrorSnackbar } from '../simple-action';
import downloadAssignContainerAsync from './downloadAssignContainerAsync';
import LocalizedString from '../../../localization';
import { VENDOR_ID_GTC } from '../../../constant';
import bookFeederFromOtherAsync from './bookFeederFromOtherAsync';

export default (assignContainer, router) => async (dispatch, getState) => {
  try {
    dispatch(uploadingAssignTruck(true));
    validateAssignTruck(assignContainer);

    const { token } = getState().authentication;

    const {
      containerNo, blNo, vendorId, truckNo,
    } = assignContainer;

    // const { soNo, status, gateIn, billTo } = getState().uiAssignTruck.assignTruckForm;
    const { tappedBlNo, tappedSoNo } = getState().uiAssignOrderType;
    const {
      id, containerSize, containerType, sealNo,
    } = getState().uiAssignTruck.assignTruckForm;

    const {
      status, billTo, orderType,
    } = getState().assignOrderType[tappedSoNo];

    if (vendorId === VENDOR_ID_GTC) {
      dispatch(bookFeederFromOtherAsync(blNo, router))
        .catch(async (error) => {
          dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
        });
      return;
    }

    const data = {
      id,
      soNo: tappedSoNo,
      containerNo,
      blNo,
      forwarderId: vendorId,
      billTo,
      truckNo: truckNo || undefined,
      docStatus: status,
      gateIn: undefined,
      orderType,
      containerSize,
      containerType,
      sealNo,
    };

    await Helper.uploadAssignTruckExport(data, token);

    dispatch(downloadAssignContainerAsync(tappedBlNo, tappedSoNo))
      .catch((error) => {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      });
  } finally {
    dispatch(uploadingAssignTruck(false));
  }
};
