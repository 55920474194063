import PropTypes from 'prop-types';

export const MainMenuShape = PropTypes.arrayOf(PropTypes.shape({
  link: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}));

export const NavigationShape = PropTypes.shape({
  navigation: PropTypes.func,
});

export const ProfileShape = PropTypes.shape({
  id: PropTypes.string,
  username: PropTypes.string,
  fullName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  jobTitle: PropTypes.string,
  profilePicture: PropTypes.string,
  role: PropTypes.string,
  company: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
  }),
});

export const TariffSimulationDetailShape = PropTypes.shape({
  description: PropTypes.string,
  days: PropTypes.number,
  price: PropTypes.number,
  total: PropTypes.number,
});

export const TariffSimulationShape = PropTypes.shape({
  adminFee: PropTypes.number,
  billTo: PropTypes.string,
  blNo: PropTypes.string,
  discount: PropTypes.number,
  gateInCdp: PropTypes.string,
  items: PropTypes.arrayOf(TariffSimulationDetailShape),
  party: PropTypes.number,
  partyDesc: PropTypes.string,
  partySize: PropTypes.number,
  partyType: PropTypes.string,
  sp2Expectation: PropTypes.string,
  taxBasis: PropTypes.number,
  totalGross: PropTypes.number,
  totalPayment: PropTypes.number,
  vat: PropTypes.number,
});

export const DropdownOption = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
});

export const MyTermOrderShape = PropTypes.shape({
  id: PropTypes.string,
  createdByUser: PropTypes.string,
  createdByCompany: PropTypes.string,
  createdDate: PropTypes.date,
  termPaymentDueDate: PropTypes.date,
  invoiceNo: PropTypes.string,
  blNumber: PropTypes.string,
  service: PropTypes.string,
  amount: PropTypes.number,
  paymentOnBehalfOf: PropTypes.date,
});

export const AssignShape = PropTypes.shape({
  soNo: PropTypes.string,
  blNo: PropTypes.string,
  billTo: PropTypes.string,
  forwarderId: PropTypes.string,
  exim: PropTypes.string,
});

export const AssignOrderTypeShape = PropTypes.shape({
  soNo: PropTypes.string,
  blNo: PropTypes.string,
  billTo: PropTypes.string,
  forwarderId: PropTypes.string,
  orderType: PropTypes.string,
  status: PropTypes.string,
});

export const AssignContainerShape = PropTypes.shape({
  soNo: PropTypes.string,
  blNo: PropTypes.string,
  billTo: PropTypes.string,
  forwarderId: PropTypes.string,
  orderType: PropTypes.string,
  containerNo: PropTypes.string,
  truckNo: PropTypes.string,
});

const LoanPaymentShape = PropTypes.shape({
  id: PropTypes.string,
  walletid: PropTypes.string,
  companyId: PropTypes.string,
  amount: PropTypes.number,
  createdDate: PropTypes.string,
  username: PropTypes.string,
});

export const MoneyLoanInfoShape = PropTypes.shape({
  status: PropTypes.string,
  limit: PropTypes.number,
  loan: PropTypes.number,
  outstanding: PropTypes.number,
  termDays: PropTypes.number,
  loanDate: PropTypes.string,
  validFrom: PropTypes.string,
  validTo: PropTypes.string,
  companyId: PropTypes.string,
  companyName: PropTypes.string,
  loanPayments: PropTypes.arrayOf(LoanPaymentShape),
});

export const HostBLShape = PropTypes.shape({
  impName: PropTypes.string,
  sppbDate: PropTypes.string,
  sppbNo: PropTypes.string,
});

const FormShape = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
});

const UserShape = PropTypes.shape({
  id: PropTypes.string,
  fullName: PropTypes.string,
});

const FieldShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  order: PropTypes.number,
  type: PropTypes.string,
});

const ResponseShape = PropTypes.shape({
  field: FieldShape,
  value: PropTypes.string,
});

export const FormResponseDetailShape = PropTypes.shape({
  id: PropTypes.string,
  form: FormShape,
  user: UserShape,
  submitDate: PropTypes.string,
  status: PropTypes.string,
  statusNotes: PropTypes.string,
  response: PropTypes.arrayOf(ResponseShape),
});
