import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { TextField, Button } from '@material-ui/core';
import LocalizedString from '../../localization';

export default class EmailSubmission extends Component {
    state = {
      pin: this.props.pin,
      minute: '00',
      second: '00',
    }


    componentDidMount() {
      this.interval = setInterval(this.onInterval, 1000);
    }

    componentWillUnmount() {
      clearInterval(this.interval);
    }

    onInterval = () => {
      if (this.props.pinExpirationDate) {
        const now = moment(new Date());
        const endDate = moment(this.props.pinExpirationDate);

        if (endDate.diff(now, 'seconds') <= 0) {
          clearInterval(this.interval);
          this.props.onPinExpired();
          return;
        }

        let minute = endDate.diff(now, 'minutes');
        minute = (`${minute}`).length === 1 ? `0${minute}` : minute;
        let second = endDate.diff(now, 'seconds') % 60;
        second = (`${second}`).length === 1 ? `0${second}` : second;

        this.setState({ minute, second });
      }
    }

    onSubmitButtonClicked = () => {
      this.props.onNext(this.state.pin);
    }

    renderPinRemainingTime = () => (
      <div>
        {LocalizedString.pinVerificationScreen.expirationLabel}
        {' '}
        {`${this.state.minute}:${this.state.second}`}
      </div>
    );


    render() {
      return (
        <div>
          <h2>{LocalizedString.pinVerificationScreen.title}</h2>

          <div>
            {LocalizedString.pinVerificationScreen.subTitle}
          </div>

          <div>

            <TextField
              label={LocalizedString.pinVerificationScreen.labelPin}
              value={this.state.pin}
              onChange={e => this.setState({ pin: e.target.value })}
              margin="normal"
            />
            {this.renderPinRemainingTime()}
          </div>

          <Button variant="contained" color="primary" onClick={this.onSubmitButtonClicked}>
            {LocalizedString.common.buttonCaptionNext}
          </Button>
          <Button onClick={this.props.onCancel}>
            {LocalizedString.common.buttonCaptionCancel}
          </Button>
        </div>
      );
    }
}

EmailSubmission.propTypes = {
  pin: PropTypes.string,
  pinExpirationDate: PropTypes.string,
  onNext: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onPinExpired: PropTypes.func.isRequired,
};

EmailSubmission.defaultProps = {
  pin: '',
  pinExpirationDate: '',
};
