import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';

const StyledFormControl = props => (
  <FormControl
    fullWidth
    {...props}
  >
    {props.children}
  </FormControl>
);
StyledFormControl.propTypes = {
  children: PropTypes.shape(PropTypes.any),
};
StyledFormControl.defaultProps = {
  children: {},
};

export default StyledFormControl;
