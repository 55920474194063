import { downloadingMyPaymentOrder, addMyPaymentOrder } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;

  try {
    dispatch(downloadingMyPaymentOrder(true));
    const myOrder = await Helper.downloadMyPaymentOrder(token);
    dispatch(addMyPaymentOrder(myOrder));
  } finally {
    dispatch(downloadingMyPaymentOrder(false));
  }
};
