import _ from 'lodash';
import * as Action from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case Action.ADD_IMAGE: {
      return { ...state, [action.image.id]: action.image };
    }
    case Action.REMOVE_IMAGE:
      return _.omit(state, action.id);
    default: return state;
  }
};
