import { downloadingFormResponseDetailFile } from '../simple-action';
import { downloadNewDocumentMyResponseFile } from '../../../helper';

export default file => async (dispatch, getState) => {
  const { token } = getState().authentication;
  try {
    dispatch(downloadingFormResponseDetailFile(true));
    const downloadedFile = await downloadNewDocumentMyResponseFile(token, file);
    return downloadedFile.content;
  } finally {
    dispatch(downloadingFormResponseDetailFile(false));
  }
};
