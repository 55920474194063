import { downloadingPpjkCompanyList, addPpjkCompanyList } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { id } = getState().currentUser.company;

  try {
    dispatch(downloadingPpjkCompanyList(true));
    const ppjkCompanyList = await Helper.downloadPpjkCompanyList(id, token);
    dispatch(addPpjkCompanyList(ppjkCompanyList));
  } finally {
    dispatch(downloadingPpjkCompanyList(false));
  }
};
