import { connect } from 'react-redux';
import * as Action from '../../redux/action';
import LoginScreen from './login.presentation';
import * as Constant from '../../constant';
import LocalizedString from '../../localization';

const getErrorMessage = (state, ownProps) => {
  const queryString = ownProps.location.search;
  const alternateError = queryString && queryString.indexOf('session-expired')
    ? LocalizedString.common.sessionExpired
    : null;

  return state.uiLogin.error || alternateError;
};

const mapStateToProps = (state, ownProps) => ({
  loggingIn: state.uiLogin.loggingIn,
  errorMsg: getErrorMessage(state, ownProps),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoginPressed: async ({ username, password, rememberMe }) => {
    try {
      dispatch(Action.hideSnackbar());
      dispatch(Action.clearUIError(Constant.RXSTATE_LOGIN_PAGE));
      await dispatch(Action.loginAsync(username, password, ownProps.history.push));
      dispatch(Action.setRememberMe(rememberMe));
    } catch (error) {
      dispatch(Action.setUIError(Constant.RXSTATE_LOGIN_PAGE, error.message));
    }
  },
  onForgetPasswordPressed: () => {
  },
  onRegisterPressed: () => {
    window.open(Constant.URL_REGISTRATION);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
