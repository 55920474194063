import * as Helper from '../../../helper';
import * as Action from '../simple-action';

export default () => async (dispatch, getState) => {
  const { authentication, uiField } = getState();
  const { token } = authentication;
  const {
    activePage, pageSize, filters,
  } = uiField;
  const { formName, form, name } = filters;

  try {
    dispatch(Action.downloadingField(true));
    const formId = formName || '';
    const pageSizeToBeDownloaded = (form || name) ? 200 : pageSize;
    const lists = await Helper.downloadAllField(
      token, formId, activePage, pageSizeToBeDownloaded,
    );
    dispatch(Action.setFields(lists));
  } finally {
    dispatch(Action.downloadingField(false));
  }
};
