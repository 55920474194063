import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  Typography,
  Icon,
  Button,
} from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import { LoadingIndicator } from '../../component';
import LocalizedString from '../../localization';
import {
  DEFAULT_STYLES,
  RXFORM_MASTER_USER_ADD,
  RXFIELD_MASTER_USER_USERNAME,
  RXFIELD_MASTER_USER_EMAIL,
  RXFIELD_MASTER_USER_FULLNAME,
  RXFIELD_MASTER_USER_IS_CORPORATE_ADMIN,
  RXFIELD_MASTER_USER_COMPANY_ID,
  RXFIELD_MASTER_USER_ROLE,
  RXFIELD_MASTER_USER_IS_ADMIN,
  ROLE_ADMIN,
  ROLE_CUSTOMER,
} from '../../constant';
import {
  renderReduxFormTextField,
  renderCheckbox,
  renderReduxFormFilterableSelectAsyncField,
  renderReduxFormFilterableSelectField,
} from '../../helper';

const styles = () => ({
  btnPrimary: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  dialogActions: {
    margin: '0 -24px',
    marginTop: '24px',
    padding: 24,
    borderTop: '1px solid #C4C4C4',
    justifyContent: 'space-between',
    paddingBottom: 0,
    display: 'flex',
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
});

const UserAddDialog = ({
  classes,
  open,
  loading,
  vendors,
  selectedCompany,
  currentUserRole,
  chbIsAdmin,
  lstUserRole,

  initialValues,
  initialize,
  handleSubmit,
  onSubmitPressed,
  onAppear,
  onClose,
  onCompanySelected,
  onUserRoleChaged,
}) => {
  const handleOnDialogEnter = () => {
    initialize(initialValues);
    onAppear(currentUserRole);
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onEnter={() => handleOnDialogEnter()}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
            }}
          >
            <Typography color="primary" variant="h6">
              {LocalizedString.masterUser.btnAddNew}
            </Typography>
            <IconButton onClick={onClose}>
              <Icon>close</Icon>
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit(onSubmitPressed)}>
            <Field
              name={RXFIELD_MASTER_USER_USERNAME}
              component={renderReduxFormTextField}
              label={LocalizedString.masterUser.labelUsername}
              required
            />
            <Field
              name={RXFIELD_MASTER_USER_EMAIL}
              component={renderReduxFormTextField}
              label={LocalizedString.masterUser.labelEmail}
              required
            />
            <Field
              name={RXFIELD_MASTER_USER_FULLNAME}
              component={renderReduxFormTextField}
              label={LocalizedString.masterUser.labelFullName}
              required
            />
            {currentUserRole === ROLE_ADMIN && (
              // <Field
              //   name={RXFIELD_MASTER_USER_IS_ADMIN}
              //   component={renderCheckbox}
              //   label={LocalizedString.masterUser.labelIsAdmin}
              // />
              <Field
                name={RXFIELD_MASTER_USER_IS_ADMIN}
                component={renderReduxFormFilterableSelectAsyncField}
                options={lstUserRole}
                label={LocalizedString.masterUser.labelIsAdmin}
                onValueChange={value => onUserRoleChaged(value)}
              />
            )}
            {chbIsAdmin === ROLE_CUSTOMER && (
              <>
                <Field
                  name={RXFIELD_MASTER_USER_COMPANY_ID}
                  component={renderReduxFormFilterableSelectAsyncField}
                  options={vendors}
                  label={LocalizedString.masterUser.labelCompanyName}
                  onValueChange={onCompanySelected}
                />
                <Field
                  name={RXFIELD_MASTER_USER_IS_CORPORATE_ADMIN}
                  component={renderCheckbox}
                  label={LocalizedString.masterUser.labelIsCorporateAdmin}
                />
              </>
            )}

            <div className={classes.dialogActions}>
              <div style={{ flexGrow: 1 }} />
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.btnPrimary}
                  disabled={loading}
                >
                  Create
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      {loading && <LoadingIndicator />}
    </>
  );
};
UserAddDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  vendors: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedCompany: PropTypes.string.isRequired,
  currentUserRole: PropTypes.string.isRequired,
  chbIsAdmin: PropTypes.bool.isRequired,
  lstUserRole: PropTypes.arrayOf(PropTypes.any).isRequired,

  initialValues: PropTypes.shape(PropTypes.any).isRequired,
  initialize: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  onAppear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onCompanySelected: PropTypes.func.isRequired,
  onUserRoleChaged: PropTypes.func.isRequired,
};

export default reduxForm({
  form: RXFORM_MASTER_USER_ADD,
  enableReinitialize: true,
})(withStyles(styles)(UserAddDialog));
