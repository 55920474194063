import React, { Component } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  Field, reduxForm,
} from 'redux-form';
import PropTypes from 'prop-types';
import { Button, withStyles, Grid } from '@material-ui/core';
import LocalizedString from '../../localization';
import * as Constant from '../../constant';
import * as Helper from '../../helper';
import * as Validation from '../../validation';
import { LoadingIndicator } from '../../component';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  buttonPrimary: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

class ProfileInfo extends Component {
    onCancelButtonPressed = () => {
      this.props.onCancelButtonPressed();
    }

    onEditButtonPressed = () => {
      this.props.onEditButtonPressed();
    }

    renderButtons = () => {
      const {
        classes,
        editable,
        isCorporateAdmin,
        companyPaymentMethod,
        uploadingProfileDetail,
      } = this.props;

      if (editable) {
        return (
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classNames(classes.button, classes.buttonPrimary)}
              disabled={uploadingProfileDetail}
              onClick={this.onSaveButtonPressed}
            >
              {LocalizedString.common.buttonCaptionSave}
            </Button>
            <Button
              type="button"
              className={classes.button}
              disabled={uploadingProfileDetail}
              onClick={this.onCancelButtonPressed}
            >
              {LocalizedString.common.buttonCaptionCancel}
            </Button>
          </div>
        );
      }

      if (isCorporateAdmin && companyPaymentMethod.toUpperCase() === Constant.PAYMENT_TYPE_DEPOSIT) {
        return (
          <div style={{ display: 'flex' }}>
            <Button
              variant="text"
              color="primary"
              type="button"
              disabled={uploadingProfileDetail}
              component={Link}
              className={classes.button}
              to={Constant.ROUTE_NAME_PROFILE_CHANGE_PASSWORD}
            >
              {LocalizedString.profileScreen.buttonCaptionChangePassword}
            </Button>
            <Button
              variant="text"
              color="primary"
              type="button"
              disabled={uploadingProfileDetail}
              component={Link}
              className={classes.button}
              to={Constant.ROUTE_NAME_PROFILE_CHANGE_PIN}
            >
              {LocalizedString.profileScreen.buttonCaptionChangePin}
            </Button>
            <div style={{ flexGrow: 1 }} />
            <Button
              variant="contained"
              color="primary"
              type="button"
              className={classNames(classes.button, classes.buttonPrimary)}
              disabled={uploadingProfileDetail}
              onClick={this.onEditButtonPressed}
            >
              {LocalizedString.common.buttonCaptionEdit}
            </Button>
          </div>
        );
      }

      return (
        <div className={classes.buttonContainer}>
          <Button
            variant="text"
            color="primary"
            type="button"
            disabled={uploadingProfileDetail}
            component={Link}
            className={classes.button}
            to={Constant.ROUTE_NAME_PROFILE_CHANGE_PASSWORD}
          >
            {LocalizedString.profileScreen.buttonCaptionChangePassword}
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="button"
            className={classNames(classes.button, classes.buttonPrimary)}
            disabled={uploadingProfileDetail}
            onClick={this.onEditButtonPressed}
          >
            {LocalizedString.common.buttonCaptionEdit}
          </Button>
        </div>
      );
    }

    render() {
      const {
        classes, uploadingProfileDetail, handleSubmit, editable, onSaveButtonPressed,
      } = this.props;
      return (
        <div>
          <form onSubmit={handleSubmit(onSaveButtonPressed)}>
            <Grid container spacing={24}>
              <Grid item xs={12} md={6}>
                <Field
                  name={Constant.RXFIELD_USERNAME}
                  component={Helper.renderReduxFormTextField}
                  type="text"
                  readonly
                  label={LocalizedString.profileScreen.labelUsername}
                />

                <Field
                  name={Constant.RXFIELD_FULLNAME}
                  component={Helper.renderReduxFormTextField}
                  readonly={!editable || uploadingProfileDetail}
                  type="text"
                  label={LocalizedString.profileScreen.labelFullName}
                  required
                />

                <Field
                  name={Constant.RXFIELD_EMAIL}
                  component={Helper.renderReduxFormTextField}
                  readonly
                  type="text"
                  label={LocalizedString.profileScreen.labelEmail}
                />

                <Field
                  name={Constant.RXFIELD_USER_ROLE}
                  className={classes.textField}
                  component={Helper.renderReduxFormTextField}
                  readonly
                  type="text"
                  label={LocalizedString.profileScreen.labelUserRole}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  name={Constant.RXFIELD_COMPANY_NAME}
                  component={Helper.renderReduxFormTextField}
                  readonly
                  type="text"
                  label={LocalizedString.profileScreen.labelCompany}
                />
                <Field
                  name={Constant.RXFIELD_COMPANY_ROLE}
                  className={classes.textField}
                  component={Helper.renderReduxFormTextField}
                  readonly
                  type="text"
                  label={LocalizedString.profileScreen.labelCompanyRole}
                />
                <Field
                  name={Constant.RXFIELD_ADDRESS}
                  component={Helper.renderReduxFormTextField}
                  readonly
                  type="text"
                  label={LocalizedString.profileScreen.labelAddress}
                />

                {this.renderButtons()}
              </Grid>
            </Grid>
          </form>
          {uploadingProfileDetail && <LoadingIndicator />}
        </div>
      );
    }
}

const ProfileInfoWithStyls = withStyles(styles)(ProfileInfo);
export default reduxForm({
  form: Constant.RXFORM_PROFILE_SCREEN_INFO,
  validate: Validation.rxformValidateProfileInfo,
})(ProfileInfoWithStyls);

ProfileInfo.propTypes = {
  editable: PropTypes.bool.isRequired,
  uploadingProfileDetail: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  isCorporateAdmin: PropTypes.bool.isRequired,
  companyPaymentMethod: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancelButtonPressed: PropTypes.func.isRequired,
  onSaveButtonPressed: PropTypes.func.isRequired,
  onEditButtonPressed: PropTypes.func.isRequired,
};
