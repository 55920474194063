import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  Icon,
  DialogContent,
} from '@material-ui/core';
import LocalizedString from '../../localization';
import AssignContainerDialogTabs from './container-dialog-tabs';
import AssignContainerList from './assign-container-list.container';
import AssignContainerTruck from './assign-container-truck.container';
import { LoadingIndicator } from '../../component';
import { CART_SERVICE_NAME_EXPORT } from '../../constant';

const styles = theme => ({
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tabIndicator: {
    display: 'none',
  },
  tabRoot: {
    marginTop: theme.spacing.unit,
    overflow: 'visible',
  },
  tabElement: {
    backgroundColor: 'rgba(200, 200, 200, 0.2)',
    textTransform: 'capitalize',
  },
  tabElementActive: {
    backgroundColor: '#3f51b5',
    color: '#ffffff !important',
    transform: 'scaleY(1.1)',
    borderRadius: '4px',
    boxShadow: '0px 4px 20px rgba(0, 102, 203, 0.45)',
  },
  tabScroller: {
    overflowX: 'visible',
  },
});

const AssignContainerDialog = ({
  classes,
  open,
  downloading,
  selectedTab,

  selectedAssignBl,

  onClose,
}) => {
  if (!selectedAssignBl) {
    return null;
  }
  const titleLabel = selectedAssignBl
    .exim.toUpperCase() !== CART_SERVICE_NAME_EXPORT.toUpperCase()
    ? LocalizedString.assign.labelBlNumber
    : LocalizedString.assign.labelDoNo;
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <div className={classes.dialogHeader}>
            <Typography color="primary" variant="h6">
              {titleLabel}
              &nbsp;
              {selectedAssignBl.blNo}
            </Typography>
            <IconButton
              onClick={onClose}
            >
              <Icon>close</Icon>
            </IconButton>
          </div>

          <AssignContainerDialogTabs
            selectedTab={selectedTab}
          />
        </DialogTitle>
        <DialogContent>
          {selectedTab === 0 && <AssignContainerList />}
          {selectedTab === 1 && <AssignContainerTruck />}
        </DialogContent>
      </Dialog>

      {downloading && <LoadingIndicator />}
    </>
  );
};
AssignContainerDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  open: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  selectedTab: PropTypes.number.isRequired,

  selectedAssignBl: PropTypes.shape(PropTypes.any).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(AssignContainerDialog);
