import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { change } from 'redux-form';
import FormAddDialog from './form-add-dialog.presentation';
import {
  showErrorSnackbar,
  setFormAddDialogStatus,
  uploadNewFormAsync,
  downloadAllFormAsync,
} from '../../redux/action';
import {
  RXFORM_FORM_ADD,
  RXFIELD_FORM_STATUS,
} from '../../constant';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const mapStateToProps = state => ({
  loading: state.uiForm.uploading,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmitPressed: async (values) => {
    try {
      await dispatch(uploadNewFormAsync(values));
      await dispatch(downloadAllFormAsync());
      dispatch(setFormAddDialogStatus(false));
    } catch (error) {
      // show some error
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onStatusSelected: (option) => {
    dispatch(change(RXFORM_FORM_ADD, RXFIELD_FORM_STATUS, option));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormAddDialog));
