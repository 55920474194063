import _ from 'lodash';
import * as Action from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case Action.ADD_USER: {
      return { ...state, [action.user.id]: action.user };
    }
    case Action.REMOVE_USER:
      return _.omit(state, action.id);
    case Action.ADD_PROFILE_PICTURE: {
      const user = state[action.userId];
      return { ...state, [action.userId]: { ...user, profilePicture: action.imageId } };
    }
    case Action.REMOVE_PROFILE_PICTURE: {
      const user = state[action.userId];
      return { ...state, [user.id]: { ...user, profilePicture: undefined } };
    }
    default:
      return state;
  }
};
