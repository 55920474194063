import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import {
  withStyles, CardHeader, Card,
  Icon, CardContent, Avatar, Typography,
  Checkbox, FormControlLabel, InputAdornment, CssBaseline, Button,
} from '@material-ui/core';
import * as Constant from '../../constant';
import LocalizedString from '../../localization';
import * as Helper from '../../helper';
import {
  LoadingIndicator, ErrorMessage, RoundedButton, MySnackbar,
} from '../../component';

const styles = theme => ({
  rootContainer: {
    display: 'flex',
    height: '100%',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundImage: `url("${Constant.IMAGE_BACKGROUND_LOGIN}")`,
    backgroundSize: 'cover',
    backgroundColor: 'rgba(0,0,0,0.6)',
    backgroundBlendMode: 'soft-light',
  },
  titleContainer: {
    width: '90%',
    padding: '16px',
  },
  footerContainer: {
    width: '90%',
    padding: '16px',
    textAlign: 'right',
  },
  button: {
    margin: theme.spacing.unit,
  },
  cardRoot: {
    overflow: 'visible',
    padding: theme.spacing.unit,
    minWidth: '360px',
    maxWidth: '95%',
  },
  cardHeaderAvatar: {
    margin: 0,
    width: '100%',
  },
  cardAvatar: {
    height: 96,
    // width: 'calc(100% - 24px)',
    width: '100%',
    padding: '12px',
    borderRadius: 4,
    marginTop: theme.spacing.unit * -6,

    background: 'linear-gradient(60deg, #0164b2, #014982)',
    boxShadow: '0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)',
    color: '#ffffff',
  },

  labelTitle: {
    fontWeight: 500,
    color: '#ffffff',
  },
  labelSubtitle: {
    fontWeight: 400,
    color: '#ffffff',
  },
  labelFooter: {
    fontWeight: 400,
    color: '#ffffff',
  },
});

const LoginCardAvatar = withStyles(styles)(props => (
  <Avatar classes={{ root: props.classes.cardAvatar }}>
    <img title="Logo" alt="Logo" src="/assets/images/logo.png" />
  </Avatar>
));

const renderCheckbox = ({ input, label }) => (
  <FormControlLabel
    label={label}
    control={(
      <Checkbox
        label={label}
        checked={input.value}
        onChange={input.onChange}
      />
    )}
  />
);
renderCheckbox.propTypes = {
  input: PropTypes.shape(PropTypes.any),
  label: PropTypes.string,
};
renderCheckbox.defaultProps = {
  input: {},
  label: '',
};

const LoginScreen = ({
  handleSubmit, onLoginPressed, onRegisterPressed, loggingIn, errorMsg, classes,
}) => (
  <>
    <CssBaseline />
    <div className={classes.rootContainer}>
      <div className={classes.titleContainer}>
        <Typography variant="title">
          <span className={classes.labelTitle}>{LocalizedString.loginScreen.title}</span>
          &nbsp;
          <span variant="title" className={classes.labelSubtitle}>{LocalizedString.loginScreen.subTitle}</span>
        </Typography>
      </div>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader avatar={<LoginCardAvatar />} classes={{ avatar: classes.cardHeaderAvatar }} />
        <CardContent>
          <form onSubmit={handleSubmit(onLoginPressed)}>
            <Field
              name={Constant.RXFIELD_USERNAME}
              type="text"
              component={Helper.renderReduxFormTextField}
              endAdornment={<InputAdornment position="end"><Icon color="disabled">face</Icon></InputAdornment>}
              label={LocalizedString.loginScreen.labelUsername}
            />
            <Field
              name={Constant.RXFIELD_PASSWORD}
              type="password"
              component={Helper.renderReduxFormTextField}
              endAdornment={<InputAdornment position="end"><Icon color="disabled">lock</Icon></InputAdornment>}
              label={LocalizedString.loginScreen.labelPassword}
            />

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
              {/* <Field name={Constant.RXFIELD_REMEMBER_ME} component={renderCheckbox} label={LocalizedString.loginScreen.labelRememberMe} /> */}
              <span style={{ marginRight: '64px' }} />
              <Typography
                component={Link}
                to={Constant.ROUTE_NAME_FORGET_PASSWORD}
                variant="body1"
              >
                {LocalizedString.loginScreen.buttonCaptionForgetPassword}
              </Typography>
            </div>

            <div style={{ textAlign: 'center', marginTop: '32px' }}>
              <RoundedButton type="submit" className={classes.button} disabled={loggingIn}>
                {LocalizedString.loginScreen.buttonCaptionLogin}
              </RoundedButton>
            </div>

            <div style={{ textAlign: 'center', marginTop: '8px' }}>
              <Button variant="text" color="primary" onClick={onRegisterPressed} disabled={loggingIn}>
                {LocalizedString.registrationInfoScreen.buttonCaptionRegister}
              </Button>
            </div>
          </form>
          <div style={{ textAlign: 'center' }}>
            {loggingIn && <LoadingIndicator />}
          </div>
          {errorMsg && <ErrorMessage message={errorMsg} />}
        </CardContent>
      </Card>
      <div className={classes.footerContainer}>
        <Typography variant="body2" className={classes.labelFooter}>{LocalizedString.loginScreen.footer}</Typography>
      </div>
    </div>
    <MySnackbar />
  </>
);

const LoginScreenWithStyles = withStyles(styles)(LoginScreen);

export default reduxForm({
  form: Constant.RXFORM_LOGIN_SCREEN,
})(LoginScreenWithStyles);

LoginScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onLoginPressed: PropTypes.func.isRequired,
  onRegisterPressed: PropTypes.func.isRequired,
};

LoginScreen.defaultProps = {
  errorMsg: '',
};
