import * as Action from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case Action.ADD_TARIFF_SIMULATION_INFO: {
      return { ...action.tariffSimulation };
    }
    default:
      return state;
  }
};
