import { connect } from 'react-redux';
import _, { isEmpty } from 'lodash';
import FormBase from './form.presentation';
import * as Constant from '../../constant';
import {
  setFormFilter,
  setFormPaging,
  setFormSort,
  setFormAddDialogStatus,
  setFormEditDialogStatus,
  setFormSelectedId,
  showErrorSnackbar,
  setFormDetailDialogStatus,
  setFormDeleteDialogStatus,
  downloadAllFormAsync,
} from '../../redux/action';
import LocalizedString from '../../localization';
import { sortAsc, unauthorizedErrorHandler } from '../../helper';

const getFilteredForms = (state) => {
  const filteredForms = _.values(state.forms.data)
    .map((item, index) => ({
      ...item,
      index: index + 1,
      order: `${item.order}`,
    }))
    .sort((a, b) => sortAsc(a.title, b.title));


  const { sortField, sortDirection } = state.uiForm;

  const sorted = filteredForms
    .sort((left, right) => {
      if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
        return left[sortField] - right[sortField];
      }
      return right[sortField] - left[sortField];
    });

  return sorted;
};

const getPageFilteredForms = (state) => {
  const { filters } = state.uiForm;
  const filteredForm = getFilteredForms(state);

  if (!isEmpty(filteredForm)) {
    return filteredForm.filter(
      x => x.title.toLowerCase().includes(filters.title.toLowerCase()),
    );
  }

  return [];
};

const mapStateToProps = state => ({
  downloading: state.uiForm.downloading,
  menuOpen: state.uiMenu.menuOpen,
  forms: getPageFilteredForms(state),

  filters: state.uiForm.filters,
  activePage: state.uiForm.activePage,
  pageSize: state.uiForm.pageSize,
  totalPage: state.forms.meta.totalPages,
  sortField: state.uiForm.sortField,
  sortDirection: state.uiForm.sortDirection,

  dialogAddStatus: state.uiForm.dialogAddStatus,
  dialogEditStatus: state.uiForm.dialogEditStatus,
  dialogDetailStatus: state.uiForm.dialogDetailStatus,
  dialogDeleteStatus: state.uiForm.dialogDeleteStatus,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    try {
      dispatch(setFormSort('order', Constant.TABLE_SORT_ASCENDING));
      dispatch(setFormPaging(Constant.RXTABLEFIELD_PAGE_SIZE, 20));
      dispatch(setFormPaging(Constant.RXTABLEFIELD_ACTIVE_PAGE, 0));

      await dispatch(downloadAllFormAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onChangeFilter: async (filterName, value) => {
    dispatch(setFormFilter(filterName, value));
    await dispatch(downloadAllFormAsync());
  },
  onChangePaging: async (pageField, value) => {
    dispatch(setFormPaging(pageField, value));
    await dispatch(downloadAllFormAsync(value));
  },
  onChangeRow: async (pageField, value) => {
    dispatch(setFormPaging(pageField, value));
    await dispatch(downloadAllFormAsync());
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setFormSort(sortField, sortDirection));
  },
  onAddNewPressed: () => {
    dispatch(setFormAddDialogStatus(true));
  },
  onAddDialogClosePressed: () => {
    dispatch(setFormAddDialogStatus(false));
  },
  onDeletePressed: (userId) => {
    dispatch(setFormSelectedId(userId));
    dispatch(setFormDeleteDialogStatus(true));
  },
  onDeleteDialogClosePressed: () => {
    dispatch(setFormDeleteDialogStatus(false));
  },
  onDetailPressed: (userId) => {
    dispatch(setFormSelectedId(userId));
    dispatch(setFormDetailDialogStatus(true));
  },
  onDetailDialogClosePressed: () => {
    dispatch(setFormDetailDialogStatus(false));
  },
  onEditPressed: (userId) => {
    dispatch(setFormSelectedId(userId));
    dispatch(setFormEditDialogStatus(true));
  },
  onEditDialogClosePressed: () => {
    dispatch(setFormEditDialogStatus(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FormBase);
