import { SET_TARIFF_SIMULATION_HOST_BL, CLEAR_TARIFF_SIMULATION_HOST_BL } from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_TARIFF_SIMULATION_HOST_BL: {
      return { ...action.value };
    }
    case CLEAR_TARIFF_SIMULATION_HOST_BL: {
      return {};
    }
    default:
      return state;
  }
};
