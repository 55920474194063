import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  withStyles,
  CssBaseline,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TableCell,
  TableSortLabel,
  Input,
  IconButton,
  Icon,
  Table,
  TableHead,
  TableRow,
  CardHeader,
  TableBody,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import {
  DEFAULT_STYLES,
  DRAWER_NOTIFICATION_WIDTH,
  TABLE_SORT_ASCENDING,
  TABLE_SORT_DESCENDING,
  RXFORM_ERP_PAYMENT_LOG_DETAIL,
  TABLE_FIELD_ERP_PAYMENT_LOG_STATUS,
  DEFAULT_PAGE_SIZE_OPTIONS,
  RXTABLEFIELD_PAGE_SIZE,
  TABLE_FIELD_ERP_PAYMENT_LOG_PROFORMA_NO,
  TABLE_FIELD_ERP_PAYMENT_LOG_COMPANY,
  TABLE_FIELD_ERP_PAYMENT_LOG_AMOUNT,
  TABLE_FIELD_ERP_PAYMENT_LOG_CREATED_DATE,
  TABLE_FIELD_FORM_RESPONSE_ACTIONS,
  RXFIELD_ERP_PAYMENT_LOG_DETAIL_PROFORMA_NO,
  RXFIELD_ERP_PAYMENT_LOG_DETAIL_COMPANY,
  RXFIELD_ERP_PAYMENT_LOG_AMOUNT,
  RXFIELD_ERP_PAYMENT_LOG_DETAIL_STATUS,
  RXFIELD_ERP_PAYMENT_LOG_DETAIL_STATUS_NOTES,
  RXFIELD_ERP_PAYMENT_LOG_DETAIL_CREATED_DATE,
  RXFIELD_ERP_PAYMENT_LOG_DETAIL_RETRY,
  RXFIELD_ERP_PAYMENT_LOG_DETAIL_LAST_RETRY_ON,
  RXFIELD_ERP_PAYMENT_LOG_DETAIL_PAYLOAD,
  RXFIELD_ERP_PAYMENT_LOG_DETAIL_RESPONSE,
} from '../../constant';
import { MainMenu, PageHeaderAdmin, LoadingIndicator } from '../../component';
import LocalizedString from '../../localization';
import {
  isSortFieldActive, getSortIcon, renderReduxFormTextField, toCurrency,
} from '../../helper';
import ErpPaymentLogFilterDialog from './erp-payment-log-filter-dialog.container';
import ConfirmationDialog from '../checkout/confirmation-dialog';

const styles = theme => ({
  backIcon: {
    ...DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  btnNewBooking: {
    backgroundColor: '#5CB860',
    color: '#ffffff',
    width: '256px',
    '&:hover': {
      backgroundColor: '#558b2f',
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentMenuDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '256px',
      width: 'auto',
    },
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  detailHeader: {
    color: '#0066CB',
  },
  grow: {
    flexGrow: 1,
  },
  itemPerRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    color: '#0266B4',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  detailContent: {
    display: 'flex',
    flex: 1,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  spacer: {
    height: 20,
  },
  column: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  responseLabel: {
    color: '#00000061',
    marginTop: '14px',
    fontSize: '16px',
  },
});

class ErpPaymentLogPage extends Component {
  componentDidMount() {
    this.props.onAppear();
  }

  handleChangeSort = (newSortField) => {
    const { sortField, sortDirection, onChangeSort } = this.props;

    if (newSortField === sortField) {
      onChangeSort(
        sortField,
        sortDirection === TABLE_SORT_ASCENDING
          ? TABLE_SORT_DESCENDING
          : TABLE_SORT_ASCENDING,
      );
    } else {
      onChangeSort(newSortField, TABLE_SORT_ASCENDING);
    }
  };

  renderTableCellHeader = (label, fieldId) => {
    const { sortField, sortDirection } = this.props;
    return (
      <TableCell>
        <TableSortLabel
          active={
            isSortFieldActive(sortField, fieldId)
          }
          IconComponent={getSortIcon(
            isSortFieldActive(sortField, fieldId),
            sortDirection,
          )}
          onClick={
            () => this.handleChangeSort(fieldId)
          }
        >
          {label}
        </TableSortLabel>
      </TableCell>
    );
  }

  renderTableCellBody = text => (
    <TableCell>
      <Typography>
        {text || '-'}
      </Typography>
    </TableCell>
  );

  renderTableCellAction = (erpPaymentLog) => {
    const {
      classes, onDetailPressed,
    } = this.props;
    return (
      <TableCell>
        <IconButton
          color="primary"
          className={classes.iconButtonPadding4}
          onClick={() => onDetailPressed(erpPaymentLog.id)}
        >
          <Icon>info</Icon>
        </IconButton>
      </TableCell>
    );
  }

  renderTablePagination = () => {
    const {
      classes, activePage, totalPage, onChangePaging,
    } = this.props;
    if (totalPage - 1 !== 0) {
      return (
        <div className={classes.tablePagination}>
          <Button
            variant="contained"
            style={{ flexGrow: 1 }}
            disabled={activePage === 0}
            onClick={() => onChangePaging(activePage - 1)}
          >
            {LocalizedString.common.labelPagingPrevButton}
          </Button>
          <div className={classes.tablePaginationPageInput}>
            <span style={{ fontWeight: 500 }}>{LocalizedString.common.labelPagingPage}</span>
            <Input
              type="number"
              value={activePage + 1}
              style={{ width: '50%' }}
              inputProps={{
                min: 1,
                max: totalPage,
                step: 1,
                style: { textAlign: 'center' },
              }}
              onChange={({ nativeEvent }) => onChangePaging(
                parseInt(nativeEvent.target.value, 10) - 1,
              )}
            />
            <span style={{ fontWeight: 500 }}>
              {`${LocalizedString.common.labelPagingPageOf} ${totalPage}`}
            </span>
          </div>
          <Button
            variant="contained"
            style={{ flexGrow: 1 }}
            disabled={activePage === totalPage - 1}
            onClick={() => onChangePaging(activePage + 1)}
          >
            {LocalizedString.common.labelPagingNextButton}
          </Button>
        </div>
      );
    }
    return (<></>);
  }

  render() {
    const {
      classes,
      menuOpen,
      downloading,
      downloadingDetail,
      showDetail,
      showErpPaymentLogFilterDialog,
      erpPaymentLogs,
      selectedStatus,
      proformaNoFilterValue,
      showConfirmRetryDialog,
      retryingErpPayment,
      selectedErpPaymentId,

      onBackPressed,
      onErpPaymentLogFilterDialogClosePressed,
      onFilterPressed,
      onOkRetryPressed,
      onCancelRetryPressed,
      onRetryPressed,
    } = this.props;

    const renderTableItemPerRow = () => {
      const { pageSize, onChangeRow } = this.props;

      return (
        <div className={classes.itemPerRowContainer}>
          <Select
            value={pageSize}
            onChange={event => onChangeRow(
              RXTABLEFIELD_PAGE_SIZE,
              event.target.value,
            )}
          >
            {DEFAULT_PAGE_SIZE_OPTIONS.map(item => (
              <MenuItem value={item} key={item}>
                {item}
                &nbsp;
                {LocalizedString.common.labelRows}
              </MenuItem>
            ))}
          </Select>
        </div>
      );
    };

    const renderBody = () => {
      if (showDetail) {
        return (
          <div
            className={
          classNames(classes.detailContent, {
            [classes.contentMenuDrawerOpen]: menuOpen,
          })
        }
            style={{ padding: '32px' }}
          >
            <Grid container spacing={24}>
              <Card className={classes.column}>
                <CardHeader
                  title={(
                    <div className={classes.row}>
                      <IconButton aria-label="delete" onClick={onBackPressed}>
                        <Icon>arrow_back</Icon>
                      </IconButton>
                      <Typography variant="h6" className={classes.detailHeader}>
                        {LocalizedString.erpPaymentLog.title}
                      </Typography>
                    </div>
                )}
                />
                <CardContent className={classes.cardContent}>
                  <Field
                    name={RXFIELD_ERP_PAYMENT_LOG_DETAIL_PROFORMA_NO}
                    component={renderReduxFormTextField}
                    label={LocalizedString.erpPaymentLog.labelProformaNo}
                    disabled
                  />
                  <Field
                    name={RXFIELD_ERP_PAYMENT_LOG_DETAIL_COMPANY}
                    component={renderReduxFormTextField}
                    label={LocalizedString.erpPaymentLog.labelCompany}
                    disabled
                  />
                  <Field
                    name={RXFIELD_ERP_PAYMENT_LOG_AMOUNT}
                    component={renderReduxFormTextField}
                    label={LocalizedString.erpPaymentLog.labelAmount}
                    disabled
                  />
                  <Field
                    name={RXFIELD_ERP_PAYMENT_LOG_DETAIL_STATUS}
                    component={renderReduxFormTextField}
                    label={LocalizedString.erpPaymentLog.labelStatus}
                    disabled
                  />
                  <Field
                    name={RXFIELD_ERP_PAYMENT_LOG_DETAIL_STATUS_NOTES}
                    component={renderReduxFormTextField}
                    label={LocalizedString.erpPaymentLog.labelStatusNotes}
                    disabled
                    multiline
                  />
                  <Field
                    name={RXFIELD_ERP_PAYMENT_LOG_DETAIL_CREATED_DATE}
                    component={renderReduxFormTextField}
                    label={LocalizedString.erpPaymentLog.labelCreatedDate}
                    disabled
                  />
                  <Field
                    name={RXFIELD_ERP_PAYMENT_LOG_DETAIL_RETRY}
                    component={renderReduxFormTextField}
                    label={LocalizedString.erpPaymentLog.labelRetry}
                    disabled
                  />
                  <Field
                    name={RXFIELD_ERP_PAYMENT_LOG_DETAIL_LAST_RETRY_ON}
                    component={renderReduxFormTextField}
                    label={LocalizedString.erpPaymentLog.labelLastRetryOn}
                    disabled
                  />
                  <Field
                    name={RXFIELD_ERP_PAYMENT_LOG_DETAIL_PAYLOAD}
                    component={renderReduxFormTextField}
                    label={LocalizedString.erpPaymentLog.labelPayload}
                    disabled
                    multiline
                  />
                  <Field
                    name={RXFIELD_ERP_PAYMENT_LOG_DETAIL_RESPONSE}
                    component={renderReduxFormTextField}
                    label={LocalizedString.erpPaymentLog.labelResponse}
                    disabled
                    multiline
                  />

                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onRetryPressed}
                      style={{ marginTop: '20px' }}
                    >
                      {LocalizedString.erpPaymentLog.buttonCaptionRetry}
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </div>
        );
      }

      return (
        <div
          className={
          classNames(classes.content, {
            [classes.contentMenuDrawerOpen]: menuOpen,
          })
        }
          style={{ padding: '16px' }}
        >
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title={(
                    <div className={classes.tableHeader}>
                      <Typography variant="h6" className={classes.detailHeader}>
                        {LocalizedString.erpPaymentLog.title}
                      </Typography>
                      <div className={classes.row}>
                        {renderTableItemPerRow()}
                        <div style={{ width: 20 }} />
                        <Button variant="contained" className={classes.approve} onClick={() => onFilterPressed()}>
                          {LocalizedString.erpPaymentLog.labelFilter}
                        </Button>
                      </div>
                    </div>
                )}
                />
                <CardContent>
                  <div style={{ width: '100%', overflow: 'auto' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {this.renderTableCellHeader(LocalizedString.erpPaymentLog.labelProformaNo,
                            TABLE_FIELD_ERP_PAYMENT_LOG_PROFORMA_NO)}
                          {this.renderTableCellHeader(LocalizedString.erpPaymentLog.labelCompany,
                            TABLE_FIELD_ERP_PAYMENT_LOG_COMPANY)}
                          {this.renderTableCellHeader(LocalizedString.erpPaymentLog.labelAmount,
                            TABLE_FIELD_ERP_PAYMENT_LOG_AMOUNT)}
                          {this.renderTableCellHeader(LocalizedString.erpPaymentLog.labelStatus,
                            TABLE_FIELD_ERP_PAYMENT_LOG_STATUS)}
                          {this.renderTableCellHeader(
                            LocalizedString.erpPaymentLog.labelCreatedDate,
                            TABLE_FIELD_ERP_PAYMENT_LOG_CREATED_DATE,
                          )}
                          {this.renderTableCellHeader(LocalizedString.erpPaymentLog.labelActions,
                            TABLE_FIELD_FORM_RESPONSE_ACTIONS)}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {erpPaymentLogs && erpPaymentLogs.map(erpPaymentLog => (
                          <TableRow key={erpPaymentLog.id}>
                            {this.renderTableCellBody(erpPaymentLog.proformaNo)}
                            {this.renderTableCellBody(erpPaymentLog.company)}
                            {this.renderTableCellBody(toCurrency(erpPaymentLog.amount))}
                            {this.renderTableCellBody(erpPaymentLog.status)}
                            {this.renderTableCellBody(erpPaymentLog.createdDate)}
                            {this.renderTableCellAction(erpPaymentLog)}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>

                  {this.renderTablePagination()}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      );
    };

    return (
      <>
        <div className={classes.root}>
          <CssBaseline />

          <PageHeaderAdmin />

          <MainMenu />

          {renderBody(showDetail)}
          <ErpPaymentLogFilterDialog
            open={showErpPaymentLogFilterDialog}
            onClose={() => onErpPaymentLogFilterDialogClosePressed(
              selectedStatus, proformaNoFilterValue,
            )}
          />
        </div>

        {(downloading || downloadingDetail || retryingErpPayment) && <LoadingIndicator />}

        <ConfirmationDialog
          open={showConfirmRetryDialog}
          title={LocalizedString.common.alertTitleConfirmation}
          message={LocalizedString.erpPaymentLog.msgConfirmRetryErpPayment}
          onOkPressed={() => onOkRetryPressed(selectedErpPaymentId)}
          onCancelPressed={onCancelRetryPressed}
          useDefaultButtonStyle={false}
        />
      </>
    );
  }
}
ErpPaymentLogPage.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  menuOpen: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDetail: PropTypes.bool.isRequired,
  showDetail: PropTypes.bool.isRequired,
  showErpPaymentLogFilterDialog: PropTypes.bool.isRequired,
  showConfirmRetryDialog: PropTypes.bool.isRequired,
  retryingErpPayment: PropTypes.bool.isRequired,

  erpPaymentLogs: PropTypes.arrayOf(PropTypes.any).isRequired,

  sortField: PropTypes.string.isRequired,
  selectedStatus: PropTypes.string.isRequired,
  proformaNoFilterValue: PropTypes.string.isRequired,
  selectedErpPaymentId: PropTypes.string.isRequired,

  sortDirection: PropTypes.string.isRequired,
  activePage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,

  onAppear: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onChangePaging: PropTypes.func.isRequired,

  onDetailPressed: PropTypes.func.isRequired,
  onBackPressed: PropTypes.func.isRequired,
  onErpPaymentLogFilterDialogClosePressed: PropTypes.func.isRequired,
  onFilterPressed: PropTypes.func.isRequired,
  onChangeRow: PropTypes.func.isRequired,
  onOkRetryPressed: PropTypes.func.isRequired,
  onCancelRetryPressed: PropTypes.func.isRequired,
  onRetryPressed: PropTypes.func.isRequired,
};

export default reduxForm({
  form: RXFORM_ERP_PAYMENT_LOG_DETAIL,
  enableReinitialize: true,
})(withStyles(styles)(ErpPaymentLogPage));
