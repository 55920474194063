import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  CssBaseline,
  Grid,
  Card,
  CardContent,
  Fab,
  Icon,
  Typography,
  withStyles,
  Button,
} from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import * as Constant from '../../constant';
import LocalizedString from '../../localization';
import { PageHeaderUser, LoadingIndicator } from '../../component';
import { renderReduxFormFilterableSelectField, renderReduxFormTextField } from '../../helper';
import { rxformValidateImportRelease } from '../../validation';
import { HostBLShape } from '../../type';

const styles = theme => ({
  backIcon: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  btnAddToCart: {
    backgroundColor: '#5CB860',
    color: '#ffffff',
    width: '256px',
    '&:hover': {
      backgroundColor: '#558b2f',
    },
  },
  btnNext: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  btnPrint: {
    marginLeft: 8,
    backgroundColor: '#ffffff',
  },
  calendarPicker: {
    '& .react-calendar__navigation__next2-button': {
      display: 'none',
    },
    '& .react-calendar__navigation__prev2-button': {
      display: 'none',
    },
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  cardActionPrimary: {
    display: 'flex',
    alignItems: 'baseline',
    padding: 16,
    justifyContent: 'space-between',
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  cardBlDataHeader: {
    marginBottom: theme.spacing.unit * 2,
  },
  cardItem: {
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '6px',
    boxSizing: 'border-box',
    padding: '16px',
    marginBottom: theme.spacing.unit,
    '&:last-child': {
      marginBottom: 0,
    },
    transition: 'background-color 0.4s',
  },
  cardItemActive: {
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  cardItemContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: theme.spacing.unit * 2,
    maxBlockSize: '500px',
    height: '500px',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${Constant.DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  detailHeader: {
    color: '#0066CB',
  },
  detailButton: {
    backgroundColor: '#ffffff',
  },
  dialogContent: {
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    marginTop: '24px',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: '24px',
  },
  dialogActions: {
    margin: 0,
    padding: theme.spacing.unit * 3,
    borderTop: '1px solid #C4C4C4',
  },
  grow: {
    flexGrow: 1,
  },
  searchButton: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  moreButton: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  marginLeft: {
    marginLeft: theme.spacing.unit * 8,
  },
  itemPerRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    color: '#0266B4',
  },
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  textJumlahPembayaran: {
    textTransform: 'uppercase',
  },
  textTotalPrice: {
    fontSize: '22px',
    fontWeight: 500,
    color: '#0065B3',
    marginRight: theme.spacing.unit * 2,
  },

  centeredContent: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
  },

  searchButtonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '24px',
  },

  boldText: {
    fontWeight: 'bold',
  },
  boldBlueText: {
    fontWeight: 'bold',
    color: Constant.COLOR_PRIMARY,
  },
  boldGreyText: {
    fontWeight: 'bold',
    color: Constant.COLOR_TEXT_GREY,
  },
  hostBLCard: {
    padding: '0px',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  sppbNoContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px 20px 0 16px',
    borderBottom: `2px solid ${Constant.COLOR_HORIZONTAL_LINE}`,
  },
  sppbBodyContainer: {
    margin: '0px 16px 10px 16px',
    padding: '16px',
    borderLeft: `2px solid ${Constant.COLOR_HORIZONTAL_LINE}`,
  },
  sppbNo: {
    margin: '16px 0 0 0',
  },
  sppbBodySpacer: {
    height: '20px',
  },
  masterBLContainer: {
    padding: '16px',
    borderBottom: `2px solid ${Constant.COLOR_HORIZONTAL_LINE}`,
  },
  masterBL: {
    margin: 0,
  },
  typeMasterBLLabel: {
    color: Constant.COLOR_PRIMARY,
  },
  sppbNextButtonContainer: {
    display: 'flex',
    padding: '16px',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const renderBody = (
  classes, showHostBLCard, handleSubmit, onSearchBlPressed,
  downloading, downloadingHostBL, hostBL, onNextPressed,
  submittingHostBL,
) => {
  if (showHostBLCard) {
    return (
      <div className={classes.centeredContent}>
        <Card>
          <CardContent className={classes.hostBLCard}>
            <div className={classes.sppbNoContainer}>
              <div className={classes.sppbNo}>
                <Typography className={classes.boldText}>
                  {LocalizedString.tariffSimulationReleaseSearchBase.labelSPPBNo}
                </Typography>
              </div>
              <div className={classes.sppbBodyContainer}>
                <Typography className={classes.boldBlueText}>
                  {hostBL && hostBL.sppbNo ? hostBL.sppbNo : '-'}
                </Typography>
                <div className={classes.sppbBodySpacer} />
                <Typography className={classes.boldGreyText}>
                  {LocalizedString.tariffSimulationReleaseSearchBase.labelSPPBDate}
                </Typography>
                <Typography className={classes.boldText}>
                  {hostBL && hostBL.sppbDate ? moment(hostBL.sppbDate).format('DD MMM YYYY') : '-'}
                </Typography>
              </div>
            </div>
            <div className={classes.masterBLContainer}>
              <Typography className={classes.typeMasterBLLabel}>
                {LocalizedString.tariffSimulationReleaseSearchBase.placeholderMasterBL}
              </Typography>
              <Field
                name={Constant.RXFIELD_IMPORT_RELEASE_MASTER_BL_NUMBER}
                component={renderReduxFormTextField}
                label=""
                className={classes.masterBL}
                inputStyle={{ margin: 0 }}
                required
              />
              <div className={classes.sppbBodySpacer} />
            </div>
            <div className={classes.sppbNextButtonContainer}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.btnNext}
                onClick={handleSubmit(onNextPressed)}
                disabled={submittingHostBL}
              >
                {LocalizedString.tariffSimulationRelease.btnNext}
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
  return (
    <div className={classes.centeredContent}>
      <Card style={{ width: '480px' }}>
        <CardContent>
          <div>
            <Field
              name={Constant.RXFIELD_IMPORT_RELEASE_BL_NO}
              component={renderReduxFormTextField}
              label={LocalizedString.tariffSimulationReleaseSearchBase.placeholderSearchBlNo}
              required
              disabled={downloading || downloadingHostBL}
            />
          </div>
          <div>
            <Field
              name={Constant.RXFIELD_IMPORT_RELEASE_BL_TYPE}
              component={renderReduxFormFilterableSelectField}
              options={Constant.BL_TYPES}
              disabled={downloading || downloadingHostBL}
              label={LocalizedString.tariffSimulationReleaseSearchBase.placeholderSelectBlType}
              required
            />
          </div>
          <div className={classes.searchButtonContainer}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              className={classes.btnNext}
              onClick={handleSubmit(onSearchBlPressed)}
              disabled={downloading || downloadingHostBL}
            >
              {LocalizedString.tariffSimulationRelease.btnSearch}
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

class TariffSimulationReleaseSearchBase extends Component {
  componentDidMount() {
    this.props.onAppear();
  }

  render() {
    const {
      classes,
      notificationOpen,
      downloading,
      onSearchBlPressed,
      handleSubmit,
      downloadingHostBL,
      hostBL,
      showHostBLCard,
      onNextPressed,
      submittingHostBL,
    } = this.props;

    return (
      <div className={classes.root}>
        <div>
          <CssBaseline />

          <PageHeaderUser />


          <div
            className={
              classNames(classes.content, {
                [classes.contentDrawerOpen]: notificationOpen,
                [classes.contentDrawerClosed]: !notificationOpen,
              })
            }
            style={{ padding: '16px' }}
          >
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <div className={classes.pageNavigatorContainer}>
                      <Fab
                        variant="round"
                        classes={{ root: classes.backIcon }}
                        component={Link}
                        to={Constant.ROUTE_NAME_DASHBOARD_USER}
                      >
                        <Icon>chevron_left</Icon>
                      </Fab>
                      <div style={{ marginLeft: '32px' }}>
                        <Typography variant="h5" className={classes.pageTitle}>{LocalizedString.tariffSimulationRelease.title}</Typography>
                        <Typography variant="caption">{LocalizedString.tariffSimulationRelease.caption}</Typography>
                      </div>
                      <div className={classes.grow} />
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography>
                          <i>Information</i>
                        </Typography>
                        <Icon fontSize="large" color="primary">info</Icon>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>

        {renderBody(
          classes, showHostBLCard, handleSubmit, onSearchBlPressed,
          downloading, downloadingHostBL, hostBL, onNextPressed,
          submittingHostBL,
        )}

        {(downloading || downloadingHostBL || submittingHostBL) && <LoadingIndicator />}
      </div>
    );
  }
}

TariffSimulationReleaseSearchBase.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.any.isRequired,
  hostBL: HostBLShape.isRequired,

  notificationOpen: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingHostBL: PropTypes.bool.isRequired,
  showHostBLCard: PropTypes.bool.isRequired,
  submittingHostBL: PropTypes.bool.isRequired,

  onAppear: PropTypes.func.isRequired,
  onSearchBlPressed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onNextPressed: PropTypes.func.isRequired,
};

export default reduxForm({
  form: Constant.RXFORM_IMPORT_RELEASE,
  validate: rxformValidateImportRelease,
})(
  withStyles(styles)(TariffSimulationReleaseSearchBase),
);
