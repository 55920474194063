import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, Typography, withStyles,
} from '@material-ui/core';
import {
  COLOR_BACKGROUND_SECONDARY, COLOR_TEXT_GREY, COLOR_PRIMARY,
  FILE_EXTENSION_PDF, FILE_EXTENSION_CSV, FILE_EXTENSION_ALL, FILE_EXTENSION_SLIDESHOW,
  FILE_EXTENSION_SPREADSHEET, FILE_EXTENSION_DOCUMENT, FILE_EXTENSION_ZIP,
} from '../../constant';
import LocalizedString from '../../localization';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  form: {
    '& label.Mui-focused': {
      color: COLOR_TEXT_GREY,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_PRIMARY,
      },
    },
  },
  innerContainer: {
    padding: 20,
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: COLOR_BACKGROUND_SECONDARY,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  primaryText: {
    fontWeight: 'bold',
    color: COLOR_PRIMARY,
  },
  helperText: {
    fontWeight: '500',
    marginTop: '8px',
  },
});

const renderFile = (value, fileName, classes) => {
  if (value && value.length > 0) {
    return (
      <div className={classes.rowContainer}>
        {typeof value === 'string' && (
        <a href={value} rel="noopener noreferrer">
          <Typography className={classes.primaryText}>
            {fileName || LocalizedString.common.buttonCaptionDownloadFile}
          </Typography>
        </a>
        )}
      </div>
    );
  }
  return null;
};

const renderField = (
  disabled, onFileChange, onFileSelected, value, fileName, classes,
  acceptedFileExtension, required,
) => (
  <div className={classes.innerContainer}>
    <input
      type="file"
      onChange={e => onFileChange(e, onFileSelected)}
      accept={acceptedFileExtension}
      style={{ width: '100%' }}
      required={required}
      disabled={disabled}
    />

    {renderFile(value, fileName, classes)}
  </div>
);

const FilePickerField = ({
  classes, disabled, error, hidden,
  onFileChange, onFileSelected,
  value,
  fileName, helperText, label,
  acceptedFileExtension, required,
  ...props
}) => {
  if (!hidden) {
    return (
      <div className={classes.container}>
        <FormControl
          className={classes.form}
          variant="outlined"
          error={error}
          {...props}
          required={required}
        >
          <Typography>{label}</Typography>
          {renderField(
            disabled, onFileChange, onFileSelected, value, fileName, classes,
            acceptedFileExtension, required,
          )}
          <Typography className={classes.helperText}>{helperText}</Typography>
        </FormControl>
      </div>
    );
  }
  return null;
};

export default withStyles(styles)(FilePickerField);

FilePickerField.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hidden: PropTypes.bool,
  onFileChange: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fileName: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  acceptedFileExtension: PropTypes.oneOfType([
    FILE_EXTENSION_PDF,
    FILE_EXTENSION_CSV,
    FILE_EXTENSION_SPREADSHEET,
    FILE_EXTENSION_SLIDESHOW,
    FILE_EXTENSION_DOCUMENT,
    FILE_EXTENSION_ZIP,
    FILE_EXTENSION_ALL,
    PropTypes.string,
  ]),
};

FilePickerField.defaultProps = {
  error: false,
  disabled: false,
  hidden: false,
  fileName: null,
  label: null,
  helperText: null,
  value: null,
  required: false,
  acceptedFileExtension: FILE_EXTENSION_PDF,
};
