import React from 'react';
import PropTypes from 'prop-types';
import { PAYMENT_TYPE_CASH } from '../../constant';
import TermDetailPresentation from './term-detail.presentation';
import HistoryDetailPresentation from './history-detail.presentation';
import { BankOptionDialog, LoadingIndicator } from '../../component';
import CheckoutPasswordInputDialog from './password-dialog';

const OutstandingDetail = ({
  selectedBl,
  selectedBlItems,
  shoppingCart,
  addingShoppingCartItem,
  onAddToCartPressed,
  userCompany,
  shoppingCheckoutItems,
  changingOrderPaymentStatus,

  // proforma
  activePage,
  totalPage,
  filters,
  sortField,
  sortDirection,
  pageSize,

  dialogPaymentSelectBankStatus,
  dialogPaymentConfirmStatus,
  selectedBank,
  password,
  error,

  onChangeSort,
  onChangePaging,
  onChangeFilter,

  onInvoicePressed,
  onSp2Pressed,
  onKmtPressed,
  onCancelPaymentPressed,
  onChangePaymentPressed,

  onBankOptionSelected,
  onOkBankOptionPressed,
  onCancelBankOptionPressed,
  onChangePasswordText,
  onCancelPasswordInputPressed,
  onSubmitPasswordInputPressed,
}) => {
  if (!selectedBl) {
    return null;
  }

  if (selectedBl.outstandingType === PAYMENT_TYPE_CASH) {
    return (
      <>
        <HistoryDetailPresentation
          selectedBl={selectedBl}
          selectedBlItems={selectedBlItems}
          userCompany={userCompany}
          activePage={activePage}
          totalPage={totalPage}
          filters={filters}
          sortField={sortField}
          sortDirection={sortDirection}
          pageSize={pageSize}
          onChangeSort={onChangeSort}
          onChangeFilter={onChangeFilter}
          onChangePaging={onChangePaging}
          onInvoicePressed={onInvoicePressed}
          onSp2Pressed={onSp2Pressed}
          onKmtPressed={onKmtPressed}
          onCancelPaymentPressed={onCancelPaymentPressed}
          onChangePaymentPressed={onChangePaymentPressed}
        />
        <BankOptionDialog
          open={dialogPaymentSelectBankStatus}
          selectedBank={selectedBank}
          onBankOptionSelected={(value) => { onBankOptionSelected(value); }}
          onOkPressed={onOkBankOptionPressed}
          onCancelPressed={onCancelBankOptionPressed}
        />
        <CheckoutPasswordInputDialog
          dialogPaymentConfirmStatus={dialogPaymentConfirmStatus}
          password={password}
          userCompany={userCompany}
          error={error}
          shoppingCheckoutItems={shoppingCheckoutItems}
          onChangePasswordText={onChangePasswordText}
          onCancelPressed={onCancelPasswordInputPressed}
          onSubmitPressed={onSubmitPasswordInputPressed}
        />
        {changingOrderPaymentStatus && <LoadingIndicator />}
      </>
    );
  }

  return (
    <>
      <TermDetailPresentation
        selectedBl={selectedBl}
        shoppingCart={shoppingCart}
        userCompany={userCompany}
        addingShoppingCartItem={addingShoppingCartItem}
        onAddToCartPressed={onAddToCartPressed}
        onInvoicePressed={onInvoicePressed}
        onSp2Pressed={onSp2Pressed}
        onKmtPressed={onKmtPressed}
        onCancelPaymentPressed={onCancelPaymentPressed}
        onChangePaymentPressed={onChangePaymentPressed}
      />
    </>
  );
};
OutstandingDetail.propTypes = {
  selectedBl: PropTypes.shape(PropTypes.any).isRequired,
  selectedBlItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  shoppingCart: PropTypes.arrayOf(PropTypes.any).isRequired,
  addingShoppingCartItem: PropTypes.bool.isRequired,
  onAddToCartPressed: PropTypes.func.isRequired,
  userCompany: PropTypes.shape(PropTypes.any).isRequired,
  error: PropTypes.string.isRequired,
  changingOrderPaymentStatus: PropTypes.bool.isRequired,

  // proforma
  filters: PropTypes.shape(PropTypes.any).isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  activePage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,

  dialogPaymentSelectBankStatus: PropTypes.bool.isRequired,
  selectedBank: PropTypes.number.isRequired,

  onChangeSort: PropTypes.func.isRequired,
  onChangePaging: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,

  onInvoicePressed: PropTypes.func.isRequired,
  onSp2Pressed: PropTypes.func.isRequired,
  onKmtPressed: PropTypes.func.isRequired,
  onCancelPaymentPressed: PropTypes.func.isRequired,
  onChangePaymentPressed: PropTypes.func.isRequired,

  onBankOptionSelectedd: PropTypes.func.isRequired,
  onOkBankOptionPressedd: PropTypes.func.isRequired,
  onCancelBankOptionPressedd: PropTypes.func.isRequired,
  onChangePasswordText: PropTypes.func.isRequired,
  onCancelPasswordInputPressed: PropTypes.func.isRequired,
  onSubmitPasswordInputPressed: PropTypes.func.isRequired,
};

export default OutstandingDetail;
