import { connect } from 'react-redux';
import ExportDetailDialog from './export-history-detail-dialog.presentation';

const getContainerInfo = state => state.exportOrderHistoryDetail[state.uiExport.tappedItemNo];

const mapStateToProps = state => ({
  containerInfo: getContainerInfo(state),
});

const mapDispatchToProps = dispatch => ({
  onAppear: async (blNo, containerNo) => {
    // download required data here
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportDetailDialog);
