import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import {
  withStyles,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Avatar,
} from '@material-ui/core';
import * as Constant from '../../constant';
import * as Helper from '../../helper';
import LocalizedString from '../../localization';
import {
  RoundedButton, ErrorMessage, LoadingIndicator, MySnackbar,
} from '../../component';

const styles = theme => ({
  rootContainer: {
    display: 'flex',
    height: '100%',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundImage: `url("${Constant.IMAGE_BACKGROUND_LOGIN}")`,
    backgroundSize: 'cover',
    backgroundColor: 'rgba(0,0,0,0.6)',
    backgroundBlendMode: 'soft-light',
  },
  titleContainer: {
    width: '90%',
    padding: '16px',
  },
  footerContainer: {
    width: '90%',
    padding: '16px',
    textAlign: 'right',
  },
  button: {
    margin: theme.spacing.unit,
  },
  cardRoot: {
    overflow: 'visible',
    padding: theme.spacing.unit,
    minWidth: '360px',
    maxWidth: '95%',
  },
  cardHeaderAvatar: {
    margin: 0,
    width: '100%',
  },
  cardAvatar: {
    height: 64,
    width: 'calc(100% - 24px)',
    padding: '12px',
    borderRadius: 4,
    marginTop: theme.spacing.unit * -6,

    background: 'linear-gradient(60deg, #0164b2, #014982)',
    boxShadow: '0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)',
    color: '#ffffff',
  },
  infoCaption: {
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.38)',
    marginBottom: 16,
  },
  sectionHeader: {
    marginTop: 8,
  },
  labelTitle: {
    fontWeight: 500,
    color: '#ffffff',
  },
  labelSubtitle: {
    fontWeight: 400,
    color: '#ffffff',
  },
  labelFooter: {
    fontWeight: 400,
    color: '#ffffff',
  },
});

const LoginCardAvatar = withStyles(styles)(props => (
  <Avatar classes={{ root: props.classes.cardAvatar }}>
    <img title="Logo" alt="Logo" src="/assets/images/logo.png" />
  </Avatar>
));

const RegisterPasswordPage = ({
  classes,
  submitting,
  errorMsg,
  resetPin,

  handleSubmit,
  onSubmitPressed,
}) => {
  const infoCaption = resetPin
    ? 'Set login password and PIN transactions'
    : 'Set login password';

  return (
    <div className={classes.rootContainer}>
      <div className={classes.titleContainer}>
        <Typography variant="title">
          <span className={classes.labelTitle}>{LocalizedString.loginScreen.title}</span>
          &nbsp;
          <span variant="title" className={classes.labelSubtitle}>{LocalizedString.loginScreen.subTitle}</span>
        </Typography>
      </div>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          avatar={<LoginCardAvatar />}
          classes={{ avatar: classes.cardHeaderAvatar }}
        />
        <CardContent>
          <Typography className={classes.infoCaption}>
            {infoCaption}
          </Typography>
          <form onSubmit={handleSubmit(onSubmitPressed)}>
            <Typography variant="body2" className={classes.sectionHeader}>Login Password</Typography>
            <Field
              name={Constant.RXFIELD_PASSWORD}
              type="password"
              disabled={submitting}
              component={Helper.renderReduxFormTextField}
              label={LocalizedString.registerPasswordScreen.labelNewPassword}
            />
            <Field
              name={Constant.RXFIELD_REPASSWORD}
              type="password"
              disabled={submitting}
              component={Helper.renderReduxFormTextField}
              label={LocalizedString.registerPasswordScreen.labelConfirmNewPassword}
            />

            {resetPin && (
              <>
                <Typography variant="body2" className={classes.sectionHeader}>PIN</Typography>
                <Field
                  name={Constant.RXFIELD_PIN}
                  type="password"
                  disabled={submitting}
                  component={Helper.renderReduxFormTextField}
                  label={LocalizedString.registerPasswordScreen.labelPin}
                />
                <Field
                  name={Constant.RXFIELD_REPIN}
                  type="password"
                  disabled={submitting}
                  component={Helper.renderReduxFormTextField}
                  label={LocalizedString.registerPasswordScreen.labelConfirmPin}
                />
              </>
            )}

            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '32px' }}>
              <RoundedButton type="submit" className={classes.button}>
                {LocalizedString.common.buttonCaptionSave}
              </RoundedButton>
            </div>

            {errorMsg && <ErrorMessage message={errorMsg} />}
          </form>
        </CardContent>
      </Card>
      <div className={classes.footerContainer}>
        <Typography variant="body2" className={classes.labelFooter}>{LocalizedString.loginScreen.footer}</Typography>
      </div>
      {submitting && <LoadingIndicator />}
      <MySnackbar />
    </div>
  );
};
RegisterPasswordPage.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  submitting: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string.isRequired,
  resetPin: PropTypes.bool.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
};

export default reduxForm({
  form: Constant.RXFORM_REGISTER_PASSWORD,
})(withStyles(styles)(RegisterPasswordPage));
