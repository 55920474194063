import * as Action from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case Action.ADD_PPJK_LIST_BY_ASSIGN:
      return { ...action.ppjkList };
    case Action.REMOVE_PPJK_LIST_BY_ASSIGN:
      return {};
    default:
      return state;
  }
};
