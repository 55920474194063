import { connect } from 'react-redux';
import VendorAddDialog from './vendor-add-dialog.presentation';
import { setUIError, clearUIError } from '../../redux/action';
import { RXSTATE_MASTER_VENDOR } from '../../constant';

const mapStateToProps = state => ({
  error: state.uiMasterVendor.error,
  initialValues: {
    id: '',
    name: '',
  },
  loading: false,
});

const mapDispatchToProps = dispatch => ({
  onAppear: () => { },
  onSubmitPressed: async (values) => {
    try {
      clearUIError(RXSTATE_MASTER_VENDOR);
    } catch (error) {
      setUIError(RXSTATE_MASTER_VENDOR, error.message);
    }
  },

});

export default connect(mapStateToProps, mapDispatchToProps)(VendorAddDialog);
