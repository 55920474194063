import { downloadingPredefinedOrder, setPredefinedOrder } from '../simple-action';
import { downloadPredefinedOrder } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    const { token } = getState().authentication;

    dispatch(downloadingPredefinedOrder(true));
    const result = await downloadPredefinedOrder(token);
    dispatch(setPredefinedOrder(result));
  } finally {
    dispatch(downloadingPredefinedOrder(false));
  }
};
