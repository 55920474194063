import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import HistoryDetail from './history-detail.presentation';

const mapStateToProps = state => ({
  selectedData: state.trackingStatusBehandleHistories[state.uiTrackingStatusBehandleHistory
    .tappedId],
});

const mapDispatchToProps = dispatch => ({
  onAppear: () => {},
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HistoryDetail));
