import * as Action from '../action';
import * as Constant from '../../constant';

const initialState = {
  downloadingTariffSimulationOrder: false,
  downloadingTariffSimulationData: false,
  downloadingTariffSimulationInfo: false,
  downloadingProformaInvoice: false,
  tappedBlNo: '',
  action: '',
  searchBarText: '',
  chosenDate: '',
  selectedPaymentBehalfOf: '',
  passwordInputDialog: false,
  password: '',
  combineWithImportRelease: false,

  filters: {
    [Constant.TABLE_FIELD_TARIFF_SIMULATION_DESCRIPTION]: '',
    [Constant.TABLE_FIELD_TARIFF_SIMULATION_DAYS]: '',
    [Constant.TABLE_FIELD_TARIFF_SIMULATION_PRICE]: '',
    [Constant.TABLE_FIELD_TARIFF_SIMULATION_TOTAL]: '',
  },
  sortField: '',
  sortDirection: '',
  activePage: 1,
  pageSize: 5,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.SET_TARIFF_SIMULATION_ACTION:
      return { ...state, action: action.value };
    case Action.DOWNLOADING_TARIFF_SIMULATION_ORDER:
      return { ...state, downloadingTariffSimulationOrder: action.status };
    case Action.DOWNLOADING_TARIFF_SIMULATION_DATA:
      return { ...state, downloadingTariffSimulationData: action.status };
    case Action.SET_TAPPED_CONTAINER_TRACKING_BL_NO:
      return { ...state, tappedBlNo: action.tappedBlNo };
    case Action.SET_TARIFF_SIMULATION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case Action.SET_CHOSEN_TARIFF_SIMULATION_DATE:
      return { ...state, chosenDate: action.chosenDate };
    case Action.DOWNLOADING_TARIFF_SIMULATION_INFO:
      return { ...state, downloadingTariffSimulationInfo: action.status };
    case Action.SET_DIALOG_CALENDAR_STATUS:
      return { ...state, dialogCalendarStatus: action.status };
    case Action.SET_SELECTED_TARIFF_SIMULATION_PAYMENT_BEHALF_OF:
      return { ...state, selectedPaymentBehalfOf: action.value };
    case Action.SET_TARIFF_SIMULATION_PASSWORD_INPUT_DIALOG:
      return { ...state, passwordInputDialog: action.status };
    case Action.SET_TARIFF_SIMULATION_PASSWORD_TEXT_INPUT:
      return { ...state, password: action.password };
    case Action.SET_TARIFF_SIMULATION_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.value,
        },
      };
    }
    case Action.SET_TARIFF_SIMULATION_PAGING: {
      return { ...state, ...action.value };
    }
    case Action.SET_TARIFF_SIMULATION_SORT: {
      return { ...state, sortField: action.sortField, sortDirection: action.sortDirection };
    }
    case Action.DOWNLOADING_TARIFF_SIMULATION_PROFORMA_INVOICE: {
      return { ...state, downloadingProformaInvoice: action.value };
    }
    case Action.SET_COMBINE_TARIFF_SIMULATION_ORDER_LIST_WITH_IMPORT_RELEASE_ORDER: {
      return { ...state, combineWithImportRelease: action.value };
    }
    default:
      return state;
  }
};
