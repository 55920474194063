import { downloadingAssignOrderType, addAssignOrderType } from '../simple-action';
import * as Helper from '../../../helper';

export default blNo => async (dispatch, getState) => {
  const { token } = getState().authentication;

  try {
    dispatch(downloadingAssignOrderType(true));
    const assignOrderType = await Helper.downloadAssignOrderType(token, blNo);
    dispatch(addAssignOrderType(assignOrderType));
  } finally {
    dispatch(downloadingAssignOrderType(false));
  }
};
