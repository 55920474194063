import { connect } from 'react-redux';
import _ from 'lodash';
import { reset } from 'redux-form';
import { withRouter } from 'react-router-dom';
import DocumentSubmissionPage from './document-submission.presentation';
import {
  clearUIError,
  setUIError,
  downloadDocSubmissionMyResponsesAsync,
  setDocSubmissionMyResponseTappedId,
  setDocSubmissionMyResponseSearchBarText,
  setMyPaymentSelectedTab,
  setPredefinedOrderSearchText,
  setMyPaymentHistorySearchText,
} from '../../redux/action';
import * as Constant from '../../constant';
import { toMoment } from '../../helper';

const getSortedMyResponses = (state) => {
  const { searchBarText } = state.uiDocSubmissionMyResponse;

  const myResponses = _.values(state.docSubmissionMyResponses);
  return myResponses
    .filter((x) => {
      const refId = x.refId || '-';
      const submitId = x.submitId || '-';

      return refId.toUpperCase().includes(searchBarText.toUpperCase())
    || submitId.toUpperCase().includes(searchBarText.toUpperCase());
    })
    .sort((left, right) => toMoment(right.submitDate) - toMoment(left.submitDate));
};

const mapStateToProps = state => ({
  myResponses: getSortedMyResponses(state),
  tappedId: state.uiDocSubmissionMyResponse.tappedId,
  downloadingMyResponses: state.uiDocSubmissionMyResponse.downloading,
  notificationOpen: state.uiWebUserMain.notificationDrawerOpen,
  searchBarText: state.uiDocSubmissionMyResponse.searchBarText,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    try {
      clearUIError(Constant.RXSTATE_DOC_SUBMISSION_MY_RESPONSE);
      await dispatch(downloadDocSubmissionMyResponsesAsync());
    } catch (error) {
      setUIError(Constant.RXSTATE_DOC_SUBMISSION_MY_RESPONSE, error.message);
    }
  },
  onSubmitNewDocumentPressed: () => {
    ownProps.history.push(Constant.ROUTE_NAME_NEW_DOCUMENT);
  },
  onCardItemPressed: (id, previousTappedId) => {
    if (previousTappedId !== id) {
      dispatch(reset(Constant.RXFORM_DOC_SUBMISSION_MY_RESPONSE_DETAIL));
      dispatch(setDocSubmissionMyResponseTappedId(id));
    }
  },
  onChangeSearchBarText: (text) => {
    dispatch(setDocSubmissionMyResponseSearchBarText(text));
  },
  onStatusActionPressed: (e, router, status, refId) => {
    e.stopPropagation();
    if (refId) {
      if (status === Constant.FORM_RESPONSE_STATUS_WAITING_PAYMENT) {
        dispatch(setMyPaymentSelectedTab(3));
        dispatch(setPredefinedOrderSearchText(refId));
      }
      if (status === Constant.FORM_RESPONSE_STATUS_FINISHED) {
        dispatch(setMyPaymentSelectedTab(2));
        dispatch(setMyPaymentHistorySearchText(refId));
      }
      router.push(Constant.ROUTE_NAME_MY_PAYMENT);
    }
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DocumentSubmissionPage),
);
