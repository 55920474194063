import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  withStyles, ButtonBase, Grid, Typography, TextField, Fab, Icon,
} from '@material-ui/core';
import LocalizedString from '../../localization';
import {
  MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING,
  DEFAULT_STYLES,
} from '../../constant';
import { LoadingIndicator } from '../../component';
import { toMoment } from '../../helper';

const styles = theme => ({
  alignRight: {
    textAlign: 'right',
  },
  cardItem: {
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '6px',
    boxSizing: 'border-box',
    padding: '16px',
    marginBottom: theme.spacing.unit,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  cardItemActive: {
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  cardItemContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: theme.spacing.unit * 2,
    maxBlockSize: '500px',
  },
  searchButton: {
    ...DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
});

class HistoryCard extends Component {
  componentDidMount() {
    this.props.onAppear();
  }

  render() {
    const {
      classes,
      bl,
      searchBarText,
      selectedProformaNo,
      userCompany,
      downloading,
      onChangeSearchBarText,
      onMorePressed,
    } = this.props;

    return (
      <>
        <div style={{ display: 'flex', marginTop: '24px' }}>
          <div style={{ flexGrow: 1, alignSelf: 'flex-end' }}>
            <TextField
              fullWidth
              placeholder="Search BL no"
              value={searchBarText}
              onChange={event => onChangeSearchBarText(event.target.value)}
            />
          </div>
          <div style={{ display: 'inline-flex', marginLeft: '8px' }}>
            <Fab
              size="small"
              variant="round"
              color="default"
              className={classes.searchButton}
            >
              <Icon>search</Icon>
            </Fab>
          </div>
        </div>

        <div className={classes.cardItemContainer}>
          {bl.map((item) => {
            const createdByName = item.createdByCompany !== userCompany.id
              ? item.createdByCompany
              : item.createdByUser;

            return (
              <ButtonBase
                component="div"
                className={classNames(classes.cardItem, {
                  [classes.cardItemActive]: selectedProformaNo
                    .toLowerCase() === item.proformaNo.toLowerCase(),
                })}
                onClick={() => onMorePressed(item.proformaNo)}
              >
                <Grid container spacing={24}>
                  <Grid item xs={12}>
                    <Typography color="primary" variant="h6">
                      {LocalizedString.myPaymentOutstanding.labelProformaNo}
                      &nbsp;
                      {item.proformaNo}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography variant="caption">
                      {LocalizedString.myPaymentOutstanding.labelStatus}
                    </Typography>
                    <Typography>
                      {item.paymentStatus}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} className={classes.alignRight}>
                    <Typography variant="caption">
                      {LocalizedString.myPaymentOutstanding.labelCreatedBy}
                    </Typography>
                    <Typography>
                      {createdByName}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography variant="caption">
                      {LocalizedString.myPaymentOutstanding.labelCreatedOn}
                    </Typography>
                    <Typography>
                      {toMoment(item.createdDate)
                        .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} className={classes.alignRight}>
                    <Typography variant="caption">
                      {LocalizedString.myPaymentOutstanding.labelPaidOn}
                    </Typography>
                    <Typography>
                      {item.paidOn
                        ? toMoment(item.paidOn).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)
                        : '-'}
                    </Typography>
                  </Grid>
                </Grid>
              </ButtonBase>
            );
          })}
        </div>

        {downloading && <LoadingIndicator />}
      </>
    );
  }
}
HistoryCard.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  bl: PropTypes.arrayOf(PropTypes.any).isRequired,
  searchBarText: PropTypes.string.isRequired,
  selectedProformaNo: PropTypes.string.isRequired,
  userCompany: PropTypes.shape(PropTypes.any).isRequired,

  downloading: PropTypes.bool.isRequired,

  onAppear: PropTypes.func.isRequired,
  onChangeSearchBarText: PropTypes.func.isRequired,
  onMorePressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(HistoryCard);
