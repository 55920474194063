import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import OrderTab from './order-tab.presentation';
import {
  downloadPredefinedOrderAsync, setPredefinedOrderSearchText, showErrorSnackbar,
} from '../../redux/action';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler, toMoment } from '../../helper';

const getFilteredOrder = (state) => {
  const { searchText } = state.uiPredefinedOrder;
  return Object.values(state.predefinedOrder)
    .filter((x) => {
      const blNo = x.blNo || '-';
      const blHeader = x.blHeader || '-';
      const invoiceNo = x.invoiceNo || '-';
      const soHeader = x.soHeader || '-';
      const billName = x.billName || '-';
      return blNo.toUpperCase().includes(searchText.toUpperCase())
      || blHeader.toUpperCase().includes(searchText.toUpperCase())
      || invoiceNo.toUpperCase().includes(searchText.toUpperCase())
      || soHeader.toUpperCase().includes(searchText.toUpperCase())
      || billName.toUpperCase().includes(searchText.toUpperCase());
    })
    .sort((left, right) => toMoment(right.createdOn) - toMoment(left.createdOn));
};

const mapStateToProps = state => ({
  downloading: state.uiPredefinedOrder.downloading,
  bl: getFilteredOrder(state),
  searchBarText: state.uiPredefinedOrder.searchText,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    try {
      await dispatch(downloadPredefinedOrderAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onChangeSearchBarText: (text) => {
    dispatch(setPredefinedOrderSearchText(text));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderTab));
