import { connect } from 'react-redux';
import ContainerTruckingInfo from './trucking.presentation';

const getContainerInfo = state => state
  .feederServiceOrderDetail[state.uiContainerTracking.tappedItemNo];

const mapStateToProps = state => ({
  selectedContainerNo: state.uiContainerTracking.tappedItemNo,
  container: getContainerInfo(state),
});

export default connect(mapStateToProps, {})(ContainerTruckingInfo);
