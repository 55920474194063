import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  withStyles, ButtonBase, Grid, Typography, TextField, Fab, Icon,
} from '@material-ui/core';
import LocalizedString from '../../localization';
import {
  MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING,
  DEFAULT_STYLES,
} from '../../constant';
import { LoadingIndicator } from '../../component';
import { toMoment } from '../../helper';

const styles = theme => ({
  alignRight: {
    textAlign: 'right',
  },
  cardItem: {
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '6px',
    boxSizing: 'border-box',
    padding: '16px',
    marginBottom: theme.spacing.unit,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  cardItemActive: {
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  cardItemContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: theme.spacing.unit * 2,
    maxBlockSize: '500px',
  },
  searchButton: {
    ...DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  boldText: {
    fontWeight: 'bold',
  },
});

const Card = ({
  classes,
  item,
  selectedItemNo,
  onMorePressed,
}) => (
  <ButtonBase
    component="div"
    key={item.blNumber}
    className={classNames(classes.cardItem, {
      [classes.cardItemActive]: selectedItemNo
          && item.containerNo.toUpperCase() === selectedItemNo.toUpperCase(),
    })}
    onClick={() => onMorePressed(item.containerNo)}
  >
    <Grid container spacing={24}>
      <Grid item xs={6} md={6}>
        <Typography color="primary" variant="subtitle1" className={classes.boldText}>
          {LocalizedString.trackingStatusBehandle.labelContainerNo}
        </Typography>
        <Typography color="primary" variant="subtitle1" className={classes.boldText}>
          {item.containerNo}
        </Typography>
      </Grid>
      <Grid item xs={6} md={6} className={classes.alignRight}>
        <Typography color="primary" variant="subtitle1" className={classes.boldText}>
          {LocalizedString.trackingStatusBehandle.labelBlNo}
        </Typography>
        <Typography color="primary" variant="subtitle1" className={classes.boldText}>
          {item.blNum}
        </Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        <Typography variant="caption">
          {LocalizedString.trackingStatusBehandle.labelCustomer}
        </Typography>
        <Typography>
          {item.customer}
        </Typography>
      </Grid>
      <Grid item xs={6} md={6} className={classes.alignRight}>
        <Typography variant="caption">
          {LocalizedString.trackingStatusBehandle.labelStatus}
        </Typography>
        <Typography>
          {item.status}
        </Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        <Typography variant="caption">
          {LocalizedString.trackingStatusBehandle.labelPibNo}
        </Typography>
        <Typography>
          {item.pibNum}
        </Typography>
      </Grid>
      <Grid item xs={6} md={6} className={classes.alignRight}>
        <Typography variant="caption">
          {LocalizedString.trackingStatusBehandle.labelPibDate}
        </Typography>
        <Typography>
          {item.timePib
            ? toMoment(item.timePib).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)
            : '-'}
        </Typography>
      </Grid>
    </Grid>
  </ButtonBase>
);

Card.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  item: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedItemNo: PropTypes.string.isRequired,
  onMorePressed: PropTypes.func.isRequired,
};

class HistoryCard extends Component {
  componentDidMount() {
    this.props.onAppear();
  }

  render() {
    const {
      classes,
      data,
      searchBarText,
      selectedItemNo,
      downloading,
      onChangeSearchBarText,
      onMorePressed,
    } = this.props;

    return (
      <>
        <div style={{ display: 'flex', marginTop: '24px' }}>
          <div style={{ flexGrow: 1, alignSelf: 'flex-end' }}>
            <TextField
              fullWidth
              placeholder={LocalizedString.trackingStatusBehandle.placeholderSearch}
              value={searchBarText}
              onChange={event => onChangeSearchBarText(event.target.value)}
            />
          </div>
          <div style={{ display: 'inline-flex', marginLeft: '8px' }}>
            <Fab
              size="small"
              variant="round"
              color="default"
              className={classes.searchButton}
            >
              <Icon>search</Icon>
            </Fab>
          </div>
        </div>

        <div className={classes.cardItemContainer}>
          {data.map(item => (
            <Card
              classes={classes}
              item={item}
              selectedItemNo={selectedItemNo}
              onMorePressed={onMorePressed}
            />
          ))}
        </div>
        {downloading && <LoadingIndicator />}
      </>
    );
  }
}

HistoryCard.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  searchBarText: PropTypes.string.isRequired,
  selectedItemNo: PropTypes.string.isRequired,
  downloading: PropTypes.bool.isRequired,

  onAppear: PropTypes.func.isRequired,
  onChangeSearchBarText: PropTypes.func.isRequired,
  onMorePressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(HistoryCard);
