import { downloadMoneyLoanTermsConditions } from '../../../helper';
import { downloadingMoneyLoanTnc, setMoneyLoanTnc, setMoneyLoanTncDialog } from '../simple-action';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  try {
    dispatch(setMoneyLoanTncDialog(true));
    dispatch(downloadingMoneyLoanTnc(true));
    const tnc = await downloadMoneyLoanTermsConditions(token);
    dispatch(setMoneyLoanTnc(tnc));
  } finally {
    dispatch(downloadingMoneyLoanTnc(false));
  }
};
