import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import * as Constant from '../constant';

const PrivateRoute = ({
  component: Component, authenticated, isAdmin, ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      authenticated === true && isAdmin === true
        ? <Component {...props} />
        : <Redirect to={Constant.ROUTE_NAME_LOGIN} />
    )}
  />
);

const mapStateToProps = state => ({
  authenticated: !!state.authentication.token,
  isAdmin: state.currentUser.corporateAdmin
    || (state.currentUser.role && state.currentUser.role.toUpperCase() === Constant.ROLE_ADMIN)
    || (state.currentUser.role && state.currentUser.role.toUpperCase() === Constant.ROLE_BILLING),
});
export default connect(mapStateToProps)(PrivateRoute);
