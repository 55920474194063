import { submittingEmailRegistration, addRegistrationInfo } from '../simple-action';
import * as Helper from '../../../helper';
import * as Constant from '../../../constant';

export default (email, navigateTo) => async (dispatch) => {
  try {
    dispatch(submittingEmailRegistration(true));
    const response = await Helper.registerEmail(email);
    const stateInfo = { email, pinExpirationDate: response.expiredDate };
    dispatch(addRegistrationInfo(stateInfo));

    navigateTo(Constant.ROUTE_NAME_PIN_VERIFICATION_REGISTER_EMAIL);
  } finally {
    dispatch(submittingEmailRegistration(false));
  }
};
