import _ from 'lodash';
import {
  addCheckoutItem,
  addingCheckoutItem,
  removeAllCheckoutItem,
  // removeShoppingCheckoutAsync,
} from '../simple-action';

export default (shoppingCartItems, selectedItems) => async (dispatch) => {
  try {
    dispatch(addingCheckoutItem(true));
    dispatch(removeAllCheckoutItem());

    _.flatMap(shoppingCartItems).forEach((item) => {
      if (selectedItems.includes(item.itemId)) {
        dispatch(addCheckoutItem(item));
      }
      // else {
      //   dispatch(removeShoppingCheckoutAsync(item.blNumber, item.itemId));
      // }
    });
  } finally {
    dispatch(addingCheckoutItem(false));
  }
};
