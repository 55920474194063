import { downloadingFeederServiceAreaList, addFeederServiceAreaList } from '../simple-action';
import * as Helper from '../../../helper';

export default exim => async (dispatch, getState) => {
  const { token } = getState().authentication;
  try {
    dispatch(downloadingFeederServiceAreaList(true));
    const feederServiceAreaList = await Helper.downloadFeederServiceAreaList(exim, token);
    dispatch(addFeederServiceAreaList(feederServiceAreaList));
  } finally {
    dispatch(downloadingFeederServiceAreaList(false));
  }
};
