import { connect } from 'react-redux';
import TruckingDetailDialog from './trucking-history-detail-dialog.presentation';

const mapStateToProps = state => ({
  selectedContainerNo: state.uiFeederServiceDetail.tappedContainerNo,
  container: state.feederServiceOrderDetail[state.uiFeederServiceDetail.tappedContainerNo],
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(TruckingDetailDialog);
