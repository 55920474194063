import * as Constant from '../../constant';
import * as Action from '../action';

const initialState = {
  filters: {
    [Constant.TABLE_FIELD_ORDER_LIST_BILL_TO]: '',
    [Constant.TABLE_FIELD_ORDER_LIST_BL_NO]: '',
  },
  sortField: '',
  sortDirection: '',
  activePage: 1,
  pageSize: 5,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.SET_ORDER_LIST_CUSTOMER_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.value,
        },
      };
    }
    case Action.SET_ORDER_LIST_CUSTOMER_PAGING: {
      return { ...state, ...action.value };
    }
    case Action.SET_ORDER_LIST_CUSTOMER_SORT: {
      return { ...state, sortField: action.sortField, sortDirection: action.sortDirection };
    }
    default: {
      return state;
    }
  }
};
