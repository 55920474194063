import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import {
  withTheme,
  Dialog,
  DialogTitle,
  Typography,
  Grid,
  List,
  ListItem,
  DialogContent,
  withStyles,
  Divider,
  Icon,
  IconButton,
} from '@material-ui/core';
import _ from 'lodash';
import * as moment from 'moment';
import * as Helper from '../../helper';
import * as Constant from '../../constant';
import { RoundedButton, LoadingIndicator } from '../../component';

/**
 *
 * @param {Theme} theme
 */
const styles = theme => ({
  listItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginTop: '-12px',
  },
  tabButtonColor: {
    backgroundColor: theme.palette.primary.main,
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
});

class UpdateUserDialog extends Component {
  state = {
    activeTabIndex: 0,
  };

  componentDidMount() {}

  initializeDialog = () => {
    this.setState({ activeTabIndex: 0 });
    this.props.onAppear();
  };

  renderVendorDropdown = () => {
    const { rxRole, isSaving, vendors } = this.props;

    const filteredVendors = _.values(vendors)
      .filter(vendor => vendor.role.toUpperCase() === rxRole)
      .map(vendor => ({ label: vendor.name, value: vendor.id }));

    if (rxRole && rxRole !== Constant.ROLE_ADMIN) {
      return (
        <Field
          name="companyId"
          component={Helper.renderReduxFormFilterableSelectField}
          options={filteredVendors}
          disabled={isSaving}
          label="Vendor"
          required
        />
      );
    }

    return <></>;
  };

  renderFormContent = () => {
    const {
      classes, isSaving, roles, rxRole, userInfo,
    } = this.props;

    if (this.state.activeTabIndex === 0) {
      return (
        <>
          <Field
            name="email"
            component={Helper.renderReduxFormTextField}
            disabled={isSaving}
            type="email"
            label="Email"
            required
          />
          <Field
            name="username"
            component={Helper.renderReduxFormTextField}
            disabled={isSaving}
            type="text"
            label="Username"
            required
          />
          <Field
            name="role"
            component={Helper.renderReduxFormFilterableSelectField}
            options={roles}
            disabled={isSaving}
            label="Role"
            required
          />

          {rxRole && rxRole !== Constant.ROLE_ADMIN && (
            <Field
              name="corporateAdmin"
              component={Helper.renderCheckbox}
              disabled={isSaving}
              label="Corporate Admin"
            />
          )}

          {this.renderVendorDropdown()}

          <div style={{ textAlign: 'center', padding: '16px' }}>
            <RoundedButton type="submit" disabled={isSaving}>
              Save
            </RoundedButton>
          </div>
        </>
      );
    }
    if (this.state.activeTabIndex === 1) {
      return (
        <>
          <Field
            name="fullName"
            component={Helper.renderReduxFormTextField}
            disabled={isSaving}
            label="Full Name"
            required
          />

          <Field
            name="address"
            component={Helper.renderReduxFormTextField}
            type="text"
            disabled={isSaving}
            label="Address"
          />

          <div style={{ textAlign: 'center', padding: '16px' }}>
            <RoundedButton type="submit" disabled={isSaving}>
              Save
            </RoundedButton>
          </div>
        </>
      );
    }
    if (this.state.activeTabIndex === 2) {
      return (
        <>
          <Grid container spacing={24}>
            <Grid item xs={12} md={4}>
              <Typography className={classes.fontWeightBold}>
                Registration Time:
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                {moment(userInfo.registeredOn).format(
                  Constant.MOMENT_DATE_TIME_FORMAT_STRING,
                )}
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography className={classes.fontWeightBold}>
                Registration IP:
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>{userInfo.registeredIp}</Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography className={classes.fontWeightBold}>
                Confirmation Status:
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                {userInfo.confirmedOn
                  ? `Confirmed at ${moment(userInfo.confirmedOn).format(
                    Constant.MOMENT_DATE_TIME_FORMAT_STRING,
                  )}`
                  : 'Unconfirmed'}
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography className={classes.fontWeightBold}>
                Block Status:
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                {userInfo.enabled ? 'Not blocked' : 'Blocked'}
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    }

    return <></>;
  };

  renderDialogContent = () => {
    const {
      classes,
      handleSubmit,
      onSaveButtonPressed,
      isSaving,
      isLoading,
    } = this.props;

    if (isLoading) {
      return (
        <div style={{ textAlign: 'center' }}>
          <LoadingIndicator />
        </div>
      );
    }

    return (
      <Grid container spacing={24}>
        <Grid item xs={12} md={4}>
          <List>
            <ListItem classes={{ root: classes.listItem }}>
              <RoundedButton
                disabled={isSaving}
                color="primary"
                variant={this.state.activeTabIndex === 0 ? 'contained' : 'text'}
                onClick={() => {
                  this.setState({ activeTabIndex: 0 });
                }}
              >
                Account Details
              </RoundedButton>
            </ListItem>
            <ListItem classes={{ root: classes.listItem }}>
              <RoundedButton
                disabled={isSaving}
                color="primary"
                variant={this.state.activeTabIndex === 1 ? 'contained' : 'text'}
                onClick={() => {
                  this.setState({ activeTabIndex: 1 });
                }}
              >
                Profile Details
              </RoundedButton>
            </ListItem>
            <ListItem classes={{ root: classes.listItem }}>
              <RoundedButton
                disabled={isSaving}
                color="primary"
                variant={this.state.activeTabIndex === 2 ? 'contained' : 'text'}
                onClick={() => {
                  this.setState({ activeTabIndex: 2 });
                }}
              >
                Information
              </RoundedButton>
            </ListItem>
            <Divider />
            <ListItem classes={{ root: classes.listItem }}>
              <RoundedButton
                disabled={isSaving}
                color="secondary"
                variant="text"
              >
                Block
              </RoundedButton>
            </ListItem>
            <ListItem classes={{ root: classes.listItem }}>
              <RoundedButton
                disabled={isSaving}
                color="secondary"
                variant="text"
              >
                Delete
              </RoundedButton>
            </ListItem>
          </List>
        </Grid>
        <Divider />
        <Grid item xs={12} md={8}>
          <div
            style={{
              paddingLeft: '16px',
              paddingRight: '16px',
              borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
              height: '100%',
            }}
          >
            <form onSubmit={handleSubmit(onSaveButtonPressed)}>
              {this.renderFormContent()}
            </form>
          </div>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { classes, onCancelButtonPressed, open } = this.props;
    return (
      <Dialog
        onEnter={this.initializeDialog}
        onClose={this.handleDialogClose}
        open={open}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              verticalAlign: 'baseline',
            }}
          >
            <Typography variant="title">Update User Account</Typography>
            <Typography variant="title">
              <IconButton
                onClick={onCancelButtonPressed}
                classes={{
                  root: classes.icon,
                }}
              >
                <Icon>close</Icon>
              </IconButton>
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>{this.renderDialogContent()}</DialogContent>
      </Dialog>
    );
  }
}

UpdateUserDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  userInfo: PropTypes.shape(PropTypes.any).isRequired,
  rxRole: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.any).isRequired,
  vendors: PropTypes.shape(PropTypes.any).isRequired,
  isSaving: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onSaveButtonPressed: PropTypes.func.isRequired,
  onCancelButtonPressed: PropTypes.func.isRequired,
};
UpdateUserDialog.defaultProps = {};

const UpdateUserDialogWithStylesTheme = withTheme()(
  withStyles(styles)(UpdateUserDialog),
);

export default reduxForm({ form: Constant.RXFORM_UPDATE_USER, enableReinitialize: true })(
  UpdateUserDialogWithStylesTheme,
);
