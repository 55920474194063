import * as Helper from '../../../helper';
import * as Action from '../simple-action';

export default () => async (dispatch, getState) => {
  const { authentication, uiForm } = getState();
  const { token } = authentication;
  const {
    selectedFormId,
  } = uiForm;

  try {
    dispatch(Action.downloadingForm(true));
    const form = await Helper.downloadFormDetail(token, selectedFormId);
    dispatch(Action.addForm(form));
  } finally {
    dispatch(Action.downloadingForm(false));
  }
};
