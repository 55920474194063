import { connect } from 'react-redux';
import BookNewDialog from './book-new-dialog.presentation';

const mapStateToProps = state => ({
  initialValues: {},
  error: state.uiBookExport.error,
  selectedTab: state.uiBookExport.activeForm,
  uploadingNewBooking: state.uiBookExport.booking,
  loading: state.uiBookExport.downloading,

});

const mapDispatchToProps = () => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BookNewDialog);
