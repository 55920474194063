import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import FormDetailDialog from './form-detail-dialog.presentation';
import {
  downloadFormDetailAsync,
  showErrorSnackbar,
} from '../../redux/action';
import LocalizedString from '../../localization';

const getUserDetail = (state) => {
  const {
    forms, uiForm,
  } = state;
  const { data } = forms;
  const { selectedFormId } = uiForm;

  const formDetail = data[selectedFormId] || {};

  if (formDetail) {
    return {
      order: formDetail.order,
      title: formDetail.title,
      description: formDetail.description,
      status: formDetail.status,
    };
  }

  return formDetail;
};

const mapStateToProps = state => ({
  initialValues: getUserDetail(state),
  loading: false,
});

const mapDispatchToProps = dispatch => ({
  onAppear: async () => {
    try {
      await dispatch(downloadFormDetailAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    }
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormDetailDialog));
