import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { change } from 'redux-form';
import _, { isEmpty } from 'lodash';
import FieldAddDialog from './field-add-dialog.presentation';
import {
  showErrorSnackbar,
  setFieldAddDialogStatus,
  uploadNewFieldAsync,
  downloadAllFieldAsync,
  setFormFilter,
  downloadAllFormAsync,
  setFieldSort,
} from '../../redux/action';
import {
  RXFORM_FIELD_ADD,
  TABLE_SORT_ASCENDING,
  RXFIELD_FIELD_FORM,
  RXFIELD_FIELD_REQUIRED,
  RXFIELD_FIELD_TYPE,
  RXFIELD_FIELD_STATUS,
} from '../../constant';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const getFilteredForms = (state) => {
  const filteredForms = _.values(state.forms.data)
    .map((item, index) => ({ ...item, index: index + 1 }));

  const sorted = filteredForms
    .map(x => ({
      label: x.title,
      value: x.id,
    }))
    .sort((left, right) => (left.label.toLowerCase().localeCompare(right.label.toLowerCase())));

  return sorted;
};

const getPageFilteredForms = (state) => {
  const { filters } = state.uiForm;
  const { formName } = filters;
  const filteredForm = getFilteredForms(state);

  if (!isEmpty(filteredForm)) {
    if (formName !== '') {
      const form = filteredForm.filter(
        x => (x.label ? x.label.toLowerCase().includes(formName.toLowerCase()) : ''),
      );
      return form;
    }
    return filteredForm;
  }

  return [];
};

const mapStateToProps = state => ({
  loading: state.uiField.uploading,
  downloadingForm: state.uiForm.downloading,

  forms: getPageFilteredForms(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    try {
      await dispatch(downloadAllFormAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    }
  },
  onSubmitPressed: async (values) => {
    try {
      await dispatch(uploadNewFieldAsync(values));
      dispatch(setFieldSort('order', TABLE_SORT_ASCENDING));
      await dispatch(downloadAllFieldAsync());
      dispatch(setFieldAddDialogStatus(false));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onFormTextChanged: async (text) => {
    dispatch(setFormFilter(RXFIELD_FIELD_FORM, text));
  },
  onFormSelected: (value) => {
    dispatch(change(RXFORM_FIELD_ADD, RXFIELD_FIELD_FORM, value));
  },
  onRequiredSelected: (option) => {
    dispatch(change(RXFORM_FIELD_ADD, RXFIELD_FIELD_REQUIRED, option));
  },
  onTypeSelected: (option) => {
    dispatch(change(RXFORM_FIELD_ADD, RXFIELD_FIELD_TYPE, option));
  },
  onStatusSelected: (option) => {
    dispatch(change(RXFORM_FIELD_ADD, RXFIELD_FIELD_STATUS, option));
  },
  onClose: () => {
    dispatch(setFieldAddDialogStatus(false));
    dispatch(setFormFilter(RXFIELD_FIELD_FORM, ''));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FieldAddDialog));
