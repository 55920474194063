import { connect } from 'react-redux';
import _ from 'lodash';
import AssignContainerDialog from './assign-container-dialog.presentation';

const getSelectedAssignBl = state => _.values(state.assign)
  .find(item => item.blNo === state.uiAssignOrderType.tappedBlNo
    && item.exim === state.uiAssign.tappedService);

const mapStateToProps = state => ({
  downloading: state.uiAssignContainer.downloading
    || state.uiAssignTruck.searchingVendorList
    || state.uiAssignTruck.searchingTruckList,
  selectedTab: state.uiAssignContainer.activeForm,

  selectedAssignBl: getSelectedAssignBl(state) || null,
});

export default connect(mapStateToProps, {})(AssignContainerDialog);
