import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  Typography,
  Icon,
} from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import { LoadingIndicator } from '../../component';
import LocalizedString from '../../localization';
import {
  DEFAULT_STYLES,
  RXFIELD_FORM_ORDER,
  RXFIELD_FORM_TITLE,
  RXFIELD_FORM_DESCRIPTION,
  RXFIELD_FORM_STATUS,
  status,
  RXFORM_FORM,
} from '../../constant';
import {
  renderReduxFormTextField,
  renderReduxFormTextArea,
} from '../../helper';

const styles = () => ({
  btnPrimary: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  dialogActions: {
    margin: '0 -24px',
    marginTop: '24px',
    padding: 24,
    borderTop: '1px solid #C4C4C4',
    justifyContent: 'space-between',
    paddingBottom: 0,
    display: 'flex',
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
});

const FormDetailDialog = ({
  open,
  loading,
  currentUserRole,

  onAppear,
  onClose,
  onStatusSelected,
}) => (
  <>
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onEnter={() => onAppear(currentUserRole)}
    >
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <Typography color="primary" variant="h6">
            {LocalizedString.form.detailTite}
          </Typography>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <Field
          name={RXFIELD_FORM_ORDER}
          component={renderReduxFormTextField}
          label={LocalizedString.form.labelOrder}
          required
          type="number"
          disabled
        />
        <Field
          name={RXFIELD_FORM_TITLE}
          component={renderReduxFormTextField}
          label={LocalizedString.form.labelTitle}
          required
          disabled
        />
        <Field
          name={RXFIELD_FORM_DESCRIPTION}
          component={renderReduxFormTextArea}
          label={LocalizedString.form.labelDescription}
          required
          disabled
        />
        <Field
          name={RXFIELD_FORM_STATUS}
          component={renderReduxFormTextField}
          options={status}
          label={LocalizedString.form.labelStatus}
          onValueChange={onStatusSelected}
          disabled
        />
      </DialogContent>
    </Dialog>
    {loading && <LoadingIndicator />}
  </>
);
FormDetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,

  onSubmitPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default reduxForm({
  form: RXFORM_FORM,
  enableReinitialize: true,
})(withStyles(styles)(FormDetailDialog));
