import * as Helper from '../../../helper';
import { downloadingContainerTrackingsData, addContainerTrackingDetail } from '../simple-action';


export default blNumber => async (dispatch, getState) => {
  try {
    dispatch(downloadingContainerTrackingsData(true));
    const { token } = getState().authentication;
    // const blNumber = getState().uiContainerTracking.searchBarText;
    const containerTrackings = await Helper.downloadContainerTrackingDetail(blNumber, token);

    if (containerTrackings) {
      dispatch(addContainerTrackingDetail(containerTrackings));
    }
  } finally {
    dispatch(downloadingContainerTrackingsData(false));
  }
};
