import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PaymentDetailDialog from './payment-detail-dialog.presentation';
import { setAdminPaymentHistoryDetailFilter, setAdminPaymentHistoryDetailPaging, setAdminPaymentHistoryDetailSort } from '../../redux/action';
import { TABLE_SORT_ASCENDING } from '../../constant';

const getFilteredPaymentDetail = (state) => {
  const { paymentInfo } = state.adminPaymentHistory;
  const { tappedItemNo } = state.uiAdminPaymentHistory;
  const { sortField, sortDirection, filters } = state.uiAdminPaymentHistoryDetail;

  if (!tappedItemNo) return [];

  if (paymentInfo && paymentInfo[tappedItemNo]) {
    const { items } = paymentInfo[tappedItemNo];

    // because everything is string filter, let's use one filter function
    return items.sort((left, right) => {
      if (typeof left[sortField] === 'string') {
        if (sortDirection === TABLE_SORT_ASCENDING) {
          return left[sortField].localeCompare(right[sortField]);
        }
        return right[sortField].localeCompare(left[sortField]);
      }

      if (sortDirection === TABLE_SORT_ASCENDING) {
        return left[sortField] - right[sortField];
      }
      return right[sortField] - left[sortField];
    })
      .filter((item) => {
        let valid = true;

        Object.keys(filters).forEach((key) => {
          valid = valid && (item[key] || '-').toString().toLowerCase().indexOf(filters[key].toLowerCase()) >= 0;
        });

        return valid;
      });
  }

  return [];
};

const getPageFilteredPaymentDetail = (state) => {
  const allItems = getFilteredPaymentDetail(state);
  const { activePage, pageSize } = state.uiAdminPaymentHistoryDetail;

  const refinedActivePage = activePage || 1;
  const start = Math.min((refinedActivePage - 1) * pageSize, allItems.length);
  const end = Math.min(start + pageSize, allItems.length);
  return allItems.slice(start, end);
};

const getTotalPage = (state) => {
  const { pageSize } = state.uiAdminPaymentHistory;
  const totalData = getFilteredPaymentDetail(state).length;

  return Math.max(Math.ceil(totalData / pageSize), 1);
};

const mapStateToProps = state => ({
  paymentDetail: getPageFilteredPaymentDetail(state),

  pageSize: state.uiAdminPaymentHistoryDetail.pageSize,
  totalPage: getTotalPage(state),
  activePage: state.uiAdminPaymentHistoryDetail.activePage,
  filters: state.uiAdminPaymentHistoryDetail.filters,
  sortField: state.uiAdminPaymentHistoryDetail.sortField,
  sortDirection: state.uiAdminPaymentHistoryDetail.sortDirection,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChangeFilter: (filterName, value) => {
    dispatch(setAdminPaymentHistoryDetailFilter(filterName, value));
  },
  onChangePaging: (pageField, value) => {
    dispatch(setAdminPaymentHistoryDetailPaging(pageField, value));
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setAdminPaymentHistoryDetailSort(sortField, sortDirection));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentDetailDialog));
