import React from 'react';
import { MainMenu } from '../component';
import LocalizedString from '../localization';

export default () => (
  <div>
    <MainMenu />
    <div>
      <h2>{LocalizedString.homeScreen.title}</h2>
    </div>

  </div>
);
