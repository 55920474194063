import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Card,
  Grid,
  CircularProgress,
  CardContent,
  Button,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { Field, reduxForm } from 'redux-form';
import {
  FIELD_TYPE_DATE,
  FIELD_TYPE_FILE,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_TEXT,
  FILE_EXTENSION_DOCUMENT,
  FILE_EXTENSION_PDF,
  FILE_EXTENSION_SPREADSHEET,
  MOMENT_DATE_API_FORMAT_STRING,
  RXFIELD_NEW_DOCUMENT_PAYMENT_ON_BEHALF,
  RXFORM_NEW_DOCUMENT_MY_FORM,
} from '../../constant';
import {
  renderReduxFormDateField, renderReduxFormFilePickerField,
  renderReduxFormFilterableSelectField, renderReduxFormTextField,
} from '../../helper';
import * as Validation from '../../validation';
import LocalizedString from '../../localization';

const styles = theme => ({
  cardBlDataHeader: {
    marginBottom: theme.spacing.unit * 2,
  },
  circularProgressContainer: {
    padding: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circularProgress: {
    height: '28px !important',
    width: '28px !important',
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '40px',
    marginBottom: '20px',
  },
  submitButton: {
    padding: '12px 48px',
    backgroundColor: '#50abf1',
  },
  notFound: {
    color: '#8C8C8C',
    textAlign: 'center',
  },
});

const NewDocumentDetail = ({
  classes,
  tappedId,
  myForm,
  fieldValue,
  paymenOnBehalfList,
  downloadingMyFormDetail,
  downloadingPaymentOnBehalfList,
  uploadingResponse,
  onAppear,
  onFieldChange,
  onImageChanged,
  onSubmitButtonPressed,
  handleSubmit,
}) => {
  useEffect(() => {
    onAppear(tappedId);
  }, [tappedId]);

  const renderField = (field, formKey) => {
    switch (field.type) {
      case FIELD_TYPE_TEXT:
      case FIELD_TYPE_NUMBER:
        return (
          <Field
            name={field.name}
            component={renderReduxFormTextField}
            label={field.label}
            type={field.type === FIELD_TYPE_NUMBER ? 'number' : 'text'}
            required={field.required}
            onChange={e => onFieldChange(e.target.value, '', field, fieldValue)}
            disabled={uploadingResponse}
          />
        );
      case FIELD_TYPE_DATE:
        return (
          <Field
            name={field.name}
            component={renderReduxFormDateField}
            label={field.label}
            required={field.required}
            keyboard
            onChange={
              (_, val) => onFieldChange(
                moment(val).format(MOMENT_DATE_API_FORMAT_STRING),
                '',
                field,
                fieldValue,
              )
            }
            disabled={uploadingResponse}
          />
        );
      case FIELD_TYPE_FILE:
        return (
          <div>
            <Field
              key={formKey}
              name={field.name}
              component={renderReduxFormFilePickerField}
              label={field.label}
              onFileSelected={
                (base64, fileName) => onFieldChange(base64, fileName, field, fieldValue)
              }
              acceptedFileExtension={`${FILE_EXTENSION_PDF},${FILE_EXTENSION_DOCUMENT},${FILE_EXTENSION_SPREADSHEET}`}
              required={field.required}
              helperText="File format: .pdf, .doc, .docx, .xls, .xlsx"
              onChange={e => onImageChanged(e.target.value, field.name, field.id, fieldValue)}
              style={{ marginTop: '18px' }}
              disabled={uploadingResponse}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const renderDynamicFields = (form) => {
    if (!form) {
      return (
        <Typography className={classes.notFound}>
          {LocalizedString.submitRequest.labelNoData}
        </Typography>
      );
    }

    return (
      <>
        <Grid item xs={12} md={12}>
          {form.fields.map(f => renderField(f, form.formKey))}
        </Grid>
        <Field
          name={RXFIELD_NEW_DOCUMENT_PAYMENT_ON_BEHALF}
          component={renderReduxFormFilterableSelectField}
          label={LocalizedString.submitRequest.labelPaymentOnBehalf}
          required
          options={paymenOnBehalfList}
        />
        <div className={classes.submitButtonContainer}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitButton}
            disabled={uploadingResponse}
          >
            {
              uploadingResponse ? (
                <CircularProgress className={classes.circularProgress} />
              ) : <>Submit</>
            }
          </Button>
        </div>
      </>
    );
  };

  if (!tappedId) {
    return null;
  }

  return (
    <>
      <Card className={classes.cardBlDataHeader}>
        {
          (downloadingMyFormDetail || downloadingPaymentOnBehalfList) ? (
            <div className={classes.circularProgressContainer}>
              <CircularProgress className={classes.circularProgress} />
            </div>
          ) : (
            <>
              <CardContent>
                <form
                  onSubmit={handleSubmit((values) => {
                    onSubmitButtonPressed(fieldValue, values.paymentOnBehalf, myForm);
                  })}
                >
                  {renderDynamicFields(myForm)}
                </form>
              </CardContent>
            </>
          )
        }
      </Card>
    </>
  );
};

NewDocumentDetail.propTypes = {
  tappedId: PropTypes.string.isRequired,
  downloadingMyFormDetail: PropTypes.bool.isRequired,
  uploadingResponse: PropTypes.bool.isRequired,
  downloadingPaymentOnBehalfList: PropTypes.bool.isRequired,
  myForm: PropTypes.object.isRequired,
  fieldValue: PropTypes.object.isRequired,
  paymenOnBehalfList: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAppear: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onImageChanged: PropTypes.func.isRequired,
  onSubmitButtonPressed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: RXFORM_NEW_DOCUMENT_MY_FORM,
  enableReinitialize: true,
  shouldValidate: () => true,
  validate: Validation.rxformValidateNewDocumentMyForm,
})(withStyles(styles)(NewDocumentDetail));
