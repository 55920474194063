import * as Helper from '../../../helper';
import * as Validation from '../../../validation';
import { uploadingForm } from '../simple-action';

export default values => async (dispatch, getState) => {
  try {
    dispatch(uploadingForm(true));

    Validation.validateNewForm(values);

    const { token } = getState().authentication;

    const body = {
      ...values,
      order: Helper.toNumber(values.order),
    };

    await Helper.uploadNewForm(body, token);
  } finally {
    dispatch(uploadingForm(false));
  }
};
