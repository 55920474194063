import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import UpdateUserDialog from './update-user-dialog.presentation';
import * as Action from '../../redux/action';
import * as Constant from '../../constant';

const mapStateToProps = (state) => {
  const selector = formValueSelector(Constant.RXFORM_UPDATE_USER);

  const selectedUser = state.uiUserManagement.updateDialogParam
    && state.uiUserManagement.updateDialogParam.userId
    ? state.users[state.uiUserManagement.updateDialogParam.userId]
    : {
      email: '',
      username: '',
      role: null,
      company: { id: null, name: '' },
      corporateAdmin: false,
      fullName: '',
      address: '',
    };

  return {
    isLoading: state.uiUserManagement.isDialogLoading,
    isSaving: state.uiUserManagement.isSaving,
    initialValues: {
      email: selectedUser.email,
      username: selectedUser.username,
      role: selectedUser.role,
      corporateAdmin: selectedUser.corporateAdmin,
      companyId: selectedUser.company && selectedUser.company.id,
      fullName: selectedUser.fullName,
      address: selectedUser.address,
    },
    userInfo: {
      registeredOn: selectedUser.registeredOn,
      registeredIp: selectedUser.registeredFrom,
      confirmedOn: selectedUser.approvedOn,
      enabled: selectedUser.enabled,
    },
    vendors: state.vendors,
    roles: [
      { label: 'Admin', value: 'ADMIN' },
      { label: 'Customer', value: 'CUSTOMER' },
      { label: 'PPJK', value: 'PPJK' },
      { label: 'CS', value: 'CS' },
    ],
    rxRole: selector(state, 'role'),
  };
};

const mapDispatchToProps = dispatch => ({
  onAppear: async () => {
    try {
      dispatch(Action.clearUIError(Constant.RXSTATE_USER_MANAGEMENT));
      await dispatch(Action.downloadAllVendorAsync());
    } catch (error) {
      dispatch(
        Action.setUIError(Constant.RXSTATE_USER_MANAGEMENT, error.message),
      );
    }
  },
  onSaveButtonPressed: async (values) => {
    try {
      const formValues = { ...values };
      if (formValues.roles === Constant.ROLE_ADMIN) {
        formValues.companyId = undefined;
      }

      dispatch(Action.clearUIError(Constant.RXSTATE_USER_MANAGEMENT));
      dispatch(Action.updateUserAsync(formValues));
    } catch (error) {
      dispatch(
        Action.setUIError(Constant.RXSTATE_USER_MANAGEMENT, error.message),
      );
    }
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UpdateUserDialog),
);
