import { downloadingPaymentOnBehalfList, addPaymentOnBehalfList } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  try {
    dispatch(downloadingPaymentOnBehalfList(true));
    const list = await Helper.downloadPaymentOnBehalfList(token);
    dispatch(addPaymentOnBehalfList(list));
  } finally {
    dispatch(downloadingPaymentOnBehalfList(false));
  }
};
