import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isEmpty } from 'lodash';
import FieldDetailDialog from './field-detail-dialog.presentation';
import {
  downloadFieldDetailAsync,
  showErrorSnackbar,
} from '../../redux/action';
import LocalizedString from '../../localization';
import { getDataType } from '../../helper';
import { BOOLEAN_OPTION } from '../../constant';

const getUserDetail = (state) => {
  const {
    fields, uiField,
  } = state;
  const { data } = fields;
  const { selectedFieldId } = uiField;

  const fieldDetail = data[selectedFieldId] || {};

  if (!isEmpty(fieldDetail)) {
    return {
      formName: fieldDetail.form.title,
      name: fieldDetail.name,
      label: fieldDetail.label,
      order: fieldDetail.order,
      required: fieldDetail.required ? BOOLEAN_OPTION[0].label : BOOLEAN_OPTION[1].label,
      type: getDataType(fieldDetail.type).label,
    };
  }

  return {};
};

const mapStateToProps = state => ({
  initialValues: getUserDetail(state),
  loading: false,
});

const mapDispatchToProps = dispatch => ({
  onAppear: async () => {
    try {
      await dispatch(downloadFieldDetailAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    }
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FieldDetailDialog));
