import { connect } from 'react-redux';
import PinVerification from './pin-verification.presentation';
import {
  clearUIError, setUIError, removeRegistrationInfo, addRegistrationInfo,
} from '../../redux/action';
import * as Validation from '../../validation';
import * as Constant from '../../constant';

const mapStateToProps = state => ({
  pin: state.registrationInfo.pin,
  pinExpirationDate: state.registrationInfo.pinExpirationDate,
});

const cancelPinVerification = (dispatch, props) => {
  dispatch(removeRegistrationInfo());
  dispatch(clearUIError(Constant.RXSTATE_REGISTRATION_PAGE));
  props.history.push(Constant.ROUTE_NAME_LOGIN);
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onNext: async (pin) => {
    try {
      dispatch(clearUIError(Constant.RXSTATE_REGISTRATION_PAGE));

      Validation.validatePin(pin);
      dispatch(addRegistrationInfo({ pin }));
      ownProps.history.push(Constant.ROUTE_NAME_REGISTRATION_INFO);
    } catch (error) {
      dispatch(setUIError(Constant.RXSTATE_REGISTRATION_PAGE, error.message));
    }
  },
  onCancel: () => {
    cancelPinVerification(dispatch, ownProps);
  },
  onPinExpired: () => {
    cancelPinVerification(dispatch, ownProps);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PinVerification);
