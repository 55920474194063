import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  withStyles, ButtonBase, Grid, Typography, Checkbox, FormControlLabel,
  Button, Dialog, DialogContent, DialogActions,
} from '@material-ui/core';
import LocalizedString from '../../localization';
import {
  MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING, PAYMENT_TYPE_TERM, CART_SERVICE_NAME_EXPORT, SERVICE_TYPE_PREDEFINED_ORDER,
} from '../../constant';
import { LoadingIndicator } from '../../component';
import { toCurrency, toMoment } from '../../helper';

const styles = theme => ({
  alignRight: {
    textAlign: 'right',
  },
  cardItem: {
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '6px',
    boxSizing: 'border-box',
    padding: '16px',
    marginBottom: theme.spacing.unit,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  cardItemActive: {
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  checkbox: {
    marginLeft: 0,
  },
  moreButton: {
    justifyContent: 'flex-end',
    paddingRight: 24,
    display: 'flex',
  },
  cardButtonContainer: {
    marginBottom: 8,
  },
  dialogButtonContainer: {
    justifyContent: 'center',
  },
});

const MAX_BL_NUMBER_LENGTH = 25;

const isAddedToCart = (blNo, shoppingCart) => {
  if (shoppingCart) {
    return shoppingCart.find(shopItem => shopItem.blNumber === blNo
      && shopItem.service === SERVICE_TYPE_PREDEFINED_ORDER);
  }

  return null;
};


const OrderCard = ({
  classes,
  item,
  selectedBlNo,
  userCompany,
  shoppingCartItems,
  downloadingProforma,
  onAddToCartPressed,
  addingItemId,
  addingItemToCart,
  onSecondaryButtonPressed,
}) => {
  const [checkedItems, setCheckedItems] = useState([]);
  const [dialogVisibility, setDialogVisibility] = useState(false);

  let data = [];
  const isTerm = userCompany.paymentMethod === PAYMENT_TYPE_TERM;

  const isShippingLine = item.typeCust === 'SL';

  const blValueLength = item.blHeader.length;
  const shortBlNumber = (blValueLength && blValueLength > MAX_BL_NUMBER_LENGTH)
    ? `${item.blHeader.substring(0, MAX_BL_NUMBER_LENGTH)}...`
    : item.blHeader;

  const foundOnCart = isAddedToCart(item.blHeader, shoppingCartItems);

  useEffect(() => {
    if (foundOnCart && foundOnCart.pph) {
      setCheckedItems([...checkedItems, item.blHeader]);
    }
  }, [foundOnCart]);

  const secondaryButtonCaption = foundOnCart
    ? LocalizedString.common.buttonCaptionOpenCart
    : LocalizedString.common.buttonCaptionAddToCart;

  const secondaryButtonLoading = addingItemToCart
  && (addingItemId === item.invoiceNo || addingItemId === item.blHeader);

  data = [(isShippingLine || isTerm)
    ? LocalizedString.myPaymentOrder.buttonCaptionInvoicePDF
    : LocalizedString.myPaymentOrder.buttonCaptionViewProforma];

  if (isTerm && item.serviceType === CART_SERVICE_NAME_EXPORT) {
    data = [...data, LocalizedString.myPaymentOrder.buttonCaptionSP2];
  }
  if (isTerm && item.serviceType === CART_SERVICE_NAME_EXPORT) {
    data = [...data, LocalizedString.myPaymentOrder.buttonCaptionKMT];
  }

  const isChecked = itemId => !!checkedItems.filter(x => x === itemId).length;

  const toggleChecked = (itemId) => {
    if (isChecked(itemId)) {
      setCheckedItems([checkedItems.filter(id => id !== itemId)]);
    } else {
      setCheckedItems([...checkedItems, itemId]);
    }
  };

  const renderActionCard = () => (
    <Dialog open={dialogVisibility}>
      <DialogContent>
        {data.map(option => (
          <Button
            variant="text"
            color="primary"
            key={option}
            onClick={() => {
              setDialogVisibility(!dialogVisibility);
              onSecondaryButtonPressed(option, item);
            }}
          >
            {option.label || option.value ? option.label : option}
          </Button>
        ))}
      </DialogContent>

      <DialogActions className={classes.dialogButtonContainer}>
        <Button
          variant="text"
          color="primary"
          onClick={() => setDialogVisibility(!dialogVisibility)}
        >
          {LocalizedString.common.buttonCaptionCancel}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <ButtonBase
      key={item.blHeader}
      component="div"
      className={classNames(classes.cardItem, {
        [classes.cardItemActive]: selectedBlNo
        && selectedBlNo.toLowerCase() === item.blHeader.toLowerCase(),
      })}
    >
      {renderActionCard()}

      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Typography color="primary" variant="h6">
            {LocalizedString.myPaymentOrder.labelBlNo}
            &nbsp;
            {shortBlNumber}
          </Typography>
        </Grid>

        <Grid item xs={6} md={6}>
          <Typography variant="caption">
            {(isShippingLine || isTerm)
              ? LocalizedString.myPaymentOrder.labelInvoiceNo
              : LocalizedString.myPaymentOrder.labelProformaNo}
          </Typography>
          <Typography>
            {item.invoiceNo}
          </Typography>
        </Grid>
        <Grid item xs={6} md={6} className={classes.alignRight}>
          <Typography variant="caption">
            {LocalizedString.myPaymentOrder.labelAmount}
          </Typography>
          <Typography>
            {item.totalAmount ? toCurrency(item.totalAmount) : '-'}
          </Typography>
        </Grid>

        <Grid item xs={6} md={6}>
          <Typography variant="caption">
            {LocalizedString.myPaymentOrder.labelInvoiceName}
          </Typography>
          <Typography>
            {item.billTo || '-'}
          </Typography>
        </Grid>
        <Grid item xs={6} md={6} className={classes.alignRight}>
          <Typography variant="caption">
            {LocalizedString.myPaymentOrder.labelService}
          </Typography>
          <Typography>
            {item.serviceType || '-'}
          </Typography>
        </Grid>

        <Grid item xs={6} md={6}>
          <Typography variant="caption">
            {LocalizedString.myPaymentOrder.labelCustomerType}
          </Typography>
          <Typography>
            {item.typeCust || '-'}
          </Typography>
        </Grid>
        <Grid item xs={6} md={6} className={classes.alignRight}>
          <Typography variant="caption">
            {LocalizedString.myPaymentOrder.labelCreatedOn}
          </Typography>
          <Typography>
            {item.createdOn ? toMoment(item.createdOn).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING) : '-'}
          </Typography>
        </Grid>

        <FormControlLabel
          label={LocalizedString.myPaymentOrder.labelReimbursementPph}
          className={classes.checkbox}
          disabled={!!foundOnCart}
          control={(
            <Checkbox
              color="primary"
              checked={foundOnCart}
              onClick={() => toggleChecked(item.blHeader)}
            />)}
        />

        <Grid container justify="center" className={classes.cardButtonContainer}>
          <Grid item xs={6} md={6} className={classes.moreButton}>
            <Button
              color="primary"
              onClick={() => setDialogVisibility(!dialogVisibility)}
              disabled={secondaryButtonLoading || downloadingProforma}
            >
              {LocalizedString.common.buttonCaptionMore}
            </Button>
          </Grid>

          <Grid item xs={6} md={6}>
            {secondaryButtonLoading ? (<LoadingIndicator />) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => onAddToCartPressed(item, foundOnCart,
                  isChecked(item.blHeader))}
                disabled={downloadingProforma}
              >
                {secondaryButtonCaption}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </ButtonBase>
  );
};


OrderCard.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  item: PropTypes.shape(PropTypes.any).isRequired,
  selectedBlNo: PropTypes.string.isRequired,
  userCompany: PropTypes.shape(PropTypes.any).isRequired,
  shoppingCartItems: PropTypes.shape(PropTypes.any).isRequired,
  addingItemId: PropTypes.string.isRequired,
  addingItemToCart: PropTypes.bool.isRequired,
  downloadingProforma: PropTypes.bool.isRequired,
  onAddToCartPressed: PropTypes.func.isRequired,
  onSecondaryButtonPressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(OrderCard);
