import { downloadingMyPaymentOutstandingOrder, addMyPaymentOutstandingOrder } from '../simple-action';
import * as Helper from '../../../helper';
import * as Constant from '../../../constant';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { company } = getState().currentUser;

  try {
    dispatch(downloadingMyPaymentOutstandingOrder(true));
    const myOutstandingOrder = await Helper.downloadMyOutstandingPayment(token);

    if (company.paymentMethod === Constant.PAYMENT_TYPE_TERM && !company.staticVa) {
      const myOutstandingTermOrder = await Helper.downloadMyOutstandingTermPayment(token);
      dispatch(addMyPaymentOutstandingOrder({ ...myOutstandingOrder, ...myOutstandingTermOrder }));
    } else {
      dispatch(addMyPaymentOutstandingOrder(myOutstandingOrder));
    }
  } finally {
    dispatch(downloadingMyPaymentOutstandingOrder(false));
  }
};
