import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  CssBaseline,
  Grid,
  Card,
  CardContent,
  Fab,
  Icon,
  Typography,
  withStyles,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Input,
  CardHeader,
  Select,
  MenuItem,
  CardActions,
} from '@material-ui/core';
import * as Constant from '../../constant';
import { getSortIcon, isSortFieldActive, toCurrency } from '../../helper';
import LocalizedString from '../../localization';
import { PageHeaderUser, LoadingIndicator } from '../../component';
import TruckingDetailDialog from './trucking-detail-dialog.container';
import BookNewDialog from './book-new-dialog.container';
import PasswordInputDialog from './password-input-dialog.presentation';
import TruckingBlCard from './bl-card.container';

const styles = theme => ({
  backIcon: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  btnNewBooking: {
    backgroundColor: '#5CB860',
    color: '#ffffff',
    width: '256px',
    '&:hover': {
      backgroundColor: '#558b2f',
    },
  },
  btnAddToCart: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
    width: '256px',
  },
  detailButton: {
    backgroundColor: '#ffffff',
  },
  grow: {
    flexGrow: 1,
  },
  searchButton: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  moreButton: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  cardActionPrimary: {
    display: 'flex',
    alignItems: 'baseline',
    padding: 16,
    justifyContent: 'space-between',
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  cardBlDataHeader: {
    marginBottom: theme.spacing.unit * 2,
  },
  cardItem: {
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '6px',
    boxSizing: 'border-box',
    padding: '16px',
    marginBottom: theme.spacing.unit,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  cardItemActive: {
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  cardItemContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: theme.spacing.unit * 2,
    maxBlockSize: '500px',
    height: '500px',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${Constant.DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  detailHeader: {
    color: '#0066CB',
  },
  itemPerRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    color: '#0266B4',
  },
  root: {
    width: '100%',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  textJumlahPembayaran: {
    textTransform: 'uppercase',
  },
  textTotalPrice: {
    fontSize: '22px',
    fontWeight: 500,
    color: '#0065B3',
    marginRight: theme.spacing.unit * 2,
  },
});

const isAddedToCart = (blNo, shoppingCart) => {
  if (shoppingCart) {
    return Boolean(shoppingCart
      .find(shopItem => shopItem.blNumber.toUpperCase() === blNo.toUpperCase()
      && shopItem.service === Constant.CART_SERVICE_NAME_TRUCKING));
  }

  return false;
};

const isAddedToTerm = (blNo, termOrder) => {
  if (termOrder) {
    return Boolean(termOrder.find(order => order.blNumber === blNo
      && order.service === Constant.CART_SERVICE_NAME_EXPORT));
  }

  return false;
};

const renderBlDetail = (
  classes, selectedBlInfo, feederServiceDetail,
  filters, sortField, sortDirection,
  pageSize, activePage, totalPage,
  handleChangeSort, onChangePaging, onChangeFilter,
  onDetailPressed,
) => {
  if (!selectedBlInfo) {
    return (<></>);
  }

  return (
    <Card className={classes.cardBlDataHeader}>
      <CardHeader
        title={(
          <>
            <Typography variant="h6" className={classes.detailHeader}>
              Detail
            </Typography>
            <div className={classes.itemPerRowContainer}>
              <Select
                value={pageSize}
                onChange={event => onChangePaging(
                  Constant.RXTABLEFIELD_PAGE_SIZE,
                  event.target.value,
                )}
              >
                {Constant.DEFAULT_PAGE_SIZE_OPTIONS.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                    &nbsp;
                    {LocalizedString.common.labelRows}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </>
        )}
      />
      <CardContent>
        <div style={{ width: '100%', overflow: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={
                      isSortFieldActive(sortField, Constant.TABLE_FIELD_CONTAINER_NUMBER)
                    }
                    IconComponent={getSortIcon(
                      isSortFieldActive(sortField, Constant.TABLE_FIELD_CONTAINER_NUMBER),
                      sortDirection,
                    )}
                    onClick={
                      () => handleChangeSort(Constant.TABLE_FIELD_CONTAINER_NUMBER)
                    }
                  >
                    {LocalizedString.trucking.lblContainerNumber}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={isSortFieldActive(sortField, Constant.TABLE_FIELD_SEAL_NUMBER)}
                    IconComponent={getSortIcon(
                      isSortFieldActive(sortField, Constant.TABLE_FIELD_SEAL_NUMBER),
                      sortDirection,
                    )}
                    onClick={
                      () => handleChangeSort(Constant.TABLE_FIELD_SEAL_NUMBER)
                    }
                  >
                    {LocalizedString.trucking.lblESil}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={isSortFieldActive(sortField, Constant.TABLE_FIELD_STATUS_HAULAGE)}
                    IconComponent={getSortIcon(
                      isSortFieldActive(sortField, Constant.TABLE_FIELD_STATUS_HAULAGE),
                      sortDirection,
                    )}
                    onClick={
                      () => handleChangeSort(Constant.TABLE_FIELD_STATUS_HAULAGE)
                    }
                  >
                    {LocalizedString.trucking.lblContainerTransportStatus}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  Action(s)
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Input
                    type="text"
                    fullWidth
                    value={filters[Constant.TABLE_FIELD_CONTAINER_NUMBER]}
                    onChange={
                      ({ nativeEvent }) => onChangeFilter(
                        Constant.TABLE_FIELD_CONTAINER_NUMBER,
                        nativeEvent.target.value,
                      )
                    }
                    placeholder={LocalizedString.common.placeholderSearch}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    type="text"
                    fullWidth
                    value={filters[Constant.TABLE_FIELD_SEAL_NUMBER]}
                    onChange={
                      ({ nativeEvent }) => onChangeFilter(
                        Constant.TABLE_FIELD_SEAL_NUMBER,
                        nativeEvent.target.value,
                      )
                    }
                    placeholder={LocalizedString.common.placeholderSearch}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    type="text"
                    fullWidth
                    value={filters[Constant.TABLE_FIELD_STATUS_HAULAGE]}
                    onChange={
                      ({ nativeEvent }) => onChangeFilter(
                        Constant.TABLE_FIELD_STATUS_HAULAGE,
                        nativeEvent.target.value,
                      )
                    }
                    placeholder={LocalizedString.common.placeholderSearch}
                  />
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {feederServiceDetail && feederServiceDetail.map(container => (
                <TableRow key={container.id} className={classes.tableRow}>
                  <TableCell>
                    {container.containerNo}
                  </TableCell>
                  <TableCell>
                    {container.sealNo}
                  </TableCell>
                  <TableCell>
                    {container.statusHaulage}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="primary"
                      classes={{ outlinedPrimary: classes.detailButton }}
                      onClick={() => onDetailPressed(container.containerNo)}
                    >
                      Detail
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <div className={classes.tablePagination}>
          <Button
            variant="contained"
            style={{ flexGrow: 1 }}
            disabled={activePage === 1}
            onClick={() => onChangePaging(Constant.RXTABLEFIELD_ACTIVE_PAGE, activePage - 1)}
          >
            {LocalizedString.common.labelPagingPrevButton}
          </Button>

          <div className={classes.tablePaginationPageInput}>
            <span style={{ fontWeight: 500 }}>{LocalizedString.common.labelPagingPage}</span>
            <Input
              type="number"
              value={activePage}
              style={{ width: '50%' }}
              inputProps={{
                min: 1,
                max: totalPage,
                step: 1,
                style: { textAlign: 'center' },
              }}
              onChange={({ nativeEvent }) => onChangePaging(
                Constant.RXTABLEFIELD_ACTIVE_PAGE,
                parseInt(nativeEvent.target.value, 10),
              )}
            />
            <span style={{ fontWeight: 500 }}>
              {`${LocalizedString.common.labelPagingPageOf} ${totalPage}`}
            </span>
          </div>

          <Button
            variant="contained"
            style={{ flexGrow: 1 }}
            disabled={activePage === totalPage}
            onClick={() => onChangePaging(Constant.RXTABLEFIELD_ACTIVE_PAGE, activePage + 1)}
          >
            {LocalizedString.common.labelPagingNextButton}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

class TruckingBase extends Component {
  componentDidMount() {
    this.props.onAppear();
  }

  handleChangeSort = (newSortField) => {
    const { sortField, sortDirection, onChangeSort } = this.props;
    if (newSortField === sortField) {
      onChangeSort(
        sortField,
        sortDirection === Constant.TABLE_SORT_ASCENDING
          ? Constant.TABLE_SORT_DESCENDING
          : Constant.TABLE_SORT_ASCENDING,
      );
    } else {
      onChangeSort(newSortField, Constant.TABLE_SORT_ASCENDING);
    }
  };

  getTotalPrice = (blInfo) => {
    const { shoppingCart } = this.props;
    const itemCart = shoppingCart.find(shopItem => shopItem.blNumber
      .toUpperCase() === blInfo.refNo.toUpperCase()
      && shopItem.service === Constant.CART_SERVICE_NAME_TRUCKING);
    return (itemCart && itemCart.amount) || blInfo.totalPrice;
  };

  renderAddToCartButton = (buttonDisabled) => {
    const {
      classes, selectedBlInfo, shoppingCart, addingShoppingCartItem, onAddToCartPressed,
    } = this.props;
    if (isAddedToCart(selectedBlInfo.refNo, shoppingCart)) {
      return (
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.btnAddToCart}
          disabled
        >
          {LocalizedString.trucking.btnAlreadyInCart}
        </Button>
      );
    }

    return (
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.btnAddToCart}
        disabled={addingShoppingCartItem || buttonDisabled}
        onClick={() => onAddToCartPressed(selectedBlInfo)}
      >
        {LocalizedString.trucking.btnAddToCart}
      </Button>
    );
  };

  renderAddToTermButton = (buttonDisabled) => {
    const {
      classes, selectedBlInfo, termOrder, addingShoppingCartItem, onAddToTermPressed,
    } = this.props;
    if (isAddedToTerm(selectedBlInfo.refNo, termOrder)) {
      return (
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.btnAddToCart}
          disabled
        >
          {LocalizedString.tariffSimulation.btnAlreadyInTerm}
        </Button>
      );
    }

    return (
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.btnAddToCart}
        disabled={addingShoppingCartItem || buttonDisabled}
        onClick={() => onAddToTermPressed(selectedBlInfo)}
      >
        {LocalizedString.tariffSimulation.btnAddToTerm}
      </Button>
    );
  };

  renderAddToButton = (buttonDisabled) => {
    const { userCompany, selectedBlInfo } = this.props;
    return userCompany.paymentMethod === Constant.PAYMENT_TYPE_TERM
      || selectedBlInfo.billToPaymentMethod === Constant.PAYMENT_TYPE_TERM
      ? this.renderAddToTermButton(buttonDisabled)
      : this.renderAddToCartButton(buttonDisabled);
  };

  renderBlHeader = () => {
    const {
      classes, selectedBlInfo,
    } = this.props;
    if (selectedBlInfo) {
      const disableAddToButton = !!selectedBlInfo.invoiceNo
        || selectedBlInfo.bookingStatus === Constant.BOOK_TRUCKING_STATUS_BOOKED
        || selectedBlInfo.bookingStatus === Constant.BOOK_TRUCKING_STATUS_REJECTED;

      return (
        <Card className={classes.cardBlDataHeader}>
          <CardHeader
            title={(
              <>
                <Typography variant="h6" className={classes.detailHeader}>
                  {LocalizedString.trucking.lblBlNo}
                  &nbsp;
                  {selectedBlInfo.refNo}
                </Typography>
              </>
            )}
          />
          <CardActions className={classes.cardActionPrimary}>
            <Typography variant="body1" className={classes.textJumlahPembayaran}>{LocalizedString.trucking.lblTotalPrice}</Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" className={classes.textTotalPrice}>
                Rp.&nbsp;
                {toCurrency(this.getTotalPrice(selectedBlInfo))}
              </Typography>
              {this.renderAddToButton(disableAddToButton)}
            </div>
          </CardActions>
        </Card>
      );
    }
    return null;
  };

  renderBlPriceHeader = () => {
    const {
      classes,
      selectedBlInfo,
      priceDetail,
    } = this.props;
  
    if (!selectedBlInfo) {
      return (<></>);
    }
  
    return (
      <Card className={classes.cardBlDataHeader}>
        <CardHeader
          title={(
            <>
              <Typography variant="h6" className={classes.detailHeader}>
                {LocalizedString.trucking.lblBlNo}
                &nbsp;
                {selectedBlInfo.refNo}
              </Typography>
            </>
          )}
        />
        <CardContent>
          <Grid container spacing={24}>
            <Grid item xs={12} md={4}>
              <Grid container spacing={0}>
                <Grid item xs={8} md={8}>
                  <Typography variant="caption">
                    {LocalizedString.priceDetail.lblTotalBeforeDiscount}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography variant="body1" align="right">
                    {toCurrency(priceDetail.totalGross)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={0}>
                <Grid item xs={8} md={8}>
                  <Typography variant="caption">
                    {LocalizedString.priceDetail.lblAdministration}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography variant="body1" align="right">
                    {toCurrency(priceDetail.adminFee)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={0}>
                <Grid item xs={8} md={8}>
                  <Typography variant="caption">
                    {LocalizedString.priceDetail.lblFatPPN}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography variant="body1" align="right">
                    {toCurrency(priceDetail.vat)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={0}>
                <Grid item xs={8} md={8}>
                  <Typography variant="caption">
                    {LocalizedString.priceDetail.lblDiscount}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography variant="body1" align="right">
                    {toCurrency(priceDetail.discount)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={0}>
                <Grid item xs={8} md={8}>
                  <Typography variant="caption">
                    {LocalizedString.priceDetail.lblTaxBasis}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography variant="body1" align="right">
                    {toCurrency(priceDetail.taxBasis)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActionPrimary}>
          <Typography variant="body1" className={classes.textJumlahPembayaran}>{LocalizedString.trucking.lblTotalPrice}</Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" className={classes.textTotalPrice}>
              Rp.&nbsp;
              {toCurrency(priceDetail.totalPayment)}
            </Typography>
          </div>
        </CardActions>
      </Card>
    );
  };
  
  renderBlPriceDetail = () => {
    const {
      classes,
      selectedBlInfo,
      priceDetail,
    } = this.props;
  
    if (!selectedBlInfo) {
      return (<></>);
    }
  
    return (
      <Card>
        <CardHeader title={(
          <>
            <Typography variant="h6" className={classes.detailHeader}>
              Detail
            </Typography>
          </>
        )}
        />
        <CardContent>
          <div style={{ width: '100%', overflow: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{LocalizedString.priceDetail.lblDescription}</TableCell>
                  <TableCell>{LocalizedString.priceDetail.lblDays}</TableCell>
                  <TableCell>{LocalizedString.priceDetail.lblPrice}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {(selectedBlInfo && priceDetail && priceDetail.items) && priceDetail.items.map(detail => (
                  <TableRow key={detail.index} className={classes.tableRow}>
                    <TableCell>
                      <Typography>
                        {detail.description}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography>
                        {detail.days}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography>
                        {toCurrency(detail.price)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    );
  };

  render() {
    const {
      classes,
      notificationOpen,
      downloadingFeederService,
      downloadingFeederServiceDetail,
      feederServiceDetail,
      searchBarText,
      selectedBlInfo,
      selectedContainerNo,
      activePage,
      totalPage,
      filters,
      sortField,
      sortDirection,
      pageSize,
      openDialogStatus,
      onChangeSearchBarText,
      onChangePaging,
      onChangeFilter,
      onDetailPressed,
      onCloseDetailDialogPressed,
      onCloseNewBookingDialogPressed,
      onAddNewBookingPressed,

      dialogPasswordInputStatus,
      password,
      dialogError,
      onCancelPasswordPressed,
      onSubmitPasswordPressed,
      onChangePasswordText,
    } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />

        <PageHeaderUser />

        <div
          className={
            classNames(classes.content, {
              [classes.contentDrawerOpen]: notificationOpen,
              [classes.contentDrawerClosed]: !notificationOpen,
            })
          }
          style={{ padding: '16px' }}
        >
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <div className={classes.pageNavigatorContainer}>
                    <Fab
                      variant="round"
                      classes={{ root: classes.backIcon }}
                      component={Link}
                      to={Constant.ROUTE_NAME_DASHBOARD_USER}
                    >
                      <Icon>chevron_left</Icon>
                    </Fab>
                    <div style={{ marginLeft: '32px' }}>
                      <Typography variant="h5" className={classes.pageTitle}>{LocalizedString.trucking.title}</Typography>
                      <Typography variant="caption">{LocalizedString.trucking.caption}</Typography>
                    </div>
                    <div className={classes.grow} />
                    <div>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        classes={{ containedSecondary: classes.btnNewBooking }}
                        onClick={onAddNewBookingPressed}
                      >
                        {LocalizedString.trucking.btnNewBooking}
                      </Button>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <div style={{ display: 'flex' }}>
                    <div style={{ flexGrow: 1, alignSelf: 'flex-end' }}>
                      <TextField
                        fullWidth
                        placeholder="Search BL no"
                        value={searchBarText}
                        onChange={event => onChangeSearchBarText(event.target.value)}
                      />
                    </div>
                    <div style={{ display: 'inline-flex', marginLeft: '8px' }}>
                      <Fab
                        size="small"
                        variant="round"
                        color="default"
                        className={classes.searchButton}
                      >
                        <Icon>search</Icon>
                      </Fab>
                    </div>
                  </div>

                  <div className={classes.cardItemContainer}>
                    <TruckingBlCard />
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              {this.renderBlHeader()}

              {renderBlDetail(
                classes, selectedBlInfo, feederServiceDetail,
                filters, sortField, sortDirection,
                pageSize, activePage, totalPage,
                this.handleChangeSort, onChangePaging, onChangeFilter,
                onDetailPressed,
              )}

              {this.renderBlPriceHeader()}
              
              {this.renderBlPriceDetail()}
            </Grid>
          </Grid>
        </div>

        {(downloadingFeederService || downloadingFeederServiceDetail) && <LoadingIndicator />}
        <TruckingDetailDialog
          open={Boolean(selectedContainerNo)}
          onClose={onCloseDetailDialogPressed}
        />

        <BookNewDialog
          open={openDialogStatus}
          onClose={onCloseNewBookingDialogPressed}
        />

        <PasswordInputDialog
          open={dialogPasswordInputStatus}
          password={password}
          error={dialogError}
          onCancelPressed={onCancelPasswordPressed}
          onSubmitPressed={() => onSubmitPasswordPressed(password, selectedBlInfo)}
          onChangePasswordText={onChangePasswordText}
        />
      </div>
    );
  }
}
TruckingBase.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  notificationOpen: PropTypes.bool,
  downloadingFeederService: PropTypes.bool.isRequired,
  downloadingFeederServiceDetail: PropTypes.bool.isRequired,
  userCompany: PropTypes.shape(PropTypes.any).isRequired,
  searchBarText: PropTypes.string.isRequired,
  selectedBlInfo: PropTypes.shape(PropTypes.any).isRequired,
  selectedContainerNo: PropTypes.string.isRequired,
  shoppingCart: PropTypes.arrayOf(PropTypes.any).isRequired,
  termOrder: PropTypes.arrayOf(PropTypes.any).isRequired,
  addingShoppingCartItem: PropTypes.bool.isRequired,
  feederServiceDetail: PropTypes.shape(PropTypes.any).isRequired,
  priceDetail: PropTypes.shape(PropTypes.any).isRequired,
  filters: PropTypes.shape(PropTypes.any).isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  activePage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  openDialogStatus: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onChangeSearchBarText: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onChangePaging: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onDetailPressed: PropTypes.func.isRequired,
  onCloseDetailDialogPressed: PropTypes.func.isRequired,
  onCloseNewBookingDialogPressed: PropTypes.func.isRequired,
  onAddNewBookingPressed: PropTypes.func.isRequired,
  onAddToCartPressed: PropTypes.func.isRequired,
  onAddToTermPressed: PropTypes.func.isRequired,

  // password dialog
  dialogPasswordInputStatus: PropTypes.bool.isRequired,
  password: PropTypes.string.isRequired,
  dialogError: PropTypes.string.isRequired,
  onCancelPasswordPressed: PropTypes.func.isRequired,
  onSubmitPasswordPressed: PropTypes.func.isRequired,
  onChangePasswordText: PropTypes.func.isRequired,
};
TruckingBase.defaultProps = {
  notificationOpen: false,
};

export default withStyles(styles)(TruckingBase);
