import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import FormDeleteDialog from './form-delete-dialog.presentation';
import {
  showErrorSnackbar,
} from '../../redux/action';
import LocalizedString from '../../localization';
import deleteFormAsync from '../../redux/action/async/deleteFormAsync';


const mapStateToProps = state => ({
  deleting: state.uiForm.deleting,
  selectedId: state.uiForm.selectedFormId,
});

const mapDispatchToProps = dispatch => ({
  onConfirmDeletePressed: async (userId) => {
    try {
      await dispatch(deleteFormAsync(userId));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    }
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormDeleteDialog));
