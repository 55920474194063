import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, withStyles } from '@material-ui/core';
import LocalizedString from '../../localization';

const styles = theme => ({
  dialogContent: {
    marginTop: '24px',
  },
  tabIndicator: {
    display: 'none',
  },
  tabRoot: {
    marginTop: theme.spacing.unit,
    overflow: 'visible',
  },
  tabElement: {
    backgroundColor: 'rgba(200, 200, 200, 0.2)',
    textTransform: 'capitalize',
  },
  tabElementActive: {
    backgroundColor: '#3f51b5',
    color: '#ffffff !important',
    transform: 'scaleY(1.1)',
    borderRadius: '4px',
    boxShadow: '0px 4px 20px rgba(0, 102, 203, 0.45)',
  },
  tabScroller: {
    overflowX: 'visible',
  },
});

const DepositHistoryTabs = ({
  classes,
  selectedTab,
  onTabPressed,
}) => (
  <Tabs
    value={selectedTab}
    indicatorColor="primary"
    textColor="primary"
    classes={{
      root: classes.tabRoot,
      indicator: classes.tabIndicator,
      scroller: classes.tabScroller,
    }}
    fullWidth
    centered
  >
    <Tab
      value={0}
      label={LocalizedString.depositHistory.labelHistoryTransaction}
      classes={{
        root: classes.tabElement,
        selected: classes.tabElementActive,
      }}
      onClick={() => onTabPressed(0)}
    />

    <Tab
      value={1}
      label={LocalizedString.depositHistory.labelMoneyLoanHistoryTransaction}
      classes={{
        root: classes.tabElement,
        selected: classes.tabElementActive,
      }}
      onClick={() => onTabPressed(1)}
    />
  </Tabs>
);
DepositHistoryTabs.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  selectedTab: PropTypes.number.isRequired,
  onTabPressed: PropTypes.func.isRequired,
};
export default withStyles(styles)(DepositHistoryTabs);
