import * as Action from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case Action.SET_FORM_RESPONSE_DETAIL:
      return action.data;
    default:
      return state;
  }
};
