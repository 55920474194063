import { connect } from 'react-redux';
import {
  clearUIError, setUIError, updatePinAsync,
} from '../../redux/action';
import * as Constant from '../../constant';
import ChangePassword from './change-pin.presentation';

const mapStateToProps = state => ({
  updatingPin: state.uiProfile.updatingPin,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onUpdatePinPressed: async (values) => {
    try {
      dispatch(clearUIError(Constant.RXSTATE_PROFILE_PAGE));

      await dispatch(updatePinAsync(
        values.oldPin,
        values.newPin,
        ownProps.history.push,
      ));
    } catch (error) {
      dispatch(setUIError(Constant.RXSTATE_PROFILE_PAGE, error.message));
    }
  },
  onCancel: () => {
    ownProps.history.push(Constant.ROUTE_NAME_PROFILE);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
