import { downloadingErpPaymentLogs, addErpPaymentLogs } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const {
    proformaNoFilterValue,
    selectedStatus,
    activePage,
    pageSize,
  } = getState().uiErpPaymentLog;

  try {
    dispatch(downloadingErpPaymentLogs(true));
    const erpPaymentLogs = await Helper.downloadErpPaymentLogs(
      proformaNoFilterValue, selectedStatus, activePage, pageSize, token,
    );
    dispatch(addErpPaymentLogs(erpPaymentLogs));
  } finally {
    dispatch(downloadingErpPaymentLogs(false));
  }
};
