import downloadFeederServiceBLListAsync from './downloadFeederServiceBLListAsync';
import {
  addTruckingFeederDoNo,
  addTruckingFeederDoDate,
  addTruckingFeederBlNo,
  setBookFeederActiveForm,
  setNewBookingDialogStatus,
  setGlobalLoading,
  addTruckingFeederBillTo,
} from '../simple-action';
import { ROUTE_NAME_TRUCKING, ROLE_PPJK } from '../../../constant';
import downloadFeederServiceWarehouseListAsync from './downloadFeederServiceWarehouseListAsync';

export default (blNo, router) => async (dispatch, getState) => {
  try {
    dispatch(setGlobalLoading(true));
    await dispatch(downloadFeederServiceBLListAsync());

    const { feederServiceBLList, currentUser } = getState();
    const blItem = Object.values(feederServiceBLList).find(x => x.blNo === blNo);

    dispatch(addTruckingFeederBlNo(blNo));
    dispatch(addTruckingFeederDoNo(blItem && blItem.doNo));
    dispatch(addTruckingFeederDoDate(blItem && blItem.doDate));

    if (currentUser.company.role.toUpperCase() === ROLE_PPJK) {
      dispatch(addTruckingFeederBillTo(blItem && blItem.billTo));
    }

    dispatch(setBookFeederActiveForm(0));
    dispatch(setNewBookingDialogStatus(true));

    await dispatch(downloadFeederServiceWarehouseListAsync(blItem && blItem.billTo));

    router.push(ROUTE_NAME_TRUCKING);
  } finally {
    dispatch(setGlobalLoading(false));
  }
};
