import { isEmpty } from 'lodash';
import * as Helper from '../../../helper';
import * as Action from '../simple-action';

export default () => async (dispatch, getState) => {
  const { authentication, uiForm } = getState();
  const { token } = authentication;
  const {
    activePage, pageSize, filters,
  } = uiForm;
  const { title } = filters;

  try {
    dispatch(Action.downloadingForm(true));
    const pageSizeTobeDownloaded = title !== '' ? 200 : pageSize;
    const lists = await Helper.downloadAllForm(token, activePage, pageSizeTobeDownloaded);
    dispatch(Action.setForms(lists));
    if (!isEmpty(lists)) {
      return Object.values(lists.data)
        .filter(x => x.title.toLowerCase().includes(title.toLowerCase()));
    }
    return [];
  } finally {
    dispatch(Action.downloadingForm(false));
  }
};
