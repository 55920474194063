import { connect } from 'react-redux';
import _ from 'lodash';
import ExportScreen from './export-history.presentation';
import {
  setUIError,
  clearUIError,
  downloadExportOrderHistoryAsync,
  downloadExportOrderHistoryDetailAsync,
  setExportDetailFilter,
  setExportDetailPaging,
  setExportDetailSort,
  setTappedExportContainerNo,
  setTappedExportBlNo,
  setExportSearchText,
  setBookExportDialogStatus,
  addToShoppingCartExportAsync,
  setExportDetailDialogStatus,
  setExportPasswordInputDialog,
  setExportPasswordTextInput,
  addToTermOrderExportAsync,
  downloadMyPaymentTermOrderAsync,
  showErrorSnackbar,
} from '../../redux/action';
import {
  RXSTATE_EXPORT_PAGE,
  TABLE_SORT_ASCENDING,
  TABLE_FIELD_CONTAINER_NUMBER,
  TABLE_FIELD_BL_NO,
  CART_SERVICE_NAME_EXPORT,
  TABLE_FIELD_SEAL_NUMBER,
  TABLE_FIELD_KMT_NO,
} from '../../constant';
import LocalizedString from '../../localization';

const getFilteredOrderHistory = state => _.values(state.exportOrderHistory)
  .filter(item => item.blNo.toUpperCase()
    .indexOf(state.uiExport.searchBarText.toUpperCase()) >= 0);

const getFilteredOrderHistoryDetail = (state) => {
  const exportOrderHistoryDetail = _.values(state.exportOrderHistoryDetail)
    .filter(item => item.coRef.toUpperCase() === state.uiExport.tappedBlNo.toUpperCase());
  const { filters, sortField, sortDirection } = state.uiExport;

  return exportOrderHistoryDetail.sort((left, right) => {
    if (typeof left[sortField] === 'string') {
      if (sortDirection === TABLE_SORT_ASCENDING) {
        return left[sortField].localeCompare(right[sortField]);
      }
      return right[sortField].localeCompare(left[sortField]);
    }

    if (sortDirection === TABLE_SORT_ASCENDING) {
      return left[sortField] - right[sortField];
    }
    return right[sortField] - left[sortField];
  })
    .filter(
      item => item.containerNo.toUpperCase().indexOf(
        filters[TABLE_FIELD_CONTAINER_NUMBER].toUpperCase(),
      ) >= 0,
    )
    .filter(
      item => (item.blNo || '').toUpperCase().indexOf(
        filters[TABLE_FIELD_BL_NO].toUpperCase(),
      ) >= 0,
    )
    .filter(
      item => (item.sealNo || '').toUpperCase().indexOf(
        filters[TABLE_FIELD_SEAL_NUMBER].toUpperCase(),
      ) >= 0,
    )
    .filter(
      item => (item.kmtNo || '').toUpperCase().indexOf(
        filters[TABLE_FIELD_KMT_NO].toUpperCase(),
      ) >= 0,
    );
};

const getPageFilteredOrderHistoryDetail = (state) => {
  const {
    activePage, pageSize,
  } = state.uiExport;

  const filteredOrderHistoryDetail = getFilteredOrderHistoryDetail(state);

  if (filteredOrderHistoryDetail) {
    const refinedActivePage = activePage || 1;
    const start = Math.min((refinedActivePage - 1) * pageSize,
      filteredOrderHistoryDetail.length);
    const end = Math.min(start + pageSize, filteredOrderHistoryDetail.length);
    return filteredOrderHistoryDetail.slice(start, end);
  }

  return undefined;
};

const getTotalPage = (state) => {
  const exportOrderDetail = getFilteredOrderHistoryDetail(state);
  const { pageSize } = state.uiExport;
  const totalData = exportOrderDetail ? exportOrderDetail.length : 0;

  return Math.max(Math.ceil(totalData / pageSize), 1);
};

const getSelectedExportInfo = state => _.values(state.exportOrderHistory)
  .find(item => item.coRef === state.uiExport.tappedBlNo);

const mapStateToProps = state => ({
  notificationOpen: state.uiWebUserMain.notificationDrawerOpen,
  menuOpen: state.uiMenu.menuOpen,
  userCompany: state.currentUser.company,

  addingShoppingCartItem: state.uiShoppingCart.addingItem || state.uiMyPaymentTerm.addingItem,
  downloadingExportOrder: state.uiExport.downloadingExportOrderHistory
    || state.uiMyPaymentTerm.downloading,
  downloadingExportOrderDetail: state.uiExport.downloadingExportOrderHistoryDetail,
  searchBarText: state.uiExport.searchBarText,
  shoppingCart: _.values(state.shoppingCart),
  termOrder: _.values(state.myTermOrder),
  selectedBlInfo: getSelectedExportInfo(state),
  selectedBlNo: state.uiExport.tappedBlNo,
  exportOrderHistoryDetail: getPageFilteredOrderHistoryDetail(state),
  bl: getFilteredOrderHistory(state),

  dialogNewBookingStatus: state.uiBookExport.openDialogStatus,
  dialogContainerDetailStatus: state.uiExport.dialogContainerDetailStatus,

  pageSize: state.uiExport.pageSize,
  totalPage: getTotalPage(state),
  activePage: state.uiExport.activePage,
  filters: state.uiExport.filters,
  sortField: state.uiExport.sortField,
  sortDirection: state.uiExport.sortDirection,

  dialogPasswordInputStatus: state.uiExport.passwordInputDialog,
  password: state.uiExport.password,
});

const mapDispatchToProps = dispatch => ({
  onAppear: () => {
    try {
      dispatch(clearUIError(RXSTATE_EXPORT_PAGE));
      Promise.all([
        dispatch(downloadExportOrderHistoryAsync()), // change to admin
      ]).catch(error => dispatch(setUIError(RXSTATE_EXPORT_PAGE, error.message)));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    }
  },
  onCloseDetailDialogPressed: () => {
    dispatch(setExportDetailDialogStatus(false));
    dispatch(setTappedExportContainerNo(''));
  },
  onDetailPressed: (containerNo) => {
    dispatch(setExportDetailDialogStatus(true));
    dispatch(setTappedExportContainerNo(containerNo));
  },
  onMorePress: async (tappedBlNo) => {
    try {
      dispatch(clearUIError(RXSTATE_EXPORT_PAGE));
      dispatch(setTappedExportBlNo(tappedBlNo));
      await dispatch(downloadExportOrderHistoryDetailAsync(tappedBlNo));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    }
  },
  onChangeSearchBarText: (text) => {
    dispatch(setExportSearchText(text));
  },
  onChangeFilter: (filterName, value) => {
    dispatch(setExportDetailFilter(filterName, value));
  },
  onChangePaging: (pageField, value) => {
    dispatch(setExportDetailPaging(pageField, value));
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setExportDetailSort(sortField, sortDirection));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportScreen);
