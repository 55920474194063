import { connect } from 'react-redux';
import _ from 'lodash';
import TruckAddDialog from './truck-add-dialog.presentation';
import { clearUIError, setUIError, downloadAllVendorAsync } from '../../redux/action';
import { RXSTATE_MASTER_TRUCKING } from '../../constant';

const mapStateToProps = state => ({
  initialValues: {},
  loading: false || state.uiMasterVendor.downloadingVendors,
  vendors: _.values(state.vendors).map(vendor => ({ label: vendor.name, value: vendor.id })),
});

const mapDispatchToProps = dispatch => ({
  onAppear: async () => {
    try {
      dispatch(clearUIError(RXSTATE_MASTER_TRUCKING));
      await dispatch(downloadAllVendorAsync());
    } catch (error) {
      dispatch(
        setUIError(RXSTATE_MASTER_TRUCKING, error.message),
      );
    }
  },
  onSubmitPressed: (values) => { },

});

export default connect(mapStateToProps, mapDispatchToProps)(TruckAddDialog);
