import * as Action from '../action';

const initialState = {
  feederInfo: {
    blNo: undefined,
    billTo: undefined,
    paymentOnBehalfOf: undefined,
    forwarderId: undefined,
    doNo: undefined,
    doExpired: undefined,
    emptyDepo: undefined,
    area: undefined,
    warehouse: undefined,
    spcDate: undefined,
    planDate: undefined,
  },
  priceInfo: {
    nilAdmin: 0,
    nilTaxBasis: 0,
    nilVat: 0,
    nilTotal: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.ADD_BOOK_FEEDER_SERVICE:
      return { ...state, feederInfo: { ...action.bookFeederServiceInfo } };
    case Action.REMOVE_BOOK_FEEDER_SERVICE_INFO:
      return initialState;
    case Action.ADD_BOOK_FEEDER_PRICE_CONTENT:
      return { ...state, priceInfo: { ...action.info } };
    case Action.ADD_TRUCKING_FEEDER_BL_NO:
      return { ...state, feederInfo: { ...state.feederInfo, blNo: action.value } };
    case Action.ADD_TRUCKING_FEEDER_DO_NO:
      return { ...state, feederInfo: { ...state.feederInfo, doNo: action.value } };
    case Action.ADD_TRUCKING_FEEDER_DO_DATE:
      return { ...state, feederInfo: { ...state.feederInfo, doExpired: action.value } };
    case Action.ADD_TRUCKING_FEEDER_BILL_TO:
      return { ...state, feederInfo: { ...state.feederInfo, billTo: action.value } };
    default: return state;
  }
};
