import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import * as Action from '../action';
import adminPaymentHistory from './adminPaymentHistory';
import adminPaymentHistoryAdd from './adminPaymentHistoryAdd';
import assign from './assign';
import assignContainer from './assignContainer';
import assignOrderType from './assignOrderType';
import authentication from './authentication';
import bookExport from './bookExport';
import bookFeeder from './bookFeeder';
import containerTracking from './containerTracking';
import containerTrackingDetail from './containerTrackingDetail';
import images from './images';
import users from './users';
import currentUser from './currentUser';
import customerCompanyList from './customerCompanyList';
import dashboard from './dashboard';
import depositHistory from './depositHistory';
import dgClass from './dgClass';
import exportBookPartyList from './exportBookPartyList';
import exportDoList from './exportDoList';
import exportOrderHistory from './exportOrderHistory';
import exportOrderHistoryDetail from './exportOrderHistoryDetail';
import exportPriceDetail from './exportPriceDetail';
import exportVesselList from './exportVesselList';
import exportVoyageList from './exportVoyageList';
import feederServiceOrderDetail from './feederServiceOrderDetail';
import feederServiceOrderHistories from './feederServiceOrderHistories';
import feederServicePriceDetail from './feederServicePriceDetail';
import feederServiceAreaList from './feederServiceAreaList';
import feederServiceEmptyDepoList from './feederServiceEmptyDepoList';
import feederServiceForwarderList from './feederServiceForwarderList';
import feederServiceWarehouseList from './feederServiceWarehouseList';
import feederServiceBookPartyList from './feederServiceBookPartyList';
import feederServiceDisclaimer from './feederServiceDisclaimer';
import feederServiceBLList from './feederServiceBLList';
import forgetPasswordInfo from './forgetPasswordInfo';
import fields from './fields';
import forms from './forms';
import forwarders from './forwarders';
import myOrder from './myOrder';
import myTermOrder from './myTermOrder';
import myOutstandingOrder from './myOutstandingOrder';
import notification from './notification';
import payment from './payment';
import ppjkCompanyList from './ppjkCompanyList';
import ppjkListByAssign from './ppjkListByAssign';
import reeferData from './reeferData';
import reeferDataDetails from './reeferDataDetails';
import registrationInfo from './registrationInfo';
import shoppingCartAdminPayment from './shoppingCartAdminPayment';
import shoppingCart from './shoppingCart';
import shoppingCheckout from './shoppingCheckout';
import tariffSimulation from './tariffSimulation';
import tariffSimulationOrder from './tariffSimulationOrder';
import importReleaseOrder from './importReleaseOrder';
import truckList from './truckList';
import trucks from './trucks';
import vendors from './vendors';
import uiLogin from './uiLogin';
import uiProfile from './uiProfile';
import uiForgetPassword from './uiForgetPassword';
import uiRegistration from './uiRegistration';
import uiMenu from './uiMenu';
import uiUserManagement from './uiUserManagement';
import uiWebUserMain from './uiWebUserMain';
import uiAdminPaymentHistory from './uiAdminPaymentHistory';
import uiAdminPaymentHistoryAdd from './uiAdminPaymentHistoryAdd';
import uiAdminPaymentHistoryDetail from './uiAdminPaymentHistoryDetail';
import uiAssign from './uiAssign';
import uiAssignClearance from './uiAssignClearance';
import uiAssignMultipleClearance from './uiAssignMultipleClearance';
import uiAssignContainer from './uiAssignContainer';
import uiAssignContainerAdd from './uiAssignContainerAdd';
import uiAssignOrderType from './uiAssignOrderType';
import uiAssignTruck from './uiAssignTruck';
import uiBookExport from './uiBookExport';
import uiBookExportFeeder from './uiBookExportFeeder';
import uiBookExportParty from './uiBookExportParty';
import uiBookFeeder from './uiBookFeeder';
import uiBookFeederFeeder from './uiBookFeederFeeder';
import uiBookFeederParty from './uiBookFeederParty';
import uiCart from './uiCart';
import uiCheckout from './uiCheckout';
import uiContactUs from './uiContactUs';
import uiContainerTracking from './uiContainerTracking';
import uiContainerTrackingDialog from './uiContainerTrackingDialog';
import uiDepositBalance from './uiDepositBalance';
import uiDepositHistory from './uiDepositHistory';
import uiExport from './uiExport';
import uiFeederService from './uiFeederService';
import uiFeederServiceDetail from './uiFeederServiceDetail';
import uiField from './uiField';
import uiForm from './uiForm';
import uiMasterForwarder from './uiMasterForwarder';
import uiMasterTruck from './uiMasterTruck';
import uiMasterUser from './uiMasterUser';
import uiMasterVendor from './uiMasterVendor';
import uiMyPayment from './uiMyPayment';
import uiMyPaymentHistory from './uiMyPaymentHistory';
import uiMyPaymentTerm from './uiMyPaymentTerm';
import uiMyPaymentOutstanding from './uiMyPaymentOutstanding';
import uiOrderDetailCustomer from './uiOrderDetailCustomer';
import uiOrderDetailVendor from './uiOrderDetailVendor';
import uiOrderListCustomer from './uiOrderListCustomer';
import uiOrderListExportIn from './uiOrderListExportIn';
import uiPayment from './uiPayment';
import uiPaymentResult from './uiPaymentResult';
import uiPdfViewer from './uiPdfViewer';
import uiReeferData from './uiReeferData';
import uiReeferDataContainer from './uiReeferDataContainer';
import uiRegisterPassword from './uiRegisterPassword';
import uiShoppingCart from './uiShoppingCart';
import uiSnackbar from './uiSnackbar';
import uiTariffSimulation from './uiTariffSimulation';
import uiTariffSimulationRelease from './uiTariffSimulationRelease';
import uiTariffSimulationReleaseSearch from './uiTariffSimulationReleaseSearch';
import moneyLoanInfo from './moneyLoanInfo';
import uiMoneyLoanInfo from './uiMoneyLoanInfo';
import moneyLoanHistory from './moneyLoanHistory';
import uiMoneyLoanHistory from './uiMoneyLoanHistory';
import trackingStatusBehandleHistories from './trackingStatusBehandleHistories';
import trackingStatusBehandleOnProgresses from './trackingStatusBehandleOnProgresses';
import uiTrackingStatusBehandle from './uiTrackingStatusBehandle';
import uiTrackingStatusBehandleHistory from './uiTrackingStatusBehandleHistory';
import uiTrackingStatusBehandleOnProgress from './uiTrackingStatusBehandleOnProgress';
import predefinedOrder from './predefinedOrder';
import uiPredefinedOrder from './uiPredefinedOrder';
import tariffSimulationHostBL from './tariffSimulationHostBL';
import docSubmissionMyResponses from './docSubmissionMyResponses';
import docSubmissionMyResponseDetail from './docSubmissionMyResponseDetail';
import uiDocSubmissionMyResponse from './uiDocSubmissionMyResponse';
import newDocumentMyForm from './newDocumentMyForm';
import uiNewDocumentMyForm from './uiNewDocumentMyForm';
import newDocumentMyFormDetail from './newDocumentMyFormDetail';
import newDocumentPaymentOnBehalfList from './newDocumentPaymentOnBehalfList';
import formResponses from './formResponses';
import formResponseDetail from './formResponseDetail';
import uiFormResponse from './uiFormResponse';
import formResponseFilterStatuses from './formResponseFilterStatuses';
import erpPaymentLogs from './erpPaymentLogs';
import erpPaymentLogDetail from './erpPaymentLogDetail';
import uiErpPaymentLog from './uiErpPaymentLog';

const reducer = combineReducers({
  adminPaymentHistory,
  adminPaymentHistoryAdd,
  assign,
  assignContainer,
  assignOrderType,
  authentication,
  bookExport,
  bookFeeder,
  containerTracking,
  containerTrackingDetail,
  currentUser,
  customerCompanyList,
  dashboard,
  depositHistory,
  dgClass,
  docSubmissionMyResponses,
  docSubmissionMyResponseDetail,
  erpPaymentLogs,
  erpPaymentLogDetail,
  exportBookPartyList,
  exportDoList,
  exportOrderHistory,
  exportOrderHistoryDetail,
  exportPriceDetail,
  exportVesselList,
  exportVoyageList,
  feederServiceDisclaimer,
  feederServiceBLList,
  feederServiceOrderDetail,
  feederServiceOrderHistories,
  feederServicePriceDetail,
  feederServiceAreaList,
  feederServiceEmptyDepoList,
  feederServiceForwarderList,
  feederServiceWarehouseList,
  feederServiceBookPartyList,
  fields,
  forgetPasswordInfo,
  forms,
  formResponses,
  formResponseDetail,
  formResponseFilterStatuses,
  forwarders,
  images,
  importReleaseOrder,
  moneyLoanHistory,
  moneyLoanInfo,
  myOrder,
  myTermOrder,
  myOutstandingOrder,
  newDocumentMyForm,
  newDocumentMyFormDetail,
  newDocumentPaymentOnBehalfList,
  notification,
  payment,
  ppjkCompanyList,
  ppjkListByAssign,
  predefinedOrder,
  reeferData,
  reeferDataDetails,
  registrationInfo,
  shoppingCartAdminPayment,
  shoppingCart,
  shoppingCheckout,
  tariffSimulation,
  tariffSimulationHostBL,
  tariffSimulationOrder,
  trackingStatusBehandleHistories,
  trackingStatusBehandleOnProgresses,
  truckList,
  trucks,
  users,
  vendors,
  uiLogin,
  uiProfile,
  uiForgetPassword,
  uiRegistration,
  uiMenu,
  uiUserManagement,
  uiWebUserMain,
  uiAdminPaymentHistory,
  uiAdminPaymentHistoryAdd,
  uiAdminPaymentHistoryDetail,
  uiAssign,
  uiAssignClearance,
  uiAssignMultipleClearance,
  uiAssignContainer,
  uiAssignContainerAdd,
  uiAssignOrderType,
  uiAssignTruck,
  uiBookExport,
  uiBookExportFeeder,
  uiBookExportParty,
  uiBookFeeder,
  uiBookFeederFeeder,
  uiBookFeederParty,
  uiCart,
  uiCheckout,
  uiContactUs,
  uiContainerTracking,
  uiContainerTrackingDialog,
  uiDepositBalance,
  uiDepositHistory,
  uiDocSubmissionMyResponse,
  uiErpPaymentLog,
  uiExport,
  uiFeederService,
  uiFeederServiceDetail,
  uiField,
  uiForm,
  uiFormResponse,
  uiMoneyLoanHistory,
  uiMoneyLoanInfo,
  uiMasterForwarder,
  uiMasterTruck,
  uiMasterUser,
  uiMasterVendor,
  uiMyPayment,
  uiMyPaymentHistory,
  uiMyPaymentTerm,
  uiMyPaymentOutstanding,
  uiNewDocumentMyForm,
  uiOrderDetailCustomer,
  uiOrderDetailVendor,
  uiOrderListCustomer,
  uiOrderListExportIn,
  uiPayment,
  uiPaymentResult,
  uiPdfViewer,
  uiPredefinedOrder,
  uiReeferData,
  uiReeferDataContainer,
  uiRegisterPassword,
  uiTrackingStatusBehandle,
  uiTrackingStatusBehandleHistory,
  uiTrackingStatusBehandleOnProgress,
  uiShoppingCart,
  uiSnackbar,
  uiTariffSimulation,
  uiTariffSimulationRelease,
  uiTariffSimulationReleaseSearch,
  form: formReducer,
});

export default (state, action) => {
  const matches = /(SET|CLEAR)_ERROR_(.+)/.exec(action.type);
  if (matches) {
    const reduxState = reducer(state, action);
    const [, stateOperation, stateName] = matches;
    const stateSlice = reduxState[stateName];
    if (stateSlice && stateOperation === 'SET') {
      return {
        ...reduxState,
        [stateName]: { ...stateSlice, error: action.error },
      };
    }
    if (stateSlice && stateOperation === 'CLEAR') {
      return {
        ...reduxState,
        [stateName]: { ...stateSlice, error: undefined },
      };
    }
  }

  if (action.type === Action.CLEAR_ALL_STATES) {
    return reducer(undefined, action);
  }
  return reducer(state, action);
};
