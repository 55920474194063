import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import { Link } from 'react-router-dom';
import {
  withStyles,
  CssBaseline,
  Grid,
  Card,
  CardContent,
  Fab,
  Typography,
  Icon,
  Button,
  TextField,
  CardHeader,
  CardActions,
  ButtonBase,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Input,
  TableBody,
} from '@material-ui/core';
import {
  LoadingIndicator, PageHeaderAdmin, MainMenu,
} from '../../component';
import * as Constant from '../../constant';
import LocalizedString from '../../localization';
import { toCurrency, isSortFieldActive, getSortIcon } from '../../helper';
import ExportDetailDialog from './export-history-detail-dialog.container';

const styles = theme => ({
  alignRight: {
    textAlign: 'right',
  },
  backIcon: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  btnNewBooking: {
    backgroundColor: '#5CB860',
    color: '#ffffff',
    width: '256px',
    '&:hover': {
      backgroundColor: '#558b2f',
    },
  },
  btnAddToCart: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
    width: '256px',
  },
  detailButton: {
    backgroundColor: '#ffffff',
  },
  grow: {
    flexGrow: 1,
  },
  searchButton: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  cardActionPrimary: {
    display: 'flex',
    alignItems: 'baseline',
    padding: 16,
    justifyContent: 'space-between',
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  cardBlDataHeader: {
    marginBottom: theme.spacing.unit * 2,
  },
  cardItem: {
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '6px',
    boxSizing: 'border-box',
    padding: '16px',
    marginBottom: theme.spacing.unit,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  cardItemActive: {
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  cardItemContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: theme.spacing.unit * 2,
    maxBlockSize: '500px',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentMenuDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '256px',
      width: 'auto',
    },
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${Constant.DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  detailHeader: {
    color: '#0066CB',
  },
  itemPerRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    color: '#0266B4',
  },
  root: {
    width: '100%',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  textJumlahPembayaran: {
    textTransform: 'uppercase',
  },
  textTotalPrice: {
    fontSize: '22px',
    fontWeight: 500,
    color: '#0065B3',
    marginRight: theme.spacing.unit * 2,
  },
});

class ExportScreen extends Component {
  componentDidMount() {
    this.props.onAppear();
  }

  render() {
    const {
      classes,
      menuOpen,

      searchBarText,
      downloadingExportOrder,
      downloadingExportOrderDetail,
      bl,
      selectedBlNo,
      selectedBlInfo,
      exportOrderHistoryDetail,

      dialogContainerDetailStatus,

      activePage,
      totalPage,
      filters,
      sortField,
      sortDirection,
      pageSize,

      onMorePress,
      onChangeSearchBarText,
      onChangeSort,
      onChangePaging,
      onChangeFilter,
      onDetailPressed,
      onCloseDetailDialogPressed,
    } = this.props;

    const handleChangeSort = (newSortField) => {
      if (newSortField === sortField) {
        onChangeSort(
          sortField,
          sortDirection === Constant.TABLE_SORT_ASCENDING
            ? Constant.TABLE_SORT_DESCENDING
            : Constant.TABLE_SORT_ASCENDING,
        );
      } else {
        onChangeSort(newSortField, Constant.TABLE_SORT_ASCENDING);
      }
    };

    const renderTableCellHeader = (label, fieldId) => (
      <TableCell>
        <TableSortLabel
          active={
            isSortFieldActive(sortField, fieldId)
          }
          IconComponent={getSortIcon(
            isSortFieldActive(sortField, fieldId),
            sortDirection,
          )}
          onClick={
            () => handleChangeSort(fieldId)
          }
        >
          {label}
        </TableSortLabel>
      </TableCell>
    );

    const renderTableCellStringFilter = field => (
      <TableCell>
        <Input
          type="text"
          fullWidth
          value={filters[field]}
          onChange={
            ({ nativeEvent }) => onChangeFilter(
              field,
              nativeEvent.target.value,
            )
          }
        />
      </TableCell>
    );

    const renderBlCard = () => {
      if (!bl) {
        return (<Typography>No BL available</Typography>);
      }

      return bl.map(item => (
        <ButtonBase
          component="div"
          key={item.blNo}
          className={classNames(classes.cardItem, {
            [classes.cardItemActive]: selectedBlNo
              && item.coRef.toUpperCase() === selectedBlNo.toUpperCase(),
          })}
          onClick={() => onMorePress(item.coRef)}
        >
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Typography color="primary" variant="h6">
                {LocalizedString.export.labelBlNo}
                &nbsp;
                {item.blNo}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="caption">{LocalizedString.export.labelDoNo}</Typography>
              <Typography>
                {item.coRef}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.alignRight}>
              <Typography variant="caption">{LocalizedString.export.labelVesselVoyage}</Typography>
              <Typography>
                {item.vessel}
                -
                {item.voyage}
              </Typography>
            </Grid>
          </Grid>
        </ButtonBase>
      ));
    };

    const renderBlDetail = () => {
      if (!selectedBlInfo) {
        return (<></>);
      }

      return (
        <Card>
          <CardHeader
            title={(
              <>
                <Typography variant="h6" className={classes.detailHeader}>
                  More
                </Typography>
                <div className={classes.itemPerRowContainer}>
                  <Select
                    value={pageSize}
                    onChange={event => onChangePaging(
                      Constant.RXTABLEFIELD_PAGE_SIZE,
                      event.target.value,
                    )}
                  >
                    {Constant.DEFAULT_PAGE_SIZE_OPTIONS.map(item => (
                      <MenuItem value={item} key={item}>
                        {item}
                        &nbsp;
                        {LocalizedString.common.labelRows}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </>
            )}
          />
          <CardContent>
            <div style={{ width: '100%', overflow: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {renderTableCellHeader(LocalizedString.export.labelContainerNumber,
                      Constant.TABLE_FIELD_CONTAINER_NUMBER)}
                    {renderTableCellHeader(LocalizedString.export.labelBlNo,
                      Constant.TABLE_FIELD_BL_NO)}
                    {renderTableCellHeader(LocalizedString.export.labelSoNo,
                      Constant.TABLE_FIELD_SEAL_NUMBER)}
                    {renderTableCellHeader(LocalizedString.export.labelKmtNo,
                      Constant.TABLE_FIELD_KMT_NO)}
                    <TableCell>
                      Action(s)
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    {renderTableCellStringFilter(Constant.TABLE_FIELD_CONTAINER_NUMBER)}
                    {renderTableCellStringFilter(Constant.TABLE_FIELD_BL_NO)}
                    {renderTableCellStringFilter(Constant.TABLE_FIELD_SEAL_NUMBER)}
                    {renderTableCellStringFilter(Constant.TABLE_FIELD_KMT_NO)}
                    <TableCell />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {exportOrderHistoryDetail && exportOrderHistoryDetail.map(container => (
                    <TableRow key={container.containerNo} className={classes.tableRow}>
                      <TableCell>
                        {container.containerNo}
                      </TableCell>
                      <TableCell>
                        {container.blNo}
                      </TableCell>
                      <TableCell>
                        {container.sealNo || '-'}
                      </TableCell>
                      <TableCell>
                        {container.kmtNo || '-'}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="primary"
                          classes={{ outlinedPrimary: classes.detailButton }}
                          onClick={() => onDetailPressed(container.containerNo)}
                        >
                          {LocalizedString.common.buttonCaptionDetail}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>

            <div className={classes.tablePagination}>
              <Button
                variant="contained"
                style={{ flexGrow: 1 }}
                disabled={activePage === 1}
                onClick={() => onChangePaging(Constant.RXTABLEFIELD_ACTIVE_PAGE, activePage - 1)}
              >
                {LocalizedString.common.labelPagingPrevButton}
              </Button>

              <div className={classes.tablePaginationPageInput}>
                <span style={{ fontWeight: 500 }}>{LocalizedString.common.labelPagingPage}</span>
                <Input
                  type="number"
                  value={activePage}
                  style={{ width: '50%' }}
                  inputProps={{
                    min: 1,
                    max: totalPage,
                    step: 1,
                    style: { textAlign: 'center' },
                  }}
                  onChange={({ nativeEvent }) => onChangePaging(
                    Constant.RXTABLEFIELD_ACTIVE_PAGE,
                    parseInt(nativeEvent.target.value, 10),
                  )}
                />
                <span style={{ fontWeight: 500 }}>
                  {`${LocalizedString.common.labelPagingPageOf} ${totalPage}`}
                </span>
              </div>

              <Button
                variant="contained"
                style={{ flexGrow: 1 }}
                disabled={activePage === totalPage}
                onClick={() => onChangePaging(Constant.RXTABLEFIELD_ACTIVE_PAGE, activePage + 1)}
              >
                {LocalizedString.common.labelPagingNextButton}
              </Button>
            </div>
          </CardContent>
        </Card>
      );
    };


    const renderBlHeader = () => {
      if (selectedBlInfo) {
        return (
          <Card className={classes.cardBlDataHeader}>
            <CardHeader
              title={(
                <>
                  <Typography variant="h6" className={classes.detailHeader}>
                    {LocalizedString.export.labelBlNo}
                    &nbsp;
                    {selectedBlInfo.blNo}
                  </Typography>
                </>
              )}
            />
            <CardActions className={classes.cardActionPrimary}>
              <Typography variant="body1" className={classes.textJumlahPembayaran}>{LocalizedString.trucking.lblTotalPrice}</Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" className={classes.textTotalPrice}>
                  Rp.&nbsp;
                  {toCurrency(selectedBlInfo.totalAmount || 0)}
                </Typography>
              </div>
            </CardActions>
          </Card>
        );
      }
      return null;
    };

    return (
      <>
        <div className={classes.root}>
          <CssBaseline />

          <PageHeaderAdmin />

          <MainMenu />

          <div
            className={
              classNames(classes.content, {
                [classes.contentMenuDrawerOpen]: menuOpen,
              })
            }
            style={{ padding: '16px' }}
          >
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <div className={classes.pageNavigatorContainer}>
                      {/* <Fab
                        variant="round"
                        classes={{ root: classes.backIcon }}
                        component={Link}
                        to={Constant.ROUTE_NAME_DASHBOARD_USER}
                      >
                        <Icon>chevron_left</Icon>
                      </Fab> */}
                      {/* <div style={{ marginLeft: '32px' }}> */}
                      <div>
                        <Typography variant="h5" className={classes.pageTitle}>{LocalizedString.export.title}</Typography>
                        <Typography variant="caption">{LocalizedString.export.caption}</Typography>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <div style={{ display: 'flex' }}>
                      <div style={{ flexGrow: 1, alignSelf: 'flex-end' }}>
                        <TextField
                          fullWidth
                          placeholder="Search BL no"
                          value={searchBarText}
                          onChange={event => onChangeSearchBarText(event.target.value)}
                        />
                      </div>
                      <div style={{ display: 'inline-flex', marginLeft: '8px' }}>
                        <Fab
                          size="small"
                          variant="round"
                          color="default"
                          className={classes.searchButton}
                        >
                          <Icon>search</Icon>
                        </Fab>
                      </div>
                    </div>

                    <div className={classes.cardItemContainer}>
                      {renderBlCard()}
                    </div>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={8}>
                {renderBlHeader()}

                {renderBlDetail()}
              </Grid>
            </Grid>
          </div>

          {(downloadingExportOrder || downloadingExportOrderDetail) && <LoadingIndicator />}
        </div>

        <ExportDetailDialog
          open={dialogContainerDetailStatus}
          onClose={onCloseDetailDialogPressed}
        />
      </>
    );
  }
}
ExportScreen.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  menuOpen: PropTypes.bool.isRequired,

  searchBarText: PropTypes.string.isRequired,
  downloadingExportOrder: PropTypes.bool.isRequired,
  downloadingExportOrderDetail: PropTypes.bool.isRequired,
  bl: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedBlNo: PropTypes.string.isRequired,
  selectedBlInfo: PropTypes.shape(PropTypes.any).isRequired,
  exportOrderHistoryDetail: PropTypes.arrayOf(PropTypes.any).isRequired,

  dialogContainerDetailStatus: PropTypes.bool.isRequired,

  filters: PropTypes.shape(PropTypes.any).isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  activePage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,

  onAppear: PropTypes.func.isRequired,
  onChangeSearchBarText: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onChangePaging: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onDetailPressed: PropTypes.func.isRequired,
  onMorePress: PropTypes.func.isRequired,
  onCloseDetailDialogPressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(ExportScreen);
