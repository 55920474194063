import * as Action from '../action';

const initialState = {
  downloading: false,
  tappedBlNo: '',
  tappedSoNo: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.DOWNLOADING_ASSIGN_ORDER_TYPE:
      return { ...state, downloading: action.status };
    case Action.SET_TAPPED_ASSIGN_ORDER_TYPE_NO:
      return { ...state, tappedBlNo: action.tappedBlNo, tappedSoNo: action.tappedSoNo };
    default: return state;
  }
};
