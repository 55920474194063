import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Card,
  CircularProgress,
  CardContent,
  Typography,
  Grid,
  Button,
} from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import {
  FIELD_TYPE_DATE,
  FIELD_TYPE_FILE,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_TEXT,
  RXFORM_DOC_SUBMISSION_MY_RESPONSE_DETAIL,
} from '../../constant';
import { renderReduxFormDateField, renderReduxFormTextField } from '../../helper';
import LocalizedString from '../../localization';

const styles = theme => ({
  cardBlDataHeader: {
    marginBottom: theme.spacing.unit * 2,
  },
  circularProgressContainer: {
    padding: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circularProgress: {
    height: '28px !important',
    width: '28px !important',
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '40px',
    marginBottom: '20px',
  },
  submitButton: {
    padding: '12px 48px',
    backgroundColor: '#50abf1',
  },
  notFound: {
    color: '#8C8C8C',
    textAlign: 'center',
  },
  fileLabel: {
    color: '#00000061',
    marginBottom: '5px',
  },
  responseLabel: {
    color: '#00000061',
    marginTop: '14px',
    fontSize: '16px',
  },
});

const DocumentSubmissionDetail = ({
  classes,
  tappedId,
  initialValues,
  downloadingDetail,
  downloadingFile,
  onAppear,
  onDownloadFilePressed,
}) => {
  useEffect(() => {
    onAppear(tappedId);
  }, [tappedId]);

  const renderField = (field) => {
    switch (field.type) {
      case FIELD_TYPE_TEXT:
      case FIELD_TYPE_NUMBER:
        return (
          <Field
            key={field.name}
            name={field.name}
            component={renderReduxFormTextField}
            label={field.label}
            type={field.type === FIELD_TYPE_NUMBER ? 'number' : 'text'}
            input={{ value: field.value }}
            disabled
          />
        );
      case FIELD_TYPE_DATE:
        return (
          <Field
            key={field.name}
            name={field.name}
            component={renderReduxFormDateField}
            label={field.label}
            input={{ value: field.value }}
            keyboard
            disabled
          />
        );
      case FIELD_TYPE_FILE:
        return (
          <div style={{ margin: '10px 0 8px 0' }} key={field.name}>
            <Typography className={classes.fileLabel}>{field.label}</Typography>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              onClick={() => onDownloadFilePressed(field.value)}
              disabled={downloadingFile}
            >
              {`${LocalizedString.submitRequest.buttonCaptionDownload} ${field.label}`}
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  const renderDetailFeilds = (form) => {
    if (!form) {
      return (
        <Typography className={classes.notFound}>
          {LocalizedString.submitRequest.labelNoData}
        </Typography>
      );
    }

    return (
      <Grid item xs={12} md={12}>
        {renderField(form.submitId)}
        {renderField(form.submitDate)}
        {renderField(form.status)}
        {renderField(form.statusNotes)}
        {renderField(form.form)}
        {renderField(form.user)}
        {renderField(form.paymentOnBehalf)}
        <Typography className={classes.responseLabel}>
          {LocalizedString.submitRequest.labelResponse}
        </Typography>
        {
          form.response.length === 0 ? (
            <Typography className={classes.notFound}>
              {LocalizedString.submitRequest.labelNoFields}
            </Typography>
          ) : form.response.map(item => (renderField(item)))
        }
      </Grid>
    );
  };

  if (!tappedId) {
    return null;
  }

  return (
    <>
      <Card className={classes.cardBlDataHeader}>
        {
          downloadingDetail ? (
            <div className={classes.circularProgressContainer}>
              <CircularProgress className={classes.circularProgress} />
            </div>
          ) : (
            <>
              <CardContent>
                {renderDetailFeilds(initialValues, classes)}
              </CardContent>
            </>
          )
        }
      </Card>
    </>
  );
};

DocumentSubmissionDetail.propTypes = {
  tappedId: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  downloadingDetail: PropTypes.bool.isRequired,
  downloadingFile: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onDownloadFilePressed: PropTypes.func.isRequired,
};

export default reduxForm({
  form: RXFORM_DOC_SUBMISSION_MY_RESPONSE_DETAIL,
  enableReinitialize: true,
})(withStyles(styles)(DocumentSubmissionDetail));
