import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import moment from 'moment-timezone';
import {
  HashRouter as Router, Route, Switch,
} from 'react-router-dom';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateMomentUtils from '@date-io/moment';
import reducer from './redux/reducer';
import * as Constant from './constant';
import { PrivateRoute, AdminRoute, IntervalManager } from './component';
import {
  SplashPage,
  LoginPage,
  HomePage,
  ProfilePage,
  LogoutPage,
  ForgetPasswordPage,
  RegistrationPage,
  UserManagementPage,
  DashboardUserPage,
  AboutUsPage,
  ContactUsPage,
  ContainerTrackingPage,
  TruckingPage,
  ReeferDataPage,
  TariffSimulationPage,
  TariffSimulationReleasePage,
  TariffSimulationReleaseSearchPage,
  CartPage,
  MasterVendorPage,
  MasterTruckPage,
  MasterForwarderPage,
  OrderDetailCustomerPage,
  OrderListCustomerPage,
  OrderListExportInPage,
  CheckoutPage,
  ExportPage,
  MyPaymentPage,
  AssignPage,
  MasterUserPage,
  DepositHistoryPage,
  ExportHistoryPage,
  TruckingHistoryPage,
  ContainerTrackingHistoryPage,
  RegisterPasswordPage,
  AdminPaymentHistoryPage,
  TrackingStatusBehandlePage,
  DocumentSubmissionPage,
  NewDocumentPage,
  ResponsePage,
  FormPage,
  FieldPage,
  ErpPaymentLogPage,
} from './page';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'images',
    'uiLogin',
    'uiProfile',
    'uiForgetPassword',
    'uiCart',
    'uiContactUs',
    'uiAdminPaymentHistory',
    'uiAdminPaymentHistoryAdd',
    'uiAdminPaymentHistoryDetail',
    'uiAssign',
    'uiAssignClearance',
    'uiAssignContainer',
    'uiAssignOrderType',
    'uiAddBookingFeeder',
    'uiBookExportParty',
    'uiBookFeederParty',
    'uiContainerTracking',
    'uiDepositBalance',
    'uiDocSubmissionMyResponse',
    'uiExport',
    'uiReeferDataContainer',
    'uiFeederService',
    'uiFeederServiceDetail',
    'uiForm',
    'uiField',
    'uiFormResponse',
    'uiMasterForwarder',
    'uiMasterTruck',
    'uiMasterUser',
    'uiMasterVendor',
    'uiMoneyLoanHistory',
    'uiMoneyLoanInfo',
    'uiMyPayment',
    'uiMyPaymentHistory',
    'uiMyPaymentTerm',
    'uiMyPaymentOutstanding',
    'uiNewDocumentMyForm',
    'uiPdfViewer',
    'uiPredefinedOrder',
    'uiOrderDetailCustomer',
    'uiOrderDetailVendor',
    'uiOrderListCustomer',
    'uiOrderListExportIn',
    'uiTariffSimulationRelease',
    'uiTariffSimulationReleaseSearch',
    'form',
  ],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'MyCDP2' }) : compose;
const IS_PRODUCTION = true;
const store = IS_PRODUCTION
  ? createStore(persistedReducer, applyMiddleware(thunk))
  : createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk, logger)));
const persistor = persistStore(store);

moment.tz.setDefault('UTC');

export default () => (
  <MuiPickersUtilsProvider utils={DateMomentUtils}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <>
            <Route path="" component={IntervalManager} />
            <Switch>
              <PrivateRoute path={Constant.ROUTE_NAME_HOME} component={HomePage} />
              <PrivateRoute path={Constant.ROUTE_NAME_PROFILE} component={ProfilePage} />
              <PrivateRoute path={Constant.ROUTE_NAME_LOGOUT} component={LogoutPage} />
              <PrivateRoute
                path={Constant.ROUTE_NAME_USER_MANAGEMENT}
                component={UserManagementPage}
              />
              <PrivateRoute
                path={Constant.ROUTE_NAME_MASTER_FORWARDER}
                component={MasterForwarderPage}
              />
              <PrivateRoute path={Constant.ROUTE_NAME_MASTER_TRUCKS} component={MasterTruckPage} />
              <PrivateRoute path={Constant.ROUTE_NAME_MASTER_VENDORS} component={MasterVendorPage} />
              <PrivateRoute path={Constant.ROUTE_NAME_ADMIN_PAYMENT_HISTORY} component={AdminPaymentHistoryPage} />

              <PrivateRoute
                path={Constant.ROUTE_NAME_ORDER_DETAIL_CUSTOMER}
                component={OrderDetailCustomerPage}
              />
              <PrivateRoute
                path={Constant.ROUTE_NAME_ORDER_DETAIL_VENDOR}
                component={OrderDetailCustomerPage}
              />
              <PrivateRoute
                path={Constant.ROUTE_NAME_ORDER_LIST_CUSTOMER}
                component={OrderListCustomerPage}
              />
              <PrivateRoute
                path={Constant.ROUTE_NAME_ORDER_LIST_EXPORT_IN}
                component={OrderListExportInPage}
              />
              <PrivateRoute path={Constant.ROUTE_NAME_DASHBOARD_USER} component={DashboardUserPage} />
              <PrivateRoute
                path={Constant.ROUTE_NAME_CONTAINER_TRACKING}
                component={ContainerTrackingPage}
              />
              <PrivateRoute
                path={Constant.ROUTE_NAME_TRUCKING}
                component={TruckingPage}
              />
              <PrivateRoute
                path={Constant.ROUTE_NAME_REEFER_DATA}
                component={ReeferDataPage}
              />
              <PrivateRoute
                path={Constant.ROUTE_NAME_TARIFF_SIMULATION}
                component={TariffSimulationPage}
              />
              <PrivateRoute
                path={Constant.ROUTE_NAME_TARIFF_SIMULATION_RELEASE_SEARCH}
                component={TariffSimulationReleaseSearchPage}
              />
              <PrivateRoute
                path={Constant.ROUTE_NAME_TARIFF_SIMULATION_RELEASE}
                component={TariffSimulationReleasePage}
              />
              <PrivateRoute
                path={Constant.ROUTE_NAME_TRACKING_STATUS_BEHANDLE}
                component={TrackingStatusBehandlePage}
              />
              <PrivateRoute
                path={Constant.ROUTE_NAME_EXPORT}
                component={ExportPage}
              />
              <PrivateRoute
                path={Constant.ROUTE_NAME_CART_PAGE}
                component={CartPage}
              />
              <PrivateRoute
                path={Constant.ROUTE_NAME_CHECKOUT_PAGE}
                component={CheckoutPage}
              />
              <PrivateRoute
                path={Constant.ROUTE_NAME_MY_PAYMENT}
                component={MyPaymentPage}
              />
              <PrivateRoute
                path={Constant.ROUTE_NAME_DOCUMENT_SUBMISSION}
                component={DocumentSubmissionPage}
              />
              <PrivateRoute
                path={Constant.ROUTE_NAME_NEW_DOCUMENT}
                component={NewDocumentPage}
              />
              <PrivateRoute
                path={Constant.ROUTE_NAME_ASSIGN}
                component={AssignPage}
              />
              <PrivateRoute
                path={Constant.ROUTE_NAME_DEPOSIT_HISTORY}
                component={DepositHistoryPage}
              />
              <AdminRoute
                path={Constant.ROUTE_NAME_MASTER_USERS}
                component={MasterUserPage}
              />
              <AdminRoute
                path={Constant.ROUTE_NAME_FORM}
                component={FormPage}
              />
              <AdminRoute
                path={Constant.ROUTE_NAME_FIELD}
                component={FieldPage}
              />
              <AdminRoute
                path={Constant.ROUTE_NAME_RESPONSE}
                component={ResponsePage}
              />
              <AdminRoute
                path={Constant.ROUTE_NAME_ADMIN_PAYMENT_HISTORY}
                component={AdminPaymentHistoryPage}
              />
              <AdminRoute
                path={Constant.ROUTE_NAME_EXPORT_HISTORY}
                component={ExportHistoryPage}
              />
              <AdminRoute
                path={Constant.ROUTE_NAME_TRUCKING_HISTORY}
                component={TruckingHistoryPage}
              />
              <AdminRoute
                path={Constant.ROUTE_NAME_CONTAINER_TRACKING_HISTORY}
                component={ContainerTrackingHistoryPage}
              />
              <PrivateRoute
                path={Constant.ROUTE_NAME_ADMIN_ERP_PAYMENT_LOG}
                component={ErpPaymentLogPage}
              />
              <Route path={Constant.ROUTE_NAME_REGISTER_PASSWORD} component={RegisterPasswordPage} />
              <Route path={Constant.ROUTE_NAME_LOGIN} component={LoginPage} />
              <Route path={Constant.ROUTE_NAME_FORGET_PASSWORD} component={ForgetPasswordPage} />
              <Route path={Constant.ROUTE_NAME_REGISTER_EMAIL} component={RegistrationPage} />
              <Route path={Constant.ROUTE_NAME_ABOUT_US} component={AboutUsPage} />
              <Route path={Constant.ROUTE_NAME_CONTACT_US} component={ContactUsPage} />
              <Route path="/" component={SplashPage} />
            </Switch>
          </>
        </Router>
      </PersistGate>
    </Provider>
  </MuiPickersUtilsProvider>
);
