import * as Action from '../action';
import * as Constant from '../../constant';

const initialState = {
  downloadingExportOrderHistory: false,
  downloadingExportOrderHistoryDetail: false,
  downloadingPriceDetail: false,
  tappedBlNo: '',
  tappedItemNo: '',
  searchBarText: '',
  dialogContainerDetailStatus: false,

  passwordInputDialog: false,
  password: '',

  filters: {
    [Constant.TABLE_FIELD_CONTAINER_NUMBER]: '',
    [Constant.TABLE_FIELD_BL_NO]: '',
    [Constant.TABLE_FIELD_SEAL_NUMBER]: '',
    [Constant.TABLE_FIELD_KMT_NO]: '',
  },
  sortField: '',
  sortDirection: '',
  activePage: 1,
  pageSize: 5,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.DOWNLOADING_EXPORT_ORDER_HISTORY:
      return { ...state, downloadingExportOrderHistory: action.status };
    case Action.DOWNLOADING_EXPORT_PRICE_DETAIL:
      return { ...state, downloadingPriceDetail: action.value };
    case Action.SET_EXPORT_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case Action.SET_TAPPED_EXPORT_BL_NO:
      return { ...state, tappedBlNo: action.tappedBlNo };
    case Action.DOWNLOADING_EXPORT_ORDER_HISTORY_DETAIL:
      return { ...state, downloadingExportOrderHistoryDetail: action.status };
    case Action.SET_TAPPED_EXPORT_CONTAINER_NO:
      return { ...state, tappedItemNo: action.tappedItemNo };
    case Action.SET_EXPORT_DETAIL_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.value,
        },
      };
    }
    case Action.SET_EXPORT_DETAIL_PAGING: {
      return { ...state, ...action.value };
    }
    case Action.SET_EXPORT_DETAIL_SORT: {
      return { ...state, sortField: action.sortField, sortDirection: action.sortDirection };
    }
    case Action.SET_EXPORT_DETAIL_DIALOG_STATUS:
      return { ...state, dialogContainerDetailStatus: action.value };
    case Action.SET_EXPORT_PASSWORD_INPUT_DIALOG:
      return { ...state, passwordInputDialog: action.status };
    case Action.SET_EXPORT_PASSWORD_TEXT_INPUT:
      return { ...state, password: action.password };
    default:
      return state;
  }
};
