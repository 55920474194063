import { connect } from 'react-redux';
import MyPaymentBaseScreen from './tracking-status-behandle.presentation';
import { setTrackingStatusBehandleHistoryTappedId, setTrackingStatusBehandleOnProgressTappedId, setTrackingStatusBehandleSelectedTab } from '../../redux/action';

const mapStateToProps = state => ({
  notificationOpen: state.uiWebUserMain.notificationDrawerOpen,
  selectedTab: state.uiTrackingStatusBehandle.selectedTab,
  isDownloading: state.uiTrackingStatusBehandleHistory.downloading
  || state.uiTrackingStatusBehandleOnProgress.downloading,
});

const mapDispatchToProps = dispatch => ({
  onAppear: async () => {
    dispatch(setTrackingStatusBehandleSelectedTab(0));
    dispatch(setTrackingStatusBehandleHistoryTappedId(''));
    dispatch(setTrackingStatusBehandleOnProgressTappedId(''));
  },
  onTabPressed: (selectedTab) => {
    dispatch(setTrackingStatusBehandleHistoryTappedId(''));
    dispatch(setTrackingStatusBehandleOnProgressTappedId(''));
    dispatch(setTrackingStatusBehandleSelectedTab(selectedTab));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MyPaymentBaseScreen);
