import * as Action from '../action';

const initialState = {
  addingItem: false,
  removingItem: false,
  addingItemId: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.ADDING_SHOPPING_CART_ITEM:
      return { ...state, addingItem: action.status, addingItemId: action.id };
    case Action.REMOVING_SHOPPING_CART_ITEM:
      return { ...state, removingItem: action.status };
    default:
      return state;
  }
};
