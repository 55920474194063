import _ from 'lodash';
import * as Action from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case Action.ADD_DOWNLOADED_MY_PAYMENT_TERM_ORDER:
      return { ...action.myTermOrder };
    case Action.ADD_MY_PAYMENT_TERM_ORDER:
      return { ...state, [action.myTermOrder.id]: action.myTermOrder };
    case Action.REMOVE_MY_PAYMENT_TERM_ORDER_ITEM:
      return _.omit(state, action.id);
    default: return state;
  }
};
