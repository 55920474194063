import { connect } from 'react-redux';
import _ from 'lodash';
import MasterTruckPage from './master-truck.presentation';
import {
  setMasterTruckFilter,
  setMasterTruckPaging,
  setMasterTruckSort,
  setMasterTruckSelectedId,
  setMasterTruckDetailDialogStatus,
  clearUIError,
  setUIError,
  downloadAllTruckAsync,
  setMasterTruckAddDialogStatus,
} from '../../redux/action';
import * as Constant from '../../constant';

const getFilteredTrucks = (state) => {
  const filteredTruck = _.values(state.trucks);

  const { filters, sortField, sortDirection } = state.uiMasterTruck;

  return filteredTruck
    .sort((left, right) => {
      if (typeof left[sortField] === 'string') {
        if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
          return left[sortField].localeCompare(right[sortField]);
        }
        return right[sortField].localeCompare(left[sortField]);
      }

      if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
        return left[sortField] - right[sortField];
      }
      return right[sortField] - left[sortField];
    })
    .filter(
      item => item.truckNo
        .toUpperCase()
        .indexOf(
          filters[Constant.TABLE_FIELD_MASTER_TRUCK_TRUCK_NO].toUpperCase(),
        ) >= 0,
    )
    .filter(
      item => item.rfid
        .toUpperCase()
        .indexOf(
          filters[Constant.TABLE_FIELD_MASTER_TRUCK_RFID].toUpperCase(),
        ) >= 0,
    )
    .filter(
      item => item.licensePlateNumber
        .toUpperCase()
        .indexOf(
          filters[
            Constant.TABLE_FIELD_MASTER_TRUCK_PLATE_NUMBER
          ].toUpperCase(),
        ) >= 0,
    )
    .filter(
      item => item.vendorId
        .toUpperCase()
        .indexOf(
          filters[Constant.TABLE_FIELD_MASTER_TRUCK_VENDOR].toUpperCase(),
        ) >= 0,
    )
    .filter(
      item => item.cid
        .toUpperCase()
        .indexOf(
          filters[Constant.TABLE_FIELD_MASTER_TRUCK_CID].toUpperCase(),
        ) >= 0,
    )
    .filter(
      item => item.weight
        .toUpperCase()
        .indexOf(
          filters[Constant.TABLE_FIELD_MASTER_TRUCK_WEIGHT].toUpperCase(),
        ) >= 0,
    );
};

const getPageFilteredTrucks = (state) => {
  const { activePage, pageSize } = state.uiMasterTruck;
  const filteredTruck = getFilteredTrucks(state);

  if (filteredTruck) {
    const refinedActivePage = activePage || 1;
    const start = Math.min(
      (refinedActivePage - 1) * pageSize,
      filteredTruck.length,
    );
    const end = Math.min(start + pageSize, filteredTruck.length);
    return filteredTruck.slice(start, end);
  }

  return [];
};

const getTotalPage = (state) => {
  const filteredTruck = getFilteredTrucks(state);
  const { pageSize } = state.uiMasterTruck;
  const totalData = filteredTruck ? filteredTruck.length : 0;

  return Math.max(Math.ceil(totalData / pageSize), 1);
};

const mapStateToProps = state => ({
  downloadingTrucks: state.uiMasterTruck.downloadingTrucks,
  menuOpen: state.uiMenu.menuOpen,
  trucks: getPageFilteredTrucks(state),
  filters: state.uiMasterTruck.filters,
  activePage: state.uiMasterTruck.activePage,
  pageSize: state.uiMasterTruck.pageSize,
  totalPage: getTotalPage(state),
  sortField: state.uiMasterTruck.sortField,
  sortDirection: state.uiMasterTruck.sortDirection,

  dialogAddStatus: state.uiMasterTruck.dialogAddStatus,
  dialogEditStatus: state.uiMasterTruck.dialogEditStatus,
});

const mapDispatchToProps = dispatch => ({
  onAppear: async () => {
    try {
      clearUIError(Constant.RXSTATE_MASTER_TRUCKING);
      await dispatch(downloadAllTruckAsync());
    } catch (error) {
      setUIError(Constant.RXSTATE_MASTER_TRUCKING, error.message);
    }
  },
  onChangeFilter: (filterName, value) => {
    dispatch(setMasterTruckFilter(filterName, value));
  },
  onChangePaging: (pageField, value) => {
    dispatch(setMasterTruckPaging(pageField, value));
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setMasterTruckSort(sortField, sortDirection));
  },
  onAddNewPressed: () => {
    dispatch(setMasterTruckAddDialogStatus(true));
  },
  onAddDialogClosePressed: () => {
    dispatch(setMasterTruckAddDialogStatus(false));
  },
  onEditPressed: (truckId) => {
    dispatch(setMasterTruckSelectedId(truckId));
    dispatch(setMasterTruckDetailDialogStatus(true));
  },
  onEditDialogClosePressed: () => {
    dispatch(setMasterTruckDetailDialogStatus(false));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MasterTruckPage);
