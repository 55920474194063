import { connect } from 'react-redux';
import PageHeader from './page-header.presentation';
import { toggleMenu } from '../../redux/action';
import * as Constant from '../../constant';
import LocalizedString from '../../localization';

const mapStateToProps = state => ({
  menuOpen: state.uiMenu.menuOpen,
});

const mapDispatchToProps = dispatch => ({
  onToggleMenuPressed: (drawerState) => {
    dispatch(toggleMenu(drawerState));
  },
  onProfilePicturePressed: () => {

  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);
