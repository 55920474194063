import * as Action from '../action';

const initialState = {
  submittingEmail: false,
  submittingRegistrationInfo: false,
  error: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.SUBMITTING_EMAIL_REGISTRATION:
      return { ...state, submittingEmail: action.status };
    case Action.SUBMITTING_REGISTRATION_INFO:
      return { ...state, submittingRegistrationInfo: action.status };
    default: return state;
  }
};
