import { downloadingDepositHistory, addDepositHistory } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { authentication, uiDepositHistory } = getState();
  const { token } = authentication;
  const { filters } = uiDepositHistory;

  try {
    dispatch(downloadingDepositHistory(true));
    const depositHistory = await Helper.downloadDepositHistory(token, filters);
    dispatch(addDepositHistory(depositHistory));
  } finally {
    dispatch(downloadingDepositHistory(false));
  }
};
