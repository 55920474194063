import { connect } from 'react-redux';
import BookNewDialog from './book-new-dialog.presentation';

const mapStateToProps = state => ({
  initialValues: {},
  selectedTab: state.uiBookFeeder.activeForm,
  uploadingNewBooking: state.uiBookFeederFeeder.uploadingNewBooking,
  loading: state.uiBookFeederFeeder.downloading,

});

const mapDispatchToProps = () => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BookNewDialog);
