import * as Action from '../action';

const initialState = {
  booking: false,
  downloading: false,
  searchingVesselList: false,
  searchingVoyageList: false,
  disableVoyagePicker: false,
  selectedVessel: '',
  selectedVoyage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.BOOKING_EXPORT_SERVICE:
      return { ...state, booking: action.status };
    case Action.DOWNLOADING_BOOK_EXPORT_LIST:
      return { ...state, downloading: action.status };
    case Action.ADD_SELECTED_BOOK_EXPORT_VESSEL:
      return { ...state, selectedVessel: action.selectedVessel };
    case Action.ADD_SELECTED_BOOK_EXPORT_VOYAGE:
      return { ...state, selectedVoyage: action.selectedVoyage };
    case Action.SEARCHING_VESSEL_LIST:
      return { ...state, searchingVesselList: action.status };
    case Action.SEARCHING_VOYAGE_LIST:
      return { ...state, searchingVoyageList: action.status };
    default: return state;
  }
};
