import { downloadingFeederServiceOrderHistories, addFeederServiceOrderHistories } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  try {
    dispatch(downloadingFeederServiceOrderHistories(true));
    const feederServiceOrderHistories = await Helper.downloadFeederServiceOrderHistories(token);
    dispatch(addFeederServiceOrderHistories(feederServiceOrderHistories));
  } finally {
    dispatch(downloadingFeederServiceOrderHistories(false));
  }
};
