import * as Action from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case Action.ADD_FEEDER_SERVICE_ORDER_DETAILS:
      return { ...action.orderDetails };
    default:
      return state;
  }
};
