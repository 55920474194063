import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import TruckingBaseScreen from './trucking.presentation';
import {
  downloadFeederServiceOrderHistoriesAsync,
  setFeederServiceSort,
  setFeederServicePaging,
  setFeederServiceFilter,
  setFeederServiceSearchText,
  setFeederServiceDetailTappedContainerNo,
  setNewBookingDialogStatus,
  clearUIError,
  downloadMyPaymentTermOrderAsync,
  downloadFeederServiceBLListAsync,
  addToTermOrderTruckingAsync,
  setFeederServicePasswordTextInput,
  setFeederServicePasswordInputDialog,
  showErrorSnackbar,
} from '../../redux/action';
import * as Constant from '../../constant';
import addToShoppingCartTruckingAsync from '../../redux/action/async/addToShoppingCartTruckingAsync';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const getFilteredFeederServiceDetail = (state) => {
  const containers = _.values(state.feederServiceOrderDetail);
  // .filter(item => state.feederServiceOrderHistories[state.uiFeederService.tappedBlNo]
  //   && item.blNo === state.feederServiceOrderHistories[state.uiFeederService.tappedBlNo].refNo);
  const {
    filters, sortField, sortDirection,
  } = state.uiFeederServiceDetail;

  return containers.sort((left, right) => {
    if (typeof left[sortField] === 'string') {
      if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
        return left[sortField].localeCompare(right[sortField]);
      }
      return right[sortField].localeCompare(left[sortField]);
    }

    if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
      return left[sortField] - right[sortField];
    }
    return right[sortField] - left[sortField];
  })
    .filter(item => item.containerNo.toUpperCase().indexOf(
      filters[Constant.TABLE_FIELD_CONTAINER_NUMBER].toUpperCase(),
    ) >= 0)
    .filter(item => (item.sealNo || '-').toUpperCase().indexOf(
      filters[Constant.TABLE_FIELD_SEAL_NUMBER].toUpperCase(),
    ) >= 0)
    .filter(item => item.statusHaulage.toUpperCase().indexOf(
      filters[Constant.TABLE_FIELD_STATUS_HAULAGE].toUpperCase(),
    ) >= 0);
};

const getPageFilteredFeederServiceDetail = (state) => {
  const {
    activePage, pageSize,
  } = state.uiFeederServiceDetail;
  const filteredFeederServiceDetail = getFilteredFeederServiceDetail(state);

  if (filteredFeederServiceDetail) {
    const refinedActivePage = activePage || 1;
    const start = Math.min((refinedActivePage - 1) * pageSize,
      filteredFeederServiceDetail.length);
    const end = Math.min(start + pageSize, filteredFeederServiceDetail.length);
    return filteredFeederServiceDetail.slice(start, end);
  }

  return undefined;
};

const getTotalPage = (state) => {
  const feederServiceDetail = getFilteredFeederServiceDetail(state);
  const { pageSize } = state.uiFeederServiceDetail;
  const totalData = feederServiceDetail ? feederServiceDetail.length : 0;

  return Math.max(Math.ceil(totalData / pageSize), 1);
};

const mapStateToProps = state => ({
  notificationOpen: state.uiWebUserMain.notificationDrawerOpen,
  downloadingFeederService:
    state.uiFeederService.downloadingFeederServiceOrderHistories,
  downloadingFeederServiceDetail:
    state.uiFeederServiceDetail.downloading || state.uiFeederServiceDetail.downloadingPriceDetail,

  userCompany: state.currentUser.company,
  selectedBlInfo: state.feederServiceOrderHistories[state.uiFeederService.tappedBlNo],
  selectedContainerNo: state.uiFeederServiceDetail.tappedContainerNo,
  feederServiceDetail: getPageFilteredFeederServiceDetail(state),
  priceDetail: state.feederServicePriceDetail,

  searchBarText: state.uiFeederService.searchBarText,
  shoppingCart: _.values(state.shoppingCart),
  termOrder: _.values(state.myTermOrder),
  addingShoppingCartItem: state.uiShoppingCart.addingItem || state.uiMyPaymentTerm.addingItem,
  openDialogStatus: state.uiBookFeeder.openDialogStatus,

  pageSize: state.uiFeederServiceDetail.pageSize,
  totalPage: getTotalPage(state),
  activePage: state.uiFeederServiceDetail.activePage,
  filters: state.uiFeederServiceDetail.filters,
  sortField: state.uiFeederServiceDetail.sortField,
  sortDirection: state.uiFeederServiceDetail.sortDirection,

  dialogPasswordInputStatus: state.uiFeederService.passwordInputDialog,
  password: state.uiFeederService.password,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: () => {
    Promise.all([
      dispatch(downloadFeederServiceOrderHistoriesAsync()),
      dispatch(downloadMyPaymentTermOrderAsync()),
    ])
      .catch((error) => {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
        unauthorizedErrorHandler(error, ownProps.history.push);
      });
  },
  onAddToCartPressed: async (trucking) => {
    try {
      dispatch(clearUIError(Constant.RXSTATE_FEEDER_SERVICE));
      await dispatch(addToShoppingCartTruckingAsync(trucking));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onAddToTermPressed: () => {
    dispatch(setFeederServicePasswordInputDialog(true));
  },
  onChangeSearchBarText: (text) => {
    dispatch(setFeederServiceSearchText(text));
  },
  onChangeFilter: (filterName, value) => {
    dispatch(setFeederServiceFilter(filterName, value));
  },
  onChangePaging: (pageField, value) => {
    dispatch(setFeederServicePaging(pageField, value));
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setFeederServiceSort(sortField, sortDirection));
  },
  onDetailPressed: (tappedContainerNo) => {
    dispatch(setFeederServiceDetailTappedContainerNo(tappedContainerNo));
  },
  onCloseDetailDialogPressed: () => {
    dispatch(setFeederServiceDetailTappedContainerNo(''));
  },
  onAddNewBookingPressed: () => {
    dispatch(downloadFeederServiceBLListAsync());
    dispatch(setNewBookingDialogStatus(true));
  },
  onCloseNewBookingDialogPressed: () => {
    dispatch(setNewBookingDialogStatus(false));
  },
  onChangePasswordText: (text) => {
    dispatch(setFeederServicePasswordTextInput(text));
  },
  onCancelPasswordPressed: () => {
    dispatch(setFeederServicePasswordInputDialog(false));
  },
  onSubmitPasswordPressed: async (password, item) => {
    try {
      await dispatch(addToTermOrderTruckingAsync(item,
        Constant.CART_SERVICE_NAME_TRUCKING,
        password));
      dispatch(setFeederServicePasswordTextInput(''));
      dispatch(setFeederServicePasswordInputDialog(false));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(TruckingBaseScreen));
