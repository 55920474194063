import { connect } from 'react-redux';
import ResetPassword from './registration-info.presentation';
import {
  clearUIError, setUIError, registerAsync,
} from '../../redux/action';
import * as Constant from '../../constant';

const mapStateToProps = state => ({
  submitting: state.uiRegistration.submittingRegistrationInfo,
  initialValues: {
    fullName: state.registrationInfo.fullName,
    phone: state.registrationInfo.phone,
    jobTitle: state.registrationInfo.jobTitle,
    password: state.registrationInfo.password,
    repassword: state.registrationInfo.password,
  },
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: async (registrationInfo) => {
    try {
      dispatch(clearUIError(Constant.RXSTATE_REGISTRATION_PAGE));

      await dispatch(registerAsync(registrationInfo, ownProps.history.push));
    } catch (error) {
      dispatch(setUIError(Constant.RXSTATE_REGISTRATION_PAGE, error.message));
    }
  },
  onBack: () => {
    ownProps.history.push(Constant.ROUTE_NAME_PIN_VERIFICATION_REGISTER_EMAIL);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
