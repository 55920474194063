import { TABLE_FIELD_FORM_RESPONSE_FORM_TITLE, TABLE_FIELD_FORM_RESPONSE_SUBMIT_ID } from '../../constant';
import * as Action from '../action';

const initialState = {
  filters: {
    [TABLE_FIELD_FORM_RESPONSE_FORM_TITLE]: '',
    [TABLE_FIELD_FORM_RESPONSE_SUBMIT_ID]: '',
  },

  downloading: false,
  downloadingDetail: false,
  downloadingFile: false,
  downloadingResponseFilterStatuses: false,
  showResponseFilterDialog: false,
  showDetail: false,

  selectedStatus: '',
  tempSelectedStatus: '',
  selectedUser: '',
  tempSelectedUser: '',
  userSearchString: '',

  activePage: 0,
  pageSize: 20,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.DOWNLOADING_FORM_RESPONSES:
      return { ...state, downloading: action.status };
    case Action.DOWNLOADING_FORM_RESPONSE_DETAIL:
      return { ...state, downloadingDetail: action.status };
    case Action.SHOW_FORM_RESPONSE_DETAIL:
      return { ...state, showDetail: action.status };
    case Action.SHOW_RESPONSE_FILTER_DIALOG:
      return { ...state, showResponseFilterDialog: action.status };
    case Action.SET_SELECTED_STATUS_RESPONSE_FILTER:
      return { ...state, selectedStatus: action.value };
    case Action.SET_SELECTED_USER_RESPONSE_FILTER:
      return { ...state, selectedUser: action.value };
    case Action.SET_TEMP_SELECTED_STATUS_RESPONSE_FILTER:
      return { ...state, tempSelectedStatus: action.value };
    case Action.SET_TEMP_SELECTED_USER_RESPONSE_FILTER:
      return { ...state, tempSelectedUser: action.value };
    case Action.SET_RESPONSE_FILTER_USER_SEARCH_TEXT:
      return { ...state, userSearchString: action.text };
    case Action.SET_FORM_RESPONSE_ACTIVE_PAGE:
      return { ...state, activePage: action.page };
    case Action.SET_FORM_RESPONSE_PAGE_SIZE:
      return { ...state, pageSize: action.size };
    case Action.SET_FORM_RESPONSE_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.value,
        },
      };
    }
    case Action.DOWNLOADING_FORM_RESPONSE_DETAIL_FILE:
      return { ...state, downloadingFile: action.status };
    case Action.DOWNLOADING_FORM_RESPONSE_FILTER_STATUSES:
      return { ...state, downloadingResponseFilterStatuses: action.status };
    case Action.CLEAR_FORM_RESPONSES:
      return initialState;
    default: return state;
  }
};
