import * as Action from '../action';
import * as Constant from '../../constant';

const initialState = {
  downloadingReeferDatas: false,
  tappedBlNo: '',
  searchBarText: '',
  filters: {
    [Constant.TABLE_FIELD_CONTAINER_NUMBER]: '',
    [Constant.TABLE_FIELD_SERVICE]: '',
    [Constant.TABLE_FIELD_SIZE]: '',
    [Constant.TABLE_FIELD_GATE_IN_CDP]: '',
    [Constant.TABLE_FIELD_PARTY]: '',
  },
  sortField: '',
  sortDirection: '',
  activePage: 1,
  pageSize: 5,
  dialogReeferDataDetailStatus: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.DOWNLOADING_REEFER_DATA:
      return { ...state, downloadingReeferDatas: action.status };
    case Action.SET_TAPPED_REEFER_DATA_BL_NO:
      return {
        ...state,
        tappedBlNo: action.tappedBlNo,
      };
    case Action.SET_REEFER_DATA_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case Action.SET_REEFER_DATA_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.value,
        },
      };
    }
    case Action.SET_REEFER_DATA_PAGING: {
      return { ...state, ...action.value };
    }
    case Action.SET_REEFER_DATA_SORT: {
      return { ...state, sortField: action.sortField, sortDirection: action.sortDirection };
    }
    case Action.SET_REEFER_DATA_DETAIL_DIALOG_STATUS: {
      return { ...state, dialogReeferDataDetailStatus: action.status };
    }
    default: return state;
  }
};
