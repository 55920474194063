import * as Helper from '../../../helper';
import { addFeederServiceOrderDetails, downloadingFeederServiceOrderDetail } from '../simple-action';

export default blNo => async (dispatch, getState) => {
  try {
    dispatch(downloadingFeederServiceOrderDetail(true));
    const { token } = getState().authentication;
    const orderDetails = await Helper.downloadFeederServiceOrderDetail(blNo, token);
    dispatch(addFeederServiceOrderDetails(orderDetails));
  } finally {
    dispatch(downloadingFeederServiceOrderDetail(false));
  }
};
