import { connect } from 'react-redux';
import { isEmpty, isObject } from 'lodash';
import { change } from 'redux-form';
import ErpPaymentLogPage from './erp-payment-log.presentation';
import {
  clearErpPaymentLogs,
  setErpPaymentLogActivePage,
  setMasterUserSort,
  setTempSelectedStatusErpPaymentLogFilter,
  showErrorSnackbar,
  showErpPaymentLogDetail,
  showErpPaymentLogFilterDialog,
  setErpPaymentLogPageSize,
  setErpPaymentLogConfirmRetryDialog,
  setSelectedErpPaymentId,
} from '../../redux/action';
import LocalizedString from '../../localization';
import {
  sortDesc, toCurrency, toMoment, unauthorizedErrorHandler,
} from '../../helper';
import downloadErpPaymentLogsAsync from '../../redux/action/async/downloadErpPaymentLogsAsync';
import {
  MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING,
  RXFORM_ERP_PAYMENT_LOG_FILTER_DIALOG,
  ERP_PAYMENT_LOG_STATUS_OPTION,
  RXFIELD_ERP_PAYMENT_LOG_DETAIL_PROFORMA_NO,
  RXFIELD_ERP_PAYMENT_LOG_DETAIL_COMPANY,
  RXFIELD_ERP_PAYMENT_LOG_AMOUNT,
  RXFIELD_ERP_PAYMENT_LOG_DETAIL_STATUS,
  RXFIELD_ERP_PAYMENT_LOG_DETAIL_STATUS_NOTES,
  RXFIELD_ERP_PAYMENT_LOG_DETAIL_CREATED_DATE,
  RXFIELD_ERP_PAYMENT_LOG_DETAIL_RETRY,
  RXFIELD_ERP_PAYMENT_LOG_DETAIL_LAST_RETRY_ON,
  RXFIELD_ERP_PAYMENT_LOG_DETAIL_PAYLOAD,
  RXFIELD_ERP_PAYMENT_LOG_DETAIL_RESPONSE,
} from '../../constant';
import downloadErpPaymentLogDetailAsync from '../../redux/action/async/downloadErpPaymentLogDetailAsync';
import retryErpPaymentAsync from '../../redux/action/async/retryErpPaymentAsync';

const getErpPaymentLogs = (state) => {
  const { erpPaymentLogs } = state;

  if (!isEmpty(erpPaymentLogs.data)) {
    const data = Object.values(erpPaymentLogs.data)
      .sort((a, b) => sortDesc(a.createdDate, b.createdDate))
      .map((x) => {
        const status = ERP_PAYMENT_LOG_STATUS_OPTION
          .find(y => x.status === y.value);
        return ({
          ...x,
          createdDate: toMoment(x.createdDate)
            .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING),
          status: status ? status.label : '',
        });
      });

    return data;
  }
  return [];
};

const getInitialValues = (state) => {
  const { erpPaymentLogDetail } = state;

  const status = erpPaymentLogDetail.status ? ERP_PAYMENT_LOG_STATUS_OPTION
    .find(y => erpPaymentLogDetail.status === y.value) : '-';

  return isEmpty(erpPaymentLogDetail) ? ({
    [RXFIELD_ERP_PAYMENT_LOG_DETAIL_PROFORMA_NO]: '',
    [RXFIELD_ERP_PAYMENT_LOG_DETAIL_COMPANY]: '',
    [RXFIELD_ERP_PAYMENT_LOG_AMOUNT]: '',
    [RXFIELD_ERP_PAYMENT_LOG_DETAIL_STATUS]: '',
    [RXFIELD_ERP_PAYMENT_LOG_DETAIL_STATUS_NOTES]: '',
    [RXFIELD_ERP_PAYMENT_LOG_DETAIL_CREATED_DATE]: '',
    [RXFIELD_ERP_PAYMENT_LOG_DETAIL_RETRY]: '',
    [RXFIELD_ERP_PAYMENT_LOG_DETAIL_LAST_RETRY_ON]: '',
    [RXFIELD_ERP_PAYMENT_LOG_DETAIL_PAYLOAD]: '',
    [RXFIELD_ERP_PAYMENT_LOG_DETAIL_RESPONSE]: '',
  }) : ({
    [RXFIELD_ERP_PAYMENT_LOG_DETAIL_PROFORMA_NO]: erpPaymentLogDetail[
      RXFIELD_ERP_PAYMENT_LOG_DETAIL_PROFORMA_NO],

    [RXFIELD_ERP_PAYMENT_LOG_DETAIL_COMPANY]: erpPaymentLogDetail[
      RXFIELD_ERP_PAYMENT_LOG_DETAIL_COMPANY],

    [RXFIELD_ERP_PAYMENT_LOG_AMOUNT]: toCurrency(erpPaymentLogDetail[
      RXFIELD_ERP_PAYMENT_LOG_AMOUNT] || 0),

    [RXFIELD_ERP_PAYMENT_LOG_DETAIL_STATUS]: isObject(status) ? status.label : status,

    [RXFIELD_ERP_PAYMENT_LOG_DETAIL_STATUS_NOTES]: erpPaymentLogDetail[
      RXFIELD_ERP_PAYMENT_LOG_DETAIL_STATUS_NOTES],

    [RXFIELD_ERP_PAYMENT_LOG_DETAIL_CREATED_DATE]: toMoment(erpPaymentLogDetail.createdDate)
      .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING),

    [RXFIELD_ERP_PAYMENT_LOG_DETAIL_RETRY]: erpPaymentLogDetail[
      RXFIELD_ERP_PAYMENT_LOG_DETAIL_RETRY],

    [RXFIELD_ERP_PAYMENT_LOG_DETAIL_LAST_RETRY_ON]: toMoment(erpPaymentLogDetail[
      RXFIELD_ERP_PAYMENT_LOG_DETAIL_LAST_RETRY_ON])
      .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING),

    [RXFIELD_ERP_PAYMENT_LOG_DETAIL_PAYLOAD]: erpPaymentLogDetail[
      RXFIELD_ERP_PAYMENT_LOG_DETAIL_PAYLOAD],
    [RXFIELD_ERP_PAYMENT_LOG_DETAIL_RESPONSE]: erpPaymentLogDetail[
        RXFIELD_ERP_PAYMENT_LOG_DETAIL_RESPONSE],
  });
};

const mapStateToProps = state => ({
  downloading: state.uiErpPaymentLog.downloading,
  downloadingDetail: state.uiErpPaymentLog.downloadingDetail,
  showDetail: state.uiErpPaymentLog.showDetail,
  menuOpen: state.uiMenu.menuOpen,
  erpPaymentLogs: getErpPaymentLogs(state),
  initialValues: getInitialValues(state),
  selectedStatus: state.uiErpPaymentLog.selectedStatus,
  proformaNoFilterValue: state.uiErpPaymentLog.proformaNoFilterValue,
  pageSize: state.uiErpPaymentLog.pageSize,

  activePage: state.uiErpPaymentLog.activePage,
  totalPage: state.erpPaymentLogs.meta && state.erpPaymentLogs.meta.totalPages
    ? state.erpPaymentLogs.meta.totalPages : 0,
  sortField: state.uiMasterUser.sortField,
  sortDirection: state.uiMasterUser.sortDirection,
  showErpPaymentLogFilterDialog: state.uiErpPaymentLog.showErpPaymentLogFilterDialog,
  showConfirmRetryDialog: state.uiErpPaymentLog.showConfirmRetryDialog,
  retryingErpPayment: state.uiErpPaymentLog.retryingErpPayment,
  selectedErpPaymentId: state.uiErpPaymentLog.selectedErpPaymentId,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    dispatch(clearErpPaymentLogs());
    dispatch(setSelectedErpPaymentId(''));
    try {
      await dispatch(downloadErpPaymentLogsAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onChangePaging: async (value) => {
    dispatch(setErpPaymentLogActivePage(value));
    try {
      await dispatch(downloadErpPaymentLogsAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setMasterUserSort(sortField, sortDirection));
  },
  onDetailPressed: async (id) => {
    dispatch(setSelectedErpPaymentId(id));
    try {
      await dispatch(downloadErpPaymentLogDetailAsync(id));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onBackPressed: () => {
    dispatch(showErpPaymentLogDetail(false));
  },
  onErpPaymentLogFilterDialogClosePressed: (selectedStatus, proformaNoFilterValue) => {
    dispatch(showErpPaymentLogFilterDialog(false));
    dispatch(change(
      RXFORM_ERP_PAYMENT_LOG_FILTER_DIALOG, RXFIELD_ERP_PAYMENT_LOG_DETAIL_STATUS, selectedStatus,
    ));
    dispatch(change(
      RXFORM_ERP_PAYMENT_LOG_FILTER_DIALOG,
      RXFIELD_ERP_PAYMENT_LOG_DETAIL_PROFORMA_NO, proformaNoFilterValue,
    ));
    dispatch(setTempSelectedStatusErpPaymentLogFilter(''));
  },
  onFilterPressed: () => {
    dispatch(showErpPaymentLogFilterDialog(true));
  },
  onChangeRow: async (pageField, value) => {
    dispatch(setErpPaymentLogPageSize(value));
    try {
      await dispatch(downloadErpPaymentLogsAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onRetryPressed: () => {
    dispatch(setErpPaymentLogConfirmRetryDialog(true));
  },
  onCancelRetryPressed: () => {
    dispatch(setErpPaymentLogConfirmRetryDialog(false));
  },
  onOkRetryPressed: async () => {
    try {
      await dispatch(retryErpPaymentAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ErpPaymentLogPage);
