import uuid from 'uuid/v4';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import moment from 'moment';
import PartyTab from './party.presentation';
import {
  setExpandedDropdownArray,
  setUploadedAttachment,
  setUIError,
  removeUploadedAttachment,
  setEditParty,
  removeEditParty,
  addBookParty,
  removeBookParty,
  modifyBookParty,
  removeNewParty,
  downloadFeederServiceBookPartyListAsync,
  setNewParty,
  uploadBookPartyDocumentAsync,
  setUploadingNewBookingStatus,
  setBookFeederActiveForm,
  showErrorSnackbar,
  downloadFeederServiceDisclaimerAsync,
} from '../../redux/action';
import { RXSTATE_FEEDER_SERVICE } from '../../constant';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const getParty = state => _.values(state.feederServiceBookPartyList.bookParties)
  .filter(party => !party.deleted);

const mapStateToProps = state => ({
  parties: getParty(state),
  inEditParty: state.uiBookFeederParty.inEditParty,
  newParty: state.uiBookFeederParty.newParty,
  disclaimer: state.feederServiceDisclaimer,
  uploadedAttachment: state.uiBookFeederParty.uploadedAttachment,
  downloadingPartyList: state.uiBookFeederParty.downloadingPartyList,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    const p1 = dispatch(downloadFeederServiceDisclaimerAsync());
    const p2 = dispatch(downloadFeederServiceBookPartyListAsync());
    Promise.all([p1, p2])
      .catch((error) => {
        dispatch(
          showErrorSnackbar(
            LocalizedString.common.alertTitleError,
            error.message,
          ),
        );
        unauthorizedErrorHandler(error, ownProps.history.push);
      });
  },
  onAddParty: () => {
    const partyId = uuid();
    dispatch(addBookParty({
      id: partyId,
    }));
    dispatch(setNewParty(partyId));
  },
  onRemoveParty: (partyId) => {
    dispatch(removeBookParty(partyId));
  },
  onEditPartyPressed: (partyId) => {
    dispatch(setEditParty(partyId));
    dispatch(setExpandedDropdownArray(partyId));
  },
  onRemoveAttachment: (id) => {
    dispatch(removeUploadedAttachment(id));
  },
  onSelectedMedia: (files: FileList) => {
    try {
      for (let i = 0; i < files.length; i += 1) {
        const item = files.item(i);
        const reader = new FileReader();
        reader.onload = ({ target: { result } }) => {
          const imgId = `IMG${moment(new Date()).format('YYYYMMDDHHmmssSSS')}`;
          dispatch(setUploadedAttachment({ id: imgId, content: result }, item.name, item.size));
        };
        reader.readAsDataURL(item);
      }
    } catch (error) {
      dispatch(setUIError(RXSTATE_FEEDER_SERVICE, error.message));
    }
  },
  onSubmitEditPressed: async (partyId, values) => {
    await dispatch(modifyBookParty(partyId, values));
    dispatch(removeEditParty(partyId));
    dispatch(removeNewParty(partyId));
  },
  onCancelEditPressed: (partyId, newParty) => {
    if (newParty.includes(partyId)) {
      dispatch(removeNewParty(partyId));
      dispatch(removeBookParty(partyId));
    } else {
      dispatch(removeEditParty(partyId));
    }
  },
  onPreviousPressed: () => {
    dispatch(setBookFeederActiveForm(0));
  },
  onNextPressed: async () => {
    try {
      dispatch(setUploadingNewBookingStatus(true));
      await dispatch(uploadBookPartyDocumentAsync());
      dispatch(setBookFeederActiveForm(2));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    } finally {
      dispatch(setUploadingNewBookingStatus(false));
    }
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartyTab));
