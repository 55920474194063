import * as Action from '../action';

const initialState = {
  downloadingDetail: false,
  showErpPaymentLogFilterDialog: false,
  showDetail: false,
  showConfirmRetryDialog: false,
  retryingErpPayment: false,

  selectedStatus: '',
  tempSelectedStatus: '',
  proformaNoFilterValue: '',
  selectedErpPaymentId: '',

  activePage: 0,
  pageSize: 20,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.DOWNLOADING_ERP_PAYMENT_LOGS:
      return { ...state, downloading: action.status };
    case Action.DOWNLOADING_ERP_PAYMENT_LOG_DETAIL:
      return { ...state, downloadingDetail: action.status };
    case Action.SHOW_ERP_PAYMENT_LOG_DETAIL:
      return { ...state, showDetail: action.status };
    case Action.SHOW_ERP_PAYMENT_LOG_FILTER_DIALOG:
      return { ...state, showErpPaymentLogFilterDialog: action.status };
    case Action.SET_ERP_PAYMENT_LOG_ACTIVE_PAGE:
      return { ...state, activePage: action.page };
    case Action.SET_ERP_PAYMENT_LOG_PAGE_SIZE:
      return { ...state, pageSize: action.size };
    case Action.CLEAR_ERP_PAYMENT_LOGS:
      return initialState;
    case Action.SET_TEMP_SELECTED_STATUS_ERP_PAYMENT_LOG_FILTER:
      return { ...state, tempSelectedStatus: action.value };
    case Action.SET_SELECTED_STATUS_ERP_PAYMENT_LOG_FILTER:
      return { ...state, selectedStatus: action.value };
    case Action.SET_PROFORMA_NO_ERP_PAYMENT_LOG_FILTER:
      return { ...state, proformaNoFilterValue: action.value };
    case Action.SHOW_ERP_PAYMENT_LOG_CONFIRM_RETRY_DIALOG:
      return { ...state, showConfirmRetryDialog: action.view };
    case Action.RETRYING_ERP_PAYMENT:
      return { ...state, retryingErpPayment: action.status };
    case Action.SET_SELECTED_ERP_PAYMENT_ID:
      return { ...state, selectedErpPaymentId: action.value };
    default: return state;
  }
};
