import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  Icon,
  DialogContent,
  Grid,
  TextField,
} from '@material-ui/core';
import moment from 'moment';
import LocalizedString from '../../localization';
import { MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING } from '../../constant';
import { toMoment } from '../../helper';

const styles = theme => ({
  dialogContent: {
    marginTop: '24px',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  tabIndicator: {
    display: 'none',
  },
  tabRoot: {
    marginTop: theme.spacing.unit,
    overflow: 'visible',
  },
  tabElement: {
    backgroundColor: 'rgba(200, 200, 200, 0.2)',
    textTransform: 'capitalize',
  },
  tabElementActive: {
    backgroundColor: '#3f51b5',
    color: '#ffffff !important',
    transform: 'scaleY(1.1)',
    borderRadius: '4px',
    boxShadow: '0px 4px 20px rgba(0, 102, 203, 0.45)',
  },
  tabScroller: {
    overflowX: 'visible',
  },
  timelineContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px !important',
    position: 'relative',
  },
  timelineLine: {
    width: '1px',
    height: '100%',
    backgroundColor: '#979797',
    marginTop: '12px',
  },
  timelineItem: {
    width: '32px',
    border: '1px solid #0066CB',
    borderRadius: '48px',
    position: 'absolute',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    fontWeight: 'bolder',
    marginTop: '12px',
  },
});

const ExportDetailDialog = ({
  classes,
  open,
  containerInfo,
  onAppear,
  onClose,
}) => {
  if (!containerInfo) {
    return (<></>);
  }

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onEnter={() => onAppear(containerInfo.blNo, containerInfo.containerNo)}
      >
        <DialogTitle>
          <div className={classes.dialogTitle}>
            <Typography color="primary" variant="h6">
              {LocalizedString.containerTracking.lblTitleContainerNo}
              &nbsp;
              {containerInfo.containerNo}
            </Typography>
            <IconButton
              onClick={onClose}
            >
              <Icon>close</Icon>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <TextField
                readonly
                fullWidth
                value={(containerInfo && containerInfo.coRef) || '-'}
                label={LocalizedString.export.labelCoRef}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                readonly
                fullWidth
                value={(containerInfo && containerInfo.billTo) || '-'}
                label={LocalizedString.export.labelBillTo}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                readonly
                fullWidth
                value={(containerInfo && containerInfo.forwarderId) || '-'}
                label={LocalizedString.export.labelForwarderId}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                readonly
                fullWidth
                value={(containerInfo && containerInfo.kmtNo) || '-'}
                label={LocalizedString.export.labelKmtNo}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                readonly
                fullWidth
                value={(containerInfo && containerInfo.containerNo) || '-'}
                label={LocalizedString.export.labelContainerNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                readonly
                fullWidth
                value={(containerInfo && containerInfo.blNo) || '-'}
                label={LocalizedString.export.labelDoNo}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                readonly
                fullWidth
                value={(containerInfo && containerInfo.ctrType) || '-'}
                label={LocalizedString.export.labelContainerType}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                readonly
                fullWidth
                value={(containerInfo && containerInfo.ctrSize) || '-'}
                label={LocalizedString.export.labelContainerSize}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                readonly
                fullWidth
                value={(containerInfo && containerInfo.ctrStatus) || '-'}
                label={LocalizedString.export.labelContainerStatus}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                readonly
                fullWidth
                value={(containerInfo && containerInfo.sealNo) || '-'}
                label={LocalizedString.export.labelSealNo}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                readonly
                fullWidth
                value={(containerInfo && containerInfo.gross) || '-'}
                label={LocalizedString.export.labelGross}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={24}>
                <Grid item xs={1} className={classes.timelineContainer}>
                  <div className={classes.timelineLine} />
                  <div className={classes.timelineItem}>1</div>
                </Grid>
                <Grid item xs={11}>
                  <Grid container spacing={24}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        readOnly
                        fullWidth
                        value={containerInfo && containerInfo.gateInCdp ? toMoment(containerInfo.gateInCdp).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING) : '-'}
                        label={LocalizedString.export.labelGateInCdp}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={24}>
                <Grid item xs={1} className={classes.timelineContainer}>
                  <div className={classes.timelineLine} />
                  <div className={classes.timelineItem}>2</div>
                </Grid>
                <Grid item xs={11}>
                  <Grid container spacing={24}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        readOnly
                        fullWidth
                        value={containerInfo && containerInfo.gateOutCdp ? toMoment(containerInfo.gateOutCdp).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING) : '-'}
                        label={LocalizedString.export.labelGateOutCdp}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={24}>
                <Grid item xs={1} className={classes.timelineContainer}>
                  <div className={classes.timelineItem}>3</div>
                  <div className={classes.timelineLine} />
                </Grid>
                <Grid item xs={11}>
                  <Grid container spacing={24}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        readOnly
                        fullWidth
                        value={containerInfo && containerInfo.gateInTo ? toMoment(containerInfo.gateInTo).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING) : '-'}
                        label={LocalizedString.export.labelGateInTo}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={24}>
                <Grid item xs={1} className={classes.timelineContainer}>
                  
                  <div className={classes.timelineItem}>4</div>
                </Grid>
                <Grid item xs={11}>
                  <Grid container spacing={24}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        readOnly
                        fullWidth
                        value={containerInfo && containerInfo.loadDateToVessel ? toMoment(containerInfo.loadDateToVessel).format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING) : '-'}
                        label={LocalizedString.export.labelLoadedToVessel}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
ExportDetailDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  open: PropTypes.bool.isRequired,

  containerInfo: PropTypes.shape(PropTypes.any).isRequired,

  onAppear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(ExportDetailDialog);
