import { setGlobalLoading } from '../simple-action';
import { downloadMoneyLoanHistoryCsv, openNewTab } from '../../../helper';
import { REST_URL_MONEY_LOAN_HISTORY_DOWNLOAD_CSV } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(setGlobalLoading(true));

    const { token } = getState().authentication;

    const { printId } = await downloadMoneyLoanHistoryCsv(token);

    openNewTab(REST_URL_MONEY_LOAN_HISTORY_DOWNLOAD_CSV.replace(/{printId}/, printId));
  } finally {
    dispatch(setGlobalLoading(false));
  }
};
