import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  withStyles, ButtonBase, Grid, Typography, Checkbox,
} from '@material-ui/core';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import * as Constant from '../../constant';
import LocalizedString from '../../localization';

const getItemSize = items => index => (items.length - 1 === index ? 312 : 320);

const styles = theme => ({
  cardItem: {
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '6px',
    boxSizing: 'border-box',
    padding: '16px',
    marginBottom: theme.spacing.unit,
    '&:last-child': {
      marginBottom: 0,
    },
    '&:after': {
      content: '',
      position: 'absolute',
      bottom: '0px',
      left: 0,
      width: '100%',
      height: '10px',
      backgroundColor: '#f4f4f4',
    },
  },
  cardItemActive: {
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  spacer: {
    height: '8px',
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

const isChecked = (multiSelectedBl, item) => !!multiSelectedBl
  .find(bl => bl.blNo === item.blNo && bl.exim === item.exim);

class ContainerTrackingBlCard extends Component {
  renderCheckbox(item) {
    const {
      multiSelectBl,
      userRole,
      onCheckboxPressed,
    } = this.props;

    const clearanceDisabled = item.forwarderId || userRole === Constant.ROLE_PPJK || item.invoiceNo;

    if (clearanceDisabled) {
      return null;
    }

    return (
      <Checkbox
        checked={isChecked(multiSelectBl, item)}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onCheckboxPressed(multiSelectBl, item);
        }}
      />
    );
  }

  render() {
    const {
      classes,
      billingLines,
      selectedBl,
      onMorePressed,
    } = this.props;

    return (
      <>
        <AutoSizer>
          {({ width, height }) => (
            <List
              autoHeight
              className="List"
              height={height}
              itemCount={billingLines.length}
              itemSize={getItemSize(billingLines)}
              width={width}
            >
              {({ index, style }) => {
                const item = billingLines[index];
                const titleLabel = item
                  .exim.toUpperCase() !== Constant.CART_SERVICE_NAME_EXPORT.toUpperCase()
                  ? LocalizedString.assign.labelBlNumber
                  : LocalizedString.assign.labelDoNo;
                return (
                  <div style={style}>
                    <ButtonBase
                      component="div"
                      key={item.blNo}
                      className={classNames(classes.cardItem, {
                        [classes.cardItemActive]: selectedBl
                          && item.blNo === selectedBl.blNo
                          && item.exim === selectedBl.exim,
                      })}
                      onClick={() => onMorePressed(item.blNo, item.exim)}
                    >
                      <Grid container spacing={24}>
                        <Grid item xs={12} md={12}>
                          <div style={{ display: 'flex', alignItems: 'center', height: '32px' }}>
                            {this.renderCheckbox(item)}
                            <Typography color="primary" variant="h6" className={classes.ellipsis}>
                              {titleLabel}
                              &nbsp;
                              {item.blNo}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="caption">
                            {LocalizedString.assign.labelInvoiceNo}
                          </Typography>
                          <Typography className={classes.ellipsis}>
                            {item.invoiceNo || '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.alignRight}>
                          <Typography variant="caption">
                            {LocalizedString.assign.labelSoNo}
                          </Typography>
                          <Typography className={classes.ellipsis}>
                            {item.soNo || '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Typography variant="caption">
                            {LocalizedString.assign.labelConsignee}
                          </Typography>
                          <Typography className={classes.ellipsis}>
                            {item.billTo || '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.alignRight}>
                          <Typography variant="caption">
                            {LocalizedString.assign.labelPpjkClearance}
                          </Typography>
                          <Typography className={classes.ellipsis}>
                            {item.forwarderId || '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.alignRight}>
                          <Typography variant="caption">
                            {LocalizedString.assign.labelTrucker}
                          </Typography>
                          <Typography className={classes.ellipsis}>
                            {item.trucker || '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.alignRight}>
                          <Typography variant="caption">
                            {LocalizedString.assign.labelService}
                          </Typography>
                          <Typography className={classes.ellipsis}>
                            {item.exim || '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ButtonBase>
                    <div className={classNames({
                      [classes.spacer]: index !== billingLines.length - 1,
                    })}
                    />
                  </div>
                );
              }}
            </List>
          )}
        </AutoSizer>
      </>
    );
  }
}
ContainerTrackingBlCard.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  billingLines: PropTypes.shape(PropTypes.any).isRequired,
  multiSelectBl: PropTypes.arrayOf(PropTypes.any).isRequired,
  userRole: PropTypes.string.isRequired,
  selectedBl: PropTypes.shape(PropTypes.any).isRequired,
  onCheckboxPressed: PropTypes.func.isRequired,
  onMorePressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(ContainerTrackingBlCard);
