import * as Action from '../action';

const initialState = {
  dialogPaymentDokuStatus: false,
  dialogConfirmClosePaymentStatus: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.SET_DIALOG_PAYMENT_DOKU_STATUS:
      return { ...state, dialogPaymentDokuStatus: action.value };
    case Action.SET_DIALOG_CONFIRM_CLOSE_PAYMENT_DOKU_STATUS:
      return { ...state, dialogConfirmClosePaymentStatus: action.value };
    default: return state;
  }
};
