import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  clearUIError, uploadProfileDetailAsync, setUIError,
  setProfileScreenEditMode, uploadingProfileDetails, showErrorSnackbar,
} from '../../redux/action';
import * as Constant from '../../constant';
import ProfileInfo from './profile-info.presentation';
import LocalizedString from '../../localization';

const mapStateToProps = (state) => {
  const { currentUser } = state;
  const user = state.users[currentUser.id];
  return ({
    editable: state.uiProfile.editable,
    companyPaymentMethod: state.currentUser.company.paymentMethod || '',
    uploadingProfileDetail: state.uiProfile.uploadingProfileDetail,
    isCorporateAdmin: state.currentUser.corporateAdmin,
    initialValues: {
      id: user.id,
      username: user.username,
      email: user.email,
      fullName: user.fullName,
      phone: user.phone,
      jobTitle: user.jobTitle,
      userRole: user.role,
      companyName: user.company ? user.company.name : '',
      companyRole: user.company ? user.company.role : '',
      address: user.company ? user.company.address : '',
    },
  });
};

const mapDispatchToProps = dispatch => ({
  onSaveButtonPressed: async (profile) => {
    try {
      dispatch(uploadingProfileDetails(true));
      dispatch(clearUIError(Constant.RXSTATE_PROFILE_PAGE));
      await dispatch(uploadProfileDetailAsync(profile));
      dispatch(setProfileScreenEditMode(false));
    } catch (error) {
      // dispatch(setUIError(Constant.RXSTATE_PROFILE_PAGE, error.message));
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    } finally {
      dispatch(uploadingProfileDetails(false));
    }
  },
  onCancelButtonPressed: () => {
    dispatch(reset(Constant.RXFORM_PROFILE_SCREEN_INFO));
    dispatch(setProfileScreenEditMode(false));
  },
  onEditButtonPressed: () => {
    dispatch(setProfileScreenEditMode(true));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo);
