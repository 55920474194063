import React from 'react';
import PropTypes from 'prop-types';
import LocalizedString from '../../localization';
import { Dialog, DialogContent, DialogActions, Button, withStyles, Typography, TextField, InputAdornment, Icon } from '@material-ui/core';
import { ROLE_PPJK, PAYMENT_TYPE_DEPOSIT, DEFAULT_STYLES } from '../../constant';

const styles = theme => ({
  btnSubmit: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  centeredActions: {
    justifyContent: 'center',
    paddingBottom: '24px',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
});

const CheckoutPasswordInputDialog = ({
  classes,
  dialogPaymentConfirmStatus,
  password,

  userCompany,
  shoppingCheckoutItems,
  error,

  onChangePasswordText,
  onCancelPressed,
  onSubmitPressed,
}) => {
  const isPpjkAndAllAllowDeposit = userCompany.role.toUpperCase() === ROLE_PPJK
  && Object.values(shoppingCheckoutItems)
    .every(blNumber => blNumber.every(({ item }) => item.allowPpjkToUseDeposit));;

    const renderPaymentConfirmationDialogContent = () => {
      if (isPpjkAndAllAllowDeposit) {
        const checkoutItem = Object.values(shoppingCheckoutItems).shift();
        const billToName = checkoutItem && checkoutItem.length > 0 ? checkoutItem[0].item.billTo : '';
        return (
          <>
            <Typography className={classes.paymentConfirmTitle}>
              {`${LocalizedString.cart.msgEnterPinAllowPPJKTrue} ${billToName}`}
            </Typography>
            <TextField
              type="password"
              label="PIN"
              value={password}
              fullWidth
              InputProps={{
                endAdornment: (<InputAdornment position="end"><Icon color="disabled">lock</Icon></InputAdornment>),
              }}
              onChange={({ nativeEvent }) => { onChangePasswordText(nativeEvent.target.value); }}
            />
            {error && (
              <Typography className={classes.errorMessage}>{error}</Typography>
            )}
          </>
        );
      }
  
      if (userCompany.paymentMethod === PAYMENT_TYPE_DEPOSIT) {
        return (
          <>
            <Typography className={classes.paymentConfirmTitle}>
              Please enter your PIN
            </Typography>
            <TextField
              type="password"
              label="PIN"
              value={password}
              fullWidth
              InputProps={{
                endAdornment: (<InputAdornment position="end"><Icon color="disabled">lock</Icon></InputAdornment>),
              }}
              onChange={({ nativeEvent }) => { onChangePasswordText(nativeEvent.target.value); }}
            />
            {error && (
              <Typography className={classes.errorMessage}>{error}</Typography>
            )}
          </>
        );
      }
  
      return (
        <>
          <Typography className={classes.paymentConfirmTitle}>
            Please enter your login password
          </Typography>
          <TextField
            type="password"
            label="Password"
            value={password}
            fullWidth
            InputProps={{
              endAdornment: (<InputAdornment position="end"><Icon color="disabled">lock</Icon></InputAdornment>),
            }}
            onChange={({ nativeEvent }) => { onChangePasswordText(nativeEvent.target.value); }}
          />
          {error && (
            <Typography className={classes.errorMessage}>{error}</Typography>
          )}
        </>
      );
    };

  return (
    <Dialog
      open={dialogPaymentConfirmStatus}
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        {renderPaymentConfirmationDialogContent()}

      </DialogContent>
      <DialogActions className={classes.centeredActions}>
        <Button
          color="primary"
          onClick={() => onCancelPressed()}
        >
          {LocalizedString.common.buttonCaptionCancel}
        </Button>
        &emsp;&emsp;
        <Button
          variant="contained"
          color="primary"
          className={classes.btnSubmit}
          onClick={() => onSubmitPressed(password, isPpjkAndAllAllowDeposit)}
        >
          {LocalizedString.common.buttonCaptionSubmit}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
CheckoutPasswordInputDialog.propTypes = {
  dialogPaymentConfirmStatus: PropTypes.bool.isRequired,
  password: PropTypes.string.isRequired,

  userCompany: PropTypes.shape(PropTypes.any).isRequired,
  shoppingCheckoutItems: PropTypes.shape(PropTypes.any).isRequired,

  onCancelPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
}

export default withStyles(styles)(CheckoutPasswordInputDialog);
