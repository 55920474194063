import { connect } from 'react-redux';
import _ from 'lodash';
import MasterVendorPage from './master-vendor.presentation';
import {
  setMasterVendorFilter,
  setMasterVendorPaging,
  setMasterVendorSort,
  setMasterVendorSelectedId,
  setMasterVendorDetailDialogStatus,
  setMasterVendorAddDialogStatus,
  downloadAllVendorAsync,
  setUIError,
  clearUIError,
} from '../../redux/action';
import * as Constant from '../../constant';

const getFilteredVendors = (state) => {
  const filteredVendor = _.values(state.vendors).map((item, index) => ({
    ...item,
    index: index + 1,
  }));

  const { filters, sortField, sortDirection } = state.uiMasterVendor;

  return filteredVendor
    .sort((left, right) => {
      if (typeof left[sortField] === 'string') {
        if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
          return left[sortField].localeCompare(right[sortField]);
        }
        return right[sortField].localeCompare(left[sortField]);
      }

      if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
        return left[sortField] - right[sortField];
      }
      return right[sortField] - left[sortField];
    })
    .filter(
      item => `${item.index}`
        .toUpperCase()
        .indexOf(
          filters[Constant.TABLE_FIELD_MASTER_VENDOR_ID].toUpperCase(),
        ) >= 0,
    )
    .filter(
      item => item.id
        .toUpperCase()
        .indexOf(
          filters[Constant.TABLE_FIELD_MASTER_VENDOR_VENDOR_ID].toUpperCase(),
        ) >= 0,
    )
    .filter(
      item => item.name
        .toUpperCase()
        .indexOf(
          filters[
            Constant.TABLE_FIELD_MASTER_VENDOR_VENDOR_NAME
          ].toUpperCase(),
        ) >= 0,
    );
};

const getPageFilteredVendors = (state) => {
  const { activePage, pageSize } = state.uiMasterVendor;
  const filteredVendor = getFilteredVendors(state);

  if (filteredVendor) {
    const refinedActivePage = activePage || 1;
    const start = Math.min(
      (refinedActivePage - 1) * pageSize,
      filteredVendor.length,
    );
    const end = Math.min(start + pageSize, filteredVendor.length);
    return filteredVendor.slice(start, end);
  }

  return [];
};

const getTotalPage = (state) => {
  const filteredVendor = getFilteredVendors(state);
  const { pageSize } = state.uiMasterVendor;
  const totalData = filteredVendor ? filteredVendor.length : 0;

  return Math.max(Math.ceil(totalData / pageSize), 1);
};

const mapStateToProps = state => ({
  downloadingVendors: state.uiMasterVendor.downloadingVendors,
  menuOpen: state.uiMenu.menuOpen,
  vendors: getPageFilteredVendors(state),
  filters: state.uiMasterVendor.filters,
  activePage: state.uiMasterVendor.activePage,
  pageSize: state.uiMasterVendor.pageSize,
  totalPage: getTotalPage(state),
  sortField: state.uiMasterVendor.sortField,
  sortDirection: state.uiMasterVendor.sortDirection,

  dialogAddStatus: state.uiMasterVendor.dialogAddStatus,
  dialogEditStatus: state.uiMasterVendor.dialogEditStatus,
});

const mapDispatchToProps = dispatch => ({
  onAppear: async () => {
    try {
      clearUIError(Constant.RXSTATE_MASTER_VENDOR);
      await dispatch(downloadAllVendorAsync());
    } catch (error) {
      setUIError(Constant.RXSTATE_MASTER_VENDOR, error.message);
    }
  },
  onChangeFilter: (filterName, value) => {
    dispatch(setMasterVendorFilter(filterName, value));
  },
  onChangePaging: (pageField, value) => {
    dispatch(setMasterVendorPaging(pageField, value));
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setMasterVendorSort(sortField, sortDirection));
  },
  onAddNewPressed: () => {
    dispatch(setMasterVendorAddDialogStatus(true));
  },
  onAddDialogClosePressed: () => {
    dispatch(setMasterVendorAddDialogStatus(false));
  },
  onEditPressed: (vendorId) => {
    dispatch(setMasterVendorSelectedId(vendorId));
    dispatch(setMasterVendorDetailDialogStatus(true));
  },
  onEditDialogClosePressed: () => {
    dispatch(setMasterVendorDetailDialogStatus(false));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MasterVendorPage);
