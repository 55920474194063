import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PriceTab from './price.presentation';
import {
  showSnackbar,
  setBookExportActiveForm,
  removeBookExportServiceinfo,
  clearBookExportParties,
  downloadExportOrderHistoryAsync,
  setBookExportDialogStatus,
  showErrorSnackbar,
} from '../../redux/action';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const mapStateToProps = state => ({
  nilAdmin: state.bookExport.priceInfo.adminFee,
  nilTaxBasis: state.bookExport.priceInfo.taxBasis,
  nilVat: state.bookExport.priceInfo.vat,
  nilTotal: state.bookExport.priceInfo.totalPayment,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFinishPressed: async () => {
    try {
      dispatch(setBookExportDialogStatus(false));
      dispatch(setBookExportActiveForm(0));
      dispatch(removeBookExportServiceinfo());
      dispatch(clearBookExportParties());
      await dispatch(downloadExportOrderHistoryAsync());
      dispatch(showSnackbar(LocalizedString.common.labelSuccess,
        LocalizedString.export.ntfBookingSuccess));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PriceTab));
