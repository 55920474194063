import { connect } from 'react-redux';
import AboutUsScreen from './about-us.presentation';

const mapStateToProps = state => ({
  notificationOpen: state.uiWebUserMain.notificationDrawerOpen,
  stepperContents: [
    {
      // label: 'San Francisco – Oakland Bay Bridge, United States',
      imgPath:
        '/assets/images/bg_cdp.jpg',
    },
    {
      // label: 'Bird',
      imgPath:
        '/assets/images/bg_aboutus_2.jpg',
    },
    {
      // label: 'Bali, Indonesia',
      imgPath:
        '/assets/images/bg_aboutus_3.jpg',
    },
  ],
});

export default connect(mapStateToProps, {})(AboutUsScreen);
