import _ from 'lodash';
import * as Action from '../action';

const initialAuthentication = { token: '', rememberMe: false };
export default (state = initialAuthentication, action) => {
  switch (action.type) {
    case Action.ADD_TOKEN:
      return { token: action.token };
    case Action.REMOVE_TOKEN:
      return _.omit(state, 'token');
    case Action.SET_REMEMBER_ME:
      return { ...state, rememberMe: action.value };
    default: return state;
  }
};
