import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { change } from 'redux-form';
import AssignContainerTruck from './assign-container-truck.presentation';
import {
  setAssignContainerActiveForm,
  uploadAssignContainerAsync,
  showErrorSnackbar,
  uploadAssignTruckExportAsync,
} from '../../redux/action';
import {
  RXFORM_ASSIGN_CONTAINER_TRUCK,
  RXFIELD_TRUCK_NO,
  RXFIELD_VENDOR_ID,
  RXFIELD_ASSIGN_CONTAINER_SIZE,
  RXFIELD_ASSIGN_CONTAINER_TYPE,
  RXSTATE_SEAL_NUMBER,
  CART_SERVICE_NAME_EXPORT,
} from '../../constant';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const getTruckList = (state) => {
  const allTrucks = _.values(state.truckList);
  const containerToAssign = state.uiAssignTruck.assignTruckForm;
  if (containerToAssign
    && containerToAssign.truckNo
    && !allTrucks.find(item => item.truckNo === containerToAssign.truckNo)) {
    allTrucks.push({ truckNo: containerToAssign.truckNo });
  }
  return allTrucks.map(item => ({ label: item.truckNo, value: item.truckNo }));
};

const getSelectedAssignBl = state => _.values(state.assign)
  .find(item => item.blNo === state.uiAssignOrderType.tappedBlNo
    && item.exim === state.uiAssign.tappedService);


const mapStateToProps = state => ({
  lstVendor: _.values(state.ppjkListByAssign).map(item => ({ label: item.name, value: item.id })),
  lstTruck: getTruckList(state),
  userRole: state.currentUser.company.role.toUpperCase(),
  selectedAssignBl: getSelectedAssignBl(state) || null,
  assignContainer: state.uiAssignTruck.assignTruckForm,
  initialValues: {
    containerNo: state.uiAssignTruck.assignTruckForm.containerNo,
    blNo: state.uiAssignTruck.assignTruckForm.blNo,
    vendorId: state.uiAssignTruck.assignTruckForm.truckNo
      ? undefined
      : state.uiAssignTruck.assignTruckForm.forwarderId,
    truckNo: state.uiAssignTruck.assignTruckForm.truckNo,
    [RXFIELD_ASSIGN_CONTAINER_SIZE]: state.uiAssignTruck.assignTruckForm.containerSize,
    [RXFIELD_ASSIGN_CONTAINER_TYPE]: state.uiAssignTruck.assignTruckForm.containerType,
  },
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSelectedVendor: () => {
    dispatch(change(RXFORM_ASSIGN_CONTAINER_TRUCK, RXFIELD_TRUCK_NO, null));
  },
  onSelectedTruckNo: () => {
    dispatch(change(RXFORM_ASSIGN_CONTAINER_TRUCK, RXFIELD_VENDOR_ID, null));
  },
  onPreviousPressed: () => {
    dispatch(setAssignContainerActiveForm(0));
  },
  onSubmitPressed: async (values, selectedAssignBl) => {
    try {
      if (selectedAssignBl.exim.toUpperCase() === CART_SERVICE_NAME_EXPORT.toUpperCase()) {
        await dispatch(uploadAssignTruckExportAsync(values, ownProps.history));
      } else {
        await dispatch(uploadAssignContainerAsync(values, ownProps.history));
      }
      dispatch(setAssignContainerActiveForm(0));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssignContainerTruck));
