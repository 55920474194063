import * as Action from '../action';
import {
  TABLE_FIELD_MY_PAYMENT_HISTORY_BL_NUMBER,
  TABLE_FIELD_MY_PAYMENT_HISTORY_SERVICE,
  TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_NUMBER,
  TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_TO,
  TABLE_FIELD_MY_PAYMENT_HISTORY_AMOUNT,
} from '../../constant';

const initialState = {
  downloading: false,
  searchBarText: '',
  tappedItemNo: '',

  // proforma
  filters: {
    [TABLE_FIELD_MY_PAYMENT_HISTORY_BL_NUMBER]: '',
    [TABLE_FIELD_MY_PAYMENT_HISTORY_SERVICE]: '',
    [TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_NUMBER]: '',
    [TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_TO]: '',
    [TABLE_FIELD_MY_PAYMENT_HISTORY_AMOUNT]: '',
  },
  sortField: '',
  sortDirection: '',
  activePage: 1,
  pageSize: 5,

  changingOrderPaymentStatus: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.DOWNLOADING_MY_PAYMENT_OUTSTANDING_ORDER:
      return { ...state, downloading: action.status };
    case Action.SET_MY_PAYMENT_OUTSTANDING_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case Action.SET_TAPPED_OUTSTANDING_ITEM_NO:
      return { ...state, tappedItemNo: action.tappedItemNo };

    // proforma
    case Action.SET_MY_PAYMENT_OUTSTANDING_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.value,
        },
      };
    }
    case Action.SET_MY_PAYMENT_OUTSTANDING_PAGING: {
      return { ...state, ...action.value };
    }
    case Action.SET_MY_PAYMENT_OUTSTANDING_SORT: {
      return { ...state, sortField: action.sortField, sortDirection: action.sortDirection };
    }
    case Action.SET_CHANGING_ORDER_PAYMENT_STATUS: {
      return { ...state, changingOrderPaymentStatus: action.status };
    }
    default: return state;
  }
};
