import moment from 'moment-timezone';
import { updatingDepositBalance, setDepositBalanceNextAutoUpdate, updateDepositBalance } from '../simple-action';
import { PAYMENT_TYPE_DEPOSIT } from '../../../constant';
import { downloadDepositBalance } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { company } = getState().currentUser;
  const { nextAutoUpdate } = getState().uiDepositBalance;
  const now = moment();

  if (!token || !company || company.paymentMethod !== PAYMENT_TYPE_DEPOSIT) {
    return;
  }

  if (nextAutoUpdate === null
    || moment(nextAutoUpdate).toDate().valueOf() > now.toDate().valueOf()) {
    return;
  }

  try {
    dispatch(updatingDepositBalance(true));
    dispatch(setDepositBalanceNextAutoUpdate(null));

    const result = await downloadDepositBalance(token);
    dispatch(updateDepositBalance(result.depositAccount.balance));
  } catch {
    // do nothing
  } finally {
    const nextUpdate = now.add('5', 'minutes');
    dispatch(updatingDepositBalance(false));
    dispatch(setDepositBalanceNextAutoUpdate(nextUpdate));
  }
};
