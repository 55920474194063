import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { change } from 'redux-form';
import ErpPaymentLogFilterDialog from './erp-payment-log-filter-dialog.presentation';
import {
  showErrorSnackbar,
  showErpPaymentLogFilterDialog,
  setTempSelectedStatusErpPaymentLogFilter,
  setSelectedStatusErpPaymentLogFilter,
  setProformaNoErpPaymentLogFilter,
} from '../../redux/action';
import {
  RXFIELD_ERP_PAYMENT_LOG_DETAIL_PROFORMA_NO,
  RXFIELD_ERP_PAYMENT_LOG_DETAIL_STATUS,
  RXFORM_ERP_PAYMENT_LOG_FILTER_DIALOG,
} from '../../constant';
import LocalizedString from '../../localization';
import downloadErpPaymentLogsAsync from '../../redux/action/async/downloadErpPaymentLogsAsync';

const getInitialValues = (state) => {
  const {
    tempSelectedStatus, selectedStatus, proformaNoFilterValue,
  } = state.uiErpPaymentLog;
  const status = tempSelectedStatus || selectedStatus;

  return ({
    [RXFIELD_ERP_PAYMENT_LOG_DETAIL_PROFORMA_NO]: proformaNoFilterValue,
    [RXFIELD_ERP_PAYMENT_LOG_DETAIL_STATUS]: status,
  });
};

const mapStateToProps = state => ({
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = dispatch => ({
  onSubmitPressed: async (values) => {
    dispatch(showErpPaymentLogFilterDialog(false));
    dispatch(setSelectedStatusErpPaymentLogFilter(values[RXFIELD_ERP_PAYMENT_LOG_DETAIL_STATUS]));
    dispatch(setProformaNoErpPaymentLogFilter(values[RXFIELD_ERP_PAYMENT_LOG_DETAIL_PROFORMA_NO]));
    dispatch(setTempSelectedStatusErpPaymentLogFilter(''));
    try {
      await dispatch(downloadErpPaymentLogsAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    }
  },
  onStatusSelected: (value) => {
    dispatch(setTempSelectedStatusErpPaymentLogFilter(value));
    dispatch(change(
      RXFORM_ERP_PAYMENT_LOG_FILTER_DIALOG, RXFIELD_ERP_PAYMENT_LOG_DETAIL_STATUS, value,
    ));
  },
  onResetPressed: async () => {
    dispatch(showErpPaymentLogFilterDialog(false));
    dispatch(change(RXFORM_ERP_PAYMENT_LOG_FILTER_DIALOG, RXFIELD_ERP_PAYMENT_LOG_DETAIL_STATUS, ''));
    dispatch(change(RXFORM_ERP_PAYMENT_LOG_FILTER_DIALOG, RXFIELD_ERP_PAYMENT_LOG_DETAIL_PROFORMA_NO, ''));
    dispatch(setSelectedStatusErpPaymentLogFilter(''));
    dispatch(setProformaNoErpPaymentLogFilter(''));
    dispatch(setTempSelectedStatusErpPaymentLogFilter(''));
    try {
      await dispatch(downloadErpPaymentLogsAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
    }
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErpPaymentLogFilterDialog));
