import { DOWNLOADING_PREDEFINED_ORDER, SET_PREDEFINED_ORDER_SEARCH_TEXT, SET_TAPPED_PAYMENT_ORDER_BL_NO } from '../action';

const initialState = {
  downloading: false,
  searchText: '',
  tappedBlNo: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_PREDEFINED_ORDER:
      return { ...state, downloading: action.status };
    case SET_PREDEFINED_ORDER_SEARCH_TEXT:
      return { ...state, searchText: action.text };
    case SET_TAPPED_PAYMENT_ORDER_BL_NO:
      return { ...state, tappedBlNo: action.tappedBlNo };
    default: return state;
  }
};
