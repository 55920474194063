import { downloadingReeferData, addReeferData } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  try {
    dispatch(downloadingReeferData(true));
    const reeferData = await Helper.downloadReeferData(token);
    dispatch(addReeferData(reeferData));
  } finally {
    dispatch(downloadingReeferData(false));
  }
};
