import { downloadAdminPaymentHistory, toMoment } from '../../../helper';
import {
  clearAdminPaymentHistoryData,
  addAdminPaymentHistoryData,
  downloadingAdminPaymentHistoryStatus,
} from '../simple-action';
import { TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PAID_ON, MOMENT_DATE_API_FORMAT_STRING } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAdminPaymentHistoryStatus(true));

    const { token } = getState().authentication;
    const {
      activePage, pageSize, sortField, sortDirection, searchText,
    } = getState().uiAdminPaymentHistory;

    // build filter query strings
    // const filterItems = Object.keys(filters)
    //   .filter(key => key !== TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PAID_ON)
    //   .map(key => `${key}=${filters[key]}`);

    // if (filters[TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PAID_ON]) {
    //   filterItems.push(`${TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PAID_ON}=${toMoment(filters[TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PAID_ON]).format(MOMENT_DATE_API_FORMAT_STRING)}`);
    // }

    const histories = await downloadAdminPaymentHistory(
      activePage - 1,
      pageSize,
      sortField,
      sortDirection,
      searchText,
      token,
    );
    dispatch(clearAdminPaymentHistoryData());
    dispatch(addAdminPaymentHistoryData(histories.contentObj,
      histories.content, histories.totalElements));
  } finally {
    dispatch(downloadingAdminPaymentHistoryStatus(false));
  }
};
