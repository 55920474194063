import {
  addTariffSimulation,
  setTariffSimulationReleaseSearchText,
  submittingHostBl,
} from '../simple-action';
import { submitHostBl } from '../../../helper';
import { ROUTE_NAME_TARIFF_SIMULATION_RELEASE } from '../../../constant';

export default (blNo, masterBl, ownProps) => async (dispatch, getState) => {
  const { token } = getState().authentication;

  try {
    dispatch(submittingHostBl(true));
    const result = await submitHostBl(blNo, masterBl, token);
    if (result) {
      dispatch(addTariffSimulation(result));
    }

    dispatch(setTariffSimulationReleaseSearchText(masterBl));
    ownProps.history.push(ROUTE_NAME_TARIFF_SIMULATION_RELEASE);
  } finally {
    dispatch(submittingHostBl(false));
  }
};
