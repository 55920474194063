import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  withStyles,
  CssBaseline,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Select,
  MenuItem,
  TableCell,
  TableSortLabel,
  Input,
  Table,
  TableHead,
  TableRow,
  CardHeader,
  TableBody,
  TextField,
  Fab,
  Icon,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {
  DEFAULT_STYLES,
  DRAWER_NOTIFICATION_WIDTH,
  RXTABLEFIELD_ACTIVE_PAGE,
  TABLE_SORT_ASCENDING,
  TABLE_SORT_DESCENDING,
  RXTABLEFIELD_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS,
  MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING,
  TABLE_FIELD_ADMIN_PAYMENT_HISTORY_CHANNEL,
  TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PROFORMA_NO,
  TABLE_FIELD_ADMIN_PAYMENT_HISTORY_STATUS,
  TABLE_FIELD_ADMIN_PAYMENT_HISTORY_METHOD,
  TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PAID_ON,
  TABLE_FIELD_ADMIN_PAYMENT_HISTORY_CREATED_BY,
  TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PAYMENT_CODE,
  TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PAYMENT_CODE_VALID_UNTIL,
  DEFAULT_DOWNLOAD_LIMIT_DAYS_OPTIONS,
  TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PAID_BY,
} from '../../constant';
import { MainMenu, PageHeaderAdmin, LoadingIndicator } from '../../component';
import LocalizedString from '../../localization';
import {
  isSortFieldActive, getSortIcon, toMoment, toCurrency,
} from '../../helper';
import PaymentAddDialog from './create-payment-dialog.container';
import PaymentDetailDialog from './payment-detail-dialog.container';

const styles = theme => ({
  backIcon: {
    ...DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  btnNewBooking: {
    backgroundColor: '#5CB860',
    color: '#ffffff',
    width: '256px',
    '&:hover': {
      backgroundColor: '#558b2f',
    },
  },
  centeredActions: {
    justifyContent: 'center',
    paddingBottom: '24px',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentMenuDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '256px',
      width: 'auto',
    },
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${DRAWER_NOTIFICATION_WIDTH}px)`,
    },
  },
  contentDrawerClosed: {
    width: '100%',
  },
  currencyColor: {
    color: '#3f51b5',
  },
  detailHeader: {
    color: '#0066CB',
  },
  grow: {
    flexGrow: 1,
  },
  itemPerRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageNavigatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    color: '#0266B4',
  },
  paymentCompleteTitle: {
    textTransform: 'uppercase',
    color: '#5CB860',
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '16px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  paymentSuccessInfoItems: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 0px',
    borderBottom: '1px solid #EEEEEE',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  alignRight: {
    textAlign: 'right',
  },
  searchButton: {
    ...DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
  downloadContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  },
  downloadInnerContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
  },
  downloadOptionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '160px',
    flexShrink: 0,
    marginBottom: '0.4rem',
  },
});

class AdminPaymentHistory extends Component {
  componentDidMount() {
    this.props.onAppear();
  }

  getTotalAmount = items => items.reduce((acc, curr) => acc + curr.amount, 0);

  handleChangeSort = (newSortField) => {
    const { sortField, sortDirection, onChangeSort } = this.props;

    if (newSortField === sortField) {
      onChangeSort(
        sortField,
        sortDirection === TABLE_SORT_ASCENDING
          ? TABLE_SORT_DESCENDING
          : TABLE_SORT_ASCENDING,
      );
    } else {
      onChangeSort(newSortField, TABLE_SORT_ASCENDING);
    }
  };

  renderSearchBar = () => {
    const { classes, searchText, onSearchTextChange } = this.props;

    return (
      <>
        <div style={{ display: 'flex', marginTop: '24px' }}>
          <div style={{ flexGrow: 0, alignSelf: 'flex-end' }}>
            <TextField
              fullWidth
              placeholder="Search..."
              value={searchText}
              onChange={event => onSearchTextChange(event.target.value)}
            />
          </div>
          <div style={{ display: 'inline-flex', marginLeft: '8px' }}>
            <Fab
              size="small"
              variant="round"
              color="default"
              className={classes.searchButton}
            >
              <Icon>search</Icon>
            </Fab>
          </div>
        </div>
      </>
    );
  };

  renderDownloadOption = () => {
    const {
      classes,
      downloadLimitOption,
      onChangeDownloadOption,
      onDownloadPressed,
    } = this.props;

    return (
      <>
        <div className={classes.downloadContainer}>
          <div className={classes.downloadInnerContainer}>
            <div className={classes.downloadOptionContainer}>
              <Typography>{LocalizedString.adminPaymentHistory.labelDuration}</Typography>
              <Select
                value={downloadLimitOption}
                onChange={event => onChangeDownloadOption(event.target.value)}
              >
                {DEFAULT_DOWNLOAD_LIMIT_DAYS_OPTIONS.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="body1">{LocalizedString.adminPaymentHistory.labelDays}</Typography>
            </div>
            <Button variant="outlined" onClick={onDownloadPressed}>Download</Button>
          </div>
        </div>
      </>
    );
  };

  renderTableItemPerRow = () => {
    const { classes, pageSize, onChangePaging } = this.props;

    return (
      <div className={classes.itemPerRowContainer}>
        <Select
          value={pageSize}
          onChange={event => onChangePaging(
            RXTABLEFIELD_PAGE_SIZE,
            event.target.value,
          )}
        >
          {DEFAULT_PAGE_SIZE_OPTIONS.map(item => (
            <MenuItem value={item} key={item}>
              {item}
              &nbsp;
              {LocalizedString.common.labelRows}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }

  renderTableCellHeader = (label, fieldId) => {
    if (!fieldId) {
      return (
        <TableCell>
          {label}
        </TableCell>
      );
    }

    const { sortField, sortDirection } = this.props;
    return (
      <TableCell>
        <TableSortLabel
          active={
            isSortFieldActive(sortField, fieldId)
          }
          IconComponent={getSortIcon(
            isSortFieldActive(sortField, fieldId),
            sortDirection,
          )}
          onClick={
            () => this.handleChangeSort(fieldId)
          }
        >
          {label}
        </TableSortLabel>
      </TableCell>
    );
  }

  // renderTableCellStringFilter = (field) => {
  //   const { filters, onChangeFilter } = this.props;
  //   return (
  //     <TableCell>
  //       <Input
  //         type="text"
  //         fullWidth
  //         value={filters[field]}
  //         onChange={
  //           ({ nativeEvent }) => onChangeFilter(
  //             field,
  //             nativeEvent.target.value,
  //           )
  //         }
  //         placeholder={LocalizedString.common.placeholderSearch}
  //       />
  //     </TableCell>
  //   );
  // }

  // renderTableCellDateFilter = (field) => {
  //   const { filters, onChangeFilter } = this.props;
  //   return (
  //     <TableCell>
  //       <DatePicker
  //         keyboard
  //         value={filters[field] || null}
  //         format={MOMENT_DATE_FORMAT_STRING}
  //         placeholder={MOMENT_DATE_FORMAT_STRING}
  //         mask={(dateValue) => {
  //           if (dateValue) {
  //             return [/\d/, /\d/, ' ', /\w/, /\w/, /\w/, ' ', /\d/, /\d/, /\d/, /\d/];
  //           }
  //           return [];
  //         }}
  //         readOnly
  //         onChange={value => onChangeFilter(field, value)}
  //         clearable
  //       />
  //     </TableCell>
  //   );
  // };

  renderTableCellBody = (text, className) => (
    <TableCell className={className}>
      <Typography>
        {text || '-'}
      </Typography>
    </TableCell>
  );

  renderTableCellAction = (payment) => {
    const { onEditPressed } = this.props;
    return (
      <TableCell>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => onEditPressed(payment.proformaNo)}
        >
          {LocalizedString.common.buttonCaptionDetail}
        </Button>
      </TableCell>
    );
  }

  renderTablePagination = () => {
    const {
      classes, activePage, totalPage, onChangePaging,
    } = this.props;

    return (
      <div className={classes.tablePagination}>
        <Button
          variant="contained"
          style={{ flexGrow: 1 }}
          disabled={activePage === 1}
          onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage - 1)}
        >
          {LocalizedString.common.labelPagingPrevButton}
        </Button>

        <div className={classes.tablePaginationPageInput}>
          <span style={{ fontWeight: 500 }}>{LocalizedString.common.labelPagingPage}</span>
          <Input
            type="number"
            value={activePage}
            style={{ width: '50%' }}
            inputProps={{
              min: 1,
              max: totalPage,
              step: 1,
              style: { textAlign: 'center' },
            }}
            onChange={({ nativeEvent }) => onChangePaging(
              RXTABLEFIELD_ACTIVE_PAGE,
              parseInt(nativeEvent.target.value, 10),
            )}
          />
          <span style={{ fontWeight: 500 }}>
            {`${LocalizedString.common.labelPagingPageOf} ${totalPage}`}
          </span>
        </div>

        <Button
          variant="contained"
          style={{ flexGrow: 1 }}
          disabled={activePage === totalPage}
          onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage + 1)}
        >
          {LocalizedString.common.labelPagingNextButton}
        </Button>
      </div>
    );
  }

  render() {
    const {
      classes,
      menuOpen,
      downloading,

      payments,
      downloadLimitOption,
      paymentResult,

      dialogAddStatus,
      dialogDetailStatus,
      dialogPaymentCompleteStatus,

      onAddNewPressed,
      onAddDialogClosePressed,
      onEditDialogClosePressed,
      onClosePaymentCompletePressed,
    } = this.props;

    return (
      <>
        <div className={classes.root}>
          <CssBaseline />

          <PageHeaderAdmin />

          <MainMenu />

          <div
            className={
              classNames(classes.content, {
                [classes.contentMenuDrawerOpen]: menuOpen,
              })
            }
            style={{ padding: '16px' }}
          >
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <div className={classes.pageNavigatorContainer}>
                      <div>
                        <Typography variant="h5" className={classes.pageTitle}>{LocalizedString.adminPaymentHistory.title}</Typography>
                        <Typography variant="caption">{LocalizedString.adminPaymentHistory.caption}</Typography>
                      </div>
                      <div className={classes.grow} />
                      <div>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          classes={{ containedSecondary: classes.btnNewBooking }}
                          onClick={onAddNewPressed}
                        >
                          {LocalizedString.adminPaymentHistory.btnAddNew}
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    title={(
                      <>
                        <Typography variant="h6" className={classes.detailHeader}>
                          {LocalizedString.adminPaymentHistory.detailTitle}
                        </Typography>
                        {this.renderDownloadOption()}
                        {this.renderTableItemPerRow()}
                        <div />
                        {this.renderSearchBar()}
                      </>
                    )}
                  />
                  <CardContent>
                    <div style={{ width: '100%', overflow: 'auto' }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {this.renderTableCellHeader(
                              LocalizedString.adminPaymentHistory.labelProformaNo,
                              TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PROFORMA_NO,
                            )}
                            {this.renderTableCellHeader(
                              LocalizedString.adminPaymentHistory.labelStatus,
                              TABLE_FIELD_ADMIN_PAYMENT_HISTORY_STATUS,
                            )}
                            {this.renderTableCellHeader(
                              LocalizedString.adminPaymentHistory.labelMethod,
                              TABLE_FIELD_ADMIN_PAYMENT_HISTORY_METHOD,
                            )}
                            {this.renderTableCellHeader(
                              LocalizedString.adminPaymentHistory.labelPaymentCode,
                              TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PAYMENT_CODE,
                            )}
                            {this.renderTableCellHeader(
                              LocalizedString.adminPaymentHistory.labelPaymentCodeValidUntil,
                              TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PAYMENT_CODE_VALID_UNTIL,
                            )}
                            {this.renderTableCellHeader(
                              LocalizedString.adminPaymentHistory.labelTotalAmount,
                            )}
                            {this.renderTableCellHeader(
                              LocalizedString.adminPaymentHistory.labelItems,
                            )}
                            {this.renderTableCellHeader(
                              LocalizedString.adminPaymentHistory.labelPaidOn,
                              TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PAID_ON,
                            )}
                            {this.renderTableCellHeader(
                              LocalizedString.adminPaymentHistory.labelChannel,
                              TABLE_FIELD_ADMIN_PAYMENT_HISTORY_CHANNEL,
                            )}
                            {this.renderTableCellHeader(
                              LocalizedString.adminPaymentHistory.labelCreatedBy,
                              TABLE_FIELD_ADMIN_PAYMENT_HISTORY_CREATED_BY,
                            )}
                            {this.renderTableCellHeader(
                              LocalizedString.adminPaymentHistory.labelPaidBy,
                              TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PAID_BY,
                            )}
                            <TableCell>{LocalizedString.common.labelActions}</TableCell>
                          </TableRow>
                          {/* <TableRow>
                            {this.renderTableCellStringFilter(
                              TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PROFORMA_NO,
                            )}
                            {this.renderTableCellStringFilter(
                              TABLE_FIELD_ADMIN_PAYMENT_HISTORY_STATUS,
                            )}
                            {this.renderTableCellStringFilter(
                              TABLE_FIELD_ADMIN_PAYMENT_HISTORY_METHOD,
                            )}
                            {this.renderTableCellStringFilter(
                              TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PAYMENT_CODE,
                            )}
                            {this.renderTableCellDateFilter(
                              TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PAYMENT_CODE_VALID_UNTIL,
                            )}
                            <TableCell />
                            <TableCell />
                            {this.renderTableCellDateFilter(
                              TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PAID_ON,
                            )}
                            {this.renderTableCellStringFilter(
                              TABLE_FIELD_ADMIN_PAYMENT_HISTORY_CHANNEL,
                            )}
                            {this.renderTableCellStringFilter(
                              TABLE_FIELD_ADMIN_PAYMENT_HISTORY_CREATED_BY,
                            )}
                            <TableCell />
                          </TableRow> */}
                        </TableHead>
                        <TableBody>
                          {payments && payments.map(payment => (
                            <TableRow>
                              {this.renderTableCellBody(payment.proformaNo)}
                              {this.renderTableCellBody(payment.paymentStatus)}
                              {this.renderTableCellBody(payment.paymentMethod)}
                              {this.renderTableCellBody(payment.paymentCode)}
                              {this.renderTableCellBody(payment.paymentCodeValidUntil
                                ? toMoment(payment.paymentCodeValidUntil)
                                  .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)
                                : '-')}
                              {this.renderTableCellBody(
                                toCurrency(this.getTotalAmount(payment.items)),
                                classes.alignRight,
                              )}
                              {this.renderTableCellBody(payment.items.length)}
                              {this.renderTableCellBody(payment.paidOn
                                ? toMoment(payment.paidOn)
                                  .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)
                                : '-')}
                              {this.renderTableCellBody(payment.paymentChannel)}
                              {this.renderTableCellBody(payment.createdByUser)}
                              {this.renderTableCellBody(payment.createdByCompany)}
                              {this.renderTableCellAction(payment)}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>

                    {this.renderTablePagination()}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>

        <PaymentAddDialog
          open={dialogAddStatus}
          onClose={onAddDialogClosePressed}
        />
        <PaymentDetailDialog
          open={dialogDetailStatus}
          onClose={onEditDialogClosePressed}
        />

        <Dialog
          open={dialogPaymentCompleteStatus}
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Typography className={classes.paymentCompleteTitle}>
              <Icon>check_circle</Icon>
              &emsp;Payment Success
            </Typography>

            <div style={{ marginTop: '48px' }}>
              <div className={classes.paymentSuccessInfoItems}>
                <Typography>Proforma No</Typography>
                <Typography variant="caption">
                  {paymentResult && paymentResult.proformaNo}
                </Typography>
              </div>
              <div className={classes.paymentSuccessInfoItems}>
                <Typography>Amount</Typography>
                <Typography className={classes.currencyColor}>
                  Rp.&nbsp;
                  {paymentResult && toCurrency(paymentResult.amount)}
                </Typography>
              </div>
            </div>
          </DialogContent>
          <DialogActions className={classes.centeredActions}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              className={classes.btnPaymentHistory}
              onClick={() => onClosePaymentCompletePressed()}
            >
              {LocalizedString.common.buttonCaptionOK}
            </Button>
          </DialogActions>
        </Dialog>

        {downloading && <LoadingIndicator />}
      </>
    );
  }
}
AdminPaymentHistory.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  menuOpen: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,

  payments: PropTypes.arrayOf(PropTypes.any).isRequired,
  downloadLimitOption: PropTypes.number.isRequired,
  paymentResult: PropTypes.shape(PropTypes.any).isRequired,

  dialogAddStatus: PropTypes.bool.isRequired,
  dialogDetailStatus: PropTypes.bool.isRequired,
  dialogPaymentCompleteStatus: PropTypes.bool.isRequired,

  searchText: PropTypes.string.isRequired,
  // filters: PropTypes.shape(PropTypes.any).isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  activePage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,

  onAddNewPressed: PropTypes.func.isRequired,
  onAddDialogClosePressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onChangePaging: PropTypes.func.isRequired,
  // onChangeFilter: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onEditDialogClosePressed: PropTypes.func.isRequired,
  onSearchTextChange: PropTypes.func.isRequired,
  onChangeDownloadOption: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onClosePaymentCompletePressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(AdminPaymentHistory);
