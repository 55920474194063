import * as Action from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case Action.SET_DOC_SUBMISSION_MY_RESPONSES: {
      return { ...action.value };
    }
    case Action.CLEAR_DOC_SUBMISSION_MY_RESPONSES: {
      return {};
    }
    default:
      return state;
  }
};
