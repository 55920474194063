import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { change, formValueSelector } from 'redux-form';
import UserEditDialog from './user-edit-dialog.presentation';
import {
  downloadAllVendorAsync,
  downloadAllProfilesAsync,
  uploadUpdateUserAsync,
  showErrorSnackbar,
  setMasterUserEditDialogStatus,
} from '../../redux/action';
import {
  ROLE_ADMIN,
  RXFIELD_MASTER_USER_COMPANY_ID,
  ROLE_CUSTOMER,
  RXFIELD_MASTER_USER_IS_ADMIN,
  RXFORM_MASTER_USER,
  ROLE_BILLING,
  DEFAULT_COMPANY,
  RXFIELD_MASTER_USER_IS_CORPORATE_ADMIN,
} from '../../constant';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const getVendorList = ({ currentUser, vendors }) => {
  if (currentUser.role === ROLE_ADMIN) {
    return _.values(vendors)
      .sort((left, right) => {
        if (left.name.trim().toLowerCase() > right.name.trim().toLowerCase()) {
          return 1;
        }
        if (left.name.trim().toLowerCase() < right.name.trim().toLowerCase()) {
          return -1;
        }
        return 0;
      })
      .map(vendor => ({ label: `${vendor.id} - ${vendor.name}`, value: vendor.id }));
  }
  return [{
    value: currentUser.company.id,
    label: currentUser.company.name,
  }];
};

const getUserDetail = (state) => {
  const userDetail = state.users[state.uiMasterUser.selectedUserId] || {};

  if (userDetail.company) {
    return {
      // ...userDetail,
      username: userDetail.username,
      fullName: userDetail.fullName,
      email: userDetail.email,
      companyId: userDetail.company.id,
      role: userDetail.role,
      isAdmin: userDetail.role,
      corporateAdmin: userDetail.corporateAdmin,
    };
  }

  return userDetail;
};

const USER_ROLES = [ROLE_ADMIN, ROLE_CUSTOMER, ROLE_BILLING];

const mapStateToProps = state => ({
  initialValues: getUserDetail(state),
  currentUserRole: state.currentUser.role,
  loading: false || state.uiMasterVendor.downloadingVendors,
  vendors: getVendorList(state),
  selectedCompany: formValueSelector(RXFORM_MASTER_USER)(state, RXFIELD_MASTER_USER_COMPANY_ID),
  chbIsAdmin: formValueSelector(RXFORM_MASTER_USER)(state, RXFIELD_MASTER_USER_IS_ADMIN),
  lstUserRole: USER_ROLES.map(item => ({ label: item, value: item })),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async (currentUserRole) => {
    if (currentUserRole === ROLE_ADMIN) {
      try {
        await dispatch(downloadAllVendorAsync());
      } catch (error) {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      }
    }
  },
  onSubmitPressed: async (values) => {
    try {
      const role = values[RXFIELD_MASTER_USER_IS_ADMIN];
      const companyId = role !== ROLE_CUSTOMER ? DEFAULT_COMPANY : values.companyId;
      const formValues = {
        ...values,
        role,
        companyId,
      };
      await dispatch(uploadUpdateUserAsync(formValues));
      await dispatch(downloadAllProfilesAsync());
      dispatch(setMasterUserEditDialogStatus(false));
    } catch (error) {
      // show error
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onUserRoleChaged: (value) => {
    if (value === ROLE_ADMIN) {
      dispatch(change(RXFORM_MASTER_USER, RXFIELD_MASTER_USER_IS_CORPORATE_ADMIN, false));
    }
  },
  onCompanySelected: (selectedCompany) => {
    // if (selectedCompany === 'CDP') {
    //   dispatch(change(RXFORM_MASTER_USER_ADD, RXFIELD_MASTER_USER_IS_CORPORATE_ADMIN, true));
    // } else {
    //   // dispatch(change(RXFORM_MASTER_USER_ADD, RXFIELD_MASTER_USER_IS_CORPORATE_ADMIN, false));
    // }
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserEditDialog));
