import { connect } from 'react-redux';
import LocalizedString from '../../localization';
import {
  downloadCheckoutTermAndConditionAsync, setDialogCheckoutTermAndCondition,
  showErrorSnackbar,
} from '../../redux/action';
import CheckoutTncDialog from './checkout-tnc-dialog.presentation';

export const mapStateToProps = state => ({
  downloading: state.uiCheckout.downloadingTermAndConditions,
  tnc: state.uiCheckout.termAndConditionText,
  visibility: state.uiCheckout.dialogTermAndCondition,
});

export const mapDispatchToProps = dispatch => ({
  onAppear: () => {
    dispatch(downloadCheckoutTermAndConditionAsync())
      .catch((error) => {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      });
  },
  onCancelPressed: () => {
    dispatch(setDialogCheckoutTermAndCondition(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutTncDialog);
