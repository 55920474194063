import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, TextField, Fab, Icon,
} from '@material-ui/core';
import { DEFAULT_STYLES } from '../../constant';
import { LoadingIndicator, PdfViewer } from '../../component';
import OrderCard from './order-card.container';

const styles = theme => ({
  cardItemContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: theme.spacing.unit * 2,
    maxBlockSize: '500px',
  },
  searchButton: {
    ...DEFAULT_STYLES.WEB_USER.BACK_ICON,
  },
});

class OrderTab extends Component {
  componentDidMount() {
    this.props.onAppear();
  }

  render() {
    const {
      classes,
      bl,
      searchBarText,
      downloading,
      onChangeSearchBarText,
    } = this.props;

    return (
      <>
        <div style={{ display: 'flex', marginTop: '24px' }}>
          <div style={{ flexGrow: 1, alignSelf: 'flex-end' }}>
            <TextField
              fullWidth
              placeholder="Search BL no"
              value={searchBarText}
              onChange={event => onChangeSearchBarText(event.target.value)}
            />
          </div>
          <div style={{ display: 'inline-flex', marginLeft: '8px' }}>
            <Fab
              size="small"
              variant="round"
              color="default"
              className={classes.searchButton}
            >
              <Icon>search</Icon>
            </Fab>
          </div>
        </div>

        <div className={classes.cardItemContainer}>
          {bl.map(item => (
            <OrderCard item={item} />
          ))}
        </div>

        {downloading && <LoadingIndicator />}

        <PdfViewer />
      </>
    );
  }
}

OrderTab.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  bl: PropTypes.arrayOf(PropTypes.any).isRequired,
  searchBarText: PropTypes.string.isRequired,
  downloading: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onChangeSearchBarText: PropTypes.func.isRequired,
};

export default withStyles(styles)(OrderTab);
