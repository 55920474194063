import { setGlobalLoading } from '../simple-action';
import { downloadDepositHistoryCsv, openNewTab } from '../../../helper';
import { URL_DEPOSIT_HISTORY_CSV } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(setGlobalLoading(true));

    const { authentication, uiDepositHistory } = getState();
    const { token } = authentication;
    const { filters } = uiDepositHistory;

    const { printId } = await downloadDepositHistoryCsv(token, filters);

    openNewTab(URL_DEPOSIT_HISTORY_CSV.replace(/{id}/, printId));
  } finally {
    dispatch(setGlobalLoading(false));
  }
};
