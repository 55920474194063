import * as Action from '../action';

const initialState = {
  downloading: false,
  downloadingDetail: false,
  uploadingResponse: false,
  downloadingPaymentOnBehalfList: false,
  tappedId: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.DOWNLOADING_NEW_DOCUMENT_MY_FORMS:
      return { ...state, downloading: action.status };
    case Action.DOWNLOADING_NEW_DOCUMENT_MY_FORM_DETAIL:
      return { ...state, downloadingDetail: action.status };
    case Action.UPLOADING_NEW_DOCUMENT_MY_FORM_RESPONSE:
      return { ...state, uploadingResponse: action.status };
    case Action.SET_NEW_DOCUMENT_MY_FORM_TAPPED_ID:
      return { ...state, tappedId: action.value };
    case Action.DOWNLOADING_PAYMENT_ON_BEHALF_LIST:
      return { ...state, downloadingPaymentOnBehalfList: action.status };
    default:
      return state;
  }
};
