import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import ReeferDataBaseScreen from './reefer-data.presentation';
import * as Constant from '../../constant';
import * as Action from '../../redux/action';
import { showErrorSnackbar } from '../../redux/action';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const getFilteredReeferData = (state) => {
  const { searchBarText } = state.uiReeferData;
  const { reeferData } = state;

  const filteredReeferData = _.values(reeferData)
    .filter(x => x.blNo.toUpperCase().includes(searchBarText.toUpperCase()));

  let uniqueReeferData = _.uniqBy(filteredReeferData, 'blNo');
  uniqueReeferData = uniqueReeferData.map((item) => {
    const containers = filteredReeferData.filter(container => container.blNo === item.blNo);
    return {
      ...item,
      items: containers,
      total: containers.length,
    };
  });

  return uniqueReeferData;
};

const getBlByBlNo = (state, blNo) => getFilteredReeferData(state)
  .find(item => item.blNo === blNo) || undefined;

const getFilteredContainer = (state, blNo) => {
  const activeBl = getFilteredReeferData(state).find(item => item.blNo === blNo);
  const containers: any[] = (activeBl
                            && activeBl.items
                              .map((item, index) => ({ ...item, party: index + 1 })))
                            || [];
  const {
    filters, sortField, sortDirection,
  } = state.uiReeferData;

  const filteredContainers = containers.sort((left, right) => {
    if (typeof left[sortField] === 'string') {
      if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
        return left[sortField].localeCompare(right[sortField]);
      }
      return right[sortField].localeCompare(left[sortField]);
    }

    if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
      return left[sortField] - right[sortField];
    }
    return right[sortField] - left[sortField];
  })
    .filter(item => item.containerNo.indexOf(
      filters[Constant.TABLE_FIELD_CONTAINER_NUMBER].toUpperCase(),
    ) >= 0)
    .filter((item) => {
      if (filters[Constant.TABLE_FIELD_SIZE] !== '') {
        return item.ctrSize === parseInt(filters[Constant.TABLE_FIELD_SIZE], 10);
      }
      return true;
    })
    .filter((item) => {
      if (filters[Constant.TABLE_FIELD_GATE_IN_CDP]) {
        return moment(item.gateInCdp).format(Constant.MOMENT_DATE_FORMAT_STRING)
              === moment(filters[Constant.TABLE_FIELD_GATE_IN_CDP])
                .format(Constant.MOMENT_DATE_FORMAT_STRING);
      }

      return true;
    });

  return filteredContainers;
};

const getPageFilteredContainer = (state, blNo) => {
  const {
    activePage, pageSize,
  } = state.uiReeferData;
  const filteredContainers = getFilteredContainer(state, blNo);

  const refinedActivePage = activePage || 1;
  const start = Math.min((refinedActivePage - 1) * pageSize, filteredContainers.length);
  const end = Math.min(start + pageSize, filteredContainers.length);
  return filteredContainers.slice(start, end);
};

const getTotalPage = (state, blNo) => {
  const totalData = getFilteredContainer(state, blNo).length;
  const { pageSize } = state.uiReeferData;

  return Math.max(Math.ceil(totalData / pageSize), 1);
};

// const refreshList = _.debounce(async (dispatch) => {
//   try {
//     await dispatch(Action.downloadReeferDataAsync());
//   } catch (error) {
//     dispatch(Action.setUIError(Constant.RXSTATE_CONTAINER_TRACKING, error.message));
//   }
// }, 800);

const mapStateToProps = state => ({
  notificationOpen: state.uiWebUserMain.notificationDrawerOpen,
  downloadingReeferData: state.uiReeferData.downloadingReeferDatas,
  searchBarText: state.uiReeferData.searchBarText,
  reeferData: getFilteredReeferData(state),
  pageSize: state.uiReeferData.pageSize,
  totalPage: getTotalPage(state, state.uiReeferData.tappedBlNo),
  activePage: state.uiReeferData.activePage,
  filters: state.uiReeferData.filters,
  sortField: state.uiReeferData.sortField,
  sortDirection: state.uiReeferData.sortDirection,
  activeBl: getBlByBlNo(state, state.uiReeferData.tappedBlNo),
  dialogReeferDataDetailStatus: state.uiReeferData.dialogReeferDataDetailStatus,
  containerTracking: getPageFilteredContainer(state, state.uiReeferData.tappedBlNo),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async () => {
    try {
      await dispatch(Action.downloadReeferDataAsync());
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onMorePress: (tappedBlNo) => {
    dispatch(Action.setTappedReeferDataBlNo(tappedBlNo));
    dispatch(Action.setTappedReeferDataContainerNo(''));
    // reset filters
    dispatch(Action.setReeferDataPaging(Constant.RXTABLEFIELD_ACTIVE_PAGE, 1));
    dispatch(Action.setReeferDataFilter(Constant.TABLE_FIELD_CONTAINER_NUMBER, ''));
    dispatch(Action.setReeferDataFilter(Constant.TABLE_FIELD_SERVICE, ''));
    dispatch(Action.setReeferDataFilter(Constant.TABLE_FIELD_SIZE, ''));
    dispatch(Action.setReeferDataFilter(Constant.TABLE_FIELD_GATE_IN_CDP, ''));
  },
  onChangeSearchBarText: (text) => {
    dispatch(Action.setReeferDataSearchText(text));
  },
  onChangeFilter: (filterName, value) => {
    dispatch(Action.setReeferDataFilter(filterName, value));
  },
  onChangePaging: (pageField, value) => {
    dispatch(Action.setReeferDataPaging(pageField, value));
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(Action.setReeferDataSort(sortField, sortDirection));
  },
  onDetailPress: (tappedBlNo, tappedContainerNo) => {
    dispatch(Action.setTappedReeferDataContainerNo(tappedContainerNo));
    dispatch(Action.setReeferDataDetailDialogStatus(true));

    dispatch(Action.downloadReeferDataDetailsAsync(tappedBlNo, tappedContainerNo))
      .catch((error) => {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
        unauthorizedErrorHandler(error, ownProps.history.push);
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReeferDataBaseScreen);
