import { downloadingTrackingStatusBehandleOnProgresses, setTrackingStatusBehandleOnProgresses } from '../simple-action';
import * as Helper from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;

  try {
    dispatch(downloadingTrackingStatusBehandleOnProgresses(true));
    const data = await Helper.downloadTrackingStatusBehandleOnProgresses(token);
    dispatch(setTrackingStatusBehandleOnProgresses(data));
  } finally {
    dispatch(downloadingTrackingStatusBehandleOnProgresses(false));
  }
};
