import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  withStyles,
  Typography,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Grid,
  Button,
} from '@material-ui/core';
import LocalizedString from '../../localization';
import {
  MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING,
  DEFAULT_STYLES,
  CART_SERVICE_NAME_IMPORT,
  CART_SERVICE_NAME_EXPORT,
} from '../../constant';
import { toCurrency, toMoment } from '../../helper';
import { MyTermOrderShape } from '../../type';
import { PdfViewer } from '../../component';

const styles = theme => ({
  btnAddToCart: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
    width: '256px',
  },
  btnKmt: {
    marginLeft: 8,
  },
  btnSp2: {
    marginLeft: 8,
  },
  btnInvoice: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  cardActionPrimary: {
    display: 'flex',
    alignItems: 'baseline',
    padding: 16,
    justifyContent: 'space-between',
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  cardBlDataHeader: {
    marginBottom: theme.spacing.unit * 2,
  },
  detailHeader: {
    color: '#0066CB',
  },
  textTotalPrice: {
    fontSize: '22px',
    fontWeight: 500,
    color: '#0065B3',
    marginRight: theme.spacing.unit * 2,
  },
});

const isAddedToCart = (blInfo, shoppingCart) => {
  if (shoppingCart) {
    return Boolean(shoppingCart
      .find(shopItem => shopItem.blNumber.toUpperCase() === blInfo.blNumber.toUpperCase()
      && shopItem.service === blInfo.service));
  }

  return false;
};

const TermDetail = ({
  classes,
  selectedBl,
  shoppingCart,
  userCompany,
  addingShoppingCartItem,
  onAddToCartPressed,
  onSp2Pressed,
  onKmtPressed,
  onInvoicePressed,
}) => {
  if (!selectedBl) {
    return null;
  }

  const getTotalPrice = () => selectedBl.amount || 0;

  const renderAddToCartButton = () => {
    if (userCompany.id !== selectedBl.createdByCompany) {
      return null;
    }

    if (isAddedToCart(selectedBl, shoppingCart)) {
      return (
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.btnAddToCart}
          disabled
        >
          {LocalizedString.common.buttonCaptionAlreadyInCart}
        </Button>
      );
    }

    return (
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.btnAddToCart}
        disabled={addingShoppingCartItem}
        onClick={() => onAddToCartPressed(selectedBl)}
      >
        {LocalizedString.common.buttonCaptionAddToCart}
      </Button>
    );
  };

  const renderKmtSp2Button = (item) => {
    if (item.service === CART_SERVICE_NAME_IMPORT) {
      // render SP2
      if (userCompany.id !== selectedBl.createdByCompany) {
        return null;
      }
      return (
        <Button
          variant="outlined"
          color="primary"
          classes={{ outlinedPrimary: classes.btnSp2 }}
          disabled={!item.invoiceNo}
          onClick={() => onSp2Pressed(item)}
        >
          {LocalizedString.myPaymentHistory.captionSp2Pdf}
        </Button>
      );
    }
    if (item.service === CART_SERVICE_NAME_EXPORT) {
      if (userCompany.id !== selectedBl.createdByCompany) {
        return null;
      }
      return (
        <Button
          variant="outlined"
          color="primary"
          classes={{ outlinedPrimary: classes.btnKmt }}
          disabled={!item.invoiceNo}
          onClick={() => onKmtPressed(item)}
        >
          {LocalizedString.myPaymentHistory.captionKmtPdf}
        </Button>
      );
    }

    return null;
  };

  const createdByName = userCompany.id !== selectedBl.createdByCompany
    ? selectedBl.createdByCompany
    : selectedBl.createdByUser;

  return (
    <>
      <Card className={classes.cardBlDataHeader}>
        <CardHeader
          title={(
            <>
              <Typography variant="h6" className={classes.detailHeader}>
                {LocalizedString.myPaymentTerm.labelBlNo}
                &nbsp;
                {selectedBl.blNumber}
              </Typography>
            </>
          )}
        />
        <CardContent>
          <Grid container spacing={24}>
            <Grid item xs={6} md={3}>
              <Typography variant="caption">
                {LocalizedString.myPaymentTerm.labelCreatedOn}
              </Typography>
              <Typography>
                {toMoment(selectedBl.termPaymentDueDate)
                  .format(MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING)}
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="caption">
                {LocalizedString.myPaymentTerm.labelCreatedBy}
              </Typography>
              <Typography>
                {createdByName}
              </Typography>
            </Grid>
            <Grid item xs={6} md={2}>
              <Typography variant="caption">
                {LocalizedString.myPaymentTerm.labelService}
              </Typography>
              <Typography>
                {selectedBl.service}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="caption">
                {LocalizedString.myPaymentTerm.labelInvoiceName}
              </Typography>
              <Typography>
                {selectedBl.paymentOnBehalfOf || '-'}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>

        <CardActions className={classes.cardActionPrimary}>
          <Typography variant="body1" className={classes.textJumlahPembayaran}>{LocalizedString.myPaymentTerm.labelTotalPrice}</Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" className={classes.textTotalPrice}>
              Rp.&nbsp;
              {toCurrency(getTotalPrice())}
            </Typography>
            {renderAddToCartButton()}
          </div>
        </CardActions>
      </Card>

      <Card className={classes.cardBlDataHeader}>
        <CardHeader
          title={(
            <>
              <Typography variant="h6" className={classes.detailHeader}>
                Detail
              </Typography>
            </>
          )}
        />
        <CardContent>
          <div style={{ display: 'flex' }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.btnInvoice}
              disabled={!selectedBl.invoiceNo}
              onClick={() => onInvoicePressed(selectedBl)}
            >
              {LocalizedString.myPaymentHistory.captionInvoicePdf}
            </Button>
            {renderKmtSp2Button(selectedBl)}
          </div>
        </CardContent>
      </Card>

      <PdfViewer />
    </>
  );
};
TermDetail.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  selectedBl: MyTermOrderShape.isRequired,
  shoppingCart: PropTypes.arrayOf(PropTypes.any).isRequired,
  userCompany: PropTypes.shape(PropTypes.any).isRequired,
  addingShoppingCartItem: PropTypes.bool.isRequired,

  onAddToCartPressed: PropTypes.func.isRequired,
  onInvoicePressed: PropTypes.func.isRequired,
  onSp2Pressed: PropTypes.func.isRequired,
  onKmtPressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(TermDetail);
