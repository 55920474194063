import * as Helper from '../../../helper';
import { setFormResponses, downloadingFormResponses } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingFormResponses(true));
    const { token } = getState().authentication;
    const {
      selectedUser, selectedStatus, activePage, pageSize,
    } = getState().uiFormResponse;
    const formResponses = await Helper.downloadFormResponses(
      token, selectedUser, selectedStatus, activePage, pageSize,
    );
    dispatch(setFormResponses(formResponses));
  } finally {
    dispatch(downloadingFormResponses(false));
  }
};
