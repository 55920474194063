import * as Helper from '../../../helper';
import * as Validation from '../../../validation';
import {
  uploadingProfileDetails, addUser, addProfilePicture, setProfileScreenEditMode, editCurrentUser,
} from '../simple-action';

export default userInfo => async (dispatch, getState) => {
  try {
    dispatch(uploadingProfileDetails(true));
    const { token } = getState().authentication;
    const user = getState().users[getState().currentUser.id];
    const { profilePicture } = user;
    Validation.validateProfileInfo(userInfo);

    const {
      id, email, fullName, phone, jobTitle,
    } = userInfo;
    const updatedUserInfo = {
      fullName,
      // fullName, phone, jobTitle, email,
    };
    await Helper.uploadProfileDetails(updatedUserInfo, token);

    dispatch(addUser({ ...user, fullName }));
    const currentUser = {
      id, email, phone, jobTitle,
    };
    dispatch(editCurrentUser(currentUser));
    dispatch(addProfilePicture(user.id, profilePicture));

    dispatch(setProfileScreenEditMode(false));
  } finally {
    dispatch(uploadingProfileDetails(false));
  }
};
